<div *ngIf="dataLoading" class="custom-paginator">
  <div class="datatable-spinner" [ngClass]="['color' | cssClassToAppSpecificColorCssClass]">
    <span>{{'loading_text.loading' | translate}}</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div *ngIf="!dataLoading" class="custom-paginator">
  <div class="custom-paginator-element">
    <select [(ngModel)]="itemPerPage" (change)="sendNbItems()">
      <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}</option>
    </select>
    <label>{{'plugins.datatables.entries_per_page' | translate}}</label>
  </div>

  <div class="custom-paginator-element">
    <app-datatable-pager [page]="curPage" [visiblePagesCount]="5" [size]="pageSize" [count]="rowCount" [hidden]="false"
                         (change)="table.onFooterPage($event)"></app-datatable-pager>
  </div>

  <span class="custom-paginator-element">
    <b *ngIf="isWebAppFilteredTableFooter">{{'displaying' | translate}} {{totalEntries}} {{'datatables.entries' | translate}}</b>
    <b *ngIf="!isWebAppFilteredTableFooter">{{'total' | translate}} {{totalEntries}} {{'datatables.entries' | translate}}</b>
  </span>
</div>
