export class GlobalConstants {
  public static siteProductivityHelpCentreLink = 'https://intercom.help/scriptgo/en/collections/9523657-site-productivity';
  public static localNotificationIconLink = 'res://drawable/ic_launcher_round';
  public static supportedCustomFormVersion = 2;
  public static maxLengthAllowedInShortTextField = 256;
  public static maxLengthAllowedInLongTextField = 4000;
  public static customFieldOptionsMaxLength = 60;
  public static numericalScaleLowerEndValueRange = [0, 1]
  public static numericalScaleHigherEndValueRange = [2, 3, 4, 5, 6, 7, 8, 9, 10]
  public static numericalScaleDefaultLowerEndValue = 0
  public static numericalScaleDefaultHigherEndValue = 10
}
