import { User } from './user';
import { Identifiable } from './identifiable';

export class Author implements Identifiable {
  id: string;
  name: string;

  constructor(
    id?: string,
    name?: string
  ) {
    this.id = id;
    this.name = name;
  }
  /**
   * Convert backend data to Model
   * @param json The given createdBy/updatedBy backend Object
   * @param model The given model
   */
  public static toModel(json: any, model: Author) {
    if (json) {
      model.id = json.id;
      model.name = json.name;
    }
  }

  public static userToAuthor(user: User) {
    const author = new Author();
    author.id = user.id;
    author.name = user.firstName + ' ' + user.lastName;
    return author;
  }
}
