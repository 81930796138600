import { AfterViewChecked, Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Automatically resize an input field base on content length
 * Beware that no minimum height or width is set by this directive, please test the behavior with an empty input field
 *
 * Supported parameter values for this directive are : 'both', 'height', 'width'
 */
@Directive({
  selector: '[appAutoResizeInput]',
})
export class ResizeTextareaDirective implements AfterViewChecked {
  @Input('appAutoResizeInput') dimension: string;

  constructor(private el: ElementRef) {
  }

  ngAfterViewChecked(): void {
    this.resizeElement(this.el.nativeElement);
  }

  @HostListener('ngModelChange')
  onInputValueChange() {
    // TODO: Ajouter un debounce pour ne pas diminuer les performance quand on écrit trop vite sur un mauvais pc
    this.resizeElement(this.el.nativeElement);
  }

  private resizeElement(nativeElement) {
    if (nativeElement) {
      if (this.dimension === 'height' || this.dimension === 'both') {
        nativeElement.style.height = (nativeElement.scrollHeight) + 'px';
      }
      if (this.dimension === 'width' || this.dimension === 'both') {
        nativeElement.style.width = '1px';
        nativeElement.style.width = nativeElement.scrollWidth + 5 + 'px';
      }
    }
  }

}
