import { Injectable } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

import { Contractor } from '@models/contractor';
import { Event } from '@models/event';
import { EventAsset } from '@models/event-asset';
import { Tag } from '@models/tag';
import { SessionService } from '@services/session.service';
import { SharedService } from '@services/shared/shared.service';
import { WeatherService } from '@services/weather.service';
import { DateManipulationService } from '@services/date-manipulation.service';
import { Task } from '@models/task';
import { DeviceService } from '@services/device.service';
import { EMAIL_PREFIX, EmailService, LINE_BREAK } from '@services/emails/email.service';
import { User } from '@models/user';
import {UrlGiverService} from '@services/url-giver.service';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from '@services/shared-data.service';

export const EMAIL_EVENT_TRANSLATIONS = [
  'app.email.subject',
  'app.email.message.hello',
  'app.email.message.text',
  'app.email.signature',
  'app.email.info',
  'app.label.organization',
  'app.label.site',
  'app.label.title',
  'app.label.creator',
  'app.label.time',
  'app.label.from',
  'app.label.to',
  'app.label.duration',
  'app.label.location',
  'app.label.weather',
  'app.label.temperature',
  'app.label.windspeed',
  'app.label.humidity',
  'app.weather.temperature',
  'app.weather.windspeed',
  'app.weather.humidity',
  'label.tags',
  'label.contractors',
  'label.plants',
  'label.labours',
  'label.materials',
  'events.edit.field.linked-task',
  'app.label.directlink',
  'events.detail.panel_details.desc',
];

@Injectable({
  providedIn: 'root'
})
export class EventEmailService extends EmailService {

  relevantSharedService: SharedService | SharedDataService;

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private weatherService: WeatherService,
    protected emailComposer: EmailComposer,
    private sessionService: SessionService,
    private dateManipulationService: DateManipulationService,
    protected deviceService: DeviceService,
    private urlGiverService : UrlGiverService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService
  ) {
    super(
      emailComposer,
      deviceService,
    );
    this.relevantSharedService = this.deviceService.isMobile ? this.sharedService : this.sharedDataService;
  }

  /**
   * Populate email template with all event data
   * @param event selected event
   * @param tags event tag list
   * @param contractors event contractor list
   * @param labours event labour list
   * @param plants event plant list
   * @param materials event material list
   * @param task event task linked
   * @param lb line break code
   */
  prepareEventEmailTemplate(
    event: Event,
    tags: Tag[],
    contractors: Contractor[],
    labours: EventAsset[],
    plants: EventAsset[],
    materials: EventAsset[],
    task: Task,
    lb: string // LINE BREAK
  ): Observable<{ subject: string, body: string }> {
    const weather = this.weatherService.getWeather(event.weather);
    return this.translate.get(EMAIL_EVENT_TRANSLATIONS).pipe(
      flatMap(async translations => {
        // Error cases:
        if (!this.relevantSharedService.hasSpaceSelected) {
          this.logger.error('Cannot generate an event email template without a current space.');
          throw new Error('Cannot generate an event email template without a current space.');
        } else if (!this.relevantSharedService.hasSiteSelected) {
          this.logger.error('Cannot generate an event email template without a current site.');
          throw new Error('Cannot generate an event email template without a current site.');
        } else if (!event) {
          this.logger.error('Cannot generate an event email template without a selected event.');
          throw new Error('Cannot generate an event email template without a selected event.');
        }

        const currentUser: User = this.sessionService.getCurrentUser();
        const space = await this.relevantSharedService.getNextNonNullSpace();
        const site = await this.relevantSharedService.getNextNonNullSite();
        const currentUserFullName: string = `${currentUser.firstName} ${currentUser.lastName}`;
        const temperatureWithSiteSettings : string = WeatherService.getTemperatureWithSiteSettingsUnits(event.weather.temperature, site);
        const windspeedWithSiteSettings : string = WeatherService.getWindspeedWithSiteSettingsUnits(event.weather.windSpeed, site);

        // (if only start date) Time: {start}
        // (if both) From: {start} To: {end} (duration: {duration})
        const time = (!event.endDatetime) ? `${translations['app.label.time']}: ` +
          `${this.dateManipulationService.formatToDateString(event.startDatetime)}` :
          `${translations['app.label.from']}: ${this.dateManipulationService.formatToDateString(event.startDatetime)}  ` +
          `${translations['app.label.to']}: ${this.dateManipulationService.formatToDateString(event.endDatetime)}  ` +
          `${translations['app.label.duration']}: ${this.dateManipulationService.getDuration(event.startDatetime, event.endDatetime)}`;

        // TAGS + CONTRACTORS + ASSETS...
        const tagList = this.formatToList(tags, lb);
        const contractorList = this.formatToList(contractors, lb);
        const labourList = this.formatToList(labours, lb);
        const plantList = this.formatToList(plants, lb);
        const materialList = this.formatToList(materials, lb);

        const SEPARATOR = '---';

        const eventLink = this.urlGiverService.giveEventUrl(space.id, site.id, event.id);
        const template = {
          subject: `${EMAIL_PREFIX} ${translations['app.email.subject']}: ${event.title}`,
          body: `${translations['app.email.message.hello']}${lb}${lb}${translations['app.email.message.text']}${lb}${SEPARATOR}${lb}`
            + `${translations['app.label.organization']}: ${space.name}${lb}`
            + `${translations['app.label.site']}: ${site.name}${lb}${SEPARATOR}${lb}`
            + `${translations['app.label.title']}: ${event.title}${lb}${translations['app.label.creator']}: ${event.createdBy.name}${lb}${time}${lb}`
            + (event.locationObject ? `${translations['app.label.location']}: ${event.locationObject.name}${lb}` : ``)
            + ((!!weather) ? `${translations['app.label.weather']}: ${weather.label} / `
              + `${temperatureWithSiteSettings} ${translations['app.label.temperature']} / `
              + `${windspeedWithSiteSettings} ${translations['app.label.windspeed']} / `
              + `${event.weather.humidity} ${translations['app.weather.humidity']} ${translations['app.label.humidity']}${lb}` : ``)
            + ((tagList !== '') ? `${translations['label.tags']}:${lb}${tagList}` : ``)
            + ((contractorList !== '') ? `${translations['label.contractors']}:${lb}${contractorList}` : ``)
            + ((labourList !== '') ? `${translations['label.labours']}:${lb}${labourList}` : ``)
            + ((plantList !== '') ? `${translations['label.plants']}:${lb}${plantList}` : ``)
            + ((materialList !== '') ? `${translations['label.materials']}:${lb}${materialList}` : ``)
            + ((task) ? `${translations['events.edit.field.linked-task']}: ${task.title} ${event.task.progress}%${lb}` : ``)
            + `${translations['events.detail.panel_details.desc']}: ${event.description}${lb}`
            + `${SEPARATOR}${lb}${translations['app.label.directlink']} ${eventLink}${lb}${SEPARATOR}${lb}${lb}`
            + `${translations['app.email.signature']}${lb}${currentUserFullName}${lb}${lb}${translations['app.email.info']}${lb}${lb}`
        };

        return template;
      })
    );
  }

  /**
   * Build the mail
   * @param event selected event
   * @param tags event tag list
   * @param contractors event contractor list
   * @param labours event labour list
   * @param plants event plant list
   * @param materials event material list
   * @param task event task linked
   * @param message note to add to the mail
   */
  private prepareEventMail(
    event: Event,
    tags: Tag[],
    contractors: Contractor[],
    labours: EventAsset[],
    plants: EventAsset[],
    materials: EventAsset[],
    task: Task,
  ): Observable<{subject: string, body: string}> {
    return this.prepareEventEmailTemplate(event, tags, contractors, labours, plants, materials, task, this.deviceService.isMobile ? LINE_BREAK.mobile : LINE_BREAK.desktop);
  }

  /**
   * Send a mail to share event
   * @param event
   * @param tags
   * @param contractors
   * @param labours
   * @param plants
   * @param materials
   * @param task
   * @param message
   */
  public sendEventEmail(
    event: Event,
    tags: Tag[],
    contractors: Contractor[],
    labours: EventAsset[],
    plants: EventAsset[],
    materials: EventAsset[],
    task: Task,
  ) {
    this.prepareEventMail(event, tags, contractors, labours, plants, materials, task).subscribe(email => {
        super.sendEmail(email.subject, email.body);
    });
  }

  //TODO move to some util class or to email-service ?
  /**
   * Format an array to a simple string list (with amount and/or duration when needed)
   * example:
   * - item1 x 3
   * - item2
   * - item4 3 x 02:30
   * ...
   * @param array given array
   */
  private formatToList(items: any[], linebreak: string): string {
    // Empty list return empty string
    if (items.length === 0) {
      return '';
    } else {
      let template = '';
      items.forEach(item => {
        // Add name
        template += '- ' + item.name;
        if (item.amount) {
          if (item.duration) {
            // Add {qty} x {HH:mm}
            template += ' ' + item.amount + ' x ' + this.dateManipulationService.formatMinutesToStringDuration(item.duration);
          } else {
            // Add x {qty}
            template += ' x ' + item.amount;
          }
        }
        // Add linebreak
        template += linebreak;
      }

      );
      return template;
    }
  }

}
