<div *ngIf="!deviceService.isMobile" class="overlay webapp phone-number-verification-modal">
  <div class="modal-dialog desktop">
    <div class="modal-content">
      <span *ngIf="!isStrictModeEnabled" class="cross-icon" (click)="dismissModal()">
        <i class="far fa-times-circle"></i>
      </span>
      <div class="container">
        <h3>{{ "phone_number.verification.verify_number" | translate }}</h3>
        <p>{{ "phone_number.2FA.info_message" | translate }}</p>
      </div>
      <div class="modal-body">
        <div class="phone-number-field">
          <div class="d-block">
            <label>{{ "phone_number.enter_phone_number.text" | translate }}</label>
          </div>
          <form [formGroup]="formGroup">
            <div class="input-field">
              <ngx-intl-tel-input
                class="form-control"
                [cssClass]="'custom'"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [phoneValidation]="false"
                [separateDialCode]="true"
                [selectedCountryISO]="selectedCountryISO"
                [preferredCountries]="preferredCountries"
                (countryChange)="onCountryChange($event)"
                formControlName="phoneNumber"
              ></ngx-intl-tel-input>
            </div>
          </form>
        </div>
        <div *ngIf="isPhoneNumberInvalid() || !isPhoneNumberFromValidCountry()">
          <p *ngIf="isPhoneNumberInvalid()" class="info-text">
            {{'phone_number.invalid_number.message' | translate}}
          </p>
          <p *ngIf="!isPhoneNumberFromValidCountry()" class="warning-msg mb-0">
            {{'phone_number.unsuppported_country_code.message' | translate}}
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="validate()" [ngClass]="{'disabled': !formGroup.valid}" [disabled]="!formGroup.valid" type="button" class="btn btn-primary">{{ 'phone_number.continue.button' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="deviceService.isMobile" class="full-screen mobile">
  <div class="mobile-header">
    <span *ngIf="!isStrictModeEnabled" class="cross-icon" (click)="dismissModal()">
      <i class="far fa-times-circle"></i>
    </span>
  </div>
  <div class="container">
    <h3>{{ "phone_number.verification.verify_number" | translate }}</h3>
    <p>{{ "phone_number.2FA.info_message" | translate }}</p>
    <div class="phone-number-field">
      <div class="label">
        <label class="left">{{ "phone_number.enter_phone_number.text" | translate }}</label>
      </div>
      <form [formGroup]="formGroup">
        <div class="input-field">
        </div>
        <ngx-intl-tel-input
          class="form-control"
          [cssClass]="'custom'"
          [enableAutoCountrySelect]="false"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [selectFirstCountry]="false"
          [phoneValidation]="false"
          [separateDialCode]="true"
          [selectedCountryISO]="selectedCountryISO"
          [preferredCountries]="preferredCountries"
          (countryChange)="onCountryChange($event)"
          formControlName="phoneNumber"
        ></ngx-intl-tel-input>
      </form>
    </div>
    <div *ngIf="isPhoneNumberInvalid() || !isPhoneNumberFromValidCountry()">
      <p *ngIf="isPhoneNumberInvalid()" class="info-text text-danger">
        {{'phone_number.invalid_number.message' | translate}}
      </p>
      <p *ngIf="!isPhoneNumberFromValidCountry()" class="warning-msg">
        {{'phone_number.unsuppported_country_code.message' | translate}}
      </p>
    </div>
  </div>
  <div class="modal-footer" >
    <button type="button" (click)="validate()" [ngClass]="{'disabled': !formGroup.valid}" [disabled]="!formGroup.valid" class="btn btn-primary">{{ 'phone_number.continue.button' | translate }}</button>
  </div>
</div>
