import { Pipe, PipeTransform } from '@angular/core';
import { TaskLabelable } from '@models/labelable';
import { taskNumberPrefix } from '@models/task';

@Pipe({
  name: 'taskToLabelList'
})
export class TaskToLabelListPipe implements PipeTransform {
  transform(list:  TaskLabelable[]): string[] {
    return list.map(item => {
      if(item.taskNumber) {
        if (item.parentTaskId) {
          return taskNumberPrefix.SUBTASK_PREFIX + '-' + item.taskNumber.toString() + ' ' + item.label;
        }
        return taskNumberPrefix.TASK_PREFIX + '-' + item.taskNumber.toString() + ' ' + item.label;
      }
      return item.label;
    });
  }
}
