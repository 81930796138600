import { Injectable } from '@angular/core';
import { Site } from '../models/site';
import { ModelVersion } from '../models/model-version';
import { DatabaseService } from './shared/database.service';
import { AbstractModelService } from './abstract-model-service';
import { Subject, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { UrlGiverService } from './url-giver.service';
import { SyncResponse } from '@models/synchronization/sync-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { SpinnerService } from './spinner.service';
import { AlertBox, AlertLevel, ToasterService } from './toaster.service';
import { ServerError } from '@models/errors/server-error';
import { WebappSyncService } from './synchronization/webapp-sync.service';
import { NetworkStatus } from '@models/synchronization/network-status';
import { EventsCountSiteCard } from '@models/events-count';
import { TasksCountSiteCard } from '@models/tasks-count';


@Injectable()
export class SiteService extends AbstractModelService<Site> {
  protected type = 'Site';

  public readonly siteItems: Observable<Site[]> = this.items;

  constructor(
    protected databaseService: DatabaseService,
    private urlGiverService: UrlGiverService,
    private http: HttpClient,
    private logger: NGXLogger,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private webappSyncService: WebappSyncService,
  ) {
    super(
      databaseService,
    );
  }

  public async filterAll(): Promise<void> {
    const sites = await this.databaseService.getSites();
    this.itemSubject.next(sites);
  }

  getAllOnce(): Promise<Site[]> {
    return this.databaseService.getSites();
  }

  async getModelVersion(siteId: string): Promise<string> {
    const db = await this.databaseService.getDBInstant();
    const version = await db.modelVersion.get(siteId);
    return version ? version.version : '1';
  }

  async setModelVersion(siteId: string, version: string): Promise<void> {
    if (siteId) {
      const db = await this.databaseService.getDBInstant();
      const modelVersion = { siteId, version };
      await db.modelVersion.put(modelVersion);
    }
  }

  public async getEventSiteCardCounts(spaceId: string): Promise<EventsCountSiteCard> {
    let url = this.urlGiverService.giveEventSiteCardCountsUrl(spaceId);
    return this.http.get(url).toPromise()
      .then((responce: EventsCountSiteCard) => {
        return responce;
      })
      .catch(error => {
        this.logger.error(`Error fetching event counts in (${spaceId}})`, error);
        throw new ServerError(`Error fetching event counts in (${spaceId}})`, error);
      });
  }

    public async getTaskSiteCardCounts(spaceId: string): Promise<TasksCountSiteCard> {
    let url = this.urlGiverService.giveTaskSiteCardCountsUrl(spaceId);
    return this.http.get(url).toPromise()
      .then((responce: TasksCountSiteCard) => {
        return responce;
      })
      .catch(error => {
        this.logger.error(`Error fetching task counts in (${spaceId}})`, error);
        throw new ServerError(`Error fetching task counts in (${spaceId}})`, error);
      });
  }

  public async getSites(spaceId: string) : Promise<Site[]> {
    let syncUrl = this.urlGiverService.giveSitesListSyncRequestUrl(spaceId);
    return this.http.get<SyncResponse>(syncUrl).toPromise()
      .then(response => {
        let sites: Site[] = [];
        for (let json of response.items) {
          let site = new Site;
          Site.toModel(json, site);
          sites.push(site);
        }
        sites = Site.sortByName(sites);
        this.webappSyncService.updateSpaceSequenceToken(response.seq);
        this.spinnerService.deactivate();
        return sites;
      })
      .catch(error => {
        this.logger.error(`Error fetching sites list in (${spaceId}})`, error);
        this.spinnerService.deactivate();
        this.toasterService.showAlertBox(new AlertBox(
          'sync-warning',
          AlertLevel.warning,
          'alertbox.sync-warning',
          false,
          true
        ));
        throw new ServerError(`Error fetching sites list in (${spaceId}})`, error);
      });
  }

  getBackendAllSites(spaceId: string): Promise<Site[]> {
    let sites = [];
    let params = new HttpParams()
    .set('getActiveSites', true)
    .set('getArchivedSites', true);
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get<SyncResponse>(this.urlGiverService.giveSitesListSyncRequestUrl(spaceId), { params: params }))
      ).toPromise()
      .then((response) => {
        for (let json of response.items) {
          let site = new Site;
          Site.toModel(json, site);
          sites.push(site);
        }
        sites = Site.sortByName(sites);
        return sites;
      })
      .catch((error) => {
        throw error;
      });
  }
}

