import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField, CustomCurrencies } from '@models/custom-event-field';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-custom-form-currency-field',
  templateUrl: './custom-form-currency-field.component.html',
  styleUrls: ['./custom-form-currency-field.component.scss'],
})
export class CustomFormCurrencyFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  currency: string;

  constructor() {}

  ngOnInit() {
    this.currency = this.getCurrencySymbol(this.customField.children.currency)
  }

  getCurrencySymbol(currencyType: string): string {
    switch (currencyType) {
      case (CustomCurrencies.AED):
        return 'AED';
      case (CustomCurrencies.AUD):
        return 'A$';
      case (CustomCurrencies.CAD):
        return 'C$';
      case (CustomCurrencies.EUR):
        return '€';
      case (CustomCurrencies.NZD):
        return 'NZ$';
      case (CustomCurrencies.GBP):
        return '£';
      case (CustomCurrencies.USD):
        return '$';
      case (CustomCurrencies.ZAR):
        return 'ZAR';
      default:
        return '£';
    }
  }

}
