import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
// Angular Material
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { UiDatetimePickerService, UiDatetimePickerTranslateService } from '../../../services';
// Components
import { UiCommonDatetimePickerComponent } from '../../common/ui-common-datetime-picker/ui-common-datetime-picker.component';

/**
 * #Webapp Datetime Picker
 * Component for displaying a calendar and a timepick for selecting a date / time.
 * @author Julien Bertacco 2019.
 */
@Component({
  selector: 'ui-web-datetime-picker',
  styleUrls: ['ui-web-datetime-picker.component.scss'],
  templateUrl: 'ui-web-datetime-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiWebDatetimePickerComponent extends UiCommonDatetimePickerComponent implements OnInit {
  @HostListener("document:click")
  clickedOut() {
    if(typeof this.customClickEvent === 'function') {
      this.customClickEvent();
    }
  }

  public customSaveAction: Function;
  public customClearAction: Function;
  public customClickEvent: Function;

  constructor(
    public datetimePickerService: UiDatetimePickerService,
    protected datetimeTranslateService: UiDatetimePickerTranslateService,
    private dialogRef: MatDialogRef<UiWebDatetimePickerComponent>,
  ) {
    super(
      datetimePickerService,
      datetimeTranslateService,
    );
  }
  /**
   * À l'initialisation, on récup les noms des mois / jours traduit depuis datetimePickerService
   * pour les afficher dans le composant.
   * Par la même on s'occupe de générer le calendrier dans un array.
   * On vérifie également que les données en entrée sont bien présente.
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.dialogRef.beforeClosed().subscribe(() => {
      this.close();
    });
  }

  save(): void {
    super.save();
    if (this.customSaveAction) { this.customSaveAction(); }
  }

  clear(): void {
    super.clear();
    if (this.customClearAction) { this.customClearAction(); }
  }

  close(): void {
    super.close();
    this.dialogRef.close();
  }
}
