<div class="toolbar">
  <div class="brush-color">
    <div class="selected-color" [style.backgroundColor]="selectedBrushColor" (click)="toggleBrushColorDialog()"></div>
    <div *ngIf="showBrushColorPalette" class="brush-color-dialog">
      <div *ngFor="let color of ColorPalette | keyvalue" class="color-border" (click)="onColorSelect(color.value,'brush')">
        <div class="color" [style.backgroundColor]="color.value"></div>
      </div>
    </div>
  </div>

  <div class="brush-width">
    <img src="/assets/img/paint.png" (click)="toggleBrushWidthDialog()"  class="toolbar-icon" alt="paint">
    <div *ngIf="showBrushWidthDialog" class="brush-width-dialog">
      <input type="range" min="1" max="10" [value]="selectedBrushWidth" (change)="onSizeChange($event,'brush')">
    </div>
  </div>

  <img src="/assets/img/undo.png" (click)="onUndoStroke()" class="toolbar-icon" alt="undo">
  <img src="/assets/img/eraser.png" (click)="clearCanvas()"  class="toolbar-icon" alt="eraser">
  <img src="/assets/img/add-text.png" (click)="addText()" class="toolbar-icon"  alt="add-text">

  <div class="text-color">
    <div [style.color]="selectedTextColor" (click)="toggleTextColorDialog()" class="text-color-icon">A</div>
    <div *ngIf="showTextColorPalette" class="text-color-dialog">
      <div *ngFor="let color of ColorPalette | keyvalue" class="color-border" (click)="onColorSelect(color.value,'text')">
        <div class="color" [style.backgroundColor]="color.value"></div>
      </div>
    </div>
  </div>
  
  <div class="text-size">
    <img src="/assets/img/font-size.png" (click)="toggleTextSizeDialog()" class="toolbar-icon" alt="font-size">
    <div *ngIf="showTextSizeDialog" class="text-size-dialog">
      <input type="range" min="5" max="50" [value]="selectedTextSize" (change)="onSizeChange($event,'text')">
    </div>
  </div>
  
  <img src="/assets/img/trash.png" (click)="deleteText()" class="toolbar-icon" alt="trash">  
</div>