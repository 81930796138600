<div class="editor d-flex flex-column" [ngClass]="{'editor': deviceService.isMobile,'editor-web': !deviceService.isMobile}">
  <div class="crop-header">
    <img src="/assets/img/cross.svg" class="cross-icon" (click)="onCancel()" alt="cross">
    <span class="edit-text flex-fill">{{'events.upload-image.edit.header-text' | translate}}</span>
    <img src="/assets/img/tick.png" (click)="onFinishClick()" class="toolbar-icon" alt="tick">
  </div>

  <div #cropCanvas class="crop-canvas">
    <div id="initial-image">
      <img id="image-src" [src]="image" (load)="startCrop()">
    </div>
  </div>

  <div #canvasToolbar class="canvas-toolbar">
    <app-canvas-toolbar (emitNewText)="onNewTextAdd()"></app-canvas-toolbar>
  </div>

  <div #canvas class="canvas flex-fill">
    <div class="canvas-container" id="final-canvas" [style.height]="deviceService.isMobile ? '66%' : '100%'" (mouseup)="onPointerEnd()"
      (mousemove)="onPointerMove($event)">
        <app-canvas-panel [boundings]="canvasBoundings" [height]="canvasHeight" [width]="canvasWidth"></app-canvas-panel>

        <!-- Text Widgets -->
        <div *ngFor="let widget of textWidgets" [attr.id]="widget.id" [style.fontSize]="widget.size" [style.color]="widget.color"
        contenteditable="false" class="widget text flex-fill d-inline-block content-container"
        (touchstart)="onPointerClick($event, widget.id)" (touchmove)="onPointerMove($event)"
        (touchend)="onPointerEnd()" (mousedown)="onPointerClick($event, widget.id)" [innerHtml]="widget.text">
        </div>
    </div>
  </div>
</div>
