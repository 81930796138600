export class IntercomUser {
  user_id: string;
  email: string;
  name: string;
  phone: string;

  constructor(
    email: string = '',
    firstName: string = '',
    lastName: string = '',
    user_id: string = '',
    phone: string = '',
    ) {
    this.user_id = user_id;
    this.email = email;
    this.name = firstName + ' ' + lastName;
    this.phone = phone;
  }

}
