import { QueueAction } from './queue-action';
import { ActionType } from './sync-dto';

export class ErrorQueueAction {

  constructor(
    public id: string,
    public spaceId: string,
    public siteId: string,
    public action: QueueAction,
    public type: string,
    public actionType: ActionType,
    public errorCode?: string,
  ) {
  }
}
