import { Component, EventEmitter, Input , OnInit, Output } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable-footer',
  templateUrl: './datatable-footer.component.html',
  styleUrls: ['./datatable-footer.component.sass']
})
export class DatatableFooterComponent implements OnInit {

  @Input() dataLoading: boolean;
  @Input() totalEntries: number;
  @Input() table: DatatableComponent;
  @Input() curPage;
  @Input() pageSize;
  @Input() rowCount;
  @Input() itemPerPage: number = 15;
  @Input() isWebAppFilteredTableFooter: boolean = false;
  @Output() changeNbItemEvent = new EventEmitter<number>();

  limitOptions = [
    {
      key: '5',
      value: 5
    },
    {
      key: '10',
      value: 10
    },
    {
      key: '15',
      value: 15
    },
    {
      key: '30',
      value: 30
    },
    {
      key: '50',
      value: 50
    },
    {
      key: '100',
      value: 100
    }
  ];


  constructor() {
  }

  ngOnInit() {
  }

  sendNbItems() {
    this.changeNbItemEvent.emit(this.itemPerPage);
  }

}
