import { ModelElement } from './model-element';

export class Tag implements ModelElement {
  id: string;
  revision: string;
  name: string;
  siteId: string;

  constructor() {
    this.id = ModelElement.generateId();
    this.revision = '';
    this.name = '';
    this.siteId = 'null';
  }

  /**
   * Extract {@link Tag} from json
   * @param json given json
   * @param tag given destination Tag
   */
  public static toModel(json: any, tag: Tag) {
    tag.id = json.payload.id;
    tag.name = json.payload.name;
    tag.siteId = json.payload.siteId ? json.payload.siteId : 'null';
  }

  /**
   * Sort {@link Tag} by name
   * @param tag1 first tag to compare
   * @param tag2 second tag to compare
   */
  public static compareByName(tag1, tag2) {
    return tag1.name.localeCompare(tag2.name);
  }

  /**
   * Copy given {@link Tag} into destination {@link Tag}
   * @param destination given destination Tag
   * @param source given source Tag
   */
  public static copy(destination: Tag, source: Tag): void {
    destination.id = source.id;
    destination.revision = source.revision;
    destination.name = source.name;
    destination.siteId = source.siteId;
  }

  /** Get json from {@link Tag} */
  public toDTO() {
    return {
      id: this.id,
      name: this.name,
      siteId: this.siteId !== 'null' ? this.siteId : null
    };
  }

  public get label(): string {
    return this.name;
  }
}
