import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlGiverService } from './url-giver.service';
import { Currency } from '../models/utils/currency';
import { SpaceDatabase } from '../models/db/space-database';
import { SharedService } from './shared/shared.service';
import { DatabaseService } from './shared/database.service';
import { Logger } from './logger';
import { NGXLogger } from 'ngx-logger';

/**
 * Currencies service
 */
@Injectable()
export class CurrenciesService {

  /**
   * CurrenciesService Constructor
   * @param {HttpClient} https Client to create and receive querys
   */
  constructor(
    private databaseService: DatabaseService,
    private https: HttpClient,
    private logger: NGXLogger,
    ) {
   }

  /**
   * Get all currencies via our local currencies JSON file
   */
  getCurrencies(): Observable<JSON> {
    return this.https.get(`${UrlGiverService.ASSETS_URL}currencies/currencies.json`)
      .pipe(map((res: any) => res));
  }

  /**
   * Save list of currencies in the indexedDB
   * @param currencies Liste of currencies from the backend
   */
  saveCurrencies(currencies: Currency[]) {
    this.databaseService.getDB().open()
      .then(_ => {
        this.saveCurrenciesInDB(currencies);
      })
      .catch(err => {
        this.logger.error(`Error while opening of database while saving list of currencies: ${err.stack}`);
      });
  }

  /**
   * Save the given currencies in the indexedDB
   * @param currencies currencies to save
   */
  saveCurrenciesInDB(currencies: Currency[], db: SpaceDatabase = this.databaseService.getDB()) {
    return this.updateCurrenciesInDB(currencies, db);
  }

  /**
   * Update the given currencies in the indexedDB
   * @param currencies currencies to update
   */
  updateCurrenciesInDB(currencies: Currency[], db: SpaceDatabase = this.databaseService.getDB()) {
    return db.currency.bulkPut(currencies);
  }

}
