import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { Device } from '@ionic-native/device/ngx';

export interface DeviceData {
  manufacturer: string,
  model: string,
  platform: string,
  serial: string,
  uuid: string,
  version: string
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  isAndroid: boolean;
  isIos: boolean;
  isMobile: boolean;
  isMobileWeb: boolean;
  hasCordova: boolean;
  isTablet: boolean;

  constructor(private platform: Platform, private device: Device) {
    this.isAndroid = this.platform.is('android');
    this.isIos = this.platform.is('ios');
    this.isMobile = this.isAndroid || this.isIos;
    this.isMobileWeb = this.platform.is("mobileweb");
    this.hasCordova = this.platform.is('cordova');
    this.isTablet = !this.platform.is('desktop') && (this.platform.is('ipad') || this.platform.is('tablet'));
    Logger.info('Supported platforms (isMobile: ' + this.isMobile + '):', this.platform.platforms());
  }

  getPlatformType(): 'Android' | 'IOS' | 'Desktop' {
    if (this.isMobile) {
      return this.isAndroid ? 'Android' : 'IOS';
    } else {
      return 'Desktop';
    }
  }

  getVersion(): string {
    return this.device.version;
  }

  getPlatforms(): string[] {
    return this.platform.platforms();
  }

  getDeviceData(): DeviceData {
    let deviceData: DeviceData = {
      manufacturer: this.device.manufacturer,
      model: this.device.model,
      platform: this.device.platform,
      serial: this.device.serial,
      uuid: this.device.uuid,
      version: this.device.version
    }
    return deviceData;
  }
}
