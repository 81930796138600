import { Injectable } from '@angular/core';



@Injectable()
export class NormalisationService {

    /**
   * checkAssetName is use to normalize a name
   */
    public static checkAssetName(name: string): String {
        const newName = name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .replace('æ', 'ae').replace('œ', 'oe').replace('ĳ', 'ij').replace('ǉ', 'lj')
        .replace('ǌ', 'nj').replace('ǳ', 'dz').replace('ß', 's');
        return newName;
      }

}
