import { Injectable } from '@angular/core';
import { AbstractModelService } from '@services/abstract-model-service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { SpaceDatabase } from '../models/db/space-database';
import { Task, TASK_INDICATOR, TASK_STATUS } from '../models/task';
import { User } from '../models/user';
import { EventService } from './event.service';
import { DatabaseService } from './shared/database.service';
import { SharedService } from './shared/shared.service';
import { UserRole } from './user-rights/user-rights-dao.service';
import { UserRightsService } from './user-rights/user-rights.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserService } from '@services/user.service';
import { SiteUser } from '@models/site-user';
import { SubsetTask } from '@models/subset-task';
import { RelatedMinimalEvent } from '@models/event';
import { TaskRelatedEvent } from '@models/tasks-related-events';
import { SharedDataService } from './shared-data.service';
import { NGXLogger } from 'ngx-logger';
import { Author } from '@models/author';

@Injectable()
export class TaskService extends AbstractModelService<Task> {

  protected type = 'TaskNewApi';
  isTaskStatusOrIndicatorUpdated$ = new BehaviorSubject<boolean>(null);
  public readonly watchIsTaskStatusOrIndicatorUpdated: Observable<boolean>;

  constructor(
    private sharedService: SharedService,
    protected databaseService: DatabaseService,
    private userService: UserService,
    private sharedDataService: SharedDataService,
    private logger: NGXLogger,
  ) {
    super(
      databaseService,
    );

    // When an event is updated, we check if a task has to be updated also
    databaseService.watchDB().pipe(
      filter(db => db != null),
      switchMap(db => SpaceDatabase.hookTable(db.event)),
      filter(hookEvent => !!(hookEvent && hookEvent.object && hookEvent.object.task && hookEvent.object.task.taskId)),
      switchMap(hookEvent => this.getById(hookEvent.object.task.taskId))
    ).subscribe(async task => {
      if (task) {
        await this.checkAndUpdateTaskStates(task);
      }
    });

    // When a task is updated, we update states and progress
    databaseService.watchDB().pipe(
      filter(db => db != null),
      switchMap(db => SpaceDatabase.hookTable(db.task)),
      filter(hookEvent => hookEvent && hookEvent.object && !hookEvent.object.mostRecentEventId),
      switchMap(hookEvent => hookEvent.table.get(hookEvent.object.id))
    ).subscribe(async task => {
      if (task) {
        await this.checkAndUpdateTaskStates(task);
      }
    });

    this.watchIsTaskStatusOrIndicatorUpdated = this.isTaskStatusOrIndicatorUpdated$.asObservable();
  }

  public async checkAndUpdateTaskStates(task: Task): Promise<void> {
    const previousLastKnownProgress = task.lastKnownProgress;
    const previousSuspendedStatus = task.suspended;
    const previousStates = JSON.stringify(task.states);
    // If one of these values has changed then we have to update the task in indexedDB database
    if (previousLastKnownProgress !== task.lastKnownProgress
      || previousSuspendedStatus !== task.suspended
      || previousStates !== JSON.stringify(task.states)) {
      this.databaseService.getDB()._put(task, this.type, false);
    }
  }

  async updateTaskStatesIfMissing(task): Promise<void> {
    if (!task.states || task.states.length === 0) {
      await this.checkAndUpdateTaskStates(task);
    }
  }

  async checkAndUpdateTaskStatusAndIndicator(task: Task): Promise<void> {
    const previousLastKnownProgress = task.lastKnownProgress;
    const previousStatus = task.taskStatus;
    const previousIndicator = task.taskIndicator;
    // If one of these values has changed then we have to update the task in indexedDB database
    if (previousLastKnownProgress !== task.lastKnownProgress
      || previousStatus !== task.taskStatus
      || previousIndicator !== task.taskIndicator) {
      this.databaseService.getDB()._put(task, this.type, false);
    }
  }

  async updateTaskStatusAndIndicatorIfNeeded(task): Promise<void> {
    if (!task.taskStatus || task.taskStatus === 0 || !task.taskIndicator) {
      await this.checkAndUpdateTaskStatusAndIndicator(task);
    }
  }

  getEditionRights(user: User, task: Task): UserRole[] {
    if (task.isAssignedTo(user)) {
      return UserRightsService.USER_RIGHTS.site.task.manage.assigned;
    } else {
      return UserRightsService.USER_RIGHTS.site.task.manage.all;
    }
  }

  getApprovalRights(task: Task): UserRole[] {
    if(task.taskStatus !== TASK_STATUS.COMPLETED && task.taskStatus !== TASK_STATUS.CANCELLED) {
      return UserRightsService.USER_RIGHTS.site.task.approve.unlocked;
    } else {
      return UserRightsService.USER_RIGHTS.site.task.approve.locked;
    }
  }

  public async filterTasks(startdate?: Date, enddate?: Date): Promise<void> {
    const tasks = await this.databaseService.getSiteItems('task', this.sharedService.currentSiteId, startdate, enddate);
    this.itemSubject.next(tasks);
  }

  getTaskById(id: string): Promise<Task> {
    return this.databaseService.getItemById('task', id);
  }

  public getTaskUsersWithNames(task: Task, siteId: string): Observable<SiteUser[]> {
    return this.userService.getLocalSiteUsers(siteId).pipe(
      filter(users => !!users),
      tap(users => {
        for (let i = 0; i < task.users.length; i++) {
          const assignedUser = users.find(user => user.id === task.users[i].userId);
          if (assignedUser) {
            task.users[i].firstName = assignedUser.firstName;
            task.users[i].lastName = assignedUser.lastName;
          }
        }
      }),
    );
  }

  public getAllTasksInSite(): Promise<Task[]> {
    return this.databaseService.getSiteItems('task', this.sharedService.currentSiteId);
  }

  public async getTotalQuantityDone(task: Task): Promise<number> {
    let totalQuantityDone = 0;
    const events = await this.databaseService.getEventsFilteredByTaskId(this.sharedService.currentSiteId,task.id);
    events.forEach((event) => {
      totalQuantityDone += event.task.quantityDone
    });
    return totalQuantityDone;
  }

  public getTotalQuantityDoneForTask(linkedEvents: TaskRelatedEvent[]) {
    let totalQuantityDone = 0;
    linkedEvents.forEach((event) => {
      totalQuantityDone += event.quantityDone;
    });
    return totalQuantityDone;
  }

  public getSubsetTaskTotalQuantityDone(task: SubsetTask): number {
    let totalQuantityDone = 0;
    task.relatedMinimalEvents.forEach((event) => {
      totalQuantityDone += event.quantityDone;
    });
    return totalQuantityDone;
  }

  public setSubsetTaskStatusAndIndicator(task: SubsetTask): void {
    const mostRecentEvent = this.getLastEventForSubsetTask(task);
    let totalQuantityDone: number = null;
    if(task.plannedQuantity > 0) {
      totalQuantityDone = this.getSubsetTaskTotalQuantityDone(task);
    }
    task.updateTaskProgressAndState(mostRecentEvent, totalQuantityDone);
  }

  setSubsetTaskStatusAndIndicatorFromSubtasks(task: SubsetTask): void {
    let lastKnownProgress = this.getAverageTotalProgress(task.subtasks);
    task.updateTaskProgressAndStateFromSubtasks(lastKnownProgress);
  }

  getSubtaskProgress(task: SubsetTask): number {
    const mostRecentEvent = this.getLastEventForSubsetTask(task);
    let lastKnownProgress = 0;
    let totalQuantityDone: number = null;
    if(task.plannedQuantity > 0) {
      totalQuantityDone = this.getSubsetTaskTotalQuantityDone(task);
    }
    if (mostRecentEvent) {
      lastKnownProgress = mostRecentEvent.progress;
    }
    if (task.plannedQuantity > 0 && totalQuantityDone !== null) {
      lastKnownProgress = Math.floor((totalQuantityDone/task.plannedQuantity) * 100);
    }
    return lastKnownProgress;
  }

  getAverageTotalProgress(subtasks: SubsetTask[]): number {
    let totalProgress = 0;
    let numberOfSubtasks = subtasks.length;
    if(numberOfSubtasks === 0) {
      return 0;
    }
    for (let i = 0; i < numberOfSubtasks; i++) {
      totalProgress += this.getSubtaskProgress(subtasks[i]);
    }
    return Math.floor(totalProgress / numberOfSubtasks);
  }

  public getLastEventForSubsetTask(task: SubsetTask): RelatedMinimalEvent {
    let lastEvent: RelatedMinimalEvent = null;
    if(task.relatedMinimalEvents.length > 0) {
      task.relatedMinimalEvents.sort((a,b) => <any>a.startDatetime - <any>b.startDatetime);
      lastEvent = task.relatedMinimalEvents[task.relatedMinimalEvents.length -1];
    }
    return lastEvent;
  }

  /**
   * Set {@link Task} state to approved
   * @param _toApprove the given task
   */
   approveTask(_toApprove: Task, approvedBy: Author): Promise<Task> {
    _toApprove.taskStatus = TASK_STATUS.COMPLETED;
    _toApprove.isApproved = true;
    _toApprove.approvedOn = new Date();
    _toApprove.approvedBy = approvedBy;
    return this.sharedDataService.updateTask(_toApprove);
  }

    cancelTask(task: Task): Promise<Task> {
      task.taskStatus = TASK_STATUS.CANCELLED;
      task.isCancelled = true;
      return this.sharedDataService.updateTask(task);
    }

  makeTaskReadyToStart(task: Task): Promise<Task> {
    task.isReadyToStart = true;
    task.taskIndicator = TASK_INDICATOR.READY_TO_START;
    return this.sharedDataService.updateTask(task);
  }

  // watch DB to check if progress has been reported on a task (event linked with a task is created)
  public watchDBForUpdatedTask(): Observable<Task> {
    return this.databaseService.watchDB().pipe(
      filter(db => db != null),
      switchMap(db => SpaceDatabase.hookTable(db.task)),
      filter(hookEvent => hookEvent && hookEvent.object && !!hookEvent.object.mostRecentEventId),
      switchMap(hookEvent => hookEvent.table.get(hookEvent.object.id)),
      take(1)
    )
  }

  async fetchFaultyItemsCount(): Promise<number> {
    let falutyEventsCount = await this.databaseService.fetchFaultyItemsCount(this.sharedService.currentSiteId);
    return falutyEventsCount;
  }

  async getTaskData(taskId: string, setCurrentTaskInPreview: boolean = false): Promise<Task> {
    return this.sharedDataService.getTaskFromBackendById(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId, taskId).toPromise()
      .then(task => {
        let newTask = new Task();
        Task.singleTaskToModel(task, newTask);
        if(setCurrentTaskInPreview) {
          this.sharedDataService.updateCurrentTaskInPreview(newTask);
        }
        return newTask;
      })
      .catch((error) => {
        this.logger.error("Failed to get task from backend", error);
        throw error;
      });
  }

  getCurrentTaskInPreviewById(taskId: string): Promise<Task> {
    let currentTaskInPreview = this.sharedDataService.getCurrentTaskInPreview();
    if(currentTaskInPreview && currentTaskInPreview.id === taskId) {
      return new Promise((resolve) => {
        resolve(currentTaskInPreview);
      });
    } else {
      return this.getTaskData(taskId);
    }
  }

  getSortedLinkedEvents(relatedEvents: TaskRelatedEvent[]): TaskRelatedEvent[] {
    return relatedEvents.sort(((a,b) => <any>a.startDatetime - <any>b.startDatetime));
  }

  getMostRecentEvent(linkedEvents: TaskRelatedEvent[]): TaskRelatedEvent {
    if (linkedEvents.length > 0) {
      let mostRecentEvent = new TaskRelatedEvent();
      if (linkedEvents.length > 0) {
        mostRecentEvent = linkedEvents[linkedEvents.length-1];
      }
      return mostRecentEvent;
    }
    else {
      return null;
    }
  }
}
