export type EventsCount = {
  TotalDiaryEntries: number | string;
  Pending: number | string;
  Approved: number | string;
  Rejected: number | string;
  Draft: number | string;
}

export interface BEEventsCountResponse {
  pendingApprovalCount: number,
  approvedEventsCount: number,
  rejectedEventsCount: number,
  draftEventsCount: number
}

export const initialEventsCount: EventsCount = {
  TotalDiaryEntries: '-',
  Pending: '-',
  Approved: '-',
  Rejected: '-',
  Draft: '-'
}

export interface EventsCountSiteCard {
  [key: string]: EventsCount;
}
