import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalController} from '@ionic/angular';  
import { Router } from '@angular/router';
import { SharedDataService } from './shared-data.service';
import { SessionService } from './session.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Session } from '@models/session';
import { UrlGiverService } from './url-giver.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { LogoutService } from './logout/logout.service';
import { AppTypeService } from './app-type.service';
import { NGXLogger } from 'ngx-logger';
import { OnboardingMobileComponent } from 'app/modals/on-boarding/onboarding-mobile/onboarding-mobile.component';
import { OnBoardingWebappComponent } from 'app/modals/on-boarding/on-boarding-webapp/on-boarding-webapp.component';

interface UserOnboardingDetails {
  isWebAdminOnboardingDone?: boolean,
  isWebSiteDiaryOnboardingDone?: boolean,
  isWebSiteTaskOnboardingDone?: boolean,
  isMobileSiteDiaryOnboardingDone?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {

  private startAdminOnboarding$ = new BehaviorSubject<boolean>(false);
  private openHelpGuide$ = new BehaviorSubject<boolean>(false);
  public watchOpenHelpGuide: Observable<boolean>;
  public watchStartAdminOnboarding: Observable<boolean>;
  private adminOnboardingLastStep$ = new BehaviorSubject<boolean>(false);
  public watchAdminOnboardingLastStep: Observable<boolean>;
  dialogRef: MatDialogRef<OnBoardingWebappComponent>;
  private showAppSelectorOnboardingPopUp$ = new BehaviorSubject<boolean>(true);

  public get showAppSelectorOnboardingPopUp(): boolean {
    return this.showAppSelectorOnboardingPopUp$.getValue();
  }

  setShowAppSelectorOnboardingPopUp(value: boolean): void {
    this.showAppSelectorOnboardingPopUp$.next(value);
  }

constructor(
  private router: Router,
  private sharedDataService: SharedDataService,
  private sessionService: SessionService,
  private urlGiverService: UrlGiverService,
  private http: HttpClient,
  private logoutService: LogoutService,
  private dialog: MatDialog,
  private appTypeService: AppTypeService,
  private modalCtrl: ModalController,
  protected logger: NGXLogger,
) {
  this.watchStartAdminOnboarding = this.startAdminOnboarding$.asObservable();
  this.watchAdminOnboardingLastStep = this.adminOnboardingLastStep$.asObservable();
  this.watchOpenHelpGuide = this.openHelpGuide$.asObservable();
  this.logoutService.addLogoutCallback(() => {
    this.clearOnboardingDataOnLogout();
  });
 }

  async showWebAdminOnboarding(): Promise<void> {
    await this.goToAdministration();
  }

  async goToAdministration(): Promise<void> {
    this.sharedDataService.resetCurrentSite();
    await this.router.navigate(['/space', this.sharedDataService.currentSpaceId, 'admin-on-boarding']);
  }

  async closeWebAdminOnboarding(lastStep: boolean = false): Promise<void> {
		this.setStartAdminOnboarding(false);
    let session = this.sessionService.getSession();
    session.user.isWebAdminOnboardingDone = true;
    this.sessionService.setSession(session);
    await this.sendOnboardingStatusToBE(session);
    if(lastStep) {
      this.setAdminOnboardingLastStep(true);
    }
  }
  
  setStartAdminOnboarding(val: boolean): void {
		this.startAdminOnboarding$.next(val);
	}

  setOpenHelpGuide(val: boolean): void {
    this.openHelpGuide$.next(val);
  }

  setAdminOnboardingLastStep(val: boolean): void {
    this.adminOnboardingLastStep$.next(val);
  }

  async sendOnboardingStatusToBE(session: Session): Promise<UserOnboardingDetails> {
    let url = this.urlGiverService.giveUserDetailsAPIUrl();
    let userOnboardingDetails: UserOnboardingDetails = {};
    userOnboardingDetails.isWebAdminOnboardingDone = session.user.isWebAdminOnboardingDone;
    userOnboardingDetails.isWebSiteDiaryOnboardingDone = session.user.isWebSiteDiaryOnboardingDone;
    userOnboardingDetails.isWebSiteTaskOnboardingDone = session.user.isWebSiteTaskOnboardingDone;
    userOnboardingDetails.isMobileSiteDiaryOnboardingDone = session.user.isMobileSiteDiaryOnboardingDone;
    let userOnboardingDetailsObject = {
      'userOnboardingDetails' : userOnboardingDetails
    }
    return this.http.put<UserOnboardingDetails>(
      url,
      userOnboardingDetailsObject,
    ).toPromise();
  }

  async showMobileOnboardingModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: OnboardingMobileComponent,
      componentProps: {
        dismissCallback: () => {
          modal.dismiss();
          this.closeOnboardingInMobile();
        }
      },
    });
    return await modal.present();
  }

  async closeOnboardingInMobile(): Promise<void> {
    let session = this.sessionService.getSession();
    session.user.isMobileSiteDiaryOnboardingDone = true;
    this.sessionService.setSession(session);
    await this.sendOnboardingStatusToBE(session).catch(error => {
      this.logger.error('onboaridng.error.failed_to_update_status', session, error);
    });
  }

  clearOnboardingDataOnLogout(): void {
    this.startAdminOnboarding$.next(false);
    this.adminOnboardingLastStep$.next(false);
    this.showAppSelectorOnboardingPopUp$.next(true);
  }

  showOnBoarding(): void {
    this.dialogRef = this.dialog.open(OnBoardingWebappComponent, {
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.closeWebSiteDiaryOnboarding();
    });
  }

  async closeWebSiteDiaryOnboarding(): Promise<void> {
    let session = this.sessionService.getSession();
    if (this.appTypeService.getCurrentAppType() === 'tasks') {
      session.user.isWebSiteTaskOnboardingDone = true;
    } else {
      session.user.isWebSiteDiaryOnboardingDone = true;
    }
    this.sessionService.setSession(session);
    await this.sendOnboardingStatusToBE(session).catch(error => {
      this.logger.error('onboaridng.error.failed_to_update_status', session, error);
    });
  }
}
