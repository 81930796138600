import { Injectable } from '@angular/core';
import { DatabaseService } from '../shared/database.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AppAccess } from '@models/space-user';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserAppAccessDAO {

  private appAccessChanges$ = new BehaviorSubject<{ spaceId: string, appAccess: AppAccess } | null>(null);

  constructor(
    private databaseService: DatabaseService,
    private deviceService: DeviceService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService
  ) { }

  async getAppAccessBySpaceId(spaceId: string): Promise<AppAccess> {
    if (spaceId) {
      if (this.deviceService.isMobile) {
        return this.databaseService.getUserDB().then(
          userDb => userDb.appAccess.get(spaceId).then(appAccess => {
            if (appAccess) {
              return appAccess;
            } else {
              this.logger.error(`Unable to find the app access of the current user in space ${spaceId}`);
              throw new Error(`Unable to find the app access of the current user in space ${spaceId}`);
            }
          }));
      }
      else {
        return this.sharedDataService.getAppAccessBySpaceId(spaceId).then(appAccess => {
          if (appAccess) {
            return appAccess;
          }
          else {
            this.logger.error(`Unable to find the app access of the current user in space ${spaceId}`);
            throw new Error(`Unable to find the app access of the current user in space ${spaceId}`);
          }
        })
      }
    }
    return Promise.reject('Trying to find the app access of the current user in an undefined space.');
  }

  /**
   * Update the app access rights of the user in the DB
   * @param spaceId Current space id
   * @param siteDiaryAccess Site Diary access of the user
   * @param siteTaskAccess Site Task access of the user
   */
  addAppAccess(spaceId: string, siteDiaryAccess: boolean, siteTaskAccess: boolean) {
    if (this.deviceService.isMobile) {
      return this.databaseService.getUserDB().then(userDB => userDB.appAccess.put({siteDiaryAccess: siteDiaryAccess, siteTaskAccess: siteTaskAccess}, spaceId)).then(() => {
        this.appAccessChanges$.next({spaceId: spaceId, appAccess: {siteDiaryAccess: siteDiaryAccess, siteTaskAccess: siteTaskAccess}});
      });
    }
    else {
      this.appAccessChanges$.next({spaceId: spaceId, appAccess: {siteDiaryAccess: siteDiaryAccess, siteTaskAccess: siteTaskAccess}});  
      return this.sharedDataService.addAppAccess(spaceId, siteDiaryAccess, siteTaskAccess);
    }
    
  }

  watchAppAccessChanges(): Observable<{ spaceId: string, appAccess: AppAccess } | null> {
    return this.appAccessChanges$.asObservable();
  }
}
