import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset, AssetCategory, InformationCategory } from '@models/asset';
import { Contractor } from '@models/contractor';
import { CustomEventForm } from '@models/custom-event-form';
import { Event, EventStatus } from '@models/event';
import { Location } from '@models/location';
import { ModelElement } from '@models/model-element';
import { Site } from '@models/site';
import { SiteUser } from '@models/site-user';
import { Space } from '@models/space';
import { AppAccess } from '@models/space-user';
import { NetworkStatus } from '@models/synchronization/network-status';
import { ActionType, SyncDto } from '@models/synchronization/sync-dto';
import { Tag } from '@models/tag';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, flatMap, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IntercomService } from './intercom.service';
import { Logger } from './logger';
import { SpinnerService } from './spinner.service';
import { SyncResponse } from '@models/synchronization/sync-response';
import { TASK_STATUS, Task } from '@models/task';
import { SyncObjectType } from './synchronization/sync-object-factory.service';
import { WebappSyncService } from './synchronization/webapp-sync.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';
import { UserRightsDAO, UserRole } from './user-rights/user-rights-dao.service';
import { HttpStatus } from '@constants/http/http-status';
import { ServerError } from '@models/errors/server-error';
import { NoInternetConnectionError } from './synchronization/no-internet-connection-error';
import { ServerErrorDuplicateItem } from './synchronization/server-error-duplicate-item';
import { PostStatusService, PostStatusStates } from './post-status.service';
import { ServerErrorSameAssetName } from './synchronization/server-error-same-asset-name';
import { UserSettingsService } from './user-settings.service';
import { SessionService } from './session.service';
import { FileTransfertService } from './file-transfert.service';
import { ServerErrorEntityBeingUsed } from './synchronization/server-error-entity-being-used';
import { SERVER_ERROR_MESSAGES } from '@models/error-messages';
import { LogoutService } from './logout/logout.service';
import { SyncStatusService } from './synchronization/sync-status.service';
import { SubsetTask, TasksCount } from '@models/subset-task';
import { TaskRelatedEvent } from '@models/tasks-related-events';
import { FilteredTimelineTask } from '@models/filtered-timeline-tasks';
import { ServerErrorCannotLinkTasks } from './synchronization/server-error-cannot-link-task';
import { TaskDependencyErrors } from '@models/errors/task-dependency-error';
import { ServerErrorOnlyAdminsAndSuperviorsCanApproveTask } from './synchronization/server-error-only-admins-and-superviors-can-approve-task';
import { ActivatedRoute, Router } from '@angular/router';
import { BETasksCountResponse, TasksDetailedStatesCount, initialTasksCount, initialTasksDetailedStates } from '@models/tasks-count';
import { SyncException, SyncExceptionType } from './synchronization/sync-exception';
import { BEEventsCountResponse, EventsCount, initialEventsCount } from '@models/events-count';
import { EventsCreatedByFilterParameters } from '@models/utils/dashboard-events-created-by-filters';
import { EventsCreatedByCount } from 'app/home/dashboards/dashboard-created-by-graph/dashboard-created-by-graph.component';
import { TotalResourceUsageFilterParameters } from '@models/utils/dashboard-total-resource-usage-filters';
import { TotalResourceUsageCount } from 'app/home/dashboards/dashboard-resources-usage-graph/dashboard-resources-usage-graph.component';
import { TotalManhoursFilterParameters } from '@models/utils/dashboard-total-manhours-filters';
import { TotalInformationUsageCount, TotalInformationUsageFilterParameters } from '@models/utils/dashboard-total-information-usage-filters';
import { TotalResourceCostCount, TotalResourceCostFilterParameters } from '@models/utils/dashboard-total-resource-cost-filters';
import { RelatedTasksLinkingErrors } from '@models/errors/related-tasks-linking-error';
import { MinimalSubtask } from '@models/minimal-subtask';
import { LinkedTask } from '@models/linked-tasks';
import { BEDiaryCountByDateAndStatusResponse, BETaskCountByDateAndStatusResponse, CountByDateAndStatusFilterParameters } from '@models/utils/dashboard-count-by-date-and-status-filters';
import { SCurveGraphFilterParameters, TaskSCurveGraphBEResponse } from '@models/utils/dashboard-s-curve-graph';
import { BETaskTypesResponse, DashboardTaskTypesTableResponse, TaskTypesFilterParameters } from '@models/utils/dashboard-task-types-filters';
import { DashboardTaskAssigneesTableResponse, TaskAssigneesCount, TaskAssigneesGraphFilterParameters } from '@models/utils/dashboard-assignees-filter';
import { DashboardTaskStatesDonutGraphTableParameters, DashboardTaskStatesTableResponse, TaskStateFilterParameters } from '@models/utils/dashboard-task-states-filters';
import { DashboardResourceTableResponse, DashboardInformationTableResponse, DashboardCostTableResponse, DashboardCreatedByTableResponse, DashboardTotalManhoursTableResponse, DashboardCountByDateAndStatusTableResponse, AdminResourcesList, AdminInformationList } from '@models/utils/dashboard';
import { QuantityUnitsService } from './quantity-units.service';
import { DateFormat } from '@models/date-format';
import { DeviceService } from './device.service';

const SITE_SYNC_VERSION = '4';

enum USER_ROLES_STRINGS {
  ADMIN = 'Admin',
  SUPERVISOR = 'Supervisor'
}

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private collapseSidebar$ = new Subject<void>();
  private canSyncSpinnerActivate$ = new BehaviorSubject<boolean>(null);

  private spaces$ = new BehaviorSubject<Space[]>(null);
  private space$ = new BehaviorSubject<Space>(null);
  private appAccess$ = new BehaviorSubject<AppAccess[]>(null);
  private userSpaceRoles$ = new BehaviorSubject<UserRole[]>(null);
  private userSiteRoles$ = new BehaviorSubject<UserRole[]>(null);

  private spaceContractors$ = new BehaviorSubject<Contractor[]>([]);
  private spaceTags$ = new BehaviorSubject<Tag[]>([]);
  private spaceLocations$ = new BehaviorSubject<Location[]>([]);
  private spaceLabours$ = new BehaviorSubject<Asset[]>([]);
  private spaceEquipments$ = new BehaviorSubject<Asset[]>([]);
  private spaceMaterials$ = new BehaviorSubject<Asset[]>([]);
  private roleChanges$ = new BehaviorSubject<{ spaceId: string, siteId: string | null, role: string } | null>(null);

  private currentSpaceId$ = new BehaviorSubject<string | null>(null);
  public readonly watchSpaceId: Observable<string>;
  public readonly watchSpace: Observable<Space | null>;

  private currentSiteId$ = new BehaviorSubject<string | null>(null);
  public readonly watchSiteId: Observable<string>;
  public readonly watchSite: Observable<Site | null>;
  public readonly watchSpaceAndSiteIds: Observable<[string | null, string | null]>;
  public readonly watchAllSites: Observable<Site[] | null>;

  private siteEvents$ = new BehaviorSubject<Event[]>(null);
  private siteTasks$ = new BehaviorSubject<Task[]>(null);
  private siteAsset$ = new BehaviorSubject<Asset[]>([]);
  private siteTag$ = new BehaviorSubject<Tag[]>(null);
  private siteUsers$ = new BehaviorSubject<SiteUser[]>(null);
  private siteContractor$ = new BehaviorSubject<Contractor[]>(null);
  private siteLocation$ = new BehaviorSubject<Location[]>(null);
  private siteForm$ = new BehaviorSubject<CustomEventForm[]>(null);
  private siteItems$ = new BehaviorSubject<Site[]>(null);
  private siteSubsetTasks$ = new BehaviorSubject<SubsetTask[]>([]);
  private currentTaskInPreview$ = new BehaviorSubject<Task>(null);
  private siteFilteredTimelineTasks$ = new BehaviorSubject<FilteredTimelineTask[]>([]);
  private allSitesList$ = new BehaviorSubject<Site[]>([]);
  private getSpaceById$: Observable<Space>;
  private getAppAccess$: Observable<void>;
  private getAllBackendSites$: Observable<Site[]>;
  private currentSiteTasksCount$ = new BehaviorSubject<TasksCount>(initialTasksCount);
  private currentSiteEventsCount$ = new BehaviorSubject<EventsCount>(initialEventsCount);
  private currentSiteTasksDetailedStatesCount$ = new BehaviorSubject<TasksDetailedStatesCount>(initialTasksDetailedStates);
  private siteTaskImportDateFormat$ = new BehaviorSubject<DateFormat>(null);

  public readonly watchSiteTags: Observable<Tag[]>;
  public readonly watchSiteContractors: Observable<Contractor[]>;
  public readonly watchSiteLocations: Observable<Location[]>;
  public readonly watchSiteEvents: Observable<Event[]>;
  public readonly watchSiteAssets: Observable<Asset[]>;
  public readonly watchSiteSubsetTasks: Observable<SubsetTask[]>;
  public readonly watchSiteFilteredTimelineTasks: Observable<FilteredTimelineTask[]>;

  public readonly watchCanSyncSpinnerActivate: Observable<boolean>;
  public readonly watchCollapseSidebar: Observable<void>;
  public readonly watchSiteUsers: Observable<SiteUser[]>;
  public readonly watchCurrentTaskInPreview: Observable<Task>;
  public readonly watchCurrentSiteTasksCount: Observable<TasksCount>;
  public readonly watchSiteTasksDetailedStatesCount: Observable<TasksDetailedStatesCount>; // used in dashboard to populate task state cards
  public readonly watchCurrentSiteEventsCount: Observable<EventsCount>;
  public readonly watchSiteTaskImportDateFormat: Observable<DateFormat>;

  appAccess: AppAccess[] = [];
  userRoles: UserRole[] = [];

  constructor(
    private http: HttpClient,
    private urlGiverService: UrlGiverService,
    private intercomService: IntercomService,
    private logger: NGXLogger,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private webappSyncService: WebappSyncService,
    private postStatusService: PostStatusService,
    private userSettingsService: UserSettingsService,
    private sessionService: SessionService,
    private fileTransferService: FileTransfertService,
    private logoutService: LogoutService,
    private syncStatusService: SyncStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private quantityUnitsService: QuantityUnitsService,
    private deviceService: DeviceService,
  ) {
    this.watchSiteTags = this.siteTag$.asObservable();
    this.watchSiteContractors = this.siteContractor$.asObservable();
    this.watchSiteLocations = this.siteLocation$.asObservable();
    this.watchSiteEvents = this.siteEvents$.asObservable();
    this.watchSiteAssets = this.siteAsset$.asObservable();
    this.watchSiteSubsetTasks = this.siteSubsetTasks$.asObservable();
    this.watchSiteFilteredTimelineTasks = this.siteFilteredTimelineTasks$.asObservable();
    this.watchSiteUsers = this.siteUsers$.asObservable();
    this.watchCurrentTaskInPreview = this.currentTaskInPreview$.asObservable();
    this.watchAllSites = this.allSitesList$.asObservable();

    this.watchSpace = this.currentSpaceId$.pipe(
      flatMap(spaceId => {
        if (spaceId) {
          return this.getSpaceById(spaceId);
        } else {
          return of(null);
        }
      }),
    );
    this.watchSpaceId = this.currentSpaceId$.asObservable();
    this.watchSiteId = this.currentSiteId$.asObservable();
    this.watchSite = this.currentSiteId$.pipe(
      mergeMap(siteId => siteId ? this.getSiteById(siteId) : of(null))
    );
    this.watchSpaceAndSiteIds = combineLatest([this.watchSpaceId, this.watchSiteId]).pipe(
      distinctUntilChanged(([prevSpaceId, prevSiteId], [currentSpaceId, currentSiteId]) => {
        return prevSiteId === currentSiteId;
      })
    );

    this.logoutService.addLogoutCallback(() => {
      this.setSpaceAndSiteIdsToNull();
      this.clearDataOnLogout();
    });
    this.watchCollapseSidebar = this.collapseSidebar$.asObservable();
    this.watchCanSyncSpinnerActivate = this.canSyncSpinnerActivate$.asObservable();
    this.watchCurrentSiteTasksCount = this.currentSiteTasksCount$.asObservable();
    this.watchCurrentSiteEventsCount = this.currentSiteEventsCount$.asObservable();
    this.watchSiteTasksDetailedStatesCount = this.currentSiteTasksDetailedStatesCount$.asObservable();
    this.watchSiteTaskImportDateFormat = this.siteTaskImportDateFormat$.asObservable();

    if (!this.deviceService.isMobile) {
      this.fetchSupportedDateFormats();
    }
  }

  public async fetchQuantityUnits(): Promise<void> {
    return await this.quantityUnitsService.fetchQuantityUnits()
  }

  public async fetchSupportedDateFormats() {
    await NetworkStatus.waitForOnlineStatus().pipe(
      mergeMap(() =>
        this.http.get<DateFormat>(this.urlGiverService.giveSupportedDateFormatsUrl())
      )
    )
    .toPromise()
    .then((response) => {
      this.siteTaskImportDateFormat$.next({
        ...response
      });
    }).catch((error) => {
      this.logger.error('Unable to fetch supported date formats: ', error);
    });
  }

  get siteEvents(): BehaviorSubject<Event[]> {
    return this.siteEvents$;
  }

  public get currentSpaceId(): string {
    if (this.hasSpaceSelected) {
      return this.currentSpaceId$.value;
    } else {
      const userSettings = this.userSettingsService.get();
      if(userSettings.lastVisitedSpace) {
        return userSettings.lastVisitedSpace;
      }
      else {
        this.logger.error("No space found");
        throw new Error('No space');
      }
    }
  }

  public get hasSpaceSelected(): boolean {
    return this.currentSpaceId$.getValue() !== null;
  }

  public resetCurrentSpace(): void {
    this.resetAllSpaceData();
    this.resetCurrentSite();
    this.currentSpaceId$.next(null);
    this.clearUserRoles();
    this.clearUserAppAccess();
    this.getSpaceById$ = null;
    this.getAllBackendSites$ = null;
    this.getAppAccess$ = null;
  }

  public get currentSiteId(): string {
    if (this.hasSiteSelected) {
      return this.currentSiteId$.value;
    } else {
      const userSettings = this.userSettingsService.get();
      if(userSettings.lastVisitedSite) {
        return userSettings.lastVisitedSite;
      } else {
        this.logger.error("No site found");
        throw new Error('No site');
      }
    }
  }

  public get hasSiteSelected(): boolean {
    return this.currentSiteId$.getValue() !== null;
  }

  public resetCurrentSite(): void {
    this.resetAllSiteData();
    this.currentSiteId$.next(null);
  }

  public async setCurrentSiteById(siteId: string): Promise<void> {
    try {
      this.currentSiteId$.next(siteId);
    } catch (ex) {
      this.resetCurrentSite();
      this.logger.error("Invalid site id:", siteId);
      throw new Error('Invalid site id: ' + siteId);
    }
  }

  public async setCurrentSpaceById(spaceId: string): Promise<void> {
    try {
      this.logger.info('Selected space id: ', spaceId);
      this.currentSpaceId$.next(spaceId);
      const space = await this.getSpaceById(spaceId);
      if (space) {
        this.intercomService.updateCompanyFromSpace(space);
        return;
      }
    } catch (ex) {
    }
    this.resetCurrentSpace();
    this.logger.error("Invalid space id:", spaceId);
    throw new Error('Invalid space id: ' + spaceId);
  }

  updateSpaces(spaces: Space[]): void {
    this.spaces$.next(spaces);
  }

  getSpaces(): Space[] {
    let spaces: Space[];
    spaces = this.spaces$.getValue();
    return spaces;
  }

  async getSpaceById(spaceId: string): Promise<Space> {
    let currentSpace = new Space();
    let space = this.space$.getValue();
    if (space) {
      currentSpace = space;
      return currentSpace;
    }
    else {
      if (this.getSpaceById$) {
        return this.getSpaceBySpaceAPIObservable();
      }
      else {
        this.getSpaceById$ = this.getBackendSpace(spaceId);
        return this.getSpaceBySpaceAPIObservable();
      }
    }
  }

  getSpaceBySpaceAPIObservable(): Promise<Space> {
    let currentSpace = new Space();
    return this.getSpaceById$.toPromise()
      .then(space => {
        currentSpace = space;
        this.space$.next(space);
        this.roleChanges$.next({ spaceId: currentSpace.id, siteId: null, role: (<any>currentSpace).role });
        return currentSpace;
      });
  }

  async getNextNonNullSpace(): Promise<Space> {
    return await this.getSpaceById(this.currentSpaceId);
  }

  async getNextNonNullSite(): Promise<Site> {
    return await this.getSiteById(this.currentSiteId);
  }

  async getSiteById(siteId: string): Promise<Site> {
    let currentSite = new Site();
    let sites = this.allSitesList$.getValue();
    if (sites.length !== 0) {
      currentSite = sites.find(site => site.id === siteId);
      return currentSite;
    }
    else {
      if (this.getAllBackendSites$) {
        return this.getSiteByBackendSitesAPIObservable(siteId);
      }
      else {
        this.getAllBackendSites$ = this.getBackendAllSites();
        return this.getSiteByBackendSitesAPIObservable(siteId);
      }
    }
  }

  populateSite(): void {
    this.getAllBackendSites$ = this.getBackendAllSites();
    this.getAllBackendSites$.toPromise()
      .then(sites => {
        this.allSitesList$.next(sites);
      });
  }

  getSiteByBackendSitesAPIObservable(siteId: string): Promise<Site> {
    let currentSite = new Site();
    return this.getAllBackendSites$.toPromise()
      .then(sites => {
        this.allSitesList$.next(sites);
        currentSite = sites.find(site => site.id === siteId);
        return currentSite;
      });
  }

  addAppAccess(spaceId: string, siteDiaryAccess: boolean, siteTaskAccess: boolean): void {
    this.appAccess[spaceId] = {'siteDiaryAccess': siteDiaryAccess, 'siteTaskAccess': siteTaskAccess};
    this.updateAppAccess(this.appAccess);
  }

  updateAppAccess(appAccess: AppAccess[]): void {
    this.appAccess$.next(appAccess);
  }

  async getAppAccess(spaceId: string): Promise<AppAccess[]> {
    let userAppAccess = this.appAccess$.getValue();
    if (userAppAccess && userAppAccess[spaceId]) {
      return userAppAccess;
    }
    else {
      if (this.getAppAccess$) {
        return this.getAppAccessByAppAccessAPIObservable(spaceId);
      }
      else {
        this.getAppAccess$ = this.getBackendAppAccess();
        return this.getAppAccessByAppAccessAPIObservable(spaceId);
      }
    }
  }

  getAppAccessByAppAccessAPIObservable(spaceId: string): Promise<AppAccess[]> {
    return this.getAppAccess$.toPromise()
      .then(() => {
        return this.getAppAccess(spaceId);
      });
  }

  async getAppAccessBySpaceId(spaceId: string): Promise<AppAccess> {
    let appAccess = await this.getAppAccess(spaceId);
    return appAccess[spaceId];
  }

  addUserSpaceRole(spaceId: string, role: string): void {
    this.userRoles[spaceId] = role;
    this.updateUserSpaceRole(this.userRoles);
  }

  addUserSiteRole(siteId: string, role: string): void {
    this.userRoles[siteId] = role;
    this.updateUserSiteRole(this.userRoles);
  }

  updateUserSpaceRole(userSpaceRoles: UserRole[]): void {
    this.userSpaceRoles$.next(userSpaceRoles);
  }

  updateUserSiteRole(userSiteRoles: UserRole[]): void {
    this.userSiteRoles$.next(userSiteRoles);
  }

  async getUserSpaceRole(spaceId): Promise<UserRole[]> {
    let userSpaceRole = this.userSpaceRoles$.getValue();
    if (userSpaceRole && userSpaceRole[spaceId]) {
      return userSpaceRole;
    }
    else {
      return this.getSpaceById(spaceId).then(()=>{return this.getUserSpaceRole(spaceId)});
    }
  }

  getBackendSpace(spaceId): Observable<Space> {
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get(this.urlGiverService.giveSpaceMinimalAPIUrl(spaceId))),
        map(spaceDto => {
          const space = new Space();
          Space.toModel(spaceDto, space);
          this.addUserSpaceRole(space.id, (<any>spaceDto).role);
          return space;
        }),
        shareReplay());
  }

  async getUserSiteRoles(spaceId: string, siteId: string): Promise<UserRole[]> {
    let userSpaceRoles = this.userSpaceRoles$.value;
    if(userSpaceRoles && userSpaceRoles[spaceId] && userSpaceRoles[spaceId] === USER_ROLES_STRINGS.ADMIN) {
      this.userRoles[siteId] = USER_ROLES_STRINGS.SUPERVISOR;
      this.userSiteRoles$.next(this.userRoles);
    }
    let userSiteRoles = this.userSiteRoles$.getValue();
    if (userSiteRoles && userSiteRoles[siteId]) {
      return userSiteRoles;
    }
    else if(this.currentSpaceId && this.currentSiteId) {
      return NetworkStatus.waitForOnlineStatus().toPromise().then( () => {
        return this.fetchCurrentSiteUser().then(() => {return this.getUserSiteRoles(spaceId, siteId)});
      });
    } else {
      return [];
    }
  }

  fetchCurrentSiteUser(): Promise<void> {
    const url = this.urlGiverService.giveCurrentSiteUserAPIUrl(this.currentSpaceId, this.currentSiteId);
    return this.http.get<SiteUser>(url).pipe(
      map(user => {
        this.addUserSiteRole(this.currentSiteId, user.role);
      })
    ).toPromise();
  }

  async getSpaceUserRoleBySpaceId(spaceId: string): Promise<UserRole> {
    let spaceUserRoles = await this.getUserSpaceRole(spaceId);
    return spaceUserRoles[spaceId];
  }

  async getSiteUserRoleBySiteId(spaceId: string, siteId: string): Promise<UserRole> {
    let siteUserRoles = await this.getUserSiteRoles(spaceId, siteId);
    return siteUserRoles[siteId];
  }

  getBackendAppAccess(): Observable<void> {
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get(this.urlGiverService.giveAppAccessAPIUrl(this.currentSpaceId))),
        map(response => {
          this.addAppAccess(this.currentSpaceId, (<any>response).siteDiaryAccess, (<any>response).siteTaskAccess);
        }),
        shareReplay());
  }

  updateSpaceContractors(contractors: Contractor[]): void {
    this.spaceContractors$.next(contractors);
  }

  getSpaceContractors(): Observable<Contractor[]> {
    return this.spaceContractors$.asObservable();
  }

  updateSpaceTags(tags: Tag[]): void {
    this.spaceTags$.next(tags);
  }

  updateSpaceLocations(locations: Location[]): void {
    this.spaceLocations$.next(locations);
  }

  getSpaceTags(): Observable<Tag[]> {
    return this.spaceTags$.asObservable();
  }

  getSpaceLocations(): Observable<Location[]> {
    return this.spaceLocations$.asObservable();
  }

  updateSpaceAssets(assets: Asset[]): void {
    let labours = [];
    let equipments = [];
    let materials = [];
    for(let asset of assets) {
      switch(asset.category) {
        case AssetCategory.LABOURS: {
          labours.push(asset);
          break;
        }
        case AssetCategory.EQUIPMENTS: {
          equipments.push(asset);
          break;
        }
        case AssetCategory.MATERIALS: {
          materials.push(asset);
          break;
        }
      }
    }
    this.spaceLabours$.next(labours);
    this.spaceEquipments$.next(equipments);
    this.spaceMaterials$.next(materials);
  }

  getSpaceLabours(): Observable<Asset[]> {
    return this.spaceLabours$.asObservable();
  }

  getSpaceEquipments(): Observable<Asset[]> {
    return this.spaceEquipments$.asObservable();
  }

  getSpaceMaterials(): Observable<Asset[]> {
    return this.spaceMaterials$.asObservable();
  }

  getAllSites(): Site[] {
    return this.allSitesList$.getValue();
  }

  getSiteSequenceToken(): string {
    return this.webappSyncService.getSiteSequenceToken();
  }

  isSubsetApiAlreadyCalled(): boolean {
    return this.getSiteSequenceToken() ? true : false;
  }

  getBackendSites(): Promise<Site[]> {
    let sites = [];
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get<SyncResponse>(this.urlGiverService.giveSitesListSyncRequestUrl(this.currentSpaceId)))
      ).toPromise()
      .then((response) => {
        for (let json of response.items) {
          let site = new Site;
          Site.toModel(json, site);
          sites.push(site);
        }
        sites = Site.sortByName(sites);
        this.webappSyncService.updateSpaceSequenceToken(response.seq);
        return sites;
      })
      .catch((error) => {
        throw error;
      });
  }

  getBackendAllSites(): Observable<Site[]> {
    let sites = [];
    let params = new HttpParams()
    .set('getActiveSites', true)
    .set('getArchivedSites', true);
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
      mergeMap(() => this.http.get<SyncResponse>(this.urlGiverService.giveSitesListSyncRequestUrl(this.currentSpaceId), { params: params })),
      map(response => {
        for (let json of response.items) {
          let site = new Site;
          Site.toModel(json, site);
          sites.push(site);
        }
        sites = Site.sortByName(sites);
        this.webappSyncService.updateSpaceSequenceToken(response.seq);
        return sites;
        }
      ),
      shareReplay());
  }

  getBackendArchivedSites(): Promise<Site[]> {
    let sites = [];
    let params = new HttpParams()
    .set('getActiveSites', false)
    .set('getArchivedSites', true);
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get<SyncResponse>(this.urlGiverService.giveSitesListSyncRequestUrl(this.currentSpaceId), { params: params }))
      ).toPromise()
      .then((response) => {
        for (let json of response.items) {
          let site = new Site;
          Site.toModel(json, site);
          sites.push(site);
        }
        sites = Site.sortByName(sites);
        return sites;
      })
      .catch((error) => {
        throw error;
      });
  }

  watchRoleChangesInWebApp() {
    return this.roleChanges$.asObservable();
  }

  fetchSpaceAssets(): void {
    this.spinnerService.activate('rotating');
    let spaceAssetsOnlyUrl = this.urlGiverService.giveSpaceAssetsAPIUrl(this.currentSpaceId);
    this.http.get<SyncResponse>(spaceAssetsOnlyUrl).toPromise()
    .then((val)=>{
      let items = val.items;
      this.createItems(items);
      this.spinnerService.deactivate();
    })
      .catch(error => {
        this.logger.error(`Error fetching sync data for space resources`, error);
        this.spinnerService.deactivate();
        this.toasterService.showErrorToaster('sync.space.resources.error');
      });
  }


  getSpaceInformation(): void {
    this.spinnerService.activate('rotating');
    let spaceInformationsOnlyUrl = this.urlGiverService.giveSpaceInformationAPIUrl(this.currentSpaceId);
    this.http.get<SyncResponse>(spaceInformationsOnlyUrl).toPromise()
      .then((val)=>{
        let items = val.items;
        this.createItems(items);
        this.spinnerService.deactivate();
      })
      .catch(error => {
        this.logger.error(`Error fetching sync data for space information`, error);
        this.spinnerService.deactivate();
        this.toasterService.showErrorToaster('sync.space.informations.error');
      });
  }

  async fetchSiteAssets(spaceId: string): Promise<Asset[]> {
    this.spinnerService.activate('rotating');
    await this.callSubsetAPI(spaceId,this.currentSiteId);
    return this.siteAsset$.value;
  }

  createItems(items): void {
    let contractors = [];
    let tags = [];
    let locations = [];
    let assets = [];
    for(let item of items) {
      if (item.action === ActionType.CREATE) {
        switch(item.type) {
          case 'Contractor': {
            let contractor = new Contractor();
            Contractor.toModel(item, contractor);
            contractors.push(contractor);
            break;
          }
          case 'Tag': {
            let tag = new Tag();
            Tag.toModel(item, tag);
            tags.push(tag);
            break;
          }
          case 'Location': {
            let location = new Location();
            Location.toModel(item, location);
            locations.push(location);
            break;
          }
          case 'Asset': {
            let asset = new Asset();
            Asset.toModel(item, asset);
            assets.push(asset);
            break;
          }
        }
      }
    }
    this.updateSpaceContractors(contractors);
    this.updateSpaceTags(tags);
    this.updateSpaceLocations(locations);
    this.updateSpaceAssets(assets);
  }

  resetAllSpaceData(): void {
    this.spaceContractors$.next([]);
    this.spaceTags$.next([]);
    this.spaceLocations$.next([]);
    this.spaceLabours$.next([]);
    this.spaceEquipments$.next([]);
    this.spaceMaterials$.next([]);
    this.allSitesList$.next([]);
    this.space$.next(null);
    this.webappSyncService.updateSpaceSequenceToken(null);
  }

  checkRouteForDashboard(): boolean {
    return this.router.url.includes('dashboards') ||
           this.router.url.includes('site-diary-dashboards') ||
           this.router.url.includes('site-task-dashboards');
  }

  async callSubsetAPI(spaceId: string, siteId: string): Promise<void> {
    if(spaceId && siteId) {
      this.spinnerService.activate('rotating', this.checkRouteForDashboard() ? 'dashboard.admin.spinner_loading_message' : '');
      this.syncStatusService.communicateEventSubsetApiSyncStatus('in-progress');
      const finalItems = await this.webappSyncService.executeWebappSubsetApi(spaceId, siteId);
      this.updateInitialSyncItems(finalItems, true);
      this.syncStatusService.communicateEventSubsetApiSyncStatus('success');
      this.spinnerService.deactivate();
      return;
    }
  }

  async callTaskSubsetAPI(spaceId: string, siteId: string, isMemberViewingAllTasks: boolean = false): Promise<void> {
    if(spaceId && siteId) {
      this.spinnerService.activate('rotating', this.checkRouteForDashboard() ? 'dashboard.admin.spinner_loading_message' : '');
      this.fetchSiteTasksCount(isMemberViewingAllTasks);
      this.syncStatusService.communicateTaskSubsetApiSyncStatus('in-progress');
      const finalItems = await this.webappSyncService.executeWebappTaskSubsetApi(spaceId, siteId, isMemberViewingAllTasks);
      this.updateInitialSyncItems(finalItems, false);
      this.syncStatusService.communicateTaskSubsetApiSyncStatus('success');
      this.spinnerService.deactivate();
      return;
    }
  }

  updateInitialSyncItems(finalItems: Map<SyncObjectType, any>, isEventSubsetSync: boolean): void {
    if(finalItems) {
      finalItems.forEach((actions, type) => {
        switch (type) {
          case 'Asset':
            this.siteAsset$.next(actions.CREATE);
            break;
          case 'Contractor':
            this.siteContractor$.next(actions.CREATE);
            break;
          case 'Event':
            this.siteEvents$.next(actions.CREATE);
            break;
          case 'EventV4':
            this.siteEvents$.next(actions.CREATE);
            break;
          case 'Location':
            this.siteLocation$.next(actions.CREATE);
            break;
          case 'Site':
            this.siteItems$.next(actions.CREATE);
            break;
          case 'Tag':
            this.siteTag$.next(actions.CREATE);
            break;
          case 'Task':
            this.siteTasks$.next(actions.CREATE);
            break;
          case 'TaskNewApi':
            this.siteTasks$.next(actions.CREATE);
            break;
          case 'SiteForm':
            this.siteForm$.next(actions.CREATE);
            break;
          case 'SiteUser':
            const currentUser = this.sessionService.getCurrentUser();
            actions.CREATE.forEach(user => {
              if(user.id === currentUser.id) {
                this.addUserSiteRole(this.currentSiteId, user.role);
              }
            });
            break;
          case 'SubsetTask':
            if(!isEventSubsetSync) {
              this.siteSubsetTasks$.next(actions.CREATE);
            }
            break;
        }
      });
    }
  }

  getCurrentTaskInPreview(): Task {
    return this.currentTaskInPreview$.value;
  }

  updateCurrentTaskInPreview(task: Task): void {
    this.currentTaskInPreview$.next(task);
  }

  updateFilteredTimelineTasks(tasks: FilteredTimelineTask[]): void {
    this.siteFilteredTimelineTasks$.next(tasks);
  }

  getSiteTagById(id: string): Tag {
    return this.siteTag$.value.find((tag) => tag.id === id);
  }

  getSiteContractorById(id: string): Contractor {
    return this.siteContractor$.value.find((contractor) => contractor.id === id);
  }

  getSiteLocationById(id: string): Location {
    return this.siteLocation$.value.find((location) => location.id === id);
  }

  getCurrentSiteById(siteId: string): Site | null {
    if (this.siteItems$.value == null) {
      return null;
    }
    return this.siteItems$.value.find((site) => site.id === siteId);
  }

  private postSyncDtoAction(spaceId: string, siteId: string, syncDto: SyncDto): Promise<boolean> {
    this.spinnerService.activate('pulsating');
    let params = null;
    if(siteId) {
      params = new HttpParams()
      .set('version', SITE_SYNC_VERSION);
    }
    const url = siteId
      ? this.urlGiverService.giveSiteSyncQueueURL(spaceId, siteId)
      : this.urlGiverService.giveSpaceSyncQueueURL(spaceId);
    return this.http.post(url, syncDto, {observe: 'response', params: params}).pipe(
      map((response) => {
        this.spinnerService.deactivate();
        return true;}),
      catchError(error => {
        this.spinnerService.deactivate();
        if (error.status === HttpStatus.UNAUTHORIZED) {
          // We stop the queue if there's a 401
          this.logger.error("Queue execution stopped due to a 401 error");
          return of(false);
        } else if (error.status === HttpStatus.NOT_FOUND && syncDto.action === 'DELETE') {
          /*
          Exceptions for errors that do not have an impact on consistency between frontend and backend data
          cases : 404 on a delete action */
          // We should continue as if action succeeded
          return of(true);
        } else if (error.status === HttpStatus.FORBIDDEN && syncDto.action === 'CREATE' && error.error.error === 'access_denied') {
          //User tries to make a creation he is not allowed to
          this.toasterService.showErrorToaster('sync.permission.error');
          this.logger.error("User tried to create an entry he is not autorized to do. [syncDTO]:",syncDto);
          return of(true);
        } else if(error.status == 0 && !NetworkStatus.isOnline) {
          throw new NoInternetConnectionError();
        } else if (this.isDuplicateItemError(error)){
          this.logger.error('The server returned an error for a duplicate item with same identifier', false);
          throw new ServerErrorDuplicateItem();
        } else if (this.isSameAssetNameError(error,syncDto) && syncDto.action === 'CREATE') {
          this.postStatusService.communicateAssetPostStatus(PostStatusStates.error, syncDto.payload.id);
          this.toasterService.showWarningToaster('validation_rules.create.with.same.asset.name.webapp');
          this.logger.error('The server returned an error for creating an asset item with same name', false);
          throw new ServerErrorSameAssetName();
        } else if (this.isSameAssetNameError(error,syncDto) && syncDto.action === 'UPDATE') {
          this.postStatusService.communicateAssetPostStatus(PostStatusStates.error, syncDto.payload.id);
          this.toasterService.showWarningToaster('validation_rules.update.with.same.asset.name.webapp');
          this.logger.error('The server returned an error for updating an asset item with same name', false);
          throw new ServerErrorSameAssetName();
        } else if (this.isEntityBeingUsedError(error, syncDto) && syncDto.action === 'DELETE') {
          this.toasterService.showWarningToaster('entity.being.used.warning', {item: syncDto.payload.name});
          throw new ServerErrorEntityBeingUsed();
        } else if (this.checkForApprovedEventUpdation(syncDto, error)) {
          // The client has tried to update an approved event which is not allowed
          // This can happen due to the client retrying due to network issues
          // In this case instead of it being an treated as an error, silently accept it and update
          // with the event object sent by the server
          const syncException = new SyncException(SyncExceptionType.APPROVED_EVENT_MUTATION, error);
          throw syncException;
        } else if (syncDto.type === 'TaskNewApi') {
          throw this.handleTaskPostSyncErrors(error, syncDto);
        }
        else {
          this.toasterService.showErrorToaster('sync.failed-to-save.message');
          this.logger.error('The server returned an error in response to an action.', false, error, syncDto);
          throw new ServerError('The server returned an error in response to an action.', error, syncDto);
        }
      })
      ).toPromise();
  }

  processEditApprovedEventSyncException(syncException: SyncException) {
    const event: Event = new Event();
    const json = { payload: syncException.exception.error };
    Event.toModel(json, event);
    this.updateSiteEvents(ActionType.UPDATE, event);
    return event;
  }

  private checkForApprovedEventUpdation(syncDto: SyncDto, error: any): boolean {
    if(syncDto.type === 'EventV4' && (syncDto.action === ActionType.UPDATE) && error.status === HttpStatus.FORBIDDEN && error.error.status === EventStatus.APPROVED) {
      return true;
    }
    return false
  }

  private uploadImageAction(spaceId: string, siteId: string, syncDto: SyncDto): Promise<boolean> {
    const ImageUploadUrl = this.urlGiverService.giveImageUploadAPIUrl(spaceId, siteId, syncDto);
    return this.fileTransferService.uploadPicture(
      syncDto.payload.data,
      ImageUploadUrl).pipe(
        map(() => true),
        catchError(error => {
          this.toasterService.showErrorToaster('sync.upload_images.error.message');
          if (error.status === HttpStatus.UNAUTHORIZED) {
            this.logger.error('Uploading of images failed due to a 401', error);
            return of(false);
          } else if (error.status === HttpStatus.NOT_FOUND && syncDto.action === 'DELETE') {
            return of(true);
          } else if(error.status == 0 && !NetworkStatus.isOnline) {
            throw new NoInternetConnectionError();
          } else if (this.isDuplicateItemError(error)){
            this.logger.error('The server returned an error for a duplicate item with same identifier', false);
            throw new ServerErrorDuplicateItem();
          } else {
            this.logger.error('The server returned an error in response to an image upload.', false, error, syncDto.payload.id, syncDto.payload.eventId);
            throw new ServerError('The server returned an error in response to an image upload.', error, syncDto);
          }
        })).toPromise();
  }

  private isDuplicateItemError(error: any): boolean {
    const duplicateValueServerMessage = "A different object with the same identifier value was already associated with the session";
    if ("error" in error && "message" in error.error) {
      if (error.error.message.includes(duplicateValueServerMessage)) {
        return true;
      }
    }
    return false;
  }

  private isSameAssetNameError(error, syncDto): boolean {
    if (error.status === 409 && (this.isOfAssetOrInformationType(syncDto.type))) {
      return true;
    }
    return false;
  }

  private isEntityBeingUsedError(error, syncDto): boolean {
    if (error.status === 403 && error.error.message === SERVER_ERROR_MESSAGES.ENTITY_BEING_USED_ERROR && (this.isOfAssetOrInformationType(syncDto.type))) {
      return true;
    }
    return false;
  }

  private isUserNotAllowedToApproveTaskError(error, syncDto): boolean {
    if (error.status === 403 && error.error.errorCode === SERVER_ERROR_MESSAGES.USER_NOT_ALLOWED_TO_APPROVE_TASK && syncDto.type === 'TaskNewApi') {
      return true;
    }
    return false;
  }

  private isOfAssetOrInformationType(type: string): boolean {
    return (type === 'Asset') || (type === 'Tag') || (type === 'Contractor') || (type === 'Location');
  }

  addActionToPostSync<T extends ModelElement>(actionType: ActionType, item: T, type: string) {
    const action = new SyncDto(actionType, type, item.toDTO());
    if ((item.hasOwnProperty('siteId')) && item.siteId !== 'null') {
      action.attachToSite(item.siteId);
    }
    return this.postSyncDtoAction(this.currentSpaceId, action.siteId, action);
  }

  // get site action syncDto
  private getSiteActionSyncDto(actionType: ActionType, item: Site): SyncDto {
    const action = new SyncDto(actionType, 'Site', item.toDTO());
    return action;
  }

  async createAsset(asset: Asset): Promise<void> {
    this.addActionToPostSync(ActionType.CREATE, asset, "Asset")
    .then(()=> {
      if (asset.siteId === 'null') {
        this.updateCreatedSpaceAsset(asset);
      } else if (asset.siteId) {
        this.updateCreatedSiteAsset(asset);
      }
    });
  }

  async createManyAssets(assets: Asset[]): Promise<void> {
    if(assets && assets.length > 0) {
      assets.forEach((asset) => {
        this.addActionToPostSync(ActionType.CREATE, asset, "Asset")
        .then(()=> {
          if (asset.siteId === 'null') {
            this.updateCreatedSpaceAsset(asset);
          } else if (asset.siteId) {
            this.updateCreatedSiteAsset(asset);
          }
        });
      });
    }
  }

  async createManyInformations(informations: Contractor[] | Tag[], type: string): Promise<void> {
    if(informations && informations.length > 0) {
      informations.forEach((information) => {
        this.addActionToPostSync(ActionType.CREATE, information, type)
        .then(()=> {
          if (information.siteId === 'null') {
            this.updateCreatedSpaceInformation(information, type);
          } else if (information.siteId) {
            this.updateCreatedSiteInformation(information, type);
          }
        });
      });
    }
  }

  async updateAsset(asset: Asset): Promise<void> {
    return this.addActionToPostSync(ActionType.UPDATE, asset, "Asset")
    .then(() => {
      if (asset.siteId === 'null') {
        this.updateUpdatedSpaceAsset(asset);
      } else if (asset.siteId) {
        this.updateUpdatedSpaceAsset(asset);
      }
    });
  }

  async deleteAsset(asset: Asset): Promise<void> {
    return this.addActionToPostSync(ActionType.DELETE, asset, "Asset")
    .then(() => {
      if (asset.siteId === 'null') {
        this.updateDeletedSpaceAsset(asset);
      } else if (asset.siteId) {
        this.updateDeletedSpaceAsset(asset);
      }
    })
    .catch((error)=> {
      throw error;
    });
  }

  async createInformation(information, type: string): Promise<void> {
    this.addActionToPostSync(ActionType.CREATE, information, type)
    .then(() => {
      if (information.siteId === 'null') {
        this.updateCreatedSpaceInformation(information, type);
      } else if (information.siteId) {
        this.updateCreatedSiteInformation(information, type);
      }
    });
  }

  async updateInformation(information, type: string): Promise<void> {
    this.addActionToPostSync(ActionType.UPDATE, information, type)
    .then(() => {
      if (information.siteId === 'null') {
        this.updateUpdatedSpaceInformation(information, type);
      } else if (information.siteId) {
        this.updateUpdatedSiteInformation(information, type);
      }
    });
  }

  async deleteInformation(information, type: string): Promise<void> {
    return this.addActionToPostSync(ActionType.DELETE, information, type)
    .then(() => {
      if (information.siteId === 'null') {
        this.updateDeletedSpaceInformation(information, type);
      } else if (information.siteId) {
        this.updateDeletedSiteInformation(information, type);
      }
    })
    .catch((error) => {
      throw error;
    });
  }

  updateCreatedSpaceAsset(asset: Asset): void {
    let labours = this.spaceLabours$.getValue();
    let equipments = this.spaceEquipments$.getValue();
    let materials = this.spaceMaterials$.getValue();
      switch(asset.category) {
        case AssetCategory.LABOURS: {
          labours.push(asset);
          break;
        }
        case AssetCategory.EQUIPMENTS: {
          equipments.push(asset);
          break;
        }
        case AssetCategory.MATERIALS: {
          materials.push(asset);
          break;
        }
      }
    this.spaceLabours$.next(labours);
    this.spaceEquipments$.next(equipments);
    this.spaceMaterials$.next(materials);
  }

  updateUpdatedSpaceAsset(asset: Asset): void {
    switch(asset.category) {
      case AssetCategory.LABOURS: {
        this.spaceLabours$.next(
          this.spaceLabours$.value.map((spaceLabour: Asset) =>
          spaceLabour.id === asset.id ? asset : spaceLabour
          )
        );
        break;
      }
      case AssetCategory.EQUIPMENTS: {
        this.spaceEquipments$.next(
          this.spaceEquipments$.value.map((spaceEquipment: Asset) =>
          spaceEquipment.id === asset.id ? asset : spaceEquipment
          )
        );
        break;
      }
      case AssetCategory.MATERIALS: {
        this.spaceMaterials$.next(
          this.spaceMaterials$.value.map((spaceMaterial: Asset) =>
          spaceMaterial.id === asset.id ? asset : spaceMaterial
          )
        );
        break;
      }
    }
  }

  updateDeletedSpaceAsset(asset: Asset): void {
    switch(asset.category) {
      case AssetCategory.LABOURS: {
        this.spaceLabours$.next(
          this.spaceLabours$.value.filter((spaceLabour: Asset) => spaceLabour.id !== asset.id)
        );
        break;
      }
      case AssetCategory.EQUIPMENTS: {
        this.spaceEquipments$.next(
          this.spaceEquipments$.value.filter((spaceEquipment: Asset) => spaceEquipment.id !== asset.id)
        );
        break;
      }
      case AssetCategory.MATERIALS: {
        this.spaceMaterials$.next(
          this.spaceMaterials$.value.filter((spaceMaterial: Asset) => spaceMaterial.id !== asset.id)
        );
        break;
      }
    }
  }

  updateCreatedSiteAsset(asset: Asset): void {
    let siteAssets: Asset[] = this.siteAsset$.value;
    siteAssets.push(asset);
    this.siteAsset$.next(siteAssets);
  }

  updateUpdatedSiteAsset(asset: Asset): void {
    this.siteAsset$.next(
      this.siteAsset$.value.map((siteAsset: Asset) =>
      siteAsset.id === asset.id ? asset : siteAsset
      )
    );
  }

  updateDeletedSiteAsset(asset: Asset): void {
    this.siteAsset$.next(
      this.siteAsset$.value.filter((siteAsset: Asset) => siteAsset.id !== asset.id)
    );
  }

  updateSiteUsers(users: SiteUser[]): void {
    this.siteUsers$.next(users);
  }

  updateCreatedSpaceInformation(information: Tag | Contractor, type: string): void {
    let tags = this.spaceTags$.getValue();
    let locations = this.spaceLocations$.getValue();
    let contractors = this.spaceContractors$.getValue();
      switch(type) {
        case InformationCategory.TAGS: {
          tags.push(information);
          break;
        }
        case InformationCategory.CONTRACTORS: {
          contractors.push(information);
          break;
        }
        case InformationCategory.LOCATIONS: {
          locations.push(information);
          break;
        }
      }
    this.spaceTags$.next(tags);
    this.spaceContractors$.next(contractors);
    this.spaceLocations$.next(locations);
  }

  updateUpdatedSpaceInformation(information: Tag | Contractor, type: string): void {
    switch(type) {
      case InformationCategory.TAGS: {
        this.spaceTags$.next(
          this.spaceTags$.value.map((spaceTag: Tag) =>
          spaceTag.id === information.id ? information : spaceTag
          )
        );
        break;
      }
      case InformationCategory.CONTRACTORS: {
        this.spaceContractors$.next(
          this.spaceContractors$.value.map((spaceContractor: Contractor) =>
          spaceContractor.id === information.id ? information : spaceContractor
          )
        );
        break;
      }
      case InformationCategory.LOCATIONS: {
        this.spaceLocations$.next(
          this.spaceLocations$.value.map((spaceLocation: Location) =>
          spaceLocation.id === information.id ? information : spaceLocation
          )
        );
        break;
      }
    }
  }

  updateDeletedSpaceInformation(information: Tag | Contractor, type: string): void {
    switch(type) {
      case InformationCategory.TAGS: {
        this.spaceTags$.next(
          this.spaceTags$.value.filter((spaceTag: Tag) => spaceTag.id !== information.id)
        );
        break;
      }
      case InformationCategory.CONTRACTORS: {
        this.spaceContractors$.next(
          this.spaceContractors$.value.filter((spaceContractor: Asset) => spaceContractor.id !== information.id)
        );
        break;
      }
      case InformationCategory.LOCATIONS: {
        this.spaceLocations$.next(
          this.spaceLocations$.value.filter((spaceLocation: Location) => spaceLocation.id !== information.id)
        );
        break;
      }
    }
  }

  updateCreatedSiteInformation(information: Tag | Contractor | Location, type: string): void {
    let tags = this.siteTag$.value;
    let contractors = this.siteContractor$.value;
    let locations = this.siteLocation$.value;
    switch(type) {
      case InformationCategory.TAGS: {
        tags.push(information);
        break;
      }
      case InformationCategory.CONTRACTORS: {
        contractors.push(information);
        break;
      }
      case InformationCategory.LOCATIONS: {
        locations.push(information);
        break;
      }
    }
    this.siteTag$.next(tags);
    this.siteContractor$.next(contractors);
    this.siteLocation$.next(locations);
  }

  updateUpdatedSiteInformation(information: Tag | Contractor | Location, type: string): void {
    switch(type) {
      case InformationCategory.TAGS: {
        this.siteTag$.next(
          this.siteTag$.value.map((siteTag: Tag) =>
          siteTag.id === information.id ? information : siteTag
          )
        );
        break;
      }
      case InformationCategory.CONTRACTORS: {
        this.siteContractor$.next(
          this.siteContractor$.value.map((siteContractor: Contractor) =>
          siteContractor.id === information.id ? information : siteContractor
          )
        );
        break;
      }
      case InformationCategory.LOCATIONS: {
        this.siteLocation$.next(
          this.siteLocation$.value.map((siteLocation: Location) =>
          siteLocation.id === information.id ? information : siteLocation
          )
        );
        break;
      }
    }
  }

  updateDeletedSiteInformation(information: Tag | Contractor | Location, type: string): void {
    switch(type) {
      case InformationCategory.TAGS: {
        this.siteTag$.next(
          this.siteTag$.value.filter((siteTag: Tag) => siteTag.id !== information.id)
        );
        break;
      }
      case InformationCategory.CONTRACTORS: {
        this.siteContractor$.next(
          this.siteContractor$.value.filter((siteContractor: Contractor) => siteContractor.id !== information.id)
        );
        break;
      }
      case InformationCategory.LOCATIONS: {
        this.siteLocation$.next(
          this.siteLocation$.value.filter((siteLocation: Location) => siteLocation.id !== information.id)
        );
        break;
      }
    }
  }

  getEventFromBackendById(spaceId: string, siteId: string, eventId: string): Observable<Event> {
    const url = this.urlGiverService.giveSingleEventByIdAPI(spaceId, siteId, eventId);
    return this.http.get<Event>(url);
  }

  getTaskFromBackendById(spaceId: string, siteId: string, taskId: string): Observable<Task> {
    const url = this.urlGiverService.giveSingleTaskByIdAPI(spaceId, siteId, taskId);
    let currentDate = new Date().getTime();
    let params = new HttpParams()
    .set('currentDate', currentDate);
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get<Task>(url, {params: params})),
        map(response => {
          return response
        }),
      );
  }

  getEventById(eventId: string): Event {
    if (this.siteEvents$.value === null) {
      return null;
    }
    return this.siteEvents$.value.find((event) => event.id === eventId);
  }

  async createEvent(event: Event): Promise<Event> {
    return this.addActionToPostSync(ActionType.CREATE, event, 'EventV4')
      .then(() => {
        this.updateSiteEvents(ActionType.CREATE, event);
        if (event.task !== null && event.task.taskId !== "") {
          this.reportEventOnTask(ActionType.CREATE, event);
        }
        return event;
      });
  }

  async updateEvent(event: Event): Promise<Event> {
    return this.addActionToPostSync(ActionType.UPDATE, event, 'EventV4')
      .then(() => {
        this.updateSiteEvents(ActionType.UPDATE, event);
        if (event.task !== null && event.task.taskId !== "") {
          this.reportEventOnTask(ActionType.UPDATE, event);
        }
        return event;
      });
  }

  async deleteEvent(event: Event): Promise<Event> {
    return this.addActionToPostSync(ActionType.DELETE, event, 'EventV4')
      .then(() => {
        this.updateSiteEvents(ActionType.DELETE, event);
        if (event.task !== null && event.task.taskId !== "") {
          this.reportEventOnTask(ActionType.DELETE, event);
        }
        return event;
      });
  }

  async createTask(task: Task): Promise<Task> {
    return this.addActionToPostSync(ActionType.CREATE, task, 'TaskNewApi')
      .then(() => {
        this.updateSiteTasks(ActionType.CREATE, task);
        return task;
      });
  }

  async updateTask(task: Task): Promise<Task> {
    return this.addActionToPostSync(ActionType.UPDATE, task, 'TaskNewApi')
      .then(() => {
        this.updateCurrentTaskInPreviewAfterSync(task.id);
        this.updateSiteTasks(ActionType.UPDATE, task);
        return task;
      });
  }

  async deleteTask(task: Task): Promise<Task> {
    return this.addActionToPostSync(ActionType.DELETE, task, 'TaskNewApi')
      .then(() => {
        this.updateSiteTasks(ActionType.DELETE, task);
        return task;
      });
  }

  updateSiteEvents(actionType: ActionType, event): void {
    let events = this.siteEvents$.value;
    switch (actionType) {
      case ActionType.CREATE:
        events.push(event);
        this.siteEvents$.next(events);
        break;
      case ActionType.UPDATE:
      this.siteEvents$.next(events.map((_event) =>
        _event.id === event.id ? event : _event));
      break;
      case ActionType.DELETE:
      this.siteEvents$.next(events.filter((_event) => _event.id !== event.id));
      break;
    }
  }

  getCustomFormById(id: string): CustomEventForm {
    return this.siteForm$.value.find((siteForm) => siteForm.id === id);
  }

  // update preview task in site task list and in timeline view
  updateSiteTasks(actionType: ActionType, _task: Task): void {
    let tasks = this.siteSubsetTasks$.value;
    let timelineFilteredTasks = this.siteFilteredTimelineTasks$.value;
    let task = new SubsetTask();
    let timelineFilteredTask = new FilteredTimelineTask();
    SubsetTask.taskToSubsetTaskModel(_task, task);
    FilteredTimelineTask.taskToTimelineFilteredTaskModel(_task, timelineFilteredTask);
    // TODO: Check here for updating minimal subtasks tasks
    switch (actionType) {
      case ActionType.CREATE:
        if(this.isTaskAlreadyPresent(tasks, task)) {
          let existingTask = tasks.find((_existingTask) => _existingTask.id === task.id);
          if(existingTask.taskNumber) {
            task.taskNumber = existingTask.taskNumber;
          }
          tasks = tasks.map((subsetTask) => {
            return task.id === subsetTask.id ? task : subsetTask;
          });
        } else {
          tasks.push(task);
        }
        if(this.isTaskAlreadyPresent(timelineFilteredTasks, task)) {
          let existingTask = timelineFilteredTasks.find((_existingTask) => _existingTask.id === task.id);
          if(existingTask.taskNumber) {
            timelineFilteredTask.taskNumber = existingTask.taskNumber;
          }
          timelineFilteredTasks = timelineFilteredTasks.map((_timelineFilteredTask) => {
            return timelineFilteredTask.id === _timelineFilteredTask.id ? timelineFilteredTask : _timelineFilteredTask;
          });
        } else {
          timelineFilteredTasks.push(timelineFilteredTask);
        }
        this.siteSubsetTasks$.next(tasks);
        this.siteFilteredTimelineTasks$.next(timelineFilteredTasks);
        break;
      case ActionType.UPDATE:
      this.siteSubsetTasks$.next(tasks.map((_task) =>
        _task.id === task.id ? task : _task));
      this.siteFilteredTimelineTasks$.next(timelineFilteredTasks.map((_task) =>
        _task.id === timelineFilteredTask.id ? timelineFilteredTask : _task));
      break;
      case ActionType.DELETE:
      this.siteSubsetTasks$.next(tasks.filter((_task) => _task.id !== task.id));
      this.siteFilteredTimelineTasks$.next(timelineFilteredTasks.filter((_task) => _task.id !== timelineFilteredTask.id));
      break;
    }
  }

  isTaskAlreadyPresent(tasks: SubsetTask[] | FilteredTimelineTask[], newTask: SubsetTask): boolean {
    return tasks.some((task) => task.id === newTask.id);
  }

  getCurrentActiveCustomForm(): CustomEventForm {
    return this.siteForm$.value.find((siteForm) => siteForm.isSelected === true);
  }

  getTotalQuantityDoneForTask(taskId: string): number {
    let totalQuantityDone = 0;
    const events = this.siteEvents$.value.filter((_event) => _event.task.taskId === taskId);
    events.forEach((event) => {
      totalQuantityDone += event.task.quantityDone
    });
    return totalQuantityDone;
  }

  uploadImage(imageDTO: SyncDto): Promise<boolean> {
    return this.uploadImageAction(this.currentSpaceId, this.currentSiteId, imageDTO);
  }

  resetAllSiteData(): void {
    this.siteAsset$.next([]);
    this.siteContractor$.next(null);
    this.siteEvents$.next(null);
    this.siteForm$.next(null);
    this.siteLocation$.next(null);
    this.siteItems$.next(null);
    this.siteTag$.next(null);
    this.siteTasks$.next(null);
    this.siteUsers$.next(null);
    this.siteSubsetTasks$.next([]);
    this.currentTaskInPreview$.next(null);
    this.siteFilteredTimelineTasks$.next([]);
    this.webappSyncService.updateSiteSequenceToken(null);
    this.updateCurrentTaskInPreview(null);
    this.currentSiteTasksCount$.next(initialTasksCount);
  }

  clearDataOnLogout(): void {
    this.clearUserRoles();
    this.clearUserAppAccess();
    this.resetAllSiteData();
    this.resetAllSpaceData();
  }

  clearUserRoles(): void {
    this.userRoles = [];
    this.userSiteRoles$.next(null);
    this.userSpaceRoles$.next(null);
  }

  clearUserAppAccess(): void {
    this.appAccess = [];
    this.appAccess$.next(null);
  }

  setSpaceAndSiteIdsToNull(): void {
    this.currentSpaceId$.next(null);
    this.currentSiteId$.next(null);
  }

  async updateSite(site: Site) : Promise<boolean> {
    let syncDto : SyncDto =  this.getSiteActionSyncDto(ActionType.UPDATE, site);
    return await this.performSiteEventAction(site,syncDto);
  }

  async createSite(site: Site) : Promise<boolean> {
    let syncDto : SyncDto =  this.getSiteActionSyncDto(ActionType.CREATE, site);
    return await this.performSiteEventAction(site,syncDto);
  }

  async deleteSite(site: Site) : Promise<boolean>{
    let syncDto : SyncDto =  this.getSiteActionSyncDto(ActionType.DELETE, site);
    return await this.performSiteEventAction(site,syncDto);
  }

  private async performSiteEventAction(site: Site, syncDto: SyncDto) : Promise<boolean> {
    this.spinnerService.activate('rotating');
    const actionSucceeded = await this.postSyncDtoAction(this.currentSpaceId, null, syncDto);
    this.spinnerService.deactivate();
    if(actionSucceeded) {
      if(syncDto.action !== ActionType.DELETE) {
        this.setCurrentSiteById(site.id);
      }
      let sites = this.allSitesList$.getValue();
      if(syncDto.action === ActionType.CREATE) {
        sites.push(site);
      } else if(syncDto.action === ActionType.UPDATE) {
        sites = sites.map((_site) => _site.id === site.id ? site : _site);
      } else if(syncDto.action === ActionType.DELETE) {
        sites = sites.filter((_site) => _site.id !== site.id);
      }
      this.allSitesList$.next(sites);
    }
    return actionSucceeded;
  }

  executeSynchronization(spaceId: string, siteId: string = null): void {
    let syncUrl;
    if (siteId) {
      syncUrl = this.urlGiverService.giveSiteSyncAPIUrl(spaceId, siteId);
    } else {
      syncUrl = this.urlGiverService.giveSpaceSyncAPIUrl(spaceId);
    }
    this.sendSyncRequest(spaceId, siteId, syncUrl);
  }

  async sendSyncRequest(spaceId: string, siteId: string, syncUrl: string) {
    const incrementalSyncItems = await this.webappSyncService.callIncrementalSyncApi(spaceId, siteId, syncUrl);
    if(siteId) {
      this.handleIncrementalSiteSyncItems(incrementalSyncItems);
    } else {
      this.handleIncrementalSpaceSyncItems(incrementalSyncItems);
    }
    return;
  }

  handleIncrementalSiteSyncItems(incrementalSyncItems: Map<SyncObjectType, any>): void {
    if(incrementalSyncItems) {
      let currentTaskInPreview = this.currentTaskInPreview$.value;
      let navigateToSiteSelectScreen = false;
      let triggerCheckForSiteArchivalStatus = false;
      incrementalSyncItems.forEach((actions, type) => {
        switch (type) {
          case 'Asset':
            let siteAssets = this.siteAsset$.value;
            actions.CREATE.forEach(action => {
              if(siteAssets.find((siteAsset) => siteAsset.id === action.id)) {
                siteAssets = siteAssets.map((siteAsset) => {
                  return siteAsset.id === action.id ? action : siteAsset;
                });
              } else {
                siteAssets.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              siteAssets = siteAssets.map((siteAsset) => {
                return siteAsset.id === action.id ? action : siteAsset;
              });
            });
            actions.DELETE.forEach((action) => {
              siteAssets = siteAssets.filter((siteAsset) => siteAsset.id !== action.id);
            });
            this.siteAsset$.next(siteAssets);
            break;
          case 'Contractor':
            let siteContractors = this.siteContractor$.value;
            actions.CREATE.forEach(action => {
              if(siteContractors.find((siteContractor) => siteContractor.id === action.id)) {
                siteContractors = siteContractors.map((siteContractor) => {
                  return siteContractor.id === action.id ? action : siteContractor;
                });
              } else {
                siteContractors.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              siteContractors = siteContractors.map((siteContractor) => {
                return siteContractor.id === action.id ? action : siteContractor;
              });
            });
            actions.DELETE.forEach((action) => {
              siteContractors = siteContractors.filter((siteContractor) => siteContractor.id !== action.id);
            });
            this.siteContractor$.next(siteContractors);
            break;
          case 'EventV4':
            if(actions.CREATE.length > 0 || actions.UPDATE.length > 0 || actions.DELETE.length > 0) {
              this.fetchSiteEventsCount().catch((error) => {});
            }
            let siteEvents = this.siteEvents$.value;
            actions.CREATE.forEach(action => {
              if(siteEvents.find((siteEvent) => siteEvent.id === action.id)) {
                siteEvents = siteEvents.map((siteEvent) => {
                  return siteEvent.id === action.id ? action : siteEvent;
                });
              } else {
                siteEvents.push(action);
              }
              if(action.task.taskId.length > 0) {
                this.updateSubsetTaskWithRelatedMinimalEvent(action, ActionType.CREATE);
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.id === action.task.taskId) {
                  this.updateTaskWithReportedProgress(this.currentTaskInPreview$.value, action, true);
                }
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.minimalSubtasks.find(subtask => subtask.id === action.task.taskId)) {
                  this.updateSubtasksInPreviewWithReportedProgress(this.currentTaskInPreview$.value, action);
                }
              }
            });
            actions.UPDATE.forEach((action) => {
              siteEvents = siteEvents.map((siteEvent) => {
                return siteEvent.id === action.id ? action : siteEvent;
              });
              if(action.task.taskId.length > 0) {
                this.updateSubsetTaskWithRelatedMinimalEvent(action, ActionType.UPDATE);
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.id === action.task.taskId) {
                  this.updateTaskWithReportedProgress(this.currentTaskInPreview$.value, action, false);
                }
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.minimalSubtasks.find(subtask => subtask.id === action.task.taskId)) {
                  this.updateSubtasksInPreviewWithReportedProgress(this.currentTaskInPreview$.value, action);
                }
              }
            });
            actions.DELETE.forEach((action) => {
              siteEvents = siteEvents.filter((siteEvent) => siteEvent.id !== action.id);
              if(action.task.taskId.length > 0) {
                this.updateSubsetTaskWithRelatedMinimalEvent(action, ActionType.DELETE);
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.id === action.task.taskId) {
                  this.updateTaskWithDeletedProgress(this.currentTaskInPreview$.value, action);
                }
                if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.minimalSubtasks.find(subtask => subtask.id === action.task.taskId)) {
                  this.updateSubtasksInPreviewWithReportedProgress(this.currentTaskInPreview$.value, action);
                }
              }
            });
            this.siteEvents$.next(siteEvents);
            break;
          case 'Location':
            let siteLocations = this.siteLocation$.value;
            actions.CREATE.forEach(action => {
              if(siteLocations.find((siteLocation) => siteLocation.id === action.id)) {
                siteLocations = siteLocations.map((siteLocation) => {
                  return siteLocation.id === action.id ? action : siteLocation;
                });
              } else {
                siteLocations.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              siteLocations = siteLocations.map((siteLocation) => {
                return siteLocation.id === action.id ? action : siteLocation;
              });
            });
            actions.DELETE.forEach((action) => {
              siteLocations = siteLocations.filter((siteLocation) => siteLocation.id !== action.id);
            });
            this.siteLocation$.next(siteLocations);
            break;
          case 'Site':
            let sites = this.allSitesList$.value;
            actions.CREATE.forEach((action) => {
              if(sites.find((site) => site.id === action.id)) {
                sites = sites.map((site) => {
                  return site.id === action.id ? action : site;
                });
              } else {
                sites.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              if(sites.find((site) => site.id === action.id)) {
                sites = sites.map((site) => {
                  return site.id === action.id ? action : site;
                });
              } else {
                sites.push(action);
              }
              // setting value to trigger check for site archival status
              if (this.currentSiteId === action.id) {
                triggerCheckForSiteArchivalStatus = true;
                if(action.isArchived) {
                  navigateToSiteSelectScreen = true;
                }
              }
            });
            this.allSitesList$.next(sites);
            if(triggerCheckForSiteArchivalStatus) {
              this.currentSiteId$.next(this.currentSiteId);
            }
            break;
          case 'Tag':
            let siteTags = this.siteTag$.value;
            actions.CREATE.forEach(action => {
              if(siteTags.find((siteTag) => siteTag.id === action.id)) {
                siteTags = siteTags.map((siteTag) => {
                  return siteTag.id === action.id ? action : siteTag;
                });
              } else {
                siteTags.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              siteTags = siteTags.map((siteTag) => {
                return siteTag.id === action.id ? action : siteTag;
              });
            });
            actions.DELETE.forEach((action) => {
              siteTags = siteTags.filter((siteTag) => siteTag.id !== action.id);
            });
            this.siteTag$.next(siteTags);
            break;
          case 'TaskNewApi':
            if(actions.CREATE.length > 0 || actions.UPDATE.length > 0 || actions.DELETE.length > 0) {
              this.fetchSiteTasksCount();
            }
            let siteSubsetTasks = this.siteSubsetTasks$.value;
            let siteFilteredTimelineTasks = this.siteFilteredTimelineTasks$.value;
            actions.CREATE.forEach(action => {
              let subsetTask = new SubsetTask();
              SubsetTask.taskToSubsetTaskModel(action, subsetTask);
              if(siteSubsetTasks.find((siteTask) => siteTask.id === subsetTask.id)) {
                siteSubsetTasks = siteSubsetTasks.map((siteTask) => {
                  return siteTask.id === subsetTask.id ? subsetTask : siteTask;
                });
              } else {
                siteSubsetTasks.push(subsetTask);
              }
              siteFilteredTimelineTasks = this.updateFilteredTimelineTasksWithSyncedTask(action, siteFilteredTimelineTasks);
            });
            actions.UPDATE.forEach((action) => {
              let subsetTask = new SubsetTask();
              SubsetTask.taskToSubsetTaskModel(action, subsetTask);
              siteSubsetTasks = siteSubsetTasks.map((siteTask) => {
                 if(siteTask.id === subsetTask.id) {
                  subsetTask.relatedMinimalEvents = siteTask.relatedMinimalEvents;
                  return subsetTask;
                 } else {
                  return siteTask;
                 }
              });
              if(!siteSubsetTasks.some((siteTask) => siteTask.id === subsetTask.id)) {
                siteSubsetTasks.push(subsetTask);
              }
              siteFilteredTimelineTasks = this.updateFilteredTimelineTasksWithSyncedTask(action, siteFilteredTimelineTasks);
            });
            actions.UPDATE.forEach(async (action) => {
              if(this.currentTaskInPreview$.value && this.currentTaskInPreview$.value.id === action.id) {
                action.relatedEvents = this.currentTaskInPreview$.value.relatedEvents;
                let linkedParentTask: LinkedTask = null;
                let linkedMinimalSubtasks: MinimalSubtask[] = [];
                let linkedPredecessors = [];
                let linkedSuccessors = [];
                let userRole = await this.getSiteUserRoleBySiteId(this.currentSpaceId, this.currentSiteId);
                if(siteSubsetTasks.find(task => task.id === action.parentTaskId)) {
                  linkedParentTask = LinkedTask.convertSubsetTaskToLinkedParentTask(siteSubsetTasks.find(task => task.id === action.parentTaskId));
                } else {
                  linkedParentTask = this.currentTaskInPreview$.value.minimalParentTask;
                }
                siteSubsetTasks.forEach((subsetTask) => {
                  if(subsetTask.parentTaskId === action.id) {
                    if(linkedMinimalSubtasks.find((subtask) => subtask.id === subsetTask.id)) {
                      linkedMinimalSubtasks = linkedMinimalSubtasks.map((subtask) => {
                        return subtask.id === subsetTask.id ? MinimalSubtask.convertSubsetTaskToMinimalSubtask(subsetTask) : subtask;
                      });
                    } else {
                      linkedMinimalSubtasks.push(MinimalSubtask.convertSubsetTaskToMinimalSubtask(subsetTask));
                    }
                  }
                  if(action.linkedTasks.Successors.includes(subsetTask.id)) {
                    if(linkedSuccessors.find((successorTask) => successorTask.id === subsetTask.id)) {
                      linkedSuccessors = linkedSuccessors.map((successorTask) => {
                        return successorTask.id === subsetTask.id ? subsetTask : successorTask;
                      });
                    } else {
                      linkedSuccessors.push(subsetTask);
                    }
                  }
                  if(action.linkedTasks.Predecessors.includes(subsetTask.id)) {
                    if(linkedPredecessors.find((predecessorTask) => predecessorTask.id === subsetTask.id)) {
                      linkedPredecessors = linkedPredecessors.map((predecessorTask) => {
                        return predecessorTask.id === subsetTask.id ? subsetTask : predecessorTask;
                      });
                    } else {
                      linkedPredecessors.push(subsetTask);
                    }
                  }
                });
                action.minimalParentTask = linkedParentTask;
                action.minimalSubtasks = linkedMinimalSubtasks;
                action.subtaskIds = linkedMinimalSubtasks.map((subtask) => {
                  return subtask.id;
                });
                if(userRole !== UserRightsDAO.BACKEND_SITE_ROLES.Supervisor) {
                  // On update of task that is currently in preview, display the previous list of predecessor and successor tasks for a non supervisor user
                  // since the FE is not guaranteed to have all the necessary data to display any new linked tasks
                  action.linkedPredecessors = this.currentTaskInPreview$.value.linkedPredecessors;
                  action.linkedSuccessors = this.currentTaskInPreview$.value.linkedSuccessors
                } else {
                  action.linkedPredecessors = LinkedTask.convertSubsetTasksToLinkedTasks(linkedPredecessors);
                  action.linkedSuccessors = LinkedTask.convertSubsetTasksToLinkedTasks(linkedSuccessors);
                }
                this.updateCurrentTaskInPreview(action);
              }
            });
            actions.DELETE.forEach((action) => {
              siteSubsetTasks = siteSubsetTasks.filter((siteTask) => siteTask.id !== action.id);
              siteFilteredTimelineTasks = siteFilteredTimelineTasks.filter((timelineTask) => timelineTask.id !== action.id);
              // navigate user to tasks list screen when task in preview is deleted
              if(currentTaskInPreview && currentTaskInPreview.id === action.id) {
                this.router.navigate(['..'], { relativeTo: this.route });
              }
            });
            this.siteSubsetTasks$.next(siteSubsetTasks);
            let siteFilteredTimelineTasksCopy = JSON.parse(JSON.stringify(siteFilteredTimelineTasks));
            siteFilteredTimelineTasksCopy = FilteredTimelineTask.mapFilteredTimelineSubtasksToParentTimelineTask(siteFilteredTimelineTasksCopy);
            this.siteFilteredTimelineTasks$.next(siteFilteredTimelineTasksCopy);
            break;
          case 'SiteForm':
            let siteForms = this.siteForm$.value;
            actions.CREATE.forEach(action => {
              if(siteForms.find((siteForm) => siteForm.id === action.id)) {
                siteForms = siteForms.map((siteForm) => {
                  return siteForm.id === action.id ? action : siteForm;
                });
              } else {
                siteForms.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              siteForms = siteForms.map((siteForm) => {
                return siteForm.id === action.id ? action : siteForm;
              });
            });
            actions.DELETE.forEach((action) => {
              siteForms = siteForms.filter((siteForm) => siteForm.id !== action.id);
            });
            this.siteForm$.next(siteForms);
            break;
        }
      });
      if(navigateToSiteSelectScreen) {
        this.router.navigate(['/space', this.currentSpaceId , 'select-site'])
        .then(() => {
          this.userSettingsService.set('lastVisitedSite', null);
        });
      }
    }
  }

  updateFilteredTimelineTasksWithSyncedTask(action: Task, siteFilteredTimelineTasks: FilteredTimelineTask[]): FilteredTimelineTask[] {
    let filteredTimelineTask = new FilteredTimelineTask();
    FilteredTimelineTask.taskToTimelineFilteredTaskModel(action, filteredTimelineTask);
    if(siteFilteredTimelineTasks.find((timelineTask) => timelineTask.id === filteredTimelineTask.id)) {
      siteFilteredTimelineTasks = siteFilteredTimelineTasks.map((timelineTask) => {
        return timelineTask.id === filteredTimelineTask.id ? filteredTimelineTask : timelineTask;
      });
    } else {
      siteFilteredTimelineTasks.push(filteredTimelineTask);
    }
    return siteFilteredTimelineTasks;
  }

  handleIncrementalSpaceSyncItems(incrementalSyncItems: Map<SyncObjectType, any>): void {
    if(incrementalSyncItems) {
      let navigateToSiteSelectScreen = false;
      incrementalSyncItems.forEach((actions, type) => {
        switch (type) {
          case 'Asset':
            let spaceLabours = this.spaceLabours$.value;
            let spaceMaterials = this.spaceMaterials$.value;
            let spaceEquipments = this.spaceEquipments$.value;
            actions.CREATE.forEach(action => {
              switch (action.category) {
                case AssetCategory.EQUIPMENTS:
                  if(spaceEquipments.find((spaceEquipment) => spaceEquipment.id === action.id)) {
                    spaceEquipments = spaceEquipments.map((spaceEquipment) => {
                      return spaceEquipment.id === action.id ? action : spaceEquipment;
                    });
                  } else {
                    spaceEquipments.push(action);
                  }
                  break;
                case AssetCategory.LABOURS:
                  if(spaceLabours.find((spaceLabour) => spaceLabour.id === action.id)) {
                    spaceLabours = spaceLabours.map((spaceLabour) => {
                      return spaceLabour.id === action.id ? action : spaceLabour;
                    });
                  } else {
                    spaceLabours.push(action);
                  }
                  break;
                case AssetCategory.MATERIALS:
                  if(spaceMaterials.find((spaceMaterial) => spaceMaterial.id === action.id)) {
                    spaceMaterials = spaceMaterials.map((spaceMaterial) => {
                      return spaceMaterial.id === action.id ? action : spaceMaterial;
                    });
                  } else {
                    spaceMaterials.push(action);
                  }
                  break;
              }
            });
            actions.UPDATE.forEach((action) => {
              switch (action.category) {
                case AssetCategory.EQUIPMENTS:
                  spaceEquipments = spaceEquipments.map((_equipment) => _equipment.id === action.id ? action : _equipment);
                  break;
                case AssetCategory.LABOURS:
                  spaceLabours = spaceLabours.map((_labours) => _labours.id === action.id ? action : _labours);
                  break;
                case AssetCategory.MATERIALS:
                  spaceMaterials = spaceMaterials.map((_materials) => _materials.id === action.id ? action : _materials);
                  break;
              }
            });
            actions.DELETE.forEach((action) => {
              switch (action.category) {
                case AssetCategory.EQUIPMENTS:
                  spaceEquipments = spaceEquipments.filter((_equipment) => _equipment.id !== action.id);
                  break;
                case AssetCategory.LABOURS:
                  spaceLabours = spaceLabours.filter((_labours) => _labours.id !== action.id);
                  break;
                case AssetCategory.MATERIALS:
                  spaceMaterials = spaceMaterials.filter((_materials) => _materials.id !== action.id);
                  break;
              }
            });
            this.spaceLabours$.next(spaceLabours);
            this.spaceMaterials$.next(spaceMaterials);
            this.spaceEquipments$.next(spaceEquipments);
            break;

          case 'Contractor':
            let spaceContractors = this.spaceContractors$.value;
            actions.CREATE.forEach(action => {
              if(spaceContractors.find((spaceContractor) => spaceContractor.id === action.id)) {
                spaceContractors = spaceContractors.map((spaceContractor) => {
                  return spaceContractor.id === action.id ? action : spaceContractor;
                });
              } else {
                spaceContractors.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              spaceContractors = spaceContractors.map((_contractor) => _contractor.id === action.id ? action : _contractor);
            });
            actions.DELETE.forEach((action) => {
              spaceContractors = spaceContractors.filter((_contractors) => _contractors.id !== action.id);
            });
            this.spaceContractors$.next(spaceContractors);
            break;

          case 'Tag':
            let spaceTags = this.spaceTags$.value;
            actions.CREATE.forEach(action => {
              if(spaceTags.find((spaceTag) => spaceTag.id === action.id)) {
                spaceTags = spaceTags.map((spaceTag) => {
                  return spaceTag.id === action.id ? action : spaceTag;
                });
              } else {
                spaceTags.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              spaceTags = spaceTags.map((_tag) => _tag.id === action.id ? action : _tag);
            });
            actions.DELETE.forEach((action) => {
              spaceTags = spaceTags.filter((_tags) => _tags.id !== action.id);
            });
            this.spaceTags$.next(spaceTags);
            break;

          case 'Location':
            let spaceLocations = this.spaceLocations$.value;
            actions.CREATE.forEach(action => {
              if(spaceLocations.find((spaceLocation) => spaceLocation.id === action.id)) {
                spaceLocations = spaceLocations.map((spaceLocation) => {
                  return spaceLocation.id === action.id ? action : spaceLocation;
                });
              } else {
                spaceLocations.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              spaceLocations = spaceLocations.map((_location) => _location.id === action.id ? action : _location);
            });
            actions.DELETE.forEach((action) => {
              spaceLocations = spaceLocations.filter((_location) => _location.id !== action.id);
            });
            this.spaceLocations$.next(spaceLocations);
            break;

          case 'Site':
            let sites = this.allSitesList$.value;
            actions.CREATE.forEach((action) => {
              if(sites.find((site) => site.id === action.id)) {
                sites = sites.map((site) => {
                  return site.id === action.id ? action : site;
                });
              } else {
                sites.push(action);
              }
            });
            actions.UPDATE.forEach((action) => {
              if(sites.find((site) => site.id === action.id)) {
                sites = sites.map((site) => {
                  return site.id === action.id ? action : site;
                });
              } else {
                sites.push(action);
              }
            });
            actions.DELETE.forEach((action) => {
              if(sites.find((site) => site.id === action.id)) {
                if(action.id === this.currentSiteId) {
                  navigateToSiteSelectScreen = true;
                }
              }
            });
            this.allSitesList$.next(sites);
            break;
        }
      });
      if(navigateToSiteSelectScreen) {
        this.router.navigate(['/space', this.currentSpaceId , 'select-site'])
        .then(() => {
          this.resetCurrentSite();
          this.userSettingsService.set('lastVisitedSite', null);
        });
      }
    }
  }

  public communicateCollapseSidebar() {
    this.collapseSidebar$.next();
  }

  public setCanSyncSpinnerActivate(state: boolean): void {
    this.canSyncSpinnerActivate$.next(state);
  }

  public get canSyncSpinnerActivate(): boolean {
    return this.canSyncSpinnerActivate$.value;
  }

  async fetchAdminResourcesList() {
    const url = this.urlGiverService.giveAdminResourcesListAPIUrl(this.currentSpaceId);
    return this.http.get(url).pipe(
      map((response: AdminResourcesList) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get Admin resources list from backend", error);
      throw error;
    });
  }

  async fetchAdminInformationList() {
    const url = this.urlGiverService.giveAdminInformationListAPIUrl(this.currentSpaceId);
    return this.http.get(url).pipe(
      map((response: AdminInformationList) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get Admin information list from backend", error);
      throw error;
    });
  }

  fetchSiteTasksCount(isMemberViewingAllTasks: boolean = false): Promise<TasksCount> {
    const url = this.urlGiverService.giveTasksCountAPIUrl(this.currentSpaceId, this.currentSiteId);
    const params = new HttpParams()
    .set('isViewAllForMember', isMemberViewingAllTasks);
    return this.http.get<BETasksCountResponse>(url, {params}).pipe(
      map((response) => {
        let tasksCount: TasksCount = {
          [TASK_STATUS.PLANNED]: response.plannedTasksCount,
          [TASK_STATUS.INPROGRESS]: response.inProgressTasksCount,
          [TASK_STATUS.COMPLETED]: response.completedTasksCount,
          [TASK_STATUS.CANCELLED]: response.cancelledTasksCount,
        }
        this.currentSiteTasksCount$.next(tasksCount);
        return tasksCount;
      })
    ).toPromise();
  }

  fetchSiteEventsCount(): Promise<EventsCount> {
    const url = this.urlGiverService.giveEventsCountAPIUrl(this.currentSpaceId, this.currentSiteId);
    return this.http.get<BEEventsCountResponse>(url).pipe(
      map((response) => {
        let eventsCount: EventsCount = {
          TotalDiaryEntries: response.pendingApprovalCount + response.approvedEventsCount + response.rejectedEventsCount + response.draftEventsCount,
          Pending: response.pendingApprovalCount,
          Approved: response.approvedEventsCount,
          Rejected: response.rejectedEventsCount,
          Draft: response.draftEventsCount
        }
        this.currentSiteEventsCount$.next(eventsCount);
        return eventsCount;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get events count from backend", error);
      throw error;
    });
  }

  fetchAdminSiteTasksCount(): Promise<TasksCount> {
    const url = this.urlGiverService.giveAdminTasksCountAPIUrl(this.currentSpaceId);
    return this.http.get<BETasksCountResponse>(url).pipe(
      map((response) => {
        let tasksCount: TasksCount = {
          [TASK_STATUS.PLANNED]: response.plannedTasksCount,
          [TASK_STATUS.INPROGRESS]: response.inProgressTasksCount,
          [TASK_STATUS.COMPLETED]: response.completedTasksCount,
          [TASK_STATUS.CANCELLED]: response.cancelledTasksCount,
        }
        this.currentSiteTasksCount$.next(tasksCount);
        return tasksCount;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get Admin tasks count from backend", error);
      throw error;
    });;
  }

  fetchAdminSiteEventsCount(): Promise<EventsCount> {
    const url = this.urlGiverService.giveAdminEventsCountAPIUrl(this.currentSpaceId);
    return this.http.get<BEEventsCountResponse>(url).pipe(
      map((response) => {
        let eventsCount: EventsCount = {
          TotalDiaryEntries: response.pendingApprovalCount + response.approvedEventsCount + response.rejectedEventsCount + response.draftEventsCount,
          Pending: response.pendingApprovalCount,
          Approved: response.approvedEventsCount,
          Rejected: response.rejectedEventsCount,
          Draft: response.draftEventsCount
        }
        this.currentSiteEventsCount$.next(eventsCount);
        return eventsCount;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get Admin events count from backend", error);
      throw error;
    });
  }

  fetchSiteDiaryEventsCountByDateAndStatus(param: CountByDateAndStatusFilterParameters, isInAdministrationPage: boolean = false): Promise<BEDiaryCountByDateAndStatusResponse> {
    const url = isInAdministrationPage? this.urlGiverService.giveAdminSiteDiaryEventsCountByDateAndStatusByUrl(this.currentSpaceId) : this.urlGiverService.giveSiteDiaryEventsCountByDateAndStatusByUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: BEDiaryCountByDateAndStatusResponse) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary count by date and status from backend", error);
      throw error;
    });
  }

  fetchSiteTasksCountByDateAndStatus(param: CountByDateAndStatusFilterParameters, isInAdministrationPage: boolean = false): Promise<BETaskCountByDateAndStatusResponse> {
    const url = isInAdministrationPage ? this.urlGiverService.giveAdminSiteTasksCountByDateAndStatusByUrl(this.currentSpaceId) : this.urlGiverService.giveSiteTasksCountByDateAndStatusByUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: BETaskCountByDateAndStatusResponse) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site task count by date and status from backend", error);
      throw error;
    });
  }

  fetchSiteDiaryEventsCreatedByCount(param: EventsCreatedByFilterParameters): Promise<EventsCreatedByCount> {
    const url = this.urlGiverService.giveSiteDiaryEventsCreatedByUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: EventsCreatedByCount) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary events created by count from backend", error);
      throw error;
    });
  }

  fetchSiteTasksAssigneesCount(param: TaskAssigneesGraphFilterParameters): Promise<TaskAssigneesCount> {
    const url = this.urlGiverService.giveSiteTasksAssigneesCountAPIUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TaskAssigneesCount) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site task assignees count from backend", error);
      throw error;
    });
  }

  fetchSiteDiaryDashboardCreatedByTable(param: EventsCreatedByFilterParameters): Promise<DashboardCreatedByTableResponse[]> {
    const url = this.urlGiverService.giveSiteDiaryDashboardCreatedByTableApiUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: DashboardCreatedByTableResponse[]) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary created by dashboard table data from backend", error);
      throw error;
    });
  }

  async fetchSiteTaskDashboardAssigneesTaskTable(param: TaskAssigneesGraphFilterParameters): Promise<DashboardTaskAssigneesTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteTaskDashboardAssigneesTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardTaskAssigneesTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site task tasks assignees dashboard table data from backend", error);
      throw error;
    }
  }

  async fetchDashboardCountByDateAndStatusTable(param: CountByDateAndStatusFilterParameters): Promise<DashboardCountByDateAndStatusTableResponse[]> {
    try {
      const url = param.appType === 'SITE_DIARY'? this.urlGiverService.giveSiteDiaryDashboardCountByDateAndStatusTableApiUrl(this.currentSpaceId, this.currentSiteId): this.urlGiverService.giveSiteTaskDashboardCountByDateAndStatusTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardCountByDateAndStatusTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site diary count by date and status dashboard table data from backend", error);
      throw error;
    }
  }

  async fetchAdminDashboardCountByDateAndStatusTable(param: CountByDateAndStatusFilterParameters): Promise<DashboardCountByDateAndStatusTableResponse[]> {
    try {
      const url = param.appType === 'SITE_DIARY'? this.urlGiverService.giveAdminSiteDiaryDashboardCountByDateAndStatusTableApiUrl(this.currentSpaceId): this.urlGiverService.giveAdminSiteTaskDashboardCountByDateAndStatusTableApiUrl(this.currentSpaceId);
      let body = param;
      const response = await this.http.post<DashboardCountByDateAndStatusTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site diary count by date and status dashboard table data from backend", error);
      throw error;
    }
  }

  fetchSiteDiaryTotalManhoursCount(param: TotalManhoursFilterParameters): Promise<number> {
    const url = this.urlGiverService.giveSiteDiaryTotalManhoursUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: number) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary total man hours from backend", error);
      throw error;
    });
  }

  fetchSiteTaskTypesCount(param: TaskTypesFilterParameters): Promise<BETaskTypesResponse> {
    const url = this.urlGiverService.giveSiteTaskTypesCountApiUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: BETaskTypesResponse) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site task types count from backend", error);
      throw error;
    });
  }

  fetchTotalResourceUsageCount(param: TotalResourceUsageFilterParameters, isAdministrationPage: boolean = false): Promise<TotalResourceUsageCount> {
    const url = isAdministrationPage? this.urlGiverService.giveAdminSiteDiaryTotalResourceUsageUrl(this.currentSpaceId) : this.urlGiverService.giveTotalResourceUsageUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TotalResourceUsageCount) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get total resource usage by count from backend", error);
      throw error;
    });
  }

  fetchTasksDetailedStateCount(param: TaskStateFilterParameters): Promise<TasksDetailedStatesCount> {
    const url = this.urlGiverService.giveTaskStatesApiUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TasksDetailedStatesCount) => {
        let tasksDetailedStates: TasksDetailedStatesCount = {
          plannedAndNotReadyTasksCount : response.plannedAndNotReadyTasksCount,
          plannedAndReadyToStartTasksCount : response.plannedAndReadyToStartTasksCount,
          delayedTasksCount : response.delayedTasksCount,
          inProgressTasksCount : response.inProgressTasksCount,
          overdueTasksCount : response.overdueTasksCount,
          pendingApprovalTasksCount : response.pendingApprovalTasksCount
        }
        this.currentSiteTasksDetailedStatesCount$.next(tasksDetailedStates);
        return tasksDetailedStates;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get task states by count from backend", error);
      throw error;
    });
  }

  fetchSiteDashboardTotalResourceUsageTable(param: TotalResourceUsageFilterParameters): Promise<DashboardResourceTableResponse[]> {
    const url = this.urlGiverService.giveSiteDashboardTotalResourceUsageTableApiUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: DashboardResourceTableResponse[]) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary total resource usage dashboard table data from backend", error);
      throw error;
    });
  }

  //Mock data- change once API is ready

  fetchAdminSiteDashboardTotalResourceUsageTable(param: TotalResourceUsageFilterParameters): Promise<DashboardResourceTableResponse[]> {
    const url = this.urlGiverService.giveAdminSiteDashboardTotalResourceUsageTableApiUrl(this.currentSpaceId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: DashboardResourceTableResponse[]) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get site diary total resource usage dashboard table data from backend", error);
      throw error;
    });
  }

  async fetchSiteDiaryDashboardTotalAverageManhoursUsageTable(param: TotalManhoursFilterParameters): Promise<DashboardTotalManhoursTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteDiaryDashboardTotalAverageManhoursTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardTotalManhoursTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site total average manhours dashboard table data from backend", error);
      throw error;
    }
  }

  async fetchSiteDashboardTotalInformationUsageTable(param: TotalInformationUsageFilterParameters): Promise<DashboardInformationTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteDashboardTotalInformationUsageTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardInformationTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site total information usage dashboard table data from backend", error);
      throw error;
    }
  }


  async fetchAdminSiteDashboardTotalInformationUsageTable(param: TotalInformationUsageFilterParameters): Promise<DashboardInformationTableResponse[]> {
    try {
      const url = this.urlGiverService.giveAdminSiteDashboardTotalInformationUsageTableApiUrl(this.currentSpaceId);
      let body = param;
      const response = await this.http.post<DashboardInformationTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site total information usage dashboard table data from backend", error);
      throw error;
    }
  }

  async fetchSiteDiaryDashboardCostTable(param: TotalResourceCostFilterParameters): Promise<DashboardCostTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteDashboardCostTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardCostTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site cost dashboard table data from backend", error);
      throw error;
    }
  }

  fetchTotalInformationUsageCount(param: TotalInformationUsageFilterParameters, isAdministrationPage: boolean = false): Promise<TotalInformationUsageCount> {
    const url = isAdministrationPage? this.urlGiverService.giveAdminTotalInformationUsageUrl(this.currentSpaceId) : this.urlGiverService.giveTotalInformationUsageUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TotalInformationUsageCount) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get total information usage by count from backend", error);
      throw error;
    });
  }

  fetchTotalResourceCostCount(param: TotalResourceCostFilterParameters): Promise<TotalResourceCostCount> {
    const url = this.urlGiverService.giveTotalResourceCostUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TotalResourceCostCount) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get total resource cost count from backend", error);
      throw error;
    });
  }

  fetchTaskSCurveGraphData(param: SCurveGraphFilterParameters, isInAdministrationPage: boolean = false): Promise<TaskSCurveGraphBEResponse> {
    const url = isInAdministrationPage? this.urlGiverService.giveAdminTaskSCurveApiUrl(this.currentSpaceId) : this.urlGiverService.giveTaskSCurveApiUrl(this.currentSpaceId, this.currentSiteId);
    let body = param;
    return this.http.post(url, body).pipe(
      map((response: TaskSCurveGraphBEResponse) => {
        return response;
      })
    ).toPromise().catch((error) => {
      this.logger.error("Failed to get task S-Curve data from backend", error);
      throw error;
    });
  }

  async fetchSiteTaskDashboardTaskStatesDonutGraphTable(param: DashboardTaskStatesDonutGraphTableParameters): Promise<DashboardTaskStatesTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteTaskDashboardTaskStatesDonutGraphTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      let response = await this.http.post<DashboardTaskStatesTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site task tasks states details dashboard table data from backend", error);
      throw error;
    }
  }

  async fetchSiteTaskDashboardTaskTypesGraphTable(param: TaskTypesFilterParameters): Promise<DashboardTaskTypesTableResponse[]> {
    try {
      const url = this.urlGiverService.giveSiteTaskDashboardTaskTypesGraphTableApiUrl(this.currentSpaceId, this.currentSiteId);
      let body = param;
      const response = await this.http.post<DashboardTaskTypesTableResponse[]>(url, body).toPromise();
      return response;
    } catch (error) {
      this.logger.error("Failed to get site task tasks types details dashboard table data from backend", error);
      throw error;
    }
  }

  public updateTaskWithReportedProgress(task: Task, reportedProgress: Event, isNewItem: boolean): void {
    let relatedEvent: TaskRelatedEvent = TaskRelatedEvent.toModel(reportedProgress);
    if (isNewItem) {
      relatedEvent.createdOn = new Date();
      if(task.relatedEvents.some(_event => _event.eventId === relatedEvent.eventId)) {
        task.relatedEvents = task.relatedEvents.map((_event) => {
          return _event.eventId === relatedEvent.eventId ? relatedEvent : _event;
        });
      } else {
        task.relatedEvents.push(relatedEvent);
      }
    }
    else {
      task.relatedEvents = task.relatedEvents.map((_event) => {
        if (_event.eventId === relatedEvent.eventId) {
          return relatedEvent;
        }
        else {
          return _event;
        }
      });
    }
    this.updateCurrentTaskInPreview(task);
    this.updateTimelineTasksWithReportedProgress(task);
  }

  updateSubtasksInPreviewWithReportedProgress(task: Task, reportedProgress: Event): void {
    task.minimalSubtasks = task.minimalSubtasks.map((subtask) => {
      if(subtask.id === reportedProgress.task.taskId && this.siteSubsetTasks$.value.find(task => task.id === reportedProgress.task.taskId)) {
        subtask = MinimalSubtask.convertSubsetTaskToMinimalSubtask(this.siteSubsetTasks$.value.find(task => task.id === reportedProgress.task.taskId));
      }
      return subtask;
    });
    this.updateCurrentTaskInPreview(task);
  }

  public updateTaskWithDeletedProgress(task: Task, deletedProgress: Event): void {
    let relatedEvent: TaskRelatedEvent = TaskRelatedEvent.toModel(deletedProgress);
    task.relatedEvents = task.relatedEvents.filter((_event: TaskRelatedEvent) => _event.eventId !== relatedEvent.eventId);
    this.updateCurrentTaskInPreview(task);
    this.updateTimelineTasksWithReportedProgress(task);
  }

  reportEventOnTask(actionType: ActionType, event: Event): void {
    let relatedMinimalEvent = TaskRelatedEvent.eventToRelatedMinimalEvent(event);
    let tasks = this.siteSubsetTasks$.value;
    if (tasks === null) {
      tasks = [];
    }
    switch (actionType) {
      case ActionType.CREATE:
        this.siteSubsetTasks$.next(tasks.map(
          (_task) => {
            if (_task.id === relatedMinimalEvent.taskId) {
              _task.relatedMinimalEvents.push(relatedMinimalEvent);
              return _task;
            }
            else {
              return _task;
            }
          })
        );
      break;
      case ActionType.UPDATE:
        this.siteSubsetTasks$.next(tasks.map(
          (_task) => {
            if (_task.id === relatedMinimalEvent.taskId) {
              _task.relatedMinimalEvents = _task.relatedMinimalEvents.map((_event) =>
              _event.id === relatedMinimalEvent.id ? relatedMinimalEvent : _event)
              return _task;
            }
            else {
              return _task;
            }
          })
        );
      break;
      case ActionType.DELETE:
        this.siteSubsetTasks$.next(tasks.map(
          (_task) => {
            if (_task.id === relatedMinimalEvent.taskId) {
              _task.relatedMinimalEvents = _task.relatedMinimalEvents.filter((_event) => _event.id !== relatedMinimalEvent.id);
              return _task;
            }
            else {
              return _task;
            }
          })
        );
      break;
    }
  }

  // update reported progress event or deleted progress event for a task in timeline view
  updateTimelineTasksWithReportedProgress(task: Task) {
    let filteredTimelineTask = new FilteredTimelineTask();
    let filteredTimelineTasks = this.siteFilteredTimelineTasks$.value;
    FilteredTimelineTask.taskToTimelineFilteredTaskModel(task, filteredTimelineTask);
    this.siteFilteredTimelineTasks$.next(filteredTimelineTasks.map(
      (_task) => {
        if (_task.id === task.id) {
          _task = filteredTimelineTask;
          return _task;
        }
        else {
          return _task;
        }
      })
    );
  }
  updateSubsetTaskWithRelatedMinimalEvent(action: Event, actionType: ActionType): void {
    let taskRelatedMinimalEvent = TaskRelatedEvent.eventToRelatedMinimalEvent(action);
    let siteSubsetTasks = this.siteSubsetTasks$.value;
    if(actionType === ActionType.CREATE) {
      siteSubsetTasks = siteSubsetTasks.map(
        (_task) => {
          if (_task.id === taskRelatedMinimalEvent.taskId) {
            if(_task.relatedMinimalEvents.find(_minimalEvent => _minimalEvent.id === taskRelatedMinimalEvent.id)) {
              _task.relatedMinimalEvents = _task.relatedMinimalEvents.map(_minimalEvent => {
                return _minimalEvent.id === taskRelatedMinimalEvent.id ? taskRelatedMinimalEvent : _minimalEvent;
              });
            } else {
              _task.relatedMinimalEvents.push(taskRelatedMinimalEvent);
            }
            return _task;
          } else {
            return _task;
          }
        }
      );
    } else if (actionType === ActionType.UPDATE) {
      siteSubsetTasks = siteSubsetTasks.map(
        (_task) => {
          if(_task.id === taskRelatedMinimalEvent.taskId) {
            _task.relatedMinimalEvents = _task.relatedMinimalEvents.map((_minimalEvent) => _minimalEvent.id === taskRelatedMinimalEvent.id ? taskRelatedMinimalEvent : _minimalEvent);
          }
          return _task;
        }
      );
    } else {
      siteSubsetTasks = siteSubsetTasks.map(
        (_task) => {
          if(_task.id === taskRelatedMinimalEvent.taskId) {
            _task.relatedMinimalEvents = _task.relatedMinimalEvents.filter((_minimalEvent) => _minimalEvent.id !== taskRelatedMinimalEvent.id);
          }
          return _task;
        }
      );
    }
    this.siteSubsetTasks$.next(siteSubsetTasks);
  }

  updateCurrentTaskInPreviewAfterSync(taskId: string): void {
    this.spinnerService.activate('pulsating');
    this.getTaskFromBackendById(this.currentSpaceId, this.currentSiteId, taskId).toPromise()
      .then(task => {
        let newTask = new Task();
        Task.singleTaskToModel(task, newTask);
        this.updateCurrentTaskInPreview(newTask);
        this.spinnerService.deactivate();
      })
      .catch((error) => {
        this.spinnerService.deactivate();
        this.logger.error("Failed to get task from backend after the incremental sync", error);
      });
  }

  // handle all task sync related errors for webapp
  handleTaskPostSyncErrors(error: any, syncDto: SyncDto) {
    if (error.status === HttpStatus.BAD_REQUEST) {
      if (syncDto.action === ActionType.CREATE) {
        if (error.error.errorCode === TaskDependencyErrors.TASK_SELF_LINK_NOT_ALLOWED) {
          this.toasterService.showErrorToaster("tasks.dependency.error.task_cannot_be_linked_to_self");
          this.logger.error("Task cannot be linked to itself", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.PARENT_TASK_PRESENT_IN_SUBTASKS) {
          this.toasterService.showErrorToaster("tasks.dependency.error.parent_task_present_in_subtasks");
          this.logger.error("The subTasks array contains parent task id", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_subtask");
          this.logger.error("One or more subtask(s) is not standalone", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_PARENT_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_parent_task");
          this.logger.error("One or more predecessor(s) or successor(s) is a subtask with a different parent", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.TASK_CANNOT_BE_PARENT_TO_ITSELF) {
          this.toasterService.showErrorToaster("tasks.dependency.error.task_cannot_be_parent_to_self");
          this.logger.error("The parentId is the same as task id", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PARENT_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_parent_task");
          this.logger.error("Parent task is a subtask", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_subtask");
          this.logger.error("One or more predecessor(s) or successor(s) is a parent (or) standalone (or) subtask with a different parent", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_STANDALONE_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_standalone_task");
          this.logger.error("One or more predecessor(s) or successor(s) is a subtask", error);
        }
        else if(error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_DEPENDENT_TASK_AS_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_dependent_as_subtask");
          this.logger.error("One or more subtask(s) has parent or standalone task as predecessor or successor", error);
        }
      }
      else if (syncDto.action === ActionType.UPDATE) {
        if (error.error.errorCode === TaskDependencyErrors.TASK_SELF_LINK_NOT_ALLOWED) {
          this.toasterService.showErrorToaster("tasks.dependency.error.task_cannot_be_linked_to_self");
          this.logger.error("Task cannot be linked to itself", error);
        }
        else if(error.error.message === RelatedTasksLinkingErrors.APPROVED_TASK_IS_UPDATED) {
          this.toasterService.showErrorToaster("tasks.dependency.error.approved_task_is_updated");
          this.logger.error("Approved task is updated", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_UNLINK_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_unlink_subtask");
          this.logger.error("One or more subtask(s) has progress", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_subtask");
          this.logger.error("One or more subtask(s) is itself a parent or a subtask with a different parent", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_UNLINK_PARENT_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_unlink_parent_task");
          this.logger.error("Task has progress", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.SUBTASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS) {
          this.toasterService.showErrorToaster("tasks.dependency.error.subtask_cannot_have_progress_from_subtasks");
          this.logger.error("isProgressFromSubtasks can be true for subtask", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.STANDALONE_TASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS) {
          this.toasterService.showErrorToaster("tasks.dependency.error.standalone_task_cannot_have_progress_from_subtasks");
          this.logger.error("isProgressFromSubtasks can be true for standalone task", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_STANDALONE_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_standalone_task");
          this.logger.error("One or more predecessor(s) or successor(s) is a subtask", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_subtask");
          this.logger.error("One or more predecessor(s) or successor(s) is a parent (or) standalone (or) subtask with a different parent", error);
        }
        else if(error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_UNLINK_DEPENDENT_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_unlink_dependent_subtask");
          this.logger.error("One or more subtask(s) has subtasks as predecessor or successor", error);
        }
        else if(error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_DEPENDENT_TASK_AS_SUBTASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_dependent_as_subtask");
          this.logger.error("One or more subtask(s) has parent or standalone task as predecessor or successor", error);
        }
        else if (error.error.errorCode === RelatedTasksLinkingErrors.CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_PARENT_TASK) {
          this.toasterService.showErrorToaster("tasks.dependency.error.cannot_link_predecessor_or_successor_for_parent_task");
          this.logger.error("One or more predecessor(s) or successor(s) is a subtask with a different parent", error);
        }
      }
      throw new ServerErrorCannotLinkTasks();
    }
    else if (this.isUserNotAllowedToApproveTaskError(error, syncDto) && syncDto.action === ActionType.UPDATE) {
      this.toasterService.showErrorToaster("sync.task.approve.error.message");
      this.logger.error("only admins and supervisors can approve a task");
      throw new ServerErrorOnlyAdminsAndSuperviorsCanApproveTask();
    }
    else {
      this.toasterService.showErrorToaster('sync.failed-to-save.message');
      this.logger.error('The server returned an error in response to an action.', false, error, syncDto);
      throw new ServerError('The server returned an error in response to an action.', error, syncDto);
    }
  }
}
