import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, HostBinding } from '@angular/core';
import { DataTablePagerComponent as SuperDataTablePagerComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable-pager',
  templateUrl: './datatable-pager.component.html',
  styleUrls: ['./datatable-pager.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatablePagerComponent extends SuperDataTablePagerComponent implements OnInit {
  @HostBinding('class') defaultHostCssClass = 'datatable-pager';
  @Input() pagerLeftArrowIcon = 'fas fa-angle-left ';
  @Input() pagerRightArrowIcon = 'fas fa-angle-right ';
  @Input() pagerPreviousIcon = 'fas fa-angle-double-left';
  @Input() pagerNextIcon = 'fas fa-angle-double-right';

  @Input()
  set size(val: number) {
    this._size = val;
    this.pages = this.calcPages();
  }
  get size(): number {
    return this._size;
  }

  @Input()
  set count(val: number) {
    this._count = val;
    this.pages = this.calcPages();
  }
  get count(): number {
    return this._count;
  }

  @Input()
  set page(val: number) {
    this._page = val;
    this.pages = this.calcPages();
  }
  get page(): number {
    return this._page;
  }
  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);
    return Math.max(count || 0, 1);
  }

  @Output() change: EventEmitter<any> = new EventEmitter();

  public _visiblePagesCount = 5;

  @Input()
  set visiblePagesCount(val: number) {
    this._visiblePagesCount = val;
    this.pages = this.calcPages();
  }
  get visiblePagesCount(): number {
    return this._visiblePagesCount;
  }

  _count = 0;
  _page = 1;
  _size = 0;
  pages: any;
  previous: boolean;
  next: boolean;

  ngOnInit() {
    this.previous = this.page > 1;
    this.next = this.page < this.totalPages;
  }

  prevPage(): void {
    this.selectPage(this.page - 1);
  }

  nextPage(): void {
    this.selectPage(this.page + 1);
  }

  selectPage(page: number): void {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;

      this.change.emit({
        page
      });
    }
  }

  calcPages(page?: number): any[] {
    const pages = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = this.visiblePagesCount;
    const isMaxSized = maxSize < this.totalPages;

    page = page || this.page;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: <string><any>num
      });
    }

    this.previous = this.page > 1;
    this.next = this.page < this.totalPages;

    return pages;
  }
}
