import { TaskIndicatorFilterValue, TaskStateFilterValue } from "./enums/task-state-indicator.enum";
import { RelatedMinimalEvent } from "./event";
import { BackendTaskState, FilteredTask } from "./filtered-task";
import { MinimalSubtask } from "./minimal-subtask";
import { ModelElement } from "./model-element";
import { SubsetTask } from "./subset-task";
import { TASK_INDICATOR, TASK_STATUS, Task } from "./task";

export class LinkedTask {

  id: string;
  parentTaskId: string;
  isParentTask?: boolean;
  taskNumber: number;
  state: TaskIndicatorFilterValue | TaskStateFilterValue;
  title: string;
  progress: number;
  startDatetime: Date | number;
  endDatetime: Date | number;
  users?: string[];

  constructor() {
    this.id = ModelElement.generateId();
    this.parentTaskId = null;
    this.isParentTask = false;
    this.taskNumber = null;
    this.state = null;
    this.title = '';
    this.progress = 0;
    this.startDatetime = null;
    this.endDatetime = null;
    this.users = [];
  }

  public static jsonTolinkedTasks(json: any): LinkedTask[] {
    return json.map(linkedTaskObject => {
      const linkedTask: LinkedTask = new LinkedTask();
      linkedTask.id = linkedTaskObject.id;
      linkedTask.parentTaskId = linkedTaskObject.parentTaskId;
      linkedTask.taskNumber = linkedTaskObject.taskNumber;
      linkedTask.state = linkedTaskObject.state;
      linkedTask.title = linkedTaskObject.title;
      linkedTask.progress = linkedTaskObject.progress;
      linkedTask.startDatetime = linkedTaskObject.startDatetime;
      linkedTask.endDatetime = linkedTaskObject.endDatetime;
      return linkedTask;
    });
  }

  public static jsonToLinkedParentTask(json: any): LinkedTask {
    if(!json) {
      return null;
    }
    const linkedParentTask = new LinkedTask();
    linkedParentTask.id = json.id;
    linkedParentTask.taskNumber = json.taskNumber;
    linkedParentTask.state = json.state;
    linkedParentTask.title = json.title;
    linkedParentTask.progress = json.progress;
    linkedParentTask.startDatetime = json.startDatetime;
    linkedParentTask.endDatetime = json.endDatetime;
    linkedParentTask.users = json.users;
    return linkedParentTask;
  }

  public static convertSubsetTasksToLinkedTasks(subsetTasks: SubsetTask[]): LinkedTask[] {
    return subsetTasks.map(subsetTask => {
      let mostRecentEvent = this.getLastEventForSubsetTask(subsetTask);
      subsetTask.updateTaskProgressAndState(mostRecentEvent, subsetTask.totalQuantityDone);
      const linkedTask: LinkedTask = new LinkedTask();
      linkedTask.id = subsetTask.id;
      linkedTask.parentTaskId = subsetTask.parentTaskId;
      if(subsetTask.subtasks.length > 0) {
        linkedTask.isParentTask = true;
      }
      linkedTask.taskNumber = subsetTask.taskNumber;
      linkedTask.title = subsetTask.title;
      linkedTask.state = this.getTaskStateFromTask(subsetTask);
      linkedTask.progress = subsetTask.getCurrentTaskProgress();
      linkedTask.startDatetime = subsetTask.startDatetime;
      linkedTask.endDatetime = subsetTask.endDatetime;
      return linkedTask;
    });
  }

  public static convertFilteredTasksToLinkedTask(filteredTasks: FilteredTask[]):  LinkedTask[] {
    return filteredTasks.map(filteredTask => {
      const linkedTask: LinkedTask = new LinkedTask();
      linkedTask.id = filteredTask.id;
      linkedTask.parentTaskId = filteredTask.parentTaskId;
      if(filteredTask.subtaskIds.length > 0) {
        linkedTask.isParentTask = true;
      }
      linkedTask.taskNumber = filteredTask.taskNumber;
      linkedTask.title = filteredTask.title;
      linkedTask.state = filteredTask.state;
      linkedTask.progress = filteredTask.progress;
      linkedTask.startDatetime = filteredTask.startDatetime;
      linkedTask.endDatetime = filteredTask.endDatetime;
      return linkedTask;
    });
  }

  public static convertLinkedTasksToMinimalSubtasks(linkedTasks: LinkedTask[]): MinimalSubtask[] {
    return linkedTasks.map(linkedTask => {
      const minimalSubtask: MinimalSubtask = new MinimalSubtask();
      minimalSubtask.id = linkedTask.id;
      minimalSubtask.title = linkedTask.title;
      minimalSubtask.parentTaskId = linkedTask.parentTaskId;
      minimalSubtask.progress = linkedTask.progress;
      minimalSubtask.state = linkedTask.state;
      minimalSubtask.taskNumber = linkedTask.taskNumber;
      minimalSubtask.startDatetime = linkedTask.startDatetime as Date;
      minimalSubtask.endDatetime = linkedTask.endDatetime as Date;
      return minimalSubtask;
    });
  }

  public static convertTaskToLinkedParentTask(task: Task): LinkedTask {
    task.updateTaskStatusAndIndicator();
    const linkedParentTask = new LinkedTask();
    linkedParentTask.id = task.id;
    linkedParentTask.parentTaskId = task.parentTaskId;
    linkedParentTask.isParentTask = true;
    linkedParentTask.taskNumber = task.taskNumber;
    linkedParentTask.state = this.getTaskStateFromTask(task);
    linkedParentTask.title = task.title;
    linkedParentTask.progress = task.lastKnownProgress;
    linkedParentTask.startDatetime = task.startDatetime;
    linkedParentTask.endDatetime = task.endDatetime;
    return linkedParentTask;
  }

  public static convertSubsetTaskToLinkedParentTask(subsetTask: SubsetTask): LinkedTask {
    let totalQuantityDone = 0;
    if(subsetTask.plannedQuantity > 0) {
      totalQuantityDone = MinimalSubtask.getSubsetTaskTotalQuantityDone(subsetTask);
      subsetTask.totalQuantityDone = Math.round(totalQuantityDone * 10) / 10;
    }
    let mostRecentEvent = this.getLastEventForSubsetTask(subsetTask);
    subsetTask.updateTaskProgressAndState(mostRecentEvent, totalQuantityDone);
    const linkedParentTask = new LinkedTask();
    linkedParentTask.id = subsetTask.id;
    linkedParentTask.parentTaskId = subsetTask.parentTaskId;
    linkedParentTask.isParentTask = true;
    linkedParentTask.taskNumber = subsetTask.taskNumber;
    linkedParentTask.state = this.getTaskStateFromTask(subsetTask);
    linkedParentTask.title = subsetTask.title;
    linkedParentTask.progress = subsetTask.lastKnownProgress;
    linkedParentTask.startDatetime = subsetTask.startDatetime;
    linkedParentTask.endDatetime = subsetTask.endDatetime;
    return linkedParentTask;
  }

  public static getTaskStateFromTask(task: SubsetTask | Task): TaskIndicatorFilterValue | TaskStateFilterValue {
    if(task.taskStatus === TASK_STATUS.PLANNED) {
      if( task.taskIndicator === null || task.taskIndicator === TASK_INDICATOR.READY_TO_START) {
        return TaskIndicatorFilterValue.READY_TO_START;
      } else if (task.taskIndicator === TASK_INDICATOR.DELAYED || task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return TaskIndicatorFilterValue.DELAYED;
      }
    }
    if(task.taskStatus === TASK_STATUS.INPROGRESS) {
      if( task.taskIndicator !== TASK_INDICATOR.OVERDUE && task.taskIndicator !== TASK_INDICATOR.PENDING_APPROVAL) {
        return TaskStateFilterValue.INPROGRESS;
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return TaskIndicatorFilterValue.OVERDUE;
      } else if (task.taskIndicator === TASK_INDICATOR.PENDING_APPROVAL) {
        return TaskStateFilterValue.INPROGRESS;
      }
    }
    if(task.taskStatus === TASK_STATUS.COMPLETED) {
      return TaskStateFilterValue.COMPLETED;
    }
    if(task.taskStatus === TASK_STATUS.CANCELLED) {
      return TaskStateFilterValue.CANCELLED;
    }
    return TaskStateFilterValue.PLANNED;
  }

  public static getLastEventForSubsetTask(subsetTask: SubsetTask): RelatedMinimalEvent {
    let lastEvent: RelatedMinimalEvent = null;
    if(subsetTask.relatedMinimalEvents.length > 0) {
      subsetTask.relatedMinimalEvents.sort((a,b) => <any>a.startDatetime - <any>b.startDatetime);
      lastEvent = subsetTask.relatedMinimalEvents[subsetTask.relatedMinimalEvents.length -1];
    }
    return lastEvent;
  }
}
