export class Address {
  countryCode: string;
  administrativeArea: string;
  locality: string;
  postalCode: string;
  thoroughfare: string;

  constructor() {
    this.countryCode = null;
    this.administrativeArea = '';
    this.locality = '';
    this.postalCode = '';
    this.thoroughfare = '';
  }
}


