import { ValidationErrors, FormGroup } from '@angular/forms';

const DAY_MS = 86400000;

export function maxDurationValidator(formGroup: FormGroup): ValidationErrors {
  if (formGroup && formGroup.controls) {
    const startDatetimeControl = formGroup.controls.startDatetime;
    const endDatetimeControl = formGroup.controls.endDatetime;

    if (startDatetimeControl && startDatetimeControl.value && endDatetimeControl && endDatetimeControl.value) {
      const startDate = new Date(startDatetimeControl.value);
      const endDate = new Date(endDatetimeControl.value);

      if (endDatetimeControl.value !== null) {
        const differenceMS = endDate.getTime() - startDate.getTime();
        if (differenceMS > DAY_MS) {
          return { 'moreThanTwentyFourHourDuration': true };
        }
      }

    }
  }
  return {};
}
