import { Injectable } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { DeviceService } from '@services/device.service';
import { SiteTaskUpgradePopupComponent } from './site-task-upgrade-popup/site-task-upgrade-popup.component';
import { UpgradePopupComponent } from './upgrade-popup/upgrade-popup.component';

@Injectable()
export class UpgradeService {

  constructor(
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private ngbModalService: NgbModal,
     ) {
  }

  annoyUser (featureLabelCode): void {
     const modalRef = this.ngbModalService.open(
      UpgradePopupComponent,
      { centered: true},
    );
    modalRef.componentInstance.featureLabelCode = featureLabelCode;
    modalRef.componentInstance.modalRef = modalRef;
  }

  displaySiteTaskSubscriptionUpgradeModal(): void {
    const modalRef = this.ngbModalService.open(
      SiteTaskUpgradePopupComponent,
      { centered: true},
    );
    modalRef.componentInstance.modalRef = modalRef;
  }

}
