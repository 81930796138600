import { Pipe, PipeTransform } from '@angular/core';
import { Labelable } from '@models/labelable';

@Pipe({
  name: 'listToLabelList'
})
export class ListToLabelListPipe implements PipeTransform {
  transform(list:  Labelable[]): string[] {
    return list.map(item => item.label);
  }
}
