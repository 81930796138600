import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Event, EventStatus } from '@models/event';
import { Tag } from '@models/tag';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { Session } from '@models/session';
import { Weather } from '@models/weather';
import { Author } from '@models/author';
import { FormatSortService } from './format-sort.service';
import { SessionService } from './session.service';
import { DatabaseService } from './shared/database.service';
import { SharedService } from './shared/shared.service';
import { UrlGiverService } from './url-giver.service';
import { UserRole } from './user-rights/user-rights-dao.service';
import { UserRightsService } from './user-rights/user-rights.service';
import { NetworkStatus } from '@models/synchronization/network-status';
import { AbstractModelService } from '@services/abstract-model-service';
import { Subject } from 'rxjs';
import { QueueService } from './synchronization/queue.service';
import { SyncException, SyncExceptionType } from './synchronization/sync-exception';
import { Logger } from './logger';
import { DeviceService } from './device.service';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from './shared-data.service';


export class eventFilters {
  private siteId: string;
  private searchText: string;
  private startFilterDate: Date;
  private endFilterDate: Date;
  private contractorsId: string[];
  private tagsId: string[];
  private localisation: string;
  private creatorsId: string[];
  private status: string[];
}


@Injectable()
export class EventService extends AbstractModelService<Event> {
  protected type = 'EventV4';

  private relevantSharedService: SharedService | SharedDataService;

  constructor(
    protected databaseService: DatabaseService,
    protected sharedService: SharedService,
    private https: HttpClient,
    private urlGiver: UrlGiverService,
    private sessionService: SessionService,
    private deviceService: DeviceService,
    private userRightsService: UserRightsService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService,
    private queueService: QueueService,
  ) {
    super(
      databaseService,
    );
    // Get only Edit Approved Event exceptions
    QueueService.syncException$.asObservable().pipe(
      filter(event => event.exceptionType === SyncExceptionType.APPROVED_EVENT_MUTATION)
    ).subscribe(exception => {
      this.processEditApprovedEventSyncException(exception);
    });
    this.relevantSharedService = this.deviceService.isMobile ? this.sharedService : this.sharedDataService;
  }

  /**
   * Process the Edit Approved Event Exception
   * Get the validated version of the event gived by the backend
   * Replace the event in the indexedDB
   * @param syncException the sync exception to process
   */
  processEditApprovedEventSyncException(syncException: SyncException) {
    const event: Event = new Event();
    const json = { payload: syncException.exception.error };
    Event.toModel(json, event);
    this.update(event, false);
  }

  /**
   * Fetch weather preview at given location and date
   * @param incompleteWeather Weather object containing at least the necessary location
   * @param isoDateTime ISO 8601 datetime
   * @returns Completed Weather object
   */
  fetchWeather(incompleteWeather: Weather, isoDateTime: string): Observable<Weather> {
    const siteId = this.relevantSharedService.currentSiteId;
    const spaceId = this.relevantSharedService.currentSpaceId;
    const url = `${this.urlGiver.giveAPIUrl()}/tenant/${spaceId}/sites/${siteId}/weather`;
    const params = {
      'latitude': incompleteWeather.latitude.toString(),
      'longitude': incompleteWeather.longitude.toString(),
      'datetime': Date.parse(isoDateTime).toString(), // backend requires a timestamp
    };
    return this.https.get<Weather>(url, {params}).pipe(map(json => Weather.jsonToWeather(json)));
  }

  /**
   * Get list of {@link Event} searched by task
   */
  getEventsByTask(taskId: string): Promise<Event[]> {
    return  this.databaseService.getEventsFilteredByTaskId(this.relevantSharedService.currentSiteId, taskId);
  }

  public async filterEvents(startdate?: Date, enddate?: Date): Promise<void> {
    this.itemSubject.next(undefined);
    const events = await this.databaseService.getSiteItems('event', this.relevantSharedService.currentSiteId, startdate, enddate);
    this.itemSubject.next(events);
  }

  public async filterEventsInMobile(startdate?: Date, enddate?: Date): Promise<void> {
    const db = await this.databaseService.getDBInstant();
    let events;
    const siteId = this.relevantSharedService.currentSiteId;
    if(startdate && enddate) {
      events = await db['event'].where(['siteId', 'startDatetime']).between([siteId, startdate.getTime()], [siteId, enddate.getTime()], true, true).reverse().sortBy('startDatetime');
    } else if (startdate) {
      events = await db['event'].where(['siteId', 'startDatetime']).aboveOrEqual([siteId, startdate.getTime()]).reverse().sortBy('startDatetime');
    } else if (enddate) {
      events = await db['event'].where(['siteId', 'startDatetime']).belowOrEqual([siteId, enddate.getTime()]).reverse().sortBy('startDatetime');
    } else {
      events = await db['event'].where('siteId').equals(siteId).reverse().sortBy('startDatetime');
    }
    this.itemSubject.next(events);
  }

  public async fetchLast30Events(): Promise<Event[]> {
    const db = await this.databaseService.getDBInstant();
    let events = await db['event'].where('siteId').equals(this.relevantSharedService.currentSiteId).reverse().sortBy('startDatetime');
    const eventCount = events.length;
    if(eventCount > 30){
      events = events.slice(0,30)
    }

    this.itemSubject.next(events);
    return Promise.resolve(events);
  }

  public async fetchNext30Events(shownEventsLength:number): Promise<void> {
    const siteId = this.relevantSharedService.currentSiteId;
    const db = await this.databaseService.getDBInstant();
    let events = await db['event'].orderBy('startDatetime').reverse().filter((event) => event.siteId === siteId).limit(shownEventsLength+30).toArray();
    this.itemSubject.next(events);
  }

  public async fetchSiteEventsCount(): Promise<number> {
    const eventCount = await this.databaseService.getSiteEventsCount(this.relevantSharedService.currentSiteId);
    return eventCount;
  }

  getEventById(id: string): Promise<Event> {
    return this.databaseService.getItemById('event', id);
  }

  getEventBySpaceAndEventId(spaceId: string, eventId: string): Promise<Event> {
    return this.databaseService.getEventBySpaceAndEventId(spaceId, eventId, this.queueService);
  }

  getById(itemId: string): Observable<Event> {
    return super.getById(itemId).pipe(
      switchMap(event => this.databaseService.mergeUnsyncedPhotosWithEvents([event])),
      map(events => {
        if (events.length > 1) {
          this.logger.error('Count of events more than expected:', events);
        }
        return events[0];
      })
    );
  }

  /**
   * Set {@link Event} state to approved
   * @param _toApprove the given event id
   */
  approveEvent(_toApprove: Event): Promise<Event> {
    _toApprove.status = EventStatus.APPROVED;
    _toApprove.modifiedAt = new Date();
    return this.update(_toApprove);
  }

  /**
   * Set {@link Event} state to approved
   * @param _toSubmit the given event id
   */
  submitEvent(_toSubmit: Event): Promise<Event> {
    _toSubmit.status = EventStatus.SUBMITTED;
    _toSubmit.modifiedAt = new Date();
    return this.update(_toSubmit);
  }

  /**
   * Set {@link Event} state to rejected
   * @param _toReject the given event id
   */
  rejectEvent(_toReject: Event): Promise<Event> {
    _toReject.status = EventStatus.REJECTED;
    _toReject.modifiedAt = new Date();
    return this.update(_toReject);
  }

  getEditionRights(event: Event): UserRole[] {
    if (event.status === EventStatus.APPROVED) {
      return UserRightsService.USER_RIGHTS.site.event.manage.locked;
    }
    if (this.isMe(event.createdBy)) {
      return UserRightsService.USER_RIGHTS.site.event.manage.unlocked.own;
    } else {
      return UserRightsService.USER_RIGHTS.site.event.manage.unlocked.all;
    }
  }

  getApprovalRights(event: Event): UserRole[] {
    if (event.status === EventStatus.APPROVED) {
      return UserRightsService.USER_RIGHTS.site.event.approve.locked;
    }
    return UserRightsService.USER_RIGHTS.site.event.approve.unlocked;
  }

  getRejectionRights(event: Event): UserRole[] {
    if (event.status === EventStatus.APPROVED) {
      return UserRightsService.USER_RIGHTS.site.event.approve.locked;
    }
    return UserRightsService.USER_RIGHTS.site.event.approve.unlocked;
  }

  isEventCreatedAndApprovedByMe(event: Event): boolean {
    if (event.status === EventStatus.APPROVED) {
      if (this.isMe(event.createdBy)) {
        return true;
      }
    }
    return false;
  }

  isEventDeletable(userRoles: UserRole[], event: Event): boolean {
    const isEventCreatedAndApprovedByMe = this.isEventCreatedAndApprovedByMe(event);
    if(!isEventCreatedAndApprovedByMe) {
      return false;
    }

    // event is created and approved by the user.
    // return true if current user is a site supervisor
    // else return false
    return this.userRightsService.userHasRight(userRoles, UserRightsService.USER_RIGHTS.site.event.deleteApproved);
  }

  /**
   * Test if user is the event creator
   * @param user user to be testeuserId
   */
  isMe(author: Author): boolean {
    const session = this.sessionService.getSession();
    return author && session && (author.id === session.userId);
  }

  public getAllEventsInSite(): Promise<Event[]> {
    return this.databaseService.getSiteItems('event', this.relevantSharedService.currentSiteId);
  }

  async createMany(createdItems: Event[], withQueue?: boolean): Promise<Event[]> {
    const db = await this.databaseService.getDBInstant();
    createdItems = await this.updateSyncingEvents(createdItems);
    if(this.currentValue) {
      this.itemSubject.next(
        this.currentValue
        .filter(item => !createdItems.some(createdItem => item.id === createdItem.id))
        .concat(createdItems)
      );
    }
    return db.bulkAddWithProgress(createdItems, this.type, withQueue, 750);
  }

  async update(updatedItem: Event, withQueue?: boolean): Promise<Event> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      updatedItem.isEventSynced = false;
      this.itemSubject.next(this.currentValue.map(item => item.id === updatedItem.id ? updatedItem : item));
    }
    return db._put(updatedItem, this.type, withQueue);
  }

  async updateMany(updatedItems: Event[], withQueue?: boolean): Promise<Event[]> {
    const db = await this.databaseService.getDBInstant();
    updatedItems = await this.updateSyncingEvents(updatedItems);
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.map(item => updatedItems.find(updatedItem => item.id === updatedItem.id) || item));
    }
    return db._bulkPut(updatedItems, this.type, withQueue);
  }

  async updateEventSyncStatus(id: string): Promise<void> {
    //checking and updation for the syncing events is only done on the mobile
    if(!this.deviceService.isMobile){
      return;
    }
    const db = await this.databaseService.getDBInstant();
    let index = this.currentValue.findIndex((event)=>{
      return event.id === id;
  })
    //update the event sync status to true and update the ui
    if (this.currentValue) {
      if(index !== -1) {
        this.currentValue[index].isEventSynced = true;
        db._put(this.currentValue[index], this.type, false);
      }
      this.itemSubject.next(this.currentValue);
    }
  }

  async updateSyncingEvents(events: Event[]): Promise<Event[]> {
    // If the device is mobile update the sync status of events that are still yet to sync.
    if(this.deviceService.isMobile) {
      const pendingSyncItems = await this.databaseService.getUserDB().then(userDB => userDB.queue.toArray());
      if(pendingSyncItems.length > 0) {
        for(let queueItems of pendingSyncItems) {
          events.map((syncingItems,index) => {
            if(queueItems.action.payload.eventId === syncingItems.id) {
              events[index].isEventSynced = false;
            }
          });
        }
      }
    }
    return events;
  }

  /**
   * @returns List of all event creators for the current site
   */
  async getAllEventCreators(): Promise<Author[]> {
    const events = await this.databaseService.getSiteItems('event', this.relevantSharedService.currentSiteId);
    let eventCreators: Author[] = [];
    if(events) {
      events.forEach((event) => {
        if((eventCreators.find(eventCreator => eventCreator.id === event.createdBy.id)) === undefined) {
          eventCreators.push(event.createdBy);
        }
      })
    }
    return eventCreators;
  }

  async fetchFaultyItemsCount(): Promise<number> {
    let falutyEventsCount = await this.databaseService.fetchFaultyItemsCount(this.relevantSharedService.currentSiteId);
    return falutyEventsCount;
  }
}
