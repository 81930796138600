import * as Package from '../../../package.json';
import { Injectable, OnInit } from '@angular/core';
import { DeviceService } from '@services/device.service';
import { environment } from '../../environments/environment';
import { IntercomUser } from '../models/intercom-user';
import { Space } from './../models/space';


export const NEW_APP_VERSION: string = 'New Site Diary';
const SITE_PRODUCTIVITY_APP: string = 'Site Productivity';
const SITE_DIARY_APP: string = 'Site Diary';
const packageInfo = Package;

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnInit {

  /**
   * Intercom app_id
   * */
  appId: String = environment.intercomId;


  constructor(
    private deviceService: DeviceService,
  ) { }

  ngOnInit() {
  }

  private shouldTrack(): boolean {
    return navigator.doNotTrack !== 'yes' && navigator.doNotTrack !== '1';
  }

  private getPlatformDetails(): object {
    const details: any = {};
    details['Device platform'] = this.deviceService.getPlatformType();
    details['Device user agent'] = navigator.userAgent;
    const device = (<any>window).device;

    if (this.deviceService.hasCordova && device) {
      details['Device model'] = device.model;
      details['Device platform'] = device.platform;
      details['Device OS version'] = device.version;
      details['Device manufacturer'] = device.manufacturer;
    }

    return details;
  }

  /**
   * Function to set intercom with app id
   */
  boot(): void {
    if (this.shouldTrack()) {
      (<any>window).Intercom('boot', {
        app_id: this.appId,
        custom_launcher_selector:'#intercom-icon',
        hide_default_launcher: true,
      });
    }
  }

  /**
   * Function to set up (create or update) user in Intercom
   * @param intercomUser Given {@link_IntercomUser}
   */
  setIntercomUser(intercomUser: IntercomUser): void {
    if (this.shouldTrack()) {
      const platformDetails = this.getPlatformDetails();
      (<any>window).Intercom('update', {
        email: intercomUser.email,
        name: intercomUser.name,
        user_id: intercomUser.user_id,
        phone: intercomUser.phone,
        'App version': NEW_APP_VERSION,
        'App release version': packageInfo.version,
        ...platformDetails,
      });
    }
  }

  signUpIntercomUser(intercomUser: IntercomUser, organization: string): void {
    if (this.shouldTrack()) {
      this.boot();
      const signedUpApp = this.deviceService.isMobile ? SITE_DIARY_APP : SITE_PRODUCTIVITY_APP;
      (<any>window).Intercom('update', {
        email: intercomUser.email,
        name: intercomUser.name,
        phone: intercomUser.phone,
        'App version': NEW_APP_VERSION,
        'Signed up app': signedUpApp
      });
    }
  }

  // Update user's company in Intercom depending of his selected space
  updateCompanyFromSpace(space: Space) {
    if (this.shouldTrack()) {
      (<any>window).Intercom('update', {
        company: {
          id: space.id,
          name: space.name
        }
      });
    }
  }

  /**
   * Function to track user action in Intercom
   * @param actionyName Name of the action to track
   */
  trackUserAction(actionName: string): void {
    if (this.shouldTrack()) {
      // trackEvent method comes from intercom's javascript api methods (no relations with site diary events)
      (<any>window).Intercom('trackEvent', actionName);
    }
  }

  /**
   * Function to shut down intercom
   */
  shutdown(): void {
    (<any>window).Intercom('shutdown');
  }

  /**
   * Function to open intercom chat
   */
  toggleChat(): void {
    (<any>window).Intercom('show');
  }
}


