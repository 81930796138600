import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '@models/user';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.sass']
})
export class SelectMultipleComponent {

  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() isReadOnly: boolean;
  @Input() isLoading: boolean;

  @Input() type: string;

  @Input() placeholder: string;
  @Input() items: any[];
  @Input() parentTaskAssignees: User[] = [];
  @Input() multiple: boolean = true;

  constructor() { }

  itemInParentTaskAssignees(item) {
    if(this.parentTaskAssignees.find(user => user.id === item.id)) {
      return true;
    }
    return false;
  }
}
