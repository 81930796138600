import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelElement } from '@models/model-element';
import { ActionType } from '@models/synchronization/sync-dto';
import { SyncItem } from '@models/synchronization/sync-item';
import { SyncResponse, TaskSubsetSyncResponse } from '@models/synchronization/sync-response';
import { User } from '@models/user';
import { SessionService } from '@services/session.service';
import { SpinnerService } from '@services/spinner.service';
import { AlertBox, AlertLevel, ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Actions, ActionsFinalItem, SyncObjectFactory, SyncObjectType } from './sync-object-factory.service';

const SITE_SYNC_VERSION = '4';
const EVENTS_WEB_SYNC_LIMIT = 250;

@Injectable({
  providedIn: 'root'
})

export class WebappSyncService {

  protected currentUser: User;
  private siteSequenceToken$ = new BehaviorSubject<string>(null);
  private spaceSequenceToken$ = new BehaviorSubject<string>(null);
  constructor(
    private urlGiverService: UrlGiverService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private http: HttpClient,
    private syncObjectFactory: SyncObjectFactory,
    private sessionService: SessionService,
  ) {
   }

  public async executeWebappSubsetApi(spaceId, siteId): Promise<Map<SyncObjectType, ActionsFinalItem>> {
    const  syncUrl = this.urlGiverService.giveLatestSubsetSyncAPIUrl(spaceId, siteId);
    const eventsLimit = EVENTS_WEB_SYNC_LIMIT;
    let params = new HttpParams()
    .set('version', SITE_SYNC_VERSION)
    .set('limit', eventsLimit);

    const response = await this.http.get<SyncResponse>(syncUrl, { 'params': params }).toPromise()
      .catch(error => {
        this.spinnerService.deactivate();
        this.toasterService.showAlertBox(new AlertBox(
          'sync-warning',
          AlertLevel.warning,
          'sync.error.try-again.message',
          false,
          true
        ));
      });

    if (response && response.items && response.items.length > 0) {
      try {
        this.updateSiteSequenceToken(response.seq);
        const orderedItems = this.sortSyncItemByType(response.items);
        const finalObjects = this.convertItems(orderedItems, spaceId);
        return finalObjects;
      } catch (error) {
        throw new Error(`Error saving sync data (${spaceId}/${siteId ? siteId : ''}): ` + error);
      }
    }
    return null;
  }

  public async executeWebappTaskSubsetApi(spaceId: string, siteId: string, isMemberViewingAllTasks: boolean): Promise<Map<SyncObjectType, ActionsFinalItem>> {
    const  syncUrl = this.urlGiverService.giveTaskLatestSubsetSyncAPIUrl(spaceId, siteId);
    let currentDateTime = moment(new Date()).valueOf();
    let params = new HttpParams()
    .set('currentDate', currentDateTime);
    if(isMemberViewingAllTasks) {
      params = params.set('isViewAllForMember', true);
    }

    const response = await this.http.post<TaskSubsetSyncResponse>(syncUrl, params).toPromise()
      .catch(error => {
        this.spinnerService.deactivate();
        this.toasterService.showAlertBox(new AlertBox(
          'sync-warning',
          AlertLevel.warning,
          'sync.error.try-again.message',
          false,
          true
        ));
      });

    if (response && response.items && response.items.length > 0) {
      try {
        this.updateSiteSequenceToken(response.seq);
        const orderedItems = this.sortSyncItemByType(response.items);
        const finalObjects = this.convertItems(orderedItems, spaceId);
        return finalObjects;
      } catch (error) {
        throw new Error(`Error saving sync data (${spaceId}/${siteId ? siteId : ''}): ` + error);
      }
    }
    return null;
  }

  public async callIncrementalSyncApi(spaceId: string, siteId: string, url: string): Promise<Map<SyncObjectType, ActionsFinalItem>> {
    let sequenceToken;
    if(siteId) {
      sequenceToken = this.getSiteSequenceToken();
    } else {
      sequenceToken = this.getSpaceSequenceToken();
    }
    let params = new HttpParams()
    .set('version', SITE_SYNC_VERSION);
    if (sequenceToken) {
      params = params
        .set('seq', sequenceToken);
    }
    const response = await this.http.get<SyncResponse>(url, { 'params': params }).toPromise()
    .catch(error => {
      this.spinnerService.deactivate();
      this.toasterService.showErrorToaster('alertbox.sync-warning');
    });
    if (response && response.items && response.items.length > 0) {
      try {
        this.updateSequenceToken(response.seq, siteId);
        const orderedItems = this.sortSyncItemByType(response.items);
        const finalObjects = this.convertItems(orderedItems, spaceId);
        return finalObjects;
      } catch (error) {
        throw new Error(`Error saving sync data (${spaceId}/${siteId ? siteId : ''}): ` + error);
      }
    }
    return null;
  }

  /**
   * Sort sync items by types
   * @param items Items to sort
   * @returns A sorted SyncItem array
   */
  private sortSyncItemByType(items: SyncItem[]): Map<SyncObjectType, Actions> {
    const typeArray = new Map<SyncObjectType, Actions>();

    const types: SyncObjectType[] = ['SiteUser', 'Site', 'Asset', 'Tag', 'Contractor', 'Task', 'TaskNewApi' , 'Location', 'SiteForm', 'Event', 'EventV4', 'SubsetTask', 'Space'];
    for (const type of types) {
      typeArray.set(type, this.regroupSyncItemsByActions(items.filter(item => item.type === type)));
    }

    return typeArray;
  }

  /**
   * Regroup syncItems by actions
   * @param items Items array to regroup by action
   */
  private regroupSyncItemsByActions(items: SyncItem[]): Actions {
    const sortByActions = new Actions();
    sortByActions.CREATE = items.filter(item => item.action === ActionType.CREATE);
    sortByActions.UPDATE = items.filter(item => item.action === ActionType.UPDATE);
    sortByActions.DELETE = items.filter(item => item.action === ActionType.DELETE);
    return sortByActions;
  }

  private convertItems(items: Map<SyncObjectType, Actions>, spaceId: string): Map<SyncObjectType, ActionsFinalItem> {
    const finalObjectArray = new Map<SyncObjectType, ActionsFinalItem>();
    items.forEach((action, type, map) => {
      const finalObjectForThisType = new ActionsFinalItem();
      finalObjectForThisType.CREATE = action.CREATE.map(item => this.createItem(item, type, spaceId));
      finalObjectForThisType.UPDATE = action.UPDATE.map(item => this.createItem(item, type, spaceId));
      finalObjectForThisType.DELETE = action.DELETE.map(item => this.createItem(item, type, spaceId));
      finalObjectArray.set(type, finalObjectForThisType);
    });
    return finalObjectArray;
  }

  private createItem(item: SyncItem, type: SyncObjectType, spaceId: string): ModelElement {
    if(type === 'Space') {
      this.handleSpace2FAUpdation(item);
      return null;
    }
    const objectToSync = this.syncObjectFactory.create(item, type);
    return objectToSync;
  }

  updateSequenceToken(sequenceToken: string, siteId: string): void {
    if(siteId) {
      this.updateSiteSequenceToken(sequenceToken);
    } else {
      this.updateSpaceSequenceToken(sequenceToken);
    }
  }

  public updateSiteSequenceToken(sequenceToken: string): void {
    this.siteSequenceToken$.next(sequenceToken);
  }

  public getSiteSequenceToken(): string {
    return this.siteSequenceToken$.value;
  }

  public updateSpaceSequenceToken(sequenceToken: string): void {
    this.spaceSequenceToken$.next(sequenceToken);
  }

  public getSpaceSequenceToken(): string {
    return this.spaceSequenceToken$.value;
  }

  private handleSpace2FAUpdation(item: SyncItem): void {
    if(item.action === 'UPDATE' && item.type === 'Space') {
      if(item.payload.is2FAEnabled) {
        let session = this.sessionService.getSession();
        session.user.isUser2FAEnabled = item.payload.is2FAEnabled;
        session.user.is2FAStrictModeEnabled = session.user.is2FAStrictModeEnabled ? true : item.payload.is2FAStrictModeEnabled;
        this.sessionService.setSession(session);
      }
    }
  }
}
