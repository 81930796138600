import { Location } from '@models/location';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { Contractor } from '@models/contractor';
import { Author } from '@models/author';
import { TaskFilterModel } from '@services/event-search-filter.service';

export class EventsFilters {
  constructor(
    public dateFrom: Date,
    public dateTo: Date,
    public selectedContractors?: Contractor[],
    public selectedCreators?: Author[],
    public selectedTags?: Tag[],
    public selectedTasks?: Task[] | TaskFilterModel[],
    public selectedStatus?: string[],
    public selectedLocations?: Location[],
  ) {
  }
}
