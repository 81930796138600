<div [ngClass]="deviceIsMobile? 'mobile-container': 'container'" [formGroup]="parentFormGroup">
  <span [ngClass]="{'mobile-label': deviceIsMobile}" class="label">{{customField.title}}</span>
  <div [ngClass]="!deviceIsMobile? 'input-options' : 'mobile-input-options'">
    <div [ngClass]="{'mobile-option-container': deviceIsMobile}" *ngFor="let option of multipleChoiceOptions">
      <input *ngIf="isEdited" formControlName="value" type="radio"  [value]="option.id" id="option_{{option.value}}">
      <input *ngIf="!isEdited" formControlName="value" type="radio" [value]="option.id" id="option_{{option.value}}" disabled>
      <label for="option_{{option.value}}" [ngClass]="{'select-hover': isEdited, 'option-label': !deviceIsMobile, 'mobile-option-label': deviceIsMobile}">{{option.value}}</label>
    </div>
  </div>
</div>
