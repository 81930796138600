import { BackendTaskState, FilteredTask } from "./filtered-task";
import { ModelElement } from "./model-element";
import { Task } from "./task";
import { TaskContractor } from "./task-contractor";
import { TaskUser } from "./task-user";

export interface timelineTaskMinimalSubtask {
  id: string,
  taskNumber: number,
  title: string,
  startDatetime: number | Date,
  endDatetime: number | Date,
  state: string
}

export interface timelineSubtaskResource {
  parentTaskId: string,
  id: string,
  title: string
}

export class FilteredTimelineTask {

  id: string;
  taskNumber: number;
  parentTaskId: string;
  state: string;
  title: string;
  locationId: string;
  startDatetime: Date;
  endDatetime: Date;
  plannedQuantity: number;
  totalQuantityDone: number;
  progress: number;
  contractors: string[];
  users: string[];
  minimalSubtasks: timelineTaskMinimalSubtask[];

  constructor() {
    this.id = ModelElement.generateId();
    this.taskNumber = null;
    this.parentTaskId = null;
    this.state = null;
    this.title = '';
    this.locationId = null;
    this.startDatetime = new Date;
    this.endDatetime = null;
    this.plannedQuantity = null;
    this.totalQuantityDone = null;
    this.progress = 0;
    this.contractors = [];
    this.users = [];
    this.minimalSubtasks = [];
  }

  public static jsonToFilteredTimelineTask(json): Array<FilteredTimelineTask> {
    return json.map(filteredTimelineTaskObject => {
      const filteredTimelineTask: FilteredTimelineTask = new FilteredTimelineTask();
      filteredTimelineTask.id = filteredTimelineTaskObject.id;
      filteredTimelineTask.parentTaskId = filteredTimelineTaskObject.parentTaskId;
      filteredTimelineTask.state = filteredTimelineTaskObject.state;
      filteredTimelineTask.title = filteredTimelineTaskObject.title;
      filteredTimelineTask.locationId = filteredTimelineTaskObject.locationId;
      filteredTimelineTask.startDatetime = filteredTimelineTaskObject.startDatetime;
      filteredTimelineTask.endDatetime = filteredTimelineTaskObject.endDatetime;
      filteredTimelineTask.plannedQuantity = filteredTimelineTaskObject.plannedQuantity;
      filteredTimelineTask.totalQuantityDone = filteredTimelineTaskObject.totalQuantityDone;
      filteredTimelineTask.progress = filteredTimelineTaskObject.progress;
      filteredTimelineTask.contractors = filteredTimelineTaskObject.contractors;
      filteredTimelineTask.users = filteredTimelineTaskObject.users;
      filteredTimelineTask.taskNumber = filteredTimelineTaskObject.taskNumber;
      filteredTimelineTask.minimalSubtasks = filteredTimelineTaskObject.minimalSubtasks;
      return filteredTimelineTask;
    });
  }

  // convert task to timeline filtered task
  public static taskToTimelineFilteredTaskModel(task: Task, model: FilteredTimelineTask): void {
    task.updateTaskStatusAndIndicator();
    model.id = task.id;
    model.taskNumber = task.taskNumber;
    model.parentTaskId = task.parentTaskId;
    model.title = task.title;
    model.startDatetime = task.startDatetime;
    model.endDatetime = task.endDatetime;
    if (task.locationObject && task.locationObject !== null) {
      model.locationId = task.locationObject.id;
    }
    model.plannedQuantity = task.plannedQuantity;
    model.state = this.getTaskStateFromTask(task);
    model.totalQuantityDone = task.totalQuantityDone;
    model.progress = task.lastKnownProgress;
    model.contractors = TaskContractor.taskContractorsToDto(task.contractors);
    model.users = TaskUser.taskUsersToDto(task.users);
    model.minimalSubtasks = [];
  }

  public static convertTaskToTimelineTaskMinimalSubtask(task: Task): timelineTaskMinimalSubtask {
    task.updateTaskStatusAndIndicator();
    let timelineTaskMinimalSubtask: timelineTaskMinimalSubtask  = {}  as timelineTaskMinimalSubtask;
    timelineTaskMinimalSubtask.id = task.id;
    timelineTaskMinimalSubtask.taskNumber = task.taskNumber;
    timelineTaskMinimalSubtask.title = task.title;
    timelineTaskMinimalSubtask.startDatetime = task.startDatetime;
    timelineTaskMinimalSubtask.endDatetime = task.endDatetime;
    timelineTaskMinimalSubtask.state = this.getTaskStateFromTask(task);
    return timelineTaskMinimalSubtask;
  }

  // get task state based on current progress of task
  public static getTaskStateFromTask(task: Task): string {
    const taskStatusOrIndicator = task.getCurrentTaskStatusOrIndicator(task);
    return FilteredTask.BACKEND_TASK_STATE.find((backendTaskState) => {
      let state = taskStatusOrIndicator[0].name;
      if (backendTaskState.name === state) {
        return backendTaskState.value
      }
      else {
        return null;
      }
    }).value;
  }

  public static mapFilteredTimelineSubtasksToParentTimelineTask(timelineTasks: FilteredTimelineTask[]): FilteredTimelineTask[] {

    const taskMap: { [key: string]: FilteredTimelineTask } = {};

    for (const task of timelineTasks) {
      let timelineTask = this.mapTimelineTaskToNewTimelineTaskObject(task);
      taskMap[task.id] = timelineTask;
    }

    for (const timelineTaskCopy of timelineTasks) {
      let timelineTask = this.convertTimelineTaskToTimelineMinimalSubtask(timelineTaskCopy);
      const parentId = timelineTaskCopy.parentTaskId;
      if (parentId && taskMap[parentId]) {
        if(taskMap[parentId].minimalSubtasks.find((subtask) => subtask.id === timelineTask.id)) {
          taskMap[parentId].minimalSubtasks = taskMap[parentId].minimalSubtasks.map((subtask) => {
            return subtask.id === timelineTask.id ? timelineTask : subtask;
          });
        } else {
          taskMap[parentId].minimalSubtasks.push(timelineTask);
        }
      }
    }
    return timelineTasks.map((timelineTask: FilteredTimelineTask) => taskMap[timelineTask.id]);
  }

  // since the task model is converted to json for avoiding shallow copy issues.
  // need to convert the json to the object model.
  // convert timeline task json object to new timeline task
  public static mapTimelineTaskToNewTimelineTaskObject(timelineTaskCopy: FilteredTimelineTask): FilteredTimelineTask {
    let timelineFilteredTask = new FilteredTimelineTask();
    timelineFilteredTask.id = timelineTaskCopy.id;
    timelineFilteredTask.parentTaskId = timelineTaskCopy.parentTaskId;
    timelineFilteredTask.taskNumber = timelineTaskCopy.taskNumber;
    timelineFilteredTask.title = timelineTaskCopy.title;
    timelineFilteredTask.state = timelineTaskCopy.state;
    timelineFilteredTask.startDatetime = timelineTaskCopy.startDatetime;
    timelineFilteredTask.endDatetime = timelineTaskCopy.endDatetime;
    timelineFilteredTask.locationId = timelineTaskCopy.locationId;
    timelineFilteredTask.plannedQuantity = timelineTaskCopy.plannedQuantity;
    timelineFilteredTask.totalQuantityDone = timelineTaskCopy.totalQuantityDone;
    timelineFilteredTask.progress = timelineTaskCopy.progress;
    timelineFilteredTask.contractors = [...timelineTaskCopy.contractors];
    timelineFilteredTask.users = [...timelineTaskCopy.users];
    return timelineFilteredTask;
  }

  public static convertTimelineTaskToTimelineMinimalSubtask(timelineTask: FilteredTimelineTask): timelineTaskMinimalSubtask {
    let timelineTaskMinimalSubtask: timelineTaskMinimalSubtask  = {}  as timelineTaskMinimalSubtask;
    timelineTaskMinimalSubtask.id = timelineTask.id;
    timelineTaskMinimalSubtask.taskNumber = timelineTask.taskNumber;
    timelineTaskMinimalSubtask.title = timelineTask.title;
    timelineTaskMinimalSubtask.startDatetime = timelineTask.startDatetime;
    timelineTaskMinimalSubtask.endDatetime = timelineTask.endDatetime;
    timelineTaskMinimalSubtask.state = timelineTask.state;
    return timelineTaskMinimalSubtask;
  }
}
