import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ColorPalette } from '@models/enums/color-palette';
import { EditingTool } from '@models/enums/editing-tool';
import { ToolbarProperty } from '@models/enums/toolbar-property.enum';
import { TextWidget, ToolbarMessageService } from '@services/toolbar-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-canvas-toolbar',
  templateUrl: './canvas-toolbar.component.html',
  styleUrls: ['./canvas-toolbar.component.scss'],
})
export class CanvasToolbarComponent implements OnInit {
  @Output() emitNewText: EventEmitter<null> = new EventEmitter();

  public ColorPalette = ColorPalette;
  public selectedBrushColor: ColorPalette = ColorPalette.Black;
  public showBrushColorPalette: boolean = false;
  public showBrushWidthDialog: boolean = false;
  public selectedBrushWidth: string = '1';
  public selectedTextSize: string = '15';
  public selectedTextColor: ColorPalette = ColorPalette.Red;
  public showTextColorPalette: boolean = false;
  public showTextSizeDialog: boolean = false;
  public activeWidgetSubscription: Subscription;
  public disableTextToolbar: boolean = true;
  public activeWidget: TextWidget;

  constructor(private toolbarService: ToolbarMessageService) { }

  ngOnInit() {
    this.subscribeToActiveWidgetSubscription();
  }

  public subscribeToActiveWidgetSubscription() {
    this.activeWidgetSubscription = this.toolbarService.activeWidget.subscribe(activeWidget => {
      this.closeAllDialogs();
      this.setActiveWidgetStyle(activeWidget);
    })
  }

  public setActiveWidgetStyle(activeWidget: TextWidget) {
    if(!activeWidget) {
      this.disableTextToolbar = true;
      this.activeWidget = null;
      return;
    }
    
    this.activeWidget = activeWidget;
    const size = activeWidget.size;
    this.selectedTextSize = size.substring(0, size.length - 2);
    this.selectedTextColor = activeWidget.color as ColorPalette;
  }

  public closeAllDialogs() {
    this.showBrushColorPalette = false;
    this.showBrushWidthDialog = false;
    this.showTextColorPalette = false;
    this.showTextSizeDialog = false;
  }

  public onColorSelect(color: ColorPalette,tool: string) {
    if(tool === EditingTool.Brush) {
      this.selectedBrushColor = color;
    } else if(tool === EditingTool.Text) {
      this.selectedTextColor = color;
    } else {
      return;
    }

    this.closeAllDialogs();

    this.toolbarService.broadcastMessage({
      tool,
      id: this.activeWidget ? this.activeWidget.id : null,
      property: ToolbarProperty.Color,
      value: color,
    })
  }

  public toggleBrushColorDialog() {
    this.showBrushWidthDialog = false;
    this.showBrushColorPalette = !this.showBrushColorPalette;
  }

  public toggleBrushWidthDialog() {
    this.showBrushColorPalette = false;
    this.showBrushWidthDialog = !this.showBrushWidthDialog;
  }

  public toggleTextColorDialog() {
    this.showTextSizeDialog = false;
    this.showTextColorPalette = !this.showTextColorPalette;
  }

  public toggleTextSizeDialog() {
    this.showTextColorPalette = false;
    this.showTextSizeDialog = !this.showTextSizeDialog;
  }

  public onSizeChange(event: Event,tool: EditingTool) {
    const value = (event.target as HTMLInputElement).value;

    if(tool === EditingTool.Brush) {
      this.selectedBrushWidth = value;
    } else if(tool === EditingTool.Text) {
      this.selectedTextSize = value;
    } else {
      return
    }

    this.toolbarService.broadcastMessage({
      tool,
      id: this.activeWidget ? this.activeWidget.id : null,
      property: ToolbarProperty.Size,
      value: value,
    })
  }

  public onUndoStroke() {
    this.closeAllDialogs();
    this.toolbarService.broadcastMessage({
      tool: EditingTool.Brush,
      property: ToolbarProperty.Undo,
      value: null,
    })
  }

  public clearCanvas() {
    this.closeAllDialogs();
    this.toolbarService.broadcastMessage({
      tool: EditingTool.Brush,
      property: ToolbarProperty.Clear,
      value: null,
    })
  }

  public addText() {
    this.emitNewText.emit();
  }

  public deleteText() {
    this.toolbarService.broadcastMessage({
      tool: EditingTool.Text,
      id: this.activeWidget ? this.activeWidget.id : null,
      property: ToolbarProperty.Delete,
      value: null,
    })
  }

  ngOnDestroy(): void {
    this.activeWidgetSubscription.unsubscribe();
  }
}
