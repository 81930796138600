import { Component, OnInit, Input } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';
import { AppTypeService } from '@services/app-type.service';
import { DeviceService } from '@services/device.service';


@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent extends AbstractButtonComponent implements OnInit {
  @Input() link: boolean = false;
  @Input() style: 'normal' | 'reverse' = 'normal';

  buttonText: string;

  constructor(
    public appTypeService: AppTypeService,
    public deviceService: DeviceService
    ){
    super();
  }

  ngOnInit(): void {
    this.buttonText = (this.style === 'normal') ? 'events.detail.btn.reject' : 'events.detail.panel_creation.rejected';
  }

}
