import { Address } from './utils/address';
import { Picture } from './picture';
import { User } from './user';
import { ModelElement } from './model-element';
import { TemperatureUnitEnum } from '@models/enums/temperature-unit.enum';
import { WindUnitEnum } from '@models/enums/wind-unit.enum';

export class Site implements ModelElement {
  id: string;
  revision: string;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  users: User[];
  logo: Picture;
  address: Address;
  windUnit: WindUnitEnum;
  temperatureUnit: TemperatureUnitEnum;
  isArchived: boolean;

  constructor(
    id: string = ModelElement.generateId(),
    revision: string = '',
    name: string = '',
    description: string = '',
    latitude: number = null,
    longitude: number = null,
    users: User[] = [],
    logo: Picture = new Picture(),
    address: Address = new Address(),
    windUnit: WindUnitEnum = WindUnitEnum.METRIC,
    temperatureUnit: TemperatureUnitEnum = TemperatureUnitEnum.CELSIUS,
    isArchived: boolean = false
  ) {
    this.id = id;
    this.revision = revision;
    this.name = name;
    this.description = description;
    this.latitude = latitude;
    this.longitude = longitude;
    this.users = users;
    this.logo = logo;
    this.address = address;
    this.windUnit = windUnit;
    this.temperatureUnit = temperatureUnit;
    this.isArchived = isArchived;
  }

  /**
   * Sort sites by name
   * @param sites given sites
   */
  public static sortByName(sites: Array<Site>): Array<Site> {
    return sites.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
      b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);
  }

  public static toCardModel(json: any, model: Site) {
    model.id = json.payload.id;
    model.name = json.payload.name;
    model.address.locality = json.payload.locality;
    model.description = json.payload.description;
  }

  /**
   * Convert backend data to Model
   * @param json The given backend Object
   * @param model The given model
   */
  public static toModel(json: any, model: Site) {
    model.id = json.payload.id;
    model.name = json.payload.name;
    model.description = json.payload.description;

    if (json.payload.coordinates !== null) {
      model.latitude = json.payload.coordinates.latitude;
      model.longitude = json.payload.coordinates.longitude;
    }

    if (json.payload.address !== null) {
      model.address = json.payload.address;
    }
    if (json.payload.windUnit) {
      if (json.payload.windUnit === WindUnitEnum.IMPERIAL) {
        model.windUnit = WindUnitEnum.IMPERIAL;
      } else {
        model.windUnit = WindUnitEnum.METRIC;
      }
    }

    if (json.payload.temperatureUnit) {
      if (json.payload.temperatureUnit === TemperatureUnitEnum.FAHRENHEIT) {
        model.temperatureUnit = TemperatureUnitEnum.FAHRENHEIT;
      } else {
        model.temperatureUnit = TemperatureUnitEnum.CELSIUS;
      }
    }
    model.isArchived = json.payload.archived;
  }

  /**
   * Convert frontend model to backend model
   */
  toDTO() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      coordinates: {
        latitude: this.latitude,
        longitude: this.longitude
      },
      address : this.address,
      temperatureUnit: this.temperatureUnit,
      windUnit: this.windUnit,
      archived: this.isArchived
    };
  }

  public get label(): string {
    return this.name;
  }
}
