import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../dialog.service';
import { flatMap } from 'rxjs/operators';
import { EventComponent } from '../../home/site/event/event.component';
import { BACKUP_TYPES } from '@services/backup/backup.service';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      if (component instanceof EventComponent) {
        return this.translateService.get('unsaved.leave_page')
        .pipe(flatMap(confirmationText => this.dialogService.confirmWithBackup(component, BACKUP_TYPES.EVENT, confirmationText)));
      } else {
        return this.translateService.get('unsaved.leave_page')
        .pipe(flatMap(confirmationText => this.dialogService.confirm(confirmationText)));
      }
    }  
  }
}
