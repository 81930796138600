import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { Logger } from '../logger';
import { UserRightsDAO, UserRole } from './user-rights-dao.service';
import { map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { SyncStatusService } from '@services/synchronization/sync-status.service';
import { SyncSequenceDAO } from '@services/synchronization/sync-sequence-DAO.service';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';


@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  /**
   * User rights matrix
   * @constant
   */
  public static readonly USER_RIGHTS = {
    site: {
      event: {
        view: {
          own: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteTeamMember, UserRole.siteViewer],
          all: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteViewer],
        },
        create: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteTeamMember],
        manage: {
          unlocked: {
            own: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteTeamMember],
            all: [UserRole.spaceAdmin, UserRole.siteSupervisor],
          },
          locked: [],
        },
        approve: {
          unlocked: [UserRole.spaceAdmin, UserRole.siteSupervisor],
          locked: [],
        },
        manage_resources: [UserRole.spaceAdmin, UserRole.siteSupervisor],
        export: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteViewer, UserRole.spaceManager, UserRole.spaceUser, UserRole.siteTeamMember],
        deleteApproved: [UserRole.spaceAdmin,UserRole.siteSupervisor]
      },
      task: {
        view: {
          assigned: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteTeamMember, UserRole.siteViewer],
          all: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteViewer],
        },
        manage: {
          assigned: [UserRole.spaceAdmin, UserRole.siteSupervisor],
          all: [UserRole.spaceAdmin, UserRole.siteSupervisor],
        },
        create: [UserRole.spaceAdmin, UserRole.siteSupervisor],
        delete: [UserRole.spaceAdmin, UserRole.siteSupervisor],
        report_progress: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteTeamMember],
        export: [UserRole.spaceAdmin, UserRole.siteSupervisor, UserRole.siteViewer, UserRole.siteTeamMember],
        approve: {
          unlocked: [UserRole.spaceAdmin, UserRole.siteSupervisor],
          locked: [],
        }
      },
      team: {
        manage: [UserRole.spaceAdmin, UserRole.siteSupervisor],
      },
      resources: {
        manage: [UserRole.spaceAdmin, UserRole.siteSupervisor],
      },
      edit: [UserRole.spaceAdmin, UserRole.siteSupervisor],
      dashboards: [UserRole.siteSupervisor, UserRole.siteViewer]
    },
    space: {
      sites: {
        view: {
          assigned: [UserRole.spaceAdmin, UserRole.spaceManager, UserRole.spaceUser],
          all: [UserRole.spaceAdmin],
        },
        create: [UserRole.spaceAdmin, UserRole.spaceManager],
      },
      users: {
        manage: [UserRole.spaceAdmin],
      },
      resources: {
        manage: [UserRole.spaceAdmin],
      },
      customForm: {
        edit: [UserRole.spaceAdmin],
      },
      edit: [UserRole.spaceAdmin],
      dashboards: [UserRole.spaceAdmin]
    },
  };
  // noinspection JSMethodCanBeStatic
  /**
   * use {@link UserRightsService.USER_RIGHTS} instead whenever possible
   * @constant
   */
  get USER_RIGHTS() {
    return UserRightsService.USER_RIGHTS;
  }

  private currentRoles$ = new BehaviorSubject<UserRole[]>([]);

  private currentSpaceRole: UserRole;
  private currentSiteRole: UserRole;

  constructor(
    private sharedService: SharedService,
    private userRightsDAO: UserRightsDAO,
    private logger: NGXLogger,
    private syncStatusService: SyncStatusService,
    private syncSequenceService: SyncSequenceDAO,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService
  ) {

    if (this.deviceService.isMobile) {
      combineLatest(
        this.sharedService.watchSpaceId,
        this.userRightsDAO.watchRoleChanges(),
      ).subscribe(([spaceId, newRole]) => {
        if (this.sharedService.hasSpaceSelected) {
          this.userRightsDAO.getSpaceRole(spaceId).then(newSpaceRole => {
            if (newSpaceRole !== this.currentSpaceRole) {
              this.currentSpaceRole = newSpaceRole;
              this.refreshCurrentRoles();
            }
          }).catch(error => {
            this.logger.error('Error while fetching the space role of the current user: ', error);
          });
        } else {
          this.currentSpaceRole = null;
          this.refreshCurrentRoles();
        }
      });
    }
    else {
      combineLatest(
        this.sharedDataService.watchSpaceId,
        this.userRightsDAO.watchRoleChanges(),
      ).subscribe(([spaceId, newRole]) => {
        if (this.sharedDataService.hasSpaceSelected) {
          this.userRightsDAO.getSpaceRole(spaceId).then(newSpaceRole => {
            if (newSpaceRole !== this.currentSpaceRole) {
              this.currentSpaceRole = newSpaceRole;
              this.refreshCurrentRoles();
            }
          }).catch(error => {
            this.logger.error('Error while fetching the space role of the current user: ', error);
          });
        } else {
          this.currentSpaceRole = null;
          this.refreshCurrentRoles();
        }
      });
    }

    if (this.deviceService.isMobile) {
      combineLatest(
        this.sharedService.watchSpaceId,
        this.sharedService.watchSiteId,
        this.userRightsDAO.watchRoleChanges(),
      ).subscribe(async ([spaceId, siteId, newRole]) => {
        if (spaceId && siteId) {
          let sequenceNumber = await this.syncSequenceService.getSequenceNumber(spaceId, siteId);
          // if sequence number is present, site user rights is fetched normally
          // if no sequence number we wait for the site sync to finish and later fetch user rights
          if (sequenceNumber) {
            this.updateSiteUserRole(spaceId, siteId);
          }
          else {
            await this.syncStatusService.waitForEndOfSiteSync().then(()=>{
              this.updateSiteUserRole(spaceId, siteId);
            })
          }
        } else {
          this.currentSiteRole = null;
          this.refreshCurrentRoles();
        }
      });
    }
    else {
      combineLatest(
        this.sharedDataService.watchSpaceId,
        this.sharedDataService.watchSiteId,
        this.userRightsDAO.watchRoleChanges(),
      ).subscribe(async ([spaceId, siteId, newRole]) => {
        if (spaceId && siteId) {
          this.updateSiteUserRole(spaceId, siteId);
        } else {
          this.currentSiteRole = null;
          this.refreshCurrentRoles();
        }
      });
    }
  }

  private static getAuthorizedRoles(right: string): UserRole[] {
    const rights = right.split('.');
    let userRights = UserRightsService.USER_RIGHTS;
    for (const subRight of rights) {
      if (subRight in userRights) {
        userRights = userRights[subRight];
      } else {
        Logger.error('Invalid user right: ', right);
        return [];
      }
    }
    if (Array.isArray(userRights)) {
      return userRights;
    } else {
      Logger.error('Invalid user right: ', right);
      return [];
    }
  }

  private refreshCurrentRoles() {
    const currentRoles = [];
    if (this.currentSpaceRole) {
      currentRoles.push(this.currentSpaceRole);
    }
    if (this.currentSiteRole) {
      currentRoles.push(this.currentSiteRole);
    }
    this.currentRoles$.next(currentRoles);
  }

  watchCurrentRoles(): Observable<UserRole[]> {
    return this.currentRoles$.asObservable();
  }

  /**
   * @returns The stored rights for the current space and site
   */
  getCurrentRights(): [UserRole | null, UserRole | null] {
    return [this.currentSpaceRole, this.currentSiteRole];
  }

  /**
   * Test if the current user is authorized to use the functionality in the specified site and space
   *
   * @param spaceId
   * @param siteId
   * @param authorizedRoles The authorized roles for this functionality
   * @returns true if the current user is authorized to access this functionality
   */
  userHasRightInSiteOrSpace(spaceId: string, siteId: string | null, authorizedRoles: UserRole[]): Promise<boolean> {
    const promises = [];
    if (spaceId) {
      promises.push(this.userRightsDAO.getSpaceRole(spaceId));
    }
    if (siteId) {
      promises.push(this.userRightsDAO.getSiteRole(spaceId, siteId));
    }
    if (promises.length > 0) {
      return Promise.all(promises).then(roles => this.userHasRight(roles, authorizedRoles));
    } else {
      return Promise.resolve(false);
    }
  }

  /**
   * Test if the specified roles are authorized to use the functionality
   *
   * @param userRoles The roles to test (usually the roles of the current user)
   * @param authorizedRoles The authorized roles for this functionality
   * @returns true if one of the authorizedRoles is present in the userRoles
   */
  userHasRight(userRoles: UserRole[], authorizedRoles: UserRole[]): boolean {
    return userRoles.find(role => role && authorizedRoles.includes(role)) !== undefined;
  }

  /**
   * Test if the current user is authorized to use the functionality
   *
   * @param authorizedRoles The authorized roles for this functionality
   * @returns true if the current user is authorized to access this functionality
   */
  hasRight(authorizedRoles: UserRole[]): boolean {
    return this.userHasRight(this.getCurrentRights(), authorizedRoles);
  }

  /**
   * Test if the current user is authorized to use the functionality
   *
   * @param authorizedRoles The authorized roles for this functionality
   * @returns true if the current user is authorized to access this functionality every time the current user roles changes
   */
  watchHasRight(authorizedRoles: UserRole[]): Observable<boolean> {
    return this.watchCurrentRoles().pipe(
      map(userRoles => this.userHasRight(userRoles, authorizedRoles))
    );
  }

  /**
   * Same as the {@link userHasRight} function, except the authorizedRoles entry is passed as a string
   *
   * @deprecated /!\ unlike the {@link userHasRight} function, the roles array is fetched at run time, and so the validity
   *             of the {right} param is not checked at compile time. Please use the function only when absolutely necessary
   * @param userRoles The roles to test (usually the roles of the current user)
   * @param right A string representing a value of the {@link UserRightsService.USER_RIGHTS} matrix
   * @returns true if the current user is authorized to access this functionality
   */
  userHasRightString(userRoles: UserRole[], right: string): boolean {
    const authorizedRoles = UserRightsService.getAuthorizedRoles(right);
    return this.userHasRight(userRoles, authorizedRoles);
  }

  /**
   * Same as the {@link hasRight} function, except the authorizedRoles entry is passed as a string
   *
   * @deprecated /!\ unlike the {@link hasRight} function, the roles array is fetched at run time, and so the validity
   *             of the {right} param is not checked at compile time. Please use the function only when absolutely necessary
   * @param right A string representing a value of the {@link UserRightsService.USER_RIGHTS} matrix
   * @returns true if the current user is authorized to access this functionality
   */
  hasRightString(right: string): boolean {
    return this.userHasRightString(this.getCurrentRights(), right);
  }

  // updates the site user role
  updateSiteUserRole(spaceId: string, siteId: string) {
    this.userRightsDAO.getSiteRole(spaceId, siteId).then(newSiteRole => {
      if (newSiteRole !== this.currentSiteRole) {
        this.currentSiteRole = newSiteRole;
        this.refreshCurrentRoles();
      }
    }).catch(error => {
      this.logger.error('Error while fetching the site role of the current user: ', error);
    });
  }
}
