import { isDevMode } from '@angular/core';

export const LOGGER_PREFIX  = '%c[SiteDiary]';

const noop = (): any => undefined;

export class Logger {

  static synchronization = new Logger(
    '[SiteDiary:Sync] 🔄', 'color: olive', false);

  static queue = new Logger(
    '[SiteDiary:Queue]🚌', 'color: teal', false);

  static debugging = new Logger(
    '[SiteDiary:DebuggingTools]🐞', 'color: orange',  true);

  static subLoggers = [Logger.synchronization, Logger.queue, Logger.debugging];

  public enabled: boolean;
  public readonly prefix: string;
  private readonly color: string;

  constructor(prefix: string, color = 'color: purple', enabled = true) {
    this.prefix = prefix;
    this.color = color;
    this.enabled = enabled;
  }

  get debug() {
    if (isDevMode() && this.enabled) {
      // tslint:disable-next-line:no-console
      return console.debug.bind(console, '🐱‍💻 %c' + this.prefix + '%c[debug]', this.color, 'color: grey');
    } else {
      return noop;
    }
  }
  static get debug() {
    if (isDevMode()) {
      // tslint:disable-next-line:no-console
      return console.debug.bind(console, '🐱‍💻 ' + LOGGER_PREFIX, 'color: grey');
    } else {
      return noop;
    }
  }

  get info() {
    if (isDevMode() && this.enabled) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console, '🐱‍👓 %c' + this.prefix + '%c[info]', this.color, 'color: cyan');
    } else {
      return noop;
    }
  }
  static get info() {
    if (isDevMode()) {
      // tslint:disable-next-line:no-console
      return console.info.bind(console, '🐱‍👓 ' + LOGGER_PREFIX, 'color: cyan');
    } else {
      return noop;
    }
  }

  get warn() {
    // tslint:disable-next-line:no-console
    return console.warn.bind(console, '😿 %c' + this.prefix + '%c[warn]', this.color, 'color: orange');
  }
  static get warn() {
    // tslint:disable-next-line:no-console
    return console.warn.bind(console, '😿 ' + LOGGER_PREFIX, 'color: orange');
  }

  get error() {
    // tslint:disable-next-line:no-console
    return console.error.bind(console, '🙀 %c' + this.prefix + '%c[error]', this.color, 'color: red');
  }
  static get error() {
    // tslint:disable-next-line:no-console
    return console.error.bind(console, '🙀 ' + LOGGER_PREFIX, 'color: red');
  }
}
