import { Component, OnInit } from '@angular/core';
import { Camera, ImageOptions, CameraResultType, CameraSource, Photo, GalleryImageOptions } from '@capacitor/camera';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ImageResizerService } from '@services/image/image-resizer.service';
import { StripeService } from '@services/stripe.service';
import { NGXLogger } from 'ngx-logger';
import { first, flatMap, map } from 'rxjs/operators';
import { ImagesInputComponent } from '../../../../../form/controls/shared/images-input/images-input.component';
import { Attachment } from '../../../../../models/attachment';
import { AndroidPermissionService } from '../../../../../services/android-permission.service';
import { DeviceService } from '../../../../../services/device.service';
import { FileTransfertService } from '../../../../../services/file-transfert.service';
import { UpgradeService } from '../../../../../subscription/upgrade.service';
import { SpinnerService } from '@services/spinner.service';
import { ToasterService } from '@services/toaster.service';

@Component({
  selector: 'app-mobile-gallery-popup',
  templateUrl: './mobile-gallery-popup.component.html',
  styleUrls: ['./mobile-gallery-popup.component.sass']
})
export class MobileGalleryPopupComponent implements OnInit {
  static readonly PICKER_QUALITY: number = 95;

  maximumImagesCount: number;
  isNewEvent: boolean;
  readOnly: boolean;
  imagesInputComponent: ImagesInputComponent;

  // ngx-viewer options
  public viewerOptions: any = {
    navbar: false,
    toolbar: false,
    transition: false
  };

  public showImageEditor: boolean = false;
  public editingImage: string | ArrayBuffer = '';
  public currentAttachmentId: string = ''; // Attachment which is being edited

  get attachments(): Attachment[] {
    return this.imagesInputComponent.attachments;
  }

  set attachments(value) { }

  constructor(private navParams: NavParams,
    private modalCtrl: ModalController,
    private fileTransfertService: FileTransfertService,
    private imagePicker: ImagePicker,
    public deviceService: DeviceService,
    public androidPermissionService: AndroidPermissionService,
    public stripeService: StripeService,
    private translateService: TranslateService,
    private upgradeService: UpgradeService,
    private logger: NGXLogger,
    private imageResizerService: ImageResizerService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
  ) {
    this.imagesInputComponent = this.navParams.get('imagesInputComponent');
    this.readOnly = this.navParams.get('readonly');
    this.isNewEvent = this.navParams.get('isNewEvent');
  }

  ngOnInit() { }

  /**
   * Method called on click of back button to cancel changes
   */
  ignoreChanges() {
    this.modalCtrl.dismiss();
  }

  /**
   * Method called on click of ok button to close popup
   */
  acceptChanges() {
    this.modalCtrl.dismiss();
  }

  /**
   * Method calling ionic native camera to take a picture
   */
  takePictureFromCamera() {
    if (this.deviceService.isMobile) {
      this.androidPermissionService.checkCameraPermission('mobile-camera-permission-missing')
        .then(hasPermission => {
          if (!hasPermission) {
            this.logger.error('Unable to get camera permission.');
            throw new Error('Unable to get camera permission.');
          }
          this.logger.debug('User opened camera from mobile device');
          const options: ImageOptions  = {
            quality: MobileGalleryPopupComponent.PICKER_QUALITY,
            source: CameraSource.Camera,
            saveToGallery: true,
            resultType: CameraResultType.DataUrl,
            correctOrientation: true,
          };
          return Camera.getPhoto(options);
        })
        .then((photo: Photo) => {
          return this.imagesInputComponent.convertPhotoIntoAttachment(photo)})
        .catch(error => {
          this.logger.error('Failed to upload an image from camera: ', error);
        });
    }
  }

  private async openMobileFilePicker(): Promise<void> {
    /*
     * On Android, the native file input does not work as expected and does not allow multiple selection.
     * On iOS, the cordova file picker causes the app to freeze and requires the app to be force stopped.
     * For this reason, we need to have the two methods in parallel.
     */
    if (this.deviceService.isAndroid) {
      try {
        let imagePaths = await this.imagePicker.getPictures({
          quality: MobileGalleryPopupComponent.PICKER_QUALITY,
          maximumImagesCount: this.maximumImagesCount,
        });
        if (this.maximumImagesCount && imagePaths.length > this.maximumImagesCount) {
          this.annoyUser();
          imagePaths = imagePaths.slice(this.maximumImagesCount);
        }
        const images = await this.fileTransfertService.getUploadableFilesFromGallery(imagePaths);
        await this.imagesInputComponent.uploadImages(images);
        this.logger.debug('Images were uploaded as Attachments from an android device');
      } catch (error) {
        this.logger.error('Failed to add an image from gallery: ', error);
      }
    } else {
      await document.getElementById('image').click();
    }
  }

  //TODO: Remove unused mobile specific methods for getting images
  async openPhotoGallery(): Promise<void> {
      const galleryOptions: GalleryImageOptions = {
        quality: MobileGalleryPopupComponent.PICKER_QUALITY,
        correctOrientation: true,
        limit: this.maximumImagesCount,
      };
      this.spinnerService.activate('pulsating');
      const cameraPermissionState = await Camera.checkPermissions();
      if((cameraPermissionState.photos !== 'granted' && cameraPermissionState.photos !== 'limited')) {
        const requestedPermissionState = await Camera.requestPermissions();
        if(requestedPermissionState.photos === 'denied') {
          this.spinnerService.deactivate();
          this.toasterService.showErrorToaster('mobile-read-external-storage-permission-missing');
        } else {
          this.openGallery(galleryOptions);
        }
      } else {
        this.openGallery(galleryOptions);
      }
  }

  async openGallery(galleryOptions: GalleryImageOptions): Promise<void> {
    try {
      const galleryPhotos = await Camera.pickImages(galleryOptions);
      if (this.maximumImagesCount && galleryPhotos.photos.length > this.maximumImagesCount) {
        this.annoyUser();
        galleryPhotos.photos = galleryPhotos.photos.slice(this.maximumImagesCount);
      }
      await this.imagesInputComponent.convertGalleryPhotosIntoAttachments(galleryPhotos.photos);
      this.spinnerService.deactivate();
    } catch(error) {
      this.spinnerService.deactivate();
      this.logger.error('Encountered an error while trying to add images using the photo gallery', error);
    }
  }

  /**
   * Method calling ionic native image picker to select multiple pictures
   */
  async loadPicturesFromGallery() {
    if (this.deviceService.isMobile) {
        return this.stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT').pipe(
          first(),
          map(hasMultiple => hasMultiple ? undefined : 1),
          flatMap(async maximumImagesCount => {
            this.maximumImagesCount = maximumImagesCount;
            if (this.deviceService.isAndroid && (this.deviceService.getVersion() === '10')) {
              await document.getElementById('image').click();
            } else {
              this.openPhotoGallery();
            }
          }),
        ).toPromise();
    }
  }

  onFileChange(files) {
    if (files.target.files && files.target.files.length) {
      this.imagesInputComponent.uploadImages(files.target.files);
    }
  }

  /**
   * Method called when added files from HMLT5 input (if cordova is unavailable)
   * @param data files added in the HTML5 file input
   */
  loadPicturesFromWeb(images) {
    this.imagesInputComponent.uploadImages(images);
    this.logger.debug('Images were uploaded as Attachments using the HTML5 input');
  }

  private async openImageEditor(image: string) {
    this.editingImage = image;
    this.showImageEditor = true;
  }

  public async editImage(attachment: Attachment) {
    const imageURL = this.imagesInputComponent.getPictureUrl(attachment, false);
    this.currentAttachmentId = attachment.id;
    const dataUrl = await this.imagesInputComponent.convertImageUrlToDataUrl(imageURL);
    this.openImageEditor(dataUrl);
  }

  public imageAfterEditing(image: string | null) {
    this.showImageEditor = false;
    if(image) {
      this.imagesInputComponent.replaceEditedImage(image, this.currentAttachmentId);
    }
  }

  /**
   * Method called on click of bin next to image to delete it
   * @param image file we want to delete
   */
  removeImage(image) {
    this.imagesInputComponent.removeAttachment(image);
  }

  annoyUser(): void {
    this.upgradeService.annoyUser('upgrade.feature.pictures');
  }
}
