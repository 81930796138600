import { ModelElement } from "./model-element";
import { NotificationContent } from "./notification-content";
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_VERSION } from "./notification-type";

export class Notification {
  id: string;
  spaceId: string;
  siteId: string;
  isRead: boolean;
  createdOn: number;
  notifiedAtString?: string;
  notificationContent: NotificationContent;
  notificationType: NOTIFICATION_TYPE;
  notificationTypeVersion: NOTIFICATION_TYPE_VERSION;

  constructor(
    id: string = ModelElement.generateId(),
    spaceId: string = '',
    siteId: string = '',
    isRead: boolean = false,
    createdOn: number = new Date().getTime(),
    notificationContent: NotificationContent = null,
    notificationType: NOTIFICATION_TYPE = null,
    notificationTypeVersion: NOTIFICATION_TYPE_VERSION = null,
  ) {
    this.id = id;
    this.spaceId = spaceId;
    this.siteId = siteId;
    this.isRead = isRead;
    this.createdOn = createdOn;
    this.notificationContent = notificationContent;
    this.notificationType = notificationType;
    this.notificationTypeVersion = notificationTypeVersion;
  }

  public static sortByCreatedOnTimeStamp(notifications: Notification[]): Notification[] {
    return notifications.sort((a, b) => {
      const date1 = new Date(a.createdOn);
      const date2 = new Date(b.createdOn);
      return date1 > date2 ? -1 : 1;
    });
  }

  /**
   * Convert the BE json to FE model
   * @param json
   * @param model Notification
   */
  public static toModel(json: any, model: Notification): void {
    model.id = json.id;
    model.createdOn = json.createdAt;
    model.isRead = json.isRead;
    model.siteId = json.siteId;
    model.spaceId = json.spaceId;
    model.notificationType = this.convertBENotificationTypeToFENotificationType(json.notificationType);
    model.notificationTypeVersion = this.convertBENotificationTypeVersionToFENotificationTypeVersion(json.typeVersion);
    model.notificationContent = this.convertNotificationContentJSONToNotificationContent(json.notificationContentJSON);
  }

  public static convertNotificationContentJSONToNotificationContent(notificationContentJSON: any): NotificationContent {
    try {
      let notificationContent: NotificationContent = {
        objectId: notificationContentJSON.objectId,
        objectTitle: notificationContentJSON.objectTitle,
        objectRevision: notificationContentJSON.objectRevision,
        objectCausedByName: notificationContentJSON.causedByUserName,
        objectTagNames: notificationContentJSON?.tagNames ? notificationContentJSON?.tagNames : null,
        objectTaskNumber: notificationContentJSON?.taskNumber ? notificationContentJSON?.taskNumber : null,
        objectParentTaskId: notificationContentJSON?.parentTaskId ? notificationContentJSON?.parentTaskId : null,
      };
      return notificationContent;
    } catch(error) {
      return null;
    }
  }

  public static convertBENotificationTypeToFENotificationType(jsonNotificationType: any): NOTIFICATION_TYPE {
    try {
      let notificationType = null;
      for(let type in NOTIFICATION_TYPE) {
        if(type === jsonNotificationType) {
          notificationType = type;
        }
      }
      return notificationType;
    } catch(error) {
      return null;
    }
  }

  public static convertBENotificationTypeVersionToFENotificationTypeVersion(jsonNotificationTypeVersion: any): NOTIFICATION_TYPE_VERSION {
    try {
      let notificationTypeVersion = null;
      for(let type in NOTIFICATION_TYPE_VERSION) {
        if(type === jsonNotificationTypeVersion) {
          notificationTypeVersion = type;
        }
      }
      return notificationTypeVersion;
    } catch(error) {
      return null;
    }
  }
}
