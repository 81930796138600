<ng-container>
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar
      doneLabel="btn.save"
      [title]="title"
      [disableDoneButton]="formGroup.invalid"
      (back)="dismiss()"
      (done)="save()">
    </app-mobile-toolbar>
  </ion-header>

    <ion-content class="main-form-wrapper whitesmoke-background">
      <form [formGroup]="formGroup">

        <ion-item-divider>
          <ion-label>
            <h3 class="pull-left">
              {{'mobile-resources-label-name' | translate}}
            </h3>
          </ion-label>
        </ion-item-divider>
        <div>
          <ion-input
            formControlName="name"
            placeholder="{{'mobile-placeholder-name' | translate}}"
            type="text">
          </ion-input>
        </div>

        <ion-item-divider *ngIf="isResourceTypeLabour()">
          <ion-label>
            <h3 class="pull-left">
              {{'mobile-resources-label-job-role' | translate}}
            </h3>
          </ion-label>
        </ion-item-divider>
        <div *ngIf="isResourceTypeLabour()">
          <ion-input
            formControlName="jobRole"
            placeholder="{{'mobile-placeholder-job-role' | translate}}"
            type="text"
            maxlength="50">
          </ion-input>
        </div>

        <ion-item-divider *ngIf="showPrice">
          <ion-label>
            <h3 class="pull-left">
              {{'mobile-resources-label-price' | translate}} {{ (sharedService.watchSpace | async)?.currency | currencyPipe | async}}/h
            </h3>
          </ion-label>
        </ion-item-divider>
        <div *ngIf="showPrice">
          <ion-input
            placeholder="{{ 'mobile-placeholder-price' | translate }}"
            formControlName="price"
            type="number">
          </ion-input>
        </div>

        <ion-item-divider *ngIf="!showPrice">
          <ion-label>
            <h3 class="pull-left">
              {{ "label.unit" | translate }}
            </h3>
          </ion-label>
        </ion-item-divider>
        <div *ngIf="!showPrice">
          <select formControlName="quantityUnit" class="form-select">
            <option value="" disabled selected hidden></option>
            <option *ngFor="let unit of quantitiesUnits | keyvalue: originalOrder" [value]="unit.key">{{ unit.value ?? "label.quantityUnit.null" | translate }}</option>
          </select>
        </div>

      </form>

      <section class="actions-mobile" *ngIf="!newItem">
        <app-delete-button (click)="showConfirmationAlert()"></app-delete-button>
      </section>

    </ion-content>
  </ng-container>
