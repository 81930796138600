/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { DeviceService } from '@services/device.service';
import { InternationalizationService } from '@services/i18n/internationalization.service';
import { NGXLogger } from 'ngx-logger';
import { Preferences } from '@capacitor/preferences';
import { UrlGiverService } from '@services/url-giver.service';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '@services/session.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  public static readonly pushTokenStorageKey = 'PUSH_TOKEN_KEY';
  public static readonly localeStorageKey = 'LOCALE_KEY';

  private areListenersConfigured: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private logger: NGXLogger,
    private intlService: InternationalizationService,
    private urlService: UrlGiverService,
    private http: HttpClient,
    private sessionService: SessionService
  ) {
    /**
     * Check for existing session. If exists, then register and check for FCM Token and locale change.
     */
    if (this.sessionService.getSession()) {
      this.logger.info('Session already exists. Checking for FCM token and locale update.');
      this.initializePushNotifications();
    }
  }

  public initializePushNotifications() {
    if (this.deviceService.isMobile && !this.deviceService.isMobileWeb) {
      this.register();
    } else {
      this.logger.info('Not setting up push notifications as device not recognised as a mobile.');
    }
  }

  private register() {
    this.logger.info('Initializing Push Notification Service');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        this.logger.error('Unable to register for Push Notifications. Result of registration attempt: ', result);
      }
    });
    // Listeners should be configured once per session. If done multiple times, on registration,
    // the block will be executed multiple times.
    if (!this.areListenersConfigured) {
      this.logger.info('Adding Push Notification Listeners.');
      this.addListeners();
    }
  }

  private addListeners() {
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        this.logger.info('Push registration success, token: ' + token.value);
        this.checkForPushTokenUpdate(token);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any) => {
        this.logger.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.logger.info('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.logger.info('Push action performed: ' + JSON.stringify(notification));
      }
    );
    this.areListenersConfigured = true;
  }

  /**
   * Checks if APNS/FCM Token exists locally. If not, stores the token locally and
   * on the server. If it does exist, checks if token has expired and requires update.
   * @param token APNS/FCM Token received from Firebase.
   */
  private async checkForPushTokenUpdate(token: Token): Promise<void> {
    await this.getPushTokenFromPreferences().then(currentToken => {
      if (currentToken === null) {
        this.logger.info('No push token record present in the database.');
        this.setPushTokenInPreferences(token);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.checkForLocaleUpdate(token.value, true);
      } else {
        this.logger.info('Fetched existing push token from DB: ' + currentToken);
        /**
         * Here, we check if the token that is received from Firebase is different
         * from the one that is currently stored in the preferences.
         * If yes, we need to update the token in the preferences as well as on the server.
         */
        if (currentToken === token.value) {
          this.logger.info('No update required for push token');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.checkForLocaleUpdate(token.value, false);
        } else {
          this.logger.info('New Push Token received. Updating locally and on the server.');
          this.setPushTokenInPreferences(token);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.checkForLocaleUpdate(token.value, true);
        }
      }
    });
  }

  /**
   * After checking if Push Token requires update, we then check if Locale has been updated and update the values
   * locally and on the server accordingly.
   * @param token APNS/FCM Token received from Firebase.
   * @param requiresTokenUpdate True if the current token is not present, or has expired and
   * has to be synced to the server.
   */
  private async checkForLocaleUpdate(token: string, requiresTokenUpdate: boolean): Promise<void> {
    await this.getCurrentLocaleFromPreferences().then((localeInPreferences) => {
      const locale = this.intlService.currentLocale;
      const currentLocale = locale.toLowerCase() === 'fr' ? 'FR' : 'EN';
      if (localeInPreferences === null) {
        this.logger.info('Current Locale record not present in the database.');
        this.setCurrentLocaleInPreferences(currentLocale);
        this.updatePushTokenOnServer(token, currentLocale);
      } else {
        this.logger.info('Fetched existing locale from DB: ' + localeInPreferences);
        /**
         * Here, we check if the locale has been updated.
         * If yes, we need to update the locale in the preferences as well as on the server.
         */
        if (currentLocale === localeInPreferences) {
          this.logger.info('No update required for locale');
          /**
           * If the token requires update but not the locale, we still need to update the token on the server.
           */
          if (requiresTokenUpdate) {
            this.updatePushTokenOnServer(token, currentLocale);
          }
        } else {
          this.logger.info('Locale requires update. Updated locale: ' + currentLocale);
          this.setCurrentLocaleInPreferences(currentLocale);
          this.updatePushTokenOnServer(token, currentLocale);
        }
      }
    });
  }

  private updatePushTokenOnServer(token: string, locale: string): void {
    const url = this.urlService.givePushNotificationAPIUrl();
    this.http.post(url, null, {
      params: {
        fcmToken: token,
        deviceLocale: locale
      }
    }).toPromise().then((response) => {
      this.logger.info('Successfully updated Push Token and Locale on server: ', response);
    }).catch(error => {
      this.logger.error('Error updating Push token and Locale on server: ', error);
    });
  }

  private async setPushTokenInPreferences(token: Token) {
    await Preferences.set({
      key: PushNotificationService.pushTokenStorageKey,
      value: token.value
    }).then(() => {
      this.logger.info('Successfully updated the push token in preferences.');
    }).catch(error => {
      this.logger.error('Unable to store push token in preferences: ', error);
    });
  }

  private async getPushTokenFromPreferences(): Promise<string> {
    return await Preferences.get({
      key: PushNotificationService.pushTokenStorageKey
    }).then((result) => result.value);
  }

  private async setCurrentLocaleInPreferences(locale: string) {
    await Preferences.set({
      key: PushNotificationService.localeStorageKey,
      value: locale
    }).then(() => {
      this.logger.info('Successfully updated locale in preferences.');
    }).catch(error => {
      this.logger.error('Unable to store locale in preferences: ', error);
    });
  }

  private async getCurrentLocaleFromPreferences(): Promise<string> {
    return await Preferences.get({
      key: PushNotificationService.localeStorageKey
    }).then((result) => result.value);
  }
}
