import { AbstractControl, ValidationErrors } from "@angular/forms";

export function numberNotPositiveIntegerValidator(
  assets: AbstractControl
): ValidationErrors {
  if (assets && assets.value) {
    for (let asset of assets.value) {
      if (asset.amount < 0 || !Number.isInteger(asset.amount)) {
        return { amountIsNotInteger: true };
      }
    }
  }
  return {};
}
