import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@models/event';
import * as moment from 'moment';


@Pipe({
  name: 'filterEventByDate'
})
export class FilterEventByDatePipe implements PipeTransform {
  // Pipe allowing to filter events of a given date
  transform(events:  Event[], date: moment.Moment): Event[] {
    if (events && date) {
      return events.filter(e => moment(e.startDatetime).startOf('day').isSame(date));
    }
    return [];
  }

}
