import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// There are two types of spinners. Their use depends on the relative length of the loading time.
export type SpinnerType =
  | 'pulsating' // The pulsating loader should be used for short loading times (e.g. API call for subscription payment)
  | 'rotating' // The pulsating loader should be used for longer loading times (e.g. report generation)
;

export type SpinnerState = {
  isActive: false;
} | {
  isActive: true;
  message: string;
  type: SpinnerType;
  appTypeInputString: string;
};

export type appType =
  | 'administration'
  | 'diary'
  | 'tasks'
;

const DISABLED: SpinnerState = {
  isActive: false,
};

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerSubject = new BehaviorSubject<SpinnerState>(DISABLED);
  public readonly spinner = this.spinnerSubject.asObservable();

  constructor() {
  }

  public activate(type: SpinnerType, message: string = '', appTypeInputString: appType = null) {
    this.spinnerSubject.next({
      isActive: true,
      message: message,
      type: type,
      appTypeInputString: appTypeInputString,
    });
  }

  public deactivate() {
    this.spinnerSubject.next(DISABLED);
  }

}
