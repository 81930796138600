import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OfflineWeatherErrorService } from '@services/offline-weather-error.service';

@Component({
  selector: 'app-offline-weather-error',
  templateUrl: './offline-weather-error.component.html',
})

export class OfflineWeatherErrorComponent implements OnInit, OnDestroy {
  coordinateSubscription: Subscription;
  siteCoordinateSubscription: Subscription;
  isSiteCoordinatesAvailable: boolean;
  isCoordinatesAvailable: boolean;

  constructor(
    private offlineWeatherErrorService: OfflineWeatherErrorService,
  ) {}

  ngOnInit() {
    this.coordinateSubscription = this.offlineWeatherErrorService.currentCoordinates.subscribe((value) => {
      this.isCoordinatesAvailable = value;
    })
    this.siteCoordinateSubscription = this.offlineWeatherErrorService.currentSiteCoordinates.subscribe((value) => {
      this.isSiteCoordinatesAvailable = value;
    })
  }

  ngOnDestroy() {
    this.coordinateSubscription.unsubscribe();
    this.siteCoordinateSubscription.unsubscribe();
  }
}
