import { AssetCategory } from './../../../models/asset';
import { Contractor } from './../../../models/contractor';
import { AssetService } from './../../../services/asset.service';
import { UserRightsService } from '../../../services/user-rights/user-rights.service';
import { ContractorService } from './../../../services/contractor.service';
import { SharedService } from './../../../services/shared/shared.service';
import { FormatSortService } from './../../../services/format-sort.service';
import { ModalController, NavParams, IonSearchbar } from '@ionic/angular';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TagService } from '../../../services/tag.service';
import { Tag } from '../../../models/tag';
import { Asset } from '../../../models/asset';
import { Location } from '../../../models/location';
import { IntercomService } from '../../../services/intercom.service';
import { ResourceType } from '@models/resource-type';
import { Labelable } from '@models/labelable';
import { Identifiable } from '@models/identifiable';
import { Subscription } from 'rxjs';
import { LocationService } from '@services/location.service';
import { CustomDatepickerI18nService } from '@services/i18n/date-internationalization.service';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { ResourcesMobileModalComponent } from 'app/home/resources-mobile/resources-mobile-modal/resources-mobile-modal.component';
import { QuantityUnitsService } from '@services/quantity-units.service';
import { UtilsService } from '@services/utils.service';
import { NetworkStatus } from '@models/synchronization/network-status';
import { ToasterService } from '@services/toaster.service';

class SelectableItem implements Labelable {
  public readonly id = this.item.id;
  public readonly label = this.item.label;
  constructor(
    public readonly item: Identifiable & Labelable,
    public selected: boolean,
  ) { }
}

export type SelectionMode =
  | 'single' // Select a single item (similar to <input type="radio">)
  | 'multiple_basic' // Select multiple items (similar to <input type="checkbox">)
  | 'multiple_with_quantity' // Select multiple items, and set a quantity for each selected item
  | 'multiple_with_quantity_and_duration' // Select multiple items, and set a quantity and a duration for each selected item
;

@Component({
  selector: 'app-ressource-modal',
  templateUrl: './item-selector-popup.component.html',
  styleUrls: ['./item-selector-popup.component.sass']
})
export class ItemSelectorPopupComponent implements OnInit, OnDestroy {
  public readonly titles = {
    'plants': 'label.plant_types',
    'labours': 'label.labour_types',
    'materials': 'label.materials_types',
    'tags': 'label.details',
    'contractors': 'label.contractors',
    'tasks': 'label.tasks',
    'location': 'label.locations',
  };

  readonly canCreate: boolean;

  readonly type: ResourceType;

  readonly selectionMode: SelectionMode;

  readonly updateItemsFunction: (items: (Identifiable & Labelable)[], type: ResourceType) => void;

  readonly completeItemList: (Identifiable & Labelable)[];

  readonly selectableItemList: SelectableItem[];

  @ViewChild('searchInput', { static: true }) searchInput: IonSearchbar;

  itemFilter: string = '';

  showCreationButton: boolean = false;
  localizedFormat: string;
  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  quantityUnits = {}

  private quantitySubscription: Subscription;

  constructor(
    private sharedService: SharedService,
    public modalCtrl: ModalController,
    private navParams: NavParams,
    private contractorService: ContractorService,
    private tagService: TagService,
    public userRightsService: UserRightsService,
    private assetService: AssetService,
    private intercomService: IntercomService,
    private changeDetector: ChangeDetectorRef,
    private locationService: LocationService,
    private toasterService: ToasterService,
    private customDatepickerI18nService: CustomDatepickerI18nService,
    private quantityUnitsService: QuantityUnitsService
  ) {
    this.canCreate = this.navParams.get('canCreate');
    this.completeItemList = this.navParams.get('items');
    this.type = this.navParams.get('type');
    this.selectionMode = this.navParams.get('selectionMode');
    this.updateItemsFunction = this.navParams.get('updateItemsFunction');
    const selectedItems: (Identifiable & Labelable)[] = this.navParams.get('selectedItems') || [];

    this.selectableItemList = this.completeItemList.map(item => this.makeSelectableItem(item, selectedItems));
   }

  ngOnInit() {
    this.changeDetector.detach();
    this.changeDetector.detectChanges();
    this.quantitySubscription = this.quantityUnitsService.watchQuantityUnits.subscribe((quantityUnits) => {
      this.quantityUnits = quantityUnits;
    })
  }

  ngOnDestroy(): void {
      UtilsService.safeUnsubscribe(this.quantitySubscription);
  }

  private makeSelectableItem(item: Identifiable & Labelable, selectedItems: (Identifiable & Labelable)[]): SelectableItem {
    const selectedOriginalItem = selectedItems.find(selectedItem => selectedItem.id === item.id);
    if (item && item.hasOwnProperty('progress')) {
      this.localizedFormat = this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime().replace(/-/g,'/');
      item['startDate'] = new Date(item['task'].startDatetime);
      item['endDate'] = new Date(item['task'].endDatetime)
    }
    if (selectedOriginalItem && selectedOriginalItem.hasOwnProperty('amount')) {
      item['amount'] = selectedOriginalItem['amount'];
    }
    if (selectedOriginalItem && selectedOriginalItem.hasOwnProperty('duration')) {
      item['duration'] = selectedOriginalItem['duration'];
    }
    return new SelectableItem(item, selectedOriginalItem !== undefined);
  }

  /**
   * Method called on click of back button to cancel changes
   */
  public async ignoreChanges(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  public async saveChanges(): Promise<void> {
    this.updateItemsFunction(this.selectableItemList.filter(item => item.selected).map(item => item.item), this.type);
    await this.modalCtrl.dismiss();
  }

  public selectItem(selectedItem: SelectableItem): void {
    if (this.selectionMode === 'single') {
      this.selectableItemList.forEach(item => item.selected = item.id === selectedItem.id);
    } else {
      selectedItem.selected = true;
    }
    this.changeDetector.detectChanges();
  }

  public deselectItem(selectedItem: SelectableItem): void {
    selectedItem.selected = false;
    this.changeDetector.detectChanges();
  }

  public search(itemFilter: string): void {
    this.itemFilter = itemFilter;
    this.showCreationButton = this.shouldDisplayCreationButton();
    this.changeDetector.detectChanges();
  }

  private shouldDisplayCreationButton(): boolean {
    return this.canCreate
      && this.itemFilter !== ''
      && this.userRightsService.hasRight(this.userRightsService.USER_RIGHTS.site.resources.manage)
      && this.selectableItemList.every(item => FormatSortService.formatText(item.label) !== FormatSortService.formatText(this.itemFilter));
  }

  public async createAndSelectItem(): Promise<void> {
    if (this.isOnline) {
      const createdItem = await this.createItem();
      this.completeItemList.push(createdItem);
      this.selectableItemList.unshift(new SelectableItem(createdItem, true));
      this.intercomService.trackUserAction(IntercomUserActions.AddSiteResources);
      this.searchInput.value = '';
      this.search('');
    } else {
      this.toasterService.showWarningToaster("device.offline.asset_information_update");
    }
  }

  // TODO Move this method to asset/tag/contractor service
  private async createItem(): Promise<Identifiable & Labelable> {
    const siteId = await this.sharedService.currentSiteId;
    switch (this.type) {
      case 'contractors': {
        const newContractor = new Contractor();
        newContractor.name = this.itemFilter;
        newContractor.siteId = siteId;
        const createdContractor = await this.contractorService.create(newContractor);
        return this.contractorService.convertContractorToEventContractor(createdContractor);
      }
      case 'labours':
      case 'plants': {
        const newAsset = new Asset();
        newAsset.name = this.itemFilter;
        newAsset.siteId = siteId;
        newAsset.category = this.type === 'labours' ? AssetCategory.LABOURS
          : this.type === 'plants' ? AssetCategory.EQUIPMENTS
          : AssetCategory.MATERIALS;
        const createdAsset = await this.assetService.create(newAsset);
        return this.assetService.convertAssetToNamedEventAsset(createdAsset);
      }
      case 'materials': {
        const createdAsset = await this.openNewAssetModal();
        return this.assetService.convertAssetToNamedEventAsset(createdAsset);
      }
      case 'tags': {
        const newTag = new Tag();
        newTag.name = this.itemFilter;
        newTag.siteId = siteId;
        const createdTag = await this.tagService.create(newTag);
        return this.tagService.convertTagToEventTag(createdTag);
      }
      case 'location': {
        const newLocation = new Location();
        newLocation.name = this.itemFilter;
        newLocation.siteId = siteId;
        const createdLocation = await this.locationService.create(newLocation);
        return this.locationService.convertLocationToEventLocation(createdLocation);
      }
    }
    throw new Error(`Trying to create an item of type ${this.type} from item selector`);
  }

  itemHeightFn(item, index) {
    //force ion-item height to have good ion-virtual-scroll display
     return 59;
  }

  async openNewAssetModal(): Promise<Asset> {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: ResourcesMobileModalComponent,
        componentProps: {
          resourceType: AssetCategory.MATERIALS,
          newItem: true,
          assetName: this.itemFilter,
          siteId: this.sharedService.currentSiteId,
          allAssets: this.completeItemList,
          successCallback: (addedAsset) => {
            modal.dismiss(addedAsset);
          },
          dismissCallback: () => modal.dismiss(),
        },
      });
      modal.onDidDismiss().then((result) => {
        const returnedValue = result.data;
        resolve(returnedValue);
      });
      await modal.present();
    });
  }

  limitDecimal(event, item): void {
    if(this.type === 'materials') {
      const input = event.target as HTMLInputElement;
      const value = input.value;
      const parts = value.split('.');
      if (parts.length > 2 || (parts.length === 2 && parts[1].length > 1)) {
        // More than one decimal point or more than one digit after the decimal point
        input.value = parts[0] + '.' + parts[1].charAt(0);
        item.amount = parseFloat(input.value);
      }
    }
  }
}
