import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Asset } from '@models/asset';
import { Contractor } from '@models/contractor';
import { ServerError } from '@models/errors/server-error';
import { Event } from '@models/event';
import { ModelElement } from '@models/model-element';
import { Site } from '@models/site';
import { SiteUser } from '@models/site-user';
import { Space } from '@models/space';
import { NetworkStatus } from '@models/synchronization/network-status';
import { SyncSequence } from '@models/synchronization/sync-sequence';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { Location } from '@models/location';
import { User } from '@models/user';
import { Logger } from '@services/logger';
import { SessionService } from '@services/session.service';
import { DatabaseService } from '@services/shared/database.service';
import { SharedService } from '@services/shared/shared.service';
import { AlertBox, AlertLevel, ToasterService } from '@services/toaster.service';
import { UserRightsDAO } from '@services/user-rights/user-rights-dao.service';
import { QueueService } from './queue.service';
import { SyncSequenceDAO } from './sync-sequence-DAO.service';
import { SiteService } from '@services/site.service';
import { Actions, SyncObjectFactory, SyncObjectType, ActionsFinalItem } from './sync-object-factory.service';
import { ActionType, SyncDto } from '@models/synchronization/sync-dto';
import { SyncStatusService, SyncStatus, SyncState } from '@services/synchronization/sync-status.service';
import { SpinnerService } from '@services/spinner.service';
import { SpaceDatabase } from '@models/db/space-database';
import { TaskService } from '@services/task.service';
import { AbstractModelService } from '@services/abstract-model-service';
import { AssetService } from '@services/asset.service';
import { ContractorService } from '@services/contractor.service';
import { EventService } from '@services/event.service';
import { TagService } from '@services/tag.service';
import { LocationService } from '@services/location.service';
import { NGXLogger } from 'ngx-logger';
import { CustomEventFormService } from '@services/custom-event-form.service';
import { DeviceService } from '@services/device.service';
import { UrlGiverService } from '@services/url-giver.service';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';
import { SyncResponse } from '@models/synchronization/sync-response';
import { SyncItem } from '@models/synchronization/sync-item';
import { Router } from '@angular/router';
import { UserSettingsService } from '@services/user-settings.service';



const SPACE_SYNC_VERSION = '1';
const SITE_SYNC_VERSION = '4';
const EVENTS_MOBILE_SYNC_LIMIT = 50;
const EVENTS_WEB_SYNC_LIMIT = 250;
const SITE_SYNC_VALID_SEQUENCE_LIMIT = 259200000; // 3 days in ms

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  private readonly tenantUrl: string;

  protected currentUser: User;
  navigateToSiteSelectScreen = false;

  constructor(
    private databaseService: DatabaseService,
    private syncSequenceService: SyncSequenceDAO,
    private sharedService: SharedService,
    private toastr: ToasterService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private http: HttpClient,
    private queueService: QueueService,
    private userRightsDAO: UserRightsDAO,
    private siteService: SiteService,
    private syncObjectFactory: SyncObjectFactory,
    private syncStatusService: SyncStatusService,
    private spinnerService: SpinnerService,
    private taskService: TaskService,
    private assetService: AssetService,
    private contractorService: ContractorService,
    private eventService: EventService,
    private tagService: TagService,
    private locationService: LocationService,
    private logger: NGXLogger,
    private customEventFormService: CustomEventFormService,
    private deviceService: DeviceService,
    private urlGiverService: UrlGiverService,
    private appUpdateSettingsService: AppUpdateSettingsService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {
    this.sessionService.watchSession().subscribe((currentSession) => {
      if (currentSession && currentSession.user) {
        this.currentUser = currentSession.user;
      } else {
        this.currentUser = null;
      }
    });
    this.tenantUrl = environment.apiUrl + '/tenant';

    if (this.deviceService.isMobile) {
      this.sharedService.watchSpaceId.subscribe(spaceId => {
        this.synchronizeSpace(spaceId);
      });
    }

    if (this.deviceService.isMobile) {
      this.sharedService.watchSpaceAndSiteIds.subscribe(([spaceId, siteId]) => {
        this.synchronizeWithSpaceAndSite(spaceId, siteId);
      });
    }

    this.syncStatusService.watchSpaceSyncStatus.subscribe(syncStatus => {
      this.checkSyncStatusForErrors(syncStatus);
    });

    this.syncStatusService.watchSiteSyncStatus.subscribe(syncStatus => {
      this.checkSyncStatusForErrors(syncStatus);
    });
  }

  private checkSyncStatusForErrors(syncStatus: SyncStatus): void {
    if (syncStatus.status === 'error') {
      this.toastr.showAlertBox(new AlertBox(
        'sync-warning',
        AlertLevel.warning,
        'alertbox.sync-warning',
        false,
        true
      ));
      this.logger.error('The server synchronization has failed. [spaceID, siteID]:', syncStatus.spaceId, syncStatus.siteId)
    } else {
      this.toastr.clearAllAlertBoxes();
    }
  }

  /**
   * Method to trigger space sync
   */
  public async synchronizeSpace(spaceId: string): Promise<void> {
    if (spaceId) {
      if (NetworkStatus.isOnline) {
        try {
          this.syncStatusService.communicateSpaceSyncStatus('in-progress', spaceId);
          const items = await this.executeSynchronization(spaceId);
          if (items.length === 0) {
            Logger.synchronization.info(`Space sync (${spaceId}) done, no changes.`);
          } else {
            Logger.synchronization.info(`Space sync (${spaceId}) done.`);
          }
          this.syncStatusService.communicateSpaceSyncStatus('success', spaceId);
          this.spinnerService.deactivate();
        } catch (error) {
          this.logger.error('Error during space sync: ', error);
          this.syncStatusService.communicateSpaceSyncStatus('error', spaceId);
          this.spinnerService.deactivate();
          throw error;
        }
      }
    }
    this.syncStatusService.communicateSpaceSyncStatus('stopped');
  }

  /**
   * Method to trigger sync with space and site already defined
   */
  public async synchronizeWithSpaceAndSite(spaceId: string, siteId: string): Promise<void> {
    if (spaceId && siteId && NetworkStatus.isOnline) {
      try {
        this.syncStatusService.communicateSiteSyncStatus('in-progress', spaceId, siteId);
        this.sharedService.setCanSyncSpinnerActivate(true);
        await this.queueService.waitForSiteCreation(spaceId, siteId);
        const items = await this.executeSynchronization(spaceId, siteId);
        if (items.length === 0) {
          Logger.synchronization.info(`Site sync (${spaceId}/${siteId}) done, no changes.`);
        } else {
          Logger.synchronization.info(`Site sync (${spaceId}/${siteId}) done.`);
        }
        this.syncStatusService.communicateSiteSyncStatus('success', spaceId, siteId);
      } catch (error) {
        this.logger.error('Error during site sync: ', error);
        this.syncStatusService.communicateSiteSyncStatus('error', spaceId, siteId);
        throw error;
      }
    }
    this.syncStatusService.communicateSiteSyncStatus('stopped');
  }

  private async sendSyncRequest(spaceId: string, siteId?: string, sequenceNumber?: string): Promise<SyncResponse> {
    let syncUrl;
    if (siteId) {
      syncUrl = this.tenantUrl + '/' + spaceId + '/sites/' + siteId + '/sync';
    } else {
      syncUrl = this.tenantUrl + '/' + spaceId + '/sync';
    }


    let params = new HttpParams()
    .set('version', siteId ? SITE_SYNC_VERSION : SPACE_SYNC_VERSION);
    if (sequenceNumber) {
      params = params
        .set('seq', sequenceNumber);
    }
    if (siteId) {
      const siteModelVersion = await this.siteService.getModelVersion(siteId);
      if (siteModelVersion !== SITE_SYNC_VERSION) {
        params = params
          .set('updateFromVersion', siteModelVersion);
      }
    }
    return this.http.get<SyncResponse>(syncUrl, { 'params': params }).toPromise()
      .catch(error => {
        this.logger.error(`Error fetching sync data (${spaceId}/${siteId ? siteId : ''})`, error);
        this.spinnerService.deactivate();
        this.toastr.showAlertBox(new AlertBox(
          'sync-warning',
          AlertLevel.warning,
          'alertbox.sync-warning',
          false,
          true
        ));
        throw new ServerError(`Error fetching sync data (${spaceId}/${siteId ? siteId : ''})`, error);
      });
  }

  public async executeSynchronization(spaceId: string, siteId?: string): Promise<ModelElement[]> {
    Logger.synchronization.info(`Requesting sync for ${spaceId}/${siteId ? siteId : ''} ...`);
    this.logger.debug(`Requesting sync for ${spaceId}/${siteId ? siteId : ''} ...`);
    this.communicateSyncStatusAfterUpdate(spaceId, siteId, 'in-progress');
    let sequenceNumber = await this.syncSequenceService.getSequenceNumber(spaceId, siteId);

    /**
     * Force space synchronization when database is flagged as corrupted
     */
    if (sequenceNumber === SyncSequence.DATABASE_FLAG_CORRUPTED) {
      await this.databaseService.clearDatabase(spaceId, siteId);
      // Here it looks like on a new sync everything is just fetched all over again?
      sequenceNumber = null;
    }
    let response: SyncResponse = null;
    try {
      if(this.deviceService.isMobile && siteId && !sequenceNumber) {
        response = await this.sendSubsetSyncRequest(spaceId, siteId);
      } else if(this.deviceService.isMobile && siteId && !this.isSequenceTokenInValidRange(sequenceNumber)) {
        response = await this.sendSubsetSyncRequest(spaceId, siteId);
      } else {
        response = await this.sendSyncRequest(spaceId, siteId, sequenceNumber);
      }
    } catch (error) {
      this.logger.error("Error in getting response", false);
    }
    if (response && response.items && response.items.length > 0) {
      try {
        const items = await this.syncSpaceOrSite(spaceId, siteId, response.items, sequenceNumber ? false : true);
        this.syncSequenceService.setSequenceNumber(spaceId, siteId, response.seq);
        this.siteService.setModelVersion(siteId, SITE_SYNC_VERSION);
        this.communicateSyncStatusAfterUpdate(spaceId, siteId, 'success');
        if(this.navigateToSiteSelectScreen) {
          // if current site is archived, navigate users to site select screen.
          this.router.navigate(['/space', spaceId , 'select-site'])
          .then(() => {
            this.userSettingsService.set('lastVisitedSite', null);
            this.navigateToSiteSelectScreen = false;
          });
        }
        return items;
      } catch (error) {
        this.communicateSyncStatusAfterUpdate(spaceId, siteId, 'error');
        this.logger.error(`Error saving sync data (${spaceId}/${siteId ? siteId : ''}): ` + error);
        throw new Error(`Error saving sync data (${spaceId}/${siteId ? siteId : ''}): ` + error);
      }
    }
    this.communicateSyncStatusAfterUpdate(spaceId, siteId, 'stopped');
    return [];
  }

  private async syncSiteOrSpaceByItemsType(
    items: Map<SyncObjectType, ActionsFinalItem>,
    spaceId: string,
    siteId: string
  ): Promise<ModelElement[]> {
    try {
      const saveItems = await this.executeDataSaving(items, siteId);
      if (siteId) {
        this.logger.info(`[Site] Synchronized items for site ${siteId} in space ${spaceId}`);
      } else {
        this.logger.info(`[Space] Synchronized items for space ${spaceId}`);
      }
      return saveItems;
      } catch (error) {
        if (siteId) {
          this.logger.error(`[Site] There was an error while synchronizing site ${siteId} in space ${spaceId}:`, error);
        } else {
          this.logger.error(`[Space] There was an error while synchronizing space ${spaceId}:`, error);
        }
        throw error;
      }
  }

  /**
   * Call the limited sync api to receive all sync object but with limited events and tasks
   * @param spaceId
   * @param siteId
   * @returns SyncResponse
   */
  private async sendSubsetSyncRequest(spaceId: string, siteId: string): Promise<SyncResponse> {
    const  syncUrl = this.urlGiverService.giveLatestSubsetSyncAPIUrl(spaceId, siteId);
    const eventsLimit = EVENTS_MOBILE_SYNC_LIMIT;
    let params = new HttpParams()
    .set('version', SITE_SYNC_VERSION)
    .set('limit', eventsLimit);

    return this.http.get<SyncResponse>(syncUrl, { 'params': params }).toPromise()
      .catch(error => {
        this.logger.error(`Error fetching sync data (${spaceId}/${siteId ? siteId : ''})`, error);
        this.spinnerService.deactivate();
        this.toastr.showAlertBox(new AlertBox(
          'sync-warning',
          AlertLevel.warning,
          'alertbox.sync-warning',
          false,
          true
        ));
        throw new ServerError(`Error fetching sync data (${spaceId}/${siteId ? siteId : ''})`, error);
      });
  }

  private async executeDataSaving(convertedItems: Map<SyncObjectType, ActionsFinalItem>, currentSiteId: string): Promise<ModelElement[]> {
    // Contains all promise for all tables and actions
    const waitingAll: Promise<ModelElement[]>[] = [];
    convertedItems.forEach(async (actions, type) => {
      waitingAll.push(
        this.performActionsInIndexDB(ActionType.CREATE, actions.CREATE, type, currentSiteId),
        this.performActionsInIndexDB(ActionType.UPDATE, actions.UPDATE, type, currentSiteId),
        this.performActionsInIndexDB(ActionType.DELETE, actions.DELETE, type, currentSiteId),
      );
    });

    // Wait items saved before continue
    const savedElementsByTypes: ModelElement[][] = await Promise.all(waitingAll);
    const savedElements: ModelElement[] = savedElementsByTypes.reduce((accumulator, value) => accumulator.concat(value), []);
    return savedElements;
  }

  /**
   * Method to sync site items in indexedDB
   * @param items items which need to be synchronize with local data
   */
  private syncSpaceOrSite(spaceId: string, siteId: string | undefined, items: SyncItem[], isFreshSync?: boolean): Promise<ModelElement[]> {
    const orderingItems = this.sortSyncItemByType(items);
    const finalObjects = this.convertItems(orderingItems, spaceId);
    if(isFreshSync) {
      //this.logger.debug(`Fresh syncing of ${siteId ? 'Site' : 'Space'} items::-`, finalObjects);
    } else {
      this.logger.debug(`Syncing of ${siteId ? 'Site' : 'Space'} items:`, finalObjects);
    }
    return this.syncSiteOrSpaceByItemsType(finalObjects, spaceId, siteId);
  }

  public convertItems(items: Map<SyncObjectType, Actions>, spaceId: string): Map<SyncObjectType, ActionsFinalItem> {
    const finalObjectArray = new Map<SyncObjectType, ActionsFinalItem>();
    items.forEach((action, type, map) => {
      const finalObjectForThisType = new ActionsFinalItem();
      finalObjectForThisType.CREATE = action.CREATE.map(item => this.createItem(item, type, spaceId));
      finalObjectForThisType.UPDATE = action.UPDATE.map(item => this.createItem(item, type, spaceId));
      finalObjectForThisType.DELETE = action.DELETE.map(item => this.createItem(item, type, spaceId));
      finalObjectArray.set(type, finalObjectForThisType);
    });
    return finalObjectArray;
  }

  public createItem(item: SyncItem, type: SyncObjectType, spaceId: string): ModelElement {
    if(type === 'Space') {
      this.handleSpace2FAUpdation(item);
      return null;
    }
    const objectToSync = this.syncObjectFactory.create(item, type);
    if (item.type === 'Site') {
      this.siteService.setModelVersion(objectToSync.id, SITE_SYNC_VERSION);
    }
    if (item.type === 'SiteUser' && objectToSync.id === this.currentUser.id) {
      // Synchronize user role if concerned SiteUser is the current user
      this.logger.info('Attempting synchronisation of Site user role of the current user. [currentUser, spaceId, SiteUser, SyncItem]', this.currentUser, spaceId, objectToSync as SiteUser, item);
      this.synchronizeCurrentSiteUserRole(spaceId, objectToSync as SiteUser, item.action);
    }
    return objectToSync;
  }

  /**
   * Deal with execution of a "site action"
   * @param spaceId concerned space id
   * @param siteId concerned site id
   * @param item item received from backend
   * @param seq optional sequence (used when synchronizing one element received from websocket)
   */
  public async synchronizeSiteAction(spaceId: string, siteId: string, item: SyncItem, seq?: string): Promise<ModelElement> {
    const objectToSync = this.syncObjectFactory.create(item, item.type);

    try {
      const syncedItem = await this.performActionInIndexDB(item.action, objectToSync, item.type);
      this.logger.debug('[Site] Synchronized item:', item.action, syncedItem);
      // Update site sequence number
      if (seq) {
        this.syncSequenceService.setSiteSequenceNumber(spaceId, siteId, seq);
      }
      return syncedItem;
    } catch (error) {
      this.logger.error('[Site] There was an error while synchronizing:', error, objectToSync);
      throw error;
    }
  }

  /**
   * Deal with execution of a "space action"
   * @param spaceId concerned space id
   * @param item item received from backend
   * @param seq sequence (optional, used only for action received from websocket)
   */
  public async synchronizeSpaceAction(spaceId: string, item: SyncItem, seq?: string): Promise<ModelElement> {
    const objectToSync = this.createItem(item, item.type, spaceId);

    try {
      // Perform space action in indexDB
      const syncedItem = await this.performActionInIndexDB(item.action, objectToSync, item.type);
      this.logger.debug('[Space] Synchronized item:', item.action, syncedItem);
      // Update space sequence number
      if (seq) {
        this.syncSequenceService.setSpaceSequenceNumber(spaceId, seq);
      }
      return syncedItem;
    } catch (error) {
      this.logger.error('[Space] There was an error while synchronizing:', error, objectToSync);
      throw error;
    }
  }

  private async performActionsInIndexDB(
    actionType: ActionType,
    objectsToSync: ModelElement[],
    objectType: SyncObjectType,
    currentSiteId: string
  ): Promise<ModelElement[]> {
    if (objectsToSync.length === 0) {
      return [];
    }

    if (objectType === 'SiteUser') {
      if (actionType === 'UPDATE' || actionType === 'CREATE') {
        const db = await this.databaseService.getDBInstant();
        this.logger.info("Bulk creating/updating of site users in IndexDB.", objectsToSync);
        return db._bulkPut(objectsToSync, objectType, false);
      } else if (actionType === 'DELETE') {
        const db = await this.databaseService.getDBInstant();
        this.logger.info("Bulk delete of site users in IndexDB.", objectsToSync);
        await db._bulkDelete(objectsToSync, objectType);
        return objectsToSync;
      }
    }
    if (objectType === 'Site') {
      // filter all archived sites before adding to the DB
      if (actionType === 'CREATE') {
        let sites = objectsToSync as Site[];
        objectsToSync = sites.filter(site => !site.isArchived);
        sites.forEach(site => {
          if(currentSiteId && (site.id === currentSiteId) && site.isArchived) {
            this.navigateToSiteSelectScreen = true;
          }
        });
      }
      // delete archived site if already present in DB. 
      else if (actionType === 'UPDATE') {
        let sites = objectsToSync as Site[];
        objectsToSync = sites.filter(site => !site.isArchived);
        sites.forEach(site => {
          this.siteService.delete(site, false);
          if (currentSiteId && (site.id === currentSiteId) && site.isArchived) {
            this.navigateToSiteSelectScreen = true;
          }
        });
      }
      // reset current site when current site is deleted
      else if (actionType === 'DELETE') {
        let sites = objectsToSync as Site[];
        if(currentSiteId && sites.find((site) => site.id === currentSiteId)) {
          this.sharedService.resetCurrentSite();
          this.navigateToSiteSelectScreen = true;
        }
      }
    }
    const service = this.getRelevantService(objectType);
    switch (actionType) {
      case 'CREATE': return service?.createMany(objectsToSync, false);
      case 'UPDATE': return service?.updateMany(objectsToSync, false);
      case 'DELETE': return service?.deleteMany(objectsToSync);
      default:
        this.logger.error("Unknown action type for performing bulk action in indexDB. [actionType]:", actionType);
        throw new Error('Unknown action type: ' + actionType);
    }
  }

  /**
   * Method to perform a given CUD action for a given object in indexDB
   * @param actionType "CREATE", "UPDATE" or "DELETE"
   * @param objectToSync Object to synchronize in indexDB
   * @param objectType Type of the Object to synchronize
   */
  private async performActionInIndexDB(
    actionType: ActionType,
    objectToSync: ModelElement,
    objectType: SyncObjectType
  ): Promise<ModelElement> {
    if (objectType === 'SiteUser') {
      if (actionType === 'UPDATE' || actionType === 'CREATE') {
        const db = await this.databaseService.getDBInstant();
        this.logger.info("Creating/updating site user in IndexDB");
        return db._put(objectToSync, objectType, false);
      } else if (actionType === 'DELETE') {
        const db = await this.databaseService.getDBInstant();
        if (await db._exists(objectToSync, objectType)) {
          this.logger.info("Deleting site user in IndexDB");
          return db._delete(objectToSync, objectType, false);
        } else {
          return objectToSync;
        }
      }
    }
    const service = this.getRelevantService(objectType);
    switch (actionType) {
      case 'CREATE': return service?.create(objectToSync, false);
      case 'UPDATE': return service?.update(objectToSync, false);
      case 'DELETE': return service?.delete(objectToSync, false);
      default:
        this.logger.error("Unknown action type for performing single action in indexDB. [actionType]:", actionType);
        throw new Error('Unknown action type: ' + actionType);
    }
  }

  private getRelevantService<T>(objectType: SyncObjectType): AbstractModelService<any> {
    switch (objectType) {
      case 'Asset': return this.assetService;
      case 'Contractor': return this.contractorService;
      case 'Event': return this.eventService;
      case 'EventV4': return this.eventService;
      case 'Location': return this.locationService;
      case 'Site': return this.siteService;
      case 'SiteUser':
        this.logger.error("Site users have no dedicated service");
        throw new Error('Site users have no dedicated service');
      case 'Tag': return this.tagService;
      case 'Task': return this.taskService;
      case 'TaskNewApi': return this.taskService;
      case 'SiteForm': return this.customEventFormService;
      case 'SubsetTask': return this.taskService;
      case 'Space': return null;
    }
  }

  /**
   * Synchronize current user's role from SiteUser DTO
   * @param spaceId Concerned space id
   * @param siteUser SiteUser DTO
   * @param action type of action (DELETE, UPDATE or CREATE)
   */
  private async synchronizeCurrentSiteUserRole(spaceId: string, siteUser: SiteUser, action: ActionType): Promise<void> {
    if (action === 'CREATE' || action === 'UPDATE') {
      // Update user's role on given site
      await this.userRightsDAO.addSiteRole(spaceId, siteUser.siteId, siteUser.role);
    } else if (action === 'DELETE') {
      // Remove user's role on given site
      await this.userRightsDAO.removeSiteRole(spaceId, siteUser.siteId);
      // Delete site from local db (user should not access it anymore)
      const db = await this.databaseService.getDBInstant();
      await db._deleteById(siteUser.siteId, 'Site');
    }
  }

  /**
   * Sort sync items by types
   * @param items Items to sort
   * @returns A sorted SyncItem array
   */
  private sortSyncItemByType(items: SyncItem[]): Map<SyncObjectType, Actions> {
    const typeArray = new Map<SyncObjectType, Actions>();

    const types: SyncObjectType[] = ['SiteUser', 'Site', 'Asset', 'Tag', 'Contractor', 'Task', 'TaskNewApi' , 'Location', 'SiteForm', 'Event', 'EventV4', 'Space'];
    for (const type of types) {
      typeArray.set(type, this.regroupSyncItemsByActions(items.filter(item => item.type === type)));
    }

    return typeArray;
  }

  /**
   * Regroup syncItems by actions
   * @param items Items array to regroup by action
   */
  private regroupSyncItemsByActions(items: SyncItem[]): Actions {
    const sortByActions = new Actions();
    sortByActions.CREATE = items.filter(item => item.action === ActionType.CREATE);
    sortByActions.UPDATE = items.filter(item => item.action === ActionType.UPDATE);
    sortByActions.DELETE = items.filter(item => item.action === ActionType.DELETE);
    return sortByActions;
  }

  // This function communicates the sync status of the sync request called if sequence tokens are deleted after an update
  private communicateSyncStatusAfterUpdate(spaceId: string, siteId: string, syncStatus: SyncState): void {
    const willFreshSyncAfterUpdate = localStorage.getItem('clearedSequenceTokenAfterUpdate');
    if(willFreshSyncAfterUpdate && siteId) {
      this.syncStatusService.communicateSiteSyncAfterUpdateStatus(syncStatus, spaceId, siteId);
    }
    // clear the temporary variable after the initial sync. Further sync will follow the normal flow
    if(syncStatus !== 'in-progress') {
      localStorage.removeItem('clearedSequenceTokenAfterUpdate');
    }
  }

  isSequenceTokenInValidRange(sequenceToken: string): boolean {
    const currentDay = new Date().getTime();
    const differenceInSequenceTime = currentDay - new Date(sequenceToken).getTime();
    if(differenceInSequenceTime > SITE_SYNC_VALID_SEQUENCE_LIMIT) {
      return false;
    } else {
      return true;
    }
  }

  private handleSpace2FAUpdation(item: SyncItem): void {
    if(item.action === 'UPDATE' && item.type === 'Space') {
      if(item.payload.is2FAEnabled) {
        let session = this.sessionService.getSession();
        session.user.isUser2FAEnabled = item.payload.is2FAEnabled;
        session.user.is2FAStrictModeEnabled = session.user.is2FAStrictModeEnabled ? true : item.payload.is2FAStrictModeEnabled;
        this.sessionService.setSession(session);
      }
    }
  }
}
