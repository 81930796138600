import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonSlides} from '@ionic/angular';

type DismissCallback = () => void;

interface SlideOpts {
  initialSlide: number;
  speed: number
}

interface OnboardingPageData {
  title: string;
  image: string;
  heading1: string;
  heading2: string;
  description: string;
  slideNumber: number;
  isLastSlide: boolean;
  subDescription?: string;
}

@Component({
  selector: 'app-onboarding-mobile',
  templateUrl: './onboarding-mobile.component.html',
  styleUrls: ['./onboarding-mobile.component.sass'],
})
export class OnboardingMobileComponent implements OnInit {

  @ViewChild('onboardingSlider',  { static: true })  slides: IonSlides;
  @Input() private dismissCallback: DismissCallback;

  slideOpts: SlideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  slideNumber: number = 0

  adminOnboardingPagesData: OnboardingPageData[] = [
    {
      title: 'onboarding.welcome.title',
      image: '/assets/img/on-boarding/on-boarding-welcome.svg',
      heading1: 'onboarding.welcome.heading1',
      heading2: 'onboarding.welcome.heading2',
      description:'onboarding.welcome.description',
      slideNumber: 0,
      isLastSlide: false
    },
    {
      title: 'onboarding.event_form.title',
      image: '/assets/img/on-boarding/on-boarding-event-form.svg',
      heading1: 'onboarding.event_form.heading1',
      heading2: 'onboarding.event_form.heading2',
      description: 'onboarding.event_form.description',
      slideNumber: 1,
      isLastSlide: false,
      subDescription: 'onboarding.event_form.sub_description'
    },
    {
      title: 'onboarding.custom_tags.title',
      image: '/assets/img/on-boarding/on-boarding-tag.svg',
      heading1: 'onboarding.custom_tags.heading1',
      heading2: 'onboarding.custom_tags.heading2',
      description: 'onboarding.custom_tags.description',
      slideNumber: 2,
      isLastSlide: false,
      subDescription: 'onboarding.custom_tags.sub_description'
    },
    {
      title: 'onboarding.invite_users.title',
      image: '/assets/img/on-boarding/on-boarding-users.svg',
      heading1: 'onboarding.invite_users.heading1',
      heading2: 'onboarding.invite_users.heading2',
      description: 'onboarding.invite_users.description',
      slideNumber: 3,
      isLastSlide: false,
      subDescription: 'onboarding.invite_users.sub_description'
    },
    {
      title: 'onboarding.diary_entry.title',
      image: '/assets/img/on-boarding/on-boarding-diary.svg',
      heading1: 'onboarding.diary_entry.heading1',
      heading2: 'onboarding.diary_entry.heading2',
      description: 'onboarding.diary_entry.description',
      slideNumber: 4,
      isLastSlide: true,
      subDescription: 'onboarding.diary_entry.sub_description'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.slideNumber = 0;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  numSequence(n: number): Array<number> { 
    return Array(n); 
  } 

  swipeNext(): void {
    this.slideNumber = this.slideNumber + 1
    this.slides.slideNext();
  }

  swipePrevious(): void {
    this.slideNumber = this.slideNumber - 1
    this.slides.slidePrev();
  }

  updateSlideNumber(): void {
    this.slides.getActiveIndex().then(index => {
      this.slideNumber = index
    })
  }

}
