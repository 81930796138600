<ion-header class="secondary-header" mode="ios">
  <app-mobile-toolbar
       doneLabel="ok"
       title="weather_location"
       (back)="closePopup()"
       (done)="saveAndClosePopup()">
  </app-mobile-toolbar>
</ion-header>

<ion-grid class="scrollable-content ion-no-padding ion-no-margin">
  <ion-list justify-content-center align-items-center>
      <app-select-gps id="select-gps-mobile" [zoom]="11" [withAutoComplete]="true"
                      [latitude]="latitude" [longitude]="longitude"
                      (coordinatesChange)="onCoordinatesChange($event)" >
      </app-select-gps>
  </ion-list>
</ion-grid>

