import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateManipulationService {

  constructor() { }

  /**
   * Get the Day, 7 days ago
   * @param _date start date
   */
  get7DaysAgo(_date: Date) {
    return moment(_date).startOf('day').subtract(7, 'days').toDate();
  }

  getXDaysAgo(date: Date, numberOfDays: number): Date {
    return moment(date).startOf('days').subtract(numberOfDays, 'days').toDate();
  }

  getNbDaysBetweenDates(earlierDate: any, laterDate: any) {
    return (new Date(laterDate).getTime() - new Date(earlierDate).getTime()) / (1000 * 3600 * 24);
  }

  /**
   * Method returning an array containing the last days (Moment objects) for a given number
   * @param numberOfDays number of days we want to store in array
   */
  getArrayOfLastDays(numberOfDays: number): moment.Moment[] {
    const dateArray = new Array();
    for (let i = 0; i <= numberOfDays; i++) {
      const date = moment();
      date.startOf('day');
      dateArray.push(date.subtract(i, 'days'));
    }
    return dateArray;
  }

  /**
   * Method returning first and last day of the last 30 days from a given date
   * @param dateFrom date we want to get last 30 days from
   */
  get30dayDates(dateFrom): any {
    const past30day = <any>{};
    past30day.dateTo = moment(dateFrom);
    past30day.dateFrom = moment(dateFrom);
    // Last day is the day before at 23:59
    past30day.dateTo.subtract('1', 'days');
    past30day.dateTo.endOf('day');
    // First day is 30 days ago at 00:00
    past30day.dateFrom.subtract('30', 'days');
    past30day.dateFrom.startOf('day');

    return past30day;
  }

  /**
   * Method to check if given date is today
   * @param date the date we want to compare to today
   */
  isToday(date): boolean {
    const today = moment().startOf('day');
    return date.isSame(today);
  }

  /**
   * Initialize today's date to midnight in local timezone.
   */
  getTodayLocalMidnight(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Method to check if givent date is yesterday
   * @param date the date we want to compare to yesterday
   */
  isYesterday(date): boolean {
    const yesterday = moment().subtract(1, 'days').startOf('day');
    return date.isSame(yesterday);
  }

  convertIonicToDate(ionicDate): Date {
    if (ionicDate && ionicDate.year) {
      return moment({
        year: ionicDate.year.value,
        month: ionicDate.month.value - 1,
        day: ionicDate.day.value,
        hour: ionicDate.hour.value,
        minute: ionicDate.minute.value,
        second: 0,
        millisecond: 0
      }).toDate();
    }
    return ionicDate ? new Date(ionicDate) : null;
  }

  convertDateToIsoString(date: Date | null): string | null {
    if (date) {
      return moment(date).toISOString(true);
    }
    return null;
  }

  /**
   * Convert a duration in javascript Date format to a time string
   * @param durationDate duration to convert
   */
  convertDurationDateFormatToTimeString(durationDate): string {
    const time: Date = new Date(durationDate);
    const stringTime: string = time.toTimeString().split(' ')[0].split(':')[1] + ':'
      + time.toTimeString().split(' ')[0].split(':')[2];
    return stringTime;
  }

  /**
   * Get duration between two date
   * @param startDate given from date
   * @param endDate given to date
   */
  getDuration(startDate: Date, endDate: Date): string {
    const from = moment(startDate);
    const to = moment(endDate);
    if (to >= from) {
      const difference = moment.duration(to.diff(from));
      const durationAsHours = Math.floor(difference.asHours());
      const durationAsMinutes = Math.floor(difference.asMinutes() % 60);
      if (durationAsHours < 24) {
        return durationAsHours + 'h ' + durationAsMinutes + 'm';
      }
    }
    return '';
  }

  /**
   * Format number of minutes to hh:mm format
   */
  formatMinutesToStringDuration(mins): string {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
  }

  /**
   * Format date with moment on string "dddd, MMMM Do YYYY, h:mm:ss a"
   * @param date given date
   */
  formatToDateString(date: Date): string {
    return moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }
}
