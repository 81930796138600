import { Component, Directive, Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppTypeService } from '@services/app-type.service';
import { DeviceService } from '@services/device.service';
import { UserSettingsService } from '@services/user-settings.service';

/**
 * Options for the modal
 */
class ConfirmOptions {
  /**
   * The title to put in the header of the confirmation modal
   */
  title: string;

  /**
   * The message in the confirmation modal
   */
  message: string;

  /**
   * Optional explanation
   */
  explanation?: string;

  /**
   * The text of the confirm button
   */
  confirm: string;

  /**
   * The text of the cancel button
   */
  cancel: string;

  /**
   * The class of the action button
   */
  btnClass?: string;

  /**
   * If false, hide the warning icon
   */
  showWarningIcon?: boolean = true;
}

/**
 * Allow access to the options and the modal reference.
 */
@Injectable()
export class ConfirmState {
  /**
   * The last options passed ConfirmService.confirm()
   */
  options: ConfirmOptions;

  /**
   * The last opened confirmation modal
   */
  modal: NgbModalRef;

  /**
   * The template containing the confirmation modal component
   */
  template: TemplateRef<any>;
}

/**
 * ConfirmService allow to open a confirm modal from anywhere - Returns a promise.
 */
@Injectable()
export class ConfirmService {
  constructor(private modalService: NgbModal, private state: ConfirmState) {}

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title, message and button's text)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  confirm(template: TemplateRef<any>, options: ConfirmOptions): Promise<any> {
    this.state.options = options;
    this.state.modal = this.modalService.open(template);
    return this.state.modal.result;
  }
}

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
  selector: 'app-confirm-modal',
  template: `
      <div class="modal-header">
          <i *ngIf="options.showWarningIcon" class="fas fa-exclamation-triangle mr-2"></i>
          <h1 class="modal-title">{{ options.title | translate }}</h1>
          <button type="button" aria-label="Close" (click)="no()">
              <ion-icon mode="md" class="small" slot="icon-only" name="cross"></ion-icon>
          </button>
      </div>
      <div class="modal-body">
          <h3>{{ options.message | translate }}</h3>
          <h4 *ngIf="options.explanation">
              {{ options.explanation | translate}}
          </h4>
      </div>
      <div class="modal-footer">
          <ion-button class="secondary-action-button" (click)="no()">
              {{ options.cancel | translate }}
          </ion-button>
          <ion-button class="ml-2 primary-action-button" [ngClass]="lastVisitedApp()" (click)="yes()">
              {{ options.confirm | translate }}
          </ion-button>
      </div>`
})
export class ConfirmModalComponent {
  options: ConfirmOptions;

  constructor(
    private state: ConfirmState,
    private userSettingsService: UserSettingsService,
    private appType: AppTypeService,
    protected deviceService: DeviceService,
    ) {
    this.options = state.options;
  }

  yes() {
    this.state.modal.close('confirmed');
  }

  no() {
    this.state.modal.dismiss('not confirmed');
  }

  lastVisitedApp(): string {
    if(this.appType.isInAdministrationPage() && !this.deviceService.isMobile){
      return 'administration';
    }
    return this.userSettingsService.get().lastVisitedApp;
  }
}

/**
 * Directive to create confirm template -> <ng-template #appConfirm>
 */
@Directive({
  selector: 'template[appConfirm]'
})
export class ConfirmTemplateDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
    state.template = confirmTemplate;
  }
}

/**
 * HOW TO USE
 *   Put below template in html template of the desired component
 *  ```
 * <ng-template #appConfirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *  fetch this DOM element using :
 *   @ViewChild('appConfirm') private appConfirmTemplate: TemplateRef<any>;
 *
 * You can now open your confirm modal using:
 * this.confirmService.confirm(appConfirmTemplate,Options)
 */
