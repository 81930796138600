<div class="overlay" *ngIf="startOnboarding" (click)="$event.stopPropagation()"></div>
<div #tourContainer class="tour-container" *ngIf="currentStep < tourData.length && startOnboarding" (click)="$event.stopPropagation()">
  <ion-icon id="arrow-left-admin-onboarding-fill" class="arrow-fill-icon" name="custom-left-arrow-fill"></ion-icon>
  <ion-icon class="close-button" (click)="closeAdminOnboarding()" name="close-outline"></ion-icon>
  <div class="header">
    <label class="heading">{{ tourData[currentStep].title | translate}}</label>
    <div class="onboarding-steps">
      <div class="step-number">{{ this.currentStep + 1 }}</div>
      <label class="step-label">{{ tourData[currentStep].stepLabel | translate }}</label>
    </div>
    <div class="progress">
      <div class="progress-value">
        {{ tourData[currentStep].percentageProgress + '%' }}
      </div>
      <div class="custom-progress-bar">
        <mat-progress-bar mode="determinate" [value]="tourData[currentStep].percentageProgress"></mat-progress-bar>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="content-heading">
      {{ tourData[currentStep].contentHeading | translate }}
    </div>
    <div class="description" [innerHTML]="tourData[currentStep].description | translate"></div>
  </div>
  <div class="footer">
    <div *ngIf="currentStep > 0" class="click-icon" (click)="previousStep()">
      <ion-icon name="custom-left-arrow-admin-onboarding"></ion-icon>
    </div>
    <div *ngIf="currentStep <= 0" class="click-icon empty-click"></div>
    <div class="step-indicators">
      <div *ngFor="let step of tourData; let i = index">
        <div class="circles" [ngClass]="{ 'active' : i === currentStep }"></div>
      </div>
    </div>
    <div *ngIf="currentStep < tourData.length -1" class="click-icon" (click)="nextStep()">
      <ion-icon name="custom-right-arrow-admin-onboarding"></ion-icon>
    </div>
    <div class="finish-tour-button click-icon" *ngIf="currentStep === 3" (click)="endTour()">
      {{ 'label.finish' | translate }}
    </div>
  </div>
</div>
