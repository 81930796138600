import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, pipe, UnaryFunction } from 'rxjs';
import { mapTo, first } from 'rxjs/operators';

export type SyncState = 'success' | 'error' | 'in-progress' | 'stopped';

export class SyncStatus {
  constructor(
    public status: SyncState = 'stopped',
    public spaceId?: string,
    public siteId?: string,
  ) {}
}

const waitForEndOfSync: () => UnaryFunction<Observable<SyncStatus>, Observable<void>> = () => pipe(
  first(state => state.status !== 'in-progress'),
  mapTo(undefined),
);

@Injectable({
  providedIn: 'root'
})
export class SyncStatusService {

  private siteSyncStatus$ = new BehaviorSubject<SyncStatus>(new SyncStatus());
  private spaceSyncStatus$ = new BehaviorSubject<SyncStatus>(new SyncStatus());
  private siteSyncAfterUpdateStatus$ = new BehaviorSubject<SyncStatus>(new SyncStatus());
  private eventSubsetApiSyncStatus$ = new BehaviorSubject<SyncStatus>(new SyncStatus());
  private taskSubsetApiSyncStatus$ = new BehaviorSubject<SyncStatus>(new SyncStatus());

  public readonly watchSpaceSyncStatus = this.spaceSyncStatus$.asObservable();
  public readonly watchSiteSyncStatus = this.siteSyncStatus$.asObservable();
  public readonly watchSiteSyncAfterUpdateStatus = this.siteSyncAfterUpdateStatus$.asObservable(); // Will only be used if db is cleared after an update to display a spinner
  public readonly watchEventSubsetApiSyncStatus = this.eventSubsetApiSyncStatus$.asObservable();
  public readonly watchTaskSubsetApiSyncStatus = this.taskSubsetApiSyncStatus$.asObservable();

  public communicateSpaceSyncStatus(status: SyncState, spaceId?: string): void {
    this.spaceSyncStatus$.next(new SyncStatus(status, spaceId));
  }

  public communicateSiteSyncStatus(status: SyncState, spaceId?: string, siteId?: string): void {
    this.siteSyncStatus$.next(new SyncStatus(status, spaceId, siteId));
  }

  public communicateSiteSyncAfterUpdateStatus(status: SyncState, spaceId?: string, siteId?: string): void {
    this.siteSyncAfterUpdateStatus$.next(new SyncStatus(status, spaceId, siteId));
  }

  public communicateEventSubsetApiSyncStatus(status: SyncState): void {
    this.eventSubsetApiSyncStatus$.next(new SyncStatus(status));
  }

  public communicateTaskSubsetApiSyncStatus(status: SyncState): void {
    this.taskSubsetApiSyncStatus$.next(new SyncStatus(status));
  }

  public waitForEndOfSiteSync(): Promise<void> {
    return this.watchSiteSyncStatus.pipe(waitForEndOfSync()).toPromise();
  }

  public waitForEndOfSpaceSync(): Promise<void> {
    return this.watchSpaceSyncStatus.pipe(waitForEndOfSync()).toPromise();
  }

  public waitForEndOfSiteSyncAfterUpdate(): Promise<void> {
    return this.watchSiteSyncAfterUpdateStatus.pipe(waitForEndOfSync()).toPromise();
  }

  public waitForEndOfEventSubsetApiSync(): Promise<void> {
    return this.watchEventSubsetApiSyncStatus.pipe(waitForEndOfSync()).toPromise();
  }

  public waitForEndOfTaskSubsetApiSync(): Promise<void> {
    return this.watchTaskSubsetApiSyncStatus.pipe(waitForEndOfSync()).toPromise();
  }

  public isTaskSubsetApiInProgress(): boolean {
    return this.taskSubsetApiSyncStatus$.value.status === 'in-progress';
  }
}
