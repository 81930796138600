import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NetworkStatus } from '../../models/synchronization/network-status';
import { OfflineCheckerService } from '../offline-checker.service';

/** Intercepts http responses to know if user is offline **/
@Injectable()
export class OfflineResponseInterceptor implements HttpInterceptor {
  constructor(
    private offlineCheckerService: OfflineCheckerService,
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request)
    .pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          // Catch ERR_CONNECTION_REFUSED etc.
          // Thanks: https://stackoverflow.com/questions/46598777/check-internet-connection-in-web-using-angular-4
          if (error.status === 0 && !navigator.onLine) {
            NetworkStatus.isOnline = false;
            this.offlineCheckerService.startChecking();
          }
        }
      }),
    );
  }
}
