import { Injectable } from '@angular/core';
import { Task } from '@models/task';

export enum BACKUP_TYPES {
  EVENT = 'eventBackup',
  TASK = 'taskBackup',
}

const BACKUP = 'Backup';

class Backup {
  eventBackup: Event;
  taskBackup: Task;
}

@Injectable({
  providedIn: 'root'
})
export class BackupService {

  public backup: Backup = new Backup();

  constructor() {
    this.getBackup();
  }

  removeBackup(): void {
    localStorage.removeItem(BACKUP);
  }

  removeBackupItem(itemType: BACKUP_TYPES): void {
    this.backup[itemType] = null;
    this.saveBackup();
  }

  getBackup(): void {
    this.backup = localStorage.getItem(BACKUP) ? JSON.parse(localStorage.getItem(BACKUP)) : {};
  }

  getBackupItem(itemType: BACKUP_TYPES): any {
    return this.backup[itemType];
  }

  addBackupItem(itemType: BACKUP_TYPES, item: any): void {
    this.backup[itemType] = item;
    this.saveBackup();
  }

  saveBackup(): void {
    localStorage.setItem(BACKUP, JSON.stringify(this.backup));
  }
}
