import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { default as WeatherCodes } from 'assets/weather/conditions.json';

import { Weather } from '../models/weather';
import { UrlGiverService } from './url-giver.service';
import { TemperatureUnitEnum } from '@models/enums/temperature-unit.enum';
import { WindUnitEnum } from '@models/enums/wind-unit.enum';
import { Site } from '@models/site';

class WeatherLanguage {
  lang_name: string;
  lang_iso: string;
  day_text: string;
  night_text: string;
}

class WeatherCode {
  code: number;
  day: string;
  night: string;
  icon: number;
  languages: WeatherLanguage[];
}

export class WeatherCodeDetails {
  code: number;
  label: string;
  icon: number;
  isDay: boolean;
  icon_path: string;


  constructor(code: number, label: string, icon: number, isDay: boolean) {
    this.code = code;
    this.label = label;
    this.icon = icon;
    this.isDay = isDay;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WeatherService {

  static readonly TEMPERATURE_CELSIUS: string = '°C';
  static readonly TEMPERATURE_FAHRENHEIT: string = '°F';
  static readonly WINDSPEED_KMH: string = 'km/h';
  static readonly WINDSPEED_MPH: string = 'mph';

  weatherCodes: WeatherCode[] = [];

  constructor(
    private translateService: TranslateService,
    private urlGiverService: UrlGiverService,
  ) {
    Object.assign(this.weatherCodes, WeatherCodes);
  }

  /**
   * Convert a celsius temperature to Fahrenheit
   * @param {number} temperature_C The celsius temperature
   * @returns {number} Converted temperature in Fahrenheit
   */
  private static convertToFahrenheit(temperature_C: number): number {
    return (temperature_C * 9 / 5) + 32;
  }

  //temperature is always displayed without any digit
  public static getTemperatureWithSiteSettingsUnits(temperature: Weather['temperature'], site: Site): string{
      if (site && Number(site.temperatureUnit) === TemperatureUnitEnum.FAHRENHEIT) {
        return temperature ? this.convertToFahrenheit(temperature).toFixed(0) + ' ' + this.TEMPERATURE_FAHRENHEIT : null;
      }
      //if site is undefined/null or temperatureUnit is set to Celsius, we return default Celsius
      return  temperature ? temperature.toFixed(0) + ' ' + this.TEMPERATURE_CELSIUS : null;
  }

  /**
   * Convert a km/h windspeed to mph
   * @param {number} windspeed_KMH The km/h windspeed
   * @returns {number} Converted windspeed in mph
   */
  private static convertToMph(windspeed_KMH: number): number {
    return windspeed_KMH / 1.609;
  }

  //wind speed is always displayed with 2 digits
  public static getWindspeedWithSiteSettingsUnits(windSpeed: Weather['windSpeed'], site: Site): string {
    if (site && Number(site.windUnit) === WindUnitEnum.IMPERIAL) {
       return windSpeed ? this.convertToMph(windSpeed).toFixed(2) + ' ' + this.WINDSPEED_MPH : null;
     }
      //if site is undefined/null or windSpeedUnit is set to Metric, we return default km/h
     return windSpeed ? windSpeed.toFixed(2) + ' ' + this.WINDSPEED_KMH : null;
  }

  private getCode(code: number): WeatherCode | null {
    return this.weatherCodes.find(wc => wc.code === code);
  }

  private findTranslation(weatherCode: WeatherCode, currentLanguage: string) {
    return weatherCode.languages.find(l => l.lang_iso === currentLanguage);
  }

  private formatText(label: string): string {
    label = label.toLocaleLowerCase();
    return label.charAt(0).toLocaleUpperCase() + label.slice(1);
  }

  private toWeatherCode(weatherCode: WeatherCode, weatherLanguage: WeatherLanguage, isDay: boolean) {
    let weatherCodeDetails: WeatherCodeDetails;
    if (!weatherLanguage) {
      weatherCodeDetails = new WeatherCodeDetails(
        weatherCode.code,
        isDay ? weatherCode.day : weatherCode.night,
        weatherCode.icon,
        isDay
        );
    } else {
      weatherCodeDetails = new WeatherCodeDetails(
        weatherCode.code,
        isDay ? weatherLanguage.day_text : weatherLanguage.night_text,
        weatherCode.icon,
        isDay
      );
    }

    weatherCodeDetails.label = this.formatText(weatherCodeDetails.label);
    weatherCodeDetails.icon_path = this.getWeatherIconUrl(weatherCodeDetails);

    return weatherCodeDetails;
  }

  getWeather(weather: Weather): WeatherCodeDetails | null {
    if (!weather || !weather.weatherType) {
      return null;
    }

    const weatherCode = this.getCode(weather.weatherType);
    if (!weatherCode) {
      return null;
    }
    const currentLanguage = this.translateService.currentLang;

    const weatherLanguage: WeatherLanguage = this.findTranslation(weatherCode, currentLanguage);
    return this.toWeatherCode(weatherCode, weatherLanguage, weather.isDay);
  }

  getWeatherIconUrl(weatherDetail: WeatherCodeDetails): string {
    return `${this.urlGiverService.giveAssetsUrl()}img/weather/${weatherDetail.isDay ? 'day' : 'night'}/${weatherDetail.icon}.svg`;
  }

}
