import { Injectable } from '@angular/core';

import { Contractor } from '../models/contractor';
import { EventContractor } from '../models/event-contractor';
import { SharedService } from './shared/shared.service';
import { DatabaseService } from './shared/database.service';
import { TaskContractor } from '../models/task-contractor';
import { Task } from '@models/task';
import { AbstractModelService } from '@services/abstract-model-service';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { linkedToTask } from '@operators/linked-to-task.operator';
import { EventService } from './event.service';
import { TaskService } from './task.service';
import { SharedDataService } from './shared-data.service';
import { DeviceService } from './device.service';

/**
 * Service of contractor. Use for load {@link Contractor} from web service
 */
@Injectable()
export class ContractorService extends AbstractModelService<Contractor> {
  protected type = 'Contractor';

  public readonly siteItems: Observable<Contractor[]> = this.items.pipe(map(items => items.filter(item => item.siteId === this.sharedService.currentSiteId)));
  public readonly spaceItems: Observable<Contractor[]> = this.items.pipe(map(items => items.filter(item => item.siteId === 'null')));

  constructor(
    protected databaseService: DatabaseService,
    private sharedService: SharedService,
    private eventService: EventService,
    private taskService: TaskService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService
  ) {
    super(
      databaseService,
    );

    if (!this.deviceService.isMobile) {
      // get space contractors from shared data service
      this.spaceItems = this.sharedDataService.getSpaceContractors();
      this.siteItems = this.sharedDataService.watchSiteContractors;
    }
  }

  public async filterForCurrentSpace(): Promise<void>  {
    const spaceContractors = await this.databaseService.getSpaceItems('contractor');
    this.itemSubject.next(spaceContractors);
  }

  public async filterForCurrentSite(): Promise<void>  {
    const siteContractors = await this.databaseService.getSiteItems('contractor', this.sharedService.currentSiteId);
    this.itemSubject.next(siteContractors);
  }

  public async filterAll(): Promise<void> {
    const contractors = await this.databaseService.getItemsForCurrentSiteAndSpace('contractor', this.sharedService.currentSiteId);
    this.itemSubject.next(contractors);
  }

  /**
   * Check if contractor has a name
   * @param contractor Contractor to check
   */
  checkIntegrity(contractor: Contractor): boolean {
    return contractor.name !== '';
  }

  /**
  * Method used to convert a {@link_SiteContractor} or {@link_SpaceContractor} to the {@link_EventContractor} format expected by an event
  * @param contractor given contractor
  */
  convertContractorToEventContractor(contractor: Contractor): EventContractor {
    const eventContractor = new EventContractor();
    eventContractor.contractorId = contractor.id;
    eventContractor.name = contractor.name;
    return eventContractor;
  }

  /**
    * Method used to convert an array of {@link_SiteContractor} or {@link_SpaceContractor} to an array of {@link_EventContractor}
    * @param contractor given contractor
    */
  convertContractorsToEventContractors(contractors: Contractor[]): EventContractor[] {
    const eventContractors = new Array();
    contractors.forEach(contractor => {
      eventContractors.push(this.convertContractorToEventContractor(contractor));
    });
    return eventContractors;
  }

  /**
   * Method used to convert an array of {@link_Contractor} to an array of {@link_TaskContractor}
   */
  convertContractorsToTaskContractors(contractors: Contractor[]): TaskContractor[] {
    return this.convertContractorsToEventContractors(contractors);
  }

  public getContractorsLinkedToTask(task: Task): Observable<Contractor[]> {
    return this.items.pipe(
      linkedToTask(task, 'contractor'),
    );
  }

  public sortContractorsAlphabetically(contractors: Contractor[] | EventContractor[]) {
    contractors.sort((n1, n2) => {
      if (n1.name > n2.name) {
        return 1;
      } else if (n1.name < n2.name) {
        return -1;
      } else {
        return 0;
      }
    })
  }
}
