import { User } from './user';

export class SiteUser extends User {
  siteId: string;
  role: string;

  constructor() {
    super();
    this.siteId = '';
    this.role = 'TeamMember';
  }

  /**
   * Convert backend data to Model
   * @param json The given backend Object
   * @param model The given model
   */
  public static toModel(json: any, model: SiteUser) {
    super.toModel(json, model);
    model.siteId = json.payload.siteId;
    model.role = json.payload.role;
  }
}
