import { Pipe, PipeTransform } from '@angular/core';
import { EventStatus } from '@models/event';

@Pipe({
  name: 'eventStatusToIonColor'
})
export class EventStatusToIonColorPipe implements PipeTransform {
  transform(eventStatus: EventStatus): string {
    switch (eventStatus) {
      case EventStatus.APPROVED:
        return 'success';
      case EventStatus.REJECTED:
        return 'danger';
      case EventStatus.DRAFT:
        return 'warning';
      case EventStatus.SUBMITTED:
      case EventStatus.PENDING:
      default:
        return 'primary';
    }
  }
}
