import { ModelElement } from './model-element';
import { FormatSortService } from '@services/format-sort.service';

export class Location implements ModelElement {
  id: string;
  revision: string;
  name: string;
  siteId: string;

  constructor() {
    this.id = ModelElement.generateId();
    this.revision = '';
    this.name = '';
    this.siteId = 'null';
  }

  /**
   * Extract {@link Location} from json
   * @param json given json
   * @param model given Location
   */
  public static toModel(json: any, location: Location) {
    location.id = json.payload.id;
    location.name = json.payload.name;
    location.siteId = json.payload.siteId ? json.payload.siteId : 'null';
  }

  /**
   * Sort {@link Location} by name
   * @param location1 first location to compare
   * @param location2 second location to compare
   */
  public static compareByName(location1, location2) {
    return FormatSortService.compare(location1.name, location2.name);
  }

  /**
   * Copy given {@link Location} into destination {@link Location}
   * @param destination given destination Location
   * @param source given source Location
   */
  public static copy(destination: Location, source: Location): void {
    destination.id = source.id;
    destination.revision = source.revision;
    destination.name = source.name;
    destination.siteId = source.siteId;
  }

  /** Get json from {@link Location} */
  public toDTO() {
    return {
      id: this.id,
      name: this.name,
      siteId: this.siteId !== 'null' ? this.siteId : null
    };
  }

  public get label(): string {
    return this.name;
  }
}
