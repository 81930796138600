import { Pipe, PipeTransform } from '@angular/core';
import { FormatSortService } from '@services/format-sort.service';
import { Labelable } from '@models/labelable';

@Pipe({
  name: 'filterListByLabel'
})
export class FilterListByLabelPipe implements PipeTransform {
  transform(list:  Labelable[], labelFilter: string): Labelable[] {
    const formattedLabelFilter = FormatSortService.formatText(labelFilter);
    return list.filter(item => this.matchesFilter(item.label, formattedLabelFilter));
  }

  private matchesFilter(itemName: string, formattedLabelFilter: string): boolean {
    return FormatSortService.formatText(itemName).includes(formattedLabelFilter);
  }
}
