import { Task } from './task';
import { EventTask } from './event-task';
import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class EventTaskAdapter implements Labelable {
    task: Task;
    progress: number;
    suspended: boolean;

    constructor(task: Task) {
        this.task = task;
        this.progress = task.lastKnownProgress;
        this.suspended = task.suspended;
    }

    /**
     * Prepare the EventTask with the task values
     * @param eventTask
     */
    updateModel(eventTask: EventTask): EventTask {
        eventTask.taskId = this.task.id;
        eventTask.progress = this.progress;
        eventTask.suspended = this.suspended;
        return eventTask;
    }

    /**
     * Update EventTaskAdapter when a task is selected in Event Form
     * @param eventTask
     */
    updateFromModel(eventTask: EventTask): void {
        if (this.task.id !== eventTask.taskId) {
            throw new RangeError(`Given EventTask.taskId ${eventTask.taskId} does not match this EventTaskAdapter.task.id ${this.task.id}`);
        }
        this.progress = eventTask.progress;
        this.suspended = eventTask.suspended;
    }

    public get label(): string {
      return this.task.title;
    }

    public get id(): string {
      return this.task.id;
    }
}
