import { Injectable } from '@angular/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

export const LANGUAGES: Array<string> = [
  'en',
  'en-au',
  'en-za',
  'en-ca',
  'en-us',
  'fr'
];

/**
 * Languages using a crazy date format and first day of the week!
 */
export const CRAZY_LANGUAGES: string[] = ['en-us', 'en-ca'];

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  /** Current Locale of the browser, default app when lang not found*/
  public readonly currentLocale: string = this.findSupportedLanguage();


  constructor(private translate: TranslateService) {
    /**
     * Register locales for i18nIsocountries plugin
     */
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
  }

  /**
   * Return application supported languages
   */
  getSupportedLanguages() {
    return LANGUAGES;
  }

  /**
   * Get default application language
   */
  getDefaultApplicationLanguage() {
    return 'en';
  }

  /**
   * Get default browser language to setup translations options
   */
  public initApplicationLanguageSettings(): void {
    const navigatorCurrentLanguage: string = this.currentLocale;
    this.translate.addLangs(this.getSupportedLanguages());
    this.translate.setDefaultLang(this.getDefaultApplicationLanguage());
    this.translate.use(navigatorCurrentLanguage);
    moment.locale(navigatorCurrentLanguage);
  }

  /**
   * Find the supported Language
   * Get the default language if nothing is find
   */
  private findSupportedLanguage(): string {
    const supportedLocale = this.doFindSupportedLanguage();
    return supportedLocale ? supportedLocale : this.getDefaultApplicationLanguage();
  }

  /**
   * Compares the list of program and user languages, and finds one to display.
   * @param matchSubLocales Search with sub locales
   */
  private doFindSupportedLanguage(): string | null {
    for (const _lang of navigator.languages) {
      const exactLocale = _lang.toLocaleLowerCase();
      if (this.tryMatchLocale(exactLocale)) {
        return exactLocale;
      } else {
        const subLocale = _lang.toLocaleLowerCase().split('-')[0];
        if (this.tryMatchLocale(subLocale)) { return subLocale; }
      }
    }
    return null;
  }

  private tryMatchLocale(locale: string): boolean {
    return this.getSupportedLanguages().includes(locale);
  }

  /**
   * Method returning localized list of countries using i18nIsoCountries
   */
  getLocalizedListOfCountries() {
    return i18nIsoCountries.getNames(this.currentLocale.split('-')[0]);
  }

  /**
   * Check if current locale is part of the CRAZY LANGUAGE club (TM)(C).
   * A.k.a. the bad guys who use MM/DD/YYYY and Sunday as start of week.
   */
  isCrazyLanguage(): boolean {
    return CRAZY_LANGUAGES.includes(this.currentLocale);
  }
}
