import { TasksCount } from "./subset-task"
import { TASK_STATUS } from "./task"

export interface BETasksCountResponse {
  plannedTasksCount: number,
  inProgressTasksCount: number,
  completedTasksCount: number,
  cancelledTasksCount: number
}

export const initialTasksCount: TasksCount = {
  [TASK_STATUS.PLANNED]: 0,
  [TASK_STATUS.INPROGRESS]: 0,
  [TASK_STATUS.COMPLETED]: 0,
  [TASK_STATUS.CANCELLED]: 0,
}

export const initialTasksDetailedStates: TasksDetailedStatesCount = {
  plannedAndNotReadyTasksCount: '-',
  plannedAndReadyToStartTasksCount: '-',
  delayedTasksCount: '-',
  inProgressTasksCount: '-',
  overdueTasksCount: '-',
  pendingApprovalTasksCount: '-'
}

export type TasksDetailedStatesCount = {
  plannedAndNotReadyTasksCount: number | string;
  plannedAndReadyToStartTasksCount: number | string;
  delayedTasksCount: number | string;
  inProgressTasksCount: number | string;
  overdueTasksCount: number | string;
  pendingApprovalTasksCount: number | string;
}

interface TasksCountInSiteCard {
  totalTaskCount: number;
  plannedTasksCount: number;
  completedTasksCount: number;
  cancelledTasksCount: number;
}
export interface TasksCountSiteCard {
  [key: string]: TasksCountInSiteCard;
}
