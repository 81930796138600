import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FabButtonService {

  fabButtonSubject: BehaviorSubject <boolean> = new BehaviorSubject<boolean>(false);
  fabClickedSubject: BehaviorSubject <boolean> = new BehaviorSubject<boolean>(false);
  route: string;

  public show(): void {
    this.fabButtonSubject.next(true);
  }

  public hide(): void {
    this.fabButtonSubject.next(false);
  }

  public click(): void {
    this.fabClickedSubject.next(true);
  }

  public reset(): void {
    this.fabClickedSubject.next(false);
  }

}
