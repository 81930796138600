<!-- WEBAPP TEMPLATE -->
<div *ngIf="!deviceService.isMobile">
  <ion-icon name="timer-outline" alt="duration"></ion-icon>&nbsp;&nbsp;
  <div class="inline-div cursor-on" *ngIf="!readOnly; else displayDurationTemplate" (click)="openTimePicker()">
      <ng-container *ngIf="convertDurationToString(duration)">
        {{ convertDurationToString(duration) }}
        <ion-icon name="arrow-down"></ion-icon>
      </ng-container>
  </div>
  <ng-template #displayDurationTemplate>
    <ng-container *ngIf="convertDurationToString(duration)">
      {{ convertDurationToString(duration) }}
    </ng-container>
  </ng-template>
</div>
<!-- END OF WEBAPP TEMPLATE -->

<!-- APP TEMPLATE -->
<div *ngIf="deviceService.isMobile">
  <div class="mobile-time-picker">
    <select class="select-box select-css" (change)="onChangeHour($event.target.value)">
      <option *ngFor="let hour of hours" [selected]="hour===selectedHour" [value]="hour" >
        {{hour}}
      </option>
    </select>
    <div>
      {{'colon.symbol' | translate}}
    </div>
    <select class="select-box select-css" (change)="onChangeMin($event.target.value)">
      <option *ngFor="let min of minutes" [selected]="min===selectedMin" [value]="min" >
        {{min}}
      </option>
    </select>
  </div>
</div>
<!-- END OF APP TEMPLATE -->
