import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UserRightsService } from '@services/user-rights/user-rights.service';

@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html'
})
export class MobileToolbarComponent {
  @Input() doneLabel: string;
  @Input() disableDoneButton: boolean = false;
  @Input() title: string;
  @Input() backIcon: string = 'arrow-back';
  @Input() menuIcon: boolean = false;
  @Input() isAdminIconDisplayed: boolean = false; 

  @Output() back = new EventEmitter<void>();
  @Output() admin = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();
  @Output() menuClicked = new EventEmitter<void>();

  constructor(
    public userRightsService: UserRightsService
  ) {}

  isAdminButtonVisible(): boolean {
    return this.isAdminIconDisplayed && this.userRightsService.hasRight(this.userRightsService.USER_RIGHTS.space.edit);
  }
}
