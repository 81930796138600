import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidCountryCodes } from '@models/2FA/valid-country-codes';
import { DeviceService } from '@services/device.service';
import { SessionService } from '@services/session.service';
import { UserService } from '@services/user.service';
import { PhoneNumberValidator } from '@validators/phone-number.validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ChangeData, CountryISO } from 'ngx-intl-tel-input';

type SuccessCallback = () => void;
type DismissCallback = () => void;

@Component({
  selector: 'app-phone-number-verification-modal',
  templateUrl: './phone-number-verification-modal.component.html',
  styleUrls: ['./phone-number-verification-modal.component.sass']
})
export class PhoneNumberVerificationModalComponent implements OnInit {

  @Input() private successCallback: SuccessCallback;
  @Input() private dismissCallback: DismissCallback;

  formGroup: FormGroup;
  currentPhoneNumber: string;
  validCountryCodes = ValidCountryCodes;
  phoneNumberControl: AbstractControl;
  isStrictModeEnabled: boolean = false;

  /* ngx-intl-tel-input reging  */
  selectedCountryISO: CountryISO = null;
  preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];
  initialPhoneNumberInput: ChangeData = {};
  /* end ngx-intl-tel-input region */

  constructor(
    public deviceService: DeviceService,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    this.formGroup = this.formBuilder.group({
      phoneNumber: [null, Validators.required],
    },{
      validators: [PhoneNumberValidator]
    });
    this.phoneNumberControl = this.formGroup.controls['phoneNumber'];
  }

  ngOnInit() {
    this.currentPhoneNumber = this.sessionService.getCurrentUser().phoneNumber;
    this.phoneNumberControl.setValue(this.currentPhoneNumber);
    this.isStrictModeEnabled = this.sessionService.getCurrentUser().is2FAStrictModeEnabled;
    const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    try {
      let phoneNumberInput = phoneNumberUtil.parseAndKeepRawInput(this.currentPhoneNumber);
      let countryCode = phoneNumberUtil.getRegionCodeForNumber(phoneNumberInput).toLowerCase();
      let countryKeys = Object.keys(CountryISO);
      for(let keys of countryKeys) {
        if(CountryISO[keys] === countryCode) {
          this.selectedCountryISO = CountryISO[keys];
          this.initialPhoneNumberInput = {
            number: phoneNumberInput.getNationalNumber().toString(),
            e164Number: this.currentPhoneNumber
          }
          this.phoneNumberControl.setValue(this.initialPhoneNumberInput);
          this.phoneNumberControl.updateValueAndValidity();
        }
      }
    } catch(error) {
      this.phoneNumberControl.updateValueAndValidity();
    }
  }

  isPhoneNumberInvalid(): boolean {
    return this.phoneNumberControl.hasError('phoneNumberInvalid');
  }

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  /**
   * Dismiss the modal when user does not wish to verify their phone number
   */
  dismissModal(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }



  validate(): void {
    if(this.formGroup.valid) {
      let session = this.sessionService.getSession();
      let phoneNumber = this.getValidPhoneNumber();
      session.user.phoneNumber = phoneNumber;
      this.userService.changeUserInfo(session).subscribe((response) => {
        this.closeModal();
      });
    }
  }

  getValidPhoneNumber(): string {
    let phoneNumberInput = this.phoneNumberControl?.value?.e164Number;
    return phoneNumberInput;
  }

  /**
   * Close the modal only when phone number is successfully updated
   */
  closeModal() {
    this.successCallback();
  }

  onCountryChange(value): void {
    this.selectedCountryISO = value.iso2;
  }

  isPhoneNumberFromValidCountry(): boolean {
    let validCountryCodes = Object.values(ValidCountryCodes) as string[];
    if(validCountryCodes.includes(this.selectedCountryISO)) {
      return true;
    } else {
      return false;
    }
  }

}
