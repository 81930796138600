<!-- ion-header should be here in common but we have to extract it out of this component to make the header sticky on ios...-->
<ion-toolbar mode="ios" class="toolbar-fixed" color="primary">
  <span slot="start" class="custom-back" (click)="back.emit()">
    <ion-icon color="light" [name]="backIcon"></ion-icon>
  </span>
  <ion-buttons slot="end">
    <ion-button *ngIf="doneLabel" class="text-capitalize" [disabled]="disableDoneButton" (click)="done.emit()" color="light">
      {{ doneLabel | translate }}
    </ion-button>
    <ion-button *ngIf="menuIcon" (click)="menuClicked.emit()"><ion-icon name="ellipsis-vertical-outline"></ion-icon></ion-button>
    <ion-button *ngIf="isAdminButtonVisible()" (click)="admin.emit()">
      <ion-icon name="settings-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title color="light">
    {{ title | translate }}
  </ion-title>
</ion-toolbar>
