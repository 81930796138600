import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';
import { UrlGiverService } from '../services/url-giver.service';

@Pipe({
  name: 'secureImageSrc',
})
export class SecureImageSrcPipe implements PipeTransform {
  public static readonly DEFAULT_ATTACHMENT_URL = '/assets/img/noImage.jpg';

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private urlGiverService: UrlGiverService,
  ) { }


  transform(url: string): Observable<SafeUrl> {
    return this.transformToBlobUrl(url)
      .pipe(map(blobUrl => this.sanitizer.bypassSecurityTrustUrl(blobUrl)))
      .pipe(startWith(this.sanitizer.bypassSecurityTrustUrl(SecureImageSrcPipe.DEFAULT_ATTACHMENT_URL)));
  }

  transformToBlobUrl(url: string): Observable<string> {
    if (url && url.startsWith(this.urlGiverService.giveAPIUrl())) {
      return this.http.get(url, {responseType: 'blob'})
                 .pipe(map(val => URL.createObjectURL(val)));
    } else {
      return of(url);
    }
  }

}
