import { TaskAsset } from './task-asset';

export class EventAsset extends TaskAsset {

  constructor() {
    super();
  }

  public static jsonToEventAssets(jsonAssets): Array<EventAsset> {
    return super.jsonToTaskAssets(jsonAssets);
  }

  public static eventAssetsToDto(eventAssets: EventAsset[]) {
    return super.taskAssetsToDto(eventAssets);
  }
}
