<div [ngClass]="getAppType()" class="loading-spinner" *ngIf="active">
  <div
    [ngClass]="{ 'webapp-popup': !deviceService.isMobile }"
    *ngIf="message"
    class="spinner-message">
    {{ message | translate }}
  </div>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'pulsating'" [ngTemplateOutlet]="spinner"></ng-container>
    <ng-container *ngSwitchCase="'rotating'" [ngTemplateOutlet]="loader"></ng-container>
  </ng-container>
</div>

<ng-template #spinner>
  <div class="lds-ripple">
    <div></div>
    <div></div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="loader">
   <ng-lottie class="rotating-spinner" [options]="options"></ng-lottie>
  </div>
</ng-template>
