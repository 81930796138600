<ng-container>
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar
    doneLabel="btn.export"
    title="events.export.pdf.summary.popup.header"
    [disableDoneButton]="isExportButtonDisabled()"
    (back)="dismiss()"
    (done)="download()">
    </app-mobile-toolbar>
  </ion-header>

  <ion-content class="main-form-wrapper whitesmoke-background">
    <form [formGroup]="formGroup">
      <ion-item-divider>
        <ion-label>
          <h1 class="pull-left">
            {{'events.export.pdf.summary.popup.type-label.short' | translate}}
          </h1>
          <ion-icon
            class="pull-right">
          </ion-icon>
        </ion-label>
      </ion-item-divider>
      <ion-list>
        <ion-radio-group name="type" formControlName="type">
          <ion-item class="form-input" lines="none">
            <ion-radio mode="md" value="daily"></ion-radio>
            <ion-label>
              {{'events.export.pdf.summary.popup.type-daily-label' | translate}}
            </ion-label>
          </ion-item>
          <ion-item class="form-input" lines="none">
            <ion-radio mode="md" value="weekly"></ion-radio>
            <ion-label>
              {{'events.export.pdf.summary.popup.type-weekly-label' | translate}}
            </ion-label>
          </ion-item>
          <ion-item class="form-input" lines="none">
            <ion-radio mode="md" value="custom"></ion-radio>
            <ion-label>
              {{'events.export.pdf.summary.popup.type-custom-label' | translate}}
            </ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <ion-item-divider>
        <ion-label
          (click)="isCollapsedDate = !isCollapsedDate"
          [attr.aria-expanded]="!isCollapsedDate"
          aria-controls="collapseDate">
          <h1 class="pull-left">
            {{'events.export.pdf.summary.popup.startdate-label.short' | translate}}
          </h1>
          <ion-icon
            class="pull-right"
            [attr.name]="isCollapsedDate ? 'chevron-down' : 'chevron-up'">
          </ion-icon>
        </ion-label>
      </ion-item-divider>
      <div id="collapseDate" [ngbCollapse]="isCollapsedDate">
        <ion-item class="form-input" lines="none" (click)="startDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #startDate [readonly]="false" [type]="'startDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.startdate-label' | translate}}" formControlName="startdate">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <ion-item [hidden]="formGroup.controls['type'].value === 'daily'" class="form-input" lines="none" (click)="endDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #endDate [readonly]="(formGroup.controls['type'].value !== 'custom'? true: false)" [type]="'endDatetime'" [hideTime]="true"
              [placeholder]="(formGroup.controls['type'].value === 'custom' ? ('events.export.pdf.summary.popup.enddate-label' | translate) : '')" formControlName="enddate">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <div class="left-margin">
          <div *ngIf="isDateDurationInvalid" class="flex-row justify-content-end ">
          <span class="validation-error text-danger" >
            {{'form.error.event-and-task.negativeDuration' | translate}}
          </span>
        </div>
        <div *ngIf="isDateRangeInvalid && !isDateDurationInvalid">
          <span class="validation-error text-danger">
            {{'events.report.error.one_month_duration' | translate}}
          </span>
        </div>
        </div>
      </div>


      <ion-item-divider>
        <ion-label
          (click)="isCollapsedTitle = !isCollapsedTitle"
          [attr.aria-expanded]="!isCollapsedTitle"
          aria-controls="collapseTitle">
          <h1 class="pull-left">
            {{'events.export.pdf.summary.popup.title-label.short' | translate}}
          </h1>
          <ion-icon
            class="pull-right"
            [attr.name]="isCollapsedTitle ? 'chevron-down' : 'chevron-up'">
          </ion-icon>
        </ion-label>
      </ion-item-divider>
      <div id="collapseTitle" [ngbCollapse]="isCollapsedTitle">
        <ion-input
          formControlName="title"
          placeholder="{{'events.export.pdf.summary.popup.title-label' | translate}}"
          type="text">
        </ion-input>
      </div>
    </form>
  </ion-content>
</ng-container>
