import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountValidationComponent } from './account-validation/account-validation.component';
import { AuthGuardService as AuthGuard } from './services/guards/auth-guard.service';
import { NotAuthGuardService as NotAuthGuard } from './services/guards/not-auth-guard.service';

export const APP_ROUTES: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  { path: '', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule), canActivate: [NotAuthGuard] },
  { path: 'validation', component: AccountValidationComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
