import { Component, Input, OnInit } from '@angular/core';
import { ColorPalette } from '@models/enums/color-palette';
import { EditingTool } from '@models/enums/editing-tool';
import { ToolbarProperty } from '@models/enums/toolbar-property.enum';
import { DeviceService } from '@services/device.service';
import { IToolbarMessage, ToolbarMessageService } from '@services/toolbar-message.service';
import { Subscription } from 'rxjs';

class Stroke {
  width: number = 1;
  color: ColorPalette = ColorPalette.Black;
  points: Point[] = [];
}

interface Point {
  x: number;
  y: number;
}

@Component({
  selector: 'app-canvas-panel',
  templateUrl: './canvas-panel.component.html',
  styleUrls: ['./canvas-panel.component.scss'],
})
export class CanvasPanelComponent implements OnInit {
  @Input() boundings: DOMRect; // Canvas Dimensions 
  @Input() height: number;
  @Input() width: number;

  constructor(private toolbarService: ToolbarMessageService, private deviceService: DeviceService) { }

  public isDrawing: boolean = false;
  public strokes: Stroke[] = [];
  public stroke: Stroke = new Stroke();
  public point: Point = { x: 0, y: 0};
  public canvas: HTMLCanvasElement;
  public context: CanvasRenderingContext2D;
  public pointerX: number;
  public pointerY: number;
  public toolbarMessageSubscription: Subscription;
  public isTouchDevice: boolean = false;

  ngOnInit(): void {
    this.subscribeToToolbarMessages();
    this.setDeviceType();
  }

  private setDeviceType() {
    if(this.deviceService.isMobile) {
      this.isTouchDevice = true;
    }
  }

  ngAfterViewInit(): void {
    this.initialize();
  }
  
  private initialize() {
    this.canvas = <HTMLCanvasElement>document.getElementById("paint-canvas");
    this.context = this.canvas.getContext("2d");    
    this.pointerX = 0;
    this.pointerY = 0;
    this.context.strokeStyle = ColorPalette.Red;
    this.context.lineWidth = 0.5;
    this.isDrawing = false;
  }

  private subscribeToToolbarMessages() {
    this.toolbarMessageSubscription = 
      this.toolbarService.toolbarMessages.subscribe((message: IToolbarMessage) => {
        this.processToolbarMessage(message);
      })
  }

  private emitActiveWidgetMessage() {
    this.toolbarService.broadcastActiveWidgetMessage(null);
  }

  private setMouseCoordinates(event: TouchEvent | MouseEvent) {
    if(this.isTouchDevice) {
      const touchEvent = event as TouchEvent; 
      if(touchEvent?.targetTouches?.length) {
        this.pointerX = touchEvent.targetTouches[0].clientX - this.boundings.left;
        this.pointerY = touchEvent.targetTouches[0].clientY - this.boundings.top;
      }
    } else {
      const mouseEvent = event as MouseEvent;
      this.pointerX = mouseEvent.clientX - this.boundings.left;
      this.pointerY = mouseEvent.clientY - this.boundings.top;
    }
  }
  
  private onClear() {
    this.context!.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
  
  private onUndo() {
    this.onClear();
    this.strokes.pop();

    const lastStrokeColor = this.context.strokeStyle;
    const lastStrokeWidth = this.context.lineWidth;

    for(let i = 0; i < this.strokes.length; i++) {
      this.context.strokeStyle = this.strokes[i].color;
      this.context.lineWidth = this.strokes[i].width;
      this.redrawStoredLines(this.strokes[i].points);
    }

    this.context.strokeStyle = lastStrokeColor;
    this.context.lineWidth = lastStrokeWidth;
  }
  
  private processToolbarMessage(message: IToolbarMessage) {
    if(message.tool === EditingTool.Brush) {
      this.updateBrushProperty(message);
    }
  }
  
  private updateBrushProperty(message: IToolbarMessage) {
    switch(message.property) {
      case ToolbarProperty.Color:
        this.context.strokeStyle = message.value as string;
        break;

      case ToolbarProperty.Size:
        this.context.lineWidth = message.value as number;
        break;

      case ToolbarProperty.Undo:
        this.onUndo();
        break;

      case ToolbarProperty.Clear:
        this.stroke = new Stroke();
        this.strokes = [];
        this.onClear();
        break;
    }
  }

  public redrawStoredLines(points: Point[]) {
    if(points.length === 0) {
      return;
    } 

    // redraw each stored line
    for(let i = 1; i < points.length; i++) {
      this.context.beginPath();
      this.context.moveTo(points[i-1].x, points[i-1].y);
      this.context.lineTo(points[i].x, points[i].y);
      this.context.stroke();
    }
  } 

  public onPointerClick(event: TouchEvent | MouseEvent) {
    this.emitActiveWidgetMessage();
    this.setMouseCoordinates(event);
    this.isDrawing = true;

    // Start Drawing
    this.stroke.color = this.context.strokeStyle as ColorPalette;
    this.stroke.width = this.context.lineWidth; 
    this.context!.beginPath();
    this.context!.moveTo(this.pointerX, this.pointerY);
  }
  
  public onPointerMove(event: TouchEvent | MouseEvent) {
    if(this.isTouchDevice) {
      const touchEvent = event as TouchEvent; 
      if(touchEvent?.targetTouches?.length > 1) return;
    }

    event.preventDefault();
    this.setMouseCoordinates(event);
    
    if(this.isDrawing) {
      this.point.x = this.pointerX;
      this.point.y = this.pointerY;
      this.context!.lineTo(this.pointerX, this.pointerY);
      this.context!.stroke();
      this.stroke.points.push(this.point);
      this.point = {x: 0, y: 0};
    }
  }

  public onPointerEnd(event: TouchEvent | MouseEvent) {
    this.strokes.push(this.stroke);
    this.stroke = new Stroke();
    this.setMouseCoordinates(event);
    this.isDrawing = false;
  }
  
  ngOnDestroy(): void {
    this.toolbarMessageSubscription.unsubscribe();
  }     
}