export enum RelatedTasksLinkingErrors {
  PARENT_TASK_PRESENT_IN_SUBTASKS = 'PARENT_TASK_PRESENT_IN_SUBTASKS',
  CANNOT_LINK_SUBTASK = 'CANNOT_LINK_SUBTASK',
  CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_PARENT_TASK = 'CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_PARENT_TASK',
  TASK_CANNOT_BE_PARENT_TO_ITSELF = 'TASK_CANNOT_BE_PARENT_TO_ITSELF',
  CANNOT_LINK_PARENT_TASK = 'CANNOT_LINK_PARENT_TASK',
  CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_SUBTASK = 'CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_SUBTASK',
  CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_STANDALONE_TASK = 'CANNOT_LINK_PREDECESSOR_OR_SUCCESSOR_FOR_STANDALONE_TASK',
  CANNOT_UNLINK_SUBTASK = 'CANNOT_UNLINK_SUBTASK',
  CANNOT_UNLINK_PARENT_TASK = 'CANNOT_UNLINK_PARENT_TASK',
  SUBTASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS = 'SUBTASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS',
  STANDALONE_TASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS = 'STANDALONE_TASK_CANNOT_HAVE_PROGRESS_FROM_SUBTASKS',
  CANNOT_UNLINK_DEPENDENT_SUBTASK = 'CANNOT_UNLINK_DEPENDENT_SUBTASK',
  APPROVED_TASK_IS_UPDATED = "Approved on can't be set for non-approved tasks",
  CANNOT_LINK_DEPENDENT_TASK_AS_SUBTASK = "CANNOT_LINK_DEPENDENT_TASK_AS_SUBTASK",
  }
    