import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Logger } from './logger';
import { ToasterService } from './toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Filesystem, FilesystemPlugin } from '@capacitor/filesystem'
/**
 * Android permission service
 */
@Injectable()
export class AndroidPermissionService {

  /**
   * Current title Constructor
   */
  constructor(
    private androidPermissions: AndroidPermissions,
    private toastr: ToasterService,
    private translateService: TranslateService,
    private logger: NGXLogger,
  ) {}

  checkFineLocationPermission(errorMsg?: string): Promise<boolean> {
    return this.checkSpecificPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION, errorMsg);
  }

  checkCameraPermission(errorMsg?: string): Promise<boolean> {
    return this.checkSpecificPermission(this.androidPermissions.PERMISSION.CAMERA, errorMsg);
  }

  checkWriteExternalStoragePermission(errorMsg?: string): Promise<boolean> {
    return this.checkSpecificPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE, errorMsg);
  }

  checkReadExternalStoragePermission(errorMsg?: string): Promise<boolean> {
    return this.checkSpecificPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, errorMsg);
  }

  async checkForFileSystemPermission(errorMsg?: string): Promise<boolean> {
    const fileSystemPermission = await Filesystem.checkPermissions();
    return this.checkAndRequestPermission(fileSystemPermission.publicStorage === 'granted', Filesystem, errorMsg);
  }

  async checkAndRequestPermission(hasPermission: boolean, plugin: FilesystemPlugin, errorMsg?: string): Promise<boolean> {
    if(hasPermission) {
      return true;
    } else {
      const requestedPermission = await plugin.requestPermissions();
      if(requestedPermission.publicStorage === 'granted') {
        return true;
      } else {
        this.toastr.showErrorToaster(errorMsg);
        return false;
      }
    }
  }

  checkSpecificPermission(permission: any, errorMsg?: string): Promise<boolean> {
    return this.androidPermissions.checkPermission(permission)
      .then(result => {
        if (result && result.hasPermission) {
          return result.hasPermission;
        } else {
          return this.androidPermissions.requestPermission(permission).then(perm => {
            if (perm && perm.hasPermission) {
              return perm.hasPermission;
            } else {
              throw errorMsg;
            }
          }).catch(error => {
            if (errorMsg) {
              this.logger.error('Failed to request android permission : ' + error);
              this.toastr.showErrorToaster(errorMsg);
              return false;
            } else {
              throw error;
            }
          });
        }
      })
      .catch(error => {
        if (errorMsg) {
          this.logger.error('FAILED to check android permission', error);
          this.toastr.showErrorToaster(errorMsg);
          return false;
        } else {
          throw error;
        }
      });
  }
}
