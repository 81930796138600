<ion-header class="secondary-header" mode="ios">
  <app-mobile-toolbar
       doneLabel="ok"
       [title]="type === 'startDatetime' ? 'ui.datetimepicker.date.start' : 'ui.datetimepicker.date.end'"
       (back)="cancel()"
       (done)="saveAndClose()">
  </app-mobile-toolbar>
</ion-header>

<div class="ui-mobile-datetime-picker">
  <!--
  <div  *ngIf="!datetimePickerService.hideShortcut && type === 'startDatetime'" class="top-actions">
    <button type="button" class="secondary-action-button action action-end" (click)="editEndDate()">
      {{'ui.datetimepicker.date.end' | translate }} <img src="/assets/img/left-arrow.svg" class="left-arrow revers">
    </button>
  </div>

  <div *ngIf="!datetimePickerService.hideShortcut && type === 'endDatetime'" class="top-actions">
    <div class="action-start">
      <button type="button" class="secondary-action-button action action-start" (click)="editStartDate()">
        <img src="/assets/img/left-arrow.svg" class="left-arrow">
        {{'ui.datetimepicker.date.start' | translate }}
      </button>
    </div>
    <div class="action-end" *ngIf="datetimePickerService.canClear">
      <button type="button" class="secondary-action-button action action-end" (click)="clear()">
        <img src="/assets/img/cross.svg" class="cross"> {{'ui.datetimepicker.date.end' | translate }}
      </button>
    </div>
  </div>
  -->
  <div id="datepicker">
    <!-- DATE PICKER -->

        <div id="months-years-nav">
            <div id="back" class="arrow" (click)="lastMonth()">
                <em class="fas fa-chevron-left"></em>
            </div>
            <div id="date">{{months[date.getMonth()]}} {{date.getFullYear()}}</div>
            <div id="next" class="arrow" (click)="nextMonth()">
                <em class="fas fa-chevron-right"></em>
            </div>
        </div>
        <div id="days-of-week">
            <div class="day-of-week" *ngFor="let dayOfW of daysOfWeek">{{dayOfW}}</div>
        </div>
        <div id="days">
            <div class="day" *ngFor="let day of calendarArray" (click)="selectDate(day)">
                <span [ngClass]="{
                    'current-day': isCurrentDate(day), 'active':isActiveDate(day)
                  }"> 
          {{day}}
        </span>
      </div>
    </div>
    <!-- END DATE PICKER -->
    <!-- TIME PICKER -->
    <div id="timepicker">
      <div class="ui-timepicker" *ngIf="!datetimePickerService.hideTime">
        <div id="description-wrapper">
          <span class="description">{{ 'ui.datetimepicker.time' | translate }}</span>
        </div>
        <div class="select-wrapper hour ">
          <select class="select-box select-css" (change)="selectHours($event.target.value)">
            <option *ngFor="let hour of hours" [selected]=" hour === selectedHours" [value]="hour">
              {{hour}}
            </option>
          </select>
        </div>
        <div class="select-wrapper colon">
          {{'colon.symbol' | translate}}
        </div>
        <div class="select-wrapper minute">
          <select class="select-box select-css" (change)="selectMinutes($event.target.value)">
            <option *ngFor="let min of minutes" [selected]="min <= date.getMinutes()" [value]="min">
              {{min}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- END TIME PICKER -->
  </div>
</div>
