import { AbstractControl, ValidationErrors } from '@angular/forms';

// Check if a field has an invisible value (if it contains only spaces or other weird characters)
export function notBlankValidator(control: AbstractControl): ValidationErrors | null {
  let spaceRegex;
  try {
    // If platform supports unicode groups in regular expressions,
    // check separators, control characters and other invisible characters
    spaceRegex = new RegExp('^(\\p{Z}|\\p{C})+$', 'u');
  } catch (ex) {
    // If unicode groups are not supported,
    // check only simple whitespace
    spaceRegex = /^\s+$/;
  }
  if (spaceRegex.test(control.value)) {
    return {
      filledWithSpaces: true
    };
  }
  return null;
}
