import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { DatabaseService } from './shared/database.service';
import { ModelElement } from '../models/model-element';
import { IndexableType } from 'dexie';
import { DatabaseHookEvent, SpaceDatabase } from '../models/db/space-database';

export abstract class AbstractModelService<T extends ModelElement> {

  protected itemSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected get currentValue(): T[] {
    return this.itemSubject.getValue();
  }
  public readonly items: Observable<T[]> = this.itemSubject.asObservable();

  /**
   * String representation of the type {@link T} of this service
   * We have to use a string because types are not available at run time
   */
  protected abstract type: string;

  private static checkEqualityCriteria(item: any, equalityCriteria: { [key: string]: IndexableType }) {
    for (const key in equalityCriteria) {
      if (equalityCriteria[key] !== item[key]) {
        return false;
      }
    }
    return true;
  }

  private static filterHook(
    hookEvent: DatabaseHookEvent<any>,
    equalityCriteria: { [p: string]: IndexableType },
    filterFunc: (item: any) => boolean
  ) {
    return hookEvent && hookEvent.object
             && (!equalityCriteria || AbstractModelService.checkEqualityCriteria(hookEvent.object, equalityCriteria))
             && (!filterFunc || filterFunc(hookEvent.object));
  }

  protected constructor(
    protected databaseService: DatabaseService,
  ) {}


  //#region Data access functions

  /**
   * Get the item of type {@link T} with the id {@link itemId} from the database
   * @param itemId The primary key of the item to return
   * @returns If present, the item of id {@link itemId} every time it is modified
   */
  getById(itemId: string): Observable<T> {
    return this.databaseService.watchDB().pipe(
      filter(db => db != null),
      map(db => db.getTable<T>(this.type)),
      switchMap(table => SpaceDatabase.hookTable(table).pipe(
        filter(hookEvent => hookEvent && hookEvent.object && hookEvent.object.id === itemId),
        startWith(null),
        map(() => table)
      )),
      switchMap(table => table.get(itemId))
    );
  }

  /**
   * Get the item of type {@link T} with the id {@link itemId} from the database
   * @param itemId The primary key of the item to return
   * @returns If present, the item of id {@link itemId}
   */
  getOnceById(itemId: string): Promise<T> {
    return this.databaseService.watchDB().pipe(
      filter(db => db != null),
      map(db => db.getTable<T>(this.type)),
      switchMap(table => table.get(itemId)),
      first()
    ).toPromise();
  }

  getItemById(itemId: string): Promise<T> {
    return this.databaseService.getItemById(this.type, itemId);
  }

  emptyItems(): void {
    this.itemSubject.next([]);
  }

  //#endregion


  //#region Data manipulation functions

  /**
   * Add an item to the database, fails if the database is not available
   * @param item The item to addThe server returned an error in response to an action.
   */
  async create(createdItem: T, withQueue?: boolean): Promise<T> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.filter(item => item.id !== createdItem.id).concat([createdItem]));
    }
    return db._add(createdItem, this.type, withQueue);
  }

  /**
   * Add multiple items to the database in one transaction, fails if the database is not available
   * @param items The item array to add
   */
  async createMany(createdItems: T[], withQueue?: boolean): Promise<T[]> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(
        this.currentValue
        .filter(item => !createdItems.some(createdItem => item.id === createdItem.id))
        .concat(createdItems)
      );
    }
    return db._bulkAdd(createdItems, this.type, withQueue);
  }

  /**
   * Update an item in the database, fails if the database is not available
   * @param item The given item
   */
  async update(updatedItem: T, withQueue?: boolean): Promise<T> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.map(item => item.id === updatedItem.id ? updatedItem : item));
    }
    return db._put(updatedItem, this.type, withQueue);
  }

  /**
   * Update multiple items in the database, fails if the database is not available
   * @param items The item array to update
   */
  async updateMany(updatedItems: T[], withQueue?: boolean): Promise<T[]> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.map(item => updatedItems.find(updatedItem => item.id === updatedItem.id) || item));
    }
    return db._bulkPut(updatedItems, this.type, withQueue);
  }

  /**
   * Delete an item from the database, fails if the database is not available
   * @param item The item to delete
   */
  async delete(deletedItem: T, withQueue?: boolean): Promise<T> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.filter(item => item.id !== deletedItem.id));
    }
    return db._delete(deletedItem, this.type, withQueue);
  }

  /**
   * Delete an item from the database, fails if the database is not available
   * @param item The item to delete
   */
  async deleteMany(deletedItems: T[]): Promise<T[]> {
    const db = await this.databaseService.getDBInstant();
    if (this.currentValue) {
      this.itemSubject.next(this.currentValue.filter(item => deletedItems.every(deletedItem => item.id !== deletedItem.id)));
    }
    await db._bulkDelete(deletedItems, this.type);
    return deletedItems;
  }


  //#endregion

}
