import { Injectable, OnInit } from '@angular/core';
import { NetworkStatus } from '@models/synchronization/network-status';
import { LogoutService } from '@services/logout/logout.service';
import { OfflineCheckerService } from '@services/offline-checker.service';
import { SharedService } from '@services/shared/shared.service';
import { RxStomp } from '@stomp/rx-stomp'
import { RxStompState } from '@stomp/rx-stomp';
import { Message } from '@stomp/stompjs';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ModelElement } from '../../models/model-element';
import { sdRxStompConfig } from '../../sd-rx-stomp.config';
import { AuthService } from '../auth/auth.service';
import { Logger } from '../logger';
import { SessionService } from '../session.service';
import { QueueService } from '../synchronization/queue.service';
import { SyncSequenceDAO } from '../synchronization/sync-sequence-DAO.service';
import { SyncService } from '../synchronization/sync.service';
import { SyncStatusService } from '../synchronization/sync-status.service';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';
import { LAST_NOTIFICATION_READ_TIMESTAMP, NotificationService } from '@services/notification/notification.service';


@Injectable()
export class RxStompService extends RxStomp {
  connectionSubscription: Subscription;
  queueErrors: Subscription;
  queueReply: Subscription;
  stompErrors: Subscription;
  connectionOpen: Subscription;
  spaceSubscription: Subscription;
  siteSubscription: Subscription;
  sessionSubscription: Subscription;
  notificationSubscription: Subscription;

  /** Interval's id of the current queue  */
  timer = null;

  currentSpaceId: string;
  currentSiteId: string;
  watchSpaceId: Observable<string>;
  watchSpaceAndSiteIds: Observable<[string | null, string | null]>;

  constructor(
    private queueService: QueueService,
    private syncSequenceService: SyncSequenceDAO,
    private authService: AuthService,
    private syncService: SyncService,
    private syncStatusService: SyncStatusService,
    private sessionService: SessionService,
    private offlineCheckerService: OfflineCheckerService,
    private logoutService: LogoutService,
    private sharedService: SharedService,
    private logger: NGXLogger,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService,
    private notificationService: NotificationService,
  ) {
    super();
    // Initialize websocket configuration
    this.configure(sdRxStompConfig);
    if(deviceService.isMobile) {
      this.sharedService.watchSpaceId.subscribe((spaceId) => {
        this.currentSpaceId = spaceId;
      });
      this.sharedService.watchSiteId.subscribe((siteId) => {
        this.currentSiteId = siteId;
      });
      this.watchSpaceId = this.sharedService.watchSpaceId;
      this.watchSpaceAndSiteIds = this.sharedService.watchSpaceAndSiteIds;
    } else {
      this.sharedDataService.watchSpaceId.subscribe((spaceId) => {
        this.currentSpaceId = spaceId;
      });
      this.sharedDataService.watchSiteId.subscribe((siteId) => {
        this.currentSiteId = siteId;
      });
      this.watchSpaceId = this.sharedDataService.watchSpaceId;
      this.watchSpaceAndSiteIds = this.sharedDataService.watchSpaceAndSiteIds;
    }
    this.logoutService.addLogoutCallback(() => this.disconnect());
  }



  private executeQueueTimeout(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(async () => {
      let continueQueue = true;
      while (continueQueue) {
        continueQueue = await this.queueService.popQueue();
      }
      let lastNotificationReadTimestamp = localStorage.getItem(LAST_NOTIFICATION_READ_TIMESTAMP);
      if(lastNotificationReadTimestamp) {
        this.notificationService.popLastNotificationReadTimestamp(lastNotificationReadTimestamp);
      }
      this.executeQueueTimeout();
    }, 5000);
  }

  /**
   * STOMP Connection step
   * Creating new config with accessToken authorization and watching for stomp errors
   */
  public connect(): void {
    this.executeQueueTimeout();

    // Watch user-specific errors
    this.queueErrors = this.watch(`/user/queue/errors`)
      .subscribe((message: Message) => {
        Logger.queue.error('[/user/queue/errors]', message);
      });

    // Watch user-specific information
    this.queueReply = this.watch(`/user/queue/reply`)
      .subscribe((message: Message) => {
        Logger.queue.info('[/user/queue/reply]', message);
      });

    // Watch STOMP errors
    this.stompErrors = this.stompErrors$
      .subscribe(async (message: Message) => {
        if (message.headers.message.includes('invalid_token')) {
          this.logger.info(`Refreshing token due to STOMP ERROR`);
          this.logger.warn('[STOMP_ERROR]', message);
          await this.manualDisconnect();
          this.authService.refreshUserSession();
        } else {
          this.logger.warn('[STOMP_ERROR]', message);
        }
      });

    // Watch space sync status to subscribe to the right space channel
    this.syncStatusService.watchSpaceSyncStatus
      .pipe(filter(syncStatus => syncStatus.status === 'success'))
      .subscribe(syncStatus => {
        if (syncStatus.spaceId) {
          // Trigger websocket subscription to given space channel when synchro is done
          this.watchSpace(syncStatus.spaceId);
        }
      });

    // Watch site sync status to subscribe to the right site channel
    this.syncStatusService.watchSiteSyncStatus
      .pipe(filter(syncStatus => syncStatus.status === 'success'))
      .subscribe(syncStatus => {
        if (syncStatus.spaceId && syncStatus.siteId) {
          // Trigger websocket subscription to given site channel when synchro is done
          this.watchSite(syncStatus.spaceId, syncStatus.siteId);
        }
      });

    //  Execute complete synchronization when reopening websocket connections (e.g. when regaining connection or coming out of sleep mode)
    this.connectionOpen = this.connectionState$.pipe(
      filter(state => state === RxStompState.OPEN),
    ).subscribe(async state => {
      if (this.sharedService.hasSpaceSelected) {
        if(this.deviceService.isMobile) {
          await this.syncService.executeSynchronization(this.currentSpaceId);
        } else {
          this.sharedDataService.executeSynchronization(this.currentSpaceId);
        }
        if (this.sharedService.hasSiteSelected) {
          if(this.deviceService.isMobile) {
            await this.syncService.executeSynchronization(this.currentSpaceId, this.currentSiteId);
          } else {
            this.sharedDataService.executeSynchronization(this.currentSpaceId, this.currentSiteId);
          }
        }
      }
    });


    // Watch session refresh to update connect headers with new access_token when available

    this.sessionSubscription = combineLatest([
      NetworkStatus.watchIsOnline(),
      this.sessionService.watchSession(),
    ]).subscribe(async ([isOnline, session]) => {
      if (session && session.accessToken && isOnline) {
        this.configure({ connectHeaders: { Authorization: `Bearer ${session.accessToken}` } });
        Logger.info(`Connecting to websocket with token ${session.accessToken}.`);
        await this.manualDisconnect();
        this.manualConnect();
        this.subscribeToNotificationChannel();
      }
      else {
        Logger.info('Offline or no access_token available, disconnecting websocket.');
        await this.manualDisconnect();
      }
    });

    // Watch space to subscribe to relevant websocket channel on space change
    combineLatest([
      NetworkStatus.watchIsOnline(),
      this.watchSpaceId
    ]).pipe(filter(([isOnline, spaceId]) => isOnline && !!spaceId))
    .subscribe(([isOnline, spaceId]) => {
      this.watchSpace(spaceId);
    });

    // Watch space and site to subscribe to relevant websocket channel on space/site change
    combineLatest([
      NetworkStatus.watchIsOnline(),
      this.watchSpaceAndSiteIds
    ]).pipe(filter(([isOnline, [spaceId, siteId]]) => isOnline && !!spaceId && !!siteId))
    .subscribe(([isOnline, [spaceId, siteId]]) => {
      this.watchSite(spaceId, siteId);
    });
  }

  /** Activate websocket manually. */
  private manualConnect(): void {
    if (!this.connected()) {
      this.activate();
      this.startCheckingConnectionState();
    }
  }

  /** Disable websocket manually. Reset all subscriptions*/
  private async manualDisconnect(): Promise<void> {
    this.resetConnectionSubscription();
    await this.deactivate();
  }

  private resetConnectionSubscription(): void {
    if (this.connectionSubscription) {
      this.connectionSubscription.unsubscribe();
      this.connectionSubscription = null;
    }
  }

  /**
   * Wait for WS to be actually connected before watching for it close in order to trigger connection checks.
   * This way, we can ensure we trigger connection check only if WS was supposed to be connected.
   */
  private startCheckingConnectionState(): void {
    this.resetConnectionSubscription();
    this.connectionSubscription = this.connectionState$.subscribe(state => {
      if (state === RxStompState.OPEN) {
        this.waitForClosedState();
      }
    });
  }

  /** Wait for WS to close and trigger connection check. */
  private waitForClosedState(): void {
    this.resetConnectionSubscription();
    this.connectionSubscription = this.connectionState$.subscribe(state => {
      if (state === RxStompState.CLOSED) {
        this.offlineCheckerService.startChecking();
      }
    });
  }

  public watchSpace(spaceId: string): void {
      this.subscribeToSpaceChannel(spaceId, this.sessionService.getSession().userId).catch(error => {
        Logger.queue.error(`An error has occurred within space channel ${spaceId}: `, error);
      });
  }

  private async subscribeToSpaceChannel(spaceId: string, userId: string): Promise<void> {
    if (this.spaceSubscription) {
      this.spaceSubscription.unsubscribe();
    }
    Logger.queue.info('[websocket] Subscribing to space channel: ', `/topic/${userId}/space/${spaceId}`);
    this.spaceSubscription =  this.watch(`/topic/${userId}/space/${spaceId}`)
      .subscribe((message: Message) => {
        Logger.queue.info(`[/topic/${userId}/space/${spaceId}]`, message);
        const body = JSON.parse(message.body);
        // Synchronize site action received from websocket
        if (body.item) {
          if(this.deviceService.isMobile) {
            this.syncService.executeSynchronization(spaceId);
          } else {
            this.sharedDataService.executeSynchronization(spaceId);
          }
        }
      });
  }

  /**
   * Subscribe on site topic
   * @param spaceId given spaceId
   * @param siteId given siteId
   */
  public watchSite(spaceId: string, siteId: string): void {
    // If the queue contains a site creation action, we wait for it to complete
    this.queueService.waitForSiteCreation(spaceId, siteId)
      .then(() => this.subscribeToSiteChannel(spaceId, siteId))
      .catch(error => {
        this.logger.error(`Error while subscribing to the site channel for the site ${spaceId}/${siteId}`, error);
      });
  }

  private async subscribeToSiteChannel(spaceId: string, siteId: string): Promise<void> {
    // Unsubscribe last site topic (avoid memory leak)
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
    const userId = this.sessionService.getSession().userId;
    Logger.queue.info('[websocket] Subscribing to site channel: ', spaceId + '/' + siteId);
    this.siteSubscription = this.watch(`/topic/${userId}/space/${spaceId}/site/${siteId}`)
      .subscribe((message: Message) => {
        Logger.queue.info(`[/topic/${userId}/space/${spaceId}/site/${siteId}]`, message);
        const body = JSON.parse(message.body);
        // Synchronize site action received from websocket
        if (body.item) {
          // On deletion of site, both site channel and space channel receive pings.
          // Only space channel ping should will considered for deletion of the site. 
          // Hence Site level synchronization should not be done for deleted site.
          if(!(body.item.action === 'DELETE' && body.item.type === 'Site')) {
            if(this.deviceService.isMobile) {
              this.syncService.executeSynchronization(spaceId, siteId);
            } else {
              this.sharedDataService.executeSynchronization(this.currentSpaceId, this.currentSiteId);
            }
          }
        }
      });
  }

  private async subscribeToNotificationChannel(): Promise<void> {
    // Unsubscribe last notification topic (avoid memory leak)
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    const userId = this.sessionService.getSession().userId;
    // call the notification api whenever the session changes
    this.notificationService.getNotifications();
    Logger.queue.info('[websocket] Subscribing to notification channel: ');
    this.notificationSubscription = this.watch(`/topic/${userId}/notification`)
      .subscribe((message: Message) => {
        this.notificationService.getNotifications();
      });
  }

  /**
   * Called after logout, disconnect STOMP service (stop auto reconnect loop)
   */
  private async disconnect(): Promise<void> {
    Logger.queue.info('[websocket] End of connection...');
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    // Deactivate websocket
    await this.manualDisconnect();

    this.queueErrors.unsubscribe();
    this.queueReply.unsubscribe();
    this.stompErrors.unsubscribe();
    this.connectionOpen.unsubscribe();
    this.sessionSubscription.unsubscribe();

    if (this.siteSubscription !== undefined) {
      this.siteSubscription.unsubscribe();
    }

    if (this.spaceSubscription !== undefined) {
      this.spaceSubscription.unsubscribe();
    }

    if (this.notificationSubscription !== undefined) {
      this.notificationSubscription.unsubscribe();
    }
  }
}

