import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '@models/site';
import { Weather } from '@models/weather';
import { WeatherService } from '@services/weather.service';
import { SharedService } from '@services/shared/shared.service';
import { SharedDataService } from '@services/shared-data.service';
import { DeviceService } from '@services/device.service';

@Pipe({
    name: 'weatherWindspeedPipe'
})
export class WeatherWindspeedPipe implements PipeTransform {
    relevantSharedService: SharedService | SharedDataService;
    constructor(
      private sharedService: SharedService,
      private sharedDataService: SharedDataService,
      private deviceService: DeviceService,
      ) {
        this.relevantSharedService = this.deviceService.isMobile ? this.sharedService : this.sharedDataService;
      }

    async transform(windSpeed: Weather['windSpeed']): Promise<string> {
      const site : Site = await this.relevantSharedService.getNextNonNullSite();
      return WeatherService.getWindspeedWithSiteSettingsUnits(windSpeed, site);
    }
}
