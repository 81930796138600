import { Injectable } from '@angular/core';

import { Location } from '../models/location';
import { SharedService } from './shared/shared.service';
import { DatabaseService } from './shared/database.service';
import { AbstractModelService } from '@services/abstract-model-service';
import { EventLocation } from '@models/event-location';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventService } from './event.service';
import { TaskService } from './task.service';
import { DeviceService } from './device.service';
import { SharedDataService } from './shared-data.service';

/**
 * Service of location. Use for load {@link Location} from web service
 */
@Injectable()
export class LocationService extends AbstractModelService<Location> {
  protected type = 'Location';

  public readonly siteItems: Observable<Location[]> = this.items.pipe(map(items => items.filter(item => item.siteId === this.sharedService.currentSiteId)));
  public readonly spaceItems: Observable<Location[]> = this.items.pipe(map(items => items.filter(item => item.siteId === 'null')));

  constructor(
    protected databaseService: DatabaseService,
    private sharedService: SharedService,
    private eventService: EventService,
    private taskService: TaskService,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService,
  ) {
    super(
      databaseService,
    );
    if(!this.deviceService.isMobile) {
      this.spaceItems = this.sharedDataService.getSpaceLocations();
      this.siteItems = this.sharedDataService.watchSiteLocations;
    }
  }

  public async filterForCurrentSpace(): Promise<void>  {
    const spaceLocations = await this.databaseService.getSpaceItems('location');
    this.itemSubject.next(spaceLocations);
  }

  public async filterForCurrentSite(): Promise<void>  {
    const siteLocations = await this.databaseService.getSiteItems('location', this.sharedService.currentSiteId);
    this.itemSubject.next(siteLocations);
  }

  public async filterAll(): Promise<void> {
    const locations = await this.databaseService.getItemsForCurrentSiteAndSpace('location', this.sharedService.currentSiteId);
    this.itemSubject.next(locations);
  }

  getLocationById(id: string): Promise<Location> {
    return this.databaseService.getItemById('location', id);
  }

  /**
   * Check if location has a name
   * @param location Location to check
   */
  checkIntegrity(location: Location): boolean {
    return location.name !== '';
  }

  /**
  * Method used to convert a {@link_Location} to the {@link_EventLocation} format expected by an event
  * @param location given location
  */
  convertLocationToEventLocation(location: Location): EventLocation {
    const eventLocation = new EventLocation();
    eventLocation.id = location.id;
    eventLocation.name = location.name;
    return eventLocation;
  }

  /**
    * Method used to convert an array of {@link_Location} to an array of {@link_EventLocation}
    * @param location given location
    */
  convertLocationsToEventLocations(locations: Location[]): EventLocation[] {
    const eventLocation = new Array();
    locations.forEach(location => {
      eventLocation.push(this.convertLocationToEventLocation(location));
    });
    return eventLocation;
  }
}
