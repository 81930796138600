export class AppUpdateSettings {
    public showAppUpdateBanner: boolean = false;
    public showAppUpdateToaster: boolean = false;
    public appUpdateLevel: ApplicationVersionStatus = ApplicationVersionStatus.appUpToDate;
    public canCreateEntities: boolean = true;
    public currentAppVersion: string = null;
  }

export enum ApplicationVersionStatus {
  appUpToDate = 'UpToDate',
  appOutdated = 'Outdated',
  appOutdatedSyncOnly = 'OutdatedSyncOnly',
  appUnsupported = 'Unsupported'
}
