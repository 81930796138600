import { TaskContractor } from './task-contractor';

export class EventContractor extends TaskContractor {

    constructor() {
        super();
    }

    public static jsonToEventContractors(jsonContractors): Array<EventContractor> {
      return super.jsonToTaskContractors(jsonContractors);
    }

    public static eventContractorsToDto(eventContractors: EventContractor[]): Array<string> {
      return super.taskContractorsToDto(eventContractors);
    }
}
