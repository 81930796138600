/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@models/notification';
import { BELocalNotificationConfigurationsResponse, BENotificationConfigurationsResponse, NotificationConfiguration } from '@models/notification-configuration';
import { NotificationContent } from '@models/notification-content';
import { NOTIFICATION_TYPE } from '@models/notification-type';
import { NetworkStatus } from '@models/synchronization/network-status';
import { taskNumberPrefix } from '@models/task';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@services/device.service';
import { LogoutService } from '@services/logout/logout.service';
import { DatabaseService } from '@services/shared/database.service';
import { SpinnerService } from '@services/spinner.service';
import { UrlGiverService } from '@services/url-giver.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const MAX_NOTIFICATIONS_COUNT = 100;
export const INTERVAL_TO_UPDATE_NOTIFIED_AT = 60000;
export const LAST_NOTIFICATION_READ_TIMESTAMP = 'lastNotificationReadTimestamp';
export const LAST_NOTIFICATIONS_SYNCED_AT = 'lastNotificationsSyncedAt';

interface SubscribedTagsResponse {
  subscribedTags: string[];
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationConfiguration$ = new BehaviorSubject<NotificationConfiguration[]>(null);
  public notificationConfiguration = this.notificationConfiguration$.asObservable();
  protected notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  protected get currentNotifications(): Notification[] {
    return this.notifications$.getValue();
  }
  public readonly watchNotifications: Observable<Notification[]> = this.notifications$.asObservable();

  notificationsAPIUrl = '';

  constructor(
    private databaseService: DatabaseService,
    private translate: TranslateService,
    private urlGiverService: UrlGiverService,
    private http: HttpClient,
    private deviceService: DeviceService,
    private spinnerService: SpinnerService,
    private logoutService: LogoutService,
  ) {
    this.notificationsAPIUrl = this.urlGiverService.giveNotificationAPIUrl();
    this.logoutService.addLogoutCallback(() => {
      this.clearNotificationDataOnLogout();
    });
    if (deviceService.isMobile) {
      this.deleteExcessNotificationsFromDB();
    }
  }

  /* DATABASE FUNCTIONS REGION */

  /**
   * Add the notification to indexDB
   * @param notification
   */
  addNotificationToDB(notification: Notification): void {
    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw?', userDB.notifications, async () => {
        await userDB.notifications.put(notification);
        if (this.currentNotifications) {
          this.notifications$.next(this.currentNotifications.filter(currentNotificationItem =>
            currentNotificationItem.id !== notification.id).concat([notification])
          );
        }
      });
    });
  }

  /**
   * Add multiple notifications to indexDB in a single transaction
   * @param notifications
   */
  addMultipleNotificationsToDB(notifications: Notification[]): void {
    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw?', userDB.notifications, async () => {
        await userDB.notifications.bulkPut(notifications);
        if (this.currentNotifications) {
          this.notifications$.next(
            this.currentNotifications
              .filter(currentNotificationItem => !notifications.some(createdNotificationItem =>
                currentNotificationItem.id === createdNotificationItem.id
              )).concat(notifications)
          );
        }
      });
    });
  }

  /**
   * Delete all the notifications for the current user from indexDB
   */
  deleteAllNotificationsFromDB(): void {
    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw', userDB.notifications, async () => {
        await userDB.notifications.clear();
        this.updateNotifications([]);
      });
    });
  }

  /**
   * Delete all excess notifications that are over the max notification count.
   */
  async deleteExcessNotificationsFromDB(): Promise<void> {
    const notificationsCount = await this.getUserNotificationsCount();
    if (notificationsCount > MAX_NOTIFICATIONS_COUNT) {
      this.deleteUserNotifications(notificationsCount);
    }
  }

  async deleteUserNotifications(notificationsCount: number): Promise<void> {
    const userDB = await this.databaseService.getUserDB();
    await userDB.notifications.limit(notificationsCount - MAX_NOTIFICATIONS_COUNT).delete();
    const notifications = await userDB.notifications.toArray();
    this.notifications$.next(notifications);
  }

  async getUserNotificationsCount(): Promise<number> {
    const userDB = await this.databaseService.getUserDB();
    return userDB.notifications.count();
  }

  /* END DATABASE FUNCTIONS REGION */

  getNotificationConfigurationFromBackend(): void {
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.getNotificationConfiguration().then((response) => {
        this.notificationConfiguration$.next(response);
      });
    });
  }


  isEventRejectionNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.EVENT_REJECTION;
  }

  isTaskAssignmentNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.TASK_ASSIGNMENT;
  }

  isTaskCompletionNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.TASK_COMPLETION;
  }

  isEventWithTagNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.TAG_NOTIFICATION;
  }

  isSpaceAccessNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.REMOVED_FROM_SPACE ||
      notification.notificationType === NOTIFICATION_TYPE.ADDED_TO_SPACE;
  }

  isUserDeletionNotification(notification: Notification): boolean {
    return notification.notificationType === NOTIFICATION_TYPE.USER_ACCOUNT_DELETED;
  }

  getNotificationMessage(notification: Notification): string {
    let translation: string = null;
    const notificationContent = notification.notificationContent;
    if (this.isEventRejectionNotification(notification)) {
      this.translate.get('notifications.event_rejection.message', {
        eventTitle: notification.notificationContent.objectTitle,
        rejectedBy: notification.notificationContent.objectCausedByName
      }).subscribe((val: string) => {
        translation = val;
      });
      return translation;
    } else if (this.isTaskAssignmentNotification(notification)) {
      const taskNumber = this.getTaskNumberForTaskRelatedNotification(notificationContent);
      this.translate.get('notifications.task_assignment.message', {
        taskNumber: taskNumber,
        taskTitle: notification.notificationContent.objectTitle,
        assignedBy: notification.notificationContent.objectCausedByName
      }).subscribe((val: string) => {
        translation = val;
      });
      return translation;
    } else if (this.isTaskCompletionNotification(notification)) {
      const taskNumber = this.getTaskNumberForTaskRelatedNotification(notificationContent);
      this.translate.get('notifications.task_completion.message', {
        taskNumber: taskNumber,
        taskTitle: notification.notificationContent.objectTitle,
        completedBy: notification.notificationContent.objectCausedByName
      }).subscribe((val: string) => {
        translation = val;
      });
      return translation;
    } else if (this.isEventWithTagNotification(notification)) {
      let translationKey = '';
      const tags = notification.notificationContent.objectTagNames;
      let tagNames: string = null;
      if (tags.length === 1) {
        translationKey = 'notifications.event_with_tag.message';
        tagNames = tags[0];
      } else {
        translationKey = 'notifications.event_with_tags.message';
        tagNames = tags.join(', ');
      }
      this.translate.get(translationKey, {
        eventTitle: notification.notificationContent.objectTitle,
        createdBy: notification.notificationContent.objectCausedByName,
        tags: tagNames
      }).subscribe((val: string) => {
        translation = val;
      });
      return translation;
    } else if (this.isSpaceAccessNotification(notification)) {
      const spaceName = notification.notificationContent.objectTitle;
      const causedBy = notification.notificationContent.objectCausedByName;
      const messageString = notification.notificationType === NOTIFICATION_TYPE.REMOVED_FROM_SPACE ?
        'notifications.removed_from_space' : 'notifications.added_to_space';
      this.translate.get(messageString, {
        space: spaceName,
        username: causedBy
      }).subscribe((val: string) => translation = val);
      return translation;
    } else if (this.isUserDeletionNotification(notification)) {
      const username = notification.notificationContent.objectCausedByName;
      const spaceName = notification.notificationContent.objectTitle;
      this.translate.get('notifications.user_account_deletion', {
        username: username,
        space: spaceName
      }).subscribe((val: string) => translation = val);
      return translation;
    }
    return null;
  }

  getTaskNumberForTaskRelatedNotification(notificationContent: NotificationContent): string {
    let taskNumber = '';
    if (notificationContent?.objectTaskNumber) {
      if (notificationContent?.objectParentTaskId) {
        taskNumber = taskNumberPrefix.SUBTASK_PREFIX + '-' + notificationContent?.objectTaskNumber.toString();
      } else {
        taskNumber = taskNumberPrefix.TASK_PREFIX + '-' + notificationContent?.objectTaskNumber.toString();
      }
    }
    return taskNumber;
  }

  setLastNotificationsSyncedAt(timestamp: number): void {
    localStorage.setItem(LAST_NOTIFICATIONS_SYNCED_AT, timestamp.toString());
  }

  clearLastNotificationsSyncedAt(): void {
    localStorage.removeItem(LAST_NOTIFICATIONS_SYNCED_AT);
  }

  setLastNotificationReadTimestamp(timestamp: number): void {
    localStorage.setItem(LAST_NOTIFICATION_READ_TIMESTAMP, timestamp.toString());
  }

  clearLastNotificationReadTimeStamp(): void {
    localStorage.removeItem(LAST_NOTIFICATION_READ_TIMESTAMP);
  }

  async fetchNotificationsFromDb(): Promise<void> {
    const userDB = await this.databaseService.getUserDB();
    let notifications = await userDB.notifications.toArray();
    notifications = Notification.sortByCreatedOnTimeStamp(notifications);
    this.notifications$.next(notifications);
  }

  updateNotifications(notifications: Notification[]): void {
    this.notifications$.next(notifications);
  }

  // filter tasks related notifications if received from BE
  filterTasksRelatedNotifications(notifications: Notification[]) {
    return notifications.filter(notification => (
      notification.notificationType === NOTIFICATION_TYPE.EVENT_REJECTION ||
      notification.notificationType === NOTIFICATION_TYPE.TAG_NOTIFICATION ||
      notification.notificationType === NOTIFICATION_TYPE.ADDED_TO_SPACE ||
      notification.notificationType === NOTIFICATION_TYPE.REMOVED_FROM_SPACE ||
      notification.notificationType === NOTIFICATION_TYPE.USER_ACCOUNT_DELETED
    ));
  }

  // filter notifications if notification type or version type is null
  filterNullTypeNotification(notifications: Notification[]) {
    return notifications.filter(notification => notification.notificationType !== null || notification.notificationTypeVersion !== null);
  }

  popLastNotificationReadTimestamp(lastNotificationReadTimestamp: string): void {
    if (NetworkStatus.isOnline) {
      if (!isNaN(Number(lastNotificationReadTimestamp))) {
        this.postNotificationsAsReadInMobile(Number(lastNotificationReadTimestamp))
          .then(() => {
            this.getNotifications();
            this.clearLastNotificationReadTimeStamp();
          });
      }
    }
  }

  getNotifications(): Promise<Notification[]> {
    return this.http.get<Notification[]>(this.notificationsAPIUrl).pipe(
      map((response) => {
        let notifications: Notification[] = [];
        response.forEach((notificationJSON) => {
          const newNotification = new Notification();
          Notification.toModel(notificationJSON, newNotification);
          notifications.push(newNotification);
        });
        notifications = Notification.sortByCreatedOnTimeStamp(notifications);
        this.updateNotifications(notifications);
        if (this.deviceService.isMobile) {
          this.addMultipleNotificationsToDB(notifications);
        }
        return response;
      })
    ).toPromise();
  }

  postNotificationsAsRead(): Promise<void> {
    const notifications = this.notifications$.value;
    if (!notifications || notifications.length === 0 || notifications[0].isRead) {
      return null;
    }

    const latestNotificationTimestamp = notifications[0].createdOn;
    const requestBody = {
      seq: latestNotificationTimestamp
    };
    return this.http.post(this.notificationsAPIUrl, requestBody).toPromise()
      .then(() => {
        this.getNotifications();
      });
  }

  postNotificationsAsReadInMobile(latestNotificationTimestamp: number): Promise<void> {
    const requestBody = {
      seq: latestNotificationTimestamp
    };
    return this.http.post<void>(this.notificationsAPIUrl, requestBody).toPromise();
  }

  getNotificationConfiguration(): Promise<NotificationConfiguration[]> {
    const url = this.urlGiverService.giveNotificationConfigurationAPIUrl();
    return this.http.get<BENotificationConfigurationsResponse[]>(url).pipe(
      map((response) => NotificationConfiguration.toModel(response))
    ).toPromise();
  }

  postNotificationConfiguration(notificationConfigurations: BENotificationConfigurationsResponse[]): Promise<void> {
    const url = this.urlGiverService.giveNotificationConfigurationAPIUrl();
    this.spinnerService.activate('pulsating');
    return this.http.post<void>(url, notificationConfigurations).toPromise();
  }

  getLocalNotificationConfiguration(): Promise<BELocalNotificationConfigurationsResponse[]> {
    const url = this.urlGiverService.giveLocalNotificationConfigurationURL();
    return this.http.get<BELocalNotificationConfigurationsResponse[]>(url).toPromise();
  }

  postLocalNotificationConfiguration(notificationConfigurations: BELocalNotificationConfigurationsResponse[]): Promise<void> {
    const url = this.urlGiverService.giveLocalNotificationConfigurationURL();
		this.spinnerService.activate('pulsating');
		return this.http.post<void>(url, notificationConfigurations).toPromise();
  }

  clearNotificationDataOnLogout(): void {
    this.notificationConfiguration$.next(null);
    this.notifications$.next([]);
    this.clearLastNotificationReadTimeStamp();
  }

  getUserSiteSubscribedTags(spaceId: string, siteId: string): Promise<string[]> {
    const url = this.urlGiverService.giveUserSubscribedTagsAPIUrl(spaceId, siteId);
    return this.http.get(url).toPromise()
      .then((response: SubscribedTagsResponse) => response.subscribedTags);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribeToTag(tagId: string, spaceId: string, siteId: string): Promise<any> {
    const url = this.urlGiverService.giveTagSubscriptionAPIUrl(spaceId, siteId);
    const body = {
      tagId
    };
    return this.http.post(url, body).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unSubscribeToTag(tagId: string, spaceId: string, siteId: string): Promise<any> {
    const url = this.urlGiverService.giveTagUnSubscriptionAPIUrl(spaceId, siteId);
    const body = {
      tagId
    };
    return this.http.delete(url, {body}).toPromise();
  }

}
