import { Component } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';


@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent extends AbstractButtonComponent {

}
