import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActionType, SyncDto } from '@models/synchronization/sync-dto';
import { Logger } from '../logger';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { FileTransfertService } from '../file-transfert.service';
import { QueueAction, RetryDetails } from '@models/synchronization/queue-action';
import { DatabaseService } from '../shared/database.service';
import { ServerError } from '@models/errors/server-error';
import { NetworkStatus } from '@models/synchronization/network-status';
import { HttpStatus } from '@constants/http/http-status';
import { ToasterService } from '@services/toaster.service';
import { SyncException, SyncExceptionType } from './sync-exception';
import { NoInternetConnectionError } from './no-internet-connection-error';
import { ServerErrorDuplicateItem } from './server-error-duplicate-item';
import { NGXLogger } from 'ngx-logger';
import { ErrorQueueAction } from '@models/synchronization/error-queue-action';
import { PostStatusStates, PostStatusService } from '@services/post-status.service';
import * as Sentry from '@sentry/angular';
import { ServerErrorSameAssetName } from './server-error-same-asset-name';
import { SessionService } from '@services/session.service';
import { ServerErrorEntityBeingUsed } from './server-error-entity-being-used';
import { Asset } from '@models/asset';
import { Tag } from '@models/tag';
import { Contractor } from '@models/contractor';
import { Location } from '@models/location';
import { ModelElement } from '@models/model-element';
import { SERVER_ERROR_MESSAGES } from '@models/error-messages';
import { PendingAttachmentService } from '@services/pending-attachment.service';
import { FaultyEventErrorCode } from '@models/faulty-event-errors';
import { UserDatabase } from '@models/db/user-database';

const SITE_SYNC_VERSION = '4';

const FIRST_RETRY_ATTEMPT_IN_MS = 10000; // 10 seconds
const SECOND_RETRY_ATTEMPT_IN_MS = 60000; // 1 minute
const THIRD_RETRY_ATTEMPT_IN_MS = 120000; // 2 minutes
const MAX_AMOUNT_OF_RETRIES = 3;

@Injectable({
  providedIn: 'root',
})
export class QueueService {

  private queue$ = new Subject<SyncDto>();
  public readonly queueCount$ = new BehaviorSubject<number>(0);

  private readonly tenantUrl = environment.apiUrl + '/tenant';

  public static syncException$ = new Subject<SyncException>();

  constructor(
    private https: HttpClient,
    private databaseService: DatabaseService,
    private fileTransferService: FileTransfertService,
    private toasterService: ToasterService,
    private logger: NGXLogger,
    private postStatusService: PostStatusService,
    private sessionService: SessionService,
    private pendingAttachmentService: PendingAttachmentService
  ) { }

  public async popQueue(): Promise<boolean> {
    if (NetworkStatus.isOnline) {
      const db = await this.databaseService.getUserDB();
      if(db) {
        const queueAction: QueueAction = await db.queue.limit(1).first();
        if (!queueAction) {
          // Queue is empty
          this.queueCount$.next(0);
          return false;
        } else {
          this.queueCount$.next(await db.queue.count());
          if((queueAction.retryDetails.retryAttempt === 0) || (queueAction.retryDetails.lastRetryTime + this.getRetryTimeout(queueAction.retryDetails.retryAttempt) < new Date().getTime())) {
            return await this.executeQueueAction(queueAction);
          } else {
            await this.waitTimeout(this.getRetryTimeout(queueAction.retryDetails.retryAttempt));
            if(NetworkStatus.isOnline) {
              return await this.executeQueueAction(queueAction);
            } else {
              return false;
            }
          }
        }
      } else {
        return false;
      }
    } else {
      Logger.queue.debug(`App is offline`);
      return false;
    }
  }

  public async addAction(action: SyncDto, spaceId: string): Promise<void> {
    const db = await this.databaseService.getUserDB();
    try {
      const retryDetails: RetryDetails = {
        retryAttempt: 0,
        lastRetryTime: null,
        shouldRetry: false,
        actionSucceeded: false,
        actionFailed: false
      }
      await db.queue.add(new QueueAction(spaceId, action, retryDetails));
      if(action.type !== 'Attachment') {
        this.logger.info('Action added to the queue: ', action);
      } else {
        this.logger.info('Attachment added to the queue: [action, eventId, attachmentId]', action.action, action.payload.eventId, action.payload.id);
        this.pendingAttachmentService.pushAttachment(action.payload.id);
      }
    } catch (error) {
      this.logger.error('Error while adding an action to the queue: ', error);
      throw error;
    }
  }

  async waitTimeout(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async executeQueueAction(queueAction: QueueAction): Promise<boolean> {
    const db = await this.databaseService.getUserDB();
    try {
      if (!await this.executeAction(queueAction.spaceId, queueAction.action, queueAction.retryDetails)) {
        Logger.queue.info('An action has failed and demanded that the queue be stopped.', queueAction.action);
        return false;
      } else {
        queueAction.retryDetails.actionSucceeded = true;
        // Remove attachment ID from pending list
        if (queueAction.action.type === 'Attachment') {
          this.pendingAttachmentService.popAttachment(queueAction.action.payload.id);
        }
      }
    } catch (error) {
      queueAction.retryDetails.lastRetryTime = new Date().getTime();
      queueAction.retryDetails.retryAttempt += 1;
      // We first check the the event failed due to the event being faulty. If so, we directly move
      // it to the error items queue and proceed with the next request. If not, we attempt to retry the
      // request thrice before moving it to the faulty items queue.
      const isFaultyEvent = this.checkForFaultyEvent(error);
      if (isFaultyEvent) {
        queueAction.retryDetails.shouldRetry = false;
        this.moveItemToErrorQueue(db, queueAction, error.cause.error.errorCode);
      } else {
        queueAction = this.checkForQueueActionRetryCondition(queueAction, error);
      }
      if(queueAction.retryDetails.shouldRetry && queueAction.action.type !== 'Attachment') {
        if(queueAction.retryDetails.retryAttempt === 1 && !(error instanceof NoInternetConnectionError)) {
          this.throwWarningItemToSentry(queueAction.action);
        }
        let errorCode: string;
        if (error && error.cause && error.cause.error && error.cause.error.errorCode) {
          errorCode = error.cause.error.errorCode;
        }
        if (queueAction.retryDetails.retryAttempt > MAX_AMOUNT_OF_RETRIES) {
          this.moveItemToErrorQueue(db, queueAction, errorCode);
        } else {
          await db.queue.put(queueAction);
        }
      } else {
        // Here the error has been known and handled or it is an attachment error, hence queue action
        // does not need to be retried nor added to the error items queue.
        // Delete the queue action from the db and move on.
        await db.queue.delete(queueAction.id);
        this.queue$.next(queueAction.action);
        if(queueAction.action.type === 'Attachment') {
          this.toasterService.showWarningToaster('sync.upload_images.error.message');
        }
      }
    }
      try {
        if(queueAction.retryDetails.actionSucceeded || queueAction.retryDetails.actionFailed) {
          await db.queue.delete(queueAction.id);
          if(queueAction.retryDetails.actionSucceeded) {
            this.queue$.next(queueAction.action);
          }
        }
      } catch (error) {
        this.logger.error('Error while trying to remove an executed element from the queue: ', queueAction, error);
        return false;
      }
    return true;
  }

  checkForFaultyEvent(error: any): boolean {
    let errorCode: string =  error?.cause?.error?.errorCode;
    if (errorCode && errorCode in FaultyEventErrorCode) {
      return true;
    }
    return false;
  }

  checkForQueueActionRetryCondition(queueAction: QueueAction, error: any): QueueAction {
    if (error instanceof NoInternetConnectionError || ('message' in error && error.message == "NoInternetConnectionError")) {
      // We have found that uploading an element ran into an error due to a bad internet connection.
      // Since we have previously checked that there is an internet connection, this happens when the
      // request or response are in transit.
      // Therefore here, we don't let the sync item be removed from the queue and pretend as if the operation
      // failed and try the request again.
      // The idea is that when the internet is back, the action's sync will be attempted again.
      // Note that if the server did receive the request, we may be uploading the same data twice.
      queueAction.retryDetails.shouldRetry = true;
    } if (error instanceof ServerErrorDuplicateItem || ('message' in error && error.message == "ServerErrorDuplicateItem")) {
      // Here it has been determined that the client has attempted to send the same data twice, to the server.
      // In this case, we will silently pretend as if this action succeeded and do not try to make the request again.
      // This will reduce the chances of the client observing repeated errors when retrying requests
      // due to a bad network.
      queueAction.retryDetails.shouldRetry = false;
      this.throwErrorToSentry(queueAction.action, error);
    } else if (error instanceof ServerErrorSameAssetName || 'message' in error && error.message == "ServerErrorSameAssetName") {
      // Here it has been determined that the client has attempted to send the asset with name same as any previous assets, to the server.
      // We'll show a warning not to use same name when creating assets or updating.
      queueAction.retryDetails.shouldRetry = false;
      this.throwErrorToSentry(queueAction.action, error);
    } else if (error instanceof ServerErrorEntityBeingUsed || 'message' in error && error.message == "ServerErrorEntityBeingUsed") {
      queueAction.retryDetails.shouldRetry = false;
    } else {
      try {
        // Remove image data from the error
        if(error.data.type === 'Attachment') {
          error.data.payload.data = "";
        }
      } catch(e) {}
      this.logger.error('An action has failed, the queue execution will continue. ', false, error);
      queueAction.retryDetails.shouldRetry = true;
    }
    return queueAction;
  }

  private async moveItemToErrorQueue(dbInstance: UserDatabase, queueAction: QueueAction, errorCode?: string) {
    queueAction.retryDetails.actionFailed = true;
    const errorQueueItem = this.createErrorQueueItem(queueAction, errorCode);
    await dbInstance.errorItemsQueue.put(errorQueueItem);
    this.throwFaultyItemToSentry(queueAction.action);
    await this.deleteItemFromDB(queueAction.action);
    this.postStatusService.communicatePostSyncStatus(PostStatusStates.error);
  }

  /**
   * Returns the executed elements
   */
  public watchQueue(): Observable<SyncDto> {
    Logger.queue.debug('Execute queue for sync');
    return this.queue$.asObservable();
  }

  public waitForSiteCreation(spaceId: string, siteId: string): Promise<void> {
    return this.queue$.pipe(
      startWith(null), // Try instantly to find a site creation when this function is first called
      switchMap(() => // Looking in the queue for a site creation SyncDto
        this.databaseService.getUserDB().then(
          db => db.queue.where({spaceId: spaceId})
                                  .filter(item => item.action.action === ActionType.CREATE
                                                  && item.action.payload
                                                  && item.action.payload.id === siteId)
                                  .count()
                                  .then(count => count > 0)
        )
      ),
      filter(hasSiteCreation => hasSiteCreation === false), // We only notify the client if there is no site creations
      map(() => undefined), // We want to return a Promise<void>
      first() // Fix bug where the client is not notified by the toPromise since our observable doe not complete
    ).toPromise();
  }

  /**
  * returns true if the queue execution should continue
  * @param spaceId the space in which the action will be executed
  * @param action the SyncDto to execute
  */
  private executeAction(spaceId: string, action: SyncDto, retryDetails: RetryDetails): Promise<boolean> {
    if(action.type !== 'Attachment') {
      this.logger.info('Executing action: ', action);
    } else {
      this.logger.info('Executing attachment action: [action, eventId, attachmentId]', action.action, action.payload.eventId, action.payload.id);
    }
    try {
      switch (action.type) {
        case 'Attachment':
          return this.uploadImageAction(spaceId, action.siteId, action);
        default:
          return this.postSyncDtoAction(spaceId, action.siteId, action, retryDetails);
      }
    } catch (error) {
      this.logger.error('Unexpected error while executing an action: ', error);
      return Promise.reject(new Error('Unexpected error while executing an action: ' + error));
    }
  }

  /**
 * Method to POST a sync action to backend
 * @param spaceId Concerned space id
 * @param siteId Concerned site id
 * @param syncDto the SyncDto to execute
 */
  private postSyncDtoAction(spaceId: string, siteId: string, syncDto: SyncDto, retryDetails: RetryDetails): Promise<boolean> {
    this.postStatusService.communicatePostSyncStatus(PostStatusStates.inProgress, siteId);
    let params = null;
    if(siteId) {
      params = new HttpParams()
      .set('version', SITE_SYNC_VERSION);
    }
    const url = siteId
      ? this.tenantUrl + '/' + spaceId + '/sites/' + siteId + '/sync/queue'
      : this.tenantUrl + '/' + spaceId + '/sync/queue';
    return this.https.post(url, syncDto, {observe: 'response', params: params}).pipe(
      map((foo) => {console.log(foo);  return true;}),
      catchError(error => {
        if (error.status === HttpStatus.UNAUTHORIZED) {
          // We stop the queue if there's a 401
          this.logger.error("Queue execution stopped due to a 401 error");
          return of(false);
        } else if (error.status === HttpStatus.NOT_FOUND && syncDto.action === 'DELETE') {
          /*
          Exceptions for errors that do not have an impact on consistency between frontend and backend data
          cases : 404 on a delete action */
          // We should continue as if action succeeded
          return of(true);
        } else if (error.status === HttpStatus.FORBIDDEN && syncDto.action === 'CREATE' && error.error.error === 'access_denied') {
          //User tries to make a creation he is not allowed to : exple duplicate task, create linked event from a task, but he's not allowed to create task/event
          //TODO syncDto.payload.title works for event/task, to be replaced with getLabel as soon as we merge the work on Labelable
          const itemLabel = syncDto && syncDto.payload && syncDto.payload.title ? syncDto.payload.title : '';
          this.toasterService.showErrorToaster('sync.permission.error', {itemLabel : itemLabel});
          this.logger.error("User tried to create an entry he is not autorized to do. [syncDTO]:",syncDto);
          //we remove/delete the task/event from local database ()created without permission, is on the user local database)
          this.databaseService.getDBInstant().then(db => db._delete(syncDto.payload, syncDto.type, false));
          // We don't flag database as corrupted and continue the queue
          return of(true);
        } else if (
          error.status === HttpStatus.BAD_REQUEST && syncDto.type === 'Event' &&
          error.error.message === ('TotalQuantityDone cannot be greater than PLannedQuantity for a task. TaskId: ' + syncDto.payload.task.taskId)
          ) {
          //User has tried to report progress on a task leading to task progress becoming greater than 100%.
          //Backend doesn't allow this and throws a 400 error.
          //This can happen if one user tries to report offline while another online user has already reported the progress on the same task.
          //Since the offline user is not working with the latest data the frontend may allow the request to go through.
          //In this case we notify the user that the event is deleted and move on with the queue.
          const itemLabel = syncDto && syncDto.payload && syncDto.payload.title ? syncDto.payload.title : '';
          this.toasterService.showWarningToaster('sync.task.reporting_progress.error', {itemLabel : itemLabel});
          //We delete the event from local database
          this.databaseService.getDBInstant().then(db => db._delete(syncDto.payload, syncDto.type, false));
          // We don't flag database as corrupted and continue the queue
          return of(true);
        } else if (syncDto.type === 'Event' && error.status === HttpStatus.FORBIDDEN && (syncDto.action === 'UPDATE' || syncDto.action === 'DELETE' )) {
          const syncException = new SyncException(SyncExceptionType.APPROVED_EVENT_MUTATION, error);
          QueueService.syncException$.next(syncException);
          return of(true);
        } else if(error.status == 0 && !NetworkStatus.isOnline) {
          throw new NoInternetConnectionError();
        } else if (this.isDuplicateItemError(error)){
          this.checkAndThrowPostErrorToSentry(syncDto, retryDetails, error);
          this.logger.error('The server returned an error for a duplicate item with same identifier', false);
          throw new ServerErrorDuplicateItem();
        } else if (this.isSameAssetNameError(error,syncDto) && syncDto.action === 'CREATE') {
          // delete the asset from local database
          this.databaseService.getDBInstant().then((db) => {
            db._delete(syncDto.payload, syncDto.type, false).then(() => {
            // communicate the response of post call for asset creation
            this.postStatusService.communicateAssetPostStatus(PostStatusStates.error, syncDto.payload.id);
            });
          });
          this.toasterService.showWarningToaster('validation_rules.create.with.same.asset.name');
          this.checkAndThrowPostErrorToSentry(syncDto, retryDetails, error);
          this.logger.error('The server returned an error for creating an asset item with same name', false);
          throw new ServerErrorSameAssetName();
        } else if (this.isSameAssetNameError(error,syncDto) && syncDto.action === 'UPDATE') {
          // delete the asset from local database
          // TODO: need to set up method to force user to change name of the asset.
          this.databaseService.getDBInstant().then((db) => {
            db._delete(syncDto.payload, syncDto.type, false).then(() => {
            // communicate the response of post call for asset updation
            this.postStatusService.communicateAssetPostStatus(PostStatusStates.error, syncDto.payload.id);
            });
          });
          this.toasterService.showWarningToaster('validation_rules.update.with.same.asset.name');
          this.checkAndThrowPostErrorToSentry(syncDto, retryDetails, error);
          this.logger.error('The server returned an error for updating an asset item with same name', false);
          throw new ServerErrorSameAssetName();
        } else if (this.isEntityBeingUsedError(error, syncDto) && syncDto.action === 'DELETE') {
          this.toasterService.showWarningToaster("entity.being.used.warning", {item: syncDto.payload.name});
          this.addEntityBackToDB(syncDto);
          throw new ServerErrorEntityBeingUsed();
        } else if (this.checkForApprovedEventUpdation(syncDto, error)) {
          // The client has tried to update an approved event which is not allowed
          // This can happen due to the client retrying due to network issues
          // In this case instead of it being an treated as an error, silently accept it and update
          // with the event object sent by the server
          const syncException = new SyncException(SyncExceptionType.APPROVED_EVENT_MUTATION, error);
          QueueService.syncException$.next(syncException);
          return of(true);
        } else {
          this.checkAndThrowPostErrorToSentry(syncDto, retryDetails, error);
          this.logger.error('The server returned an error in response to an action.', false, error, syncDto);
          throw new ServerError('The server returned an error in response to an action.', error, syncDto);
        }
      })).toPromise();
  }

  private uploadImageAction(spaceId: string, siteId: string, syncDto: SyncDto): Promise<boolean> {
    return this.fileTransferService.uploadPicture(
      syncDto.payload.data,
      `${this.tenantUrl}/${spaceId}/sites/${siteId}/events/${syncDto.payload.eventId}/pictures/${syncDto.payload.id}`).pipe(
        map(() => true),
        catchError(error => {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            this.logger.error('The queue execution is stopped due to a 401 on an image upload.', error);
            // We stop the queue if there's a 401
            return of(false);
          } else if (error.status === HttpStatus.NOT_FOUND && syncDto.action === 'DELETE') {
            return of(true);
          } else if(error.status == 0 && !NetworkStatus.isOnline) {
            throw new NoInternetConnectionError();
          } else if (this.isDuplicateItemError(error)){
            this.logger.error('The server returned an error for a duplicate item with same identifier', false);
            throw new ServerErrorDuplicateItem();
          } else {
            this.logger.error('The server returned an error in response to an image upload.', false, error, syncDto.payload.id, syncDto.payload.eventId);
            throw new ServerError('The server returned an error in response to an image upload.', error, syncDto);
          }
        })).toPromise();
  }

  private isDuplicateItemError(error: any): boolean {
    const duplicateValueServerMessage = "A different object with the same identifier value was already associated with the session";
    if ("error" in error && "message" in error.error) {
      if (error.error.message.includes(duplicateValueServerMessage)) {
        return true;
      }
    }
    return false;
  }

  private checkAndThrowPostErrorToSentry(syncDTO: SyncDto, retryDetails: RetryDetails, error: any): void {
    if(retryDetails.retryAttempt === MAX_AMOUNT_OF_RETRIES) {
      this.throwErrorToSentry(syncDTO, error);
    }
  }

  // sends error message to sentry
  private throwErrorToSentry(syncDto: SyncDto, error: any): void {
    try {
      Sentry.withScope((scope) => {
        scope.setExtras({'syncDto.type': syncDto.type, 'syncDto': JSON.stringify(syncDto)});
        let message: string = error.error.message + syncDto.payload.id;
        Sentry.captureMessage(message, scope => {
          return scope.setContext('error', error.error);
        });
      });
    } catch(unhandledError) {
      Sentry.withScope((scope) => {
        scope.setExtras({'syncDto.type': syncDto.type, 'syncDto': JSON.stringify(syncDto)});
        let message: string = error;
        Sentry.captureMessage(message, scope => {
          return scope.setContext('error', error);
        });
      });
    }
  }

  // send faulty items to sentry
  private throwFaultyItemToSentry(syncDto: SyncDto): void {
    let message: string = "Faulty Items - " + this.sessionService.getCurrentUser().id + " : " + syncDto.payload.id;
    Sentry.withScope((scope) => {
      // set the fingerprint explicitely so that sentry does not group these faulty items by default
      scope.setFingerprint([message]);
      scope.setExtras({'syncDto.type': syncDto.type, 'syncDto': JSON.stringify(syncDto)});
      Sentry.captureMessage(message);
    });
  }

  // send warning items to sentry
  private throwWarningItemToSentry(syncDto: SyncDto): void {
    let message: string = "Warning Items - " + this.sessionService.getCurrentUser().id + " : " + syncDto.payload.id;
    Sentry.withScope((scope) => {
      // set the fingerprint explicitely so that sentry does not group these warning items by default
      scope.setFingerprint([message]);
      scope.setExtras({'syncDto.type': syncDto.type, 'syncDto': JSON.stringify(syncDto)});
      Sentry.captureMessage(message);
    });
  }

  private isSameAssetNameError(error, syncDto): boolean {
    if (error.status === 409 && (this.isOfAssetOrInformationType(syncDto.type))) {
      return true;
    }
    return false;
  }

  private isOfAssetOrInformationType(type: string): boolean {
    return (type === 'Asset') || (type === 'Tag') || (type === 'Contractor') || (type === 'Location');
  }

  private async deleteItemFromDB(syncDTO: SyncDto): Promise<void> {
    const db = await this.databaseService.getDBInstant();
    return db._delete(syncDTO.payload, syncDTO.type, false);
  }

  private createErrorQueueItem(queueAction: QueueAction, errorCode?: string): ErrorQueueAction {
    return new ErrorQueueAction(
      queueAction.action.payload.id,
      queueAction.spaceId,
      queueAction.action.siteId,
      queueAction.action.payload,
      queueAction.action.type,
      queueAction.action.action,
      errorCode,
    );
  }

  private getRetryTimeout(attempt: number): number {
    switch (attempt) {
      case 0:
        return 0;
      case 1:
        return FIRST_RETRY_ATTEMPT_IN_MS;
      case 2:
        return SECOND_RETRY_ATTEMPT_IN_MS;
      case 3:
        return THIRD_RETRY_ATTEMPT_IN_MS;
      default:
        return 0;
    }
  }

  private checkForApprovedEventUpdation(syncDto: SyncDto, error: any): boolean {
    if(syncDto.type === 'EventV4' && (syncDto.action === 'UPDATE') && error.status === HttpStatus.FORBIDDEN && error.error.status === 'Approved') {
      return true;
    }
    return false
  }

  private isEntityBeingUsedError(error, syncDto: SyncDto): boolean {
    if (error.status === 403 && error.error.message === SERVER_ERROR_MESSAGES.ENTITY_BEING_USED_ERROR && (this.isOfAssetOrInformationType(syncDto.type))) {
      return true;
    }
    return false;
  }

  // add emtity back to db if server throws entity being used error
  private addEntityBackToDB(syncDto: SyncDto): void {
    switch (syncDto.type) {
      case 'Asset':
        let asset = new Asset();
        Asset.toModel(syncDto, asset);
        this.addItemToDB(asset, syncDto);
        break;
      case 'Tag':
        let tag = new Tag();
        Tag.toModel(syncDto, tag);
        this.addItemToDB(tag, syncDto);
        break;
      case 'Contractor':
        let contractor = new Contractor();
        Contractor.toModel(syncDto, contractor);
        this.addItemToDB(contractor, syncDto);
        break;
      case 'Location':
        let location = new Location();
        Location.toModel(syncDto, location);
        this.addItemToDB(location, syncDto);
        break;
    }
  }

  addItemToDB(item: ModelElement, syncDto: SyncDto): void {
    this.databaseService.getDBInstant().then((db) => {
      db._add(item, syncDto.type, false).then(() => {
        if (this.isOfAssetType(syncDto.type)) {
          this.postStatusService.communicateAssetPostStatus(PostStatusStates.error, syncDto.payload.id);
        }
        else if (this.isOfInformationType(syncDto.type)) {
          this.postStatusService.communicateInformationPostStatus(PostStatusStates.error, syncDto.payload.id);
        }
      });
    });
  }

  private isOfInformationType(type: string): boolean {
    return (type === 'Tag') || (type === 'Contractor') || (type === 'Location');
  }

  private isOfAssetType(type: string): boolean {
    return (type === 'Asset');
  }
}
