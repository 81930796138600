import { SubscriptionState } from './subscription-state';
import { SubscriptionStatus } from './subscription-status';

export enum SubscriptionType {
  FREE_VERSION,
  TRAILING,
  SUBSCRIPTION,
  FREE_VERSION_POST_SUBSCRIPTION,
  UNKNOWN
}

export class SubscriptionTypeHelper {

  public static getSubscriptionStateBasedOnStatus(status: SubscriptionStatus, hasInvoices: boolean): SubscriptionType {
    switch (status.status as SubscriptionState) {
      case SubscriptionState.ACTIVE:
        return SubscriptionType.SUBSCRIPTION;
      case SubscriptionState.CANCELED:
        return hasInvoices ? SubscriptionType.FREE_VERSION_POST_SUBSCRIPTION : SubscriptionType.FREE_VERSION;
      case SubscriptionState.INCOMPLETE:
        return SubscriptionType.SUBSCRIPTION;
      case SubscriptionState.INCOMPLETE_EXPIRED:
        if (status.isExtendedTrialFromBO) {
          return SubscriptionType.SUBSCRIPTION;
        } else {
          return hasInvoices ? SubscriptionType.FREE_VERSION_POST_SUBSCRIPTION : SubscriptionType.FREE_VERSION;
        }
      case SubscriptionState.PAST_DUE:
        return SubscriptionType.SUBSCRIPTION;
      case SubscriptionState.TRIALING:
        return SubscriptionType.TRAILING;
      case SubscriptionState.UNPAID:
        if (status.isExtendedTrialFromBO) {
          return SubscriptionType.SUBSCRIPTION;
        } else {
          return SubscriptionType.FREE_VERSION_POST_SUBSCRIPTION;
        }
      case SubscriptionState.UNSUBSCRIBED:
        if (hasInvoices) {
          return SubscriptionType.FREE_VERSION_POST_SUBSCRIPTION;
        } else {
          return SubscriptionType.FREE_VERSION;
        }
      case SubscriptionState.UNKNOWN:
        return SubscriptionType.UNKNOWN;
      default:
        return SubscriptionType.UNKNOWN;
    }
  }
}
