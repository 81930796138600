import { ModelElement } from './model-element';

export enum SpaceRoles {
  User,
  Manager,
  Admin,
}

export interface UserDetails {
  SpaceID?: string,
  SpaceName?: string,
  username?: string,
  SiteID?: string,
  SiteName?: string,
  email?: string,
  userId?: string
}

export enum SiteRoles {
  TeamMember,
  Supervisor,
  Viewer,
}

export class User implements ModelElement {
  id: string;
  mail: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  isPhoneNumberVerified?: boolean;
  isUser2FAEnabled?: boolean;
  is2FAStrictModeEnabled?: boolean;
  fullName?: string;
  isWebAdminOnboardingDone: boolean;
  isWebSiteDiaryOnboardingDone: boolean;
  isWebSiteTaskOnboardingDone: boolean;
  isMobileSiteDiaryOnboardingDone: boolean;

  constructor(
    id: string = ModelElement.generateId(),
    mail: string = '',
    lastname: string = '',
    firstname: string = '',
    phoneNumber: string = '',
    isPhoneNumberVerified: boolean = false,
    isUser2FAEnabled: boolean = false,
    is2FAStrictModeEnabled: boolean = false,
    fullName: string = '',
    isWebAdminOnboardingDone: boolean = false,
    isWebSiteDiaryOnboardingDone: boolean = false,
    isWebSiteTaskOnboardingDone: boolean = false,
    isMobileSiteDiaryOnboardingDone: boolean = false
  ) {
    this.id = id;
    this.mail = mail;
    this.lastName = lastname;
    this.firstName = firstname;
    this.phoneNumber = phoneNumber;
    this.isPhoneNumberVerified = isPhoneNumberVerified;
    this.isUser2FAEnabled = isUser2FAEnabled;
    this.is2FAStrictModeEnabled = is2FAStrictModeEnabled;
    this.fullName = fullName;
    this.isWebAdminOnboardingDone = isWebAdminOnboardingDone;
    this.isWebSiteDiaryOnboardingDone = isWebSiteDiaryOnboardingDone;
    this.isWebSiteTaskOnboardingDone = isWebSiteTaskOnboardingDone;
    this.isMobileSiteDiaryOnboardingDone = isMobileSiteDiaryOnboardingDone;
  }

  /**
   * Copy given {@link User} into destination {@link User}
   * @param source given source user
   * @param destination given destination user
   */
  public static copy(source: User, destination: User): void {
    destination.id = source.id;
    destination.mail = source.mail;
    destination.lastName = source.lastName;
    destination.firstName = source.firstName;
    destination.phoneNumber = source.phoneNumber;
  }

  /**
   * Extract {@link User} from json
   * @param json given json
   * @param user given user
   */
  public static toModel(json: any, user: User) {
    user.id = json.payload.id;
    user.lastName = json.payload.lastName;
    user.firstName = json.payload.firstName;
    user.phoneNumber = json.payload.phoneNumber;
  }

  /**
   * Sort {@link User} by name
   * @param user1 first user to compare
   * @param user2 second user to compare
   */
  public static compareByLastName(user1, user2) {
    if (user1.lastName == null || user2.lastName == null) {
      return 0;
    }
    return user1.lastName.localeCompare(user2.lastName);
  }

  public getLabel():string {
    return this.firstName + ' ' + this.lastName;
  }

  /** toDto method by inheritance not implemented here */
  public toDTO() {
    throw new Error('not implemented');
  }

  public get label(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
