import { ModelElement } from './model-element';
import { Identifiable } from './identifiable';

export class Attachment implements Identifiable {
  id: string;
  url: string | ArrayBuffer

  constructor(
    id: string = ModelElement.generateId(),
    url?: string | ArrayBuffer
  ) {
    this.id = id;
    this.url = url;
  }
}
