import { UrlGiverService } from './url-giver.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { map, flatMap, catchError, mergeMap } from 'rxjs/operators';
import { Space } from '../models/space';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { DatabaseService } from './shared/database.service';
import { Logger } from './logger';
import { UserRightsDAO } from './user-rights/user-rights-dao.service';
import { Picture } from '../models/picture';
import { NetworkStatus } from "@models/synchronization/network-status";
import { UserAppAccessDAO } from './user-app-access/user-app-access-dao.service';
import { DeviceService } from './device.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {
  spaceUrl: string;
  headers: HttpHeaders;

  constructor(
    private urlGiverService: UrlGiverService,
    private http: HttpClient,
    private sessionService: SessionService,
    private databaseService: DatabaseService,
    private userRightsDAO: UserRightsDAO,
    private userAppAccessDAO: UserAppAccessDAO,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService
  ) {
    this.spaceUrl = environment.apiUrl + '/spaces';
    this.headers = this.urlGiverService.httpOptions.headers;
  }

  /**
   * Get spaces from indexDB
   */
  getSpaces(): Observable<Space[]> {
    return this.spacesSyncRequest().pipe(
      catchError(async err => {
        // show local data
        let spaces: Space[];
        if (this.deviceService.isMobile) {
          const userDB = await this.databaseService.getUserDB();
          spaces = await userDB.spaces.toArray();
        }
        else {
          spaces = this.sharedDataService.getSpaces();
        }
        return spaces;
      })
    );
  }


  private getBackendSpaces(): Observable<Space[]> {
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        flatMap(() => this.http.get<(Space & { thumbnailLogo?: string })[]>(environment.apiUrl + '/spaces')),
        map((response) => response.map(jsonSpace => {
          let space = new Space()
          Space.toModel(jsonSpace, space);
          this.userRightsDAO.addSpaceRole(space.id, (<any>jsonSpace).role);
          
          this.userAppAccessDAO.addAppAccess(space.id, (<any>jsonSpace).siteDiaryAccess, (<any>jsonSpace).siteTaskAccess);
          return space;
        }))
      );
  }

  private getBackendMinimalSpaces(): Observable<Space[]> {
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        mergeMap(() => this.http.get<(Space)[]>(this.urlGiverService.giveSpacesMinimalAPIUrl())),
        map((response) => response.map(jsonSpace => {
          const space = Object.assign(new Space(), jsonSpace);
          return space;
        }))
      );
  }

  private spacesSyncRequest(): Observable<Space[]> {
    Logger.synchronization.info('Syncing spaces list...');
    if (this.deviceService.isMobile) {
      return this.getBackendSpaces().pipe(
        flatMap(async spaces => {
          // Clear and re fill database with updated data from backend
          let formatSpaces = [];
          const userDB = await this.databaseService.getUserDB();
          formatSpaces = await userDB.transaction('rw', userDB.spaces, async () => {
            await userDB.spaces.clear();
            await userDB.spaces.bulkPut(spaces);
            Logger.synchronization.info('Spaces list synced.', spaces);
            return spaces;
          });
          return formatSpaces;
        }));
    }
    else {
      return this.getBackendMinimalSpaces().pipe(
        mergeMap(async spaces => {
          spaces = Space.sortByName(spaces);
          return spaces;
        }));
    }
  }

  /**
   * Get space by id from indexDB
   * @param id
   */
  getSpace(id): Promise<Space> {
    if(this.deviceService.isMobile) {
      return this.databaseService.getUserDB().then(db => db.spaces.get(id));
    }
    else {
      return this.sharedDataService.getSpaceById(id);
    }
  }

  getBackendSpace(id): Observable<Space> {
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        flatMap(() => this.http.get(this.spaceUrl + '/' + id)),
        map(spaceDto => {
          const space = new Space();
          Space.toModel(spaceDto, space);
          return space;
        }),
      );
  }

  /**
   * Create a space using POST method
   * @param newSpace Space to create
   */
  createSpace(newSpace: Space): Observable<Space> {
    let spaceDto = newSpace.toDTO();
    return this.http.post<Space>(
      this.spaceUrl,
      spaceDto,
      { headers: this.headers },
    );

  }

  /**
   * Edit a space using PUT method
   * @param editSpace Space to edit
   */
  updateSpace(editedSpace: Space): Observable<any> {
    let spaceDto = editedSpace.toDTO();
    const req = new HttpRequest('PUT', this.spaceUrl + '/' + editedSpace.id, JSON.stringify(editedSpace), {
      headers: this.headers
    });
    if (this.deviceService.isMobile) {
      this.updateSpaceInIndexedDB(editedSpace);
    }
    return this.http.put<Space>(
      this.spaceUrl + '/' + editedSpace.id,
      spaceDto,
      { headers: this.headers },
    );
  }

  /**
   * Delete space using DELETE method
   * @param deletedSpace Space to delete
   */
  deleteSpace(deletedSpace: Space): Observable<any> {
    this.deleteSpaceFromIndexedDB(deletedSpace);
    return this.http.delete(this.spaceUrl + '/' + deletedSpace.id, { 'headers': this.headers });
  }

  /**
   * Add space in localStorage userDB
   * @param space Space to save
   */
  public addSpaceToIndexedDB(space: Space): void {

    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw?', userDB.spaces, async () => {
        await userDB.spaces.put(space);
      });
    });
  }

  /**
   * Update space in localStorage userDB
   * @param space Space to update
   */
  public updateSpaceInIndexedDB(space: Space): void {

    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw', userDB.spaces, async () => {
        await userDB.spaces.put(space);
      });
    });
  }

  /**
   * Add space in localStorage userDB
   * @param space Space to update
   */
  public deleteSpaceFromIndexedDB(space: Space): void {

    this.databaseService.getUserDB().then(userDB => {
      userDB.transaction('rw', userDB.spaces, async () => {
        await userDB.spaces.delete(space.id);
      });
    });
  }
}

