import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal,NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { DeviceService } from '@services/device.service'
import { Space } from '@models/space';
import { SharedService } from '@services/shared/shared.service';
import { UserRightsService } from '@services/user-rights/user-rights.service'
import { MenuService } from '@services/menu.service';
import { SharedDataService } from '@services/shared-data.service';
import { AppTypeService } from '@services/app-type.service';

@Component({
  selector: 'upgrade-popup',
  templateUrl: './upgrade-popup.component.html',
  styleUrls: ['./upgrade-popup.component.scss']
})
export class UpgradePopupComponent implements OnInit {

  //label code sent by caller to customize the title and subtitle messages
  public featureLabelCode: string;
  subtitle : string;

  //to display subscription link when current user is space administrator
  spaceAdmin : boolean;

  currentSpaceId: string;
  currentSiteId: string;

  constructor(
    public activeModal: NgbActiveModal,
    public deviceService: DeviceService,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private userRightsService : UserRightsService,
    private menuService: MenuService,
    private sharedDataService: SharedDataService,
    public appTypeService: AppTypeService,
  ) {
    if(deviceService.isMobile) {
      this.sharedService.watchSpaceId.subscribe((spaceId) => {
        this.currentSpaceId = spaceId;
      });
      this.sharedService.watchSiteId.subscribe((siteId) => {
        this.currentSiteId = siteId;
      });
    } else {
      this.sharedDataService.watchSpaceId.subscribe((spaceId) => {
        this.currentSpaceId = spaceId;
      });
      this.sharedDataService.watchSiteId.subscribe((siteId) => {
        this.currentSiteId = siteId;
      });
    }
   }

  ngOnInit() {
    //TODO We could create an enriched translate pipe to only display it if there is a translation and not use susbscribe
    //translate subtitle if label exists, otherwise subtitle should be empty
    let subtitleCode = this.featureLabelCode + '.subtitle';
    this.translateService.get(subtitleCode).subscribe(
     _translate => {
        if(_translate !== subtitleCode) {
          this.subtitle = _translate;
        }
     }
    );

    this.spaceAdmin = this.userRightsService.hasRight(this.userRightsService.USER_RIGHTS.space.users.manage);
  }

  dismiss() {
    this.activeModal.close('dismiss');
  }

  //link to subscription page for space administrator
  goToSpaceSubscription(): void {
   this.dismiss();
   this.router.navigate(['/space',this.currentSpaceId , 'users', 'subscription']).then((isSuccessfullyNavigated) => {
    if(isSuccessfullyNavigated) {
      if(!this.deviceService.isMobile) {
        this.sharedDataService.resetCurrentSite();
      }
      this.sharedService.setCurrentSiteById(null);
      this.menuService.generateSpaceDetails(this.currentSpaceId);
    }
   });
  }
}
