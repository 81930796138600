import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-events-form-mobile-toolbar',
  templateUrl: './events-form-mobile-toolbar.component.html',
  styleUrls: ['./events-form-mobile-toolbar-component.scss'],
})

export class EventsFormMobileToolbarComponent {
  @Input() doneLabel: string;
  @Input() disableDoneButton: boolean = false;
  @Input() title: string;
  @Input() backIcon: string = 'arrow-back';

  @Output() back = new EventEmitter<void>();
  @Output() saveDraft = new EventEmitter<void>();
  @Output() saveSubmit = new EventEmitter<void>();

}
