import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
	selector: 'app-custom-form-numerical-scale-field',
	templateUrl: './custom-form-numerical-scale-field.component.html',
	styleUrls: ['./custom-form-numerical-scale-field.component.scss']
})
export class CustomFormNumericalScaleFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  rangeOfScale: number[] = [];
  lowerRange: number = 0;
  lowerRangeLabel: string;
  upperRange: number = 10;
  upperRangeLabel: string;

  constructor() {}

  ngOnInit() {
    if (this.customField.children.lowerRange) {
      this.lowerRange = this.customField.children.lowerRange;
    }
    if (this.customField.children.upperRange) {
      this.upperRange = this.customField.children.upperRange;
    }
    for (let i = this.lowerRange; i <= this.upperRange; i++) {
      this.rangeOfScale.push(i);
    }
  }

  lowerRangeLabelExists(): boolean {
    if (this.customField.children.lowerRangeLabel) {
      this.lowerRangeLabel = this.customField.children.lowerRangeLabel;
      return true;
    }
    return false;
  }

  upperRangeLabelExists(): boolean {
    if (this.customField.children.upperRangeLabel) {
      this.upperRangeLabel = this.customField.children.upperRangeLabel;
      return true;
    }
    return false;
  }
}   
