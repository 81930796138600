import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncDto } from '@models/synchronization/sync-dto';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';

@Injectable()
export class UrlGiverService {
  static readonly ASSETS_URL: string = `${environment.path}assets/`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private readonly webappUrl: string = environment.webappUrl;
  private readonly apiUrl: string = environment.apiUrl;
  private readonly assetsUrl: string = `${environment.path}assets/`;

  constructor(
    private translateService: TranslateService,
  ) { }

  giveEventUrl(spaceId: string, siteId: string, eventId: string): string {
    return this.webappUrl + '/space/' + spaceId + '/site/' + siteId + '/events/' + eventId;
  }

  giveAPIUrl(): string {
    return this.apiUrl;
  }

  giveAssetsUrl(): string {
    return this.assetsUrl;
  }

  giveSupportUrl(): string {
    return this.translateService.instant('website.support') as string;
  }

  giveAdministrationUrl(spaceId: string): string {
    return '/space/' + spaceId;
  }

  /**
   * Helper method to get the first part (until the current site id) of the URL used for import API calls
   */
  giveImportAPIUrl(spaceId: string, siteId: string): string {
    return `${this.giveAPIUrl()}/tenant/${spaceId}/sites/${siteId}`;
  }

  giveResourceOrInformationImportAPIUrl(spaceId: string): string {
    return `${this.giveTenantAPIUrl()}/${spaceId}/import`;
  }

  giveDownloadTemplateFileUrl(): string {
    return `${this.giveAPIUrl()}/resources/excelDemo`;
  }

  giveDownloadQuantityUnitsUrl(): string {
    return `${this.giveAPIUrl()}/resources/quantity-units`;
  }

  giveSupportedDateFormatsUrl(): string {
    return `${this.giveAPIUrl()}/resources/supported-date-formats`;
  }

  giveTenantAPIUrl(): string {
    return this.apiUrl + '/tenant';
  }

  giveCustomFormAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/forms';
  }

  giveSitesListSyncRequestUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/sites';
  }

  giveEventSiteCardCountsUrl(spaceId: string): string {
    return this.giveSpacesAPIUrl() + `/${spaceId}` + '/event-counts';
  }

  giveTaskSiteCardCountsUrl(spaceId: string): string {
    return this.giveSpacesAPIUrl() + `/${spaceId}` +  '/task-counts';
  }

  giveLatestSubsetSyncAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/sync/latestSubset';
  }

  giveTaskLatestSubsetSyncAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/sync/tasksLatestSubset';
  }

  giveSpacesAPIUrl(): string {
    return this.giveAPIUrl() + '/spaces';
  }

  giveSpaceAssetsAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/resources';
  }

  giveSpaceInformationAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/information';
  }

  giveUserInviteUrl() {
    return this.giveAPIUrl() + '/invite';
  }

  giveVersionCheckAPIUrl(): string {
    return this.giveAPIUrl() + '/version';
  }

  giveEventSearchAndFilterAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/events/filter';
  }

  giveTaskFilterAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/tasks/filter';
  }

  giveTaskTimelineFilterAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/tasks/timelineFilter';
  }

  giveFilterDataForTasks(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/tasks/minimal';
  }

  giveFilterDataForEventCreators(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/events/creators';
  }

  giveFilterDataForTaskCreators(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/tasks/creators';
  }

  giveSpaceSyncQueueURL(spaceId) {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/queue';
  }

  giveSiteSyncQueueURL(spaceId, siteId) {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sites' + `/${siteId}` + '/sync/queue';
  }

  giveSingleEventByIdAPI(spaceId: string, siteId: string, eventId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}/events/event/${eventId}`;
  }

  giveSingleTaskByIdAPI(spaceId: string, siteId: string, taskId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}/tasks/task/${taskId}`;
  }

  giveAppAccessAPIUrl(spaceId: string): string {
    return this.giveSpacesAPIUrl() + `/${spaceId}/app-access`;
  }

  giveSpaceMinimalAPIUrl(spaceId: string): string {
    return this.giveSpacesAPIUrl() + `/${spaceId}/minimal`;
  }

  giveSpacesMinimalAPIUrl(): string {
    return this.giveSpacesAPIUrl() + '/minimal';
  }

  giveImageUploadAPIUrl(spaceId: string, siteId: string, syncDto: SyncDto): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}/events/${syncDto.payload.eventId}/pictures/${syncDto.payload.id}`;
  }

  giveSpaceSyncAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + '/' + spaceId + '/sync';
  }

  giveSiteSyncAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + '/' + spaceId + '/sites/' + siteId + '/sync';
  }

  giveCurrentSiteUserAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + '/' + spaceId + '/sites/' + siteId + '/user';
  }

  giveRequestOtpAPIUrl(): string {
    return this.giveAPIUrl() + '/otp/request-otp';
  }

  giveSubmitOtpAPIUrl(): string {
    return this.giveAPIUrl() + '/otp/submit-otp';
  }

  giveUserDetailsAPIUrl(): string {
    return this.giveAPIUrl() + '/profiles/user-details';
  }

  giveGetOrganisationTypesUrl(): string {
    return this.giveAPIUrl() + '/resources/organisation-types';
  }

  giveGetOrganisationSizesUrl(): string {
    return this.giveAPIUrl() + '/resources/organisation-sizes';
  }

  giveTasksCountAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + `/sites/${siteId}/` + 'sync/tasksCount';
  }

  giveEventsCountAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + `/sites/${siteId}/` + 'sync/eventsCount';
  }

  giveAdminEventsCountAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/space-events-count';
  }

  giveAdminTasksCountAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/space-tasks-count';
  }

  giveAdminResourcesListAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/space-resources';
  }

  giveAdminInformationListAPIUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/sync/space-information';
  }

  giveAdminSiteDiaryTotalResourceUsageUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/total-resource-usage';
  }

  /* Notification APIs region */

  giveNotificationAPIUrl(): string {
    return this.giveAPIUrl() + '/notifications';
  }

  giveNotificationConfigurationAPIUrl(): string {
    return this.giveAPIUrl() + '/notifications/configuration';
  }

  givePushNotificationAPIUrl(): string {
    return this.giveAPIUrl() + '/notifications/push-notification';
  }
  
  giveLocalNotificationConfigurationURL(): string {
    return this.giveAPIUrl() + '/notifications/local-configuration'
  }

  giveUserSubscribedTagsAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/subscribed-tags';
  }

  giveTagSubscriptionAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/subscribe-to-tag';
  }

  giveTagUnSubscriptionAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/unsubscribe-tag';
  }

  /* End notification APIs region */

  /* App type notification logos region */
  giveSiteDiaryNotificationLogo(): string {
    return this.assetsUrl + '/img/logo/casque_sitediary_purple.svg';
  }

  giveSiteDiaryNotificationGreyLogo(): string {
    return this.assetsUrl + '/img/logo/site_diary_logo_greyscale.svg';
  }

  giveSiteTaskNotificationLogo(): string {
    return this.assetsUrl + '/img/logo/site_task_logo.svg';
  }

  giveSiteTaskNotificationGreyLogo(): string {
    return this.assetsUrl + '/img/logo/site_task_logo_greyscale.svg';
  }
  /* End of app type notification logos region */

  /* Dashboard API region */

  giveSiteDiaryEventsCountByDateAndStatusByUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/daily-state-count';
  }

  giveAdminSiteDiaryEventsCountByDateAndStatusByUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/daily-state-count';
  }

  giveAdminSiteTasksCountByDateAndStatusByUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/task-state-count';
  }

  giveSiteTasksCountByDateAndStatusByUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-state-count';
  }

  giveSiteDiaryEventsCreatedByUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/total-created-by';
  }

  giveSiteTasksAssigneesCountAPIUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-assignee-count';
  }

  giveSiteDiaryDashboardCreatedByTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/events-created-by-details';
  }

  giveSiteTaskDashboardAssigneesTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-assignee-count-details';
  }

  giveSiteDiaryDashboardCountByDateAndStatusTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/event-state-count-details';
  }

  giveAdminSiteDiaryDashboardCountByDateAndStatusTableApiUrl(spaceId: string) {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/event-state-count-details';
  }

  giveSiteTaskDashboardCountByDateAndStatusTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-state-count-details';
  }

  giveAdminSiteTaskDashboardCountByDateAndStatusTableApiUrl(spaceId: string) {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/task-state-count-details';
  }

  giveSiteDiaryTotalManhoursUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/total-manhours';
  }

  giveSiteTaskTypesCountApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-type-count';
  }

  giveTotalResourceUsageUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/total-resource-usage';
  }

  giveTaskStatesApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-states-count';
  }

  giveTaskSCurveApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/s-curve-graph';
  }

  giveAdminTaskSCurveApiUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/s-curve-graph';
  }

  giveSiteDashboardTotalResourceUsageTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/resource-usage-details';
  }

  giveAdminSiteDashboardTotalResourceUsageTableApiUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/resource-usage-details';
  }


  giveSiteDiaryDashboardTotalAverageManhoursTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/manhours-details';
  }

  giveSiteDashboardTotalInformationUsageTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/information-usage-details';
  }


  giveAdminSiteDashboardTotalInformationUsageTableApiUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/information-usage-details';
  }

  giveSiteDashboardCostTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/resource-cost-details';
  }

  giveTotalInformationUsageUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/total-information-usage';
  }

  giveAdminTotalInformationUsageUrl(spaceId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}` + '/dashboards/total-information-usage';
  }

  giveTotalResourceCostUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/total-resource-cost';
  }

  giveSiteTaskDashboardTaskStatesDonutGraphTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-states-details';
  }

  giveSiteTaskDashboardTaskTypesGraphTableApiUrl(spaceId: string, siteId: string): string {
    return this.giveTenantAPIUrl() + `/${spaceId}/sites/${siteId}` + '/dashboards/task-type-count-details';
  }
  /* End Dashboard API region */
}
