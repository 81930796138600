import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location } from '@models/location';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss', '../event-legacy-field.component.sass']
})
export class EventLocationComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() locations: Location[];
  @Input() locationsLoaded: boolean;

  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
  }

  onClearLocation() {
    this.parentFormGroup.controls['location'].setValue(null);
  }

}
