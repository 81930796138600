import { Asset } from '@models/asset';
import { Contractor } from '@models/contractor';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { TaskAsset } from '@models/task-asset';

import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

type TypeName = 'asset' | 'contractor' | 'tag';

export function linkedToTask(task: Task, type: 'asset'): OperatorFunction<Asset[], TaskAsset[]>;
export function linkedToTask(task: Task, type: 'contractor'): OperatorFunction<Contractor[], Contractor[]>;
export function linkedToTask(task: Task, type: 'tag'): OperatorFunction<Tag[], Tag[]>;
export function linkedToTask(task: Task, type: TypeName): OperatorFunction<any[], any[]> {
  switch (type) {
    case 'asset':
      return map(items => task.assets
        .filter(taskItem => items.some(item => item.id === taskItem.assetId))
        .map(taskItem => ({...taskItem, name: items.find(item => item.id === taskItem.assetId).name}))
      );
    case 'contractor':
      return map(items => items.filter(item => task.contractors.some(taskItem => item.id === taskItem.contractorId)));
    case 'tag':
      return map(items => items.filter(item => task.tags.some(taskItem => item.id === taskItem.tagId)));
  }
}
