<!-- ion-header should be here in common but we have to extract it out of this component to make the header sticky on ios...-->
<ion-toolbar mode="ios" class="toolbar-fixed" color="white">
  <span slot="start" class="custom-back" (click)="back.emit()">
    <ion-icon color="primary" [name]="backIcon"></ion-icon>
  </span>

  <ion-label color="dark" class="event-title">
    <b>{{ title | translate }}</b>
  </ion-label>
  <ion-buttons slot="end">
    <ion-button class="draft-btn" size="small" [disabled] = "disableDoneButton" (click)="saveDraft.emit()" >
      <ion-chip color="warning">
        <ion-label color="light">
          {{'btn.draft' | translate}}
        </ion-label>
      </ion-chip>
    </ion-button>
    <ion-button class="submit-btn" size="small" [disabled] = "disableDoneButton" (click)="saveSubmit.emit()" >
      <ion-chip color="primary">
        <ion-label color="light">
          {{ 'btn.submit' | translate }}
        </ion-label>
      </ion-chip>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
