<div [ngClass]="deviceIsMobile? 'mobile-container' : 'container'" [formGroup]="parentFormGroup">
  <span [ngClass]="{'mobile-label': deviceIsMobile}" class="label" *ngIf="!isEventPreviewScreen">{{ customField.title }}</span>
  <div [ngClass]="deviceIsMobile? 'mobile-date-picker' : 'date-picker'">
    <i *ngIf="deviceIsMobile" class="fa fa-calendar calender-icon"></i>
      <app-datetime-input
      formControlName="value"
      #startDate
      (click)="startDate.openDatetimePicker()"
      [readonly]="!isEdited"
      [dateFormat]="dateFormat"
      [type]="'startDatetime'"
      [hideTime]="true"
      placeholder="{{'custom_event_form.field_type.date_placeholder' | translate}}">
    </app-datetime-input>
  </div>
</div>
