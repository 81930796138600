<div class="ui-web-datetime-picker" (click)="$event.stopPropagation()">
    <div id="datepicker">
        <!-- START DATE PICKER -->
        <div id="months-years-nav">
            <div id="back" class="arrow" (click)="lastMonth()">
                <em class="fas fa-chevron-left"></em>
            </div>
            <div id="date">{{months[date.getMonth()]}} {{date.getFullYear()}}</div>
            <div id="next" class="arrow" (click)="nextMonth()">
                <em class="fas fa-chevron-right"></em>
            </div>
        </div>
        <div id="days-of-week">
            <div class="day-of-week" *ngFor="let dayOfW of daysOfWeek">{{dayOfW}}</div>
        </div>
        <div id="days">
            <div class="day" *ngFor="let day of calendarArray" (click)="selectDate(day)" [ngClass]="['bg-color' | cssClassToAppSpecificColorCssClass]">
                <span [ngClass]="{
                    'current-day': isCurrentDate(day), 'active':isActiveDate(day)
                  }">
          {{day}}
        </span>
      </div>
    </div>
    <!-- END DATE PICKER -->
    <!-- START TIME PICKER -->
    <div id="timepicker">
      <div class="ui-timepicker" *ngIf="!datetimePickerService.hideTime">
        <div id="description-wrapper">
          <span class="description">{{ 'ui.datetimepicker.time' | translate }}</span>
        </div>
        <div class="select-wrapper hour ">
          <select class="select-box select-css" (change)="selectHours($event.target.value)">
            <option *ngFor="let hour of hours" [selected]=" hour === selectedHours" [value]="hour">
              {{hour}}
            </option>
          </select>
        </div>
        <div class="select-wrapper colon">
          {{'colon.symbol' | translate}}
        </div>
        <div class="select-wrapper minute">
          <select class="select-box select-css" (change)="selectMinutes($event.target.value)">
            <option *ngFor="let min of minutes" [selected]="min <= date.getMinutes()" [value]="min">

              {{min}}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- END TIME PICKER -->
    <div id="actions">
      <div>
        <button *ngIf="datetimePickerService.canClear" class="btn-clear" (click)="clear()">
          {{ 'btn.clear' | translate }}
        </button>
      </div>
      <div class="align-right">
        <ion-button class="secondary-action-button" (click)="cancel()">
          {{ 'btn.cancel' | translate }}
        </ion-button>
        <ion-button class="ml-2 primary-action-button" (click)="saveAndClose()" [ngClass]="['bg-color' | cssClassToAppSpecificColorCssClass]">
          {{ 'Ok' | translate | uppercase }}
        </ion-button>
      </div>
    </div>
  </div>
