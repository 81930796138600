<div *ngIf="isPopup && isOnline" class="custom-form-mobile">
  <div class="custom-form-heading">
    {{ "custom_event_form_mobile.heading" | translate }}
  </div>
  <div class="custom-form-title">
    <div class="section-heading">
      {{ "custom_event_form_mobile.section.form_title" | translate }} {{'wildcard.symbol' | translate}}
    </div>
    <form [formGroup]="formTitleGroup">
      <ion-input
        formControlName="formTitle"
        [placeholder]="'custom_event_form.form_title.placeholder' | translate"
        [ngClass]="getControlErrors('formTitle') ? 'field-error' : ''"
      ></ion-input>
      <span class="text-danger">{{ getControlErrors("formTitle") }}</span>
    </form>
  </div>
  <div class="custom-form-sections">
    <div class="custom-form-section">
      <div class="section-heading">
        {{ "custom_event_form_mobile.section.mandatory_field" | translate }}
      </div>
      <ion-list>
        <ion-item lines="none" *ngFor="let field of mandatorySectionFields">
          <ion-checkbox slot="start" disabled [checked]="true"></ion-checkbox>
          <ion-label>{{ field | translate }}</ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div class="custom-form-section">
      <div class="section-heading">
        {{ "custom_event_form_mobile.section.other_field" | translate }}
      </div>
      <ion-list>
        <ion-item lines="none" *ngFor="let field of otherFields">
          <ion-checkbox
            slot="start"
            [(ngModel)]="fieldSelectionMap[field.value]"
          ></ion-checkbox>
          <ion-label>{{ field.name | translate }}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div class="note">
    {{ "custom_event_form_mobile.note" | translate }}
  </div>

  <div class="footer">
    <div *ngIf="isPopup" class="cancel-button" (click)="closeDialog()">
      {{ "btn.cancel" | translate | uppercase }}
    </div>
    <button
      class="save-button"
      (click)="saveForm()"
      [ngClass]="{ 'disabled': formTitleGroup.invalid }"
      [disabled]="formTitleGroup.invalid"
    >
      {{ "btn.save" | translate | uppercase }}
  </button>
  </div>
</div>

<div *ngIf="!isPopup && isOnline" class="custom-form-mobile form-layout">
  <ion-card>
    <ion-card-content>
      <div class="custom-form-heading">
        {{ "custom_event_form_mobile.heading" | translate }}
      </div>
      <div class="custom-form-title">
        <div class="section-heading">
          {{ "custom_event_form_mobile.section.form_title" | translate }} {{'wildcard.symbol' | translate}}
        </div>
        <form [formGroup]="formTitleGroup">
          <ion-input
            formControlName="formTitle"
            [placeholder]="'custom_event_form.form_title.placeholder' | translate"
            [ngClass]="getControlErrors('formTitle') ? 'field-error' : ''"
          ></ion-input>
          <span class="text-danger">{{ getControlErrors("formTitle") }}</span>
        </form>
      </div>
    </ion-card-content>
  </ion-card>

  <div class="custom-form-sections">
    <ion-card>
      <ion-card-content>
        <div class="custom-form-section">
          <div class="section-heading">
            {{ "custom_event_form_mobile.section.mandatory_field" | translate }}
          </div>
          <ion-list>
            <ion-item lines="none" *ngFor="let field of mandatorySectionFields">
              <ion-checkbox slot="start" disabled [checked]="true"></ion-checkbox>
              <ion-label>{{ field | translate }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-content>
        <div class="custom-form-section">
          <div class="section-heading">
            {{ "custom_event_form_mobile.section.other_field" | translate }}
          </div>
          <ion-list>
            <ion-item lines="none" *ngFor="let field of otherFields">
              <ion-checkbox
                slot="start"
                [(ngModel)]="fieldSelectionMap[field.value]"
              ></ion-checkbox>
              <ion-label>{{ field.name | translate }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
        <div class="note">
          {{ "custom_event_form_mobile.note" | translate }}
        </div>
      </ion-card-content>
    </ion-card>
  </div>

  <div class="footer">
    <div *ngIf="isPopup" class="cancel-button" (click)="closeDialog()">
      {{ "btn.cancel" | translate | uppercase }}
    </div>
    <button
      class="save-button"
      (click)="saveForm()"
      [ngClass]="{ 'disabled': formTitleGroup.invalid }"
      [disabled]="formTitleGroup.invalid"
    >
      {{ "btn.save" | translate | uppercase }}
  </button>
  </div>
</div>

<div *ngIf="!isOnline">
  <ion-card>
    <ion-card-content>
      {{'custom_event.form.offline_message' | translate }}
    </ion-card-content>
  </ion-card>
</div>