import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceService } from './device.service';
import { LogoutService } from './logout/logout.service';

export enum AlertLevel {
  info,
  success,
  warning,
  error
}

export class AlertBox {
  constructor(
    public id: string,
    public alertLevel: AlertLevel,
    public textContent: string,
    public showCloseButton: boolean = true,
    public showRefreshButton: boolean = false
  ) {}
}

@Injectable()
export class ToasterService {

  private alertBoxes$ = new BehaviorSubject<AlertBox[]>([]);
  toastrSettings;

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private logoutService: LogoutService,
    ) {
    if (this.deviceService.isMobile) {
      this.toastrSettings = {
        timeOut: 3000,
        toastClass: 'ngx-toastr mobile-toaster d-print-none',
        positionClass: 'toast-bottom-center',
        closeButton: false,
        progressBar: false,
        autoDismiss: true,
        preventDuplicates: true,
        tapToDismiss: true
      };
    } else {
      this.toastrSettings = {
        timeOut: 8000,
        toastClass: 'ngx-toastr d-print-none',
        positionClass: 'toast-top-right',
        closeButton: true,
        progressBar: true,
        autoDismiss: true
      };
    }

    // clear all alert boxes on logout
    this.logoutService.addLogoutCallback(() => this.clearAllAlertBoxes());
  }

  /**
   * Basic Toaster
   * @param msg information displayed in the text area of the toaster
   */
  showInfoToaster(msg: string, translationParams?: Object) {
    this.translateService.get(['info', msg], translationParams).subscribe(
      translations => this.toastr.info(
        this.deviceService.isMobile ? null : translations[msg],
        this.deviceService.isMobile ? translations[msg] : translations['info'],
        this.toastrSettings
      )
    );
  }

  /**
   * A RED Error Toaster
   * @param msg information displayed in the text area of the toaster
   */
  showErrorToaster(msg: string, translationParams?: Object) {
    this.translateService.get(['error', msg], translationParams).subscribe(
      translations => this.toastr.error(
        this.deviceService.isMobile ? null : translations[msg],
        this.deviceService.isMobile ? translations[msg] : translations['error'],
        this.toastrSettings)
    );
  }

  /**
   * A GREEN Success Toaster
   * @param msg information displayed in the text area of the toaster
   */
  showSuccessToaster(msg: string, translationParams?: Object) {
    this.translateService.get(['success', msg], translationParams).subscribe(
      translations => this.toastr.success(
        this.deviceService.isMobile ? null : translations[msg],
        this.deviceService.isMobile ? translations[msg] : translations['success'],
        this.toastrSettings
      )
    );
  }

  /**
   * YELLOW Success Toaster
   * @param msg information displayed in the text area of the toaster
   */
  showWarningToaster(msg: string, translationParams?: Object) {
    this.translateService.get(['warning', msg], translationParams).subscribe(
      translations => this.toastr.warning(
        this.deviceService.isMobile ? null : translations[msg],
        this.deviceService.isMobile ? translations[msg] : translations['warning'],
        this.toastrSettings
      )
    );
  }

  showToaster(alertLevel: AlertLevel, text: string) {
    let translatedText = null;
    let translatedTitle = null;

    if (this.deviceService.isMobile) {
      this.translateService.get(text).subscribe(
        _translate => translatedTitle = _translate
      );
    } else {
      this.translateService.get('error').subscribe(
        _translate => translatedTitle = _translate
      );
      this.translateService.get(text).subscribe(
        _translate => translatedText = _translate
      );
    }

    switch (alertLevel) {
      case AlertLevel.success:
        this.toastr.success(translatedText, translatedTitle, this.toastrSettings);
        break;
      case AlertLevel.info:
        this.toastr.info(translatedText, translatedTitle, this.toastrSettings);
        break;
      case AlertLevel.warning:
        this.toastr.warning(translatedText, translatedTitle, this.toastrSettings);
        break;
      default:
        this.toastr.error(translatedText, translatedTitle, this.toastrSettings);
    }
  }

  isAlertBoxActive(alertBoxId: string): boolean {
    return this.alertBoxes$.getValue().some(alertBox => alertBox.id === alertBoxId);
  }

  showAlertBox(alertBox: AlertBox): void {
    if (!this.isAlertBoxActive(alertBox.id)) {
      const alertBoxes = this.alertBoxes$.getValue();
      alertBoxes.push(alertBox);
      alertBoxes.sort((a, b) => b.alertLevel.valueOf() - a.alertLevel.valueOf());
      this.alertBoxes$.next(alertBoxes);
    }
  }

  hideAlertBox(alertBoxId: string): void {
    const alertBoxes = this.alertBoxes$.getValue();
    this.alertBoxes$.next(alertBoxes.filter(alertBox => alertBox.id !== alertBoxId));
  }

  // clear all alert boxes
  clearAllAlertBoxes(): void {
    this.alertBoxes$.next([]);
  }

  watchAlertBoxes(): Observable<AlertBox[]> {
    return this.alertBoxes$.asObservable();
  }

}
