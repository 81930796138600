import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpStatus } from "@constants/http/http-status";
import { FilteredTask } from "@models/filtered-task";
import { FilteredTimelineTask } from "@models/filtered-timeline-tasks";
import { CreatorFilterModel, TaskFilterModel } from "./event-search-filter.service";
import { SharedDataService } from "./shared-data.service";
import { ToasterService } from "./toaster.service";
import { UrlGiverService } from "./url-giver.service";
import { taskNumberPrefix } from "@models/task";

export interface TaskFilterParameters {
  currentDate : number | string,
  searchKeyword?: string,
  fromDate?: Date,
  toDate?: Date,
  contractors?: string[],
  creators?: string[],
  indicators?: string[],
  states?: string[],
  considerAllTasks?: boolean,
}

@Injectable()
export class TaskFilterService {

  constructor(
    private http: HttpClient,
    private urlGiverService: UrlGiverService,
    private sharedDataService: SharedDataService,
    private toasterService: ToasterService,
  ) { }

  async callTaskFilterApi(filterParameters: TaskFilterParameters): Promise<FilteredTask[]> {
    const url = this.urlGiverService.giveTaskFilterAPIUrl(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const payloadBody = filterParameters;
    const filteredTasks = await this.http.post(url, payloadBody).toPromise().then((res: FilteredTask[]) => {
    return FilteredTask.jsonToFilteredTasks(res);
    }).catch((error) => {
      if(error.status === HttpStatus.PAYLOAD_TOO_LARGE) {
        this.toasterService.showWarningToaster('filter.error.tasks_payload_too_large');
      } else {
        this.toasterService.showWarningToaster('filter.error.tasks.try_again.message');
      }
      return [];
    });
    return filteredTasks;
  }

  async getTaskCreators(): Promise<CreatorFilterModel[]> {
    const url = this.urlGiverService.giveFilterDataForTaskCreators(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const filterCreators = await this.http.get(url).toPromise().then((res: CreatorFilterModel[]) => {
      return res;
    }).catch((error) => {
      return [];
    });
    return filterCreators;
  }

  async getTasks(): Promise<TaskFilterModel[]> {
    const url = this.urlGiverService.giveFilterDataForTasks(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const filterTasks = await this.http.get(url).toPromise().then((res: TaskFilterModel[]) => {
      res.map((task) => {
        if(task.taskNumber) {
          if (task.parentTaskId) {
            task.title = taskNumberPrefix.SUBTASK_PREFIX + '-' + task.taskNumber.toString() + ' ' + task.title;
          } else {
            task.title = taskNumberPrefix.TASK_PREFIX + '-' + task.taskNumber.toString() + ' ' + task.title;
          }
        }
      });
      return res;
    }).catch((error) => {
      return null;
    });
    return filterTasks;
  }

  async callTaskTimelineFilterApi(filterParameters: TaskFilterParameters): Promise<FilteredTimelineTask[]> {
    const url = this.urlGiverService.giveTaskTimelineFilterAPIUrl(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const payloadBody = filterParameters;
    const filteredTasks = await this.http.post(url, payloadBody).toPromise().then((res: FilteredTimelineTask[]) => {
    return res;
    }).catch((error) => {
      this.toasterService.showWarningToaster('filter.error.tasks.try_again.message');
      return [];
    });
    return filteredTasks;
  }

}
