import { TaskIndicatorFilterValue, TaskStateFilterValue } from "./enums/task-state-indicator.enum";
import { Task } from "./task";
import { TaskContractor } from "./task-contractor";

export interface BackendTaskState {
  name: string,
  value: TaskStateFilterValue | TaskIndicatorFilterValue,
}

export class FilteredTask {
  id: string;
  parentTaskId: string;
  subtaskIds: string[];
  taskNumber: number;
  state: TaskIndicatorFilterValue | TaskStateFilterValue;
  title: string;
  locationId: string;
  locationName: string;
  startDatetime: Date;
  endDatetime: Date;
  plannedQuantity: number;
  totalQuantityDone: number;
  progress: number;
  contractors: TaskContractor[];
  createdBy: string;

  public static BACKEND_TASK_STATE: BackendTaskState[] = [
    { name: 'tasks.list.planned', value: TaskStateFilterValue.PLANNED },
    { name: 'tasks.list.inprogress', value: TaskStateFilterValue.INPROGRESS },
    { name: 'tasks.list.completed', value: TaskStateFilterValue.COMPLETED },
    { name: 'tasks.list.cancelled', value: TaskStateFilterValue.CANCELLED },
    { name: 'tasks.list.readyToStart', value: TaskIndicatorFilterValue.READY_TO_START },
    { name: 'tasks.list.delayed', value: TaskIndicatorFilterValue.DELAYED },
    { name: 'tasks.list.overdue', value: TaskIndicatorFilterValue.OVERDUE },
    { name: 'tasks.list.pendingApproval', value: TaskIndicatorFilterValue.PENDING_APPROVAL },
  ];

  constructor() {}

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend task filter API
  * @param model Filtered Task
  */
  public static toModel(json: any, model: FilteredTask) {
    model.id = json.payload.id;
    model.parentTaskId = json.payload.id;
    model.subtaskIds = json.payload.subtaskIds;
    model.taskNumber = json.payload.taskNumber;
    model.state = json.payload.state;
    model.title = json.payload.title;
    model.locationId = json.payload.locationId;
    model.startDatetime = json.payload.startDatetime;
    model.endDatetime = json.payload.endDatetime;
    model.plannedQuantity = json.payload.plannedQuantity;
    model.totalQuantityDone = json.payload.totalQuantityDone;
    model.progress = json.payload.progress;
  }

  public static jsonToFilteredTasks(json: any): FilteredTask[] {
    return json.map(filteredTaskObject => {
      const filteredTask: FilteredTask = new FilteredTask();
      filteredTask.id = filteredTaskObject.id;
      filteredTask.parentTaskId = filteredTaskObject.parentTaskId;
      filteredTask.subtaskIds = filteredTaskObject.subtaskIds;
      filteredTask.taskNumber = filteredTaskObject.taskNumber;
      filteredTask.locationId = filteredTaskObject.locationId;
      filteredTask.state = filteredTaskObject.state;
      filteredTask.title = filteredTaskObject.title;
      filteredTask.progress = filteredTaskObject.progress;
      filteredTask.startDatetime = filteredTaskObject.startDatetime;
      filteredTask.endDatetime = filteredTaskObject.endDatetime;
      filteredTask.plannedQuantity = filteredTaskObject.plannedQuantity;
      filteredTask.totalQuantityDone = filteredTaskObject.totalQuantityDone;
      filteredTask.locationId = filteredTaskObject.locationId;
      if(filteredTaskObject.contractors) {
        filteredTask.contractors = TaskContractor.jsonToTaskContractors(filteredTaskObject.contractors);
      }
      if(filteredTaskObject.createdBy) {
        filteredTask.createdBy = filteredTaskObject.createdBy;
      }
      return filteredTask;
    });
  }

}
