import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(labelsToJoin: string[], delimiter: string = ', '): string {
    return labelsToJoin.join(delimiter);
  }
}
