import { Component, OnInit, Output } from '@angular/core';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-update-banner-web',
  templateUrl: './update-banner-web.component.html',
  styleUrls: ['./update-banner-web.component.sass'],
})
export class UpdateBannerWebComponent extends AutoUnsubscribeComponent implements OnInit {
  showUpdateBanner: boolean = false;

  constructor(
    private appUpdateSettingsService: AppUpdateSettingsService
  ) {
    super();
    this.appUpdateSettingsService.watchAppUpdateSettings().pipe(
      takeUntil(this.destroy),
    ).subscribe(appUpdateSettings => {
        this.showUpdateBanner = appUpdateSettings.showAppUpdateBanner ?? false;
    });
   }

  ngOnInit() {
    this.appUpdateSettingsService.periodicVersionCheck();
  }

  updateApp(): void {
    window.location.reload();
  }

  dismissAppUpdateBanner(): void {
    this.appUpdateSettingsService.onWebUpdateDismiss();
  }
}
