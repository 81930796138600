<ng-container *ngIf="deviceService.isMobile; else desktop">
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar *ngIf="isApprovalRequest || isRejectionRequest"
      [doneLabel]="isApprovalRequest ? 'events.approve-reject.comment.popup.btn.approve'
        : isRejectionRequest ? 'events.approve-reject.comment.popup.btn.reject':''"
      [title]="isApprovalRequest ? 'events.approve-reject.comment.popup.approve.header.mobile'
        : isRejectionRequest ? 'events.approve-reject.comment.popup.reject.header.mobile':''"
      (back)="dismiss()"
      (done)="save()">
    </app-mobile-toolbar>
  </ion-header>

  <ion-content class="main-form-wrapper mobile-wrapper">
    <form [formGroup]="formGroup">
      <ion-item-divider>
        <ion-label
          aria-controls="collapseTitle">
          <h1 class="pull-left">
            {{'events.approve-reject.comment.popup.title-label' | translate}}
          </h1>
        </ion-label>
      </ion-item-divider>
      <div>
        <ion-input
          formControlName="title"
          placeholder="{{'events.approve-reject.comment.popup.title-label-placeholder' | translate}}"
          type="text">
        </ion-input>
      </div>
    </form>
  </ion-content>
</ng-container>

<ng-template #desktop>
  <aside class="webapp-wrapper">
    <div class="top-display">
      <h1>
        {{isApprovalRequest ? ('events.approve-reject.comment.popup.approve.header' | translate) : isRejectionRequest ? ('events.approve-reject.comment.popup.reject.header' | translate) : ''}}
        <span class="popup-close-button" (click)="dismiss()">
          <ion-icon name="custom-close"></ion-icon>
        </span>
      </h1>
    </div>
    <form [formGroup]="formGroup" (submit)="save($event)">
      <label for="title">
        {{'events.approve-reject.comment.popup.title-label' | translate}}
      </label>
        <textarea
          type="text"
          name="title"
          formControlName="title"
          placeholder="{{ 'events.approve-reject.comment.popup.title-label-placeholder' | translate }}">
        </textarea>
      <div class="validation-buttons">
        <button
          type="button"
          (click)="dismiss()"
          class="btn btn-link btn-sm">
          {{'events.approve-reject.comment.popup.btn.cancel' | translate}}
        </button>
        <button *ngIf="isApprovalRequest"
          type="submit"
          class="btn text-success font-weight-bold">
          {{'events.approve-reject.comment.popup.btn.approve' | translate}}
        </button>
        <button *ngIf="isRejectionRequest"
          type="submit"
          class="btn text-danger font-weight-bold">
          {{'events.approve-reject.comment.popup.btn.reject' | translate}}
        </button>
      </div>
    </form>
  </aside>
</ng-template>
