import { UiDatetimePickerService } from './datetime-picker/datetime-picker.service';
import { UiDatetimePickerTranslateService } from './datetime-picker/datetime-picker-translate.service';

export const services: any[] = [
    UiDatetimePickerService,
    UiDatetimePickerTranslateService
];

export * from './datetime-picker/datetime-picker.service';
export * from './datetime-picker/datetime-picker-translate.service';
