export enum faviconHrefs {
    siteProductivityFavicon_32x32 = 'assets/icons/site-productivity-favicon-32x32.png',
    siteProductivityFavicon_16x16 = 'assets/icons/site-productivity-favicon-16x16.png',
    siteProductivityFavicon = 'assets/icons/site-productivity-favicon.ico',
    siteProductivityMaskIcon = 'assets/icons/site-productivity-icon.svg',
    siteDiaryFavicon_32x32 = 'assets/icons/favicon-32x32.png',
    siteDiaryFavicon_16x16 = 'assets/icons/favicon-16x16.png',
    siteDiaryFavicon = 'assets/icons/favicon.ico',
    siteDiaryMaskIcon = 'assets/img/logo/casque_sitediary_purple.svg',
    siteTaskFavicon_32x32 = 'assets/icons/site-task-favicon-32x32.png',
    siteTaskFavicon_16x16 = 'assets/icons/site-task-favicon-16x16.png',
    siteTaskFavicon = 'assets/icons/site-task-favicon.ico',
    siteTaskMaskIcon = 'assets/img/logo/site_task_logo.svg',
}