import { Asset } from '../asset';
import Dexie from 'dexie';

import { QueueService } from '../../services/synchronization/queue.service';
import { Event } from '../event';
import { Site } from '../site';
import { ActionType, SyncDto } from '../synchronization/sync-dto';
import { Task } from '../task';
import { Currency } from '../utils/currency';
import { Contractor } from '../contractor';
import { Tag } from '../tag';
import { Location } from '../location';
import { SiteUser } from '../site-user';
import { Observable } from 'rxjs';
import { ModelElement } from '../model-element';
import { Logger } from '../../services/logger';
import { ModelVersion } from '../model-version';
import { CustomEventForm } from '@models/custom-event-form';
import { customFormLegacyFormLayout } from '@models/customFormLayout/custom-form-legacy-form-layout';

const SPACE_DB_NAME_PREFIX = 'SPACE';
const SPACE_DB_ORIGINAL_VERSION = 1; // Used until 1.6.7
const SPACE_DB_LOCATION_VERSION = 2; // Used exclusively in 1.6.8
const SPACE_DB_SYNC_REFACTO_VERSION = 3; // Used starting 1.7.0
const SPACE_DB_SMART_UPDATE_VERSION = 4; // Used starting 1.8.1
const SPACE_DB_SITE_TASK_UPDATE_VERSION = 5; // Used starting 1.9.22
const SPACE_DB_CUSTOM_EVENT_FORM_UPDATE_VERSION = 6;
const SPACE_DB_EVENT_INDEXING_VERSION = 7;
const SPACE_DB_CLEAR_DB_FOR_LIMIT_SYNC_VERSION = 8; // used to clear data in mobile device to then store data from the new restricted sync api
const SPACE_DB_2FA_VERSION = 9; // used to fetch all the space data after 2FA update
const SPACE_DB_EVENT_MODIFIED_AT_VERSION = 10;
const SPACE_DB_SITE_ARCHIVE_VERSION = 11; // used to fetch all sites after site archival update
const SPACE_DB_CUSTOM_EVENT_FORM_V2_MIGRATION_VERSION = 12 // used to set the default form version to existing forms before we roll out Custom Forms V2

/**
 * type Type of the transaction
 * table Table modified by the transaction
 * object The object that is about to be modified
 */
export class DatabaseHookEvent<T> {
  constructor(
    public type: 'creating' | 'deleting' | 'updating',
    public table: Dexie.Table<T, string>,
    public object: T,
  ) {}
}


/**
 * Class to manage platform's objects inside the local indexedDB
 */
export class SpaceDatabase extends Dexie {

  /**
   * Table of {@link Tag} object
   */
  public readonly tag: Dexie.Table<Tag, string>;

  /**
   * Table of {@link Asset} object
   */
  public readonly asset: Dexie.Table<Asset, string>;

  /**
   * Table of {@link_Contractor}
   */
  public readonly contractor: Dexie.Table<Contractor, string>;

  /**
   * Table of {@link Site} object
   */
  public readonly site: Dexie.Table<Site, string>;

  /**
   * Table of {@link Event}
   */
  public readonly event: Dexie.Table<Event, string>;

  /**
   * Table of {@link User} object
   */
  public readonly siteUser: Dexie.Table<SiteUser, string>;

  /**
   * Table of {@link Currency} object
   */
  public readonly currency: Dexie.Table<Currency, string>;

  /**
   * Table of {@link Task} object
   */
  public readonly task: Dexie.Table<Task, string>;

  /**
   * Table of {@link Location} object
   */
  public readonly location: Dexie.Table<Location, string>;

  /**
   * Table of {@link ModelVersion} object
   */
  public readonly modelVersion: Dexie.Table<ModelVersion, string>;

  /**
   * Table of {@link CustomEventForm} object
   */
  public readonly customEventForm: Dexie.Table<CustomEventForm, string>;


  private readonly types: {[typeName: string]: Dexie.Table<any, string>};
  public readonly userId: string;
  public readonly spaceId: string;
  public readonly queueService: QueueService;

  /**
   * Wrapper for the Dexie hooks so we can use them with the rxjs framework
   * The hook sends an event for each action on the table after the transactions completes
   * There is no way to filter the events with Dexie, but we can use `.pipe(filter(T => boolean))` from rxjs
   * @param table The database table to hook
   * @returns An observable that is triggers each time an event occurs
   */
  public static hookTable<T>(table: Dexie.Table<T, string>): Observable<DatabaseHookEvent<T>> {
    return new Observable(subscriber => {

      function getHookFunction(
        type: 'creating' | 'deleting' | 'updating',
        obj: T,
        transaction: Dexie.Transaction
      ) {
        return () => {
          if (transaction) {
            transaction.on('complete', () => {
              subscriber.next(new DatabaseHookEvent(type, table, obj));
            });
          } else {
            subscriber.next(new DatabaseHookEvent(type, table, obj));
          }
        };
      }

      // Hook the Dexie events
      const creatingFunc = function(primKey, obj, transaction) {
        this.onsuccess = getHookFunction('creating', obj, transaction);
      };
      table.hook('creating', creatingFunc);

      const deletingFunc = function(primKey, obj, transaction) {
        this.onsuccess = getHookFunction('deleting', obj, transaction);
      };
      table.hook('deleting', deletingFunc);

      const updatingFunc = function(modifications, primKey, obj, transaction) {
        this.onsuccess = getHookFunction('updating', obj, transaction);
      };
      table.hook('updating', updatingFunc);

      return (() => {
        // Clean-up the hooks on unsubscribe
        table.hook('creating').unsubscribe(creatingFunc);
        table.hook('deleting').unsubscribe(deletingFunc);
        table.hook('updating').unsubscribe(updatingFunc);
      });
    });
  }

  public static getDatabaseName(userId: string, spaceId: string) {
    return SPACE_DB_NAME_PREFIX + '/' + userId + '/' + spaceId;
  }

  /**
   * Constructor of the SpaceDatabase
   */
  constructor(userId: string, spaceId: string, queueService: QueueService, isMobileDevice: boolean) {
    super(SpaceDatabase.getDatabaseName(userId, spaceId));
    this.userId = userId;
    this.spaceId = spaceId;
    this.queueService = queueService;

    let customLegacyForm = new CustomEventForm();
    CustomEventForm.toModel(customFormLegacyFormLayout, customLegacyForm);
    this.version(SPACE_DB_ORIGINAL_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId]',
      contractor: 'id, siteId',
      location: 'id',
      site: 'id',
      event: 'id, siteId, taskId',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId',
    });
    this.version(SPACE_DB_LOCATION_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId]',
      contractor: 'id, siteId',
      location: 'id, siteId', // added siteId as a searching key for locations
      site: 'id',
      event: 'id, siteId, taskId',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      country: 'libelle',
      task: 'id, siteId',
    });
    this.version(SPACE_DB_SYNC_REFACTO_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId', // Add siteId as a searching key
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime]', // added startDatetime as a searching key for events, fix task ID index, add compound indexes for performance
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',  // added startDatetime as a searching key for tasks
    });
    this.version(SPACE_DB_SMART_UPDATE_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime]', // added startDatetime as a searching key for events, fix task ID index, add compound indexes for performance
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
    });
    this.version(SPACE_DB_SITE_TASK_UPDATE_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
    }).upgrade(tx => {
      // Modify the existing task table to now have these new values.
      tx.table('task').toCollection().modify( task => {
        task.locationObject = null;
        task.plannedQuantity = null;
        task.taskStatus = 0;
        task.taskIndicator = null;
        task.totalQuantityDone = null;
        task.quantityUnit = null;
        task.isApproved = false;
        task.isReadyToStart = false;
        task.isCancelled = false;
      });
      // Modify the existing event table to now have these new values.
      tx.table('event').toCollection().modify(event => {
        if (event.task) {
          event.task.quantityDone = null;
        }
      });
    });
    this.version(SPACE_DB_CUSTOM_EVENT_FORM_UPDATE_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      // Add legacy forms to all sites
      tx.table('site').toCollection().each((site) => {
        let customSiteForm = JSON.parse(JSON.stringify(customLegacyForm));
        customSiteForm.siteId = site.id;
        tx.table('customEventForm').put(customSiteForm);
      });
      const updateDate = new Date().valueOf();
      if(updateDate > 1663644600000) {
        tx.table('event').clear();
        tx.table('task').clear();
      } else {
        // Modify all events to now have the legacy form id
        tx.table('event').toCollection().modify(event => {
          event.formLayoutId = customLegacyForm.id;
          event.customFieldValues = null;
        });
      }
    });
    this.version(SPACE_DB_EVENT_INDEXING_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    });
    this.version(SPACE_DB_CLEAR_DB_FOR_LIMIT_SYNC_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      if(isMobileDevice) {
        tx.table('event').clear();
        tx.table('task').clear();
        tx.table('tag').clear();
        tx.table('asset').clear();
        tx.table('contractor').clear();
        tx.table('location').clear();
      }
    });
    this.version(SPACE_DB_2FA_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      if(isMobileDevice) {
        localStorage.setItem('fetchSpacesDataAfterUpdate', 'true');
      }
    });
    this.version(SPACE_DB_EVENT_MODIFIED_AT_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      if(isMobileDevice) {
        tx.table('event').toCollection().modify(event => {
          event.modifiedAt = null;
        });
      }
    });
    this.version(SPACE_DB_SITE_ARCHIVE_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      if(isMobileDevice) {
        localStorage.setItem('checkForArchivedSiteOnUpdate', 'true');
      }
    });
    this.version(SPACE_DB_CUSTOM_EVENT_FORM_V2_MIGRATION_VERSION).stores({
      tag: 'id, siteId',
      asset: 'id, [category+siteId], siteId',
      contractor: 'id, siteId',
      location: 'id, siteId',
      site: 'id',
      event: 'id, siteId, task.taskId, startDatetime, [siteId+task.taskId], [task.taskId+startDatetime], [siteId+startDatetime]',
      siteUser: '[id+siteId], siteId',
      currency: 'id',
      task: 'id, siteId, startDatetime',
      modelVersion: 'siteId',
      customEventForm: '[id+siteId], id, siteId',
    }).upgrade((tx) => {
      tx.table('customEventForm').toCollection().modify(customForm => {
        customForm.formVersion = 1;
      });
    })
    this.tag.mapToClass(Tag);
    this.asset.mapToClass(Asset);
    this.contractor.mapToClass(Contractor);
    this.location.mapToClass(Location);
    this.site.mapToClass(Site);
    this.event.mapToClass(Event);
    this.siteUser.mapToClass(SiteUser);
    this.currency.mapToClass(Currency);
    this.task.mapToClass(Task);
    this.modelVersion.mapToClass(ModelVersion);
    this.customEventForm.mapToClass(CustomEventForm);

    this.types = {
      'Tag': this.tag,
      'Asset': this.asset,
      'Contractor': this.contractor,
      'Location': this.location,
      'Site': this.site,
      'Event': this.event,
      'SiteUser': this.siteUser,
      'Currency': this.currency,
      'Task': this.task,
      'TaskNewApi': this.task,
      'CustomEventForm': this.customEventForm,
      'EventV4': this.event,
    };
    if(this.verno >= 6) {
      this.isLegacyFormAbsent().then((isAbsent) => {
        if(isAbsent) {
          this._add(customLegacyForm, 'CustomEventForm', false);
        }
      }).catch(() => {
      });
    }
  }

  private async isLegacyFormAbsent(): Promise<boolean> {
    let customLegacyForm = new CustomEventForm();
    CustomEventForm.toModel(customFormLegacyFormLayout, customLegacyForm);
    const legacyForm = await this.getTable('CustomEventForm').where('id').equals(customLegacyForm.id).toArray();
    return new Promise((resolve, reject) => {
      if(legacyForm.length > 0) {
        reject()
      } else {
        resolve(true);
      }
    });
  }

  public getTable<T extends ModelElement>(typeName: string): Dexie.Table<T, string> {
    if (typeName in this.types) {
      return this.types[typeName];
    } else {
      Logger.error('There is no table for the type:', typeName);
      return null;
    }
  }

  private addActionToQueue<T extends ModelElement>(actionType: ActionType, item: T, type: string): void {
    const action = new SyncDto(actionType, type, item.toDTO());
    if ((item.hasOwnProperty('siteId')) && item.siteId !== 'null') {
      action.attachToSite(item.siteId);
    }
    this.queueService.addAction(action, this.spaceId);
  }

  /**
   * Add item in localStorage and add action on the queue
   * @param item Item to save
   * @param type The type of the {@link item} to save
   * @param withQueue Add or not on the queue system
   */
  public _add<T extends ModelElement>(item: T, type: string, withQueue = true): Promise<T> {
    return this.transaction('rw?', this.types[type], async () => {
      await this.types[type].put(item);
      if (withQueue) {
        this.addActionToQueue(ActionType.CREATE, item, type);
      }
      return item;
    });
  }

  /**
   * Add array in database and add action on the queue
   * @param items Array to save
   * @param type Type of the items to save
   * @param withQueue Add or not on the queue system
   */
  public async _bulkAdd<T extends ModelElement>(items: T[], type: string, withQueue = true): Promise<T[]> {
    if (items.length !== 0) {
      return this.transaction('rw?', this.types[type], async () => {
        await this.types[type].bulkPut(items);
        if (withQueue) {
          for (const item of items) {
            this.addActionToQueue(ActionType.CREATE, item, type);
          }
        }
        return items;
      });
    }
    return [];
  }

  public async bulkAddWithProgress<T extends ModelElement>(items: T[], type: string, withQueue = true, chunkSize, position=0): Promise<T[]> {
    if (items.length !== 0) {
      let loopCount = Math.ceil(items.length / chunkSize);
      for(let i = 0; i < loopCount; i++) {
        await this._bulkAdd(items.slice(position, position + chunkSize), type, withQueue);
        position = position + chunkSize;
      }
      return items;
    }
    return [];
  }

  /**
   * Update item in database and add action on the queue
   * @param item Item to update
   * @param type The type of the {@link item} to update
   * @param withQueue Add or not on the queue system
   */
  public _put<T extends ModelElement>(item: T, type: string, withQueue = true): Promise<T> {
    return this.transaction('rw', this.types[type], async () => {
      await this.types[type].put(item);
      if (withQueue) {
        this.addActionToQueue(ActionType.UPDATE, item, type);
      }
      return item;
    });
  }

  /**
   * Update array in database and add action on the queue
   * @param items Array to update
   * @param type The type of the {@link item} to update
   * @param withQueue Add or not on the queue system
   */
  public async _bulkPut<T extends ModelElement>(items: T[], type: string, withQueue = true): Promise<T[]> {
    if (items.length !== 0) {
      return this.transaction('rw', this.types[type], async () => {
        await this.types[type].bulkPut(items);
        return items;
      });
    }
    return [];
  }

  /**
   * Update item in database and add action on the queue
   * @param item Item to update
   * @param type The type of the {@link item} to update
   * @param withQueue Add or not on the queue system
   */
  public _delete<T extends ModelElement>(item: T, type: string, withQueue = true): Promise<T> {
    return this.transaction('rw', this.types[type], async () => {
      await this.types[type].delete(item.id);
      if (withQueue) {
        this.addActionToQueue(ActionType.DELETE, item, type);
      }
      return item;
    });
  }

  /**
   * Delete a list of items in database
   * @param items List of items to delete
   * @param type Type of items
   */
  public async _bulkDelete<T extends ModelElement>(items: T[], type: string): Promise<void> {
    if (items.length !== 0) {
      return this.transaction('rw', this.types[type], () => {
        const itemsIds: string[] = items.map(item => item.id);
        return this.types[type].bulkDelete(itemsIds);
      });
    }
  }

  /**
   * Delete item in database by id
   * @param id id of the item to delete
   * @param type The type of the object to delete
   */
  public _deleteById(id: string, type: string): Promise<void> {
    return this.transaction('rw', this.types[type], () => this.types[type].delete(id));
  }

  /**
   * Check if item exists in localStorage.
   * @param item Item to update
   * @param type The type of the {@link item} to update
   */
  public _exists<T extends ModelElement>(item: T, type: string): Promise<boolean> {
    const primaryKey = (type === 'SiteUser') ? item.id + '+' + item.siteId : item.id;
    return this.transaction('rw', this.types[type], async () => !!await this.types[type].get(primaryKey));
  }

  public clear(): void {
    for (const table of Object.values(this.types)) {
      table.clear();
    }

    Logger.info(`Database '${this.name}' has been cleared.`);
  }
}
