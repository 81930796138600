import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DeviceService } from '../../../../services/device.service';
import { DateManipulationService } from '../../../../services/date-manipulation.service';
import { MatDialog } from '@angular/material/dialog';
import { UiWebTimePickerComponent } from 'app/core/ui/components/web';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.sass'],
})
export class DurationPickerComponent implements OnInit {

  @Input() duration: number;
  @Input() readOnly: boolean;
  @Output() durationChange = new EventEmitter<number>();

  durationMask = '00:m0';
  minutes: number[] = [];
  hours: number[] = [];
  selectedMin: number = 0;
  selectedHour: number = 0;

  constructor(
    public deviceService: DeviceService,
    private dateManipulationService: DateManipulationService,
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
    if(this.duration>0){
      this.selectedHour=Math.floor(this.duration / 60);
      this.selectedMin=this.duration % 60;
    }
    if(this.deviceService.isMobile) {
      this.updateTimeDurationValue();
    }
  }

  onChangeHour(value: string): void {
    this.selectedHour=parseInt(value);
    this.updateTimeDurationValue();
  }

  onChangeMin(value: string): void {
    this.selectedMin=parseInt(value);
    this.updateTimeDurationValue();
  }

  updateTimeDurationValue(): void {
    let timeDuration = (this.selectedHour*60) + this.selectedMin;
    this.durationChange.emit(timeDuration);
  }

  updateDurationValue(duration: number): void {
    this.durationChange.emit(duration);
  }

  convertDurationFromString(duration: string): number {
    const splitDuration = duration.split(':');
    return (+splitDuration[0]) * 60 + (+splitDuration[1]);
  }

  convertDurationToString(duration: number): string {
    let h: any = Math.floor(duration / 60);
    let m: any = duration % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
  }

  convertDurationFromIonic(duration: any): number {
    if (typeof duration === 'string') {
      return this.convertDurationFromString(duration);
    }
    return (+duration.hour.value) * 60 + (+duration.minute.value);
  }

  openTimePicker() {
    const dialogRef = this.dialog.open(UiWebTimePickerComponent, {
      width: '350px',
      data: {duration: this.duration}
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((value) => {
      if(value) {
        this.updateDurationValue(value);
      }
    });
  }

}
