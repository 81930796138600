import { Injectable,PLATFORM_ID } from "@angular/core";
import { INGXLoggerConfig, INGXLoggerMetadata, NGXLoggerWriterService } from "ngx-logger";
import { Logger } from "./logger";
import { DatabaseService } from "./shared/database.service";
import { environment } from "environments/environment";
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root'
})
export class NGXLoggerCustomisedWriterService extends NGXLoggerWriterService {

  constructor(
    private databaseService: DatabaseService,
  ) {
    super(PLATFORM_ID);
  }

  /** Write the content sent to the log function to indexDB */
  public async writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): Promise<void> {
    const userDB = await this.databaseService.getUserDB();
    let additionalInfo: any = '';
    if(metadata.additional.length > 0) {
      try {
        additionalInfo = JSON.stringify(metadata.additional);
      }
      catch {
        additionalInfo = metadata.additional;
      }
    }
    const logLevel = this.getLogLevel(metadata.level);
    if(userDB) {
      const currentDate = new Date().toISOString();
      let logData = '\n' + logLevel + ' : [' + (currentDate + '] ' + metadata.message) + ' - ' + additionalInfo  + ' \n';
      userDB.logData.put(logData,currentDate);
    }
    if(environment.production === false) {
      this.logToConsole(metadata);
    }
    if (logLevel === 'Error' && (metadata.additional[0] !== false)) {
      this.throwErrorToSentry(metadata)
    }
  }

  logToConsole(metadata: INGXLoggerMetadata): void {
    // Print to console depending on the log level of the log input sent.
    switch(metadata.level) {
      case 1:
        Logger.debug(metadata.message, metadata.additional);
        break;
      case 2:
        Logger.info(metadata.message, metadata.additional);
        break;
      case 4:
        Logger.warn(metadata.message, metadata.additional);
        break;
      case 5:
        Logger.error(metadata.message, metadata.additional);
        break;
      default:
        console.log(metadata);
        break;
    }
  }

 /**
 * Convert the NgxLoggerLevel into a string to be written in the log file.
 */
  getLogLevel(level: number): string {
    switch(level) {
      case 0:
        return 'Trace';
      case 1:
        return 'Debug';
      case 2:
        return 'Info';
      case 3:
        return 'Log';
      case 4:
        return 'Warn';
      case 5:
        return 'Error';
      case 6:
        return 'Fatal';
      case 7:
        return 'Off';
      default:
        return 'Off'
    }
  }

  // sends error message to sentry
  private throwErrorToSentry(metadata: INGXLoggerMetadata): void {
    let message = metadata.message;
    Sentry.captureMessage(message, scope => {
      return scope.setExtra('error', JSON.stringify(metadata.additional));
    });
  }
}
