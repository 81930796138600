<div [formGroup]="parentFormGroup">
  <label [ngClass]="deviceIsMobile? 'mobile-label': 'label'">
    {{ customField.title }}
  </label>
  <div class="text-area">
    <ion-textarea rows="1" [maxlength]="maxLengthAllowed" auto-grow = "true" id="description" [formControlName]="'value'" #userInput (keyup)="countCharacters(userInput)"
    placeholder="{{ placeholderText | translate }}" [ngClass]="{'custom-form-field-content web-customtextarea short-text-area': !deviceIsMobile, 'mobile-text-area' : deviceIsMobile}">
    </ion-textarea>
    <span class="word-count">{{ lengthOfString }}/{{ maxLengthAllowed }}</span>
  </div>
</div>

