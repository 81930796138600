import { Component, Input } from '@angular/core';
import { DeviceService } from '../services/device.service';
import { SpinnerType } from '@services/spinner.service';
import { AnimationOptions } from 'ngx-lottie';
import { UserSettingsService } from '@services/user-settings.service';
import { AppTypeService } from '@services/app-type.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.sass']
})
export class SpinnerComponent {

  @Input() active: boolean;
  @Input() type: SpinnerType = 'pulsating';
  @Input() message: string;
  @Input() appTypeInputString: string = null;

  readonly options: AnimationOptions = {
    path: '/assets/loader.json',
    autoplay: true,
    loop: true,
  };

  constructor(
    public deviceService: DeviceService,
    private userSettingsService: UserSettingsService,
    private appType: AppTypeService,
  ) { }

  lastVisitedApp(): string {
    return this.userSettingsService.get().lastVisitedApp;
  }

  getAppType(): string {
    if (this.appTypeInputString && !this.deviceService.isMobile) {
      return this.appTypeInputString;
    }
    else if (this.appType.isInAdministrationPage() && !this.deviceService.isMobile) {
      return 'administration';
    }
    else {
      return this.lastVisitedApp()
    }
  }
}
