import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { CustomCurrencies, CustomDateFormats, CustomEventField, CustomEventFieldTypeValue } from '@models/custom-event-field';
import { Event } from '@models/event';
import { TranslateService } from '@ngx-translate/core';
import { CustomDatepickerI18nService } from '@services/i18n/date-internationalization.service';

@Component({
  selector: 'app-event-custom-field',
  templateUrl: './event-custom-field.component.html',
  styleUrls: ['./event-custom-field.component.scss']
})
export class EventCustomFieldComponent implements OnInit {

  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() deviceIsMobile: boolean = false;
  @Input() event: Event = null;
  @Input() isEdited: boolean;
  @Input() isMobileEventPreviewComponent: boolean = false;

  dateFormat: string;
  localisedFormat: string;
  customFieldType: typeof CustomEventFieldTypeValue = CustomEventFieldTypeValue;

  get customFields() {
    return this.parentFormGroup.controls["customFields"] as FormArray;
  }

  inputType: string = 'text';
  placeholderText: string = 'custom_event.custom_text_field.placeholder';

  constructor(
    private translateService: TranslateService,
    private customDatepickerI18nService: CustomDatepickerI18nService,
  ) { }

  ngOnInit() {
    this.getInputType();
  }

  getInputType(): void {
    switch (this.customField.fieldType.value) {
      case (CustomEventFieldTypeValue.STRING):
        this.inputType = this.customFieldType.STRING;
        this.placeholderText = 'custom_event.custom_text_field.placeholder';
        break;
      case (CustomEventFieldTypeValue.NUMBER):
        this.inputType = this.customFieldType.NUMBER;
        this.placeholderText = 'custom_event.custom_number_field.placeholder';
        break;
      case (CustomEventFieldTypeValue.SHORT_TEXT):
        this.inputType = this.customFieldType.SHORT_TEXT;
        this.placeholderText = 'custom_event.custom_text_field.placeholder';
        break;
      case (CustomEventFieldTypeValue.LONG_TEXT):
        this.inputType = this.customFieldType.LONG_TEXT;
        this.placeholderText = 'custom_event.custom_text_field.placeholder';
        break;
      case (CustomEventFieldTypeValue.DATE):
        this.inputType = this.customFieldType.DATE;
        this.dateFormat = this.customField.children["dateFormat"];
        this.localisedFormat = this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime(this.dateFormat);
        break;
      case (CustomEventFieldTypeValue.DROPDOWN):
        this.inputType = this.customFieldType.DROPDOWN;
        break;
      case (CustomEventFieldTypeValue.CURRENCY):
        this.inputType = this.customFieldType.CURRENCY;
        break;
      case (CustomEventFieldTypeValue.TIME):
        this.inputType = this.customFieldType.TIME;
        break;
      case (CustomEventFieldTypeValue.MULTIPLE_CHOICE):
        this.inputType = this.customFieldType.MULTIPLE_CHOICE;
        break;
      case (CustomEventFieldTypeValue.CHECKBOX):
        this.inputType = this.customFieldType.CHECKBOX;
        break;
      case (CustomEventFieldTypeValue.NUMERICAL_SCALE):
        this.inputType = this.customFieldType.NUMERICAL_SCALE;
        break;
    }
  }

  isCheckboxArrayEmpty(formGroup: FormGroup) {
    if(formGroup && formGroup.value && formGroup.value.value.length > 0) {
      return false
    }
    return true
  }

  getCustomFieldValue(formGroup: FormGroup): string {
    if(formGroup && formGroup.value && formGroup.value.value) {
      return formGroup.value.value;
    } else if(formGroup && formGroup.value && formGroup.value.value === null) {
      let translatedString = '';
      return translatedString;
    }
    else {
      return '';
    }
  }

  customFieldHasDifferentPreview(customField: CustomEventField): boolean {
    return customField.fieldType.value === CustomEventFieldTypeValue.DATE ||
    customField.fieldType.value === CustomEventFieldTypeValue.DROPDOWN ||
    customField.fieldType.value === CustomEventFieldTypeValue.CURRENCY ||
    customField.fieldType.value === CustomEventFieldTypeValue.TIME ||
    customField.fieldType.value === CustomEventFieldTypeValue.MULTIPLE_CHOICE ||
    customField.fieldType.value === CustomEventFieldTypeValue.CHECKBOX ||
    customField.fieldType.value === CustomEventFieldTypeValue.NUMERICAL_SCALE;
  }

  getDropdownValue(formGroup: FormGroup): string {
    if(formGroup && formGroup.value && formGroup.value.value) {
      let customEventOptions = this.customField.children.options;
      for (let i = 0; i < customEventOptions.length; i++) {
        if (customEventOptions[i].id === formGroup.value.value) {
          return customEventOptions[i].value;
        }
      }
      return formGroup.value.value;
    } else if(formGroup && formGroup.value && formGroup.value.value === null) {
      let translatedString = '';
      return translatedString;
    }
    else {
      return '';
    }
  }

  getCurrencySymbol(currencyType: string): string {
    switch (currencyType) {
      case (CustomCurrencies.AED):
        return 'AED';
      case (CustomCurrencies.AUD):
        return 'A$';
      case (CustomCurrencies.CAD):
        return 'C$';
      case (CustomCurrencies.EUR):
        return '€';
      case (CustomCurrencies.NZD):
        return 'NZ$';
      case (CustomCurrencies.GBP):
        return '£';
      case (CustomCurrencies.USD):
        return '$';
      case (CustomCurrencies.ZAR):
        return 'ZAR';
      default :
        return '£'
    }
  }

  getCustomFieldValueFromEvent(customField: CustomEventField): string | number {
    let customFieldValue = null;
    if(this.event) {
      this.event.customFieldValues.forEach((eventCustomField) => {
        if (eventCustomField.uuid === customField.id) {
          customFieldValue = eventCustomField.value;
        }
      });
    }
    if (customField.fieldType.value === CustomEventFieldTypeValue.DROPDOWN ||
        customField.fieldType.value === CustomEventFieldTypeValue.MULTIPLE_CHOICE) {
      let customEventOptions = this.customField.children.options;
      for (let i = 0; i < customEventOptions?.length; i++) {
        if (customEventOptions[i].id === customFieldValue) {
          return customEventOptions[i].value;
        }
      }
    }
    if (customField.fieldType.value === CustomEventFieldTypeValue.CHECKBOX) {
      let customEventOptions = this.customField.children.options;
      let optionValue: string[] = [];
      for (let i = 0; i < customFieldValue?.length; i++) {
        for ( let j = 0; j < customEventOptions.length; j++) {
          if (customEventOptions[j].id == customFieldValue[i]) {
            optionValue.push(customEventOptions[j].value);
          }
        }
      }
      let finalString = optionValue.join(", ")
      return finalString;
    }
    if(customFieldValue) {
      if (customField.fieldType.value === CustomEventFieldTypeValue.CURRENCY) {
        let currencySymbol = this.getCurrencySymbol(customField.children.currency);
        return currencySymbol + " " + customFieldValue;
      }
      return customFieldValue;
    } else {
      let translatedString = '';
      return translatedString;
    }
  }
}
