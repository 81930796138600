import { TaskIndicatorFilterValue, TaskStateFilterValue } from "./enums/task-state-indicator.enum";
import { RelatedMinimalEvent } from "./event";
import { LinkedTask } from "./linked-tasks";
import { ModelElement } from "./model-element";
import { SubsetTask } from "./subset-task";
export interface BackendTaskState {
  name: string,
  value: TaskStateFilterValue | TaskIndicatorFilterValue,
}

export class MinimalSubtask {
  id: string;
  parentTaskId: string;
  taskNumber: number;
  state: TaskIndicatorFilterValue | TaskStateFilterValue;
  title: string;
  locationId: string;
  locationName: string;
  startDatetime: Date;
  endDatetime: Date;
  plannedQuantity: number;
  totalQuantityDone: number;
  progress: number;

  public static BACKEND_TASK_STATE: BackendTaskState[] = [
    { name: 'tasks.list.planned', value: TaskStateFilterValue.PLANNED },
    { name: 'tasks.list.inprogress', value: TaskStateFilterValue.INPROGRESS },
    { name: 'tasks.list.completed', value: TaskStateFilterValue.COMPLETED },
    { name: 'tasks.list.cancelled', value: TaskStateFilterValue.CANCELLED },
    { name: 'tasks.list.readyToStart', value: TaskIndicatorFilterValue.READY_TO_START },
    { name: 'tasks.list.delayed', value: TaskIndicatorFilterValue.DELAYED },
    { name: 'tasks.list.overdue', value: TaskIndicatorFilterValue.OVERDUE },
    { name: 'tasks.list.pendingApproval', value: TaskIndicatorFilterValue.PENDING_APPROVAL },
  ];

  constructor() {
    this.id = ModelElement.generateId();
    this.parentTaskId = null;
    this.taskNumber = null;
    this.state = null;
    this.title = '';
    this.locationId = null;
    this.progress = 0;
    this.startDatetime = null;
    this.endDatetime = null;
    this.plannedQuantity = null;
    this.totalQuantityDone = null;
  }

  public static jsonToFilteredTasks(json: any): MinimalSubtask[] {
    return json.minimalSubtasks.map(minimalSubtaskObject => {
      const minimalSubtask: MinimalSubtask = new MinimalSubtask();
      minimalSubtask.id = minimalSubtaskObject.id;
      minimalSubtask.parentTaskId = json.id;
      minimalSubtask.taskNumber = minimalSubtaskObject.taskNumber;
      minimalSubtask.locationId = minimalSubtaskObject.locationId;
      minimalSubtask.state = minimalSubtaskObject.state;
      minimalSubtask.title = minimalSubtaskObject.title;
      minimalSubtask.progress = minimalSubtaskObject.progress;
      minimalSubtask.startDatetime = minimalSubtaskObject.startDatetime;
      minimalSubtask.endDatetime = minimalSubtaskObject.endDatetime;
      minimalSubtask.plannedQuantity = minimalSubtaskObject.plannedQuantity;
      minimalSubtask.totalQuantityDone = minimalSubtaskObject.totalQuantityDone;
      return minimalSubtask;
    });
  }

  public static convertSubsetTaskToMinimalSubtask(subsetTask: SubsetTask): MinimalSubtask {
    let totalQuantityDone = 0;
    if(subsetTask.plannedQuantity > 0) {
      totalQuantityDone = this.getSubsetTaskTotalQuantityDone(subsetTask);
      subsetTask.totalQuantityDone = Math.round(totalQuantityDone * 10) / 10;
    }
    let mostRecentEvent = this.getLastEventForSubsetTask(subsetTask);
    subsetTask.updateTaskProgressAndState(mostRecentEvent, totalQuantityDone);
    const minimalSubtask = new MinimalSubtask();
    minimalSubtask.id = subsetTask.id;
    minimalSubtask.parentTaskId = subsetTask.parentTaskId;
    minimalSubtask.taskNumber = subsetTask.taskNumber;
    minimalSubtask.locationId = subsetTask.locationId;
    minimalSubtask.state = LinkedTask.getTaskStateFromTask(subsetTask);
    minimalSubtask.title = subsetTask.title;
    minimalSubtask.progress = subsetTask.lastKnownProgress;
    minimalSubtask.startDatetime = subsetTask.startDatetime;
    minimalSubtask.endDatetime = subsetTask.endDatetime;
    minimalSubtask.plannedQuantity = subsetTask.plannedQuantity;
    minimalSubtask.totalQuantityDone = subsetTask.totalQuantityDone;
    return minimalSubtask;
  }

  public static getLastEventForSubsetTask(subsetTask: SubsetTask): RelatedMinimalEvent {
    let lastEvent: RelatedMinimalEvent = null;
    if(subsetTask.relatedMinimalEvents.length > 0) {
      subsetTask.relatedMinimalEvents.sort((a,b) => <any>a.startDatetime - <any>b.startDatetime);
      lastEvent = subsetTask.relatedMinimalEvents[subsetTask.relatedMinimalEvents.length -1];
    }
    return lastEvent;
  }

  public static getSubsetTaskTotalQuantityDone(task: SubsetTask): number {
    let totalQuantityDone = 0;
    task.relatedMinimalEvents.forEach((event) => {
      totalQuantityDone += event.quantityDone;
    });
    return totalQuantityDone;
  }
}
