import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  readonly root = document.documentElement;

  constructor() {
  }

  /**
   * Get CSS variable in javascript
   * @param variableName Name of the variable
   * @return Value of css variable --variableName
   */
  public get(variableName: string): string {
    return getComputedStyle(this.root)
      .getPropertyValue(`--${variableName}`);
  }
}
