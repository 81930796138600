import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FilteredTimelineTask } from '@models/filtered-timeline-tasks';
import { Task, taskNumberPrefix } from '@models/task';

@Pipe({
  name: 'taskNumber'
})
@Injectable({
  providedIn: 'root'
})
export class TaskNumberPipe implements PipeTransform {
  transform(task: Task | FilteredTimelineTask): string {
    if (task.taskNumber) {
      if (task.parentTaskId) {
        return taskNumberPrefix.SUBTASK_PREFIX + '-' + task.taskNumber.toString();
      }
      return taskNumberPrefix.TASK_PREFIX + '-' + task.taskNumber.toString();
    }
    else {
      return '';
    }
  }
}
