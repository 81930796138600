import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  HostListener,
  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonItemSliding, ModalController, IonSearchbar } from '@ionic/angular';
import { Attachment } from '@models/attachment';
import { Author } from '@models/author';
import { Contractor } from '@models/contractor';
import { Event, EventStatus } from '@models/event';
import { EventsFilters } from '@models/events-filters';
import { Location } from '@models/location';
import { NetworkStatus } from '@models/synchronization/network-status';
import { Tag } from '@models/tag';
import { Task, TaskState } from '@models/task';
import { MultiSelectItem } from '@models/utils/multi-select-item';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AttachmentService } from '@services/attachment.service';
import { ContractorService } from '@services/contractor.service';
import { DateManipulationService } from '@services/date-manipulation.service';
import { DeviceService } from '@services/device.service';
import { EventService } from '@services/event.service';
import { ExportService, SummaryType } from '@services/export.service';
import { FormatSortService } from '@services/format-sort.service';
import { CustomDatepickerI18nService, NgbDateParserFormatterI18N } from '@services/i18n/date-internationalization.service';
import { LocationService } from '@services/location.service';
import { Logger } from '@services/logger';
import { PageTitleService } from '@services/page-title.service';
import { SpinnerService } from '@services/spinner.service';
import { SyncStatusService } from '@services/synchronization/sync-status.service';
import { SyncService } from '@services/synchronization/sync.service';
import { TabTitleService } from '@services/tab-title.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UserRole } from '@services/user-rights/user-rights-dao.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { UserService } from '@services/user.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UiWebDatetimePickerComponent } from 'app/core/ui/components/web';
import { UiDatetimePickerService } from 'app/core/ui/services';
import { FabButtonService } from 'app/shared/components/fab-button/fab-button.service';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ExportSelectorPopupComponent } from '../../export-selector-popup/export-selector-popup.component';
import { FiltersSelectorPopupComponent } from '../../filters-selector-popup/filters-selector-popup.component';
import { PdfExportConfigurationPopupComponent } from './pdf-export-configuration-popup/pdf-export-configuration-popup.component';
import { SummaryConfigurationPopupWebappComponent } from './summary-configuration-popup/summary-configuration-popup-webapp/summary-configuration-popup-webapp.component';
import { IntercomService } from '@services/intercom.service';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { FilterEvents } from '../../../../pipes/filter-events.pipe';
import { ScreenManipulationService } from '@services/screen-manipulation.service';
import { NGXLogger } from 'ngx-logger';
import { MenuService } from '@services/menu.service';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { AppRateSettingsService } from '@services/app-rate-settings.service';
import { SiteService } from '@services/site.service';
import { SharedDataService } from '@services/shared-data.service';
import { CustomEventFormCreatorMobileComponent } from 'app/home/space/custom-event-form-creator/custom-event-form-creator-mobile.component';
import { Site } from '@models/site';
import { PostStatusService, PostStatusStates } from '@services/post-status.service';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { EventSearchFilterService, TaskFilterModel, EventFilterParameters } from '@services/event-search-filter.service';
import { FilteredEvent } from '@models/filtered-event';
import { SERVER_ERROR_MESSAGES } from '@models/error-messages';
import { AuthService } from '@services/auth/auth.service';
import { SessionService } from '@services/session.service';
import { ArchiveSitesService } from '@services/archive-sites.service';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';
import { ModelElement } from '@models/model-element';

const MAX_FILTER_DATE_RANGE_IN_MONTHS = 12;
const EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS = 6;
@Component({
  selector: 'app-event-list-webapp',
  templateUrl: './event-list-webapp.component.html',
  styleUrls: ['./event-list-webapp.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterI18N }
  ]
})
export class EventListWebappComponent extends AutoUnsubscribeComponent implements OnInit, OnDestroy {

  //#region Task to filter
  @Input()
  set taskToFilter(idTaskToFilter: string | null) {
    if (idTaskToFilter) {
      this.taskService.getOnceById(idTaskToFilter)
        .then(task => {
          this.initializeFilters();
          this.eventsFilters.selectedTasks.push(task);
          this.eventsFilters.dateFrom = this.dateManipulationService.getXDaysAgo(task.startDatetime, 60);
          this.selectedTaskItems = [task];
          this.search();
        });
    }
  }

  readonly UserRole = UserRole;
  readonly EventStatus = EventStatus;

  /**
   * Filter Contractor list
   */
  contractors: Contractor[] = [];

  /**
   * Filter Tag list
   */
  tags: Tag[] = [];

  /**
   * Filter Location list
   */
  locations: Location[] = [];

  /**
   * Filter Task list
   */
  tasks: TaskFilterModel[] = [];

  /**
   * Filter Creator list
   */
  creators: Author[] = [];

  readonly localizedFormat: string;

  /**
   * NG Multiselect Contractors settings
   */
  multiselectContractorSettings = {};

  /**
   * NG Multiselect Tags settings
   */
  multiselectTagSettings = {};

  /**
   * NG Multiselect Status settings
   */
  multiselectStatusSettings = {};

  /**
   * NG Multiselect Creators settings
   */
  multiselectCreatorSettings = {};

  /**
  * NG Multiselect Tasks settings
  */
  multiselectTaskSettings = {};

  /**
   * NG Multiselect Location settings
   */
  multiselectLocationSettings = {};

  /**
   * NG Multiselect selected contractors
   */
  _selectedContractorItems: MultiSelectItem[];

  /**
   * NG Multiselect selected tags
   */
  _selectedTagItems: MultiSelectItem[];

  /**
   * NG Multiselect selected status
   */
  _selectedStatusItems: MultiSelectItem[];

  /**
   * NG Multiselect selected creators
   */
  _selectedCreatorItems: MultiSelectItem[];

  /**
   * NG Multiselect selected tasks
   */
  _selectedTaskItems: Task[];

  /**
   * NG Multiselect selected locations
   */
  _selectedLocationItem: MultiSelectItem[];

  // @TODO: Refactor Ngx datatable component => unmaintainable
  /**
   * NGX datatable component
   */
  @ViewChild(DatatableComponent) table: DatatableComponent;

  /**
   * NGX datatable search input
   */
  @ViewChild('searchInput') searchInput: IonSearchbar;

  /**
   * NGX datatable event list
   */
  events: Event[] = [];

  /**
   * NGX datatable filtered event list to display
   */
   filteredEvents: FilteredEvent[] = [];

   /**
   * Filtered event list thats updated and used to display the events only when input changes in the searchbar
   */
   searchableFilteredEvents: Event[] = [];

  /**
   * NGX datatable item per page value
   */
  itemPerPage: number;

  /**
   * NGX datatable scrollable test
   */
  canScrollDatatable: boolean;

  /**
   * NGX datatable custom compare string
   */
  readonly customCompareString = FormatSortService.sortText;

  /**
   * NGX datatable custom compare author
   */
  readonly customCompareAuthor = FormatSortService.sortAuthor;

  /**
   * NGX datatable dataloading state
   */
  dataLoading: boolean = true;

  /**
   * Events filters
   */
  eventsFilters: EventsFilters;

  /**
   * Events status (pending, approved, rejected)
   */
  status: TaskState[] = [];

  /**
   * Array containing the last 7 days of the week
   */
  readonly lastSevenDays: moment.Moment[];

  /**
   * Store last date fetched when fetching earlier events
   */
  lastDateFrom: Date;

  /**
   * Boolean to check if user has already fetched earlier events
   */
  hasFetchEarlierEvents: boolean;

  /**
   * Boolean to check state of swipe icon on mobile (arrow up or down on swipe)
   */
  swipeIconDown: boolean;

  /**
   * Array containing today and the previous day
   */
  readonly lastTwoDays: moment.Moment[];

  numberOfFaultyItems = 0;

  shownEventsLength: number = 0;
  areAllEventsShown: boolean = false;
  canCreateEvent: boolean = false;
  userHasAppAccess: boolean = false;
  showLimitedSearchBanner: boolean = false;
  showCompleteEvents: boolean = true;
  isArchivedSite: boolean = false;

  readonly isOnline = NetworkStatus.watchIsOnline();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScrollHorizontal();
  }

  constructor(
    private eventService: EventService,
    private router: Router,
    private contractorService: ContractorService,
    private locationService: LocationService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private toasterService: ToasterService,
    public translate: TranslateService,
    public deviceService: DeviceService,
    public dateManipulationService: DateManipulationService,
    private alertController: AlertController,
    private pageTitleService: PageTitleService,
    private tabTitleService: TabTitleService,
    private tagService: TagService,
    public userRightsService: UserRightsService,
    private activatedRoute: ActivatedRoute,
    public modalController: ModalController,
    private attachmentService: AttachmentService,
    private spinnerService: SpinnerService,
    private datetimePickerService: UiDatetimePickerService,
    private dialog: MatDialog,
    private customDatepickerI18nService: CustomDatepickerI18nService,
    private exportService: ExportService,
    private modalService: NgbModal,
    private taskService: TaskService,
    private syncStatusService: SyncStatusService,
    private fabButtonService: FabButtonService,
    private syncService: SyncService,
    private intercomService: IntercomService,
    private filterEventsPipe: FilterEvents,
    private screenManipulationService: ScreenManipulationService,
    private logger: NGXLogger,
    private menuService: MenuService,
    private siteService: SiteService,
    private postStatusService: PostStatusService,
    private userAppAccessService: UserAppAccessService,
    private sharedDataService: SharedDataService,
    private eventFilterService: EventSearchFilterService,
    private authService: AuthService,
    private sessionService: SessionService,
    private archiveSitesService: ArchiveSitesService,
    private translateService: TranslateService
  ) {
    super();
    this.localizedFormat = this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime();
    this.initializeFilters();
  }

  ngOnInit() {
    this.checkFor2FASessionAuthentication();
    this.authService.display2FAModals();

    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.sharedDataService.callSubsetAPI(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
      if(this.sharedDataService.isSubsetApiAlreadyCalled()) {
        this.spinnerService.deactivate();
      }
      this.fetchFilterData();
    });

    this.userAppAccessService.watchCurrentAppAccess().subscribe((userAppAccess) => {
      this.userHasAppAccess = this.userAppAccessService.userHasCorrectAppAccess();
    });

    // watch if current site is archived
    this.sharedDataService.watchSite.subscribe((site) => {
      if(site) {
        this.isArchivedSite = site.isArchived;
      }
    });

    this.menuService.updateMenuContent();
    this.fabButtonService.hide();

    if (this.sharedDataService.canSyncSpinnerActivate) {
      this.spinnerService.activate('rotating', this.translate.instant('sync.loading-data'));
      this.sharedDataService.setCanSyncSpinnerActivate(false);
    }
    this.fetchAndPopulateEvents();

    // Set the current title
    this.pageTitleService.emitNewPageTitle('menu.events');
    this.tabTitleService.updateTabTitle('menu.events');

    this.sharedDataService.watchCollapseSidebar.pipe(
      takeUntil(this.destroy),
    ).subscribe(() => {
      this.recalculateTable();
    });

    this.status[0] = { name: 'events.list.draft', id: EventStatus.DRAFT, active: true, color: 'warning', itemName: null };
    this.status[1] = { name: 'events.list.pendingApproval', id: EventStatus.SUBMITTED, active: true, color: 'primary', itemName: null };
    this.status[2] = { name: 'events.list.approved', id: EventStatus.APPROVED, active: true, color: 'light-green', itemName: null };
    this.status[3] = { name: 'events.list.rejected', id: EventStatus.REJECTED, active: true, color: 'danger', itemName: null };
    this.status.forEach(s => this.translate.get(s.name).subscribe(translation => s.itemName = translation));

    let multiselectSettings: any = {
      classes: 'angular2-multiselect',
      badgeShowLimit: 2,
      labelKey: 'name',
      primaryKey: 'id',
      enableSearchFilter: true,
      searchBy: ['name'],
    };

    this.translate.get(['filter.contractors', 'filter.tags', 'filter.status', 'filter.creators', 'filter.locations',
      'filter.select_all', 'filter.unselect_all', 'filter.tasks',
      'filter.search', 'filter.no_results', 'filter.select_all_filtered', 'filter.unselect_all_filtered'])
      .pipe(
        takeUntil(this.destroy),
      ).subscribe(translations => {
        multiselectSettings = {
          ...multiselectSettings,
          selectAllText: translations['filter.select_all'],
          unSelectAllText: translations['filter.unselect_all'],
          searchPlaceholderText: translations['filter.search'],
          noDataLabel: translations['filter.no_results'],
          filterSelectAllText: translations['filter.select_all_filtered'],
          filterUnSelectAllText: translations['filter.unselect_all_filtered'],
        };
        this.multiselectContractorSettings = {
          ...multiselectSettings,
          text: translations['filter.contractors'],
        };
        this.multiselectTagSettings = {
          ...multiselectSettings,
          text: translations['filter.tags'],
        };
        this.multiselectTaskSettings = {
          ...multiselectSettings,
          text: translations['filter.tasks'],
          labelKey: 'title',
          searchBy: ['title'],
        };
        this.multiselectStatusSettings = {
          ...multiselectSettings,
          text: translations['filter.status'],
          labelKey: 'itemName',
          enableSearchFilter: false,
          enableCheckAll: false,
        };
        this.multiselectCreatorSettings = {
          ...multiselectSettings,
          text: translations['filter.creators'],
          labelKey: 'name',
        };
        this.multiselectLocationSettings = {
          ...multiselectSettings,
          text: translations['filter.locations'],
          labelKey: 'name',
        };
      });

    // When switching site, reset last date from to 7 days ago
    this.lastDateFrom = moment(new Date()).startOf('day').subtract(30, 'days').toDate();

    // NGX datatable default paramter values
    this.itemPerPage = 15;
    this.checkScrollHorizontal();

    // When you refresh the browser the user rights take some time to be received from the indexedDB so we have to wait for them to know if we can show the button
    this.userRightsService.watchHasRight(this.userRightsService.USER_RIGHTS.site.event.create).pipe(
      takeUntil(this.destroy),
    ).subscribe(hasEventCreationRights => {
      if (hasEventCreationRights) {
        this.fabButtonService.fabClickedSubject.pipe(
          takeUntil(this.destroy),
          filter(state => state)
        ).subscribe(state => {
          this.fabButtonService.reset();
          this.goToNew();
        });
      }
    });

    this.userRightsService.watchCurrentRoles().subscribe(userRoles => {
      this.canCreateEvent = this.userRightsService.userHasRight(userRoles, UserRightsService.USER_RIGHTS.site.event.create);
    });
  }

  async fetchFilterData(): Promise<void> {
    this.sharedDataService.watchSiteTags.pipe(
      takeUntil(this.destroy),
    ).subscribe(tags => this.tags = tags);
    this.sharedDataService.watchSiteLocations.pipe(
      takeUntil(this.destroy),
    ).subscribe(locations => this.locations = locations);
    this.sharedDataService.watchSiteContractors.pipe(
      takeUntil(this.destroy),
    ).subscribe(contractors => this.contractors = contractors);
    this.tasks = await this.eventFilterService.getTasks();
    this.creators = await this.eventFilterService.getCreators();
  }

  fetchAndPopulateEvents(): void {
    this.sharedDataService.watchSiteEvents.pipe(
      takeUntil(this.destroy),
    ).subscribe((events) => {
      if (events !== undefined && events !== null) {
        events.sort(((a, b) => <any>b.startDatetime - <any>a.startDatetime));
        events.forEach((event) => {
          if (event.tags.length > 0) {
            event.tags.forEach(async (tag) => {
              const newTag = this.sharedDataService.getSiteTagById(tag.tagId);
              if (newTag) {
                tag.name = newTag.name;
              }
            });
          }
          if (event.contractors.length > 0) {
            event.contractors.forEach(async (contractor) => {
              const newContractor = this.sharedDataService.getSiteContractorById(contractor.contractorId);
              if (newContractor) {
                contractor.name = newContractor.name;
              }
            });
          }
          if (event.locationObject) {
            const newLocation = this.sharedDataService.getSiteLocationById(event.locationObject.id);
            if (newLocation) {
              event.locationObject.name = newLocation.name;
            }
          }
        });

        this.events = [...events];
        this.dataLoading = false;

        this.swipeIconDown = false;
        this.hasFetchEarlierEvents = true;
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.fabButtonService.hide();
  }

  // Method Triggered on activation of a row (ex. click on an event)
  onActivateRow($event) {
    if ($event.type === 'click') {
      // Navigate to event
      this.router.navigate(['../' + $event.row.id], { relativeTo: this.activatedRoute });
    }
  }

  onActivateFilteredEventRow($event) {
    if ($event.type === 'click') {
      // Navigate to filtered event
      this.router.navigate(['../' + $event.row.id], { relativeTo: this.activatedRoute, queryParams: {isFilteredEvent: "true"}});
    }
  }

  swapSearchBar(): void {
    // Very hack-ish way to swap search bar and "Select all"
    const searchbars = document.querySelectorAll('angular2-multiselect .list-area .list-filter');
    for (let i = 0; i < searchbars.length; i++) {
      const searchbar = searchbars[i];
      searchbar.parentNode.insertBefore(searchbar, searchbar.parentNode.firstChild);
    }
  }

  /**
   *  Getter/setters to watch selected items
   */

  get selectedContractorItems(): MultiSelectItem[] {
    return this._selectedContractorItems;
  }
  set selectedContractorItems(value: MultiSelectItem[]) {
    this._selectedContractorItems = value;
    this.eventsFilters.selectedContractors = this.contractors.filter(
      contractor => value.some(selectedContractor => contractor.id === selectedContractor.id)
    );
  }
  get selectedTagItems(): MultiSelectItem[] {
    return this._selectedTagItems;
  }
  set selectedTagItems(value: MultiSelectItem[]) {
    this._selectedTagItems = value;
    this.eventsFilters.selectedTags = this.tags.filter(tag => value.some(selectedTag => tag.id === selectedTag.id));
  }
  get selectedStatusItems(): MultiSelectItem[] {
    return this._selectedStatusItems;
  }
  set selectedStatusItems(value: MultiSelectItem[]) {
    this._selectedStatusItems = value;
    this.eventsFilters.selectedStatus = this.status.filter(status => value.some(selectedStatus => status.id === selectedStatus.id)).map(status => '' + status.id);
  }
  get selectedCreatorItems(): MultiSelectItem[] {
    return this._selectedCreatorItems;
  }
  set selectedCreatorItems(value: MultiSelectItem[]) {
    this._selectedCreatorItems = value;
    this.eventsFilters.selectedCreators = this.creators.filter(creator => value.some(selectedCreator => creator.id === selectedCreator.id));
  }
  get selectedTaskItems(): Task[] {
    return this._selectedTaskItems;
  }
  set selectedTaskItems(value: Task[]) {
    this._selectedTaskItems = value;
    this.eventsFilters.selectedTasks = this.tasks.filter(task => value.some(selectedTask => task.id === selectedTask.id));
  }
  get selectedLocationItem(): MultiSelectItem[] {
    return this._selectedLocationItem;
  }
  set selectedLocationItem(value: MultiSelectItem[]) {
    this._selectedLocationItem = value;
    this.eventsFilters.selectedLocations = this.locations.filter(location => value.some(selectedLocation =>
      location.id === selectedLocation.id));
  }

  async updateEventsFilter(): Promise<void> {
    const areFiltersValid = this.validateEventFilterParameters();
    if(!areFiltersValid) {
      // Filters are not valid
      this.toasterService.showWarningToaster('filter.invalid_filter_parameter');
      return;
    }
    const eventFilterParameters = this.getEventFilterParameters();
    if(Object.keys(eventFilterParameters).length === 0) {
      this.toasterService.showWarningToaster('filter.no_parameters_provided');
      return;
    }
    this.dataLoading = true;
    this.spinnerService.activate('pulsating');
    let filteredEvents = await this.eventFilterService.callSearchApi(eventFilterParameters);
    filteredEvents = this.getDataForFilteredEvents(filteredEvents);
    this.showCompleteEvents = false;
    this.dataLoading = false;
    if(filteredEvents) {
      this.filteredEvents = [...filteredEvents];
      this.spinnerService.deactivate();
    } else {
      this.filteredEvents = [];
      this.spinnerService.deactivate();
    }
  }

  getDataForFilteredEvents(filteredEvents: FilteredEvent[]): FilteredEvent[] {
    filteredEvents.forEach((filteredEvent) => {
      if (filteredEvent.tags.length > 0) {
        filteredEvent.tags.forEach((tag) => {
          const newTag = this.sharedDataService.getSiteTagById(tag.tagId);
          if (newTag) {
            tag.name = newTag.name;
          }
        });
      }
      if (filteredEvent.contractors.length > 0) {
        filteredEvent.contractors.forEach((contractor) => {
          const newContractor = this.sharedDataService.getSiteContractorById(contractor.contractorId);
          if (newContractor) {
            contractor.name = newContractor.name;
          }
        });
      }
      if (filteredEvent.locationId) {
        const newLocation = this.sharedDataService.getSiteLocationById(filteredEvent.locationId);
        if (newLocation) {
          filteredEvent.locationObject = newLocation;
        }
      }
    });
    return filteredEvents;
  }

  validateEventFilterParameters(): boolean {
    if((this.searchInput.value.trim()) && this.searchInput.value.length <= 2) {
      return false;
    }
    if(this.eventsFilters.dateFrom && this.eventsFilters.dateTo) {
      const startDate = moment(this.eventsFilters.dateFrom);
      const endDate = moment(this.eventsFilters.dateTo);
      const differenceInMonths = endDate.diff(startDate, 'months');
      if(differenceInMonths > MAX_FILTER_DATE_RANGE_IN_MONTHS) {
        return false;
      }
    }
    return true;
  }

  getEventFilterParameters(): EventFilterParameters {
    const eventFilterParameters: EventFilterParameters = {};
    if(this.searchInput.value.trim()) {
      eventFilterParameters.searchKeyword = this.searchInput.value.trim();
    }
    if(this.eventsFilters.dateFrom) {
      eventFilterParameters.startDatetime = this.eventsFilters.dateFrom;
    }
    if(this.eventsFilters.dateTo) {
      eventFilterParameters.endDatetime = this.eventsFilters.dateTo;
    }
    if(this.eventsFilters.selectedContractors.length > 0) {
      eventFilterParameters.contractors = this.getEventFilterParameterIds(this.eventsFilters.selectedContractors);
    }
    if(this.eventsFilters.selectedCreators.length > 0) {
      eventFilterParameters.creators = this.getEventFilterParameterIds(this.eventsFilters.selectedCreators);
    }
    if(this.eventsFilters.selectedLocations.length > 0) {
      eventFilterParameters.locations = this.getEventFilterParameterIds(this.eventsFilters.selectedLocations);
    }
    if(this.eventsFilters.selectedTags.length > 0) {
      eventFilterParameters.tags = this.getEventFilterParameterIds(this.eventsFilters.selectedTags);
    }
    if(this.eventsFilters.selectedTasks.length > 0) {
      eventFilterParameters.tasks = this.getEventFilterParameterIds(this.eventsFilters.selectedTasks);
    }
    if(this.eventsFilters.selectedStatus.length > 0) {
      eventFilterParameters.states = this.eventsFilters.selectedStatus;
    }
    return eventFilterParameters;
  }

  getEventFilterParameterIds(filter: Contractor[] | Author[] | Location[] | Tag[] | Task[] | TaskFilterModel[]): string[] {
    const ids = [];
    filter.forEach((_filter) => {
      ids.push(_filter.id)
    });
    return ids;
  }

  private warnOfflineUser(): void {
    this.toasterService.showWarningToaster('events.export.offline');
  }

  updateFiltersFromDatepicker(type: string): void {
    const date = type === 'endDatetime' ? this.datetimePickerService.endDatetime : this.datetimePickerService.startDatetime;
    if (type === 'startDatetime') {
      this.triggerDateSearch('from', date);
    } else {
      this.triggerDateSearch('to', date);
    }
  }

  async openDatePicker(type: 'startDatetime' | 'endDatetime') {
    this.datetimePickerService.type = type;
    this.datetimePickerService.hideTime = true;
    const dialogRef = this.dialog.open(UiWebDatetimePickerComponent, {
      width: '350px',
    });
    dialogRef.componentInstance.customSaveAction = () => this.updateFiltersFromDatepicker(type);
  }

  /**
   * get Events with filters
   */
  search(): void {
    this.updateEventsFilter();
    // Reset search input
    if (this.searchInput) {
      this.searchInput.value = '';
    }
  }

  /**
   * Method triggered when from date or to date is changed in ngbDatepicker
   */
  triggerDateSearch(type: string, date: Date): void {
    if (type === 'from') {
      this.eventsFilters.dateFrom = date;
    } else {
      this.eventsFilters.dateTo = date;
    }
  }

  /** DATA PROCESSING FUNCTIONS ********************************/
  getStatus(index): TaskState {
    return this.status.find(s => s.id === index);
  }

  /*********************************************************/

  /** NGX DATATABLE FUNCTIONS ********************************/
  /**
   * Function listening to item per page changes in app-footer component
   * @param nb Number of item per page to show -> given by child component app-footer
   */
  receiveItemPerPage(nb: number): void {
    this.itemPerPage = nb;
    if (this.table) {
      this.table.offset = 0;
    }
  }

  /**
  * Check if datatable should be responsive => scroll bar horizontal should appear
  */
  checkScrollHorizontal(): void {
    this.canScrollDatatable = this.breakpointObserver.isMatched('(max-width: 1350px)');
  }

  /**
  * Method to recalculate size of datatable columns (used when available space changes, ex: collapse of sidebar )
  */
  recalculateTable(): void {
    this.table.recalculate();
    this.events = [...this.events];
  }

  /**
   * Function to search events based on value in search input
   */
  updateSearchFilter(): void {
    // filter events depending of search input value
    this.updateEventsFilter();
    // go back to the first page
    if (this.table) {
      this.table.offset = 0;
    }
  }
  /*********************************************************/

  invalidDatesInFilter(): boolean {
    return this.eventsFilters.dateFrom && this.eventsFilters.dateTo && this.eventsFilters.dateFrom.getTime() > this.eventsFilters.dateTo.getTime();
  }

  /**
   * Reset all filters and launch a search
   */
  initializeFilters(): void {
    this.eventsFilters = new EventsFilters(
      null,
      null,
      [],
      [],
      [],
      [],
      [],
      [],
    );
    this.datetimePickerService.startDatetime = this.eventsFilters.dateFrom;
    this.datetimePickerService.endDatetime = this.eventsFilters.dateTo;
  }

  /***************************************************/

  /**
   * Open PDF modal to configure PDF summary generation
   */
  openPdfSummaryModal(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_SUMMARY').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        const dialogRef = this.dialog.open(
          SummaryConfigurationPopupWebappComponent,
          { width: '600px', },
        );
        const summaryConfigurationPopupWebappComponent: SummaryConfigurationPopupWebappComponent = dialogRef.componentInstance;
        summaryConfigurationPopupWebappComponent.setCallbacks(
          (type, fromDate, title, toDate) => {
            this.generateSummaryPdf(type, fromDate, title, toDate);
          },
          () => {
            dialogRef.close();
          }
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  maximumReportsDownloading() {
    if (this.exportService.exportingReport.length >= 3) {
      return true;
    }
    return false
  }

  /**
   * Download PDF Daily Summary
   */
  generateSummaryPdf(type: SummaryType, fromDate: Date, title: string, toDate: Date): void {
    const document = {
      id: ModelElement.generateId(),
      'title': title ? title + '.pdf' : 'Site_Diary_Report_' + this.getDateForPlaceholder() + '.pdf'
    }
    this.exportService.exportingReport.push(document)
    this.exportService.updateQueue();
    this.exportService.isReportGenerating(document).subscribe((result) => {
      if (result) {
        this.exportService.isReportDownloading = true;
        this.exportService.fetchAndSaveEventSummaryPDF(type, fromDate, title, toDate).pipe(
        ).subscribe(
          () => {
            this.toasterService.showSuccessToaster('events.report.success.download');
            this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
            if (this.exportService.exportingReport.length === 0) {
              this.exportService.isReportDownloading = false;
            }
          },
          error => {
            this.logger.error('Error while downloading Event daily summary PDF', error);
            this.toasterService.showWarningToaster('events.report.error.download');
            this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
            if (this.exportService.exportingReport.length === 0) {
              this.exportService.isReportDownloading = false;
            }
          },
        );
      }
    })
  }

  /**
   * Open PDF modal to configure PDF generation
   */
  openPdfReportModal(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_PDF').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        let includedEvents = (this.table.rows as Event[]).map(event => event.id);
        if (includedEvents.length > 0) {
          if (this.showCompleteEvents || (this.eventsFilters.dateFrom && this.eventsFilters.dateTo)) {
            if (this.showCompleteEvents || this.exportService.checkPdfReportDuration(this.eventsFilters.dateFrom, this.eventsFilters.dateTo)) {
              const modalRef = this.modalService.open(
                PdfExportConfigurationPopupComponent,
                { centered: true },
              );
              const pdfExportConfigurationComponent: PdfExportConfigurationPopupComponent = modalRef.componentInstance;
              pdfExportConfigurationComponent.setCallbacks(
                result => {
                  modalRef.close(result);
                  this.getPDFReport(result, includedEvents);
                },
                () => {
                  modalRef.dismiss();
                }
              );
            }
          }
          else {
            this.toasterService.showWarningToaster("filter.dates.not.provided");
          }
        }
        else {
          this.toasterService.showWarningToaster("events.report.error.no.included.events");
        }
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Download the PDF file generated by REST API with filters
   */
  getPDFReport(title: string, includedEvents: string[]): void {
    const document = {
      'id': ModelElement.generateId(),
      'title': title ? title + '.pdf' : 'Site_Diary_Report_' + this.getDateForPlaceholder() + '.pdf'
    }
    this.exportService.exportingReport.push(document)
    this.exportService.updateQueue();
    this.exportService.isReportGenerating(document).subscribe((result) => {
      if (result) {
        this.exportService.isReportDownloading = true;
        this.exportService.fetchAndSavePdfReport(
          this.eventsFilters.dateFrom,
          this.eventsFilters.dateTo,
          includedEvents,
          title,
        ).pipe(
        ).subscribe(
          () => {
            this.toasterService.showSuccessToaster('events.report.success.download');
            this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
            if (this.exportService.exportingReport.length === 0) {
              this.exportService.isReportDownloading = false;
            }
          },
          error => {
            this.logger.error('Error while downloading Event PDF report', error);
            this.toasterService.showWarningToaster('events.report.error.download');
            this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
            if (this.exportService.exportingReport.length === 0) {
              this.exportService.isReportDownloading = false;
            }
          },
        );
      }
    })
  }

  /**
   * Download the CSV file generated by REST API with filters
   */
  getCsvReport(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_CSV').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        let includedEvents = (this.table.rows as Event[]).map(event => event.id);
        if (includedEvents.length > 0) {
          if (this.showCompleteEvents || (this.eventsFilters.dateFrom && this.eventsFilters.dateTo)) {
            if (this.showCompleteEvents || this.exportService.checkExcelOrCsvReportDuration(this.eventsFilters.dateFrom, this.eventsFilters.dateTo)) {
              const document = {
                'id': ModelElement.generateId(),
                'title': 'Site_Diary_Report_' + this.getDateForPlaceholder() + '.csv'
              }
              this.exportService.exportingReport.push(document)
              this.exportService.updateQueue();
              this.exportService.isReportGenerating(document).subscribe((result) => {
                if (result) {
                  this.exportService.isReportDownloading = true;
                  this.exportService.fetchAndSaveCsvReport(
                    includedEvents,
                  ).pipe(
                  ).subscribe(
                    () => {
                      this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
                      if (this.exportService.exportingReport.length === 0) {
                        this.exportService.isReportDownloading = false;
                      }
                    },
                    async error => {
                      let errorText = await  (error.error as Blob).text();
                      error = JSON.parse(errorText);
                      if (error.message === SERVER_ERROR_MESSAGES.EMPTY_LIST_FOR_INCLUDED_EVENTS) {
                        this.toasterService.showWarningToaster('events.report.error.no.included.events');
                      }
                      else {
                        this.logger.error("Error while downloading CSV file", error);
                        this.toasterService.showErrorToaster('errors.ajax.error_file');
                      }
                      this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
                      if (this.exportService.exportingReport.length === 0) {
                        this.exportService.isReportDownloading = false;
                      }
                    },
                  );
                }
              })
            }
          }
          else {
            this.toasterService.showWarningToaster("filter.dates.not.provided");
          }
        }
        else {
          this.toasterService.showWarningToaster("events.report.error.no.included.events");
        }
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }

  }

  /**
   * Download the XLS file generated by REST API with filters
   */
  getXlsReport(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_XLS').subscribe(() => {
        let includedEvents = (this.table.rows as Event[]).map(event => event.id);
        if (includedEvents.length > 0) {
          if (this.showCompleteEvents || (this.eventsFilters.dateFrom && this.eventsFilters.dateTo)) {
            if (this.showCompleteEvents || this.exportService.checkExcelOrCsvReportDuration(this.eventsFilters.dateFrom, this.eventsFilters.dateTo)) {
              const document = {
                'id': ModelElement.generateId(),
                'title': 'Site_Diary_Report_' + this.getDateForPlaceholder() + '.xls'
              }
              this.exportService.exportingReport.push(document)
              this.exportService.updateQueue();
              this.exportService.isReportGenerating(document).subscribe((result) => {
                if (result) {
                  this.exportService.isReportDownloading = true;
                  this.exportService.fetchAndSaveXlsReport(
                    this.eventsFilters.dateFrom,
                    this.eventsFilters.dateTo,
                    includedEvents,
                  ).pipe(
                  ).subscribe(
                    () => {
                      this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
                      if (this.exportService.exportingReport.length === 0) {
                        this.exportService.isReportDownloading = false;
                      }
                    },
                    async error => {
                      let errorText = await  (error.error as Blob).text();
                      error = JSON.parse(errorText);
                      if (error.message === SERVER_ERROR_MESSAGES.TOO_BROAD_FILTERS) {
                        this.toasterService.showWarningToaster("events.report.error.too.broad.filters");
                      }
                      else if (error.message === SERVER_ERROR_MESSAGES.DURATION_TOO_LONG_FOR_FROM_DATE) {
                        this.toasterService.showWarningToaster("events.report.error.duration", { months: EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS });
                      }
                      else if (error.message === SERVER_ERROR_MESSAGES.DURATION_TOO_LONG_BETWEEN_FROM_AND_TO_DATES) {
                        this.toasterService.showWarningToaster("events.report.error.duration.long.between.from_and_to.dates", { months: EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS });
                      }
                      else {
                        this.logger.error("Error while downloading XLS file", error);
                      }
                      this.exportService.exportingReport.splice(this.exportService.exportingReport.indexOf(document), 1)
                      if (this.exportService.exportingReport.length === 0) {
                        this.exportService.isReportDownloading = false;
                      }
                    },
                  );
                }
              })
            }
          }
          else {
            this.toasterService.showWarningToaster("filter.dates.not.provided");
          }
        }
        else {
          this.toasterService.showWarningToaster("events.report.error.no.included.events");
        }
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  getDateForPlaceholder(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return year + "_" + month + "_" + day;
  }


  getThumbnailUrl(attachment: Attachment, eventId: string): string {
    return this.attachmentService.getPictureUrl(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId, eventId, attachment.id, true);
  }


  goToNew() {
    this.intercomService.trackUserAction(IntercomUserActions.StartingCreatingEvent);
    this.router.navigate(['../new'], { relativeTo: this.activatedRoute });
  }

  createEvent(): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      this.fabButtonService.click();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  async forceRefresh(): Promise<void> {
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.sharedDataService.callSubsetAPI(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
      this.fetchFilterData();
    });
  }

  applyAllFilters() {
    this.updateEventsFilter();
  }

  clearAllFilters() {
    this.initializeFilters();
    this.filteredEvents = [];
    this.searchInput.value = '';
    this.selectedTagItems = [];
    this.selectedTaskItems = [];
    this.selectedContractorItems = [];
    this.selectedCreatorItems = [];
    this.selectedLocationItem = [];
    this.selectedStatusItems = [];
    this.showCompleteEvents = true;
  }

  matchesSearchText(event: Event, searchText: string): boolean {
    const formattedSearchText = FormatSortService.formatText(searchText);
    return !searchText
      || (event.title && this.textMatches(event.title, formattedSearchText))
      || (event.createdBy && this.textMatches(event.createdBy.name, formattedSearchText));
  }

  textMatches(completeText: string, searchedText: string): boolean {
    return FormatSortService.formatText(completeText).includes(FormatSortService.formatText(searchedText));
  }


  // check if error occurs in adding events
  fetchFaultyItemsCountOnError(): void {
      this.postStatusService.getPostStatus().subscribe(async val => {
        if (val.status === PostStatusStates.error) {
          this.fetchFaultyItemsCount();
        }
      })
  }

  async fetchFaultyItemsCount(): Promise<void> {
    this.numberOfFaultyItems = await this.eventService.fetchFaultyItemsCount();
  }

  hasFaultyItems(): boolean {
    if (this.numberOfFaultyItems > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  routeToFaultyItems(): void {
    this.router.navigate(['space', this.sharedDataService.currentSpaceId, 'site', this.sharedDataService.currentSiteId, 'faultyItems']);
  }

  getFilterValidationErrorText(): string[] {
    let filterValidationErrors : string[] = [];
    if(this.eventsFilters.selectedContractors.length > 20) {
      filterValidationErrors.push("form.error.event-and-task.contractors.limit");
    }
    if(this.eventsFilters.selectedCreators.length > 20) {
      filterValidationErrors.push("form.error.event-and-task.creators.limit");
    }
    if(this.eventsFilters.selectedLocations.length > 20) {
      filterValidationErrors.push("form.error.event-and-task.locations.limit");
    }
    if(this.eventsFilters.selectedTags.length > 20) {
      filterValidationErrors.push("form.error.event-and-task.tags.limit");
    }
    if(this.eventsFilters.selectedTasks.length > 20) {
      filterValidationErrors.push("form.error.event-and-task.tasks.limit");
    }
    if(this.eventsFilters.selectedStatus.length > 2) {
      filterValidationErrors.push("form.error.event-and-task.states.limit");
    }
    return filterValidationErrors;
  }

  /**
   * If user ever arrived to this component with out getting 2FA authenticated (for example after the update)
   * then set the 2FA values and initiate the 2FA flow.
   */
  async checkFor2FASessionAuthentication(): Promise<void> {
    let session = this.sessionService.getSession();
    if(session.is2FAAuthenticated) {
      return;
    }
    let space = await this.sharedDataService.getSpaceById(this.sharedDataService.currentSpaceId);
    if(space.is2FAEnabled) {
      session.user.isUser2FAEnabled = space.is2FAEnabled;
      session.user.is2FAStrictModeEnabled = session.user.is2FAStrictModeEnabled ? true : space.is2FAStrictModeEnabled;
      this.sessionService.setSession(session);
      this.authService.display2FAModals();
    }
  }

  updateApp(): void {
    window.location.reload();
  }
}
