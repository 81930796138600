import { KeyValue } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NetworkStatus } from "@models/synchronization/network-status";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { UrlGiverService } from "./url-giver.service";

interface QuantityUnit {
    [key: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class QuantityUnitsService {
    private quantityUnits$ = new BehaviorSubject<QuantityUnit>({ null: null })
    public readonly watchQuantityUnits: Observable<QuantityUnit>

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private urlGiverService: UrlGiverService) {
          this.watchQuantityUnits = this.quantityUnits$.asObservable()
    }

    /**
     * By default, the `keyValue` pipe sorts the key-value pairs alphabetically. 
     * This function is used to restrict sorting by passing it as a 
     * parameter to the pipe. 
     * @param a First key-value pair
     * @param b Second key-value pair
     * @returns 0 - indicates that no sorting has to be performed
     */
    public static originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
      return 0;
    }

    public async fetchQuantityUnits(): Promise<void> {
        await NetworkStatus.waitForOnlineStatus().pipe(
            tap(() => {
                this.logger.info('[NETWORK_STATUS_ONLINE] Wait for Online status functional: QuantityUnit');
            }),
            mergeMap(() => this.http.get<QuantityUnit>(this.urlGiverService.giveDownloadQuantityUnitsUrl()))
        )
        .toPromise()
        .then((response) => {
            this.quantityUnits$.next({
                null: null,
                ...response
            })
        }).catch((error) => {
            this.logger.error("Unable to fetch quantity-units: ", error)
        })
    }
}
