import { FormGroup, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function PhoneNumberValidator(form: FormGroup): ValidationErrors {
  if (form?.controls?.phoneNumber?.value) {
    const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    let phoneNumberInput: string = form?.controls?.phoneNumber?.value?.e164Number;
    if ((phoneNumberInput || '').trim().length !== 0) {
      try {
          const phoneNumber = phoneNumberUtil.parse(phoneNumberInput);
          if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
              form.controls.phoneNumber.setErrors({ 'phoneNumberInvalid': true });
              return { 'phoneNumberInvalid': true };
          }
      } catch (error) {
          form.controls.phoneNumber.setErrors({ 'phoneNumberInvalid': true });
          return { 'phoneNumberInvalid': true };
      }
    } else {
      form.controls.phoneNumber.setErrors({ 'phoneNumberInvalid': true });
      return { 'phoneNumberInvalid': true };
    }
  }
  return null;
}
