import { Injectable } from '@angular/core';
import { UserSettings } from '../models/utils/user-settings';
import { BehaviorSubject, Observable } from 'rxjs';

const LOCAL_STORAGE_USER_SETTINGS = 'userSettings';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private currentSettings$: BehaviorSubject<UserSettings>;
  get currentSettings(): Observable<UserSettings> {
    return this.currentSettings$.asObservable();
  }

  constructor() {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_USER_SETTINGS);
    if (storedSettings) {
      this.currentSettings$ = new BehaviorSubject(Object.assign(new UserSettings(), JSON.parse(storedSettings)));
    } else {
      this.currentSettings$ =  new BehaviorSubject(new UserSettings());
    }
  }

  get(): UserSettings {
    return Object.assign(new UserSettings(), this.currentSettings$.getValue());
  }

  set(key: keyof UserSettings, value: any): void {
    const currentSettings = this.currentSettings$.getValue();
    if (key in currentSettings) {
      currentSettings[key] = value;
      localStorage.setItem(LOCAL_STORAGE_USER_SETTINGS, JSON.stringify(currentSettings));
      this.currentSettings$.next(currentSettings);
    }
  }

  clear(): void {
    localStorage.removeItem('userSettings');
  }
}

