import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class TaskTag implements Labelable, Identifiable {
  tagId: string;
  name?: string;

  constructor() {
    this.tagId = '';
  }

  public static jsonToTaskTags(jsonTags): Array<TaskTag> {
    return jsonTags.map(jsonTag => {
      const tag: TaskTag = new TaskTag();
      tag.tagId = jsonTag;
      return tag;
    });
  }

  public static taskTagsToDto(taskTags: TaskTag[]): Array<string> {
    return taskTags.map(taskTag => taskTag.tagId);
  }

  public get label(): string {
    return this.name;
  }

  /**
   * /!\ Warning /!\ This is a helper ID and is not the same as the ID used in the backend
   */
  public get id(): string {
    return this.tagId;
  }
}
