export enum TaskStateFilterValue {
    PLANNED = 'Planned',
    INPROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled'
  }
  
  export enum TaskIndicatorFilterValue {
    READY_TO_START = 'ReadyToStart',
    DELAYED = 'Delayed',
    OVERDUE = 'Overdue',
    PENDING_APPROVAL = 'PendingApproval'
  }
  