import { Pipe, PipeTransform } from '@angular/core';
import { Task, TASK_INDICATOR, TASK_STATUS } from '@models/task';

@Pipe({
  name: 'taskStatusOrIndicatorToCssClass'
})
export class TaskStatusOrIndicatorToCssClassPipe implements PipeTransform {
  transform(task: Task): string {
    if(task.taskStatus === TASK_STATUS.PLANNED) {
      if( task.taskIndicator === null || task.taskIndicator === TASK_INDICATOR.READY_TO_START) {
        return 'not-started';
      } else if (task.taskIndicator === TASK_INDICATOR.DELAYED || task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return 'overdue';
      }
    }
    if(task.taskStatus === TASK_STATUS.INPROGRESS) {
      if( task.taskIndicator !== TASK_INDICATOR.OVERDUE && task.taskIndicator !== TASK_INDICATOR.PENDING_APPROVAL) {
        return 'in-progress';
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return 'overdue';
      } else if (task.taskIndicator === TASK_INDICATOR.PENDING_APPROVAL) {
        return 'in-progress'
      }
    }
    if(task.taskStatus === TASK_STATUS.COMPLETED) {
      return 'completed';
    }
    if(task.taskStatus === TASK_STATUS.CANCELLED) {
      return 'cancelled';
    }
    return 'all-tasks'
  }
}
