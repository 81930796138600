import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { positiveDurationValidator } from '@validators/positive-duration.validator';

import { ExportService } from '@services/export.service';
import { DeviceService } from '@services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@services/toaster.service';

type SuccessCallback = (title: string, fromDate?: Date, toDate?: Date) => void;
type DismissCallback = () => void;

@Component({
  selector: 'app-pdf-export-configuration-popup',
  templateUrl: './pdf-export-configuration-popup.component.html',
  styleUrls: ['./pdf-export-configuration-popup.component.sass']
})
export class PdfExportConfigurationPopupComponent implements OnInit {

  public isCollapsedTitle: boolean = false;
  public isCollapsedDate: boolean = false;

  @Input() private successCallback: SuccessCallback;
  @Input() private dismissCallback: DismissCallback;
  @Input() public isDateRequired: boolean = true;

  public formGroup: FormGroup;
  protected localizedFormat: string;

  constructor(
    private formBuilder: FormBuilder,
    private exportService: ExportService,
    public deviceService: DeviceService,
    protected translateService: TranslateService,
    protected toasterService: ToasterService,
  ) { }

  ngOnInit() {
    if (this.deviceService.isMobile) {
      if(this.isDateRequired) {
        this.formGroup = this.formBuilder.group({
          startDatetime: [null, Validators.required],
          endDatetime: [null, Validators.required],
          title: [''],
        }, {
          validator: [positiveDurationValidator],
        });
      } else {
        this.formGroup = this.formBuilder.group({
          title: [''],
        });
      }
    }
    else {
      this.formGroup = this.formBuilder.group({
        title: [''],
      });
    }
  }

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  download(domEvent?: Event): void {
    if (domEvent) {
      domEvent.preventDefault();
    }
    if (!this.formGroup.invalid) {
      if (this.deviceService.isMobile) {
        const { title, startDatetime, endDatetime } = this.formGroup.value;
        if (this.exportService.checkPdfReportDuration(startDatetime, endDatetime)) {
          this.successCallback(title, new Date(startDatetime), new Date(endDatetime));
        }
      }
      else {
        const title = this.formGroup.controls['title'].value as string;
        this.successCallback(title);
      }
    }
    else {
      if (this.formGroup.controls.startDatetime.errors
        && this.formGroup.controls.startDatetime.errors.required) {
        this.toasterService.showErrorToaster('form.error.event-and-task.startDatetime.required');
      }
      else if (this.formGroup.controls.endDatetime.errors
        && this.formGroup.controls.endDatetime.errors.required) {
        this.toasterService.showErrorToaster('form.error.event-and-task.endDatetime.required');
      }
      else if (this.formGroup.errors && this.formGroup.errors.negativeDuration) {
        this.toasterService.showErrorToaster('form.error.event-and-task.negativeDuration');
      }
    }
  }

  getDateForPlaceholder(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return year + "_" + month + "_" + day;
  }
}
