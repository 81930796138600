import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Attachment } from '@models/attachment';
import { Event } from '@models/event';
import { NetworkStatus } from '@models/synchronization/network-status';
import { AttachmentService } from '@services/attachment.service';
import { SharedDataService } from '@services/shared-data.service';
import { SharedService } from '@services/shared/shared.service';
import { SpinnerService } from '@services/spinner.service';
import { ImagesInputComponent } from 'app/form/controls/shared/images-input/images-input.component';
import { SecureImageSrcPipe } from 'app/pipes/secure-image.pipe';
import { NGXLogger } from 'ngx-logger';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';
import Viewer from 'viewerjs';
import { UpgradeService } from 'app/subscription/upgrade.service';
import { StripeService } from '@services/stripe.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExportService } from '@services/export.service';
import moment from 'moment';

@Component({
  selector: 'app-event-photos',
  templateUrl: './event-photos.component.html',
  styleUrls: ['./event-photos.component.scss', '../event-legacy-field.component.sass']
})
export class EventPhotosComponent extends EventLegacyFieldComponent implements OnInit, OnDestroy {

  @Input() isViewReady: boolean;
  @Input() event: Event;
  @Input() eventId: string = null;

  @ViewChild('imagesInputComponent')
  public imagesInputComponent: ImagesInputComponent;

  public viewerOptions: Viewer.Options = {
    title: false,
    toolbar: {
      zoomIn: false,
      zoomOut: false,
      oneToOne: false,
      rotateLeft: false,
      rotateRight: false,
      prev: true,
      reset: false,
      play: false,
      next: true,
      flipHorizontal: false,
      flipVertical: false,
    },
    transition: false,
    className: 'ngx-image-viewing-mobile',
    viewed: async (image) => {
      let imageId = this.event.attachments[image.detail.index].id;
      this.loadImageByImageId(imageId);
      this.addDownloadButtonToToolbar();
    },
    hidden:() => {
      document.getElementById("event-preview-mobile-id").style.filter = 'none';
      document.getElementById("event-preview-mobile-id").style.pointerEvents = 'auto';
      this.showDropDown = false;
      document.getElementById("image-preview-dropdown").style.display = 'none';
      const mainContent = document.getElementsByClassName("main-content");
      /**
       * Once the image previewer is closed, we replace the class that disables the scroll with the initial class that was present which was responsible for scrolling.
       */
      if (mainContent.length > 0) {
        if (mainContent[0].shadowRoot.querySelector('main')) {
          mainContent[0].shadowRoot.querySelector('main').classList.replace("disable-scroll", "scroll-y");
        }
      }
    }
  };

  relevantSharedService: SharedService | SharedDataService;
  destroy = new Subject<boolean>();
  showDropDown: boolean = false;

  constructor(
    protected modalController: ModalController,
    public sharedService: SharedService,
    private attachmentService: AttachmentService,
    private secureImageSrcPipe:SecureImageSrcPipe,
    private spinnerService: SpinnerService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService,
    private upgradeService: UpgradeService,
    private stripeService: StripeService,
    private exportService: ExportService,
  ) {
    super(
      modalController,
    );
    if(this.deviceIsMobile) {
      this.relevantSharedService = this.sharedService;
    } else {
      this.relevantSharedService = this.sharedDataService;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.deviceIsMobile) {
      const element = document.getElementById("event-preview-mobile-id");
      if (element) {
        element.style.filter = 'none';
        element.style.pointerEvents = 'auto';
        const mainContent = document.getElementsByClassName("main-content");
        if (mainContent.length > 0) {
          if (mainContent[0].shadowRoot.querySelector('main')) {
            mainContent[0].shadowRoot.querySelector('main').classList.replace("disable-scroll", "scroll-y");
          }
        }
      }
    }
    this.destroy.next(true);
    this.destroy.complete();
  }

  public isEmpty(controlName: string) {
    if (this.isViewReady && this.parentFormGroup) {
      const control = this.parentFormGroup.controls[controlName];
      return !control || !control.value || control.value.length === 0;
    }
    return true;
  }

  addDownloadButtonToToolbar() {
    const viewerContainer = document.querySelector('.viewer-container');
    if (!viewerContainer) {
      return;
    }
    const dropdownContainer = document.querySelector('#image-preview-dropdown');
    if(dropdownContainer) {
      this.showDropDown = false;
      document.getElementById("image-preview-dropdown").style.display = 'none';
    }

    const toolbarContainer = document.querySelector('.toolbar');
    document.getElementById("event-preview-mobile-id").style.filter = 'blur(3px)';
    document.getElementById("event-preview-mobile-id").style.pointerEvents = 'none';

    /**
     * On the `ion-content` that is wrapping the whole event-form, there is an implicit scroller present in the Shadow DOM of `ion-content`. 
     * When the photos modal opens, we are able to scroll the event-form in the background through this intrinsic scroller. 
     * 
     * To prevent this from happening, we access this intrinsic scroller and replace the class responsible for scrolling with a different class which disables scroll.
     * When the modal is closed we revert the change, which allows the user to scroll through the form again.
     */
    const mainContent = document.getElementsByClassName("main-content");
    if (mainContent.length > 0) {
      if (mainContent[0].shadowRoot.querySelector('main')) {
        mainContent[0].shadowRoot.querySelector('main').classList.replace("scroll-y", "disable-scroll");
      }
    }

    if(!toolbarContainer) {
      const toolbarDiv = document.createElement('div');
      toolbarDiv.className = 'toolbar';

      const dotsButton = document.createElement('div');
      dotsButton.className = 'viewer-download viewer-button image-download-button';
      dotsButton.setAttribute('role', 'button');
      dotsButton.style.left = '78%';
      const ionIcon = document.createElement('ion-icon');
      ionIcon.setAttribute('name', 'ellipsis-horizontal-outline');
      ionIcon.style.marginTop = '28px';
      dotsButton.appendChild(ionIcon);

      const closeDiv = document.querySelector('.viewer-close');
      toolbarDiv.appendChild(closeDiv);
      toolbarDiv.appendChild(dotsButton);

      const dropdown = document.createElement('div');
      dropdown.id = 'image-preview-dropdown';

      this.addDownloadButton(viewerContainer, dropdown);
      this.addShareButton(viewerContainer, dropdown);

      dotsButton.onclick = () => {
        this.showDropDownDiv(dropdown);
      };

      viewerContainer.appendChild(dropdown);
      viewerContainer.appendChild(toolbarDiv);
    }
  }

  showDropDownDiv = (dropdown: HTMLDivElement) => {
    this.showDropDown = !this.showDropDown;
    if(!this.showDropDown) {
      dropdown.style.display = 'none';
    } else {
      dropdown.style.display = 'block';
    }
  }

  addDownloadButton(viewerContainer: Element, dropdown: HTMLDivElement) {
    const downloadOption = document.createElement('ion-item');
    downloadOption.setAttribute('role', 'button');
    downloadOption.setAttribute('lines', 'none');
    const downloadIcon = document.createElement('ion-icon');
    downloadIcon.setAttribute('name', 'download-outline');
    const downloadText = document.createElement('p');
    downloadText.textContent = 'Download';
    downloadText.style.marginTop = '21px';
    downloadOption.appendChild(downloadIcon);
    downloadOption.appendChild(downloadText);
    dropdown.appendChild(downloadOption);

    downloadOption.onclick = () => {
      const imgElement = viewerContainer.querySelector('.viewer-move');
      if (!(imgElement instanceof HTMLImageElement)) {
        this.logger.error('No image found for downloading in EventPhotosComponent.');
        return;
      }
      const imgUrl = imgElement.src;

      fetch(imgUrl)
        .then(response => response.blob())
        .then(blob => {
          this.handleDownload(blob);
        })
    }
  }

  handleDownload = (blob) => {
    this.showDropDown = false;
    document.getElementById("image-preview-dropdown").style.display = 'none';
    this.exportService.fetchAndSaveImage(this.event.title + "-" + moment(new Date()).format('YYYY-MM-DD-HH-mm-s'), blob);
  }

  addShareButton(viewerContainer: Element, dropdown: HTMLDivElement) {
      const shareOption = document.createElement('ion-item');
      shareOption.setAttribute('role', 'button');
      shareOption.setAttribute('lines', 'none');
      const shareIcon = document.createElement('ion-icon');
      shareIcon.setAttribute('name', 'share');
      shareIcon.style.width = '16px';
      shareIcon.style.height = '16px';
      const shareText = document.createElement('p');
      shareText.textContent = 'Share';
      shareText.style.marginTop = '21px';
      shareOption.appendChild(shareIcon);
      shareOption.appendChild(shareText);
      dropdown.appendChild(shareOption);
      dropdown.style.display = 'none';

      shareOption.onclick = async () => {
        const imgElement = viewerContainer.querySelector('.viewer-move');
        if (!(imgElement instanceof HTMLImageElement)) {
          this.logger.error('No image found for sharing in EventPhotosComponent.');
          return;
        }
        this.shareImage(imgElement.src);
      }
  }

  shareImage = (url) => {
    this.showDropDown = false;
    document.getElementById("image-preview-dropdown").style.display = 'none';
    this.exportService.shareImage(url, this.event.title + "-" + moment(new Date()).format('YYYY-MM-DD-HH-mm-s'));
  }

  getAttachmentsToUpload(): { [attachmentId: string]: string } {
    if(this.imagesInputComponent) {
      return this.imagesInputComponent.getAttachmentsToUpload();
    }
    return null;
  }

  clearAllAttachmentsToUpload(): void {
    if(this.imagesInputComponent) {
      return this.imagesInputComponent.clearAllAttachmentsToUpload();
    }
    return null;
  }

  /**
   * Get picture url from attachment object
   * @param attachment given attachment
   * @param isThumbnail set it to true to get a light version of the picture
   */
  public getPictureUrl(attachment: Attachment, isThumbnail: boolean = true): string {
    if(this.deviceIsMobile) {
      return this.attachmentService.getPictureUrl(this.sharedService.currentSpaceId, this.event.siteId, this.event.id, attachment.id, isThumbnail);
    } else {
      return this.attachmentService.getPictureUrl(this.sharedDataService.currentSpaceId, this.event.siteId, this.event.id, attachment.id, isThumbnail);
    }
  }

// load full resolution image
  async loadImageByImageId(imageId: string): Promise<void> {
    if(!(await this.attachmentService.isAttachmentLocallyStoredOrPresentInQueue(imageId))) {
      this.spinnerService.activate('rotating');
      let imageSrc = this.getPictureUrlById(imageId, false);
      if (NetworkStatus.isOnline) {
        this.secureImageSrcPipe.transformToBlobUrl(imageSrc).toPromise()
        .then((url) => {
          let newViewingImage: any = document.getElementsByClassName('viewer-move');
          if(newViewingImage[0]) {
            newViewingImage[0].src = url;
          }
          this.spinnerService.deactivate();
        })
        .catch((error) => {
          this.logger.error("Error Loading full Image", error);
          this.spinnerService.deactivate();
        });
      }
      else {
        this.spinnerService.deactivate();
      }
    }
  }

  getPictureUrlById(attachmentId, isThumbnail: boolean = true): string {
    return this.attachmentService.getPictureUrl(this.sharedService.currentSpaceId, this.event.siteId, this.event.id, attachmentId, isThumbnail);
  }

  isEventNew(): boolean {
    if(this.eventId && this.eventId !== 'new') {
      return false;
    } else {
      return true;
    }
  }

  openMobileGalleryPopup() {
    this.stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT').pipe(
      takeUntil(this.destroy)
    ).subscribe(hasFeature => {
      if(hasFeature) {
        this.imagesInputComponent?.openMobileGalleryPopup();
      }
      else {
        this.upgradeService.annoyUser('upgrade.feature.pictures');
      }
    });
  }
}
