<div class="ui-web-datetime-picker">
    <!-- START TIME PICKER -->
    <div id="timepicker">
      <div class="ui-timepicker">
        <div id="description-wrapper">
          <span class="description">{{ 'ui.datetimepicker.time' | translate }}</span>
        </div>
        <div class="select-wrapper hour ">
          <select class="select-box select-css" (change)="selectHour($event.target.value)">
            <option *ngFor="let hour of hours" [selected]=" hour === selectedHour" [value]="hour">
              {{hour}}
            </option>
          </select>
        </div>
        <div class="select-wrapper colon">
          {{'colon.symbol' | translate}}
        </div>
        <div class="select-wrapper minute">
          <select class="select-box select-css" (change)="selectMinute($event.target.value)">
            <option *ngFor="let min of minutes" [selected]="min === selectedMinute" [value]="min">
              {{min}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- END TIME PICKER -->
    <div>
      <div class="align-right">
        <ion-button class="secondary-action-button" (click)="cancel()">
          {{ 'btn.cancel' | translate }}
        </ion-button>
        <ion-button class="ml-2 primary-action-button" (click)="saveAndClose()" [ngClass]="['bg-color' | cssClassToAppSpecificColorCssClass]">
          {{ 'Ok' | translate | uppercase }}
        </ion-button>
      </div>
    </div>
</div>
