import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkStatus } from '@models/synchronization/network-status';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AngularGoogleMapsService {

  private apiLoadedStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  apiLoaded: Observable<boolean>;
  private googleMapsKey = environment.googleMapsKey;

  constructor(
    private http: HttpClient,
  ) {
    this.apiLoaded = this.apiLoadedStatus.asObservable();
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&libraries=places`, 'callback')
      .pipe(
        map((res) => {
          return true
        }),
        catchError((err) => {
          return of(false)}),
      ).subscribe((isLoaded) => {
        this.apiLoadedStatus.next(isLoaded);
      });
    });
   }

}
