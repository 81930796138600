import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NetworkStatus } from '@models/synchronization/network-status';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@services/device.service';
import { Logger } from '@services/logger';
import { Coordinates, CoordinatesService } from '@services/shared/coordinates.service';
import { SharedService } from '@services/shared/shared.service';
import { OfflineWeatherErrorService } from '@services/offline-weather-error.service';
import { Observable, Subject } from 'rxjs';

import { SelectGpsPopupComponent } from '../select-gps/select-gps-popup/select-gps-popup.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-select-weather',
  templateUrl: './select-weather.component.html',
  styleUrls: ['./select-weather.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectWeatherComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectWeatherComponent),
    multi: true
  }]
})
export class SelectWeatherComponent implements OnInit {
  @Input() defaultLat: number;
  @Input() defaultLng: number;
  @Input() readOnly = false;
  @Input() desktopRenewWeather: Observable<Coordinates>;

  @Output() closingMap = new EventEmitter();
  /** An output interaction to emit if weather is ignored to the parent */
  @Output() weatherActiveEmitter = new EventEmitter<boolean>();

  @Output() weatherChangeEmitter = new EventEmitter<Coordinates>();
  @Output() mapViewStatus = new EventEmitter<boolean>();

  public weather: Coordinates;

  private _showMap: boolean;

  noCoordinatesAvailable: boolean = false;
  noSiteCoordinatesAvailable: boolean = false;

  get showMap(): boolean {
    return this._showMap;
  }

  set showMap(value: boolean) {
    this.isWeatherIgnoredProp = false;
    if (value) {
      this.getLocationUser()
        .then(() => this._showMap = value)
        .catch(() => this._showMap = value);
    } else {
      this.closeMap();
      this._showMap = value;
    }
    this.mapViewStatus.emit(value);
  }

  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  isWeatherIgnoredProp: boolean = false;

  constructor(
    public deviceService: DeviceService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private coordinatesService: CoordinatesService,
    private modalController: ModalController,
    private offlineWeatherErrorService: OfflineWeatherErrorService,
    private logger: NGXLogger,
  ) {}

  ngOnInit() {
    if (this.deviceService.isMobile) {
      this.showMap = false;
      const siteCoordinates = new Coordinates(this.defaultLat, this.defaultLng);
      this.setCoordinates(siteCoordinates);
      this.setNoSiteCoordinatesAvailable(siteCoordinates);

    } else {
        this.desktopRenewWeather.subscribe(coordinates => {
          this.showMap = false;
          this.setCoordinates(coordinates);
          this.setNoSiteCoordinatesAvailable(coordinates);
      });
    }
  }

  setNoSiteCoordinatesAvailable(coordinates: Coordinates) {
    if (!coordinates.lat && !coordinates.lng) {
      this.noSiteCoordinatesAvailable = true;
    }
    this.offlineWeatherErrorService.updateSiteCoordinatesAvailable(this.noSiteCoordinatesAvailable);
  }

  ignoreWeather() {
    this.showMap = false;
    this.isWeatherIgnoredProp = true;
    this.weatherActiveEmitter.emit(false);
  }

  setCoordinates(coordinates: Coordinates) {
    if (!this.weather) {
      this.weather = new Coordinates();
    }
    this.weather.lat = coordinates.lat;
    this.weather.lng = coordinates.lng;
    this.noCoordinatesAvailable = !(coordinates && (coordinates.lat !== null && coordinates.lng !== null));
    this.weatherChangeEmitter.emit(coordinates);
    this.offlineWeatherErrorService.updateCoordinatesAvailable(this.noCoordinatesAvailable);
  }

  closeMap() {
    this.closingMap.emit();
  }

  /** Show event location settings view to calculate weather, also emit weatherActive event */
  openLocationSettings() {
    this.showMap = !this.showMap;
    this.weatherActiveEmitter.emit(true);
  }

  async openSelectGpsPopup() {
    if (!this.weather) {
      this.weather = new Coordinates();
    }
    const coordinateSubject = new Subject<Coordinates>();
    coordinateSubject.subscribe(coordinates => {
      this.setCoordinates(coordinates);
    });
    if (this.isOnline) {
      const modal = await this.modalController.create({
        component: SelectGpsPopupComponent,
        componentProps: {
          initLatitude: this.weather.lat,
          initLongitude: this.weather.lng,
          coordinateSubject: coordinateSubject,
        },
      });
      await modal.present();
      this.weatherActiveEmitter.emit(true);
      this.isWeatherIgnoredProp = false;
    } else {
      await this.applySiteCoordinates();
    }
  }

  /**
   * Get Site location and apply on current component
   */
  public async applySiteCoordinates(): Promise<Coordinates> {
    try {
      const coordinates = await this.getSiteLocation();
      this.setCoordinates(coordinates);
      return coordinates;
    } catch (ex) {
      this.noSiteCoordinatesAvailable = true;
      this.offlineWeatherErrorService.updateSiteCoordinatesAvailable(this.noSiteCoordinatesAvailable);
      this.ignoreWeather();
      return null;
    }
  }

  /**
   * Get location set on the event
   * Return null if location is not set
   */
  getEventCoordinates(): Coordinates | null {
    if (this.defaultLat && this.defaultLng) {
      const eventCoordinates = new Coordinates(this.defaultLat, this.defaultLng);
      this.setCoordinates(eventCoordinates);
      return eventCoordinates;
    } else {
      return null;
    }
  }

  /**
   * Get current location in weather
   * Returns User location if not set in weather
   */
  async getLocationUser(): Promise<Coordinates> {
    const weatherCoordinates = this.getEventCoordinates();
    return (weatherCoordinates ? weatherCoordinates : this.locateUser());
  }

  private getSiteLocation(): Promise<Coordinates> {
    return this.coordinatesService
      .getLocationFromCurrentSite();
  }

  /**
   * Get the location of the user by GPS
   */
  private async locateUser(): Promise<Coordinates> {
    return this.coordinatesService.geolocateUser()
      .then(coordinates => {
        this.setCoordinates(coordinates);
        return coordinates;
      })
      .catch(err => {
        this.logger.error('Error while locating user by GPS', err);
        return this.applySiteCoordinates();
      });
  }
}
