import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormatSortService } from '@services/format-sort.service';
import { Identifiable } from '@models/identifiable';
import { Labelable } from '@models/labelable';

class SelectableItem implements Labelable {
  public readonly id = this.item.id;
  public readonly label = this.item.label;
  constructor(
    public readonly item: Identifiable & Labelable,
    public selected: boolean,
  ) { }
}

@Component({
  selector: 'app-resource-selector',
  templateUrl: './resource-selector.component.html',
  styleUrls: ['./resource-selector.component.sass']
})
export class ResourceSelectorComponent {

  @Input() items: (Identifiable & Labelable)[];

  @Input()
  set selectedItems(selectedItems: (Identifiable & Labelable)[]) {
    if (this.items && selectedItems) {
      this.selectableItemList = this.items.map(
        item => new SelectableItem(item, selectedItems.some(selected => item.id === selected.id))
      );
    }
  }
  @Output() selectedItemsChange = new EventEmitter<(Identifiable & Labelable)[]>();

  itemFilter: string = '';
  selectableItemList: SelectableItem[] = [];
  wrappedSelectedItems: SelectableItem[] = [];

  constructor() { }

  public search(itemFilter: string): void {
    this.itemFilter = FormatSortService.formatText(itemFilter);
  }

  public toggleItem(toggledItem: SelectableItem): void {
    if (toggledItem.selected) {
      toggledItem.selected = false;
      this.wrappedSelectedItems = this.wrappedSelectedItems.filter(item => toggledItem.id !== item.id);
    } else {
      toggledItem.selected = true;
      this.wrappedSelectedItems.push(toggledItem);
    }
    this.selectedItemsChange.emit(this.wrappedSelectedItems.map(item => item.item));
  }
}
