import { User } from './../models/user';
import { Injectable } from '@angular/core';
import * as Unidecode from 'unidecode';
import { Author } from '../models/author';

@Injectable()
/**
 * Service contains some methods to format the text for sort and filter
 */
export class FormatSortService {

  /**
   * FormatText is use for remove space character, remove accent and lower case the text
   */
  public static formatText(text: string): string {
    if (text) {
      text = text.toString();
      text = text.replace(' ', '');
      text = Unidecode(text);
      text = text.toLocaleLowerCase();
      return text;
    } else {
      return '';
    }
  }

  /**
   * SortText is use to sort datatable's rows with string text
   * @param valueA String value A
   * @param valueB String value B
   * @param rowA Object A
   * @param rowB Object B
   * @param sortDirection asc or desc
   */
  public static sortText(valueA: string, valueB: string, rowA: any, rowB: any, sortDirection: string) {
    valueA = FormatSortService.formatText(valueA);
    valueB = FormatSortService.formatText(valueB);
    if (valueA > valueB) {
      return sortDirection.match('[asc]') ? 1 : -1;
    } else if (valueA < valueB) {
      return sortDirection.match('[asc]') ? -1 : 1;
    }
    return 0;
  }

  public static sortAuthor(authorA: Author, authorB: Author) {
    // Compare Authors's by name
    if (authorA.name < authorB.name) { return -1; } else { return 1; }
  }

  public static sortAssigned(assignedA: User[], assignedB: User[]) {
    if (assignedA.length > 0) {
      if (assignedB.length > 0) {
        if (assignedA[Math.floor(Math.random() * Math.floor(assignedA.length))].firstName
          < assignedB[Math.floor(Math.random() * Math.floor(assignedB.length))].firstName) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    } else {
      return -1;
    }
  }

  public static compare(a: string, b: string): number {
    return a.toUpperCase().localeCompare(b.toUpperCase());
  }
}
