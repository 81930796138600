import { Component, Input, OnInit } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';


@Component({
  selector: 'app-approve-button',
  templateUrl: './approve-button.component.html',
  styleUrls: ['./approve-button.component.scss'],
})
export class ApproveButtonComponent extends AbstractButtonComponent implements OnInit {
  @Input() style: 'normal' | 'reverse' = 'normal';

  buttonText: string;

  ngOnInit(): void {
    this.buttonText = (this.style === 'normal') ? 'events.detail.approve.btn.approve' : 'events.detail.panel_creation.approved';
  }

}
