import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArchiveSitesService {

  constructor(
    private translateService: TranslateService,
    private alertController: AlertController,
  ) {}

  showSiteArchivedModal(): void {
    this.translateService.get([
      'sites.archive.alert.header',
      'sites.archive.alert.message',
      'Ok'
    ]).pipe(
      mergeMap(async translations => {
        const alert = await this.alertController.create({
          header: translations['sites.archive.alert.header'],
          message: translations['sites.archive.alert.message'],
          cssClass: "site-archived-alert-box",
          buttons: [
            {
              text: translations['Ok'],
              role: 'cancel',
              cssClass: 'ok-btn',
              handler: () => {
              },
            }
          ],
        });
        await alert.present();
      })
    ).toPromise();
  }
}
