import { Directive, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { CustomEventField } from "@models/custom-event-field";
import { Event } from "@models/event";
import { ResourceType } from "@models/resource-type";
import { ItemSelectorPopupComponent } from "app/home/site/item-selector-popup/item-selector-popup.component";

@Directive()
export abstract class EventLegacyFieldComponent implements OnInit {

  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean = false;
  @Input() isViewReady: boolean = false;
  @Input() event: Event = null;
  @Input() isMobileEventPreviewComponent: boolean = false;

  constructor(
    protected modalController: ModalController,
  ) {}

  ngOnInit(): void {}

   /**
   * Show ionic popup page to select resources
   * @param data list of items
   * @param selectedData selected items
   * @param type type of items
   */
  async openItemSelectorPopup(data: any[], selectedData: any[], type: ResourceType, selectionMode: SelectionMode) {
    const modal = await this.modalController.create({
      component: ItemSelectorPopupComponent,
      componentProps: {
        items: data,
        selectedItems: selectedData,
        type: type,
        selectionMode: selectionMode,
        canCreate: type !== 'tasks',
        updateItemsFunction: this.updateItems.bind(this)
      },
    });
    return await modal.present();
  }
  
  /**
   * Method called on click of OK to refresh event form with newly selected contractor, labours, plants or tags
   */
  updateItems(selected: any[], type: ResourceType): void {
    if (type === 'location') {
      this.parentFormGroup.controls[type].setValue(selected[0]);
      this.parentFormGroup.controls[type].markAsDirty();
    }
    else {
      this.parentFormGroup.controls[type].setValue(selected);
      this.parentFormGroup.controls[type].markAsDirty();
    }
  }

  public isEmpty(controlName: string) {
    if (this.isViewReady && this.parentFormGroup) {
      const control = this.parentFormGroup.controls[controlName];
      return !control || !control.value || control.value.length === 0;
    }
    return true;
  }

  /**
   * Get readable version of time in hh:mm format
   * @param time time in minutes (m)
   */
  public timeConvert(time: number): string {
    const num = time;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return this.minTwoDigits(rhours) + ':' + this.minTwoDigits(rminutes);
  }

  /**
   * Convert number to time two digit format
   * e.g 9 h => 09
   * e.g 16 h => 16
   * @param n given number
   */
  public minTwoDigits(n): string {
    return (n < 10 ? '0' : '') + n;
  }

}
