export class Currency {
    private id: string;
    private text: string;
    private symbol: string;

    constructor() {
        this.id = '';
        this.text = '';
        this.symbol = '';
    }
}
