import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService } from '@services/logout/logout.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Session } from '../../models/session';
import { User } from '../../models/user';
import { IntercomService } from '../intercom.service';
import { SessionService } from '../session.service';
import { ToasterService } from '../toaster.service';
import { IntercomUser } from './../../models/intercom-user';
import { HttpStatus } from '@constants/http/http-status';
import { SpaceService } from '@services/space.service';
import { NGXLogger } from 'ngx-logger';
import { SharedService } from '@services/shared/shared.service';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';
import { UrlGiverService } from '@services/url-giver.service';
import { RequestOTPResponse } from '@models/2FA/request-otp';
import { NetworkStatus } from '@models/synchronization/network-status';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// eslint-disable-next-line max-len
import { PhoneNumberVerificationModalComponent } from 'app/modals/2FA/phone-number-verification/phone-number-verification-modal/phone-number-verification-modal.component';
import { OtpVerificationComponent } from 'app/welcome/otp-verification/otp-verification.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { PhoneNumberMaskPipe } from 'app/pipes/phone-number-mask.pipe';
import { OrganisationSize } from '@models/utils/registration-credentials';
import { PushNotificationService } from '@services/notification/push-notification.service';
import { LocalNotificationService } from '@services/local-notification-service';


@Injectable()
export class AuthService {
  static readonly AUTH_TOKEN_URL_PART: string = '/oauth/token';
  static readonly NO_TOKEN_MUTEX_VALUE = 'no_token_available';

  private tokenRefreshMutex: BehaviorSubject<any> =
    new BehaviorSubject<any>(AuthService.NO_TOKEN_MUTEX_VALUE); // initialized with default value in order not to lock mutex by default

  private tokenRefreshMutexObserver: Observer<any> = {
    next: mutexValue => {
      if (mutexValue == null) {
        this.logger.info('[Token refresh mutex] Acquired.');
      } else {
        this.logger.info(`[Token refresh mutex] Released. Value: ${mutexValue}`);
      }
    },
    error: null,
    complete: null,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private sessionService: SessionService,
    private toasterService: ToasterService,
    private intercomService: IntercomService,
    private logoutService: LogoutService,
    private spaceService: SpaceService,
    private logger: NGXLogger,
    private sharedService: SharedService,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService,
    private urlGiverService: UrlGiverService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private alertController: AlertController,
    private phoneNumberMaskPipe: PhoneNumberMaskPipe,
    private pushNotificationService: PushNotificationService,
    private localNotificationService: LocalNotificationService,
  ) {
    this.tokenRefreshMutex.subscribe(this.tokenRefreshMutexObserver);
    this.logoutService.addLogoutCallback(() => this.dismissAllModals());
  }

  /** This mutex holds the latest refreshed access_token value, or is null when a refresh is in progress. */

  /** This mutex holds the latest refreshed access_token value, or is null when a refresh is in progress. */

  /**
   * Authentification Guard
   * Check user session (during routing operation)
   * Test ID, ACCESS_TOKEN, REFRESH_TOKEN
   */
  public isAuthenticated(): boolean {
    const session = this.sessionService.getSession();

    if (session) {
      // Valid user id required
      if (session.userId === undefined || session.userId === null || session.userId === '') {
        this.logger.error(`Invalid userId: ${session.userId}`);
        return false;
      }

      // Valid access token required
      if (session.accessToken === undefined || session.accessToken === null || session.accessToken === '') {
        this.logger.error(`Invalid accessToken: ${session.accessToken}. Resetting session...`);
        this.sessionService.removeSession();
        return false;
      }

      // Refresh token may or may not be null, depending on if "Remember me" option was checked, so no need to check it
      return true;
    }

    return false;
  }

  /**
   * 2FA Authentification Guard
   * Check user session (during routing operation)
   */
  public is2FAAuthenticated(): boolean {
    const session = this.sessionService.getSession();

    if (session) {
      // user has 2FA enabled but is not 2FA authenticated
      if (session.user.isUser2FAEnabled && !session.is2FAAuthenticated) {
        return false;
      }
      return true;
    }

    return false;
  }

  /**
   * Get session ACCESS_TOKEN
   */
  getAccessToken(): string {
    if (this.isAuthenticated()) {
      return this.sessionService.getSession().accessToken;
    }
    return null;
  }

  /**
   * Get user session
   */
  async signIn(
    username: string,
    password: string,
    rememberMe: boolean,
    fromSignUp?: boolean
  ): Promise<Session> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('remember_me', rememberMe.toString())
      .set('grant_type', 'password');

    return this.http.post<any>(
      environment.apiUrl + AuthService.AUTH_TOKEN_URL_PART,
      body,
      { headers: headers }
    ).toPromise()
      .then(fetched_session => {
        // Convert user in session into frontend User model
        const user = new User(
          fetched_session.user.id,
          username,
          fetched_session.user.lastName,
          fetched_session.user.firstName,
          fetched_session.user.phoneNumber,
        );

        const userDetailsAPIHeaders = new HttpHeaders()
          .set('Authorization', `Bearer ${fetched_session.access_token}`);

        return this.http.get<any>(
          this.urlGiverService.giveUserDetailsAPIUrl(),
          { headers: userDetailsAPIHeaders }
        ).toPromise()
          .then(userDetails => {
            user.isPhoneNumberVerified = userDetails.isPhoneNumberVerified;
            user.isUser2FAEnabled = userDetails.is2FAEnabled;
            user.is2FAStrictModeEnabled = userDetails.is2FAStrictModeEnabled;
            user.isWebAdminOnboardingDone = userDetails.userOnboardingDetails.isWebAdminOnboardingDone;
            user.isWebSiteDiaryOnboardingDone = userDetails.userOnboardingDetails.isWebSiteDiaryOnboardingDone;
            user.isWebSiteTaskOnboardingDone = userDetails.userOnboardingDetails.isWebSiteTaskOnboardingDone;
            user.isMobileSiteDiaryOnboardingDone = userDetails.userOnboardingDetails.isMobileSiteDiaryOnboardingDone;

            // Set user session inside local storage
            const session = new Session(user.id, fetched_session.access_token, rememberMe ? fetched_session.refresh_token : '', user, false);
            this.sessionService.setSession(session);
            this.pushNotificationService.initializePushNotifications();
            if (this.deviceService.isMobile && !this.deviceService.isMobileWeb) {
              this.localNotificationService.initializeLocalNotifications();
            }
          this.toasterService.clearAllAlertBoxes();

            this.logger.info('User details: [session - userId, accessToken, refreshToken], [user - userId, firstName, lastName, mail]:', session.userId, session.accessToken, session.refreshToken,
              session.user.id, session.user.firstName, session.user.lastName, session.user.mail);

            return this.continueSignInFlow(session, fromSignUp);
          }).catch((_errorResponse) => {
            const errorResponse: HttpErrorResponse = _errorResponse;
            if (errorResponse.status === HttpStatus.BAD_REQUEST) {
              this.toasterService.showErrorToaster('login.errors.incorrect');
              throw errorResponse;
            } else {
              this.toasterService.showErrorToaster('default.error');
              throw errorResponse;
            }
          });
      })
      .catch(_errorResponse => {
        const errorResponse: HttpErrorResponse = _errorResponse;
        this.logger.error('Error while signing in: ', errorResponse);
        if (errorResponse.error && errorResponse.error.error_description === 'User account is locked') {
          this.router.navigate(['/confirm'], { queryParams: { email: username } });
          return null;
        } else if (errorResponse.status === HttpStatus.BAD_REQUEST && fromSignUp) {
          this.toasterService.showInfoToaster('login.errors.already_subscribed');
        } else if (errorResponse.status === HttpStatus.BAD_REQUEST) {
          this.toasterService.showErrorToaster('login.errors.incorrect');
          throw errorResponse;
        } else {
          this.toasterService.showErrorToaster('default.error');
          throw errorResponse;
        }
      });
  }

  continueSignInFlow(session: Session, fromSignUp: boolean): Session {

    if (fromSignUp) {
      // A previous user that signs up with a new account will see data from the previous account.
      // Space and Site needs to be reset here so that the new account will show the correct data.
      if (this.deviceService.isMobile) {
        this.logger.info('Resetting Space and Site for logged in user.')
        this.sharedService.resetCurrentSpace();
        this.sharedService.resetCurrentSite();
      } else {
        this.logger.info('Resetting Space and Site for logged in user.');
        this.sharedDataService.resetCurrentSpace();
      }

      this.spaceService.getSpaces().subscribe(spaces => {
        if(spaces.length === 1) {
          this.logger.info('User has access to only one space. Routing to site selection automatically.')
          this.router.navigate(['space/' + spaces[0].id + '/select-site']);
        } else {
          this.router.navigate(['/spaces']);
        }
      });
    } else {
      this.router.navigate(['/spaces']);
    }
    return session;
  }

  /**
   * Reset password method
   * @param username username of user who wants to change password
   * @param space space of user who wants to change password
   */
  async resetPassword(username: string): Promise<void> {

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const body = new HttpParams()
      .set('email', username);

    return this.http.post(
      environment.apiUrl + '/passwords/reset',
      body,
      { headers: headers }
    ).toPromise()
      .then(() => {
        this.toasterService.showSuccessToaster('reset.email_sent');
        this.router.navigate(['/']);
      }).catch((error) => {
        this.logger.error('Error sending reset password link via email: ', error);
        this.toasterService.showErrorToaster('default.error');
      });
  }

  async setPassword(password: string, confirmKey: string): Promise<void> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(
      environment.apiUrl + '/passwords/set',
      { 'key': confirmKey, 'password': password },
      { headers: headers }
    ).toPromise()
      .then(() => {
        this.toasterService.showSuccessToaster('account.notify.new_password.success');
        this.router.navigate(['/login']);
      })
      .catch(error => {
        this.logger.error("Error while resetting password of the user using a confirm key: ", error);
        this.toasterService.showErrorToaster('app.error.password.reset');
        this.router.navigate(['/reset']);
      });
  }

  /**
   * Delete current local storage session object
   */
  clearSession() {
    this.sessionService.removeSession();
  }

  /**
   * Update OAuth tokens stored in session, to be used when sending authenticated requests.
   * @param accessToken New access token.
   * @param refreshToken New refresh token.
   */
  updateSessionTokens(accessToken: string, refreshToken: string): void {
    this.logger.info(`Updating session tokens. Access token: ${accessToken} | Refresh token: ${refreshToken}`);
    const session = this.sessionService.getSession();
    session.accessToken = accessToken;
    session.refreshToken = refreshToken;
    this.sessionService.setSession(session);
  }

  /**
   * Try to use refresh_token to renew access_token.
   * If no refresh_token is available (user did not check "Remember me"), log him out.
   * This operation happens in the background, if you need to retrieve the new access_token value use internalRefreshUserSession() instead.
   */
  refreshUserSession(): void {
    // Only force a refresh if one is not already in progress
    if (!this.isTokenRefreshInProgress()) {
      this.internalRefreshUserSession().subscribe();
    }
  }

  /**
   * Wait until mutex holds a non-null value, which means a new access_token is available, then return it.
   * @return Observable returning a new access_token to be available.
  */
  public waitForNewAccessToken(): Observable<any> {
    return this.tokenRefreshMutex.pipe(
      filter(result => result !== null),
      take(1));
  }

  /**
   * Checks if a token refresh is currently in progress or not depending on mutex value.
   */
  public isTokenRefreshInProgress(): boolean {
    return this.tokenRefreshMutex.getValue() == null;
  }

  /**
   * Try to use refresh_token to renew access_token and returns an observable holding the new access_token value.
   * If no refresh_token is available (user did not check "Remember me"), log him out.
   * Acquire token refresh mutex during operations in order to block concurrent refreshes.
   * Will wait for mutex to be released to finish before returning.
   * @returns Observable holding the new access_token value.
   */
  internalRefreshUserSession(): Observable<string> {
    // If an access_token refresh is already in progress, wait for it to complete and return
    if (this.isTokenRefreshInProgress()) {
      return this.waitForNewAccessToken();
    }

    // Otherwise, try to refresh access_token
    else {
      // Lock mutex
      this.tokenRefreshMutex.next(null);

      // Get refresh_token from current session
      const refreshToken = this.sessionService.getSession().refreshToken;

      // If refreshToken is not null ("remember me" option was checked), try to use refresh token in order to renew access token
      if (refreshToken != null) {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new HttpParams()
          .set('refresh_token', refreshToken)
          .set('grant_type', 'refresh_token');

        this.logger.info(`Sending request for new access_token using refresh_token ${refreshToken}`);
        return this.http.post<any>(
          environment.apiUrl + AuthService.AUTH_TOKEN_URL_PART,
          body,
          { headers: headers }
        ).pipe(
          tap(fetchedSession => { // Update curent session
            this.updateSessionTokens(fetchedSession.access_token, fetchedSession.refresh_token);
          }),
          map(fetchedSession => fetchedSession.access_token), // Return new access_token value
          tap(accessToken => this.tokenRefreshMutex.next(accessToken)), // Release mutex with new access_token value
          catchError(error => { // Release mutex and throw error
            this.tokenRefreshMutex.next(AuthService.NO_TOKEN_MUTEX_VALUE);
            throw error;
          }),
        );
      }
      // If refreshToken is null ("remember me" option was not checked), user should be logged out when his access_token has expired
      else {
        this.logger.info(`No refresh_token found: access_token has expired, logging out...`);
        this.tokenRefreshMutex.next(AuthService.NO_TOKEN_MUTEX_VALUE);
        this.redirectTologout();
      }
    }
    return null;
  }

  redirectTologout(): void {
    this.toasterService.showErrorToaster('error.session_expired');
    this.logoutService.disconnectServices();
  }

  signUp(
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    organization: string = null,
    organisationType: string = null,
    organisationSize: OrganisationSize = null,
    phoneNumber: string = null,
    key: string = null
  ): Promise<void> {
    const body = {
      'email': username,
      'password': password,
      'firstName': firstName,
      'lastName': lastName,
      'organization': organization,
      'organisationType': organisationType,
      'organisationSize': organisationSize,
      'phoneNumber': phoneNumber,
    };

    let queryParam = '';
    if (key != null) {
      queryParam = `?key=${key}`;
    }

    this.logger.info(`Try to register account...`);
    return this.http.post(environment.apiUrl + '/accounts' + queryParam, body).toPromise().then(
      (data: any) => {
        this.logger.info('Register success !');
        // Sign up intercom user
        const intercomUser = new IntercomUser(username, firstName, lastName, phoneNumber);
        this.intercomService.signUpIntercomUser(intercomUser, organization);
        this.signIn(body.email, body.password, false, true);
      },
      (error: any) => {
        this.logger.error('Failed to register account');
        // 409 error status - user already exist
        if (error !== null && error.status === HttpStatus.CONFLICT) {
          this.toasterService.showErrorToaster('account.notify.conflict.error');
          this.logger.error(`Failed to register account: User already exists`);
        } else {
          this.logger.error(`Failed to register account. Error: ${error}`);
          this.toasterService.showErrorToaster('account.notify.unknown.error');
        }
      }
    );
  }

  /**
   * resendValidationMail
   * @param email
   */
  resendValidationMail(email: string) {
    let queryParam = '';
    if (email != null) {
      queryParam = `?email=${email}`;
    }
    this.logger.info(`Try to resend validation mail...`);
    this.http.get(environment.apiUrl + '/accounts/resend' + queryParam)
      .subscribe(
        (data: any) => {
          this.logger.info('Resend success !');
          this.toasterService.showSuccessToaster('reset.email_sent');
        },
        (error: any) => {
          this.logger.error(`Failed to resend validation mail. Error: ${error}`);
          this.toasterService.showErrorToaster('account.notify.conflict.error');
        }
      );
  }

  async getOrganisationTypes() {
    const language = window.navigator.language;
    const url = this.urlGiverService.giveGetOrganisationTypesUrl();
    const languageHeader = new HttpHeaders()
      .set('Accept-Language', language.split('-')[0].toUpperCase());
    return this.http.get<any>( url,
      { headers: languageHeader}).toPromise();
  }

  async getOrganisationSizes() {
    const language = window.navigator.language;
    const url = this.urlGiverService.giveGetOrganisationSizesUrl();
    const languageHeader = new HttpHeaders()
      .set('Accept-Language', language.split('-')[0].toUpperCase());
    return this.http.get<any>( url,
      { headers: languageHeader}).toPromise();
  }

  confirmUserEmail(key: string): Observable<{email: string}> {
    this.logger.info('TRY to confirm user email...');
    return this.http.post<{email: string}>(environment.apiUrl + '/accounts/' + key, {});
  }

  validateUserInvitation(key: string): Observable<{email: string}> {
    this.logger.info('TRY to confirm user invitation...');
    return this.http.get<{email: string}>(environment.apiUrl + '/accounts/invitation/' + key, {});
  }

  isUser2FAEnabled(user: User): boolean {
    return user.isUser2FAEnabled;
  }

  isUser2FAStrictModeEnabled(user: User): boolean {
    return user.is2FAStrictModeEnabled;
  }

  isPhoneNumberVerified(user: User): boolean {
    return user.isPhoneNumberVerified;
  }

  displayPhoneVerificationModal(): void {
    const user = this.sessionService.getCurrentUser();
    const session = this.sessionService.getSession();
    if (!session.is2FAAuthenticated) {
      if (this.isUser2FAEnabled(user) && !this.isPhoneNumberVerified(user)) {
        const modalRef = this.modalService.open(PhoneNumberVerificationModalComponent,
          {
            centered: true,
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            windowClass: 'phone-number-verification-component'
          }
        );
        const phoneNumberModal: PhoneNumberVerificationModalComponent = modalRef.componentInstance;
        phoneNumberModal.setCallbacks(
          () => {
            modalRef.close();
            this.displayOTPVerificationModal();
          },
          () => {
            modalRef.dismiss();
            this.userVerifiedWith2FA();
            this.navigateUserManuallyAfter2FAValidation();
          }
        );
      }
    }
  }

  async displayRequestOtpModal(): Promise<void> {
    const user = this.sessionService.getCurrentUser();
    const userPhoneNumber = this.phoneNumberMaskPipe.transform(user.phoneNumber);
    const cssClass = this.deviceService.isMobile ? 'request-otp-alert-box-mobile' : 'request-otp-alert-box';
    this.translateService.get([
      'two_factor.authentication.title',
      'two.factor.authentication.request_otp_alert',
      'two.factor.authentication.request_verification_code',
      'header.log_out'
    ], {phoneNumber: userPhoneNumber}).pipe(
      mergeMap(async translations => {
        const alert = await this.alertController.create({
          header: translations['two_factor.authentication.title'],
          message: translations['two.factor.authentication.request_otp_alert'],
          backdropDismiss: false,
          keyboardClose: false,
          cssClass: cssClass,
          buttons: [
            {
              text: translations['header.log_out'],
              role: 'cancel',
              cssClass: 'logout-btn',
              handler: () => {
                this.logoutService.disconnectServices();
              },
            },
            {
              text: translations['two.factor.authentication.request_verification_code'],
              cssClass: 'confirm-btn',
              handler: () => {
                this.displayOTPVerificationModal();
              },
            },
          ],
        });
        await alert.present();
      })
    ).toPromise();
  }

  displayOTPVerificationModal(): void {
    const user = this.sessionService.getCurrentUser();
    const session = this.sessionService.getSession();
    if (!session.is2FAAuthenticated) {
      if (this.isUser2FAEnabled(user)) {
        const otpModalRef = this.modalService.open(OtpVerificationComponent,
          {
            centered: true,
            backdrop: 'static',
            size: 'xl',
            keyboard: false,
            windowClass: 'otp-verification-component'
          });
        const otpVerificationComponent: OtpVerificationComponent = otpModalRef.componentInstance;
        otpVerificationComponent.setCallbacks(
          () => {
            otpModalRef.close();
            this.userVerifiedWith2FA();
            this.navigateUserManuallyAfter2FAValidation();
          }
        );
      }
    }
  }

  display2FAModals(): void {
    const user = this.sessionService.getCurrentUser();
    const session = this.sessionService.getSession();
    if (!session.is2FAAuthenticated && NetworkStatus.isOnline) {
      if (this.isUser2FAEnabled(user) && !this.isPhoneNumberVerified(user)) {
        this.displayPhoneVerificationModal();
      } else if (this.isUser2FAEnabled(user) && this.isPhoneNumberVerified(user)) {
        this.displayRequestOtpModal();
      }
    }
  }

  requestOtp(): Promise<RequestOTPResponse> {
    const requestOtpUrl = this.urlGiverService.giveRequestOtpAPIUrl();
    return NetworkStatus.waitForOnlineStatus().toPromise()
      .then(() => this.http.get<RequestOTPResponse>(requestOtpUrl).toPromise());
  }

  submitOtp(enteredOtp: string): Promise<void> {
    const submitOtpUrl = this.urlGiverService.giveSubmitOtpAPIUrl();
    const requestBody = {
      otp : enteredOtp
    };
    return NetworkStatus.waitForOnlineStatus().toPromise()
      .then(() => this.http.post<void>(submitOtpUrl, requestBody).toPromise());
  }

  userVerifiedWith2FA(): void {
    const session = this.sessionService.getSession();
    session.is2FAAuthenticated = true;
    this.sessionService.setSession(session);
  }

  dismissAllModals(): void {
    this.modalService.dismissAll();
  }

  navigateUserManuallyAfter2FAValidation(): void {
    if (this.router.url === '/' || '/login') {
      this.router.navigateByUrl('/');
    }
  }

  updateUserSessionWithUserDetails(): Promise<Session> {
    const currentSession = this.sessionService.getSession();
    const userDetailsAPIHeaders = new HttpHeaders()
      .set('Authorization', `Bearer ${currentSession.accessToken}`);

    return this.http.get<any>(
      this.urlGiverService.giveUserDetailsAPIUrl(),
      { headers: userDetailsAPIHeaders }
    ).toPromise()
      .then(userDetails => {
        currentSession.user.isPhoneNumberVerified = userDetails.isPhoneNumberVerified;
        currentSession.user.isUser2FAEnabled = userDetails.is2FAEnabled;
        currentSession.user.is2FAStrictModeEnabled = userDetails.is2FAStrictModeEnabled;
        // Set user session inside local storage
        this.sessionService.setSession(currentSession);
        return currentSession;
      }).catch((_errorResponse) => {
        const errorResponse: HttpErrorResponse = _errorResponse;
        this.toasterService.showErrorToaster('default.error');
        throw errorResponse;
      });
  }
}
