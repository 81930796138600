import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DeviceService } from '@services/device.service';
import { SummaryConfigurationPopupComponent } from '../summary-configuration-popup.component';

@Component({
  selector: 'app-summary-configuration-popup-mobile',
  templateUrl: './summary-configuration-popup-mobile.component.html',
  styleUrls: ['./summary-configuration-popup-mobile.component.sass']
})
export class SummaryConfigurationPopupMobileComponent extends SummaryConfigurationPopupComponent {
  public isCollapsedDate: boolean = false;
  public isCollapsedTitle: boolean = false;

  constructor(
    protected formBuilder: FormBuilder,
    public deviceService: DeviceService,
  ) {
    super(
      formBuilder,
      deviceService,
    );
  }
}
