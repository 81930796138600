import { OnInit, Input, Directive } from '@angular/core';
// Services
import { UiDatetimePickerService, UiDatetimePickerTranslateService } from '../../../services';

/**
 * Component for displaying a calendar and a timepick for selecting a date / time.
 * @author Julien Bertacco 2019.
 */

@Directive()
export class UiCommonDatetimePickerComponent implements OnInit {
  @Input() dateFromParent: Date;
  public type: 'startDatetime' | 'endDatetime';
  public calendarArray = [];
  public date = new Date();
  public selectedDate = new Date();
  public selectedMonth: string;
  public daysOfWeek = [];
  public months = [];
  public minutes = [];
  public hours = [];
  private selectedMinutes: number;
  private selectedHours: number;
  constructor(
    public datetimePickerService: UiDatetimePickerService,
    protected datetimeTranslateService: UiDatetimePickerTranslateService,
  ) { }
  /**
   * À l'initialisation, on récup les noms des mois / jours traduit depuis datetimePickerService
   * pour les afficher dans le composant.
   * Par la même on s'occupe de générer le calendrier dans un array.
   * On vérifie également que les données en entrée sont bien présente.
   */
  ngOnInit(): void {
    this.init();
  }
  init(): void {
    this.type = this.datetimePickerService.type;
    this.months = this.datetimeTranslateService.getMonths();
    this.daysOfWeek = this.datetimeTranslateService.getDays();
    this.setDate();
    this.completeCalendar();
    this.generateHoursMinutes();
  }
  setDate(): void {
    if (this.dateFromParent) {
      this.date = new Date(this.dateFromParent);
    } else {
      if (this.isEndDatetime()) {
        if (this.datetimePickerService.endDatetime) {
          this.date = this.datetimePickerService.endDatetime ? new Date(this.datetimePickerService.endDatetime) : new Date();
          this.forceHoursIfTimeHidden(this.date, 'end');
          this.selectedHours = this.date.getHours();
        } else {
          this.date = this.datetimePickerService.startDatetime ? new Date(this.datetimePickerService.startDatetime) : new Date();
          this.date.setHours(this.date.getHours() + 1);
          this.forceHoursIfTimeHidden(this.date, 'end');
          this.selectedHours = this.date.getHours();
        }
      } else {
        this.date = this.datetimePickerService.startDatetime ? new Date(this.datetimePickerService.startDatetime) : new Date();
        this.forceHoursIfTimeHidden(this.date, 'start');
        this.selectedHours = this.date.getHours();
      }
    }
    this.selectedMonth = this.months[this.date.getMonth()];
    this.selectedDate = this.date;
  }
  completeCalendar(): void {
    this.calendarArray = this.datetimePickerService.completeCalendar(this.date).slice(0);
  }
  lastMonth(): void {
    const { calendar, date } = this.datetimePickerService.lastMonth(this.date);
    this.calendarArray = calendar.slice(0);
    this.date = date;
    this.setHoursMinutes();
  }
  nextMonth(): void {
    const { calendar, date } = this.datetimePickerService.nextMonth(this.date);
    this.calendarArray = calendar.slice(0);
    this.date = date;
    this.setHoursMinutes();
  }
  selectDate(day: number): void {
    this.selectedDate.setFullYear(this.date.getFullYear(), this.date.getMonth(), day);
    this.selectedMonth = this.months[this.date.getMonth()];
  }
  generateHoursMinutes(): void {
    const { hours, minutes, selectedMinutes } = this.datetimePickerService.generateHoursMinutes(this.date);
    this.hours = hours;
    this.minutes = minutes;
    this.selectedMinutes = selectedMinutes;
  }
  selectHours(hours: string) {
    this.selectedHours = parseInt(hours, 10);
  }
  selectMinutes(minutes: string) {
    this.selectedMinutes = parseInt(minutes, 10);
  }
  setHoursMinutes(): void {
    this.date.setMinutes(this.selectedMinutes);
    this.date.setHours(this.selectedHours);
  }
  save(): void {
    this.setHoursMinutes();
    this.datetimePickerService.updateDatetime(this.selectedDate);
  }
  cancel(): void {
    this.close();
  }
  close(): void {
    // Reset default values to avoid behavior leak between different calls
    this.datetimePickerService.hideTime = false;
    this.datetimePickerService.hideShortcut = false;
    this.datetimePickerService.canClear = false;
  }
  saveAndClose(): void {
    this.save();
    this.close();
  }
  isEndDatetime(): boolean {
    return this.type === 'endDatetime';
  }
  clear(): void {
    if (this.isEndDatetime()) {
      this.datetimePickerService.endDatetime = null;
    }
    else {
      this.datetimePickerService.startDatetime = null;
    }
    this.datetimePickerService.updateDatetime();
    this.close();
  }
  isCurrentDate(day): boolean {
    const currentDate = new Date();
    if (currentDate.getDate() === day
      && currentDate.getMonth() === this.date.getMonth()
      && currentDate.getFullYear() === this.date.getFullYear()) {
      return true;
    }
    return false;
  }
  forceHoursIfTimeHidden(date: Date, type: 'start' | 'end') {
    if (this.datetimePickerService.hideTime) {
      if (type ===  'start') {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
      }
      if (type ===  'end') {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
      }
    }
  }

  isActiveDate(day: number): boolean {
    return ((day === this.selectedDate.getDate()) && (this.months[this.date.getMonth()] === this.selectedMonth) && (this.date.getFullYear() === this.selectedDate.getFullYear()));
  }
}
