import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Asset, AssetCategory } from '@models/asset';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '@services/asset.service';
import { ToasterService } from '@services/toaster.service';
import { NormalisationService } from '@services/normalization.service';
import { SharedService } from '@services/shared/shared.service';
import { PostStatusStates, PostStatusService } from '@services/post-status.service';
import { QuantityUnitsService } from '@services/quantity-units.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { NetworkStatus } from '@models/synchronization/network-status';

type SuccessCallback = (asset?: Asset) => void;
type DismissCallback = () => void;

@Component({
  selector: 'app-resources-mobile-modal',
  templateUrl: './resources-mobile-modal.component.html',
  styleUrls: ['./resources-mobile-modal.component.scss']
})
export class ResourcesMobileModalComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input() private successCallback: SuccessCallback;
  @Input() private dismissCallback?: DismissCallback;
  @Input() resourceType: AssetCategory;
  @Input() assetName: string = '';
  @Input() newItem: boolean = false;
  @Input() assetIdToModify: string = '';
  @Input() siteId: string;
  @Input() allAssets: any[];

  quantitiesUnits = {};

  public formGroup: FormGroup;

  public asset: any;
  public showPrice: boolean = false;
  public title: string;
  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  originalOrder = QuantityUnitsService.originalOrder;

  constructor(
    private formBuilder: FormBuilder,
    protected translateService: TranslateService,
    protected toasterService: ToasterService,
    private assetService: AssetService,
    public alertController: AlertController,
    public sharedService: SharedService,
    private postStatusService: PostStatusService,
    private quantityUnitsService: QuantityUnitsService
  ) { 
    super()
   }

  ngOnInit() {
    this.quantityUnitsService.watchQuantityUnits.pipe(
      takeUntil(this.destroy)
    ).subscribe((quantityUnits) => {
      this.quantitiesUnits = quantityUnits;
    })

    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      jobRole: null,
      price: 0,
      quantityUnit: [null],
    });
    this.title = 'label.' + this.resourceType.toLowerCase();
    if (!this.newItem) {
      this.assetService.getOnceById(this.assetIdToModify).then(asset => {
        this.asset = asset;
        if (this.asset.category === 'Equipment') {
          this.showPrice = true;
          this.formGroup.setValue({
            name: this.asset.name,
            jobRole: null,
            price: this.asset.cost,
            quantityUnit: null,
          });
        }
        else if (this.asset.category === 'Labours') {
          this.showPrice = true;
          this.formGroup.setValue({
            name: this.asset.name,
            jobRole: this.asset.jobRole,
            price: this.asset.cost,
            quantityUnit: null,
          });
        }
        else {
          this.showPrice = false;
          this.formGroup.setValue({
            name: this.asset.name,
            jobRole: null,
            price: 0,
            quantityUnit: this.asset.quantityUnit ? this.asset.quantityUnit : null,
          });
        }
      });

    }
    else {
      this.asset = new Asset();
      this.asset.category = this.resourceType;
      if ((this.resourceType === 'Labours') || (this.resourceType === 'Equipment')) {
        this.showPrice = true;
        this.formGroup.setValue({
          name: '',
          jobRole: null,
          price: 0,
          quantityUnit: null
        });
      }
      else {
        this.showPrice = false;
        this.formGroup.setValue({
          name: this.assetName,
          jobRole: null,
          price: 0,
          quantityUnit: null,
        });
      }
    }
  }

  isResourceTypeLabour(): boolean {
    return this.resourceType === AssetCategory.LABOURS
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  save(): void {
    if (this.isOnline) {
      if (!this.formGroup.invalid) {
        if (this.showPrice) {
          this.asset.cost = this.formGroup.value.price;
        }
        const jobRole = this.formGroup.value.jobRole
        if (jobRole && jobRole !=='') {
          this.asset.jobRole = jobRole.trim();
        } else {
          this.asset.jobRole = null;
        }
        this.asset.name = this.formGroup.value.name.trim();
        const quantityUnit = this.formGroup.value.quantityUnit;
        if (quantityUnit && quantityUnit !=='null') {
          this.asset.quantityUnit = quantityUnit;
        } else {
          this.asset.quantityUnit = null;
        }
        if (this.newItem) {
          if (this.siteId) {
            this.asset.siteId = this.siteId;
          }
          this.create(this.asset);
        }
        else {
          this.update(this.asset);
        }
      }
    } else {
      this.toasterService.showWarningToaster("device.offline.asset_information_update");
    }
  }

  checkIntegrity(asset): boolean {
    return asset.name !== '';
  }

  async create(newAsset: Asset): Promise<void> {
    this.postStatusService.communicateAssetPostStatus(PostStatusStates.inProgress, newAsset.id);
    if (this.allAssets.every(asset => NormalisationService.checkAssetName(asset.name) !== NormalisationService.checkAssetName(newAsset.name))) {
      if (this.checkIntegrity(newAsset)) {
        this.assetService.create(this.asset).then(_addedAsset => {
          this.successCallback(_addedAsset);
        });
      }
    } else {
      this.toasterService.showWarningToaster('validation_rules.recordsamename');
    }
    // check if any error occurs while adding the asset and filter the asset list if any error occurs.
    this.filterAssetsListOnError(newAsset.siteId);
  }

  async update(editedAsset: Asset): Promise<void> {
    this.postStatusService.communicateAssetPostStatus(PostStatusStates.inProgress, editedAsset.id);
    if (this.allAssets.every(asset =>
       NormalisationService.checkAssetName(asset.name) !== NormalisationService.checkAssetName(editedAsset.name) || (asset.id === editedAsset.id))) {
      if (this.checkIntegrity(editedAsset)) {
        this.assetService.update(this.asset).then(_addedAsset => {
          this.successCallback(_addedAsset);
        });
      }
    } else {
      this.toasterService.showWarningToaster('validation_rules.recordsamename');
    }
    // check if any error occurs while updating the asset and filter the asset list if any error occurs.
    this.filterAssetsListOnError(editedAsset.siteId);
  }

  delete(): void {
    this.postStatusService.communicateAssetPostStatus(PostStatusStates.inProgress, this.asset.id);
    this.assetService.delete(this.asset).then(()=>{
      this.successCallback();
    });
    this.filterAssetsListOnError(this.asset.siteId);
  }

  async showConfirmationAlert() {
    const translatedAlert: any = {};
    translatedAlert.cancelBtn = this.translateService.instant('admin.delete.sure');
    translatedAlert.text = this.translateService.instant('admin.delete.btn.cancel');
    translatedAlert.okBtn = this.translateService.instant('admin.delete.btn.delete');
    const alert = await this.alertController.create({
      header: translatedAlert.text,
      buttons: [
        {
          text: translatedAlert.cancelBtn,
          role: 'cancel',
        },
        {
          text: translatedAlert.okBtn,
          cssClass: 'text-danger font-weight-bold',
          handler: () => {
            // Approve event on confirmation
            this.delete();
          },
        },
      ],
    });
    await alert.present();
  }

  // check if error occurs in addition or updation of assets
  async filterAssetsListOnError(siteId): Promise<void> {
    await this.postStatusService.waitForEndOfAssetPost().then(() => {
      this.postStatusService.getAssetPostStatus().subscribe(val => {
        if (val.status === PostStatusStates.error) {
          if (siteId !== 'null') {
            this.assetService.filterForCurrentSite();
          }
          else {
            this.assetService.filterForCurrentSpace();
          }
        }
      })
    })
  }
}
