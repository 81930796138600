export enum FaultyEventErrorCode {
    EVENT_NOT_FOUND = 'EVENT_NOT_FOUND',
    ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
    CONTRACTOR_NOT_FOUND = 'CONTRACTOR_NOT_FOUND',
    LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
    TAG_NOT_FOUND = 'TAG_NOT_FOUND',
    EVENTPICTURE_NOT_FOUND = 'EVENTPICTURE_NOT_FOUND',
    EVENT_EXISTS = 'EVENT_EXISTS',
    TASK_EXISTS = 'TASK_EXISTS',
    ASSET_EXISTS = 'ASSET_EXISTS',
    EVENT_PICTURE_EXISTS = 'EVENT_PICTURE_EXISTS',
    APPROVED_EVENT_MUTATION = 'APPROVED_EVENT_MUTATION',
    PAYMENT_REQUIRED = 'PAYMENT_REQUIRED'
}

export class FaultyEventErrorCodeUtil {
    public static getFaultyEventErrorString(errorCode: string): string {
        switch (errorCode) {
          case FaultyEventErrorCode.EVENT_NOT_FOUND:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.EVENT_NOT_FOUND;
          case FaultyEventErrorCode.ASSET_NOT_FOUND:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.ASSET_NOT_FOUND;
          case FaultyEventErrorCode.CONTRACTOR_NOT_FOUND :
            return 'events.faulty.error.message.' + FaultyEventErrorCode.CONTRACTOR_NOT_FOUND;
          case FaultyEventErrorCode.LOCATION_NOT_FOUND:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.LOCATION_NOT_FOUND;
          case FaultyEventErrorCode.TAG_NOT_FOUND:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.TAG_NOT_FOUND;
          case FaultyEventErrorCode.EVENTPICTURE_NOT_FOUND:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.EVENTPICTURE_NOT_FOUND;
          case FaultyEventErrorCode.EVENT_EXISTS:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.EVENT_EXISTS;
          case FaultyEventErrorCode.EVENT_PICTURE_EXISTS:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.EVENT_PICTURE_EXISTS;
          case FaultyEventErrorCode.APPROVED_EVENT_MUTATION:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.APPROVED_EVENT_MUTATION;
          case FaultyEventErrorCode.PAYMENT_REQUIRED:
            return 'events.faulty.error.message.' + FaultyEventErrorCode.PAYMENT_REQUIRED;
          default:
            return 'events.faulty.error.message.DEFAULT';
        }
    }
}
