import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberMask'
})
@Injectable({
  providedIn: 'root'
})
export class PhoneNumberMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const length = value.length;
      if (length > 4) {
        const lastFourDigits = value.substr(length - 4, length);
        const maskedString = 'X'.repeat(length - 4);
        return `${maskedString}${lastFourDigits}`;
      }
      else {
        return value;
      }
    }
    else {
      // return empty string for null or undefined phone number
      return '';
    }
  }
}
