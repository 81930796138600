import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { timer, Subscription } from 'rxjs';
import { UrlGiverService } from './url-giver.service';
import { NetworkStatus } from '../models/synchronization/network-status';
import { Logger } from './logger';


@Injectable({
  providedIn: 'root'
})
export class OfflineCheckerService {

  private static readonly CHECK_DELAY_MS = 30000;

  private readonly offlineCheckTimer$ = timer(0, OfflineCheckerService.CHECK_DELAY_MS);
  private timerSubscription: Subscription;
  private https: HttpClient;

  constructor(
    private handler: HttpBackend,
    private urlGiver: UrlGiverService,
  ) {
    this.https = new HttpClient(handler); // Bypass interceptors for requests in this service
  }

  /** Start checking periodically if server can be reached. */
  public startChecking(): void {
    if (!this.timerSubscription) {
      Logger.info('Site Diary may be offline, start pinging every 30 seconds.');
      this.timerSubscription = this.offlineCheckTimer$.subscribe(() => {
        this.ping();
      });
    }
  }

  /** Stop checking periodically if server can be reached. */
  public stopChecking(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  /**
   * Send test connection to /ping to check if server can be reached.
   * If yes, we are actually offline: stop periodic check and try to restart STOMP connection.
   */
  public ping(): void {
    NetworkStatus.isPinging = true;
    this.https.options(`${this.urlGiver.giveAPIUrl()}/ping`).subscribe(
      () => {
        Logger.info('Ping succeeded, Site Diary is online');
        NetworkStatus.isOnline = true;
        NetworkStatus.isPinging = false;
        this.stopChecking();
      }, error => {
        Logger.info(`Ping failed (error: ${error.status}), Site Diary is offline`);
        NetworkStatus.isOnline = false;
        NetworkStatus.isPinging = false;
      });
  }
}
