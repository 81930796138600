<div [ngClass]="deviceIsMobile? 'mobile-container': 'container'" [formGroup]="parentFormGroup">
  <span [ngClass]="{'mobile-label': deviceIsMobile}" class="label">{{ customField.title }}</span>
  <div *ngIf="!deviceIsMobile"  class="dropdown-container">
    <ng-select
    [items]="dropdownOptions"
    bindValue="id"
    class="dropdown"
    bindLabel="value"
    formControlName="value">
    </ng-select>
    <i class="fas fa-caret-down" [ngClass]="{'is-disabled': !isEdited}"></i>
  </div>
  <mat-form-field class="dropdown-field" *ngIf="deviceIsMobile">
    <mat-select class="dropdown-options" formControlName="value">
      <mat-option *ngFor="let option of dropdownOptions" [value]="option.id">{{ option.value }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
