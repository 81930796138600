import { Injectable } from '@angular/core';
// Rxjs
import { Subject, Observable } from 'rxjs';
// I18n Service
import { InternationalizationService } from '@services/i18n/internationalization.service';

/**
 * Datetime Picker utils Service
 * @author Julien Bertacco 2019.
 */
@Injectable()
export class UiDatetimePickerService {
  public selectedDate: string;
  // Défini le type de calendrier à afficher : startDatetime / endDatetime
  public type: 'startDatetime' | 'endDatetime';
  public startDatetime: Date;
  public endDatetime: Date;
  public calendarArray = [];
  public hideTime: boolean = false;
  public hideShortcut: boolean = false;
  public canClear: boolean = false;
  private nbDays;
  constructor(
    private i18n: InternationalizationService
  ) { }

  completeCalendar(date): any[] {
    this.nbDays = this.getNumberOfDays(date.getFullYear(), date.getMonth());
    this.calendarArray = [];
    let startWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    // Shift start of week by 1 so that Monday becomes first day of the week
    // JS uses Sunday by default, so no shift when using a CRAZY LANGUAGE
    if (!this.i18n.isCrazyLanguage()) {
      startWeek--;
      if (startWeek === -1) {
        startWeek = 6;
      }
    }
    for (let i = 0; i < startWeek; i++) {
      this.calendarArray.push(' ');
    }
    let nb = 1;
    for (let i = 0; i < this.nbDays; i++) {
      this.calendarArray.push(nb++);
    }
    return this.calendarArray;
  }


  updateDatetime(date?: Date) {
    if (this.type === 'endDatetime') {
      this.endDatetime = date ? date : this.endDatetime;
    } else {
      this.startDatetime = date ? date : this.startDatetime;
    }
  }

  generateHoursMinutes(date): { hours: any[]; minutes: any[]; selectedMinutes: any; } {
    const hours = [];
    const minutes = [];
    let selectedMinutes: number = 0;
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    for (let i = 0; i <= 45; i += 15) {
      if (i <= date.getMinutes()) {
        selectedMinutes = i;
      }
      minutes.push(i);
    }
    return { hours, minutes, selectedMinutes };
  }

  lastMonth(date: Date): {
    calendar: any[], date: Date
  } {
    return this.incrementMonth(date, -1);
  }
  nextMonth(date: Date): {
    calendar: any[], date: Date
  } {
    return this.incrementMonth(date, 1);
  }
  private incrementMonth(date: Date, increment: number): {
    calendar: any[], date: Date
  } {
    let dayOfMonth = date.getDate();
    const incrementedMonth = date.getMonth() + increment;
    let incrementedDate = new Date(date.getFullYear(), incrementedMonth, dayOfMonth);
    /**
     * When doing the incrementation above: if original dayOfMonth is above the maximum number of days
     * in the new incremented month, then the date automatically jumps to the next month.
     *
     * Example:
     *   Original date: 31/08
     *   Increment: +1 month
     *   Result: 01/10
     *   Intended target: 30/09
     *
     * To fix that behavior, we adjust dayOfMonth by decrementing it until the new date
     * fits the intended target, e.g. it uses the RIGHT incremented month.
     *
     * Special case: when switching year, the check needs to be specifically skipped.
     * This is because DD/12/YY + 1 month becomes DD/01/YY+1 (resp. DD/01/YY - 1 month becomes DD/12/YY-1).
     * No additional action is needed because December and January both have 31 days.
     */
    while (incrementedDate.getFullYear() === date.getFullYear() && incrementedDate.getMonth() !== incrementedMonth) {
      incrementedDate = new Date(date.getFullYear(), incrementedMonth, --dayOfMonth);
    }
    return { calendar: this.completeCalendar(incrementedDate), date: incrementedDate };
  }

  /**
   * Retourne le nombre de jours dans un mois en fonction de l'année et du mois.
   * @param year
   * @param month
   */
  private getNumberOfDays(year, month): number {
    return 32 - new Date(year, month, 32).getDate();
  }

}
