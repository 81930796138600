import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export abstract class AbstractButtonComponent {
  @Output() click = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() type: 'submit' | 'button' = 'button';
  @Input() size: 'small' | 'normal' = 'normal';

  constructor(
  ) {
  }

  clickButton() {
    this.click.emit();
  }

}
