import { Component } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';


@Component({
  selector: 'app-draft-button',
  templateUrl: './draft-button.component.html',
  styleUrls: ['./draft-button.component.scss'],
})
export class DraftButtonComponent extends AbstractButtonComponent {

}
