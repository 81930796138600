import { Injectable } from '@angular/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

import { DeviceService } from './device.service';
import { Logger } from './logger';

@Injectable({
  providedIn: 'root'
})
export class ScreenManipulationService {

  private isSidebarOpenedInLandscape = new BehaviorSubject(false);
  getIsSidebarOpenedInLandscape = this.isSidebarOpenedInLandscape.asObservable();
  public isTabletInLandscapeMode = new BehaviorSubject(false);
  getIsTabletInLandscapeMode = this.isTabletInLandscapeMode.asObservable();

  constructor(
    private deviceService: DeviceService,
    private screenOrientation: ScreenOrientation,
    private logger: NGXLogger,
  ) { }

  /**
   * Lock screen in position depending of type of device:
   * - Android: Portrait
   * - Others: Portrait primary
   * see {@link ScreenOrientation} for futher details
   */
  public lockScreenOrientation(): void {
    if (this.deviceService.hasCordova) {
      // Unlock screen orientation in tablet devices
      // NOTE: when testing on chrome browser emulated devices the following will not get executed even if device chosen is a tablet
      if(this.deviceService.isTablet) {
        this.screenOrientation.unlock();
        this.screenOrientationSubscription();
      }
      else {
        const orientationType =
        this.deviceService.isAndroid ? this.screenOrientation.ORIENTATIONS.PORTRAIT : this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY;
      this.screenOrientation.lock(orientationType)
        .then(() => {
          this.logger.debug('Lock screen orientation in PORTRAIT');
        })
        .catch(err => {
          this.logger.error('Error during locking the orientation of the screen in PORTRAIT: ', err);
        });
      }
    }
  }

  public isTabletInLandscape(): boolean {
    if(this.deviceService.isTablet) {
      return this.screenOrientation.type.includes('landscape');
    }
    return false;
  }

  /**
   * Method used to display or hide the Sidemenu in tablet landscape mode
   */
  public updateIsSidebarOpenedInLandscape(type: boolean) {
    this.isSidebarOpenedInLandscape.next(type);
  }

  /**
   * Method to subscribe to the screen orientation onChange observable
   */
  public screenOrientationSubscription(): void {
    if(this.deviceService.isTablet) {
      // updating the value as false here to fix the bug where sidemenu is
      // not displayed when app is opened directly in landscape mode
      this.isSidebarOpenedInLandscape.next(false);
      this.isSidebarOpenedInLandscape.next(this.isTabletInLandscape())
      this.isTabletInLandscapeMode.next(this.isTabletInLandscape());
      this.screenOrientation.onChange().subscribe(() => {
        this.isTabletInLandscapeMode.next(this.isTabletInLandscape());
      });
    }
  }
}
