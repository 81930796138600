export enum ActionType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
  }

  /**
   * SyncDto Object
   * SyncDto to add in the queue for the synchronization
   */
export class SyncDto {
    action: ActionType;
    type: string;
    payload: any;
    siteId: string;

    constructor(action, type, payload) {
        this.action = action;
        this.type = type;
        this.payload = payload;
        this.siteId = null;
    }

    attachToSite(siteId: string) {
      this.siteId = siteId;
    }
}
