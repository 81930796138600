import { SyncDto } from './sync-dto';

export interface RetryDetails {
  retryAttempt: number,
  lastRetryTime: number,
  shouldRetry: boolean,
  actionSucceeded: boolean,
  actionFailed: boolean,
}
export class QueueAction {
  /**
   * @private
   * Incremental id set by Dexie
   */
  public id: string;

  constructor(
    public spaceId: string,
    public action: SyncDto,
    public retryDetails: RetryDetails
  ) {
  }
}
