import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class EventLocation implements Labelable, Identifiable {

  id: string = '';
  name?: string;

    constructor() {
    }

    public get label(): string {
      return this.name;
    }
}
