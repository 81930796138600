import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpStatus } from '@constants/http/http-status';
import { RequestOTPErrors } from '@models/2FA/request-otp';
import { SubmitOTPErrors } from '@models/2FA/submit-otp';
import { User } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { DeviceService } from '@services/device.service';
import { LogoutService } from '@services/logout/logout.service';
import { SessionService } from '@services/session.service';
import { SpinnerService } from '@services/spinner.service';
import { ToasterService } from '@services/toaster.service';
import { NGXLogger } from 'ngx-logger';

type SuccessCallback = () => void;

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.sass'],
})
export class OtpVerificationComponent implements OnInit, AfterViewInit {

  @Input() private successCallback: SuccessCallback;

  userPhoneNumber: string;
  user: User;
  enteredOTP: string = '';
  timeoutUntilResend: number;
  isIncorrectOtp: boolean = false;
  isSessionTimeout: boolean = false;
  isVerificationCodeSent: boolean = false;
  errorMessage: string;
  showErrorMessage: boolean = false;
  allowRetry: boolean = false;
  isLogoutButtonEnabled: boolean = false;
  isResendDisabled: boolean = true;

  @ViewChild('webInputText') private webInputText: ElementRef;
  @ViewChild('mobileInputText') private mobileInputText: ElementRef;

  constructor(
    public deviceService: DeviceService,
    private sessionService: SessionService,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private logoutService: LogoutService,
  ) { }

  ngOnInit() {
    this.user  = this.sessionService.getCurrentUser();
    this.userPhoneNumber = this.user.phoneNumber;
    this.requestOtp();
  }

  ngAfterViewInit(): void {
    this.focusOnInputField();
  }

  focusOnInputField(): void {
    if(this.deviceService.isMobile) {
      if(this.mobileInputText && this.mobileInputText.nativeElement) {
        this.mobileInputText.nativeElement.focus();
      }
    } else {
      if(this.webInputText && this.webInputText.nativeElement) {
        this.webInputText.nativeElement.focus();
      }
    }

  }

  isAValidOtp(): boolean {
    // check validity of OTP entered
    let otp = this.enteredOTP.trim();
    if (otp.length >= 2 && otp.length <= 10) {
      return true;
    }
    return false;
  }

  setCallbacks(
    success: SuccessCallback,
  ): void {
    this.successCallback = success;
  }

  verifyOtp() {
    if (this.isAValidOtp()) {
      this.spinnerService.activate('pulsating', null, 'administration');
      this.isIncorrectOtp = false;
      this.authService.submitOtp(this.enteredOTP)
      .then(() => {
        this.logger.info("OTP Verified");
        this.toasterService.showSuccessToaster('otp.verification.otp_verified_successfully')
        this.spinnerService.deactivate();
        this.closeModal();
      })
      .catch((error) => {
        this.showErrorMessage = true;
        this.isLogoutButtonEnabled = true;
        if (error.status === HttpStatus.UNAUTHORIZED) {
          this.toasterService.showWarningToaster('otp.verification.invalid_otp');
          this.isIncorrectOtp = true;
          this.showErrorMessage = false;
          this.isLogoutButtonEnabled = false;
          this.logger.error('Incorrect OTP', error);
        }
        else if (error.error.errorCode === SubmitOTPErrors.TEMPORARILY_BLOCKED) {
          this.errorMessage = this.translateErrorMessage('otp.verification.too_many_incorrect_otps');
          this.logger.error("Too many incorrect attempts for the day", error);
        }
        else if (error.error.errorCode === SubmitOTPErrors.OTP_EXPIRED) {
          this.isSessionTimeout = true;
          this.errorMessage = this.translateErrorMessage('otp.verification.otp_expired');
        }
        else if (error.error.errorCode === SubmitOTPErrors.TOO_MANY_REQUESTS) {
          this.errorMessage = this.translateErrorMessage('otp.verification.verify_otp_failed');
          this.logger.error("Cannot verify the verification code at the moment", error)
          this.allowRetry = true;
        }
        else {
          this.errorMessage = this.translateErrorMessage('otp.verification.verify_otp_failed');
          this.logger.error("Otp verification failed", error);
          this.allowRetry = true;
        }
        this.spinnerService.deactivate();
      });
    }
  }

  // disable resend button till next resend timeout
  updateIsResendDisabled() {
    let timeoutInterval = setInterval(() => {
      // disable resend button if current time is less than next resend at timestamp
      this.isResendDisabled = new Date().getTime() < this.timeoutUntilResend;
      if (!this.isResendDisabled) {
        // clear this interval once resend is enabled
        clearInterval(timeoutInterval);
      }
    }, 1000);
  }

  closeModal() {
    this.successCallback();
  }

  logout() {
    this.logoutService.disconnectServices();
  }

  requestOtp() {
    this.enteredOTP = '';
    this.isIncorrectOtp = false;
    this.isSessionTimeout = false;
    this.showErrorMessage = false;
    this.errorMessage = null;
    this.allowRetry = false;
    this.isLogoutButtonEnabled = false;
    this.isResendDisabled = true;
    this.spinnerService.activate('pulsating', null, 'administration');
    this.authService.requestOtp()
    .then((response) => {
      this.isVerificationCodeSent = true;
      this.logger.info("Requested OTP");
      this.timeoutUntilResend = response.nextResendAt;
      this.updateIsResendDisabled();
      this.toasterService.showSuccessToaster('otp.verification.code_sent_success');
      this.focusOnInputField();
      this.spinnerService.deactivate();
    })
    .catch((error) => {
      if (error.error.errorCode === RequestOTPErrors.TEMPORARILY_BLOCKED) {
        this.errorMessage = this.translateErrorMessage('otp.verification.too_many_incorrect_otps');
        this.logger.error("Too many incorrect attempts for the day", error);
      }
      else if (error.error.errorCode === RequestOTPErrors.TOO_MANY_REQUESTS) {
        this.errorMessage = this.translateErrorMessage('otp.verification.request_failed');
        this.logger.error("Cannot request for verification code at the moment", error);
        this.allowRetry = true;
      }
      else if (error.error.errorCode === RequestOTPErrors.INVALID_PHONE_NUMBER) {
        this.errorMessage = this.translateErrorMessage('otp.verification.invalid_phone_number');
        this.logger.error("Invalid phone number", error);
      }
      else if (error.error.errorCode === RequestOTPErrors.UNSUPPORTED_PHONE_NUMBER) {
        this.errorMessage = this.translateErrorMessage('otp.verification.unsupported_phone_number');
        this.logger.error("Cannot send OTP to the existing phone number. Not in specified countries.", error);
      }
      else if (error.error.errorCode === RequestOTPErrors.TWILIO_EXCEPTION) {
        this.errorMessage = this.translateErrorMessage('otp.verification.request_failed');
        this.logger.error("Requesting Otp failed due to twilio exception", error);
        this.allowRetry =  true;
      }
      else if (error.error.errorCode === RequestOTPErrors.PHONE_NUMBER_UNAVAILABLE) {
        this.errorMessage = this.translateErrorMessage('otp.verification.phone_number_unavailble');
        this.logger.error("The phone number is unavailable", error);
      }
      else {
        this.errorMessage = this.translateErrorMessage('otp.verification.request_failed');
        this.logger.error("Requesting Otp failed", error);
        this.allowRetry = true;
      }
      this.showErrorMessage = true;
      this.isLogoutButtonEnabled = true;
      this.spinnerService.deactivate();
    });
  }

  translateErrorMessage(errorMessage: string): string | null {
    if (errorMessage) {
      let errorText = '';
          this.translateService.get(errorMessage).subscribe(translation => {
            errorText += translation;
          });
      return errorText;
    }
    return null;
  }
}
