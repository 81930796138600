import { Injectable } from "@angular/core";
import { Subscription } from 'rxjs';

@Injectable()
export class UtilsService {

    public static safeUnsubscribe(subscription: Subscription): void {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

}
