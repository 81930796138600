import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceService } from './device.service';
import { SharedDataService } from './shared-data.service';
import { StyleService } from './style.service';
import { UrlGiverService } from './url-giver.service';
import { UserSettingsService } from './user-settings.service';
import { BehaviorSubject } from 'rxjs';

export enum AppTypes {
  SITE_DIARY = 'SITE_DIARY',
  SITE_TASK = 'SITE_TASK',
  ADMINISTRATION = 'ADMIN'
}

@Injectable({
  providedIn: 'root'
})
export class AppTypeService {

  private appType$ = new BehaviorSubject<string | null>(null);

  constructor(
    private router: Router,
    private userSettingsService: UserSettingsService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private styleService: StyleService,
    private urlGiverService: UrlGiverService,
    private deviceService: DeviceService,
  ) { }

  setAppType(type: string) {
    this.appType$.next(type);
  }

  getAppTypeObservable() {
    return this.appType$.asObservable();
  }

  observeCurrentURLForAppType() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // set app type to diary when user visits the event module or site-diary-dashboards url
        if (event.url.includes('events') || event.url.includes('site-diary-dashboards')) {
          this.userSettingsService.set('lastVisitedApp', 'diary');
          // set app type to task when user visits the task module or site-task-dashboards url
        } else if (event.url.includes('tasks') || event.url.includes('site-task-dashboards')) {
          this.userSettingsService.set('lastVisitedApp', 'tasks');
        }
      });
  }

  checkRouteForAppType(): boolean {
    const userSettings = this.userSettingsService.get();
    if (this.router.url.includes('events') || userSettings.lastVisitedApp === 'diary') {
      return true;
    } else if (this.router.url.includes('tasks') || userSettings.lastVisitedApp === 'tasks') {
      return false;
    } else {
      return true;
    }
  }

  changeAppType(appType: string): void {
    this.setAppType(appType);
    this.sharedDataService.resetAllSiteData();
    if (appType === 'tasks') {
      this.userSettingsService.set('lastVisitedApp', 'tasks');
      localStorage.setItem('selectedApp', 'tasks');
      if (this.sharedDataService.hasSiteSelected) {
        this.router.navigate(['tasks'], {relativeTo: this.route});
      }
    } else if (appType === 'diary') {
      this.userSettingsService.set('lastVisitedApp', 'diary');
      localStorage.setItem('selectedApp', 'diary');
      if (this.sharedDataService.hasSiteSelected) {
        this.router.navigate(['events'], {relativeTo: this.route});
      }
    }
  }

  getCurrentAppType(): string {
    const userSettings = this.userSettingsService.get();
    return userSettings.lastVisitedApp;
  }

  getAppSpecificColor(): string {
    if (this.isInAdministrationPage() && (this.deviceService.isMobile)) {
      return this.styleService.get('site-diary-color');
    }
    if (this.isInAdministrationPage()) {
      return this.styleService.get('administration-page-color');
    }
    if (this.checkRouteForAppType()) {
      return this.styleService.get('site-diary-color');
    } else {
      return this.styleService.get('site-task-color');
    }
  }

  /**
   * Return true if current page is in the administration section, else return false
   */
  isInAdministrationPage(): boolean {
    const userSettings = this.userSettingsService.get();
    try {
      if (userSettings.lastVisitedSpace) {
        const administrationURL = this.urlGiverService.giveAdministrationUrl(userSettings.lastVisitedSpace as string);
        if ((this.router.url === (administrationURL + '/edit')) ||
           (this.router.url === (administrationURL + '/dashboards')) ||
           (this.router.url === (administrationURL + '/resources/labours')) ||
           (this.router.url === (administrationURL + '/resources/plants')) ||
           (this.router.url === (administrationURL + '/resources/materials')) ||
           (this.router.url === (administrationURL + '/information/tags')) ||
           (this.router.url === (administrationURL + '/information/contractors')) ||
           (this.router.url === (administrationURL + '/information/locations')) ||
           (this.router.url === (administrationURL + '/users/list')) ||
           (this.router.url === (administrationURL + '/users/subscription')) ||
           (this.router.url === (administrationURL + '/users/no-subscription')) ||
           (this.router.url === (administrationURL + '/users/payment-information')) ||
           (this.router.url === (administrationURL + '/users/faq')) ||
           (this.router.url === (administrationURL + '/event-form')) ||
           (this.router.url === (administrationURL + '/admin-on-boarding'))
        ) {
          return true;
        } else {
          return false;
        }
      }
    } catch {
      return false;
    }
    return false;
  }

  isInProfileScreen(): boolean {
    try {
      return this.router.url === '/profile';
    } catch {
      return false;
    }
  }

}
