import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../shared/shared.module';
import { UpgradePopupComponent } from './upgrade-popup/upgrade-popup.component';
import { UpgradeService } from './upgrade.service';
import { SiteTaskUpgradePopupComponent } from './site-task-upgrade-popup/site-task-upgrade-popup.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SharedModule,
    ],
    declarations: [
        UpgradePopupComponent,
        SiteTaskUpgradePopupComponent,
    ],
    providers: [
        UpgradeService,
    ]
})
export class SubscriptionModule { }
