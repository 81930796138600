import { Injectable, OnDestroy } from '@angular/core';
// Translation Service
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@services/i18n/internationalization.service';
// Rxjs
import { Subscription } from 'rxjs';
/**
 * Datetime picker Translate Service
 * @author Julien Bertacco 2019.
 */
@Injectable()
export class UiDatetimePickerTranslateService implements OnDestroy {

  private months = [];
  private daysOfWeek = [];
  private monthSub: Subscription;
  private daysSub: Subscription;

  constructor(
    private translateService: TranslateService,
    private i18n: InternationalizationService,
  ) { }

  public getMonths(): string[] {
    this.monthSub = this.translateService.get(
      [
        'ui.datetimepicker.january.short',
        'ui.datetimepicker.february.short',
        'ui.datetimepicker.march.short',
        'ui.datetimepicker.april.short',
        'ui.datetimepicker.may.short',
        'ui.datetimepicker.june.short',
        'ui.datetimepicker.july.short',
        'ui.datetimepicker.august.short',
        'ui.datetimepicker.september.short',
        'ui.datetimepicker.october.short',
        'ui.datetimepicker.november.short',
        'ui.datetimepicker.december.short',
      ],
    ).subscribe((val) => {
      this.months = [];
      this.months.push(val['ui.datetimepicker.january.short']);
      this.months.push(val['ui.datetimepicker.february.short']);
      this.months.push(val['ui.datetimepicker.march.short']);
      this.months.push(val['ui.datetimepicker.april.short']);
      this.months.push(val['ui.datetimepicker.may.short']);
      this.months.push(val['ui.datetimepicker.june.short']);
      this.months.push(val['ui.datetimepicker.july.short']);
      this.months.push(val['ui.datetimepicker.august.short']);
      this.months.push(val['ui.datetimepicker.september.short']);
      this.months.push(val['ui.datetimepicker.october.short']);
      this.months.push(val['ui.datetimepicker.november.short']);
      this.months.push(val['ui.datetimepicker.december.short']);
    });
    return this.months;
  }
  public getDays(): string[] {

    this.daysSub = this.translateService.get(
      [
        'ui.datetimepicker.monday.short',
        'ui.datetimepicker.tuesday.short',
        'ui.datetimepicker.wednesday.short',
        'ui.datetimepicker.thursday.short',
        'ui.datetimepicker.friday.short',
        'ui.datetimepicker.saturday.short',
        'ui.datetimepicker.sunday.short',
      ]
    ).subscribe((val) => {
      this.daysOfWeek = [];
      this.daysOfWeek.push(val['ui.datetimepicker.monday.short']);
      this.daysOfWeek.push(val['ui.datetimepicker.tuesday.short']);
      this.daysOfWeek.push(val['ui.datetimepicker.wednesday.short']);
      this.daysOfWeek.push(val['ui.datetimepicker.thursday.short']);
      this.daysOfWeek.push(val['ui.datetimepicker.friday.short']);
      this.daysOfWeek.push(val['ui.datetimepicker.saturday.short']);
      // Set sunday at the right position (beginning or end of week) depending on language
      const sundayIndex = this.i18n.isCrazyLanguage() ? 0 : 6;
      this.daysOfWeek.splice(sundayIndex, 0, val['ui.datetimepicker.sunday.short']);
    });
    return this.daysOfWeek;
  }

  ngOnDestroy(): void {
    if (this.daysSub) {
      this.daysSub.unsubscribe();
    }
    if (this.monthSub) {
      this.monthSub.unsubscribe();
    }
  }
}
