import { Author } from './author';
import { User } from './user';

export interface AppAccess {
  siteDiaryAccess: boolean,
  siteTaskAccess: boolean
}
export class SpaceUser extends User {
  role: string;
  isValidated: boolean;
  lastAccess: Date;
  createdOn: Date;
  createdBy: Author;
  userId: string;
  emailAddress: string;
  siteTaskAccess: boolean;
  siteDiaryAccess: boolean;
  active: boolean;

  constructor() {
    super();
    this.isValidated = false;
  }

  /**
   * Convert backend data to Model
   * @param json The given backend Object
   * @param model The given model
   */
  toModel(json: any, model: SpaceUser) {
    User.toModel(json, model);
    model.role = json.role;
    model.active = json.active;
  }
}
