export class EventTask {
  taskId: string;
  progress: number;
  suspended: boolean;
  quantityDone: number;

  constructor() {
    this.taskId = '';
    this.progress = 0;
    this.suspended = false;
    this.quantityDone = null;
  }

  public static jsonToEventTask(jsonTask) {
    const task: EventTask = new EventTask();
    task.taskId = jsonTask.taskId;
    task.progress = jsonTask.progress;
    task.suspended = jsonTask.suspended;
    task.quantityDone = jsonTask.quantityDone;
    return task;
  }

}
