import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { DeviceService } from '@services/device.service'
import { MenuService } from '@services/menu.service';
import { SharedService } from '@services/shared/shared.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';

@Component({
  selector: 'site-task-upgrade-popup',
  templateUrl: './site-task-upgrade-popup.component.html',
  styleUrls: ['./site-task-upgrade-popup.component.scss']
})
export class SiteTaskUpgradePopupComponent implements OnInit {

  //to check if current user is space administrator
  isSpaceAdmin : boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public deviceService: DeviceService,
    private router: Router,
    private sharedService: SharedService,
    private userRightsService : UserRightsService,
    private menuService: MenuService,
  ) {  }

  ngOnInit() {
    this.isSpaceAdmin = this.userRightsService.hasRight(this.userRightsService.USER_RIGHTS.space.users.manage);
  }

  dismiss() {
    this.activeModal.close('dismiss');
  }

  //link to subscription page for space administrator
  goToSpaceSubscription(): void {
   this.dismiss();
   this.router.navigate(['/space',this.sharedService.currentSpaceId , 'users', 'subscription']).then((isSuccessfullyNavigated) => {
    if(isSuccessfullyNavigated) {
      this.sharedService.setCurrentSiteById(null);
      this.menuService.generateSpaceDetails(this.sharedService.currentSpaceId);
    }
   });
  }
}
