import { Injectable } from '@angular/core';
import { UrlGiverService } from './url-giver.service';
import { ActionType, SyncDto } from '../models/synchronization/sync-dto';
import { QueueService } from './synchronization/queue.service';
import { Observable, from } from 'rxjs';
import { flatMap, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {NetworkStatus} from "@models/synchronization/network-status";
import { EventService } from './event.service';
import { DeviceService } from './device.service';
import { DatabaseService } from './shared/database.service';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from './shared-data.service';
import { SpinnerService } from './spinner.service';
import { FileConversionService } from './file-conversion.service';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { PendingAttachmentService } from './pending-attachment.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends AutoUnsubscribeComponent {
  public static readonly DEFAULT_ATTACHMENT_URL = '/assets/img/noImage.jpg';

  // Attachments stored locally waiting for synchronization
  private readonly locallyStoredAttachements: { [attachmentId: string]: string } = {};
  // Attachments present in the DB waiting for synchronization
  private pendingAttachments: string[] = [];

  private syncingEventId: string = null;

  constructor(
    private urlGiverService: UrlGiverService,
    private queueService: QueueService,
    private https: HttpClient,
    private eventService: EventService,
    private deviceService: DeviceService,
    private databaseService: DatabaseService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService,
    private spinnerService: SpinnerService,
    private fileConversionService: FileConversionService,
    private pendingAttachmentService: PendingAttachmentService
  ) {
    super();
     //TODO this watch should be removed and replaced by a call to clearStoredAttachment when queueService.uploadImageAction
    this.queueService.watchQueue().subscribe(action => {
      if (action && action.type === 'Attachment') {
        this.clearStoredAttachment(action.payload.id);
      }
      if (action && (action.type === 'Event' || action.type === 'EventV4' ) && action.action !== 'DELETE') {
        this.syncingEventId = action.payload.id;
      }
      if (this.deviceService.isMobile){
        this.checkPendingSyncItems();
      }
    });
    this.pendingAttachmentService.getPendingAttachments().pipe(
      takeUntil(this.destroy)
    ).subscribe(attachments => {
      this.pendingAttachments = attachments;
    });
  }

  // This method checks if pending attachments for the event is present in the queue.
  // And when none are left updates the event sync status
  private async checkPendingSyncItems() {
    const pendingSyncItems = await this.databaseService.getUserDB().then(userDB => userDB.queue.toArray());
    let areUnsyncedAttachmentsPresent = false;
    if(pendingSyncItems.length > 0) {
      for (let queueItems of pendingSyncItems) {
        if(queueItems.action.type === 'Attachment' && queueItems.action.payload.eventId === this.syncingEventId) {
          areUnsyncedAttachmentsPresent = true;
        }
      }
    }
    if(!areUnsyncedAttachmentsPresent && this.syncingEventId !== null) {
      this.eventService.updateEventSyncStatus(this.syncingEventId);
      this.syncingEventId = null;
    }
  }

  private storeAttachment(id: string, data: string) {
    this.locallyStoredAttachements[id] = data;
  }

  private getStoredAttachmentData(id: string): string {
    return this.locallyStoredAttachements[id];
  }

  /**
   * removes attachement that was stored locally when synchronization of the attachement is done
   * @param id
   */
  private clearStoredAttachment(id: string): void {
    if (id in this.locallyStoredAttachements) {
      delete (this.locallyStoredAttachements[id]);
    } else {
      // This line triggers repeatedly since a local merging of photos happens on the client.
      // This was done to quick-fix a bug seen on the client.
      // Check the PR of this commit for more.
      this.logger.error('Error while trying to clear an attachment that is not stored locally. [attachmentID]:', id);
    }
  }

  // The client tries to fetch event picture thumbnail after the event has been created and before it has been saved on the server.
  // Storing the initial attachment locally stops the 403/404 errors when client tries to fetch thumbnail url after event creation.
  addAttachmentToLocallyStoredAttachments(attachmentsToUpload: { [attachmentId: string]: string }): void {
    for (const attachmentId in attachmentsToUpload) {
      if (attachmentsToUpload[attachmentId]) {
        this.storeAttachment(attachmentId,attachmentsToUpload[attachmentId]);
        break;
      }
    }
  }

  uploadAttachments(spaceId: string, siteId: string, eventId: string, attachmentsToUpload: { [attachmentId: string]: string }) {
    for (const attachmentId in attachmentsToUpload) {
      if (attachmentsToUpload[attachmentId]) {
        this.uploadAttachment(spaceId, siteId, eventId, attachmentId, attachmentsToUpload[attachmentId]);
      }
    }
  }

  async uploadAttachmentsInWeb(spaceId: string, siteId: string, eventId: string, attachmentsToUpload: { [attachmentId: string]: string }) {
    try {
      this.spinnerService.activate('pulsating');
      for (const attachmentId in attachmentsToUpload) {
        if (attachmentsToUpload[attachmentId]) {
          const action = new SyncDto(ActionType.CREATE, 'Attachment', {
            id: attachmentId,
            eventId: eventId,
            data: attachmentsToUpload[attachmentId],
          });
          action.attachToSite(siteId);
          await this.sharedDataService.uploadImage(action);
        }
      }
      this.spinnerService.deactivate();
    } catch(error) {
      this.spinnerService.deactivate();
      this.logger.error('Error while uploading images in the web', error);
    }
  }

  private uploadAttachment(spaceId: string, siteId: string, eventId: string, attachmentId: string, attachmentData: string) {
    this.storeAttachment(attachmentId, attachmentData);

    const action = new SyncDto(ActionType.CREATE, 'Attachment', {
      id: attachmentId,
      eventId: eventId,
      data: attachmentData,
    });
    action.attachToSite(siteId);
    this.queueService.addAction(action, spaceId);
  }

  getPictureUrl(spaceId: string, siteId: string, eventId: string, attachmentId: string, getThumbnail: boolean = false): string {
    if (!spaceId || !siteId || !eventId || !attachmentId) {
      return AttachmentService.DEFAULT_ATTACHMENT_URL;
    }

    if (attachmentId in this.locallyStoredAttachements) {
      if (this.getStoredAttachmentData(attachmentId)) {
        return this.getStoredAttachmentData(attachmentId);
      } else {
        return AttachmentService.DEFAULT_ATTACHMENT_URL;
      }
    } else if (this.pendingAttachments.includes(attachmentId)) {
      // If attachment is yet to be uploaded, return the default attachment URL.
      return AttachmentService.DEFAULT_ATTACHMENT_URL;
    }
    return getThumbnail ?
      this.urlGiverService.giveAPIUrl() + `/tenant/${spaceId}/sites/${siteId}/events/${eventId}/pictures/${attachmentId}/thumbnail` :
      this.urlGiverService.giveAPIUrl() + `/tenant/${spaceId}/sites/${siteId}/events/${eventId}/pictures/${attachmentId}`;
  }

  getPictureUrlFromFile(file: Blob): Promise<string> {
    return new Promise<string>((resolve => {
      // Get and store file content
      const fileReader = this.fileConversionService.getFileReader();
      fileReader.onloadend = (event) => {
        if (event.target && (<any>event.target).result) {
          resolve((<any>event.target).result);
        }
        resolve(AttachmentService.DEFAULT_ATTACHMENT_URL);
      };
      fileReader.onerror = (event) => {
        this.logger.error('Error while reading a file: ', file, event);
        resolve(AttachmentService.DEFAULT_ATTACHMENT_URL);
      };
      fileReader.onabort = (event) => {
        this.logger.error('Error while reading a file (abort): ', file, event);
        resolve(AttachmentService.DEFAULT_ATTACHMENT_URL);
      };
      fileReader.readAsDataURL(file);
    }));
  }

  /**
   * Send request to url and return it as a {@link Blob}
   * @param url URL to send request to
   * @param forceReload If true, add a GET param to bypass cache and force reloading
   * @return Response of request
   */
  private getBlobFromUrl(url: URL, forceReload: boolean): Observable<Blob> {
    if (forceReload) {
      url.searchParams.set('reload', Date.now().toPrecision());
    }
    return NetworkStatus.waitForOnlineStatus()
      .pipe(
        flatMap(() => this.https.get(url.toString(), {responseType: 'blob'})),
      );
  }

  /**
   * Get space logo as a base64 image blob
   * @param spaceId ID of space whose logo is returned
   * @return Site logo as a base64 blob
   */
  getSpaceLogoBlobUrl(spaceId: string): Observable<string> {
    const thumbnailUrl = new URL(`${this.urlGiverService.giveAPIUrl()}/spaces/${spaceId}/logo/thumbnail`);
    return this.getBlobFromUrl(thumbnailUrl, true)
      .pipe(flatMap(blob => from(this.getPictureUrlFromFile(blob))));
  }

  /**
   * Send request to change space logo
   * @param spaceId ID of space whose logo is changed
   * @param logoBlob New logo
   */
  changeSpaceLogo(spaceId: string, logoBlob: Blob): Observable<any>  {
    const thumbnailUrl = `${this.urlGiverService.giveAPIUrl()}/spaces/${spaceId}/logo`;
    const formData = new FormData();
    formData.append('logo', logoBlob);
    return this.https.post(thumbnailUrl, formData);
  }

  /**
   * Send request to delete site logo
   * @param spaceId ID of space whose logo is deleted
   */
  deleteSpaceLogo(spaceId): Observable<any> {
    const thumbnailUrl = `${this.urlGiverService.giveAPIUrl()}/spaces/${spaceId}/logo`;
    return this.https.delete(thumbnailUrl);
  }

  public async isAttachmentLocallyStoredOrPresentInQueue(attachmentId: string): Promise<boolean> {
    if (attachmentId in this.locallyStoredAttachements) {
      return true;
    } else if (await this.isAttachmentPresentInQueue(attachmentId)) {
      return true;
    } else {
      return false;
    }
  }

  private async isAttachmentPresentInQueue(attachmentId: string): Promise<boolean> {
    const db = await this.databaseService.getUserDB();
    let attachmentIsPresent = false;
    (await db.queue.toArray()).forEach((queueItem) => {
      if(queueItem.action.payload.id === attachmentId) {
        attachmentIsPresent = true;
      }
    });
    return attachmentIsPresent;
  }
}
