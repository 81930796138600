import { Injectable } from '@angular/core';
import { AppRateSettings } from '@models/utils/app-rate-settings';
import { BehaviorSubject, Observable } from 'rxjs';

const LOCAL_STORAGE_APPRATE_SETTINGS = 'appRateSettings';

@Injectable({
  providedIn: 'root'
})
export class AppRateSettingsService {
  private currentSettings$: BehaviorSubject<AppRateSettings>;
  get currentSettings(): Observable<AppRateSettings> {
    return this.currentSettings$.asObservable();
  }

  constructor() {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_APPRATE_SETTINGS);
    if (storedSettings) {
      this.currentSettings$ = new BehaviorSubject(Object.assign(new AppRateSettings(), JSON.parse(storedSettings)));
    } else {
      this.currentSettings$ =  new BehaviorSubject(new AppRateSettings());
    }
  }

  get(): AppRateSettings {
    return Object.assign(new AppRateSettings(), this.currentSettings$.getValue());
  }

  set(key: keyof AppRateSettings, value: any): void {
    const currentSettings: any = this.currentSettings$.getValue();
    if (key in currentSettings) {
      currentSettings[key] = value;
      localStorage.setItem(LOCAL_STORAGE_APPRATE_SETTINGS, JSON.stringify(currentSettings));
      this.currentSettings$.next(currentSettings);
    }
  }

  clear(): void {
    localStorage.removeItem('appRateSettings');
  }
}
