import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialogRef} from "@angular/material/dialog";
import {
  CustomEventField,
  allLegacyFieldTypes,
  CustomEventLegacyFieldType,
  allFormSectionTypes,
  CustomEventLegacyFieldTitle,
} from "@models/custom-event-field";
import { CustomEventForm } from "@models/custom-event-form";
import { CustomEventFormSection } from "@models/custom-event-form-layout";
import { NetworkStatus } from "@models/synchronization/network-status";
import { TranslateService } from "@ngx-translate/core";
import { CustomEventFormService } from "@services/custom-event-form.service";
import { PageTitleService } from "@services/page-title.service";
import { SharedService } from "@services/shared/shared.service";
import { SpaceService } from "@services/space.service";
import { SpinnerService } from "@services/spinner.service";
import { TabTitleService } from "@services/tab-title.service";
import { ToasterService } from "@services/toaster.service";
import { notBlankValidator } from "@validators/not-blank.validator";
import moment from "moment";
import { NGXLogger } from "ngx-logger";
import { Subscription } from "rxjs";

@Component({
  selector: "app-custom-event-form-creator-mobile",
  templateUrl: "./custom-event-form-creator-mobile.component.html",
  styleUrls: ["./custom-event-form-creator-mobile.component.scss"],
})
export class CustomEventFormCreatorMobileComponent implements OnInit {
  @Input() isPopup: boolean = true;

  private ERROR_TRANSLATE_KEYS_PREFIX: string = "custom-event-form";
  private allLegacyFields: CustomEventLegacyFieldType[] = JSON.parse(
    JSON.stringify(allLegacyFieldTypes)
  );

  public mandatorySectionFields = ["custom_event_form.mandatory_event_title","custom_event_form.mandatory_event_start_date","custom_event_form.mandatory_event_end_date"];
  public networkStatusSubscription: Subscription;
  public fieldSelectionMap: { [key: string]: boolean } = {};
  public otherFields: CustomEventLegacyFieldType[] = [];
  public formTitleGroup: FormGroup;
  currentTime: string = "";
  currentSpaceName: string = "";

  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  constructor(
    private customEventFormService: CustomEventFormService,
    private logger: NGXLogger,
    private toasterService: ToasterService,
    protected formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CustomEventFormCreatorMobileComponent>,
    private spinnerService: SpinnerService,
    private spaceService: SpaceService,
    private sharedService: SharedService,
    private tabTitleService: TabTitleService,
    private pageTitleService: PageTitleService,
  ) {
    this.tabTitleService.updateTabTitle('menu.event_form');
    this.pageTitleService.emitNewPageTitle('menu.event_form');
    this.createFormTitleGroup();
  }

  private async createFormTitleGroup(): Promise<void> {
    this.formTitleGroup = this.formBuilder.group({
      formTitle: [
        "",
        [Validators.required, Validators.maxLength(255), notBlankValidator],
      ],
    });
    const currentSpace = await this.spaceService.getSpace(this.sharedService.currentSpaceId);
    this.currentSpaceName = currentSpace.name;
    this.currentTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
    let formTitle = currentSpace.name + " " + this.currentTime;
    this.formTitleGroup.controls['formTitle'].setValue(formTitle);
  }

  ngOnInit(): void {
    this.checkForNetworkStatus();
  }

  private checkForNetworkStatus(): void {
    this.networkStatusSubscription =
      NetworkStatus.waitForOnlineStatus().subscribe(() => {
        this.initializeCustomEventFormCreation();
      });
  }

  private initializeCustomEventFormCreation(): void {
    this.setCustomFormOnboardingStatus();
    this.populateOtherFields();
    this.initializeFieldSelectionMap();
  }

  private setCustomFormOnboardingStatus(){
    localStorage.setItem("isCustomFormOnboardingShown", "true");
  }

  private populateOtherFields(): void {
    this.otherFields = [...this.allLegacyFields];
  }

  private initializeFieldSelectionMap(): void {
    this.allLegacyFields.forEach(
      (field) => (this.fieldSelectionMap[field.value] = false)
    );
  }

  private getNewFormCustomField(): CustomEventField[] {
    const newCustomFields = [];
    this.otherFields.forEach((field) => {
      if (!this.fieldSelectionMap[field.value]) return;
      newCustomFields.push(
        new CustomEventField(
          field.value,
          CustomEventLegacyFieldTitle[field.value],
          CustomEventField.CUSTOMEVENTFIELDTYPE[2],
          true,
          field
        )
      );
    });

    return newCustomFields;
  }

  private getNewFormLayout(): CustomEventFormSection[] {
    const formLayout = JSON.parse(JSON.stringify(allFormSectionTypes));
    const newFormLayout = formLayout.filter((layout) => {
      const newSectionFields = layout.sectionFields.filter(
        (field) => this.fieldSelectionMap[field]
      );
      if (newSectionFields.length) {
        layout.sectionFields = newSectionFields;
        return true;
      }
      return false;
    });

    return newFormLayout;
  }

  private getControlErrorsText(
    controlName: string,
    errors: ValidationErrors
  ): string | null {
    if (errors) {
      let errorText = "";
      for (const error in errors) {
        if (errors[error]) {
          this.translateService
            .get(
              `form.error.${this.ERROR_TRANSLATE_KEYS_PREFIX}.${controlName}.${error}`
            )
            .subscribe((translation) => {
              if (errorText !== "") {
                errorText += "\n";
              }
              errorText += translation;
            });
        }
      }
      return errorText;
    }
    return null;
  }

  public closeDialog() {
    if(!this.isPopup) {
      return;
    }
    this.dialogRef.close();
  }

  public saveForm(): void {
    if(this.formTitleGroup.invalid) {
      return;
    }
    const newForm = new CustomEventForm();
    newForm.title = this.formTitleGroup.value["formTitle"];
    newForm.customFields = this.getNewFormCustomField();
    newForm.formLayout = this.getNewFormLayout();
    this.updateForm(newForm);
  }

  public updateForm(newForm: CustomEventForm): void {
    this.spinnerService.activate('pulsating');
    this.customEventFormService
      .updateForm(newForm)
      .then(() => {
        this.spinnerService.deactivate();
        this.closeDialog();
        this.toasterService.showSuccessToaster(
          "custom_event_form.update_form.successful_message"
        );
        let newCurrentTime = moment(this.currentTime).add(1, 'minutes').format('YYYY-MM-DD HH:mm');
        this.currentTime = newCurrentTime;
        let formTitle = this.currentSpaceName + " " + newCurrentTime;
        this.formTitleGroup.controls['formTitle'].setValue(formTitle);
      })
      .catch((error) => {
        this.spinnerService.deactivate();
        this.logger.error("Error while updating the custom form", error);
        if(error.message === "Duplicate form name") {
          this.formTitleGroup.controls['formTitle'].setErrors({formTitleAlreadyExists: true});
          this.toasterService.showErrorToaster('form.error.custom-event-form.formTitle.formTitleAlreadyExists');
        } else {
          this.toasterService.showErrorToaster('custom_event_form.update_form.failed_message');
        }
      });
  }

  public getControlErrors(controlName: string): string | null {
    if (this.formTitleGroup) {
      const control = this.formTitleGroup.controls[controlName];
      if (control && control.invalid) {
        return this.getControlErrorsText(controlName, control.errors);
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.networkStatusSubscription.unsubscribe();
  }
}
