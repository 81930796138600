import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const TIME_INTERVALS = [
	{
		key: 'month',
		value: 2592000,
		singularStringTranslateKey: 'notifications.notified_at.label.month_ago',
		pluralStringTranslateKey: 'notifications.notified_at.label.months_ago'
	},
	{
		key: 'week',
		value: 604800,
		singularStringTranslateKey: 'notifications.notified_at.label.week_ago',
		pluralStringTranslateKey: 'notifications.notified_at.label.weeks_ago'
	},
	{
		key: 'day',
		value: 86400,
		singularStringTranslateKey: 'notifications.notified_at.label.yesterday',
		pluralStringTranslateKey: 'notifications.notified_at.label.days_ago'
	},
	{
		key: 'hour',
		value: 3600,
		singularStringTranslateKey: 'notifications.notified_at.label.hour_ago',
		pluralStringTranslateKey: 'notifications.notified_at.label.hours_ago'
	},
	{
		key: 'minute',
		value: 60,
		singularStringTranslateKey: 'notifications.notified_at.label.minute_ago',
		pluralStringTranslateKey: 'notifications.notified_at.label.minutes_ago'
	}
];
@Pipe({
  name: 'notifiedAt'
})
export class NotifiedAtPipe implements PipeTransform {

	constructor( private translateService: TranslateService) {}

  transform(timestamp: number): string {
		if(timestamp) {
			const currentTime = new Date().getTime();
			const notificationTime = new Date(timestamp).getTime();
			const timeDifference = currentTime - notificationTime;
			const timeDifferenceInSeconds = Math.floor(timeDifference / 1000);
			if(timeDifferenceInSeconds < 59) {
				return `${this.getTranslation('notifications.notified_at.label.just_now')}`;
			}
			let counter;
			for (let i = 0; i < TIME_INTERVALS.length; i++) {
                counter = Math.floor(timeDifferenceInSeconds / TIME_INTERVALS[i].value);
                if (counter > 0) {
					// if 1 day ago notification was received, show notified at as yesterday to the user
					if(counter === 1 && TIME_INTERVALS[i].key === 'day') {
						return `${this.getTranslation(TIME_INTERVALS[i].singularStringTranslateKey)}`
					}
                    if (counter === 1) {
                        return `${counter} ${this.getTranslation(TIME_INTERVALS[i].singularStringTranslateKey)}`;
                    } 
					else {
                        return `${counter} ${this.getTranslation(TIME_INTERVALS[i].pluralStringTranslateKey)}`;
                    }
				}
            }
		}
		return null;
  }

	getTranslation(key: string) {
		let translation = null;
		this.translateService.get([key])
		.subscribe((val) => {
			translation = val[key];
		});
		return translation;
	}
}
