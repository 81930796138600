import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OnBoardingService } from '@services/on-boarding.service';
import { SharedDataService } from '@services/shared-data.service';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin-onboarding-modal',
  templateUrl: './admin-onboarding-modal.component.html',
  styleUrls: ['./admin-onboarding-modal.component.sass']
})
export class AdminOnboardingModalComponent extends AutoUnsubscribeComponent implements OnInit {

  // Admin onboarding tour data
  tourData = [
    { title: 'admin_onboarding.step_1.title',
      stepLabel: 'admin_onboarding.step_1.stepLabel',
      percentageProgress: 25,
      contentHeading: 'admin_onboarding.step_1.contentHeading',
      description: 'admin_onboarding.step_1.description', 
      targetElementId: 'space_details' 
    },
    { title: 'admin_onboarding.step_2.title',
      stepLabel: 'admin_onboarding.step_2.stepLabel',
      percentageProgress: 50,
      contentHeading: 'admin_onboarding.step_2.contentHeading',
      description: 'admin_onboarding.step_2.description',
      targetElementId: 'global-resources' 
    },
    { title: 'admin_onboarding.step_3.title',
      stepLabel: 'admin_onboarding.step_3.stepLabel',
      percentageProgress: 75,
      contentHeading: 'admin_onboarding.step_3.contentHeading',
      description: 'admin_onboarding.step_3.description', 
      targetElementId: 'event-form'
    },
    { title: 'admin_onboarding.step_4.title',
      stepLabel: 'admin_onboarding.step_4.stepLabel',
      percentageProgress: 100,
      contentHeading: 'admin_onboarding.step_4.contentHeading',
      description: 'admin_onboarding.step_4.description', 
      targetElementId: 'onboarding-admin'
    }
  ];

  currentStep = 0;
  startOnboarding: boolean = false;

  @ViewChild('tourContainer') tourContainer!: ElementRef;

  constructor(
    private onBoardingService: OnBoardingService,
    private router: Router,
    private sharedDataService: SharedDataService,
    private location: Location,
  ) {
    super();
    this.location.subscribe((change) => {
      if (change.type === 'popstate') {
        this.closeAdminOnboarding();
      }
    });
   }

  ngOnInit(): void {
    this.onBoardingService.watchStartAdminOnboarding.pipe(
      takeUntil(this.destroy)
    ).subscribe((val) => {
      if(val) {
        this.startOnboarding = true;
        setTimeout(() => {
          this.startTour();
        });
      } else {
        this.startOnboarding = false;
      }
    });
  }

  async startTour(): Promise<void> {
    this.updateTourPosition();
  }

  nextStep(): void {
    this.currentStep++;
    if (this.currentStep < this.tourData.length) {
      this.updateTourPosition();
    } else {
      this.currentStep = 0;
      this.onBoardingService.setStartAdminOnboarding(false);
    }
  }

  previousStep(): void {
    if(this.currentStep > 0) {
      this.currentStep--;
      if (this.currentStep < this.tourData.length) {
        this.updateTourPosition();
      } else {
        this.currentStep = 0;
        this.onBoardingService.setStartAdminOnboarding(false);
      }
    }
  }

  async updateTourPosition(): Promise<void> {
    let extraWidth = 0;
    let extraHeight = 0;
    if(this.currentStep === this.tourData.length - 1) {
      this.onBoardingService.setAdminOnboardingLastStep(true);
    } else {
      this.onBoardingService.setAdminOnboardingLastStep(false);
    }
    // Handle navigation based on which step modal is in.
    switch (this.currentStep) {
      case 0:
        await this.router.navigate(['/space', this.sharedDataService.currentSpaceId, 'edit']);
        break;
      case 1:
        await this.router.navigate(['/space', this.sharedDataService.currentSpaceId, 'resources']);
        break;
      case 2:
        await this.router.navigate(['/space', this.sharedDataService.currentSpaceId, 'event-form']);
        break;
      case 3:
        await this.router.navigate(['/space', this.sharedDataService.currentSpaceId, 'edit']);
        extraWidth = 100;
        break;
    }
    const targetElementId = this.tourData[this.currentStep].targetElementId;
    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      const targetRect = targetElement.getBoundingClientRect();
      const tourContainer = this.tourContainer.nativeElement;

      // Calculate position based on target element
      // Set styles dynamically
      if(this.currentStep === 3) {
        extraWidth = 100;
        extraHeight = 10;
        let arrowKeyElementId = 'arrow-left-admin-onboarding-fill';
        const targetElement = document.getElementById(arrowKeyElementId);
        if(targetElement) {
          targetElement.style.top = '0px';
        }
      } else if(this.currentStep === 1) {
        let arrowKeyElementId = 'arrow-left-admin-onboarding-fill';
        const targetElement = document.getElementById(arrowKeyElementId);
        if(targetElement) {
          targetElement.style.top = '52px';
        }
      } else {
        extraHeight = 0;
        extraWidth = 0;
        let arrowKeyElementId = 'arrow-left-admin-onboarding-fill';
        const targetElement = document.getElementById(arrowKeyElementId);
        if(targetElement) {
          targetElement.style.top = '24px';
        }
      }

      const left = targetRect.right + extraWidth;
      const top = targetRect.top -20 + extraHeight;

      tourContainer.style.left = `${left}px`;
      tourContainer.style.top = `${top}px`;
      tourContainer.style.visibility = 'visible';
    }
  }

  endTour(): void {
    this.closeAdminOnboarding(true);
  }

  closeAdminOnboarding(lastStep: boolean = false): void {
    this.currentStep = 0;
    this.startOnboarding = false;
    this.onBoardingService.closeWebAdminOnboarding(lastStep);
    this.onBoardingService.setAdminOnboardingLastStep(false);
  }

}
