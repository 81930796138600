<ion-content fullscreen class="ion-padding" scroll-y="false">
  <ion-slides [options]="slideOpts" #onboardingSlider (ionSlideWillChange)="updateSlideNumber()">
    <ion-slide *ngFor="let data of adminOnboardingPagesData">
      <ng-template [ngTemplateOutlet]="onboardingTemplate" [ngTemplateOutletContext]="{data: data}"></ng-template>
    </ion-slide>
  </ion-slides>
</ion-content>

<ng-template #onboardingTemplate let-data="data">
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-row id="dot-div">
        <div *ngFor="let num of numSequence(5); let i = index">
          <div class="circles" [ngClass]="{ 'shaded' : i !== data.slideNumber }"></div>
        </div>
      </ion-row>
      <ion-buttons slot="end">
        <ion-button id="dismiss" (click)="dismiss()"> {{ !data.isLastSlide ? ('onboarding.skip' | translate) : ('onboarding.finish' | translate) }} </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="container" id="top-container"> 
    <ion-grid>  
      <ion-row> 
        <div class="container" id="heading1">
          {{ data.title | translate }}
        </div>
      </ion-row>  
      <ion-row>
        <div class="container" id="image-container">
          <img id="img" [src]="data.image"/>
        </div>
      </ion-row>
      <ion-row> 
        <div class="container heading2" id="subtitle-container"> 
          {{ data.heading1 | translate }}
        </div>
      </ion-row> 
      <ion-row>  
        <div class="container heading2 color-black"> 
          {{ data.heading2 | translate }}
        </div>
      </ion-row> 
      <ion-row>
        <div class="container" id="small-bar">
          <img src='/assets/img/on-boarding/small-bar.svg'/>
        </div>
      </ion-row>
      <ion-row> 
        <div class="container" id="description">  
          <p id="description-text">{{ data.description | translate }}</p> 
        </div>
      </ion-row>  
      <ion-row> 
        <ion-col size="2" offset="2" *ngIf="data.slideNumber !== 0">
          <div id="left-arrow-div" (click)="swipePrevious()">
            <img id="left-arrow" *ngFor="let i of numSequence(3)" src='/assets/img/on-boarding/left-arrow.svg'/>
          </div>
        </ion-col>
        <ion-col size="4" offset="4" *ngIf="data.slideNumber === 0">
          <p id="swipe">{{ 'onboarding.swipe' | translate }}</p>
        </ion-col>
        <ion-col size="4" *ngIf="data.slideNumber !== 0">
          <p id="swipe">{{ 'onboarding.swipe' | translate }}</p>
        </ion-col>
        <ion-col size="2" offset="0" *ngIf="data.slideNumber !== 4">
          <div id="right-arrow-div" (click)="swipeNext()">
            <img id="right-arrow" *ngFor="let i of numSequence(3)" src='/assets/img/on-boarding/right-arrow.svg'/>
          </div>
        </ion-col>
      </ion-row> 
    </ion-grid>  
  </div>  
  <div id="bottom-div" *ngIf="data.slideNumber !== 0"> 
    <ion-row>
      <p class="container additional-information" id="sub-description-heading">{{ 'onboarding.did_you_know' | translate }}</p>
    </ion-row>
    <ion-row>
      <p class="container additional-information" id="sub-description">{{ data.subDescription | translate }}</p>
    </ion-row>
  </div>
</ng-template>
