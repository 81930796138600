import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
  selector: 'app-custom-form-date-field',
  templateUrl: './custom-form-date-field.component.html',
  styleUrls: ['./custom-form-date-field.component.scss'],
})
export class CustomFormDateFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  dateFormat: string;

  constructor() { }

  ngOnInit() {
    this.dateFormat = this.customField.children["dateFormat"];
  }

}
