import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { DeviceService } from '../device.service';

export const EMAIL_PREFIX = '[Site Diary] ';

export const LINE_BREAK: { desktop: string, mobile: string } = {
  desktop: '\n',
  mobile: '\n'
};

export class EmailService {

  protected constructor(
    protected emailComposer: EmailComposer,
    protected deviceService: DeviceService,
  ) { }

  /**
   * Create the mailto link from email template
   * @param email Email template
   */
  private getMailtoLink(subject: string,
    body: string,
   ): string {
    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  /**
   * Send a mail
   * @param subject Subject of the mail
   * @param body Content of the mail
   */
  public sendEmail(
    subject: string,
    body: string,
  ) {
    if (!this.deviceService.hasCordova) {
      this.sendDesktopMail(subject, body);
    } else {
      this.sendEmailCordova(subject, body);
    }
  }

  /**
   * Send mail with link method
   * @param subject
   * @param body
   */
  private sendDesktopMail(
    subject: string,
    body: string,
  ): void {
    const mailtoLink: string = this.getMailtoLink(subject, body);
    window.location.href = mailtoLink;
  }

  /**
   * Send email using cordova method
   * @param subject Subject of the mail
   * @param body Content of the mail
   */
  private sendEmailCordova(
    subject: string,
    body: string,
  ): void {
    // Send email using cordova email composer plugin
    this.emailComposer.open({subject: subject, body: body});
  }

}
