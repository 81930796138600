import { HttpClient, HttpEventType, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';

import { File as IonicFileService, FileEntry, IFile } from '@ionic-native/file/ngx';
import { map, switchMap } from 'rxjs/operators';
import { HttpStatus } from '@constants/http/http-status';
import { FileConversionService } from './file-conversion.service';

const SITE_SYNC_VERSION = 4;
/**
 * File Transfert Service
 */
@Injectable()
export class FileTransfertService {

  /**
   * File Transfert Service constructor
   */
  constructor(
    private http: HttpClient,
    private ionicFileService: IonicFileService,
    private fileConversionService: FileConversionService,
  ) {}

  uploadPicture(data: string, url: string): Observable<{isDone: boolean, progress?: number}> {
    return from(fetch(data)).pipe(
      switchMap(response => response.blob()),
      switchMap(blob => {
        let params = new HttpParams()
          .set('version', SITE_SYNC_VERSION);
        const formData: FormData = new FormData();
        formData.append('logo', blob);
        const request = new HttpRequest('POST', url, formData, {
          reportProgress: true,
          params: params,
        });
        return this.http.request(request).pipe(map(evt => {
          if (evt.type === HttpEventType.UploadProgress) {
            let progress = 0;
            if (evt.total) {
              progress = Math.floor(evt.loaded / evt.total);
            }
            return { isDone: false, progress: progress };
          } else if (evt.type === HttpEventType.Response && evt.status === HttpStatus.CREATED) {
            return { isDone: true };
          }
          throwError(evt);
          return null;
        }));
      })
    );
  }

  /**
   * Method to transform imagePath from ionic native camera to a javascript file object
   * We will then be able to upload the file using our uploadFile method
   * @param filePath image path returned by ionic native camera
   */
  async getUploadableFileFromCamera(filePath: string): Promise<File> {
    // Get a FileEntry object from the image path given by the camera
    const fileEntry: any = await this.ionicFileService.resolveLocalFilesystemUrl(filePath);

    // Get File from the FileEntry. NOTE : The file does not contain actual data yet, we need to use FileReader;
    const IonicFile: IFile = await this.convertFileEntryToIonicFile(fileEntry);

    // Use FileReader on the File object to populate it with file data.
    return this.convertIonicFileToJavascriptFile(IonicFile);
  }

  /**
   * Method to transform array of image paths from ionic native image picker to an array of javascript file objects
   * @param filePaths array of string containing files paths returned by ionic native image picker
   */
  async getUploadableFilesFromGallery(filePaths: string[]): Promise<File[]> {
    // Get a FileEntry array from the array of image paths given by the gallery (image picker ionic native)
    const fileEntryPromises: Promise<any>[] = filePaths.map(filePath => {
      return this.ionicFileService.resolveLocalFilesystemUrl(filePath);
    }) as Promise<any>[];
    // Await that all image path are resolved to file entries to populate FileEntry[];
    const fileEntries: FileEntry[] = await Promise.all(fileEntryPromises);

    // Get a File array from the FileEntry array. NOTE the files will do not contain actual data yet, we need to use FileReader;
    const cordovaFilePromises: Promise<IFile>[] = fileEntries.map(fileEntry => {
      return this.convertFileEntryToIonicFile(fileEntry);
    });
    // Await that all file entries are converted to ionic files to populate Ifile[]
    const cordovaFiles: IFile[] = await Promise.all(cordovaFilePromises);

    // Use FileReader on each File object to read the actual file data into the file object
    const filePromises: Promise<File>[] = cordovaFiles.map(cordovaFile => {
      return this.convertIonicFileToJavascriptFile(cordovaFile);
    });
    // Will return a list of javascript File objects
    return await Promise.all(filePromises);
  }

  /**
   * First step convert the file entry to an ionic file
   * @param fileEntry
   */
  private convertFileEntryToIonicFile(fileEntry: FileEntry): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fileEntry.file(resolve, reject);
    });
  }

  /**
   * Second step convert the ionic file to an actual javascript file
   * @param IonicFile
   */
  private convertIonicFileToJavascriptFile(IonicFile: IFile): Promise<any> {
    return new Promise<File>((resolve, reject) => {
      const reader = this.fileConversionService.getFileReader();
      reader.onloadend = () => {
        if (reader.error) {
          reject(reader.error);
        } else {
          const blob: any = new Blob([reader.result], { type: IonicFile.type });
          blob.lastModifiedDate = new Date();
          blob.name = IonicFile.name;
          resolve(blob as File);
        }
      };
      reader.readAsArrayBuffer(IonicFile);
    });
  }
}
