import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToasterService } from '@services/toaster.service';
import { HttpStatus } from '@constants/http/http-status';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from '@services/device.service';
import * as Package from '../../../../package.json'
import { ApplicationVersionStatus } from '@models/utils/app-update-settings';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';

  const IONIC_MOBILE_APP = 'site-diary-ionic';
  const ANGULAR_WEB_APP = 'site-productivity-angular';

@Injectable()
export class AppUpdateHandlerInterceptor implements HttpInterceptor {
    packageInfo = Package;
    platform: string;

  constructor(
    private toasterService: ToasterService,
    private logger: NGXLogger,
    private deviceService: DeviceService,
    private appUpdateSettingsService: AppUpdateSettingsService
  ) {
    // platform and version info
    if (this.deviceService.isMobile) {
      this.platform = IONIC_MOBILE_APP;
    }
    else {
      this.platform = ANGULAR_WEB_APP;
    }
  }

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.url.includes('/oauth/token')) {
      return next.handle(request);
    }
    else {
      const modifiedRequest = request.clone({
        headers: request.headers.set("client-version", this.packageInfo.version).set("client-platform", this.platform)
      });
      return next.handle(modifiedRequest)
        .pipe(
          tap({
            error: (error: HttpErrorResponse) => {
              if (error.status === HttpStatus.FORBIDDEN) {
                  if (this.isAppUnsupported(error)) {
                  this.appUpdateSettingsService.updateAppUpdateSettings(ApplicationVersionStatus.appUnsupported);
                  this.toasterService.showInfoToaster("app.update.unsupported");
                  this.logger.error('The app is unsupported, user is logged out from the app', false);
                }
              }
            },
          }),
        );
      }
  }

  isAppUnsupported(error): boolean {
    if (error.error.message === 'UNSUPPORTED_CLIENT') {
      return true;
    }
    else {
      return false;
    }
  }
}
