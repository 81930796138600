import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetCategory } from '@models/asset';
import { EventAsset } from '@models/event-asset';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-people',
  templateUrl: './event-people.component.html',
  styleUrls: ['./event-people.component.scss', '../event-legacy-field.component.sass']
})
export class EventPeopleComponent extends EventLegacyFieldComponent {

  @Input() labours: EventAsset[];
  @Input() laboursLoaded: boolean;

  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  getAssetCategory(): typeof AssetCategory {
    return AssetCategory;
  }
}
