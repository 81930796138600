import { Identifiable } from './identifiable';

export class Picture implements Identifiable {
  id: string;
  originalContentType: string;
  thumbnailContentType: string;
  thumbnailData: string;

  constructor() {}

  /**
   * Convert backend data to Model
   * @param json The given backend Object
   * @param model The given model
   */
  toModel(json: any, model: Picture) {
    model.id = json.payload.id;
    model.originalContentType = json.payload.originalContentType;
    model.thumbnailContentType = json.payload.thumbnailContentType;
    model.thumbnailData = json.payload.thumbnailData;
  }
}
