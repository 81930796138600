import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
  selector: 'app-custom-form-checkbox-field',
  templateUrl: './custom-form-checkbox-field.component.html',
  styleUrls: ['./custom-form-checkbox-field.component.scss'],
})
export class CustomFormCheckboxFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  checkboxOptions: string[] = [];
  selectedOptions: string[] = [];

  constructor() { }

  ngOnInit() {
    this.customField.children.options.map((option) => {
      if (option.value !== "") {
        this.checkboxOptions.push(option)
      }
    })
    if (this.parentFormGroup.value.value){
      this.selectedOptions = this.parentFormGroup.value.value;
    }
  }

  addCheckedOptions(option) {
    let valueAlreadyChecked: boolean = false;
    for (let i = 0; i < this.selectedOptions.length; i++){
      if (option.id === this.selectedOptions[i]){
        valueAlreadyChecked = true;
      }
    }
    if (valueAlreadyChecked) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(option.id), 1)
    } else {
      this.selectedOptions.push(option.id)
    }
      this.parentFormGroup.patchValue({
        value: this.selectedOptions
      });
  }

  isOptionChecked(option): boolean {
    for (let i = 0; i < this.selectedOptions.length; i++){
      if (option.id === this.selectedOptions[i]){
        return true;
      }
    }
    return false;
  }
}
