/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { NetworkStatus } from '@models/synchronization/network-status';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@services/device.service';
import { SharedService } from '@services/shared/shared.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AndroidPermissionService } from '@services/android-permission.service';
import { Logger } from '@services/logger';
import { ToasterService } from '@services/toaster.service';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from '@services/shared-data.service';

export class GoogleAddress {
  locality: string;
  country: string;
  postalCode: string;
  thoroughfare: string;
  administrativeArea: string;
}

export class Coordinates {
  lat: number;
  lng: number;

  constructor(lat?: number, lng?: number) {
    this.lat = lat;
    this.lng = lng;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoordinatesService {

  relevantSharedService: SharedService | SharedDataService;

  constructor(
    private deviceService: DeviceService,
    private androidPermissionService: AndroidPermissionService,
    private toastr: ToasterService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private logger: NGXLogger,
    private sharedDataService: SharedDataService,
    ) {
      this.relevantSharedService = this.deviceService.isMobile ? this.sharedService : this.sharedDataService;
    }

  /**
   * Get latitude and longitude from given address
   * @param address given address
   * @param geocoder gmap geocoder
   * @return Promise<Coordinates>
   */
  getCoordsFromAddress(address: any): Promise<Coordinates> {
    return new Promise<any>(resolve => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': address }, (results, status) => {
        let lat: number;
        let lng: number;
        if (status === google.maps.GeocoderStatus.OK) {
          const place = results[0];
          lat = place.geometry.location.lat();
          lng = place.geometry.location.lng();
          resolve(new Coordinates(lat, lng));
        }
      });
    });
  }

  /**
   * Get latitude and longitude from current site location
   * @return Observable<Coordinates>
   */
  public async getLocationFromCurrentSite(): Promise<Coordinates> {
    const site = await this.relevantSharedService.getNextNonNullSite();
    if (!(site.latitude && site.longitude)) {
      this.logger.error(`Tried to fetch location from current Site. Site doesn't have coordinates!`);
      throw new Error('Site don\'t have coordinates!');
    }
    return new Coordinates(site.latitude, site.longitude);
  }

  /**
   * Search an address component with a pattern
   * @param _pattern searching pattern
   * @param _address Raw address
   */
  private _filterAddressComponentsByType(_pattern: string, _address: {types: string[]}[]): any | null {
    const result: any[] = _address.filter(value => {
      return value.types.includes(_pattern);
    });
    return result.length > 0 ? result[0] : null;
  }

  /**
   * Transforms a raw address into a usable one
   * @param _address Raw address
   * @returns Address processed
   */
  private _convertAddressToGoogleAdressModel(_address: google.maps.GeocoderAddressComponent[]): GoogleAddress {
    const address = new GoogleAddress();

    const street_number = this._filterAddressComponentsByType('street_number', _address);
    let route = this._filterAddressComponentsByType('route', _address);
    let locality = this._filterAddressComponentsByType('locality', _address);
    const administrative_area_level_1 = this._filterAddressComponentsByType('administrative_area_level_1', _address);
    const administrative_area_level_2 = this._filterAddressComponentsByType('administrative_area_level_2', _address);
    const country = this._filterAddressComponentsByType('country', _address);
    const postal_code = this._filterAddressComponentsByType('postal_code', _address);

    // China special case
    route = route ? route : this._filterAddressComponentsByType('premise', _address);

    // England special case
    locality = locality ? locality : this._filterAddressComponentsByType('postal_town', _address);

    address.thoroughfare = '';
    address.thoroughfare += street_number ? street_number.long_name + ' ' : '';
    address.thoroughfare += route ? route.long_name : '';
    address.locality = locality ? locality.long_name : '';
    address.administrativeArea = '';
    address.administrativeArea += administrative_area_level_2 ? administrative_area_level_2.long_name + ', ' : '';
    address.administrativeArea += administrative_area_level_1 ? administrative_area_level_1.long_name : '';
    address.country = country ? country.short_name : '';
    address.postalCode = postal_code ? postal_code.long_name : '';

    return address;
  }

  /**
   * Search address by coords
   * @param lat latitude
   * @param lng longitude
   * @return Promise of the first result
   */
  searchAddressByCoords(lat: number, lng: number): Promise<google.maps.GeocoderResult> {
    const latlng = { 'lat': lat, 'lng': lng };
    return new Promise<google.maps.GeocoderResult>(resolve => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status.toString() === 'OK') {
          resolve(results[0]);
        }
      });
    });
  }

  /**
   * Get address from given latitude and longitude
   * @param lat given latitude
   * @param lng givent longitude
   * @return Promise of the address convert on GoogleAddress model
   */
  async getAddressFromCoords(lat: number, lng: number): Promise<GoogleAddress> {
    return this.searchAddressByCoords(lat, lng).then(address => this._convertAddressToGoogleAdressModel(address.address_components));
  }

  /**
   * Get a raw address from given latitude and longitude
   * @param lat given latitude
   * @param lng givent longitude
   * @return Promise of the address convert on string
   */
  async getRawAddressFromCoords(lat: number, lng: number): Promise<string> {
    return this.searchAddressByCoords(lat, lng).then(address => address.formatted_address);
  }

  /**
   * Retrieve geolocation from browser
   */
  private getGeolocation(): Promise<Coordinates> {
    if (navigator.geolocation) {
      return new Promise<Coordinates>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            if (pos.coords.latitude !== null && pos.coords.longitude !== null) {
              const lat: number = pos.coords.latitude;
              const lng: number = pos.coords.longitude;
              resolve(new Coordinates(lat, lng));
            }
            reject(new Error('Invalid coordinates: ' + pos));
          },
          error => {
            this.toastr.showErrorToaster('mobile-geolocation-function-disabled');
            reject(error);
          },
          { timeout: 5000 });
      });
    }
    return Promise.reject('Browser geolocation is not available.');
  }

  /**
   * Method returning current coordinates of user, using either Mobile's or Browser's geolocation
   */
  async geolocateUser(): Promise<Coordinates> {
    if (!NetworkStatus.isOnline) {
      throw new Error('GPS disable when offline');
    }
    if (this.deviceService.isAndroid) {
      return this.androidPermissionService.checkFineLocationPermission('mobile-geolocation-permission-missing')
        .then(async hasPermission => {
          if (hasPermission) {
            return this.getGeolocation().catch(error => {
              this.logger.error('Error during user geolocation: ', error);
              throw error;
            }).then(res => {
              return res;
            });
          }
          this.logger.error('Unable to get geolocate permission.');
          throw new Error('Unable to get geolocate permission.');
        });
    } else {
      return this.getGeolocation().catch(error => {
        this.logger.error('Error during user geolocation: ', error);
        throw error;
      });
    }
  }

}
