import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hourDiffBetweenTwoDates'
})
export class HourDiffBetweenTwoDatesPipe implements PipeTransform {

  transform(date1: any, date2: any): string {
    date1 = moment(date1);
    date2 = moment(date2);
    const duration = moment.duration(date2.diff(date1));
    const h: any = Math.floor(duration.asMinutes() / 60);
    const m: any = Math.round(duration.asMinutes() % 60);
    const hourText = h > 1 ? 'hours' : 'hour';
    return `${h} ${hourText} ${m} minute(s)`;
  }
}
