import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportToolSelectorComponent } from './support-tool-selector/support-tool-selector.component';
import { SharedModule } from '../shared/shared.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        IonicModule,
    ],
    declarations: [
        SupportToolSelectorComponent,
    ]
})
export class SupportModule { }
