<div>
  <div class="update-banner-container" *ngIf="showUpdateBanner">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div>
            <p class="update-header">{{ 'app.update.available.web.header' | translate }}</p>
            <p>{{ 'app.update.available.web.message' | translate }}</p>
          </div>
        </ion-col>
        <ion-col size="auto">
          <button class="update-button" (click)="updateApp()">{{ 'app.update.available.web.update.button' | translate }}</button>
        </ion-col>
        <ion-col size="auto">
          <button class="close-button" (click)="dismissAppUpdateBanner()"><ion-icon name="close-outline"></ion-icon></button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
