import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '@services/device.service';
import { AngularGoogleMapsService } from '@services/google-maps/angular-google-maps.service';
import { Coordinates, CoordinatesService } from '@services/shared/coordinates.service';

/// <reference types="@types/googlemaps" />

@Component({
  selector: 'app-select-gps',
  templateUrl: './select-gps.component.html',
  styleUrls: ['./select-gps.component.sass']
})
export class SelectGPSComponent {

  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() withAutoComplete: boolean;

  @Output() mapReady = new EventEmitter();
  @Output() coordinatesChange = new EventEmitter<Coordinates>();

  private _geoUser: boolean;

  get geoUser(): boolean {
    return this._geoUser;
  }

  map: any;
  searchBox: any;


  // Google Map options
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  center: google.maps.LatLngLiteral = {lat: 51, lng: 10};
  apiLoaded: boolean = false;
  options: google.maps.MapOptions = {
    mapTypeControl: false,
  }

  googleMapReady: boolean = false;
  /**
   * Attributes for mobile autocomplete location selection
   */
  mobileAutocomplete;
  autocomplete = null;
  autocompletePlaces;

  constructor(
    public deviceService: DeviceService,
    private translateService: TranslateService,
    private coordinatesService: CoordinatesService,
    private angularGoogleMapsService: AngularGoogleMapsService,
  ) {
    this.angularGoogleMapsService.apiLoaded.subscribe((isLoaded) => this.apiLoaded = isLoaded);
  }

  /**
   * Init Our own buttons & inputs when Google Map is ready
   * @param event Google Map ready
   */
  mapIsReady(event: any) {
      this.map = event;
      if (this.deviceService.isMobile) {
        this.mobileAutocomplete = new google.maps.places.AutocompleteService();
        this.autocomplete = { input: '' };
        this.clearAutocompleteSearch();
      } else {
        const options = {
          types: ['(cities)']
        };
        const input = <HTMLInputElement>document.getElementById('map-search');
        this.searchBox = new google.maps.places.Autocomplete(input, options);
      }
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('map-location'));
      this.googleMapReady = true;
      this.updateMapWithNewLocation();
      this.emitMapReady(event);
  }

  emitMapReady(event: any) {
    this.mapReady.emit(event);
  }

  /**
   * Update the marker in the google map with the new coordinates
   */
  updateMapWithNewLocation(latlng?: {lat: number, lng: number}): void {
    this.markerPositions = [
      {
        lat: this.latitude,
        lng: this.longitude
      }
    ];
    if(latlng) {
      this.markerPositions = [latlng];
    }
    this.center = this.markerPositions[0];
  }

  /**
   * Method to show list of corresponding autocomplete addresses
   */
  showAutocompletePlaces(event) {
    if (!event.target.value) {
      this.clearAutocompleteSearch();
      return;
    }
    this.mobileAutocomplete.getPlacePredictions({ input: event.target.value },
      (predictions, status) => {
        if (status === 'OK') {
          this.clearAutocompleteSearch();
          predictions.forEach((prediction) => {
            this.autocompletePlaces.push(prediction);
          });
        }
      });
  }

  onMapClick(event: google.maps.MapMouseEvent) {
    this.mapClickedOrDrag({coords: {lat: event.latLng.lat(), lng: event.latLng.lng()}})
  }

  /**
   * Change coords when click on map or drag the marker
   * @param event object which contains coords (latitude, longitude)
   */
  mapClickedOrDrag(event: { coords: { lat: number, lng: number } }) {
    this.setCoordinates(event.coords.lat, event.coords.lng);
  }

  locateMe() {
    this.coordinatesService.geolocateUser().then(location => {
      if (location instanceof Coordinates) {
        return this.setCoordinates(location.lat, location.lng);
      }
    }).catch(error => {
      throw error;
    });
  }

  setCoordinates(lat: number, lng: number) {
    this.coordinatesChange.emit(new Coordinates(lat, lng));
    this.updateMapWithNewLocation({lat, lng});
  }

  /**
   * Search a city about many results, take the first one and update the map
   * @param city value from user input
   */
  geocodeCity(city) {
    const geocoder = new google.maps.Geocoder();
    if(this.deviceService.isMobile) {
      const input = <HTMLInputElement>document.getElementById('map-search');
      input.value = city;
    }
    geocoder.geocode({ 'address': city }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const place = results[0];
        const lat: number = place.geometry.location.lat();
        const lng: number = place.geometry.location.lng();
        this.setCoordinates(lat, lng);
      }
    });
  }

  clearAutocompleteSearch() {
    this.autocompletePlaces = [];
  }

}
