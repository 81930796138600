import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './spinner.service';
import { ComponentCanDeactivate } from '@services/guards/pending-changes.guard';
import { BACKUP_TYPES, BackupService } from '@services/backup/backup.service';

@Injectable()
export class DialogService {

  constructor(
    private translateService: TranslateService,
    private alertController: AlertController,
    private spinnerService: SpinnerService,
    private backupService: BackupService,
  ) {}

  confirm(message?: string): Observable<boolean> {
    const alertController = this.alertController;
    const self = this;
    this.spinnerService.deactivate(); // Force deactivate spinner to avoid blocking users when dialogs are displayed
    return new Observable(subscriber => {
      self.translateService.get(['label.confirm', 'Ok', 'btn.cancel']).subscribe({
        next(translation) {
          self.alertController.create({
            header: translation['label.confirm'],
            backdropDismiss: false,
            message: message || 'Do you want to leave the page',
            buttons: [
              {
                text: translation['Ok'],
                cssClass: 'text-dark',
                handler: () => {
                  subscriber.next(true);
                  subscriber.complete();
                }
              },
              {
                text: translation['btn.cancel'],
                cssClass: 'text-dark',
                handler: () => {
                  subscriber.next(false);
                  subscriber.complete();
                }
              },
            ],
          }).then(alert => alert.present());
        }
      });
    });
  }

  confirmWithBackup(component: ComponentCanDeactivate, backupItem: BACKUP_TYPES, message?: string): Observable<boolean> {
    const alertController = this.alertController;
    const self = this;
    this.spinnerService.deactivate(); // Force deactivate spinner to avoid blocking users when dialogs are displayed
    return new Observable(subscriber => {
      self.translateService.get(['label.confirm', 'Ok', 'btn.cancel']).subscribe({
        next(translation) {
          self.alertController.create({
            header: translation['label.confirm'],
            backdropDismiss: false,
            message: message || 'Do you want to leave the page',
            buttons: [
              {
                text: translation['Ok'],
                cssClass: 'text-dark',
                handler: () => {
                  self.backupService.removeBackupItem(BACKUP_TYPES.EVENT);
                  subscriber.next(true);
                  subscriber.complete();
                }
              },
              {
                text: translation['btn.cancel'],
                cssClass: 'text-dark',
                handler: () => {
                  subscriber.next(false);
                  subscriber.complete();
                }
              },
            ],
          }).then(alert => alert.present());
        }
      });
    });
  }
}
