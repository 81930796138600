import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tag } from '@models/tag';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';

@Component({
  selector: 'app-event-tag',
  templateUrl: './event-tag.component.html',
  styleUrls: ['./event-tag.component.scss', '../event-legacy-field.component.sass']
})
export class EventTagComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() tags: Tag[];
  @Input() tagsLoaded: boolean;

  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
    
  }

}
