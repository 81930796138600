import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Coordinates, CoordinatesService } from '@services/shared/coordinates.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-gps-popup',
  templateUrl: './select-gps-popup.component.html'
})
export class SelectGpsPopupComponent implements OnInit, OnDestroy {

  geoUser: boolean;
  longitude: number;
  latitude: number;
  coordinateSubject: Subject<Coordinates>;
  private backup: Coordinates;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private coordinatesService: CoordinatesService,
  ) {}

  ngOnInit() {
    this.geoUser = this.navParams.get('geoUser');
    this.longitude = this.navParams.get('initLongitude');
    this.latitude = this.navParams.get('initLatitude');
    this.backup = new Coordinates(this.latitude, this.longitude);
    this.coordinateSubject = this.navParams.get('coordinateSubject');
    if (!this.latitude || !this.longitude) {
      this.coordinatesService.geolocateUser()
        .then(coordinates => this.onCoordinatesChange(coordinates))
        .catch(() => this.coordinatesService.getLocationFromCurrentSite())
        .then(coordinates => {
          if (coordinates) {
            this.onCoordinatesChange(coordinates);
          }
        })
    }
  }

  ngOnDestroy() {
    if (this.coordinateSubject) {
      this.coordinateSubject.complete();
    }
  }

  onCoordinatesChange(coordinates: Coordinates) {
    if (coordinates) {
      this.latitude = coordinates.lat;
      this.longitude = coordinates.lng;
    }
  }

  saveAndClosePopup() {
    this.coordinateSubject.next(new Coordinates(this.latitude, this.longitude));
    this.closePopup();
  }

  closePopup() {
    this.modalController.dismiss();
  }
}
