import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
// Ionic Components
import { ModalController } from '@ionic/angular';
// Services
import { UiDatetimePickerService, UiDatetimePickerTranslateService } from '../../../services';
// Components
import { UiCommonDatetimePickerComponent } from '../../common/ui-common-datetime-picker/ui-common-datetime-picker.component';
/**
 * #Mobile Datetime Picker
 * Component for displaying a calendar and a timepick for selecting a date / time.
 * @author Julien Bertacco 2019.
 */
@Component({
  selector: 'ui-mobile-datetime-picker',
  styleUrls: ['ui-mobile-datetime-picker.component.scss'],
  templateUrl: 'ui-mobile-datetime-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiMobileDatetimePickerComponent extends UiCommonDatetimePickerComponent implements OnInit {
  public customEditEndDateFunction: Function;
  public customEditStartDateFunction: Function;

  constructor(
    public modalController: ModalController,
    public datetimePickerService: UiDatetimePickerService,
    protected datetimeTranslateService: UiDatetimePickerTranslateService,
  ) {
    super(
      datetimePickerService,
      datetimeTranslateService,
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  close(): void {
    super.close();
    this.modalController.dismiss();
  }
  editEndDate(): void {
    this.save();
    this.datetimePickerService.type = 'endDatetime';
    if (this.customEditEndDateFunction) { this.customEditEndDateFunction(); }
    this.init();
  }
  editStartDate(): void {
    this.save();
    this.datetimePickerService.type = 'startDatetime';
    if (this.customEditStartDateFunction) { this.customEditStartDateFunction(); }
    this.init();
  }
}
