<ion-header class="secondary-header" mode="ios" *ngIf="!showImageEditor">
  <app-mobile-toolbar
      doneLabel="ok"
      [disableDoneButton]="readOnly"
      title="events.list.images"
      (back)="ignoreChanges()"
      (done)="acceptChanges()">
  </app-mobile-toolbar>
</ion-header>

<!-- Display only the title in the toolbar when editing images -->
<ion-header class="secondary-header" mode="ios" *ngIf="showImageEditor">
  <ion-toolbar mode="ios" class="toolbar-fixed" color="primary">
    <ion-title color="light">
      {{ 'events.list.images' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<!-- EVENT'S PICTURES GALLERY -->
<ion-content>
  <ion-grid *ngIf="!showImageEditor">
    <ion-item lines="none" class="mt-2 no-lines" *ngIf="attachments.length <= 0">
      <ion-text class="font-italic" color="primary">
        {{'mobile-no-image' | translate}}
      </ion-text>
    </ion-item>
    <ion-row *ngIf="attachments.length > 0">
      <ion-col ngxViewer [viewerOptions]="viewerOptions" *ngFor="let attachment of attachments" size="6">
        <ion-row>
          <ion-col class="image-container" size="9">
              <img
                [src]="imagesInputComponent.getPictureUrl(attachment, false) | secureImageSrc | async"
                [class.new-image]="imagesInputComponent.isNewAttachment(attachment)"
                class="thumbnail big"/>
                <ion-label *ngIf="!readOnly && isNewEvent" class="edit-image">
                  <ion-icon mode="md" (click)="editImage(attachment)" name="create-outline"></ion-icon>
                </ion-label>
          </ion-col>
          <ion-col class="d-flex delete-picture" size="3">
            <ion-label *ngIf="!readOnly">
              <ion-icon mode="md" (click)="removeImage(attachment)" name="custom-delete" color="danger"></ion-icon>
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Uploaded Image Editor -->
  <app-image-editor *ngIf="showImageEditor && editingImage" [image]="editingImage" (editedImage)="imageAfterEditing($event)"></app-image-editor>
</ion-content>
<!-- END OF EVENT'S PICTURES GALLERY -->

<!-- FOOTER (choices to load pictures from camera or gallery) -->
<ion-footer *ngIf="!readOnly && !showImageEditor">
  <ion-toolbar>
    <!-- IF CORDOVA AVAILABLE -->
    <ion-row *ngIf="!(stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async); else realImageInput" fixed>
      <ion-col no-margin size="6" class="right-separator">
        <label class="bigger text-primary" for="NOTHING" (click)="annoyUser()">
          <span class="aligned">{{'mobile-action-camera' | translate | uppercase}}</span>
          <ion-icon mode="md" color="primary" class="pl-2 aligned" slot="icon-only" name="custom-camera"></ion-icon>
        </label>
      </ion-col>
      <ion-col no-margin size="6">
        <label class="bigger text-primary" for="NOTHING" (click)="annoyUser()">
          <span class="aligned">{{'mobile-action-gallery' | translate | uppercase}}</span>
          <ion-icon mode="md" color="primary" class="pl-2 aligned" slot="icon-only" name="custom-images"></ion-icon>
        </label>
      </ion-col>
    </ion-row>
    <ng-template #realImageInput>
      <ion-buttons *ngIf="deviceService.hasCordova">
        <ion-col size="6">
          <ion-button (click)="takePictureFromCamera()" color="primary" expand="full" class="right-separator">
            <ion-icon mode="md" slot="end" name="custom-camera"></ion-icon>
            {{'mobile-action-camera' | translate}}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button (click)="loadPicturesFromGallery()" color="primary" expand="full">
            <ion-icon mode="md" slot="end" name="custom-images"></ion-icon>
            {{'mobile-action-gallery' | translate}}
          </ion-button>
          <input id="image" type="file" accept="image/*" (change)="onFileChange($event)" style="display: none;" multiple />
        </ion-col>
      </ion-buttons>
      <!-- IF CORDOVA UNAVAILABLE (should use html5 file input to load files) -->
      <ion-row *ngIf="!deviceService.hasCordova" fixed>
        <ion-col no-margin size="6" class="right-separator">
          <label class="bigger text-primary" for="cameraFileInput">
            <span class="aligned">{{'mobile-action-camera' | translate | uppercase}}</span>
            <ion-icon mode="md" color="primary" class="pl-2 aligned" slot="icon-only" name="custom-camera"></ion-icon>
          </label>
          <!-- Invisible file HTML5 input to load file from camera -->
          <input
            class="d-none"
            type="file"
            accept="image/*"
            (change)="loadPicturesFromWeb($event?.target?.files)"
            [multiple]="stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async"
            id="cameraFileInput"
            capture="camera">
        </ion-col>
        <ion-col no-margin size="6">
          <label class="bigger text-primary" for="galleryFileInput">
            <span class="aligned">{{'mobile-action-gallery' | translate | uppercase}}</span>
            <ion-icon mode="md" color="primary" class="pl-2 aligned" slot="icon-only" name="custom-images"></ion-icon>
          </label>
          <!-- Invisible file HTML5 input to load file from gallery -->
          <input
            class="d-none"
            type="file"
            accept="image/*"
            (change)="loadPicturesFromWeb($event?.target?.files)"
            [multiple]="stripeService.currentSpaceHasFeature('EVENT_MULTIPLE_ATTACHMENT') | async"
            id="galleryFileInput">
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-toolbar>
</ion-footer>
<!-- END OF FOOTER -->
