import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
  selector: 'app-custom-form-dropdown-field',
  templateUrl: './custom-form-dropdown-field.component.html',
  styleUrls: ['./custom-form-dropdown-field.component.scss'],
})
export class CustomFormDropdownFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  dropdownOptions: string[] = [];
  selectedOption: any;

  constructor() { }

  ngOnInit() {
    this.customField.children.options.map((option) => { 
      if (option.value !== "") {
        this.dropdownOptions.push(option)
      }
    })
  }

}
