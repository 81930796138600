export interface RequestOTPResponse {
  nextResendAt: number
}

export enum RequestOTPErrors {
  TEMPORARILY_BLOCKED = 'TEMPORARILY_BLOCKED',
  UNSUPPORTED_PHONE_NUMBER = 'UNSUPPORTED_PHONE_NUMBER',
  PHONE_NUMBER_UNAVAILABLE = 'PHONE_NUMBER_UNAVAILABLE',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  TWILIO_EXCEPTION = 'TWILIO_EXCEPTION',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS'
}
