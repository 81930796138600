import { Component, Input, OnInit } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';


@Component({
  selector: 'app-reject-button',
  templateUrl: './reject-button.component.html',
  styleUrls: ['./reject-button.component.scss'],
})
export class RejectButtonComponent extends AbstractButtonComponent implements OnInit {
  @Input() style: 'normal' | 'reverse' = 'normal';

  buttonText: string;

  ngOnInit(): void {
    this.buttonText = (this.style === 'normal') ? 'events.detail.btn.reject' : 'events.detail.panel_creation.rejected';
  }

}
