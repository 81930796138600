import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkStatus } from '../../models/synchronization/network-status';
import { OfflineNetworkError } from '../../models/errors/offline-network-error';
import { UrlGiverService } from '../url-giver.service';

/** Intercepts http requests before they are sent if user is offline **/
@Injectable()
export class OfflineRequestInterceptor implements HttpInterceptor {

  constructor(
    private urlGiver: UrlGiverService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!NetworkStatus.isOnline && !request.url.startsWith(this.urlGiver.giveAssetsUrl())) {
      //if user is offline, we don't send http request and throw error instead
      throw new OfflineNetworkError();
    }
    return next.handle(request);
  }

}
