import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileConversionService {

  constructor() { }

  //TODO: Completely migrate to using only capacitor plugins to potentially fix this issue
  /**
   * The filereader events doesn't fire correctly in a capacitor project.
   * A workaround mentioned here: https://github.com/ionic-team/capacitor/issues/1564 is used
   * as the cordova file plugin cannot be removed completely from the project yet.
   * @returns FileReader
   */
  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

}
