import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditingTool } from '@models/enums/editing-tool';
import { ToolbarProperty } from '@models/enums/toolbar-property.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IToolbarMessage, TextWidget, ToolbarMessageService } from '@services/toolbar-message.service';
import Cropper from 'cropperjs';
import { ColorPalette } from '@models/enums/color-palette';
import * as htmlToImage from 'html-to-image';
import { Subscription } from 'rxjs';
import { SpinnerService } from '@services/spinner.service';
import { DeviceService } from '@services/device.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit {
  @Input() image: string | ArrayBuffer;
  @Output() editedImage: EventEmitter<string | null> = new EventEmitter();

  public cropper: Cropper;
  public croppedImage: string | ArrayBuffer;
  public canvasBoundings: DOMRect;
  public canvasHeight: number = 0;
  public canvasWidth: number = 0;
  public textWidgets: TextWidget[] = [];
  public positionDifference: {[key: string]: number} = { x: 0, y: 0 }; // difference between mouse/touch coordinates and widget Coordinates
  public activeWidgetElement: HTMLElement | null = null;
  public widgetDraggable: boolean = false;
  public toolbarMessageSubscription: Subscription;
  public activeWidgetSubscription: Subscription;
  public isImageCropped: boolean = false;
  public maxWidgetDims: {[key: string]: number} = { top: 0, left: 0};
  public addTextModalRef: NgbModalRef;

  @ViewChild("cropCanvas") cropCanvas: ElementRef;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("canvasToolbar") canvasToolbar: ElementRef;

  constructor(private toolbarService: ToolbarMessageService,
    private spinnerService: SpinnerService,
    public deviceService: DeviceService,
    private alertController: AlertController,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.subscribeToToolbarMessages();
    this.subscribeToActiveWidgetSubscription();
  }


  ngAfterViewInit() {
  }

  public subscribeToToolbarMessages() {
    this.toolbarMessageSubscription =
      this.toolbarService.toolbarMessages.subscribe((message: IToolbarMessage) => {
        this.processToolbarMessage(message);
      });
  }

  public subscribeToActiveWidgetSubscription() {
    this.activeWidgetSubscription = this.toolbarService.activeWidget.subscribe(activeWidget => {
      if(!activeWidget) {
        if(this.activeWidgetElement) {
          this.activeWidgetElement.classList.remove('active-widget');
        }
      };
    });
  }

  public processToolbarMessage(message: IToolbarMessage) {
    if(message.tool === EditingTool.Text) {
      const widgetIndex = this.textWidgets.findIndex((widget) => widget.id === message.id);

      if(widgetIndex !== -1) {
        switch(message.property) {
          case ToolbarProperty.Color:
            this.textWidgets[widgetIndex].color = message.value as string;
            break;

          case ToolbarProperty.Size:
            this.textWidgets[widgetIndex].size = message.value + 'px';
            break;

          case ToolbarProperty.Delete:
            this.textWidgets.splice(widgetIndex,1);
            break;
        }
      }
    }
  }

  public async startCrop() {
    const cropCanvas = document.getElementById("image-src") as HTMLImageElement;
    this.cropper = new Cropper(cropCanvas, {
      viewMode: 1,
      zoomable: false,
      scalable: false,
    });
    this.spinnerService.deactivate();
  }

  public onFinishClick() {
    if(this.isImageCropped) {
      this.finalizingImage();
      return;
    }

    this.croppedImage = this.cropper.getCroppedCanvas().toDataURL('image/png');
    this.isImageCropped = true;
    this.cropCanvas.nativeElement.style.display = 'none';
    this.canvas.nativeElement.firstChild.style.backgroundImage = `url(${this.croppedImage})`;
    this.canvas.nativeElement.style.display = 'flex';
    this.canvasToolbar.nativeElement.style.display = 'block';

    this.updateCanvasConfig();
  }

  public updateCanvasConfig() {
    this.canvasBoundings = this.canvas.nativeElement.firstChild.getBoundingClientRect();
    this.canvasHeight = this.canvasBoundings.height;
    this.canvasWidth = this.canvasBoundings.width;
  }

  public onCancel() {
    this.editedImage.emit(null);
  }

  public onPointerMove(event: TouchEvent | MouseEvent) {
    if (
      !this.activeWidgetElement ||
      !this.canvas ||
      !this.widgetDraggable
    ) {
      return;
    }
    event.preventDefault();

    this.widgetDraggable = true;

    let pageX: number;
    let pageY: number;

    if(this.deviceService.isMobile) {
      event = event as TouchEvent;
      pageX = event.targetTouches[0].pageX;
      pageY = event.targetTouches[0].pageY;
    } else {
      event = event as MouseEvent;
      pageX = event.pageX;
      pageY = event.pageY;
    }

    let newTop = pageY - this.positionDifference.y;
    let newLeft = pageX - this.positionDifference.x;

    /// Preventing Text widget to not go outside canvas

    if (newTop < 0) {
      newTop = 0;
    }

    if (newLeft < 0) {
      newLeft = 0;
    }

    if(newTop > this.maxWidgetDims.top) {
      newTop = this.maxWidgetDims.top;
    }

    if(newLeft > this.maxWidgetDims.left) {
      newLeft = this.maxWidgetDims.left;
    }

    this.activeWidgetElement.style.top = newTop + "px";
    this.activeWidgetElement.style.left = newLeft + "px";
  }

  public onPointerClick(event: TouchEvent | MouseEvent, textWidgetId: string) {
    if (!event) {
      return;
    }

    event.preventDefault();

    if(this.activeWidgetElement) {
      this.activeWidgetElement.classList.remove('active-widget');
    }

    this.emitActiveWidgetMessage(textWidgetId);
    this.activeWidgetElement = event.currentTarget as HTMLElement;
    this.activeWidgetElement.classList.add('active-widget');

    const widgetDims = this.activeWidgetElement.getBoundingClientRect();
    const canvasDims = this.canvas.nativeElement.firstChild.getBoundingClientRect();

    this.maxWidgetDims.top = canvasDims.height - widgetDims.height;
    this.maxWidgetDims.left = canvasDims.width - widgetDims.width;

    let pageX: number;
    let pageY: number;

    if(this.deviceService.isMobile) {
      event = event as TouchEvent;
      pageX = event.targetTouches[0].pageX;
      pageY = event.targetTouches[0].pageY;
    } else {
      event = event as MouseEvent;
      pageX = event.pageX;
      pageY = event.pageY;
    }

    if (widgetDims) {
      this.positionDifference = {
        x: pageX - (widgetDims.left - canvasDims.left),
        y: pageY - (widgetDims.top - canvasDims.top),
      };
    }

    this.widgetDraggable = true;
  }

  public emitActiveWidgetMessage(textWidgetId: string | null) {
    let widget: TextWidget;

    if(textWidgetId) {
      widget = this.textWidgets.find(widget => widget.id === textWidgetId);
      if(!widget) {
        widget = null;
      }
    } else {
      widget = null;
    }

    this.toolbarService.broadcastActiveWidgetMessage(widget);
  }

  public onPointerEnd() {
    this.widgetDraggable = false;
  }

  public async finalizingImage() {
    this.spinnerService.activate('rotating');
    if(this.activeWidgetElement) {
      this.activeWidgetElement.classList.remove('active-widget');
    }

    const final = document.getElementById('final-canvas');
    const image = await htmlToImage.toJpeg(final);
    this.spinnerService.deactivate();
    this.editedImage.emit(image);
  }

  async onNewTextAdd() {
    this.translate.get([
      'events.upload-image.edit.add-text',
      'Okay',
    ]).pipe(
      mergeMap(async translations => {
        const alert = await this.alertController.create({
          header: translations['events.upload-image.edit.add-text'],
          mode: 'ios',
          cssClass: 'add-new-text-box',
          inputs: [
            {
              type: 'textarea',
              cssClass: 'text-input'
            }
          ],
          buttons: [
            {
              text: translations['Okay'],
              handler: (text) => {
                const widget = new TextWidget(ColorPalette.Red,'15px');
                widget.text = text[0];
                this.textWidgets.push(widget);
              },
            },
          ],
        });
        alert.present().then(() => {
          const firstInput: HTMLTextAreaElement = document.querySelector('ion-alert textarea');
          firstInput.focus();
          return;
        });
      })
    ).toPromise();
  }

  ngOnDestroy(): void {
    this.addTextModalRef?.close();
    this.toolbarMessageSubscription.unsubscribe();
    this.activeWidgetSubscription.unsubscribe();
  }
}
