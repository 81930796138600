import { EventContractor } from './event-contractor';
import { EventTag } from './event-tag';
import { Location } from './location';
import { ModelElement } from './model-element';

export enum EventStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
  DRAFT = 'Draft'
}

export class FilteredEvent {
  id: string;
  status: string;
  title: string;
  startDatetime: Date;
  createdBy: string;
  contractors: EventContractor[];
  tags: EventTag[];
  locationId: string;
  locationObject: Location;

  constructor(
    title: string = '',
    id: string = ModelElement.generateId(),
    startDatetime: Date = new Date(),
    createdBy: string = '',
    status: string = EventStatus.DRAFT,
    contractors: EventContractor[] = [],
    tags: EventTag[] = [],
    locationId: string = '',
    locationObject: Location = new Location(),
    ) {
    this.title = title;
    this.id = id;
    this.startDatetime = startDatetime;
    this.createdBy = createdBy;
    this.status = status;
    this.contractors = contractors;
    this.tags = tags;
    this.locationId = locationId;
    this.locationObject = locationObject;
  }

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend sync
  * @param model Filtered Event
  */
  public static toModel(json: any, model: FilteredEvent) {
    model.id = json.payload.id;
    model.title = json.payload.title;
    model.startDatetime = json.payload.startDatetime;
    model.status = json.payload.status;
    model.createdBy = json.payload.createdBy
  }

  public static convertJsonToFilteredEvent(json: any): FilteredEvent[] {
    return json.map((filteredEventObject) => {
      let filteredEvent = new FilteredEvent();
      filteredEvent.id = filteredEventObject.id;
      filteredEvent.title = filteredEventObject.title;
      filteredEvent.startDatetime = filteredEventObject.startDatetime;
      filteredEvent.createdBy = filteredEventObject.createdBy;
      filteredEvent.status = filteredEventObject.status;
      filteredEvent.contractors = EventContractor.jsonToEventContractors(filteredEventObject.contractors);
      filteredEvent.tags = EventTag.jsonToEventTags(filteredEventObject.tags);
      filteredEvent.locationId = filteredEventObject.locationId;
      return filteredEvent;
    });
  }

  toDTO() {

  }

  public get label(): string {
    return this.title;
  }
}
