import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { NetworkStatus } from '@models/synchronization/network-status';
import { ApplicationVersionStatus, AppUpdateSettings } from '@models/utils/app-update-settings';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DeviceService } from './device.service';
import { LogoutService } from './logout/logout.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';
import * as Package from '../../../package.json';

export interface VersionCheckApiResponse {
  "updateLevel": string
}

const LOCAL_STORAGE_APPUPDATE_SETTINGS = 'appUpdateSettings';
const LATEST_APP_UPDATE_CHECK_TIMESTAMP = 'latestAppUpdateCheckTimestamp';
// Milliseconds equivalent to ~ 5 days
const UPDATE_CHECK_THRESHOLD = 432_000_000;
const ANDROID_APP_ID = 'com.scriptandgo.sitediary';
const APP_UPDATE_ALERT = 'appUpdateAlert';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateSettingsService {

  packageInfo = Package;

  private currentSettings$: BehaviorSubject<AppUpdateSettings>;
  private appUpdateSettings: AppUpdateSettings;
  private isUpdateBannerDismissed: boolean = false;

  constructor(
    private toasterService: ToasterService,
    private urlGiverService: UrlGiverService,
    private http: HttpClient,
    private deviceService: DeviceService,
    private logoutService: LogoutService,
    private logger: NGXLogger,
    private alertController: AlertController,
    private translateService: TranslateService,
  ) {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_APPUPDATE_SETTINGS);
    if (storedSettings) {
      this.currentSettings$ = new BehaviorSubject(Object.assign(new AppUpdateSettings(), JSON.parse(storedSettings)));
    } else {
      this.currentSettings$ =  new BehaviorSubject(new AppUpdateSettings());
    }
  }

  getAppUpdateSettings(): AppUpdateSettings {
    return Object.assign(new AppUpdateSettings(), this.currentSettings$.getValue());
  }

  setAppUpdateSettings(key: keyof AppUpdateSettings, value: any): void {
    const currentSettings: any = this.currentSettings$.getValue();
    if (key in currentSettings) {
      currentSettings[key] = value;
      localStorage.setItem(LOCAL_STORAGE_APPUPDATE_SETTINGS, JSON.stringify(currentSettings));
      this.currentSettings$.next(currentSettings);
    }
  }

  clear(): void {
    localStorage.removeItem(LOCAL_STORAGE_APPUPDATE_SETTINGS);
  }

  // update the app update settings in local storage
  updateAppUpdateSettings(response) {
    this.setAppUpdateSettings('appUpdateLevel', response);
    this.setAppUpdateSettings('currentAppVersion', this.packageInfo.version);
    localStorage.setItem(LATEST_APP_UPDATE_CHECK_TIMESTAMP, new Date().toUTCString());

    switch(response) {
      case ApplicationVersionStatus.appUpToDate: {
        this.setAppUpdateSettings('showAppUpdateBanner', false);
        this.setAppUpdateSettings('showAppUpdateToaster', false);
        this.setAppUpdateSettings('canCreateEntities', true);
        this.dismissUpdateAlert();
        break;
      }
      case ApplicationVersionStatus.appOutdated: {
        this.setAppUpdateSettings('showAppUpdateBanner', true);
        this.setAppUpdateSettings('showAppUpdateToaster', true);
        this.setAppUpdateSettings('canCreateEntities', true);
        this.dismissUpdateAlert();
        break;
      }
      case ApplicationVersionStatus.appOutdatedSyncOnly: {
        this.setAppUpdateSettings('showAppUpdateBanner', false);
        this.setAppUpdateSettings('showAppUpdateToaster', true);
        this.setAppUpdateSettings('canCreateEntities', false);
        this.showUpdateAlert();
        break;
      }
      case ApplicationVersionStatus.appUnsupported: {
        this.setAppUpdateSettings('showAppUpdateBanner', true);
        this.setAppUpdateSettings('showAppUpdateToaster', true);
        this.setAppUpdateSettings('canCreateEntities', false);
        // log the user out if app is unsupported
        this.logoutService.disconnectServices();
        this.toasterService.showInfoToaster("app.update.unsupported");
        break;
      }
    }
  }

  async showUpdateAlert(): Promise<void> {
    this.translateService.get([
      'app.update.unsupported',
      'app.update.update.button',
    ]).pipe(
      mergeMap(async translations => {
      const alert = await this.alertController.create({
        id: APP_UPDATE_ALERT,
        header: translations['app.update.unsupported'],
        backdropDismiss: false,
        buttons: [
          {
            text: translations['app.update.update.button'],
            role: 'submit',
            handler: () => {
              this.goToAppStore();
            },
          },
        ],
      });
      return await alert.present();
    })
    ).toPromise();
  }

  async dismissUpdateAlert(): Promise<void> {
    try {
      await this.alertController.dismiss(null, null, APP_UPDATE_ALERT);
    } catch(e) {}
  }

  goToAppStore(): void {
    if (this.deviceService.isAndroid) {
      (<any>cordova.plugins).AppReview.openStoreScreen(ANDROID_APP_ID, false);
    }
    else if (this.deviceService.isIos) {
      (<any>cordova.plugins).AppReview.openStoreScreen();
    }
  }

  watchAppUpdateSettings(): BehaviorSubject<AppUpdateSettings> {
    return this.currentSettings$;
  }

  // call version check API and set when the last time API was called called.
  versionCheck(): void {
    this.appUpdateSettings = this.getAppUpdateSettings();
    let versionCheckApiUrl = this.urlGiverService.giveVersionCheckAPIUrl();
    let updateLevel: string;
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      try {
        this.http.get<VersionCheckApiResponse>(versionCheckApiUrl).toPromise()
        .then(response => {
          updateLevel = response.updateLevel;
          this.updateAppUpdateSettings(updateLevel);
        })
        .catch(error => {
          this.logger.error(error);
        });
      } catch {
        this.logger.error('Error while making version check API call', false);
      }
    });
    if(!NetworkStatus.isOnline && !NetworkStatus.isPinging) {
      this.appUpdateSettings = this.getAppUpdateSettings();
      if(this.appUpdateSettings.currentAppVersion === this.packageInfo.version) {
        if(this.appUpdateSettings.appUpdateLevel === ApplicationVersionStatus.appOutdatedSyncOnly) {
          this.showUpdateAlert();
        } else {
          this.dismissUpdateAlert();
        }
      }
    }
  }

  showUpdateToaster(): boolean {
    this.appUpdateSettings = this.getAppUpdateSettings();
    if (this.appUpdateSettings.showAppUpdateToaster) {
      return true;
    }
    else {
      return false;
    }
  }

  canCreateEntites(): boolean {
    this.appUpdateSettings = this.getAppUpdateSettings();
    if (this.appUpdateSettings.canCreateEntities) {
      return true;
    }
    else{
      return false;
    }
  }

  periodicVersionCheck(): void {
    if (!this.isUpdateBannerDismissed) {
      const lastCheckedString = localStorage.getItem(LATEST_APP_UPDATE_CHECK_TIMESTAMP);
      if (lastCheckedString) {
        const timeDifference = new Date().getTime() - new Date(lastCheckedString).getTime();
        // We do not show the banner throughout the session if the user has dismissed this banner already
        if (timeDifference >= UPDATE_CHECK_THRESHOLD) {
          this.logger.info('Forcefully checking for app update. Last app version update check performed on: ', lastCheckedString);
          this.versionCheck();
        }
      } else {
        this.logger.info('Last app update check timestamp does not exist in localStorage.')
      }
    }
  }

  onWebUpdateDismiss(): void {
    this.isUpdateBannerDismissed = true;
    this.logger.info('Dismissed app update prompt banner. It will not be shown throughout the session.')
    this.setAppUpdateSettings('showAppUpdateBanner', false);
    this.setAppUpdateSettings('showAppUpdateToaster', false);
  }
}
