<ion-header class="secondary-header" mode="ios">
  <app-mobile-toolbar
       title="label.export"
       (back)="ignoreChanges()">
  </app-mobile-toolbar>
</ion-header>

<ion-content class="main-form-wrapper">
  <div class="export-buttons">
    <button class="btn btn-primary export-button" color="primary" (click)="openPdfSummaryModal()">
      {{ 'events.export.pdf.summary' | translate | uppercase }}</button>
    <button class="btn btn-primary export-button" color="primary" (click)="openPdfModal()">
      {{ 'events.export.pdf.events' | translate | uppercase }}</button>
    <button class="btn btn-primary export-button" color="primary" (click)="getCsvReport()">
      {{ 'events.export.csv' | translate | uppercase }}</button>
    <button class="btn btn-primary export-button" color="primary" (click)="getXlsReport()">
      {{ 'events.export.excel' | translate | uppercase }}</button>
  </div>
</ion-content>
