import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppTypeService } from '@services/app-type.service';
import { SharedDataService } from '@services/shared-data.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding-webapp.component.html',
  styleUrls: ['./on-boarding-webapp.component.sass'],
})
export class OnBoardingWebappComponent implements OnInit {
  baseUrl = window.location.href.split('/space/')[0] + '/space/' + this.sharedDataService.currentSpaceId;
  administrationLink: string = this.baseUrl + '/edit';
  administrationEventFormLink: string = this.baseUrl + '/event-form';
  administrationDashboardLink: string = this.baseUrl + '/dashboards';
  administrationResources: string = this.baseUrl + '/resources/labours';
  administrationInformation: string = this.baseUrl + '/information/tags';
  administrationUsersSubscriptions: string = this.baseUrl + '/users/list';
  onboardingDataObject = [];
  slideWelcomeMessage: string = '';
  appType: string = 'diary';

  constructor(
    protected dialogRef: MatDialogRef<OnBoardingWebappComponent>,
    private sharedDataService: SharedDataService,
    private appTypeService: AppTypeService,
  ) {
  }

  ngOnInit() {
    if (this.appTypeService.getCurrentAppType() === 'tasks') {
      this.onboardingDataObject = [...this.onBoardingSiteTask];
      this.slideWelcomeMessage = 'onboarding.site_task.welcome.slide';
    } else {
      this.onboardingDataObject = [...this.onBoardingSiteDiary];
      this.slideWelcomeMessage = 'onboarding.site_diary.welcome.slide';
    }
    this.appType = this.appTypeService.getCurrentAppType();
  }

  showOnboarding: boolean = true;
  screenNumber: number = 0;

  onBoardingSiteDiary = [
    {
      title: "onboarding.site_diary.welcome_slide.title",
      subTitle: "onboarding.site_diary.welcome_slide.subTitle",
      icon: "site-diary-welcome.svg",
      userGuideLink: "https://www.youtube.com/watch?v=CcmcSYMJ11g"
    },
    {
      title: "onboarding.site_diary.diary_forms_slide.title",
      subTitle: "onboarding.site_diary.diary_forms_slide.subTitle",
      icon: "site-diary-customise-forms.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8889915-site-dairy-customize-the-form"
    },
    {
      title: "onboarding.site_diary.custom_tags_slide.title",
      subTitle: "onboarding.site_diary.custom_tags_slide.subTitle",
      icon: "site-diary-custom-tags.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8889751-site-diary-site-task-how-to-set-up-resources-such-as-manpower-equipment-and-materials-for-your-organization-and-site"
    },
    {
      title: "onboarding.site_diary.analytics_slide.title",
      subTitle: "onboarding.site_diary.analytics_slide.subTitle",
      icon: "site-diary-analytics.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/9077115-site-diary-site-task-dashboards"
    },
    {
      title: "onboarding.site_diary.roles_slide.title",
      subTitle: "onboarding.site_diary.roles_slide.subTitle",
      icon: "site-diary-roles.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8898648-site-diary-site-task-how-to-invite-the-team"
    },
    {
      title: "onboarding.site_diary.entry_slide.title",
      subTitle: "onboarding.site_diary.entry_slide.subTitle",
      icon: "site-diary-entry.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8805947-site-diary-site-task-create-a-new-site-on-site-diary"
    },
    {
      title: "onboarding.site_diary.export_slide.title",
      subTitle: "onboarding.site_diary.export_slide.subTitle",
      icon: "site-diary-entry.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8890598-site-diary-how-to-export-reports-pdf-excel-and-csv"
    }
  ];

  onBoardingSiteTask = [
    {
      title: "onboarding.site_task.welcome_slide.title",
      subTitle: "onboarding.site_task.welcome_slide.subTitle",
      icon: "site-task-welcome.svg",
      userGuideLink: null
    },
    {
      title: "onboarding.site_task.custom_tags_slide.title",
      subTitle: "onboarding.site_task.custom_tags_slide.subTitle",
      icon: "site-task-custom-tags.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8889751-site-diary-site-task-how-to-set-up-resources-such-as-manpower-equipment-and-materials-for-your-organization-and-site"
    },
    {
      title: "onboarding.site_task.analytics_slide.title",
      subTitle: "onboarding.site_task.analytics_slide.subTitle",
      icon: "site-task-analytics.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/9077115-site-diary-site-task-dashboards"
    },
    {
      title: "onboarding.site_task.roles_slide.title",
      subTitle: "onboarding.site_task.roles_slide.subTitle",
      icon: "site-task-roles.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8898648-site-diary-site-task-how-to-invite-the-team"
    },
    {
      title: "onboarding.site_task.entry_slide.title",
      subTitle: "onboarding.site_task.entry_slide.subTitle",
      icon: "site-task-entry.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/8907734-site-task-how-to-create-a-task-and-assign-it"
    },
    {
      title: "onboarding.site_task.view_slide.title",
      subTitle: "onboarding.site_task.view_slide.subTitle",
      icon: "site-task-timeline-view.svg",
      userGuideLink: "https://intercom.help/scriptgo/en/articles/9084080-site-task-view-manage-tasks-in-list-or-timeline-view"
    }
  ];

  openUserGuide(): void {
    window.open(this.onboardingDataObject[this.screenNumber].userGuideLink, '_blank');
  }

  nextScreen(): void {
    if (this.screenNumber < this.onBoardingSiteDiary.length) {
      this.screenNumber++;
    }
  }

  previousScreen(): void {
    if (this.screenNumber !== 0) {
      this.screenNumber--;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
