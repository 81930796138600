import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatus } from '@constants/http/http-status';
import { IMPORT_FILE_ERRORS } from '@models/errors/import-file/import-file-errors';
import { NGXLogger } from 'ngx-logger';
import { Logger } from './logger';
import { SharedDataService } from './shared-data.service';
import { SpinnerService } from './spinner.service';
import { ToasterService } from './toaster.service';
import { UrlGiverService } from './url-giver.service';
import { mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { AppTypeService } from './app-type.service';

interface ImportType {
  frontendImportType: FRONTEND_IMPORT_TYPE;
  backendImportType: BACKEND_IMPORT_TYPE;
  fileName: FRONTEND_TEMPLATE_FILENAME;
}

export enum FRONTEND_IMPORT_TYPE {
  PEOPLE = 'labours',
  EQUIPMENT = 'plants',
  MATERIAL = 'materials',
  TAG = 'tags',
  CONTRACTOR = 'contractors',
  LOCATION = 'locations',
  TASK = 'tasks',
}

export enum BACKEND_IMPORT_TYPE {
  PEOPLE = 'labour',
  EQUIPMENT = 'equipment',
  MATERIAL = 'material',
  TAG = 'tag',
  CONTRACTOR = 'contractor',
  LOCATION = 'location',
  TASK = 'task',
}

export enum FRONTEND_TEMPLATE_FILENAME {
  PEOPLE = 'import.template-file.people.title',
  EQUIPMENT = 'import.template-file.equipments.title',
  MATERIAL = 'import.template-file.materials.title',
  TAG = 'import.template-file.tags.title',
  CONTRACTOR = 'import.template-file.contractors.title',
  LOCATION = 'import.template-file.locations.title',
  TASK = 'import.template-file.tasks.title',
}

enum FRONTEND_TASK_IMPORT_ERRORS {
  DATE_MISMATCH = "DATE_MISMATCH",
  FIELD_REQUIRED = "FIELD_REQUIRED",
  INVALID_FORMAT = "INVALID_FORMAT",
}

/**
 * This is a general service used for all importing features.
 */
@Injectable({
  providedIn: 'root'
})
export class ImportService {

  private readonly EXCEL_XLS_MIMETYPE = 'application/vnd.ms-excel';
  private readonly EXCEL_XLSX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  public static importTypes: ImportType[] = [
    { frontendImportType: FRONTEND_IMPORT_TYPE.PEOPLE, backendImportType: BACKEND_IMPORT_TYPE.PEOPLE, fileName: FRONTEND_TEMPLATE_FILENAME.PEOPLE },
    { frontendImportType: FRONTEND_IMPORT_TYPE.EQUIPMENT, backendImportType: BACKEND_IMPORT_TYPE.EQUIPMENT, fileName: FRONTEND_TEMPLATE_FILENAME.EQUIPMENT },
    { frontendImportType: FRONTEND_IMPORT_TYPE.MATERIAL, backendImportType: BACKEND_IMPORT_TYPE.MATERIAL, fileName: FRONTEND_TEMPLATE_FILENAME.MATERIAL },
    { frontendImportType: FRONTEND_IMPORT_TYPE.CONTRACTOR, backendImportType: BACKEND_IMPORT_TYPE.CONTRACTOR, fileName: FRONTEND_TEMPLATE_FILENAME.CONTRACTOR },
    { frontendImportType: FRONTEND_IMPORT_TYPE.TAG, backendImportType: BACKEND_IMPORT_TYPE.TAG, fileName: FRONTEND_TEMPLATE_FILENAME.TAG },
    { frontendImportType: FRONTEND_IMPORT_TYPE.LOCATION, backendImportType: BACKEND_IMPORT_TYPE.LOCATION, fileName: FRONTEND_TEMPLATE_FILENAME.LOCATION },
    { frontendImportType: FRONTEND_IMPORT_TYPE.TASK, backendImportType: BACKEND_IMPORT_TYPE.TASK, fileName: FRONTEND_TEMPLATE_FILENAME.TASK },
  ];

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private sharedDataService: SharedDataService,
    private urlGiverService: UrlGiverService,
    private spinnerService: SpinnerService,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private alertController: AlertController,
    private appTypeService: AppTypeService,
  ) { }

  // Check file format is xls or xlsx
  private isExcelFile(type: string): boolean {
    return type === this.EXCEL_XLSX_MIMETYPE || type === this.EXCEL_XLS_MIMETYPE;
  }

  private async uploadExcel(file: File[], dateFormat?: string, isTask: boolean = false): Promise<void> {
    this.spinnerService.activate('rotating');
    const url = this.urlGiverService.giveImportAPIUrl(this.sharedDataService.currentSpaceId, this.sharedDataService.currentSiteId);
    const blob = new Blob(file, {type: this.EXCEL_XLS_MIMETYPE});
    const formData = new FormData();
    formData.append('tasksExcel', blob, 'tasksExcel');
    if (isTask) {
      formData.append('dateFormat', dateFormat);
      formData.append('timeFormat', 'HH:mm');
    }
    return this.http.post(
      url + `/tasks/uploadTaskSheet`,
      formData,
      ).toPromise()
        .then(() => {
          this.spinnerService.deactivate();
          this.toasterService.showSuccessToaster('tasks.import.excel.success.upload');
        })
        .catch(error => {
          this.spinnerService.deactivate();
          Logger.error(error);
          this.showUploadExcelErrorToaster(error);
        });
  }

  private async uploadResourceExcel(file: File[], categoryType: string, siteId: string = null): Promise<void> {
    this.spinnerService.activate('rotating');
    const url = this.urlGiverService.giveResourceOrInformationImportAPIUrl(this.sharedDataService.currentSpaceId);
    const blob = new Blob(file, {type: this.EXCEL_XLS_MIMETYPE});
    const formData = new FormData();
    formData.append('importFile', blob, 'importFile');
    let importType = ImportService.importTypes.find((type) => type.frontendImportType === categoryType);
    let params = new HttpParams()
      .set('type', importType.backendImportType);
    if(siteId) {
      params = params.set('siteId', siteId);
    }
    return this.http.post(
      url,
      formData,
      {
        params
      }
      ).toPromise()
        .then(() => {
          this.toasterService.showSuccessToaster('import.import-file.success.message');
          this.spinnerService.deactivate();
        })
        .catch((error) => {
          let serverError = error.error;
          this.logger.error('Error while importing resources or information by excel', error);
          this.spinnerService.deactivate();
          if (serverError.status === HttpStatus.BAD_REQUEST) {
            if(serverError.errorCode === IMPORT_FILE_ERRORS.DUPLICATE_NAME_FOUND) {
              this.toasterService.showErrorToaster('import.import-file.duplicate-names.error.message');
            } else if(serverError.errorCode === IMPORT_FILE_ERRORS.FAILED_TO_READ_EXCEL_FILE) {
              this.toasterService.showErrorToaster('import.import-file.unsupported-file.error.message');
            } else if(serverError.errorCode === IMPORT_FILE_ERRORS.SITE_ID_NULL_FOR_LOCATION) {
              this.toasterService.showErrorToaster('import.import-file.error.message');
            } else if(serverError.errorCode === IMPORT_FILE_ERRORS.UNSUPPORTED_IMPORT_TYPE) {
              this.toasterService.showErrorToaster('import.import-file.error.message');
            } else if(serverError.errorCode === IMPORT_FILE_ERRORS.CONFLICTING_OBJECT_FOUND) {
              let conflictingItems = serverError.conflictingItems;
              let okayButtonCss = this.appTypeService.isInAdministrationPage() ? 'is-administration-page' : this.appTypeService.getCurrentAppType();
              // alert users about conflicting items
              this.translateService.get([
                'import.import-file.record_exists_error_message',
                'import.import-file.change_records_and_try_again',
                'import.import-file.existing_records_are',
                'Okay',
              ]).pipe(
                mergeMap(async translations => {
                  const alert = await this.alertController.create({
                    header: translations['import.import-file.record_exists_error_message'],
                    subHeader: translations['import.import-file.change_records_and_try_again'],
                    message: translations['import.import-file.existing_records_are'],
                    mode: 'ios',
                    cssClass: 'import-resources-duplicate-items-error-alert',
                    buttons: [
                      {
                        text: translations['Okay'],
                        cssClass: okayButtonCss
                      },
                    ],
                    inputs: conflictingItems.map(item => ({
                      type: 'text',
                      label: item,
                      value: item,
                      disabled: true,
                      name: item,
                    }))
                  });
                  return await alert.present();
                })
              ).toPromise();
            } else {
              this.toasterService.showErrorToaster('import.import-file.error.message');
            }
          } else {
            this.toasterService.showErrorToaster('import.import-file.error.message');
          }
        });
  }

  public importExcel(file: File[], dateFormat?: string, isTask: boolean = false): void {
    if (this.isExcelFile(file[0].type)) {
      this.uploadExcel(file, dateFormat, isTask);
    }
    else {
      this.toasterService.showErrorToaster('tasks.import.excel.error.type.description');
    }
  }

  public importResource(file: File[], categoryType: string, siteId: string): void {
    if (this.isExcelFile(file[0].type)) {
      this.uploadResourceExcel(file, categoryType, siteId);
    }
    else {
      this.toasterService.showErrorToaster('import.excel.error.type.description');
    }
  }

  private showUploadExcelErrorToaster(error: HttpErrorResponse): void {
    if (error.error && error.error.errorCode && error.error.message) {
      if (error.error.errorCode === FRONTEND_TASK_IMPORT_ERRORS.DATE_MISMATCH) {
        // ERROR MESSAGE EG.:  "End date cannot be before start date. Task title in sheet: 'Wall Paint' at row number: 1"
        const splitString = error.error.message.split(':');
        if (splitString.length > 1) {
          const row = splitString[splitString.length - 1].trim();
          const taskTitle = splitString[1].split('\'')[1];
          this.toasterService.showErrorToaster('tasks.import.excel.error.upload.date.mismatch', { title: taskTitle, row: row });
        } else {
          this.toasterService.showErrorToaster('tasks.import.excel.error.upload');
        }
      } else if (error.error.errorCode === FRONTEND_TASK_IMPORT_ERRORS.INVALID_FORMAT) {
        if (error.error.message.includes('Unparseable date:')) {
          // ERROR MESSAGE EG.: "Failed to parse data in file at row: 1.\nUnparseable date: \"30/30/2023 01:00\""
          const splitString = error.error.message.split(':');
          if (splitString.length > 1) {
            const row = splitString[1].trim().split('.')[0];
            const date = splitString[2].trim().slice(-13, -3);
            this.toasterService.showErrorToaster('tasks.import.excel.error.upload.invalid.date.format', { date: date, row: row });
          } else {
            this.toasterService.showErrorToaster('tasks.import.excel.error.upload.invalid.format');
          }
        } else {
          // ERROR MESSAGE EG.: "Failed to read Excel file. Incompatible date or time format."
          this.toasterService.showErrorToaster('tasks.import.excel.error.upload.invalid.format');
        }
      } else if (error.error.errorCode === FRONTEND_TASK_IMPORT_ERRORS.FIELD_REQUIRED) {
        // ERROR MESSAGE EG.: "Empty title cell at row: 1"
        const splitString = error.error.message.split(':');
        const row = splitString[splitString.length - 1].trim();
        this.toasterService.showErrorToaster('tasks.import.excel.error.upload.missing.title', { row: row });
      } else {
        this.toasterService.showErrorToaster('tasks.import.excel.error.upload');
      }
    } else {
      this.toasterService.showErrorToaster('tasks.import.excel.error.upload');
    }
  }
}
