export class SyncException {
    exception: any;
    exceptionType: SyncExceptionType;

    constructor(exceptionType: SyncExceptionType, exception: any) {
        this.exceptionType = exceptionType;
        this.exception = exception;
    }
}

export enum SyncExceptionType {
  APPROVED_EVENT_MUTATION,
}
