export const customFormLegacyFormLayout = {
  payload: {  
    id: "afce2730-c6b9-4617-a073-e9fa82c72f1b",
    revision: '',
    title: "Legacy Form",
    active: true,
    selected: true,
    configuration: {
      customFields: [
        {
          id:"CONTRACTOR",
          title:"Contractor",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"LOCATION",
          title:"Location",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"PHOTOS",
          title:"Add photos",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"TAGS",
          title:"Tags",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"PEOPLE",
          title:"People",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"EQUIPMENT",
          title:"Equipment",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"MATERIALS",
          title:"Materials",
          fieldType:"Legacy",
          isVisible:true
        },
        {
          id:"NOTES",
          title:"Note/Comment",
          fieldType:"Legacy",
          isVisible:true,
        }
      ],
      formLayout: [
        {
          sectionTitle:"Activities information",
          sectionFields:["CONTRACTOR","LOCATION","PHOTOS","TAGS"],
          isVisible:true
        },
        {	sectionTitle:"Resources",
          sectionFields:["PEOPLE","EQUIPMENT","MATERIALS"],
          isVisible:true
        },
        {
          sectionTitle:"Notes",
          sectionFields:["NOTES"],
          isVisible:true
        }
      ]
    },
    siteId: '',
  }
}

