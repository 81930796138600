import { RxStompConfig } from '@stomp/rx-stomp';

import { environment } from '../environments/environment';
import { Logger } from './services/logger';

const HEARTBEAT_RATE_MS = 45000;

export const sdRxStompConfig: RxStompConfig = {
  // Which server ? used environment variable to get server url
  brokerURL: environment.wsUrl,

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'guest',
    passcode: 'guest',
    Authorization: 'Bearer token',
    server: 'Site Diary'
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: HEARTBEAT_RATE_MS, // Typical value 0 - disabled
  heartbeatOutgoing: HEARTBEAT_RATE_MS, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 10000,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    // Uncomment me if you need websockets logs
    // Logger.debug('[STOMP_DEBUG]', msg);
  }
};
