import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToasterService } from '@services/toaster.service';
import { Logger } from '@services/logger';
import { HttpStatus } from '@constants/http/http-status';
import { NGXLogger } from 'ngx-logger';
import { SERVER_ERROR_MESSAGES } from '@models/error-messages';
import { LogoutService } from '@services/logout/logout.service';

@Injectable()
export class PermissionNotAllowedInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToasterService,
    private logger: NGXLogger,
    private logoutService: LogoutService,
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request)
    .pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if(error.status === HttpStatus.FORBIDDEN && request.url.includes('/sync/latestSubset')) {
            // Logout user who are not part of a site.
            this.logoutService.disconnectServices();
          }
          if (error.status === HttpStatus.FORBIDDEN && !(error.error && error.error.title)) {
            if (error.error.message && error.error.message === SERVER_ERROR_MESSAGES.ENTITY_BEING_USED_ERROR) {}
            else {
              this.logger.error('Received PermissionNotAllowed exception', error);
              this.toasterService.showErrorToaster('sync.permission.error', {itemLabel : ''});
            }
          }
        },
      }),
    );
  }
}
