import { AfterViewChecked, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Directive } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Space } from '@models/space';
import { User } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { DateManipulationService } from '@services/date-manipulation.service';
import { DeviceService } from '@services/device.service';
import { ComponentCanDeactivate } from '@services/guards/pending-changes.guard';
import { Logger } from '@services/logger';
import { SessionService } from '@services/session.service';
import { SharedService } from '@services/shared/shared.service';
import { ToasterService } from '@services/toaster.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { Observable, Subscription } from 'rxjs';
import { filter, flatMap, takeUntil } from 'rxjs/operators';
import { Event } from '@models/event';
import { Task } from '@models/task';
import { AutoUnsubscribeComponent } from 'app/shared/components/subscriptions/auto-unsubscribe.component';
import { NGXLogger } from 'ngx-logger';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { SharedDataService } from '@services/shared-data.service';
import { ArchiveSitesService } from '@services/archive-sites.service';
import { FaultyEventService } from '@services/faulty-event-service';

@Directive()
export abstract class AbstractFormComponent<T> extends AutoUnsubscribeComponent implements OnInit, OnDestroy, AfterViewChecked, ComponentCanDeactivate {
  @Output() editForm = new EventEmitter<boolean>();

  protected abstract ERROR_TRANSLATE_KEYS_PREFIX: string;
  relevantSharedService: SharedService | SharedDataService;

  @Input() isFaultyEvent: boolean;

  private _itemId: string;
  @Input() set itemId(itemId: string) {
    if (this._itemId && !itemId) {
      this.onFormHidden();
    } else if (!this._itemId && itemId) {
      this.onFormShown();
    }

    this._itemId = itemId;

    this.showObsoleteItemWarning = false;
    this.invalidFormSubmitted = false;

    if (itemId === 'new' || itemId === 'creatingSubtask') {
      this.isNewItem = true;
      this.isEdited = true;
      this.item = this.createItem();
    } else if (itemId) {
      this.isNewItem = false;
      this.isEdited = false;
      this.item = null;
      this.fetchItem(itemId).then(
        item => {
          if (item === null || this.item === null || !this.formGroup.dirty) {
            this.item = item;
          } else {
            this.showObsoleteItemWarning = true;
          }
        },
        err => {
          this.logger.error('Failed to fetch item ', itemId, err);
        },
      );
    }
  }
  get itemId(): string {
    return this._itemId;
  }

  private _itemIdToDuplicate: string | null;
  @Input() set itemIdToDuplicate(itemIdToDuplicate: string | null) {
    this._itemIdToDuplicate = itemIdToDuplicate;
    if (itemIdToDuplicate) {
      this.item = null;
      this.duplicateItem(itemIdToDuplicate).then(item => {
        this.item = item;
        this.logger.info('Item duplicated successfully. Item:', item);
      }).catch(error => {
        this.logger.error('Failed to duplicate item ', itemIdToDuplicate, error);
      });
    }
  }
  get itemIdToDuplicate(): string | null {
    return this._itemIdToDuplicate;
  }

  private _item: T;
  set item(item: T) {
    this._item = item;
    this.formGroup.reset();
    this.setItem(item);
    this.formGroup.markAsPristine();
    if (this.relevantSharedService.hasSiteSelected) {
      this.canEdit = false;
      this.updateRights(this.sessionService.getCurrentUser(), item);
    }
  }
  get item(): T {
    return this._item;
  }

  private _isEdited = false;
  get isEdited(): boolean {
    return this._isEdited;
  }
  set isEdited(value: boolean) {
    this._isEdited = value;
    if (!value) {
      this.formGroup.markAsPristine();
    }
  }

  private isViewReady: boolean;
  isNewItem: boolean = false;
  public isDeletedItem: boolean = false;
  //canEdit is only used in event webapp form which is used both for preview and editing, should not be used neither in task nor in events mobile
  canEdit: boolean = false;
  showObsoleteItemWarning: boolean = false;
  invalidFormSubmitted: boolean = false;
  userHasAppAccess: boolean = false;
  isArchivedSite: boolean = false;

  abstract formGroup: FormGroup;

  protected constructor(
    protected sharedService: SharedService,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    protected toasterService: ToasterService,
    protected deviceService: DeviceService,
    protected alertController: AlertController,
    protected sessionService: SessionService,
    protected logger: NGXLogger,
    protected userAppAccessService: UserAppAccessService,
    protected sharedDataService: SharedDataService,
    protected archiveSitesService: ArchiveSitesService,
    protected faultyEventService: FaultyEventService
  ) {
    super();
    if (this.deviceService.isMobile) {
      this.relevantSharedService = this.sharedService;
    }
    else {
      this.relevantSharedService = this.sharedDataService;
    }
  }

  ngOnInit(): void {

    if (this.deviceService.isMobile) {
      const eventId = this.route.snapshot.paramMap.get('eventId');
      const taskId = this.route.snapshot.paramMap.get('taskId');
      const faultyEventId = this.route.snapshot.paramMap.get('faultyEventId');
      if (eventId) {
        this.itemId = eventId;
      } else if (faultyEventId) {
        this.itemId = faultyEventId;
      } else {
        this.itemId = taskId;
      }
    } else {
      this.route.params.pipe(
        filter(params =>  params.hasOwnProperty('eventId') || params.hasOwnProperty('taskId')),
        takeUntil(this.destroy),
      ).subscribe(params => {
        const eventId = params['eventId'];
        let taskId = params['taskId'];
        if(this.route.snapshot.queryParamMap.get('creatingSubtask')) {
          taskId = 'creatingSubtask';
        }
        this.itemId = eventId ? eventId : taskId;
      });
    }

    if (this.item) {
      this.updateRights(this.sessionService.getCurrentUser(), this.item);
    }

    this.fetchResources();
    this.userAppAccessService.watchCurrentAppAccess().subscribe((userAppAccess) => {
      this.userHasAppAccess = this.userAppAccessService.userHasCorrectAppAccess();
    });

    // watch if current site is archived
    this.sharedDataService.watchSite.subscribe((site) => {
      if(site) {
        this.isArchivedSite = site.isArchived;
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.editForm.emit(false);
  }

  ngAfterViewChecked(): void {
    this.isViewReady = true;
  }

  onFormShown(): void {
  }

  onFormHidden(): void {
  }

  public isDurationDisplayed(): boolean {
    return this.formGroup.value.startDatetime && this.formGroup.value.endDatetime
      && new Date(this.formGroup.value.startDatetime).getTime() < new Date(this.formGroup.value.endDatetime).getTime();
  }

  public getFormValidationStyles(controlsNameFilter: string[], errorsNameFilter: string[]): string {
    if (this.isViewReady && this.formGroup && this.formGroup.errors) {
      const errors = this.formGroup.errors;
      for (const error in errors) {
        if (errors[error] && (!errorsNameFilter || (errorsNameFilter && errorsNameFilter.includes(error)))) {
          let isAnyControlDirty = false;

          for (const controlName of controlsNameFilter) {
            if (this.formGroup.controls[controlName].dirty) { isAnyControlDirty = true; }
          }

          if (this.invalidFormSubmitted) {
            return 'is-invalid is-invalid-submitted';
          } else if (isAnyControlDirty) {
            return 'is-invalid';
          }
          else {
            return 'is-valid';
          }
        }
      }
    }
    return '';
  }

  public getValidationStyles(controlName: string): string {
    if (this.isViewReady && this.formGroup && this.formGroup.controls[controlName]) {
      if (this.invalidFormSubmitted && this.formGroup.controls[controlName].invalid) {
        return 'is-invalid is-invalid-submitted';
      } else if (this.formGroup.controls[controlName].dirty) {
        if (this.formGroup.controls[controlName].invalid) {
          return 'is-invalid';
        }
        if (this.formGroup.controls[controlName].valid) {
          return 'is-valid';
        }
      }
    }
    return '';
  }

  public getColorStyle(controlName: string): string {
    if (this.isViewReady && this.formGroup && this.formGroup.controls[controlName]) {
      if ((this.invalidFormSubmitted || this.formGroup.controls[controlName].dirty) && this.formGroup.controls[controlName].invalid) {
        return 'danger';
      }
      if (this.isEmpty(controlName)) {
        return 'medium';
      }
    }
    return 'primary';
  }

  private getControlErrorsText(controlName: string, errors: ValidationErrors): string | null {
    if (errors) {
      let errorText = '';
      for (const error in errors) {
        if (errors[error]) {
          this.translateService.get(`form.error.${this.ERROR_TRANSLATE_KEYS_PREFIX}.${controlName}.${error}`).subscribe(translation => {
            if (errorText !== '') {
              errorText += '\n';
            }
            errorText += translation;
          });
        }
      }
      return errorText;
    }
    return null;
  }

  public getControlErrors(controlName: string): string | null {
    if (this.isViewReady && this.formGroup) {
      const control = this.formGroup.controls[controlName];
      if (control && (control.dirty || this.invalidFormSubmitted) && control.invalid) {
        return this.getControlErrorsText(controlName, control.errors);
      }
    }
    return null;
  }

  private getFormErrorsText(errors: ValidationErrors, errorsNameFilter?: string[]): string | null {
    if (errors) {
      let errorText = '';
      for (const error in errors) {
        if (errors[error] && (!errorsNameFilter || (errorsNameFilter && errorsNameFilter.includes(error)))) {
          this.translateService.get(`form.error.${this.ERROR_TRANSLATE_KEYS_PREFIX}.${error}`).subscribe(translation => {
            if (errorText !== '') {
              errorText += '\n';
            }
            errorText += translation;
          });
        }
      }
      return errorText;
    }
    return null;
  }

  public getFormErrors(...errorsNameFilter: string[]) {
    if (this.isViewReady && this.formGroup) {
      return this.getFormErrorsText(this.formGroup.errors, errorsNameFilter);
    }
    return null;
  }

  public getFormAndControlsErrors() {
    if (this.isViewReady && this.formGroup) {
      let errorText = '';
      for (const controlName in this.formGroup.controls) {
        if (this.formGroup.controls[controlName]) {
          const controlErrors = this.getControlErrorsText(controlName, this.formGroup.controls[controlName].errors);
          if (controlErrors !== null) {
            errorText += (errorText === '') ? controlErrors : '\n' + controlErrors;
          }
        }
      }
      const formErrors = this.getFormErrorsText(this.formGroup.errors);
      if (formErrors !== null) {
        errorText += (errorText === '') ? formErrors : '\n' + formErrors;
      }
      return errorText;
    }
    return null;
  }

  public isEmpty(controlName: string) {
    if (this.isViewReady && this.formGroup) {
      const control = this.formGroup.controls[controlName];
      return !control || !control.value || control.value.length === 0;
    }
    return true;
  }

  public save(linkedItem?: T): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      if (this.formGroup.valid) {
        this.invalidFormSubmitted = false;
        this.isEdited = false;
        this.saveItem(this.isNewItem, this.item).then(item => {
          this.logger.info('Saved item: ', (item as unknown as Event|Task).id);
          if (linkedItem) {
            this.navigateToLinkedItem(linkedItem);
          } else if (this.isFaultyEvent) {
            this.faultyEventService.deleteFaultyEventFromDb(this.itemId);
            this.navigateToEventListFromFaultyList();
          } else {
            this.navigateToItem(item);
            this.editForm.emit(false);
          }
        }).catch(error => {
          this.logger.error('Failed to save item: ', this.item, error);
        });
      } else {
        this.invalidFormSubmitted = true;
        this.logger.error('Trying to submit an invalid form');
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  public cancel(): void {
    if (this.isNewItem || this.deviceService.isMobile) {
      this.getBack();
    } else {
      this.isEdited = false;
      this.itemId = this.itemId;
    }
  }

  public async getBack(): Promise<void> {
    let path = '..';
    const isDuplicatedItem: boolean = this.itemIdToDuplicate ? true : false;
    const isNewItem: boolean = this.itemId === 'new';
    const isDeletedItem: boolean = this.isDeletedItem ? true : false;
    if (isDuplicatedItem) {
      path += `/${this.itemIdToDuplicate}`;
    } else if (!isNewItem && !isDeletedItem) {
      path += `/${this.itemId}`;
    }
    this.router.navigate([path], { relativeTo: this.route });
    this.editForm.emit(false);
  }

  public navigateToNewItem(): void {
    this.router.navigate(['../new'], { relativeTo: this.route });
  }

  public navigateToDuplicatedItem(itemIdToDuplicate: string): void {
    this.router.navigate(['../new'], { relativeTo: this.route, fragment: 'id:' + itemIdToDuplicate });
  }

  public async delete() {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      this.translateService.get([
        'events.detail.delete.question',
        'btn.cancel',
        'btn.confirm_delete',
      ]).pipe(
        flatMap(async translations => {
          const alert = await this.alertController.create({
            header: translations['events.detail.delete.question'],
            buttons: [
              {
                text: translations['btn.cancel'],
                role: 'cancel',
                handler: () => {
                },
              },
              {
                text: translations['btn.confirm_delete'],
                cssClass: 'text-danger',
                handler: () => {
                  this.deleteItem(this.item).then(item => {
                    this.logger.info('Deleted item: ', item);
                    this.isDeletedItem = true;
                    this.getBack();
                  }).catch(error => {
                    this.logger.error('Failed to delete item: ', this.item, error);
                  });
                },
              },
            ],
          });
          return await alert.present();
        })
      ).toPromise();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  public duplicate(message: string): void {
    if(this.userHasAppAccess) {
      if(this.isArchivedSite) {
        this.archiveSitesService.showSiteArchivedModal();
        return;
      }
      // Show a toaster to explain to user that he is now on a copy of the event
      this.toasterService.showSuccessToaster(message);

      this.navigateToDuplicatedItem(this.itemId);
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
      return null;
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(this.item && this.isEdited && this.formGroup && this.formGroup.dirty);
  }

  /**
   * Round given date minutes to nearest 15 mins block.
   * Round down or up depending on closest target, example:
   *   - [0-7]   => 0
   *   - [8-14]  => 15
   *   - [15-22] => 15
   *   - [23-29] => 30
   * @param date given date
   * @return rounded date
   */
  protected getNearest15Mins(date: Date): number {
    const minutes = date.getMinutes();
    const multiplier = (minutes % 15) <= 7 ? Math.floor(minutes / 15) : Math.floor(minutes / 15) + 1;
    return multiplier * 15;
  }

  /**
   * Create a new item
   */
  protected abstract createItem(): T;

  protected abstract duplicateItem(itemId: string): Promise<T>;

  /**
   * Fetch the item with the specified id
   * @param itemId
   */
  protected abstract fetchItem(itemId: string): Promise<T>;

  /**
   * save the item
   * @param isNewItem
   * @param item
   */
  protected abstract saveItem(isNewItem: boolean, item: T): Promise<T>;

  /**
   * delete the item
   * @param item
   */
  protected abstract deleteItem(item: T): Promise<T>;

  /**
   * update the edition rights for the item
   * @param user The current user
   * @param item
   */
  protected abstract updateRights(user: User, item: T): void;

  /**
   * fetch the resources necessary to display the form, called when the site changes
   */
  protected abstract fetchResources(): void;

  /**
   * Called when the form is to be filled with the item's data
   * @param item
   */
  protected abstract setItem(item: T): void;

  /**
   * navigates to the item's form
   * @param item
   */
  protected abstract navigateToItem(item: T): Promise<boolean>;

  /**
   * navigates to the linked item in form
   * @param item
   */
  protected abstract navigateToLinkedItem(item: any): Promise<boolean>;

   /**
   * 
   * @param item
   */
    protected abstract navigateToEventListFromFaultyList(): Promise<boolean>;
}
