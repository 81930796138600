export enum ColorPalette {
  Black = '#000000',
  Red = '#FF0000',
  White ='#FFFFFF',
  Blue = '#0000FF',
  Lime = '#00FF00',
  Magenta = '#FF00FF',
  NavyBlue = '#000080',
  Orange = '#FFA500',
  Purple = '#800080',
  Yellow = '#FFFF00',
  DarkBlue = '#4c27ba',
  LightGreen = '#14A735'
}
