import { Injectable } from '@angular/core';
import { EditingTool } from '@models/enums/editing-tool';
import { Observable, Subject } from 'rxjs';

export interface IToolbarMessage {
  id?: string;
  tool: EditingTool;
  property: 'color' | 'size' | 'undo' | 'clear' | 'delete';
  value: string | number;
}

export class TextWidget {
  public id: string;
  public text: string;
  public color: string;
  public size: string;

  constructor(color: string,size: string) {
    const uid = Math.round(Math.random()*10000000);
    this.id = `id${uid}`;
    this.color = color;
    this.size = size;
    this.text = '';
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarMessageService {

  // Used for sending toolbar changes from toolbar to canvas
  private toolbarMessagesSource: Subject<IToolbarMessage> =
    new Subject<IToolbarMessage>();

  // Used for sending active text widget status to other components
  private activeWidgetMessageSource: Subject<TextWidget | null> =
    new Subject<TextWidget | null>();

  public toolbarMessages: Observable<IToolbarMessage> =
    this.toolbarMessagesSource.asObservable();

  public activeWidget: Observable<TextWidget | null> =
    this.activeWidgetMessageSource.asObservable();

  constructor() { }

  public broadcastMessage(message: IToolbarMessage): void {
    this.toolbarMessagesSource.next(message);
  }

  public broadcastActiveWidgetMessage(message: TextWidget | null): void {
    this.activeWidgetMessageSource.next(message);
  }
}
