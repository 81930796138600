import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomEventField } from '@models/custom-event-field';

@Component({
  selector: 'app-custom-form-multiple-choice-field',
  templateUrl: './custom-form-multiple-choice-field.component.html',
  styleUrls: ['./custom-form-multiple-choice-field.component.scss'],
})
export class CustomFormMultipleChoiceFieldComponent implements OnInit {
  @Input() customField: CustomEventField;
  @Input() parentFormGroup: FormGroup;
  @Input() isEdited: boolean;
  @Input() deviceIsMobile: boolean;
  @Input() isEventPreviewScreen: boolean;

  multipleChoiceOptions: string[] = [];

  constructor() { }

  ngOnInit() {
    this.customField.children?.options?.map((option) => {
      if (option.value !== "") {
        this.multipleChoiceOptions.push(option)
      }
    })
  }
}
