import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DeviceService } from '@services/device.service';
import { TranslateService } from '@ngx-translate/core';

type SuccessCallback = (title: string) => void;
type DismissCallback = () => void;

@Component({
  selector: 'app-event-approve-reject-configuration-popup',
  templateUrl: './event-approve-reject-configuration-popup.component.html',
  styleUrls: ['./event-approve-reject-configuration-popup.component.sass']
})
export class EventApproveRejectConfigurationPopupComponent implements OnInit {

  public isApprovalRequest: boolean = false;
  public isRejectionRequest: boolean = false;

  @Input() private successCallback: SuccessCallback;
  @Input() private dismissCallback: DismissCallback;

  public formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public deviceService: DeviceService,
    protected translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      title: [''],
    });
  }

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  save(domEvent?: Event): void {
    if (domEvent) {
      domEvent.preventDefault();
    }
    const title = this.formGroup.controls['title'].value as string;
    this.successCallback(title);
  }

}
