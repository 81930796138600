<div class="w-100 wrapper">
  <ion-item lines="full">
    <ion-searchbar
      #searchInput
      (ionInput)="search($event.target.value)"
      class="no-shadow"
      placeholder="{{'btn.search' | translate}}"
      animated>
    </ion-searchbar>
  </ion-item>
  <ng-container *ngIf="selectableItemList | filterListByLabel:itemFilter as filteredList">
    <ion-item lines="none" class="list-item ml-2" *ngFor="let item of filteredList">
      <ion-label class="task-number" *ngIf="item.item.taskNumber">{{item.item | taskNumber}}</ion-label>
      <ion-label>{{ item.label }}</ion-label>
      <ion-checkbox
        [checked]="item.selected"
        (ionChange)="toggleItem(item)"
        slot="start">
      </ion-checkbox>
    </ion-item>
    <ion-item lines="none" class="ml-2" *ngIf="filteredList.length === 0">
      <ion-label>{{ 'plugins.datatables.zero_records' | translate }}</ion-label>
    </ion-item>
  </ng-container>
</div>
