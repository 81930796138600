import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToasterService } from '@services/toaster.service';
import { Logger } from '@services/logger';
import { HttpStatus } from '@constants/http/http-status';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class FeatureNotAvailableInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToasterService,
    private logger: NGXLogger
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request)
    .pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatus.PAYMENT_REQUIRED) {
            this.logger.error('Received unexpected FeatureNotAvailable exception', error);
            this.toasterService.showErrorToaster('payment.featurenotavailable');
          }
        },
      }),
    );
  }
}
