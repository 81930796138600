import { Component, Output, EventEmitter } from '@angular/core';
import { AbstractButtonComponent } from '../abstract-button';


@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent extends AbstractButtonComponent {

}
