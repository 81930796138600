import { Input, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Author } from '@models/author';
import { Contractor } from '@models/contractor';
import { CustomEventField, CustomEventFieldTypeValue, CustomEventLegacyFieldValue } from '@models/custom-event-field';
import { CustomEventForm } from '@models/custom-event-form';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { Event, EventStatus } from '@models/event';
import { EventAsset } from '@models/event-asset';
import { EventContractor } from '@models/event-contractor';
import { EventTag } from '@models/event-tag';
import { EventTaskAdapter } from '@models/event-task-adapter';
import { Location } from '@models/location';
import { NetworkStatus } from '@models/synchronization/network-status';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { User } from '@models/user';
import { Weather } from '@models/weather';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '@services/asset.service';
import { AttachmentService } from '@services/attachment.service';
import { BackupService, BACKUP_TYPES } from '@services/backup/backup.service';
import { ContractorService } from '@services/contractor.service';
import { CustomEventFormService, CustomFormSupportDialogType } from '@services/custom-event-form.service';
import { DeviceService } from '@services/device.service';
import { EventEmailService } from '@services/emails/event-email.service';
import { EventService } from '@services/event.service';
import { ExportService } from '@services/export.service';
import { FileTransfertService } from '@services/file-transfert.service';
import { IntercomService } from '@services/intercom.service';
import { LocationService } from '@services/location.service';
import { SessionService } from '@services/session.service';
import { Coordinates, CoordinatesService } from '@services/shared/coordinates.service';
import { DatabaseService } from '@services/shared/database.service';
import { SharedService } from '@services/shared/shared.service';
import { SiteService } from '@services/site.service';
import { SpinnerService } from '@services/spinner.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { UserAppAccessService } from '@services/user-app-access/user-app-access.service';
import { UserRightsService } from '@services/user-rights/user-rights.service';
import { WeatherCodeDetails, WeatherService } from '@services/weather.service';
import { eventNotInTheFutureValidator } from '@validators/event-not-in-the-future.validator';
import { numberNotPositiveIntegerValidator } from '@validators/number-not-positive-integer.validator';
import { maxDurationValidator } from '@validators/max-duration.validator';
import { notBlankValidator } from '@validators/not-blank.validator';
import { positiveDurationValidator } from '@validators/positive-duration.validator';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { flatMap, takeUntil } from 'rxjs/operators';
import { AbstractFormComponent } from '../../../../form/abstract-form.component';
import { ImagesInputComponent } from '../../../../form/controls/shared/images-input/images-input.component';
import { EventApproveRejectConfigurationPopupComponent } from '../event-list/event-approve-reject-configuration-popup/event-approve-reject-configuration-popup.component';
import { PdfExportConfigurationPopupComponent } from '../event-list/pdf-export-configuration-popup/pdf-export-configuration-popup.component';
import { EventPhotosComponent } from './custom-event-form-components/event-photos/event-photos.component';
import { SharedDataService } from '@services/shared-data.service';
import { ArchiveSitesService } from '@services/archive-sites.service';
import { materialAssetNotPositiveNumberValidator } from '@validators/material-asset-not-positive-number.validator';
import { ActionType } from '@models/synchronization/sync-dto';
import { FaultyEventService } from '@services/faulty-event-service';
import { LocalNotificationPendingList, LocalNotifications } from '@capacitor/local-notifications';
import { PendingAttachmentService } from '@services/pending-attachment.service';

@Directive()
export abstract class EventFormComponent extends AbstractFormComponent<Event> implements OnInit, OnDestroy {
  public canClearEndDatetime: boolean = true;
  protected ERROR_TRANSLATE_KEYS_PREFIX: string = 'event-and-task';

  createdFromTask: boolean;
  formGroup: FormGroup;
  coordinates: Coordinates;
  today: string;
  isPreviewingWeather: boolean;
  defaultEndDate;
  weatherCodeDetails: WeatherCodeDetails = null;
  isWeatherPendingProp: boolean = false;
  statusToSave: EventStatus = EventStatus.DRAFT;
  canDelete: boolean = false;
  taskPlannedQuantity: number = null;
  taskTotalQuantityDone: number = null;
  weatherApiInvalidResponse: boolean = false;
  isWeatherDataLoading: boolean = false;
  isCustomFormUpdateRequired: boolean = false;

  currentCustomEventForm: CustomEventForm = null;
  isCustomEventFormNotAvailable: boolean = false;
  public legacyFieldStringValue = CustomEventField.CUSTOMEVENTFIELDTYPE[2].value;
  legacyFieldType = {
    contractor: CustomEventLegacyFieldValue.CONTRACTORS,
    location: CustomEventLegacyFieldValue.LOCATION,
    photos: CustomEventLegacyFieldValue.PHOTOS,
    tags: CustomEventLegacyFieldValue.TAGS,
    people: CustomEventLegacyFieldValue.PEOPLE,
    equipment: CustomEventLegacyFieldValue.EQUIPMENT,
    materials: CustomEventLegacyFieldValue.MATERIALS,
    notes: CustomEventLegacyFieldValue.NOTES,
  }

  readonly EventStatus = EventStatus;

  @ViewChild('imagesInputComponent')
  public imagesInputComponent: ImagesInputComponent;

  @ViewChild('eventPhotosComponent')
  public eventPhotosComponent: EventPhotosComponent;

  //#region Task to link
  private _idTaskToLink: string | null;

  @Input()
  set taskToLink(idTaskToLink: string | null) {
    this._idTaskToLink = idTaskToLink;
    const params = this.route.snapshot.params;
    const eventId = params['eventId'];
    this.itemId = eventId;
    if (idTaskToLink && this.isNewItem) {
      this.createdFromTask = true;
      this.taskService.getOnceById(idTaskToLink).then(t => {
        // copy of all assets with amount = 0
        t.assets.forEach(asset => {
          asset.amount = 0;
          asset.duration = 0;
          this.event.assets.push(asset);
        });
        // copy of contractors, tags, and task data
        this.event.contractors = t.contractors;
        this.event.tags = t.tags;
        this.event.task.progress = t.lastKnownProgress;
        this.event.task.suspended = t.suspended;
        this.event.task.taskId = t.id;

        // if quantity is present in task update taskPlannedQuantity
        if(t.plannedQuantity !== null && t.plannedQuantity !== undefined) {
          this.event.task.quantityDone = 0;
          this.taskPlannedQuantity = t.plannedQuantity;
        }
      });
    }
  }

  get taskToLink(): string | null {
    return this._idTaskToLink;
  }
  //#endregion

  //#region Event
  get event(): Event {
    return this.item;
  }

  set event(event: Event) {
    this.event = event;
    this.isWeatherPendingProp = this.isWeatherPending();
  }

  canApprove = false;
  get isApproved(): boolean {
    return this.event && this.event.status === EventStatus.APPROVED;
  }

  canReject = false;
  get isRejected(): boolean {
    return this.event && this.event.status === EventStatus.REJECTED;
  }

  //#endregion

  //#region Resources loaded booleans
  tasksLoaded = false;
  tagsLoaded = false;
  laboursLoaded = false;
  contractorsLoaded = false;
  plantsLoaded = false;
  materialsLoaded = false;
  locationsLoaded = false;
  //#endregion

  //#region Tasks
  _tasks: EventTaskAdapter[] = [];

  get tasks(): EventTaskAdapter[] {
    return this._tasks;
  }
  set tasks(tasks: EventTaskAdapter[]) {
    this._tasks = tasks;
    if (this.event) {
      this.updateSelectedTasks(tasks, this.event);
    }
  }
  //#endregion

  _tags: Tag[] = [];
  get tags(): Tag[] {
    return this._tags.sort(Tag.compareByName);
  }
  set tags(tags: Tag[]) {
    this._tags = tags.sort(Tag.compareByName);
    this.updateSelectedTagOrContractor(
      this.formGroup.controls['tags'],
      tags,
      this.event ? this.event.tags : null
    );
  }

  _contractors: Contractor[] = [];
  get contractors(): Contractor[] {
    return this._contractors.sort(Contractor.compareByName);
  }
  set contractors(contractors: Contractor[]) {
    this._contractors = contractors.sort(Contractor.compareByName);
    this.updateSelectedTagOrContractor(
      this.formGroup.controls['contractors'],
      contractors,
      this.event ? this.event.contractors : null
    );
  }

  _labours: EventAsset[] = [];
  get labours(): EventAsset[] {
    return this._labours;
  }
  set labours(labours: EventAsset[]) {
    this._labours = labours;
    this.updateSelectedAsset(this.formGroup.controls['labours'], labours);
  }

  _plants: EventAsset[] = [];
  get plants(): EventAsset[] {
    return this._plants.sort(EventAsset.compareByName);
  }
  set plants(plants: EventAsset[]) {
    this._plants = plants.sort(EventAsset.compareByName);
    this.updateSelectedAsset(this.formGroup.controls['plants'], plants);
  }

  _materials: EventAsset[] = [];
  get materials(): EventAsset[] {
    return this._materials.sort(EventAsset.compareByName);
  }
  set materials(materials: EventAsset[]) {
    this._materials = materials.sort(EventAsset.compareByName);
    this.updateSelectedAsset(this.formGroup.controls['materials'], materials);
  }

  _locations: Location[] = [];
  get locations(): Location[] {
    return this._locations.sort(Location.compareByName);
  }
  set locations(locations: Location[]) {
    this._locations = locations.sort(Location.compareByName);
    this.updateSelectedLocation(
      this.formGroup.controls['location'],
      locations,
      this.event ? this.event.locationObject : null
    );
  }

  private updateSelectedLocation(
    control: AbstractControl,
    itemList: Location[],
    eventItem: Location
  ): void {
    let newValue: Location;
    if (control.dirty) {
      newValue = itemList.find(item =>
        control.value.find(selectedItem => item.id === selectedItem.id)
      );
    } else if (this.event) {
      newValue = itemList.find(item =>
        item.id === eventItem.id
      );
    }
    control.setValue(newValue);
  }

  private updateSelectedTagOrContractor(
    control: AbstractControl,
    itemList: (Contractor | Tag)[],
    eventItems: (EventContractor | EventTag)[]
  ): void {
    let newValue = [];
    if (control.dirty) {
      newValue = itemList.filter(item =>
        control.value.some(selectedItem => item.id === selectedItem.id)
      );
    } else if (this.event) {
      newValue = itemList.filter(item =>
        eventItems.some((eventItem: any) =>
          item.id === (eventItem.contractorId ? eventItem.contractorId : eventItem.tagId)
        )
      );
    }
    control.setValue(newValue);
  }

  // By design, event assets initally contain only their ID. This completes the
  // asset object with the name attribute
  private completeAssetName(asset: EventAsset, assetList: EventAsset[]): EventAsset {
    const referenceAsset = assetList.find(reference => reference.assetId === asset.assetId);
    if (referenceAsset) {
      asset.name = referenceAsset.name;
      return asset;
    }
    return null;
  }

  private updateSelectedAsset(control: AbstractControl, assetList: EventAsset[]): void {
    let assets = [];
    if (control.dirty) {
      assets = control.value;
    } else if (this.event) {
      assets = this.event.assets;
    }
    control.setValue(
      assets
        .map(selectedEventAsset => this.completeAssetName(selectedEventAsset, assetList))
        .filter(selectedEventAsset => selectedEventAsset)
    );
  }

  get isOnline(): boolean {
    return NetworkStatus.isOnline;
  }

  get customFields() {
    return this.formGroup.controls["customFields"] as FormArray;
  }

  // Subject used to unsubscribe the isTabletInLandscape subscription in event-form-mobile-component
  protected unsubscribeIsTabletInLandscapeSubscription: Subject<void> = new Subject();
  relevantSharedService: SharedService | SharedDataService;

  protected constructor(
    protected sharedService: SharedService,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    protected toasterService: ToasterService,
    protected deviceService: DeviceService,
    protected alertController: AlertController,
    protected sessionService: SessionService,
    protected userRightsService: UserRightsService,
    protected eventService: EventService,
    protected contractorService: ContractorService,
    protected siteService: SiteService,
    protected urlGiver: UrlGiverService,
    protected fileTransfertService: FileTransfertService,
    protected tagService: TagService,
    protected assetService: AssetService,
    protected locationService: LocationService,
    protected coordinatesService: CoordinatesService,
    protected taskService: TaskService,
    protected attachmentService: AttachmentService,
    protected weatherService: WeatherService,
    protected eventEmailService: EventEmailService,
    protected intercomService: IntercomService,
    protected formBuilder: FormBuilder,
    protected backupService: BackupService,
    protected databaseService: DatabaseService,
    protected exportService: ExportService,
    protected modalService: NgbModal,
    protected spinnerService: SpinnerService,
    protected logger: NGXLogger,
    protected customEventFormService: CustomEventFormService,
    protected userAppAccessService: UserAppAccessService,
    protected sharedDataService: SharedDataService,
    protected archiveSitesService: ArchiveSitesService,
    protected faultyEventService: FaultyEventService,
    protected pendingAttachmentService: PendingAttachmentService
  ) {
    super(
      sharedService,
      route,
      translateService,
      router,
      toasterService,
      deviceService,
      alertController,
      sessionService,
      logger,
      userAppAccessService,
      sharedDataService,
      archiveSitesService,
      faultyEventService
    );
    this.coordinates = new Coordinates();
    this.formGroup = this.formBuilder.group({
      'title': ['', [Validators.required, Validators.maxLength(255), Validators.minLength(3), notBlankValidator]],
      'startDatetime': [null, [Validators.required, eventNotInTheFutureValidator]],
      'endDatetime': [null],
      'location': [],
      'contractors': [[]],
      'eventTaskAdapter': [],
      'tags': [[]],
      'plants': [[],[numberNotPositiveIntegerValidator]],
      'materials': [[],[materialAssetNotPositiveNumberValidator]],
      'labours': [[],[numberNotPositiveIntegerValidator]],
      'description': [''],
      'approvalComment': [''],
      'attachments': [[]],
      'quantityDone': [],
      'quantityUnit': [],
      'customFields': this.formBuilder.array([]),
    }, {
      validator: [maxDurationValidator, positiveDurationValidator],
    });
    this.isCustomEventFormNotAvailable = false;
    if(deviceService.isMobile) {
      this.relevantSharedService = this.sharedService;
    } else {
      this.relevantSharedService = this.sharedDataService;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.taskService.items.pipe(
      takeUntil(this.destroy)
    ).subscribe(tasks => {
      this.tasks = tasks.map(t => new EventTaskAdapter(t));
      this.tasksLoaded = true;
    });
    this.tagService.items.pipe(
      takeUntil(this.destroy)
    ).subscribe(tags => {
      this.tags = tags;
      this.tagsLoaded = true;
    });
    this.contractorService.items.pipe(
      takeUntil(this.destroy)
    ).subscribe(contractors => {
      this.contractors = contractors;
      this.contractorsLoaded = true;
    });
    this.assetService.labours.pipe(
      takeUntil(this.destroy)
    ).subscribe(labours => {
      this.labours = this.assetService.convertAssetsToNamedEventAssets(labours);
      this.laboursLoaded = true;
    });
    this.assetService.equipments.pipe(
      takeUntil(this.destroy)
    ).subscribe(plants => {
      this.plants = this.assetService.convertAssetsToNamedEventAssets(plants);
      this.plantsLoaded = true;
    });
    this.assetService.materials.pipe(
      takeUntil(this.destroy)
    ).subscribe(materials => {
      this.materials = this.assetService.convertAssetsToNamedEventAssets(materials);
      this.materialsLoaded = true;
    });
    this.locationService.items.pipe(
      takeUntil(this.destroy)
    ).subscribe(locations => {
      this.locations = locations;
      this.locationsLoaded = true;
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.deviceService.isTablet) {
      this.unsubscribeIsTabletInLandscapeSubscription.next();
      this.unsubscribeIsTabletInLandscapeSubscription.complete();
    }
  }

  onFormShown(): void {
    super.onFormShown();
    this.init();
  }

  onFormHidden(): void {
    super.onFormHidden();
  }

  async confirmLoadBackup() {
    this.translateService.get([
      'events.backup.question',
      'btn.cancel',
      'btn.confirm',
    ]).pipe(
      flatMap(async translations => {
        const alert = await this.alertController.create({
          /* header: 'coucou', */
          message: translations['events.backup.question'],
          buttons: [
            {
              text: translations['btn.cancel'],
              role: 'cancel',
              handler: () => {
                // Delete backup
                this.backupService.removeBackupItem(BACKUP_TYPES.EVENT);
              }
            },
            {
              text: translations['btn.confirm'],
              handler: () => {
                // Delete current site on confirm
                this.loadBackup();
              }
            }
          ]
        });
        await alert.present();
      })
    ).pipe(
      takeUntil(this.destroy)
    ).subscribe();
  }

  loadBackup() {
    const backupEvent = this.backupService.getBackupItem(BACKUP_TYPES.EVENT);
    for (let attachment of backupEvent.attachments) {
      if (attachment.url) {
        this.pendingAttachmentService.storedImages[attachment.id] = attachment.url
      }
    }
    this.formGroup.patchValue(backupEvent);
    this.formGroup.markAsDirty();
  }

  private init(): void {
    this.formGroup.controls['endDatetime'].valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      this.defaultEndDate = moment(this.formGroup.controls['startDatetime'].value).add(1, 'hours').toISOString(true);
      if (this.formGroup.controls['endDatetime'].dirty) {
        this.formGroup.controls['startDatetime'].updateValueAndValidity();
        this.formGroup.controls['startDatetime'].markAsDirty();
      }
    });

    this.formGroup.controls['startDatetime'].valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      if(this.formGroup.controls['endDatetime'].value) {
        this.previewWeather();
      }
    });
  }

  public onCoordinatesChanged(coordinates: Coordinates) {
    if (!this.event.weather || this.event.weather.longitude !== coordinates.lng || this.event.weather.latitude !== coordinates.lat) {
      this.event.weather = new Weather();
    }
    const weather = this.event.weather;
    weather.latitude = coordinates.lat;
    weather.longitude = coordinates.lng;
    if(this.event.startDatetime && this.event.endDatetime) {
      this.previewWeather();
    }
  }

  /**
   * Show weather details if weather is available
   * @param weather Weather to show
   */
  private showWeatherIfAvailable(weather: Weather): void {
    if (weather) {
      this.weatherCodeDetails = this.weatherService.getWeather(weather);
      this.isPreviewingWeather = true;
    }
  }

  protected setItem(event: Event): void {
    if (event) {
      this.formGroup.controls['labours'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.labours)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['plants'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.plants)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['materials'].setValue(event.assets.map(eventAsset =>
        this.completeAssetName(eventAsset, this.materials)
      ).filter(eventAsset => eventAsset));

      this.formGroup.controls['tags'].setValue(this.tags.filter(tag =>
        event.tags.some(eventTag => tag.id === eventTag.tagId))
      );

      this.formGroup.controls['contractors'].setValue(this.contractors.filter(contractor =>
        event.contractors.some(eventContractor => contractor.id === eventContractor.contractorId))
      );

      this.updateSelectedTasks(this.tasks, event);

      this.formGroup.controls['title'].setValue(event.title);
      if (event.endDatetime) {
        this.formGroup.controls['endDatetime'].setValue(new Date(event.endDatetime).toISOString());
      }

      // To calculate difference between startDate & endDate we need to set startDate seconds & milliseconds to 0
      const startDateTimeMoment = moment(event.startDatetime).seconds(0).milliseconds(0);
      this.formGroup.controls['startDatetime'].setValue(startDateTimeMoment.toISOString(true));

      if (event.locationObject) {
        this.formGroup.controls['location'].setValue(this.locations.find(location => location.id === event.locationObject.id));
      }
      this.formGroup.controls['description'].setValue(event.description);
      this.formGroup.controls['approvalComment'].setValue(event.approvalComment);
      this.formGroup.controls['attachments'].setValue(event.attachments);
      this.defaultEndDate = moment(this.formGroup.controls['startDatetime'].value).add(1, 'hours').toISOString(true);

      this.showWeatherIfAvailable(event.weather);
    }
  }

  private async setDefaultWeather(): Promise<Weather> {
    const weather = new Weather();
    const coordinates = await this.getSiteLocation();
    weather.latitude = coordinates.lat;
    weather.longitude = coordinates.lng;
    return weather;
  }

  private async getSiteLocation(): Promise<Coordinates> {
    try {
      return await this.coordinatesService.getLocationFromCurrentSite();
    } catch (ex) {
      return new Coordinates(null, null);
    }
  }


  protected createItem(): Event {
    const event = new Event();
    event.startDatetime.setMinutes(this.getNearest15Mins(event.startDatetime));
    this.setDefaultWeather().then(weather => {
      event.weather = weather;
      this.renewWeatherForNewEvent();
    });
    return event;
  }

  protected async duplicateItem(eventId: string): Promise<Event> {
    this.backupService.removeBackupItem(BACKUP_TYPES.EVENT);
    this.intercomService.trackUserAction(IntercomUserActions.DuplicateEvent);
    const event = await this.getEventFromId(eventId);
    this.fetchAndPopulateWithEventCustomFormData(event);
    return event.duplicate();
  }

  protected async fetchItem(eventId: string): Promise<Event> {
    this.isEdited = true;
    const event = await this.getEventFromId(eventId);
    this.intercomService.trackUserAction(IntercomUserActions.DisplayingEventPreview);
    this.fetchAndPopulateWithEventCustomFormData(event);
    event.assets = event.assets.map(eventAsset => Object.assign(new EventAsset(), eventAsset));
    event.contractors = event.contractors.map(eventContractor => Object.assign(new EventContractor(), eventContractor));
    event.tags = event.tags.map(eventTag => Object.assign(new EventTag(), eventTag));
    return event;
  }

  private async getEventFromId(eventId: string): Promise<Event> {
    let event: Event;
    if (this.route.snapshot.params['faultyEventId']) {
      const errorQueueAction = await this.faultyEventService.getErrorQueueActionFromDb(eventId);
      const newEvent = new Event();
      Event.singleEventToModel(errorQueueAction.action, newEvent);
      if (errorQueueAction.actionType === ActionType.CREATE) {
        // If the faulty event is not synced to the server, then we remove the attachments as they are not uploaded to the server.
        newEvent.attachments = [];
      }
      event = newEvent;
    } else {
      event = await this.eventService.getOnceById(eventId);
    }
    return event;
  }

  private async cancelLocalNotification() {
    const localNotificationsPending: LocalNotificationPendingList = await LocalNotifications.getPending();
    return localNotificationsPending
    // console.log(localNotificationsPending);
    // LocalNotifications.cancel(localNotificationsPending);
  }

  /**
   * Give the linked task for the abstract form
   * if the current event was create from a task
   */
  beforeSave(status: EventStatus): void {
    // console.log(this.cancelLocalNotification())
    if(this.userHasAppAccess) {
      this.statusToSave = status;
      this.backupService.removeBackupItem(BACKUP_TYPES.EVENT);
      this.createdFromTask ? this.save(this.formGroup.value.eventTaskAdapter.task) : this.save();
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  protected async saveItem(isNewItem: boolean, event: Event): Promise<Event> {
    await this.prepareEvent(event);
    event.isEventSynced = false;
    if (this.isNewItem) {
      event.status = this.statusToSave;
      event.createdBy = Author.userToAuthor(this.sessionService.getCurrentUser()); /* set by the server but used in event-list before synchronization so it should be set */
      this.attachmentService.addAttachmentToLocallyStoredAttachments(this.getAttachmentsToUpload());
      const createdEvent = this.createEvent(event);
      this.logger.info('New Event created successfully. Event is', createdEvent);
      return createdEvent;
    } else if (this.isFaultyEvent) {
      event.status = this.statusToSave;
      event.createdBy = Author.userToAuthor(this.sessionService.getCurrentUser()); /* set by the server but used in event-list before synchronization so it should be set */
      this.attachmentService.addAttachmentToLocallyStoredAttachments(this.getAttachmentsToUpload());
      return this.faultyEventService.getFaultyItemActionTypeFromDb(this.itemId).then(async (value) => {
        if (value === ActionType.CREATE) {
          const createdEvent = this.createEvent(event);
          this.logger.info('Faulty Event resolved and created successfully. Event is', createdEvent);
          return createdEvent;
        } else if (value === ActionType.UPDATE) {
          const updatedEvent = this.updateEvent(event);
          this.logger.info('Faulty Event resolved and updated successfully. Event is', updatedEvent);
          return updatedEvent;
        } else {
           const deletedEvent = this.deleteEvent(event);
           this.logger.info('Faulty Event to be deleted has been deleted successfully. Event is', deletedEvent);
           return deletedEvent;
        }
      }).catch(async (error) => {
        this.logger.error("Unable to check if event exists in ErrorItemsQueue db: ", error);
        this.logger.info("Defaulting to create new event from faulty event.");
        const createdEvent = this.createEvent(event);
        this.logger.info('Faulty Event resolved and created successfully. Event is', createdEvent);
        return createdEvent;
      })
    } else {
      const updatedEvent = this.updateEvent(event);
      return updatedEvent;
    }
  }

  async createEvent(event: Event): Promise<Event> {
    const createdEvent = await this.eventService.create(event);
    this.itemId = createdEvent.id;
    event.id = createdEvent.id;
    this.attachmentService.uploadAttachments(this.relevantSharedService.currentSpaceId, this.relevantSharedService.currentSiteId, createdEvent.id, this.getAttachmentsToUpload());
    this.clearAllAttachmentsToUpload();
    // Track action in intercom
    this.intercomService.trackUserAction(IntercomUserActions.AddEvent);
    return createdEvent;
  }

  async updateEvent(event: Event): Promise<Event> {
    event.updatedOn = new Date();
    event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
    event.modifiedAt = new Date();
    event.status = this.statusToSave;
    this.attachmentService.addAttachmentToLocallyStoredAttachments(this.getAttachmentsToUpload());
    const updatedEvent = await this.eventService.update(event);
    this.logger.info('Event updated successfully. Event is', updatedEvent);
    this.attachmentService.uploadAttachments(this.relevantSharedService.currentSpaceId, this.relevantSharedService.currentSiteId, updatedEvent.id, this.getAttachmentsToUpload());
    this.clearAllAttachmentsToUpload();
    return updatedEvent;
  }

  async deleteEvent(event: Event): Promise<Event> {
    const deletedEvent = await this.eventService.delete(event);
    this.itemId = deletedEvent.id;
    event.id = deletedEvent.id;
    return deletedEvent;
  }

  protected fetchAndPopulateWithEventCustomFormData(event: Event): void {
    this.customEventFormService.getCurrentEventCustomFormFromDB(event.formLayoutId).then((form) => {
      if(form) {
        this.isCustomEventFormNotAvailable = false;
        this.customFields.clear();
        this.currentCustomEventForm = form;
        if(this.currentCustomEventForm.customFields) {
          this.currentCustomEventForm.customFields.forEach((field) => {
            if(field.fieldType.value !== CustomEventFieldTypeValue.LEGACY) {
              const customFieldValue = event.customFieldValues.find(customField => customField.uuid === field.id);
              const customFormField = this.formBuilder.group({
                id: [field.id],
                value: [customFieldValue ? customFieldValue.value : ''],
              });
              this.customFields.push(customFormField);
            }
          });
        }
        this.isCustomFormUpdateRequired = this.customEventFormService.isCustomFormUpdateRequired(form);
      } else {
        this.customEventFormService.getLegacyFormLayout().then((legacyForm) => {
          // If form is legacy form then allow users to edit normally.
          if(event.formLayoutId !== 'afce2730-c6b9-4617-a073-e9fa82c72f1b') {
            this.isCustomEventFormNotAvailable = true;
          }
          this.currentCustomEventForm = legacyForm;
          this.isCustomFormUpdateRequired = this.customEventFormService.isCustomFormUpdateRequired(form);
        });
      }
    }).catch((error) => {
      this.logger.error("Error while fetching the form layout of the event, defaulting to the legacy form.", error);
      this.customEventFormService.getLegacyFormLayout().then((legacyForm) => {
        this.isCustomEventFormNotAvailable = true;
        this.currentCustomEventForm = legacyForm;
      });
    });
  }

  protected async sendMail(): Promise<void> {
    if(this.userHasAppAccess) {
      try {
        this.eventEmailService.sendEventEmail(
          this.event,
          this.formGroup.controls['tags'].value,
          this.formGroup.controls['contractors'].value,
          this.formGroup.controls['labours'].value,
          this.formGroup.controls['plants'].value,
          this.formGroup.controls['materials'].value,
          this.formGroup.controls['eventTaskAdapter'].value,
        );
      } catch (error) {
        this.logger.error("Error sending event email", false);
      }
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Open PDF modal to configure PDF generation
   */
  openPDFReportModalForSingleEvent(): void {
    if(this.userHasAppAccess) {
      this.exportService.checkSubscription('REPORT_PDF').pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {
        const modalRef = this.modalService.open(
          PdfExportConfigurationPopupComponent,
          { centered: true },
        );
        const pdfExportConfigurationComponent: PdfExportConfigurationPopupComponent = modalRef.componentInstance;
        pdfExportConfigurationComponent.setCallbacks(
          result => {
            modalRef.close(result);
            this.getPDFReport(result);
          },
          () => {
            modalRef.dismiss();
          }
        );
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Download the PDF file generated by REST API with filters
   */
  getPDFReport(title: string): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSavePdfReport(
      new Date(),
      new Date(),
      this.event.id.split(', '),
      title,
    ).pipe(
      takeUntil(this.destroy),
    ).subscribe(
      () => {
        this.toasterService.showSuccessToaster('events.report.success.download');
        this.spinnerService.deactivate();
      },
      error => {
        this.toasterService.showWarningToaster('events.report.error.download');
        this.spinnerService.deactivate();
      },
    );
  }

  protected deleteItem(event: Event): Promise<Event> {
    return this.eventService.delete(event)
      .then(deletedEvent => {
        this.toasterService.showSuccessToaster('events.detail.delete.notify.success');
        return deletedEvent;
      }).catch(error => {
        this.toasterService.showErrorToaster('events.detail.delete.notify.error');
        throw error;
      });
  }

  protected updateRights(user: User, event: Event): void {
    if (user && event) {
      this.canApprove = false;
      this.canReject = false;
      this.canEdit = false;
      this.canDelete = false;
      this.userRightsService.watchCurrentRoles().pipe(
        takeUntil(this.destroy)
      ).subscribe(userRoles => {
        this.canApprove = this.userRightsService.userHasRight(userRoles, this.eventService.getApprovalRights(event));
        this.canReject = this.userRightsService.userHasRight(userRoles, this.eventService.getRejectionRights(event));
        this.canEdit = this.userRightsService.userHasRight(userRoles, this.eventService.getEditionRights(event));
        this.canDelete = this.eventService.isEventDeletable(userRoles, event);
      });
    } else if (this.isNewItem) {
      this.canApprove = true;
      this.canReject = true;
      this.canEdit = true;
    } else {
      this.canApprove = false;
      this.canReject = false;
      this.canEdit = false;
    }
  }

  protected navigateToItem(event: Event): Promise<boolean> {
    return this.router.navigate(['..', event.id], { relativeTo: this.route });
  }

  protected navigateToLinkedItem(task: Task): Promise<boolean> {
    return this.router.navigate(['../../tasks/', task.id], { relativeTo: this.route });
  }

  protected navigateToEventListFromFaultyList(): Promise<boolean> {
    return this.router.navigate(['/space', this.relevantSharedService.currentSpaceId, '/site', this.relevantSharedService.currentSiteId, '/events']);
  }

  private async prepareEvent(event: Event): Promise<void> {
    const form = this.formGroup.value;

    event.siteId = this.relevantSharedService.currentSiteId;
    event.title = form.title.trim();
    event.description = form.description.trim();
    event.locationObject = form.location;
    event.location = event.locationObject ? event.locationObject.name : '';
    if (form.eventTaskAdapter) {
      form.eventTaskAdapter.updateModel(event.task);
    }
    event.tags = this.tagService.convertTagsToEventTags(form.tags);
    event.contractors = this.contractorService.convertContractorsToEventContractors(form.contractors);
    event.assets = EventAsset.jsonToEventAssets(
      this.formGroup.value.labours.concat(this.formGroup.value.plants).concat(this.formGroup.value.materials));
    event.attachments = form.attachments;
    const startDate: any = new Date(form.startDatetime).getTime();
    const endDate: any = form.endDatetime ? new Date(form.endDatetime).getTime() : null;
    event.startDatetime = startDate;
    event.endDatetime = endDate;

    // if quantityDone is specified update task progress using quantities
    if(form.quantityDone > 0) {
      let taskTotalQuantityDone = await this.taskService.getTotalQuantityDone(form.eventTaskAdapter.task);

      // if an event is edited remove the previous quantityDone from taskTotalQuantityDone and then add the new
      // quantityDone to taskTotalQuantityDone
      if(!this.isNewItem) {
        taskTotalQuantityDone = taskTotalQuantityDone - event.task.quantityDone;
      }
      event.task.quantityDone = form.quantityDone;
      taskTotalQuantityDone += form.quantityDone;
      const taskProgress = Math.floor((taskTotalQuantityDone/this.taskPlannedQuantity) * 100);
      event.task.progress = taskProgress;
      form.eventTaskAdapter.progress = taskProgress;
    }
    event.formLayoutId = this.currentCustomEventForm.id;
    if(this.customFields.value.length > 0) {
      event.customFieldValues = [];
      this.customFields.value.forEach((customFieldValue) => {
        event.customFieldValues.push({uuid: customFieldValue.id, value: customFieldValue.value});
      });
    }


    if (!this.isNewItem) {
      event.id = this.itemId;
    }
  }

  /**
   * Update weather preview if we have necessary data (event location and event date)
   */
  previewWeather(
    date = this.formGroup.controls['startDatetime'].value,
  ): void {
    if (this.event) {
      const weather = this.event.weather;
      if (date && weather && weather.latitude && weather.longitude) {
        // If weather object is already filled and contains weather information, use existing info
        if (weather.humidity && weather.temperature && weather.windSpeed && this.formGroup.controls['startDatetime'].pristine) {
          this.weatherCodeDetails = this.weatherService.getWeather(weather);
        }
        else {
          this.isWeatherDataLoading = true;
          this.eventService.fetchWeather(weather, date).pipe(
            takeUntil(this.destroy)
          ).subscribe(_weather => {
            this.event.weather = _weather;
            this.weatherCodeDetails = this.weatherService.getWeather(this.event.weather);
            if(this.weatherCodeDetails) {
              this.weatherApiInvalidResponse = false;
            } else {
              this.weatherApiInvalidResponse = true;
            }
            this.isWeatherPendingProp = this.isWeatherPending();
            this.showWeatherIfAvailable(_weather);
            this.isWeatherDataLoading = false;
          });
        }
      }
    }
    this.isWeatherPendingProp = this.isWeatherPending();
  }

  private isWeatherPending(): boolean {
    return this.weatherCodeDetails === null || !!this.event &&
      !!this.event.weather &&
      !this.event.weather.ignored &&
      this.event.weather.humidity === null &&
      this.event.weather.temperature === null &&
      this.event.weather.windSpeed === null;
  }

  updateSelectedTasks(tasks: EventTaskAdapter[], event: Event): void {
    if (event.task && event.task.taskId && event.task.taskId !== '') {
      const eventTaskAdapter = tasks.find(x => x.task.id === event.task.taskId);
      if (eventTaskAdapter) {
        this.taskService.updateTaskStatusAndIndicatorIfNeeded(eventTaskAdapter.task);
        eventTaskAdapter.updateFromModel(event.task);
        if(eventTaskAdapter.task.plannedQuantity > 0) {
          this.formGroup.controls['quantityDone'].setValue(event.task.quantityDone);
          this.formGroup.controls['quantityUnit'].setValue(eventTaskAdapter.task.quantityUnit);
          this.taskPlannedQuantity = eventTaskAdapter.task.plannedQuantity;
        }
      }
      this.formGroup.controls['eventTaskAdapter'].setValue(eventTaskAdapter);
    }
  }

  showEndDatetime(): void {
    if (!this.formGroup.controls['endDatetime'].value) {
      this.formGroup.controls['endDatetime'].setValue(this.defaultEndDate);
    }
  }

  clearEndDatetime(): void {
    this.formGroup.controls['endDatetime'].setValue(null);
  }

  fetchResources(): void {
    // labours
    this.laboursLoaded = false;
    this.plantsLoaded = false;
    this.materialsLoaded = false;
    this.assetService.filterForCurrentSite();

    // tags
    this.tagsLoaded = false;
    this.tagService.filterForCurrentSite();

    // contractors
    this.contractorsLoaded = false;
    this.contractorService.filterForCurrentSite();

    // tasks
    this.tasksLoaded = false;
    this.taskService.filterTasks();

    // locations
    this.locationsLoaded = false;
    this.locationService.filterForCurrentSite();

    // Populate custom fields which arent Legacy fields into the formGroup
    if(this.isNewItem) {
      const siteId = this.relevantSharedService.currentSiteId;
      this.customEventFormService.getCurrentActiveFormFromDB(siteId).then((form) => {
        if(form) {
          this.isCustomEventFormNotAvailable = false;
          this.currentCustomEventForm = form;
          if(this.currentCustomEventForm.customFields) {
            this.currentCustomEventForm.customFields.forEach((field) => {
              if(field.fieldType.value !== CustomEventFieldTypeValue.LEGACY) {
                const customFormField = this.formBuilder.group({
                  id: [field.id],
                  value: [null],
                });
                this.customFields.push(customFormField);
              }
            });
          }
        }
        else {
          this.customEventFormService.getLegacyFormLayout().then((legacyForm) => {
            this.currentCustomEventForm = legacyForm;
          });
        }
      }).catch((error) => {
        this.logger.error("Error while fetching the form layout while creating an event, defaulting to the legacy form.", error);
        this.customEventFormService.getLegacyFormLayout().then((legacyForm) => {
          this.isCustomEventFormNotAvailable = true;
          this.currentCustomEventForm = legacyForm;
        });
      });
    }
  }

  onEditClick(): void {
    if(this.userHasAppAccess) {
      this.isEdited = true;
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  /**
   * Open comment modal to input approval comment.
   */
  showApproveCommentPopup() : void {
    if(this.userHasAppAccess) {
      const modalRef = this.modalService.open(
        EventApproveRejectConfigurationPopupComponent,
        { centered: true },
      );
      const eventApproveRejectConfigurationComponent: EventApproveRejectConfigurationPopupComponent = modalRef.componentInstance;
      eventApproveRejectConfigurationComponent.isApprovalRequest = true;
      eventApproveRejectConfigurationComponent.setCallbacks(
        result => {
          modalRef.close(result);
          this.approveEvent(result);
        },
        () => {
          modalRef.dismiss();
        }
      );
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  showApproveAlert(title: string): void {
    this.translateService.get([
      'events.detail.approve.question',
      'btn.cancel',
      'events.detail.btn.approve',
    ]).pipe(
      flatMap(translations => this.alertController.create({
        header: translations['events.detail.approve.question'],
        buttons: [
          {
            text: translations['btn.cancel'],
            role: 'cancel',
            handler: () => {
            },
          },
          {
            text: translations['events.detail.btn.approve'],
            cssClass: 'text-success font-weight-bold',
            handler: () => {
              // Approve event on confirmation
              this.approveEvent(title);
            },
          },
        ],
      })),
      flatMap(alert => alert.present()),
    ).pipe(
      takeUntil(this.destroy)
    ).subscribe();
  }

    /**
   * Open comment modal to input rejection comment.
   */
     showRejectCommentPopup() : void {
      if(this.userHasAppAccess) {
        const modalRef = this.modalService.open(
          EventApproveRejectConfigurationPopupComponent,
          { centered: true },
        );
        const eventApproveRejectConfigurationComponent: EventApproveRejectConfigurationPopupComponent = modalRef.componentInstance;
        eventApproveRejectConfigurationComponent.isRejectionRequest = true;
        eventApproveRejectConfigurationComponent.setCallbacks(
          result => {
            modalRef.close(result);
            this.rejectEvent(result);
          },
          () => {
            modalRef.dismiss();
          }
        );
      } else {
        this.userAppAccessService.showUpdateAppAccessModal();
      }
    }

  showRejectAlert(title: string) {
    this.translateService.get([
      'events.detail.reject.question',
      'btn.cancel',
      'events.detail.btn.reject',
    ]).pipe(
      flatMap(translations => this.alertController.create({
        header: translations['events.detail.reject.question'],
        buttons: [
          {
            text: translations['btn.cancel'],
            role: 'cancel',
            handler: () => {
            },
          },
          {
            text: translations['events.detail.btn.reject'],
            cssClass: 'text-danger font-weight-bold',
            handler: () => {
              // Reject event on confirmation
              this.rejectEvent(title);
            },
          },
        ],
      })),
      flatMap(alert => alert.present()),
    ).pipe(
      takeUntil(this.destroy)
    ).subscribe();
  }

  private approveEvent(title: string): void {
    this.event.approvalComment = title;
    // Change event updatedBy to current user
    this.event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
    this.eventService.approveEvent(this.event).then(() => {
      // Change event status
      this.event.status = EventStatus.APPROVED;
      // Get new user rights after approval
      this.updateRights(this.sessionService.getCurrentUser(), this.event);
    });
  }

  protected submitEvent(): void {
    if(this.userHasAppAccess) {
      // Change event status
      this.event.status = EventStatus.SUBMITTED;
      this.eventService.submitEvent(this.event).then(() => {
        // Get new user rights after approval
        this.updateRights(this.sessionService.getCurrentUser(), this.event);
      });
    } else {
      this.userAppAccessService.showUpdateAppAccessModal();
    }
  }

  private rejectEvent(title: string): void {
    this.event.approvalComment = title;
    // Change event updatedBy to current user
    this.event.updatedBy = Author.userToAuthor(this.sessionService.getCurrentUser());
    this.eventService.rejectEvent(this.event).then(() => {
      // Change event status
      this.event.status = EventStatus.REJECTED;
      // Get new user rights after rejection
      this.updateRights(this.sessionService.getCurrentUser(), this.event);
    });
  }

  /**
   * Returns the attachments to upload and their data
   */
  protected getAttachmentsToUpload(): { [attachmentId: string]: string } {
    if (this.eventPhotosComponent) {
      return this.eventPhotosComponent.getAttachmentsToUpload();
    }
    return {};
  }


  /**
   * Clear the cached attachments
   */
  protected clearAllAttachmentsToUpload(): void {
    if (this.eventPhotosComponent) {
      this.eventPhotosComponent.clearAllAttachmentsToUpload();
    }
  }

  /** Bound to select weather component on activeWeather event */
  onWeatherActiveEvent(state: boolean): void {
    this.event.weather.ignored = !state;
  }

  protected abstract renewWeatherForNewEvent(): void;
}
