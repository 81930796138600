import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppTypeService } from './app-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faviconHrefs } from '@models/favicon-hrefs';

@Injectable({
  providedIn: 'root'
})
export class TabTitleService {

  private appName: string = 'Site Productivity';
  private currentAppName: string = 'Site Productivity';
  confirmKey: string;

  constructor(
    private htmlTitleService: Title,
    private translateService: TranslateService,
    private appType: AppTypeService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.translateService.get('app_name_lower')
      .subscribe(appName => {
        this.appName = appName;
      });
    this.route.queryParams.subscribe(queryParams => {
      if(queryParams['key']) {
        this.confirmKey = queryParams['key'];
      } else {
        this.confirmKey = '';
      }
    });
  }

  /**
   * Change the title of the window
   * @param newTitle given new title
   */
  updateTabTitle(newTitle: string, translationParams?: Object): void {
    let favicon_32x32Href = null;
    let favicon_16x16Href = null;
    let faviconHref = null;
    let maskIconHref = null;
    const currentApp = this.appType.getCurrentAppType();
    if (this.appType.isInAdministrationPage() || this.isInWelcomeScreens()) {
      // set currentAppName to site productivity on tab bar
      this.currentAppName = 'app_name.site_productivity';
      // add favicon hrefs based on app type
      favicon_32x32Href = faviconHrefs.siteProductivityFavicon_32x32;
      favicon_16x16Href = faviconHrefs.siteProductivityFavicon_16x16;
      faviconHref = faviconHrefs.siteProductivityFavicon;
      maskIconHref = faviconHrefs.siteProductivityMaskIcon;
    }
    else {
      // check for current app type and update currentAppName
      if (currentApp === 'diary') {
        // set currentAppName to site diary on tab bar
        this.currentAppName = 'app_name_lower';
        // add favicon hrefs based on current app
        favicon_32x32Href = faviconHrefs.siteDiaryFavicon_32x32;
        favicon_16x16Href = faviconHrefs.siteDiaryFavicon_16x16;
        faviconHref = faviconHrefs.siteDiaryFavicon;
        maskIconHref = faviconHrefs.siteDiaryMaskIcon;
      }
      else if (currentApp === 'tasks') {
        // set currentAppName to site task on tab bar
        this.currentAppName = 'app.site_task.name.lower';
        // add favicon hrefs based on current app
        favicon_32x32Href = faviconHrefs.siteTaskFavicon_32x32;
        favicon_16x16Href = faviconHrefs.siteTaskFavicon_16x16;
        faviconHref = faviconHrefs.siteTaskFavicon;
        maskIconHref = faviconHrefs.siteTaskMaskIcon;
      }
    } 
    this.translateService.get(this.currentAppName)
      .subscribe(appName => {
        this.currentAppName = appName;
      });
    this.translateService.get(newTitle, translationParams)
      .subscribe(translation => {
        // get favicon element
        const favicon_32x32: HTMLLinkElement = document.querySelector('#favicon-32x32');
        const favicon_16x16: HTMLLinkElement = document.querySelector('#favicon-16x16');
        const favicon: HTMLLinkElement = document.querySelector('#fav-icon');
        const maskIcon: HTMLLinkElement = document.querySelector('#mask-icon');
        // set href on favicon element
        favicon_32x32.href = favicon_32x32Href;
        favicon_16x16.href = favicon_16x16Href;
        favicon.href = faviconHref;
        maskIcon.href = maskIconHref;
        this.htmlTitleService.setTitle(newTitle !== this.appName ? `${this.currentAppName} | ${translation}` : this.appName);
      });
  }

  /**
   * Set tab title to default app name
   */
  resetTabTitle(): void {
    this.updateTabTitle(this.appName);
  }

  // Check if users are in welcome screens
  isInWelcomeScreens(): boolean{
    try {
        if((this.router.url === ('/login')) ||
            (this.router.url === ('/register')) ||
            (this.router.url === ('/reset')) ||
            (this.router.url === ('/reset?key=' + this.confirmKey))
          )
        {
          return true;
        } else {
          return false;
        }
    } catch {
      return false;
    }
  }
}
