import { HttpErrorResponse } from '@angular/common/http';


export class ServerError extends Error {
  cause?: HttpErrorResponse;
  data?: any | any[];

  constructor(message: string, cause?: HttpErrorResponse, data?: any | any[]) {
    if (cause) {
      super(message + ` (${cause.message})`);
    } else {
      super(message);
    }
    this.name = 'ServerError';
    this.cause = cause;
    this.data = data;
  }
}
