<div [ngClass]="deviceIsMobile? 'mobile-container': 'container'" [formGroup]="parentFormGroup">
  <span [ngClass]="{'mobile-label': deviceIsMobile}" class="label">{{ customField.title }}</span>
  <div *ngIf="!deviceIsMobile" class="input-options">
    <div class="range-label" *ngIf="lowerRangeLabelExists()">{{lowerRangeLabel}}</div>
    <ion-range formControlName="value" [ngClass]="isEdited? 'slider' : 'disabled-slider'" item-content [min]="lowerRange" [max]="upperRange" step="1"
    [pin]="true" [ticks]="true" [snaps]="true" [disabled]="!isEdited"></ion-range>
    <div class="range-label" *ngIf="upperRangeLabelExists()">{{ upperRangeLabel }}</div>
  </div>
  <div *ngIf="deviceIsMobile">
    <ion-range formControlName="value" class="mobile-slider" item-content [min]="lowerRange" [max]="upperRange" step="1"
    [pin]="true" [ticks]="true" [snaps]="true"></ion-range>
    <div class="labels">
      <div class="mobile-lower-label" *ngIf="lowerRangeLabelExists()">{{ lowerRangeLabel }}</div>
      <div class="mobile-upper-label" *ngIf="upperRangeLabelExists()">{{  upperRangeLabel }}</div>
     </div>
  </div>
</div>
