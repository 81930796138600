import { Injectable } from '@angular/core';

export enum Unit { B, KB, MB, GB, TB, PB, EB, ZB, YB }

@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {

  static readonly BASE: number = 1024;

  constructor() { }

  /**
   * Convert given bytes into targeted format
   * @param bytes given bytes
   * @param unit Unit enum
   */
  convertBytesTo(bytes: number, unit: Unit = Unit.B): number {
    return bytes / Math.pow(FileUtilsService.BASE, unit);
  }
}
