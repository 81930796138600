import { Author } from './author';
import { Attachment } from './attachment';
import { SpaceDatabase } from './db/space-database';
import { EventAsset } from './event-asset';
import { EventContractor } from './event-contractor';
import { EventTag } from './event-tag';
import { EventTask } from './event-task';
import { Location } from '@models/location';
import { Weather } from './weather';
import { SiteOwnedModelElement } from './site-owned-model-element';
import { ModelElement } from './model-element';

export enum EventStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
  DRAFT = 'Draft'
}

export interface RelatedMinimalEvent {
 id: string;
 taskId: string;
 startDatetime: Date;
 endDatetime: Date;
 progress: number;
 quantityDone: number;
 isSuspended: boolean
}

interface EventCustomFieldValues {
  uuid: string;
  value: string | number;
}

export class Event implements SiteOwnedModelElement {
  id: string;
  siteId: string;
  status: string;
  title: string;
  revision: string;
  startDatetime: Date;
  endDatetime: Date;
  description: string;
  location: string;
  locationObject: Location;
  createdOn: Date;
  createdBy: Author;
  updatedOn: Date | number;
  updatedBy: Author;
  attachments: Attachment[];
  contractors: EventContractor[];
  assets: EventAsset[];
  tags: EventTag[];
  task: EventTask;
  weather: Weather;
  isEventSynced: boolean;
  approvalComment: string;
  formLayoutId: string;
  customFieldValues: EventCustomFieldValues[];
  modifiedAt: Date;

  constructor(
    title: string = '',
    id: string = ModelElement.generateId(),
    siteId: string = '',
    revision: string = '',
    contractors: EventContractor[] = [],
    description: string = '',
    location: string = '',
    locationObject: Location = new Location(),
    startDatetime: Date = new Date(),
    endDatetime: Date = null,
    createdOn: Date = null, /* set by the server should be null/empty untill synchronization */
    createdBy: Author = new Author(),
    updatedOn: Date = new Date(),
    updatedBy: Author = new Author(),
    attachments: Attachment[] = [],
    assets: EventAsset[] = [],
    tags: EventTag[] = [],
    task: EventTask = new EventTask(),
    status: string = EventStatus.DRAFT,
    /* isEventSynced will be changed to false only when the event syncing begins */
    isEventSynced: boolean = true,
    approvalComment: string = '',
    formLayoutId: string = null,
    customFieldValues: EventCustomFieldValues[] = null,
    modifiedAt: Date = new Date(),
    ) {
    this.title = title;
    this.id = id;
    this.siteId = siteId;
    this.revision = revision;
    this.contractors = contractors;
    this.description = description;
    this.location = location;
    this.locationObject = locationObject;
    this.startDatetime = startDatetime;
    this.endDatetime = endDatetime;
    this.createdOn = createdOn;
    this.createdBy = createdBy;
    this.updatedOn = updatedOn;
    this.updatedBy = updatedBy;
    this.attachments = attachments;
    this.assets = assets;
    this.tags = tags;
    this.task = task;
    this.status = status;
    this.weather = null;
    this.isEventSynced = isEventSynced;
    this.approvalComment = approvalComment;
    this.formLayoutId = formLayoutId;
    this.customFieldValues = customFieldValues;
    this.modifiedAt = modifiedAt;
  }

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend sync/ websocket
  * @param model Given Event
  */
  public static toModel(json: any, model: Event) {
    model.id = json.payload.id;
    model.siteId = json.payload.siteId;
    model.title = json.payload.title;
    model.location = json.payload.location;
    model.locationObject = json.payload.locationObject;
    model.startDatetime = json.payload.startDatetime;
    model.endDatetime = json.payload.endDatetime;
    model.description = json.payload.description;
    model.status = json.payload.status;
    model.createdOn = json.payload.createdOn;
    model.updatedOn = json.payload.revision;
    model.description = json.payload.description;
    Author.toModel(json.payload.createdBy, model.createdBy);
    Author.toModel(json.payload.updatedBy, model.updatedBy);
    model.tags = EventTag.jsonToEventTags(json.payload.tags);
    model.contractors = EventContractor.jsonToEventContractors(json.payload.contractors);
    model.assets = EventAsset.jsonToEventAssets(json.payload.assets);
    model.task = json.payload.task ? EventTask.jsonToEventTask(json.payload.task) : new EventTask();
    for (const attachmentId of json.payload.pictures) {
      model.attachments.push(new Attachment(attachmentId));
    }
    model.weather = Weather.jsonToWeather(json.payload.weather);
    model.approvalComment = json.payload.approvalComment;
    model.formLayoutId = json.payload.formLayoutId;
    model.customFieldValues = json.payload.customFieldsValues;
    model.modifiedAt = json.payload.modifiedAt;
  }

  /**
  * Convert backend json to MODEL
  * @param json json returned by backend sync/ websocket
  * @param model Given Event
  */
  public static singleEventToModel(json: any, model: Event) {
    model.id = json.id;
    model.siteId = json.siteId;
    model.title = json.title;
    model.location = json.location;
    model.locationObject = json.locationObject;
    model.startDatetime = json.startDatetime;
    model.endDatetime = json.endDatetime;
    model.description = json.description;
    model.status = json.status;
    model.createdOn = json.createdOn;
    model.updatedOn = json.revision;
    model.description = json.description;
    Author.toModel(json.createdBy, model.createdBy);
    Author.toModel(json.updatedBy, model.updatedBy);
    model.tags = EventTag.jsonToEventTags(json.tags);
    model.contractors = EventContractor.jsonToEventContractors(json.contractors);
    model.assets = EventAsset.jsonToEventAssets(json.assets);
    model.task = json.task ? EventTask.jsonToEventTask(json.task) : new EventTask();
    for (const attachmentId of json.pictures) {
      model.attachments.push(new Attachment(attachmentId));
    }
    model.weather = Weather.jsonToWeather(json.weather);
    model.approvalComment = json.approvalComment;
    model.formLayoutId = json.formLayoutId;
    model.customFieldValues = json.customFieldsValues;
    model.modifiedAt = json.modifiedAt;
  }

  duplicate(): Event {
    const newEvent = Object.assign(new Event(), this);
    newEvent.id = ModelElement.generateId();
    newEvent.assets = this.assets.map(eventAsset => Object.assign(new EventAsset(), eventAsset));
    newEvent.contractors = this.contractors.map(eventContractor => Object.assign(new EventContractor(), eventContractor));
    newEvent.tags = this.tags.map(eventTag => Object.assign(new EventTag(), eventTag));
    newEvent.status = EventStatus.DRAFT;
    newEvent.revision = '';
    newEvent.attachments = [];
    newEvent.startDatetime = new Date();
    newEvent.endDatetime = null;
    newEvent.formLayoutId = this.formLayoutId;
    newEvent.customFieldValues = this.customFieldValues;
    newEvent.modifiedAt = new Date();

    return newEvent;
  }

  /**
   * Convert frontend model to backend model
   */
  toDTO() {
    return {
      id: this.id,
      siteId: this.siteId,
      task: this.task.taskId ? this.task : null,
      assets: EventAsset.eventAssetsToDto(this.assets),
      contractors: EventContractor.eventContractorsToDto(this.contractors),
      tags: EventTag.eventTagsToDto(this.tags),
      // TODO: deal with comments in the app
      comments: null,
      pictures: this.attachments.map(attachment => attachment.id),
      status: this.status,
      title: this.title,
      startDatetime: this.startDatetime instanceof Date ? this.startDatetime.getTime() : this.startDatetime,
      endDatetime: this.endDatetime instanceof Date ? this.endDatetime.getTime() : this.endDatetime,
      description: this.description,
      location: this.location,
      locationObject: this.locationObject,
      createdOn: this.createdOn instanceof Date ? this.createdOn.getTime() : this.createdOn,
      updatedOn: this.updatedOn instanceof Date ? this.updatedOn.getTime() : this.updatedOn,
      weather: Weather.toDTO(this.weather),
      approvalComment: this.approvalComment,
      formLayoutId: this.formLayoutId,
      customFieldsValues: this.customFieldValues,
      modifiedAt : this.modifiedAt instanceof Date ? this.modifiedAt.getTime() : this.modifiedAt,
      /**
       * Backend is responsible for setting  createdBy and updatedBy
       * Data sent from frontend will not be taken into account
       */
    };
  }

  public get label(): string {
    return this.title;
  }
}
