import { Injectable } from '@angular/core';
import { ErrorQueueAction } from '@models/synchronization/error-queue-action';
import { ActionType } from '@models/synchronization/sync-dto';
import { NGXLogger } from 'ngx-logger';
import { DatabaseService } from './shared/database.service';
import { SharedService } from './shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class FaultyEventService {

  constructor(
    private databaseService: DatabaseService,
    protected logger: NGXLogger,
    private sharedService: SharedService
  ) {}

  /**
   * Deletes faulty event from db - to be called after the faulty event has been submitted
   */
  async deleteFaultyEventFromDb(eventId: string): Promise<void> {
    const db = await this.databaseService.getUserDB();
    db.errorItemsQueue.delete(eventId);
    this.logger.info('Deleted item from ErrorItemsQueue. Item ID: ', eventId);
  }

  /**
   * Faulty events can come up while creating, updating or deleting events. This function is used 
   * to fetch the action type to be performed after the user submits the resolved form. 
   * @returns The action type to be performed after resolving the faulty event.
   *
   */
  async getFaultyItemActionTypeFromDb(eventId: string): Promise<ActionType> {
    const db = await this.databaseService.getUserDB();
    return (await db.errorItemsQueue.get(eventId)).actionType;
  }

  /**
   * Used to show appropriate error message to the user based on the error code causing the faulty event.
   * @returns The error code which caused the event to be faulty.
   */
  async getFaultyItemErrorCodeFromDb(eventId: string): Promise<string | null> {
    const db = await this.databaseService.getUserDB();
    return (await db.errorItemsQueue.get(eventId)).errorCode;
  }

  /**
   *
   * @param eventId The event ID of the faulty event that needs to be fetched.
   * @returns The faulty event as `ErrorQueueAction`.
   */
  async getErrorQueueActionFromDb(eventId: string): Promise<ErrorQueueAction> {
    return this.databaseService.getSiteFaultyItems(this.sharedService.currentSiteId).then(errorQueueActions =>
      errorQueueActions.find((value) =>
       value.type === 'EventV4' && value.action.id === eventId
      )
    );
  }
}
