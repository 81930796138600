<div *ngIf="!deviceIsMobile && (isEdited || !isEdited && !isEmpty('attachments'))" class="custom-field-web">
  <div [formGroup]="parentFormGroup">
    <app-images-input #imagesInputComponent formControlName="attachments" [readOnly]="!isEdited"
      [spaceId]="sharedDataService.currentSpaceId" [siteId]="relevantSharedService.currentSiteId" [eventId]="event?.id" [eventTitle]="event.title" [appType]="'diary'" [isNewEvent]="isEventNew()">
    </app-images-input>
  </div>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" [formGroup]="parentFormGroup" class="custom-field-mobile">
  <ion-item
    class="photos-section"
    lines="none"
    (click)="openMobileGalleryPopup()">
    <app-images-input
      #imagesInputComponent
      *ngIf="event"
      class="deep-ion-item"
      formControlName="attachments"
      [eventTitle]="event.title"
      [eventId]="event.id"
      [spaceId]="relevantSharedService.currentSpaceId"
      [siteId]="relevantSharedService.currentSiteId"
      [appType]="'diary'"
      [isNewEvent]="isEventNew()">
    </app-images-input>
    <ion-icon name="custom-camera" color="primary" slot="start"></ion-icon>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <div *ngIf="event.attachments.length > 0">
    <div id="photos-title">
      <header class="label">{{'events.preview.section.photos' | translate}}</header>
    </div>
    <ul ngxViewer [viewerOptions]="viewerOptions" id="photos-container">
      <li class="photo" *ngFor="let attachment of event.attachments">
        <img class="cursor-on" [src]="getPictureUrl(attachment) | secureImageSrc | async">
      </li>
    </ul>
  </div>
</div>

