import { Component, OnInit } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { ScreenManipulationService } from '@services/screen-manipulation.service';
import { SpinnerService, SpinnerState } from '@services/spinner.service';
import { Observable } from 'rxjs';

import { NetworkStatus } from './models/synchronization/network-status';
import { DeviceService } from './services/device.service';
import { InternationalizationService } from './services/i18n/internationalization.service';
import { Logger } from './services/logger';
import { OfflineCheckerService } from '@services/offline-checker.service';
import { AppTypeService } from '@services/app-type.service';
import { AppUpdateSettingsService } from '@services/app-update-settings.service';
import { AngularGoogleMapsService } from '@services/google-maps/angular-google-maps.service';


/**
 * App component. Default component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  constructor(
    public deviceService: DeviceService,
    private platform: Platform,
    private i18n: InternationalizationService,
    public spinnerService: SpinnerService,
    private screenManipulationService: ScreenManipulationService,
    private offlineCheckerService: OfflineCheckerService,
    private appTypeService: AppTypeService,
    private appUpdateSettingsService: AppUpdateSettingsService,
    private angularGoogleMapsService: AngularGoogleMapsService,
  ) {}


  ngOnInit() {
    this.i18n.initApplicationLanguageSettings();
    this.appTypeService.observeCurrentURLForAppType();

    // Hide splashscreen when platform is ready
    this.platform.ready().then(() => {
      this.screenManipulationService.lockScreenOrientation();
      this.offlineCheckerService.startChecking();
      Logger.info('Site Diary online status : ', NetworkStatus.isOnline);
      SplashScreen.hide();
      if(this.deviceService.isMobile) {
        Keyboard.setAccessoryBarVisible({isVisible: false});
      }
    });

    // Call version check API
    this.appUpdateSettingsService.versionCheck();
  }

}
