import { Component, OnInit } from '@angular/core';
import { SummaryConfigurationPopupComponent } from '../summary-configuration-popup.component';
import { CustomDatepickerI18nService } from '@services/i18n/date-internationalization.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-summary-configuration-popup-webapp',
  templateUrl: './summary-configuration-popup-webapp.component.html',
  styleUrls: ['./summary-configuration-popup-webapp.component.scss']
})
export class SummaryConfigurationPopupWebappComponent extends SummaryConfigurationPopupComponent implements OnInit {
  constructor(
    protected formBuilder: FormBuilder,
    public deviceService: DeviceService,
    protected customDatepickerI18nService: CustomDatepickerI18nService,
    protected dialogRef: MatDialogRef<SummaryConfigurationPopupWebappComponent>,
  ) {
    super(
      formBuilder,
      deviceService,
    );
  }

  ngOnInit() {
    this.localizedFormat = this.customDatepickerI18nService.getPickerDisplayFormatWithOutTime();
    super.ngOnInit();
  }

  dismiss(): void {
    super.dismiss();
    this.dialogRef.close();
  }

  download(domEvent?: Event): void {
    super.download(domEvent);
    this.dialogRef.close();
  }
}
