import { Injectable } from '@angular/core';
import { Tag } from '../models/tag';
import { EventTag } from '../models/event-tag';
import { Task } from '@models/task';
import { DatabaseService } from './shared/database.service';
import { TaskTag } from '../models/task-tag';
import { SharedService } from './shared/shared.service';
import { AbstractModelService } from '@services/abstract-model-service';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { linkedToTask } from '@operators/linked-to-task.operator';
import { EventService } from './event.service';
import { TaskService } from './task.service';
import { SharedDataService } from './shared-data.service';
import { DeviceService } from './device.service';

/**
 * Tag service. Use for interact with webservice for get, add, delete or update {@link Tag} objects
 */
@Injectable()
export class TagService extends AbstractModelService<Tag> {
  type = 'Tag';

  public readonly siteItems: Observable<Tag[]> = this.items.pipe(map(items => items.filter(item => item.siteId === this.sharedService.currentSiteId)));
  public readonly spaceItems: Observable<Tag[]> = this.items.pipe(map(items => items.filter(item => item.siteId === 'null')));

  constructor(
    protected databaseService: DatabaseService,
    private sharedService: SharedService,
    private eventService: EventService,
    private taskService: TaskService,
    private sharedDataService: SharedDataService,
    private deviceService: DeviceService
  ) {
    super(
      databaseService,
    );

    if (!this.deviceService.isMobile) {
      // get space tags from shared data service
      this.spaceItems = this.sharedDataService.getSpaceTags();
      this.siteItems = this.sharedDataService.watchSiteTags;
    }
  }

  public async filterForCurrentSpace(): Promise<void>  {
    const spaceTags = await this.databaseService.getSpaceItems('tag');
    this.itemSubject.next(spaceTags);
  }

  public async filterForCurrentSite(): Promise<void>  {
    const siteTags = await this.databaseService.getSiteItems('tag', this.sharedService.currentSiteId);
    this.itemSubject.next(siteTags);
  }

  public async filterAll(): Promise<void> {
    const tags = await this.databaseService.getItemsForCurrentSiteAndSpace('tag', this.sharedService.currentSiteId);
    this.itemSubject.next(tags);
  }

  getTagById(id: string): Promise<Tag> {
    return this.databaseService.getItemById('tag', id);
  }

  /**
   * Check if a tag has a name and a cost
   * @param tag Tag to check
   */
  checkIntegrity(tag: Tag): boolean {
    return tag.name !== '';
  }

  /**
   * Method used to convert a {@link_SiteTag} or {@link_SpaceTag} to the {@link_EventTag} format expected by an event
   * @param tag given tag
   */
  convertTagToEventTag(tag: Tag): EventTag {
    const eventTag = new EventTag();
    eventTag.tagId = tag.id;
    eventTag.name = tag.name;
    return eventTag;
  }

  /**
   * Method used to convert an array of {@link_SiteTag} or {@link_SpaceTag} to an array of {@link_EventTag}
   */
  convertTagsToEventTags(tags: Tag[]): EventTag[] {
    const eventTags = [];
    tags.forEach(tag => {
      eventTags.push(this.convertTagToEventTag(tag));
    });
    return eventTags;
  }

  /**
   * Method used to convert an array of {@link_Tag} to an array of {@link_TaskTags}
   */
  convertTagsToTaskTags(tags: Tag[]): TaskTag[] {
    return this.convertTagsToEventTags(tags);
  }

  public getTagsLinkedToTask(task: Task): Observable<Tag[]> {
    return this.items.pipe(
      linkedToTask(task, 'tag'),
    );
  }

  public sortTagsAlphabetically(tags: Tag[] | EventTag[]) {
    tags.sort((n1, n2) => {
      if (n1.name > n2.name) {
        return 1;
      } else if (n1.name < n2.name) {
        return -1;
      } else {
        return 0;
      }
    })
  }
}

