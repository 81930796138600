import { SiteUser } from './site-user';
import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class TaskUser implements Labelable {
  userId: string;
  firstName: string;
  lastName: string;

  constructor() {
    this.userId = '';
  }

  public static jsonToTaskUsers(jsonUsers): Array<TaskUser> {
    return jsonUsers.map(jsonUser => {
      const user: TaskUser = new TaskUser();
      user.userId = jsonUser;
      return user;
    });
  }

  public static userToTaskUsers(siteUsers: Array<SiteUser>): Array<TaskUser> {
    return siteUsers.map(siteUser => {
      const user: TaskUser = new TaskUser();
      user.firstName = siteUser.firstName;
      user.lastName = siteUser.lastName;
      user.userId = siteUser.id;
      return user;
    });
  }

  public static taskUsersToDto(taskUsers: TaskUser[]): Array<string> {
    return taskUsers.map(taskUser => taskUser.userId);
  }

  public get label(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public get id(): string {
    return this.userId;
  }
}
