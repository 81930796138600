import { Injectable } from '@angular/core';
import { Asset } from '@models/asset';
import { Contractor } from '@models/contractor';
import { CustomEventForm } from '@models/custom-event-form';
import { Event } from '@models/event';
import { Location } from '@models/location';
import { ModelElement } from '@models/model-element';
import { Site } from '@models/site';
import { SiteUser } from '@models/site-user';
import { Tag } from '@models/tag';
import { Task } from '@models/task';
import { SyncItem } from '@models/synchronization/sync-item';
import { SubsetTask } from '@models/subset-task';

export type SyncObjectType =
  | 'Asset'
  | 'Contractor'
  | 'Location'
  | 'Event'
  | 'Location'
  | 'Site'
  | 'SiteUser'
  | 'Tag'
  | 'Task'
  | 'TaskNewApi'
  | 'SiteForm'
  | 'EventV4'
  | 'SubsetTask'
  | 'Space'
;

export class Actions {
  CREATE: SyncItem[];
  UPDATE: SyncItem[];
  DELETE: SyncItem[];
}

export class ActionsFinalItem {
  CREATE: ModelElement[];
  UPDATE: ModelElement[];
  DELETE: ModelElement[];
}

interface SyncClass {
  new(): ModelElement;
  toModel(SyncItem, ModelElement): void;
}

@Injectable({
  providedIn: 'root'
})
export class SyncObjectFactory {

  constructor() { }

  private readonly nameToClassMap = new Map<SyncObjectType, SyncClass>([
    ['Asset', Asset],
    ['Contractor', Contractor],
    ['Location', Location],
    ['Event', Event],
    ['EventV4', Event],
    ['Location', Location],
    ['Site', Site],
    ['SiteUser', SiteUser],
    ['Tag', Tag],
    ['Task', Task],
    ['TaskNewApi', Task],
    ['SiteForm', CustomEventForm],
    ['SubsetTask', SubsetTask]
  ]);

  /**
   * Create an object in its frontend version from the parsed DTO
   * @param item Object coming from the backend (DTO format) to be converted to a usable model (frontend format)
   * @param typeName Name of the expected class for the created object
   * @return Converted object (frontend format)
   */
  public create(item: SyncItem, typeName: SyncObjectType): ModelElement {
    const clazz = this.nameToClassMap.get(typeName);
    const newItem = new clazz();
    clazz.toModel(item, newItem);
    return newItem;
  }

}
