import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class OfflineWeatherErrorService {
  private isCoordinatesAvailable = new BehaviorSubject<boolean>(false);
  private isSiteCoordinatesAvailable = new BehaviorSubject<boolean>(false);
  currentCoordinates = this.isCoordinatesAvailable.asObservable();
  currentSiteCoordinates = this.isSiteCoordinatesAvailable.asObservable();
  constructor() {}

  updateSiteCoordinatesAvailable(value: boolean) {
    this.isSiteCoordinatesAvailable.next(value);
  }

  updateCoordinatesAvailable(value: boolean) {
    this.isCoordinatesAvailable.next(value);
  }

}
