import { Author } from './author';
import { TaskAsset } from './task-asset';
import { User } from './user';
import { TaskTag } from './task-tag';
import { TaskContractor } from './task-contractor';
import { TaskUser } from './task-user';
import { SiteOwnedModelElement } from './site-owned-model-element';
import { ModelElement } from './model-element';
import { Location } from '@models/location';
import { TaskRelatedEvent } from './tasks-related-events';
import { LinkedTask } from './linked-tasks';
import { SubsetTask } from './subset-task';
import { MinimalSubtask } from './minimal-subtask';
import { TaskStateFilterValue } from './enums/task-state-indicator.enum';

export enum taskNumberPrefix {
  TASK_PREFIX = 'T',
  SUBTASK_PREFIX = 'ST'
}

export enum TaskTypes {
  PARENT_TASKS = 'PARENT_TASKS',
  SUBTASKS = 'SUBTASKS',
  PREDECESSOR_TASKS = 'PREDECESSOR_TASKS',
  SUCCESSOR_TASKS = 'SUCCESSOR_TASKS'
}

export enum linkedTaskTypes {
  PREDECESSORS = 'Predecessors',
  SUCCESSORS = 'Successors',
}

export enum linkedTasksString {
  LINKEDTASKS = 'linkedTasks'
}

export interface linkedTasks {
  'Predecessors': LinkedTask[],
  'Successors': LinkedTask[]
}
export interface linkedTasksArray {
  'Predecessors': string[],
  'Successors': string[]
}

export enum TASK_STATES {
  all,
  inProgress,
  notStarted,
  done,
  suspended,
  overdue
}

export enum TASK_STATUS {
  PLANNED,
  INPROGRESS,
  COMPLETED,
  CANCELLED
}

export type DashboardTasksCount = {
  TotalTasksEntries: number | string;
  Planned: number | string;
  InProgress: number | string;
  Completed: number | string;
  Cancelled: number | string;
}

export enum TASK_INDICATOR {
  READY_TO_START,
  DELAYED,
  OVERDUE,
  PENDING_APPROVAL
}

export enum QUANTITY_UNIT_IN_TASKS {
  CUM = "CUM", // will be displayed as m3 instead of CUM in the UI.
  SQM= "SQM",
  T = "T",
  KM = "KM",
  M = "M",
  L = "L",
  KG = "KG",
  PIECES = "PIECES"
}

export class TaskState {
  name: string;
  id: number | string;
  active: boolean;
  value?: TASK_STATES;
  color?: string;
  itemName?: string;
}

export class TaskStatus {
  name: string;
  id: number | string;
  active: boolean;
  value?: TASK_STATUS;
  color?: string;
  itemName?: string;
}

export class NewTaskIndicator {
  name: string;
  id: number | string;
  active: boolean;
  value?: TASK_INDICATOR;
  color?: string;
  itemName?: string;
}

export class Task implements SiteOwnedModelElement {

  public static STATES: TaskState[] = [
    { name: 'tasks.list.allTasks', id: 0, active: true, value: TASK_STATES.all },
    { name: 'tasks.list.inprogress', id: 1, active: false, value: TASK_STATES.inProgress },
    { name: 'tasks.list.notStarted', id: 2, active: false, value: TASK_STATES.notStarted },
    { name: 'tasks.list.completed', id: 3, active: false, value: TASK_STATES.done },
    { name: 'tasks.list.suspended', id: 4, active: false, value: TASK_STATES.suspended },
    { name: 'tasks.list.overdue', id: 5, active: false, value: TASK_STATES.overdue },
  ];

  public static STATUS: TaskStatus[] = [
    { name: 'tasks.list.planned', id: 0, active: false, value: TASK_STATUS.PLANNED },
    { name: 'tasks.list.inprogress', id: 1, active: false, value: TASK_STATUS.INPROGRESS },
    { name: 'tasks.list.completed', id: 2, active: false, value: TASK_STATUS.COMPLETED },
    { name: 'tasks.list.cancelled', id: 3, active: false, value: TASK_STATUS.CANCELLED },
  ]
  public static INDICATOR: NewTaskIndicator[] = [
    { name: 'tasks.list.readyToStart', id: 0, active: false, value: TASK_INDICATOR.READY_TO_START },
    { name: 'tasks.list.delayed', id: 1, active: false, value: TASK_INDICATOR.DELAYED },
    { name: 'tasks.list.overdue', id: 2, active: false, value: TASK_INDICATOR.OVERDUE },
    { name: 'tasks.list.pendingApproval', id: 3, active: false, value: TASK_INDICATOR.PENDING_APPROVAL },
  ]

  public static DEFAULT_STATE = Task.STATUS[0];

  id: string;
  parentTaskId: string;
  taskNumber: number;
  revision: string;
  title: string;
  tags: TaskTag[];
  description: string;
  startDatetime: Date;
  endDatetime: Date;
  createdOn: Date;
  createdBy: Author;
  assets: TaskAsset[];
  siteId: string;
  contractors: TaskContractor[];
  users: TaskUser[];
  lastKnownProgress: number;
  suspended: boolean;
  states: TASK_STATES[];
  mostRecentEventId: string;
  locationObject: Location;
  plannedQuantity: number;
  taskStatus: TASK_STATUS;
  taskIndicator: TASK_INDICATOR;
  totalQuantityDone: number;
  quantityUnit: string;
  isApproved: boolean;
  isReadyToStart: boolean;
  isCancelled: boolean;
  linkedTasks: linkedTasksArray;
  relatedEvents?: TaskRelatedEvent[];
  approvedOn: Date;
  approvedBy: Author;
  linkedPredecessors: LinkedTask[];
  linkedSuccessors: LinkedTask[];
  minimalSubtasks: MinimalSubtask[];
  minimalParentTask: LinkedTask;
  subtaskIds: string[];
  isProgressFromSubtasks: boolean;

  constructor() {
    this.id = ModelElement.generateId();
    this.parentTaskId = null;
    this.taskNumber = null;
    this.title = '';
    this.startDatetime = new Date();
    this.endDatetime = new Date();
    this.siteId = '';
    this.description = '';
    this.createdOn = new Date();
    this.createdBy = new Author();
    this.assets = [];
    this.users = [];
    this.contractors = [];
    this.tags = [];
    this.lastKnownProgress = 0;
    this.suspended = false;
    this.states = [];
    this.mostRecentEventId = undefined;
    this.locationObject = new Location();
    this.plannedQuantity = null;
    this.taskStatus = TASK_STATUS.PLANNED;
    this.taskIndicator = null;
    this.totalQuantityDone = null;
    this.quantityUnit = null;
    this.isApproved = false;
    this.isReadyToStart = false;
    this.isCancelled = false;
    this.linkedTasks = {Predecessors: [], Successors: []};
    this.approvedOn = null;
    this.approvedBy = new Author();
    this.linkedPredecessors = [];
    this.linkedSuccessors = [];
    this.minimalSubtasks = [];
    this.subtaskIds = [];
    this.isProgressFromSubtasks = false;
    this.minimalParentTask = null;
  }

  public static toModel(json: any, model: Task) {
    model.id = json.payload.id;
    model.parentTaskId = json.payload.parentTaskId;
    model.taskNumber = json.payload.taskNumber;
    model.siteId = json.payload.siteId;
    model.title = json.payload.title;
    model.startDatetime = json.payload.startDatetime;
    model.endDatetime = json.payload.endDatetime;
    model.createdOn = json.payload.createdOn;
    Author.toModel(json.payload.createdBy, model.createdBy);
    model.users = TaskUser.jsonToTaskUsers(json.payload.users);
    model.assets = TaskAsset.jsonToTaskAssets(json.payload.assets);
    model.tags = TaskTag.jsonToTaskTags(json.payload.tags);
    model.contractors = TaskContractor.jsonToTaskContractors(json.payload.contractors);
    model.description = json.payload.description;
    model.locationObject = json.payload.locationObject;
    model.plannedQuantity = json.payload.plannedQuantity;
    model.quantityUnit = json.payload.quantityUnit;
    model.isApproved = json.payload.approved;
    model.isReadyToStart = json.payload.readyToStart;
    model.isCancelled = json.payload.cancelled;
    this.convertDTOToModel(json, model);
    model.approvedOn = json.payload.approvedOn;
    Author.toModel(json.payload.approvedBy, model.approvedBy);
    model.subtaskIds = json.payload.subtasks;
    model.isProgressFromSubtasks = json.payload.isProgressFromSubtasks;
  }


  updateTaskProgressAndState(mostRecentEvent: TaskRelatedEvent, totalQuantityDone?: number): void {
    this.states = [];
    if (mostRecentEvent && mostRecentEvent !== null) {
      this.lastKnownProgress = mostRecentEvent.progress;
      this.mostRecentEventId = mostRecentEvent.eventId;
    }
    if (mostRecentEvent === null) {
      this.lastKnownProgress = 0;
      this.mostRecentEventId = undefined;
    }
    if (this.plannedQuantity > 0 && totalQuantityDone !== null) {
      this.lastKnownProgress = Math.floor((totalQuantityDone/this.plannedQuantity) * 100);
      this.totalQuantityDone = totalQuantityDone;
    }
    this.updateTaskStatusAndIndicator();
  }
  
  getAverageTotalProgress(subtasks: MinimalSubtask[]): number {
    let totalProgress = 0;
    let numberOfSubtasks = subtasks.length;
    if(numberOfSubtasks === 0) {
      return 0;
    }
    for (let i = 0; i < numberOfSubtasks; i++) {
      totalProgress += subtasks[i].progress;
    }
    return Math.floor(totalProgress / numberOfSubtasks);
  }

  updateTaskProgressAndStateFromSubtasks(subtasks: MinimalSubtask[]): void {
    this.states = [];
    // filter cancelled subtasks
    subtasks = subtasks.filter(subtask => subtask.state !== TaskStateFilterValue.CANCELLED);
    if (subtasks.length !== 0) {
      this.lastKnownProgress = this.getAverageTotalProgress(subtasks);
    } else {
      this.lastKnownProgress = 0;
    }
    this.updateTaskStatusAndIndicator();
  }

  updateTaskStatusAndIndicator(): void {
    const today = new Date();
    // New task status and indicator section

    this.taskStatus = TASK_STATUS.PLANNED;
    this.taskIndicator = null;

    // READY TO START: Supervisor indicates when a task is ready to start.
    if(this.isReadyToStart) {
      this.taskIndicator = TASK_INDICATOR.READY_TO_START;
    }

    // DELAYED: If the start date has passed and the work hasn’t begun.
    if((!this.lastKnownProgress || this.lastKnownProgress === 0 ) && today > this.startDatetime) {
      this.taskIndicator = TASK_INDICATOR.DELAYED;
    }

    // IN PROGRESS: Task is between 0% and 100% progress and is on-going.
    if((this.lastKnownProgress > 0 && this.lastKnownProgress <= 100) && !this.isApproved && !(this.suspended || this.isCancelled)) {
      this.taskStatus = TASK_STATUS.INPROGRESS;
    }

    // OVERDUE: Tasks that have passed their end date and progress is still not 100%.
    if((this.lastKnownProgress > 0 && this.lastKnownProgress < 100 && today > this.endDatetime)) {
      this.taskIndicator = TASK_INDICATOR.OVERDUE;
    }

    // PENDING APPROVAL: Tasks that have 100% progress.
    if((this.lastKnownProgress === 100) && !this.isApproved && !(this.suspended || this.isCancelled)) {
      this.taskIndicator = TASK_INDICATOR.PENDING_APPROVAL;
    }

    // COMPLETED: Tasks that are approved are made completed. Tasks need not have 100% progress to be approved.
    if(this.isApproved) {
      this.taskStatus = TASK_STATUS.COMPLETED;
      this.taskIndicator = null;
    }

    // CANCELLED: Tasks that are cancelled/suspended.
    if(this.suspended || this.isCancelled) {
      this.taskStatus = TASK_STATUS.CANCELLED;
      this.taskIndicator = null;
    }
  }

  duplicate(): Task {
    const newTask = Object.assign(new Task(), this);
    newTask.id = ModelElement.generateId();
    newTask.assets = this.assets.map(taskAsset => Object.assign(new TaskAsset(), taskAsset));
    newTask.contractors = this.contractors.map(taskContractor => Object.assign(new TaskContractor(), taskContractor));
    newTask.tags = this.tags.map(taskTag => Object.assign(new TaskTag(), taskTag));

    if(newTask.plannedQuantity !== null) {
      newTask.totalQuantityDone = 0;
    }

    return newTask;
  }

  /**
   * Convert frontend model to backend model
   */
  toDTO() {
    return {
      id: this.id,
      parentTaskId: this.parentTaskId,
      siteId: this.siteId,
      assets: TaskAsset.taskAssetsToDto(this.assets),
      contractors: TaskContractor.taskContractorsToDto(this.contractors),
      tags: TaskTag.taskTagsToDto(this.tags),
      // TODO: deal with comments in the app
      title: this.title,
      startDatetime: this.startDatetime instanceof Date ? this.startDatetime.getTime() : this.startDatetime,
      endDatetime: this.endDatetime instanceof Date ? this.endDatetime.getTime() : this.endDatetime,
      description: this.description,
      createdOn: this.createdOn instanceof Date ? this.createdOn.getTime() : this.createdOn,
      users: TaskUser.taskUsersToDto(this.users),
      locationObject: this.locationObject,
      plannedQuantity: this.plannedQuantity,
      quantityUnit: this.quantityUnit,
      approved: this.isApproved,
      readyToStart: this.isReadyToStart,
      cancelled: this.isCancelled,
      predecessors: this.linkedTasks.Predecessors,
      successors: this.linkedTasks.Successors,
      approvedOn: this.approvedOn instanceof Date ? this.approvedOn.getTime() : this.approvedOn,
      subtasks: this.subtaskIds,
      isProgressFromSubtasks: this.isProgressFromSubtasks,
      /**
       * Backend is responsible for setting  createdBy and updatedBy
       * Data sent from frontend will not be taken into account
       */
    };
  }

  getCurrentTaskStateText(): string {
    return Task.STATUS[this.taskStatus].name;
  }

  getCurrentTaskStatusOrIndicatorText(isIndicator: boolean): string {
    if (this.taskStatus && !isIndicator) {
      const statusId = this.taskStatus;
      if (statusId >= 0 && statusId < Task.STATUS.length) {
        return Task.STATUS[statusId].name;
      }
      return Task.STATUS[0].name;
    }
    if (this.taskIndicator && isIndicator) {
      const indicatorId = this.taskIndicator;
      if (indicatorId >= 0 && indicatorId < Task.INDICATOR.length) {
        return Task.INDICATOR[indicatorId].name;
      }
      return '';
    } else if (!this.taskIndicator && isIndicator) {
      return '';
    }
    return Task.STATUS[0].name;
  }

  getCurrentTaskProgress(): number {
    if(this.plannedQuantity > 0) {
      return (this.lastKnownProgress) ? Math.floor(this.lastKnownProgress) : 0;
    }
    return (this.lastKnownProgress) ? Math.floor(this.lastKnownProgress) : 0;
  }

  isAssignedTo(user: User) {
    if (user && this.users) {
      for (const assignedUser of this.users) {
        if (user.id === assignedUser.userId) {
          return true;
        }
      }
    }
    return false;
  }

  getCurrentTaskStatusOrIndicator(task: Task): TaskStatus[] | NewTaskIndicator[] {

    // If task status is 'planned' task indicator can be either 'ready to start' or 'delayed' or 'overdue'
    // depending on the heirarchy return either the status or the indicator
    if (task.taskStatus === TASK_STATUS.PLANNED) {
      if (task.taskIndicator === null) {
        return Task.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
      } else if (task.taskIndicator === TASK_INDICATOR.READY_TO_START) {
        return Task.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.READY_TO_START);
      } else if (task.taskIndicator === TASK_INDICATOR.DELAYED) {
        return Task.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.DELAYED);
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return Task.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.OVERDUE);
      } else {
        return Task.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
      }
    }

    // If task status is 'in progress' task indicator can be either 'overdue' or 'pending for approval'
    // depending on the heirarchy return either the status or the indicator
    if (task.taskStatus === TASK_STATUS.INPROGRESS) {
      if (task.taskIndicator !== TASK_INDICATOR.OVERDUE && task.taskIndicator !== TASK_INDICATOR.PENDING_APPROVAL) {
        return Task.STATUS.filter(status => status.value === TASK_STATUS.INPROGRESS);
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return Task.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.OVERDUE);
      } else if (task.taskIndicator === TASK_INDICATOR.PENDING_APPROVAL) {
        return Task.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.PENDING_APPROVAL);
      } else {
        return Task.STATUS.filter(status => status.value === TASK_STATUS.INPROGRESS);
      }
    }

    // If task status is 'completed' return completed status
    if (task.taskStatus === TASK_STATUS.COMPLETED) {
      return Task.STATUS.filter(status => status.value === TASK_STATUS.COMPLETED);
    }

    // If task status is 'cancelled' return cancelled status
    if (task.taskStatus === TASK_STATUS.CANCELLED) {
      return Task.STATUS.filter(status => status.value === TASK_STATUS.CANCELLED);
    }
    return Task.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
  }

  public get label(): string {
    return this.title;
  }

  static convertDTOToModel(json: any, model: Task): void {
    model.linkedTasks = {
      Predecessors: json.payload.predecessors,
      Successors: json.payload.successors
    }
  }

  public static singleTaskToModel(json: any, model: Task) {
    model.id = json.id;
    model.parentTaskId = json.parentTaskId;
    model.taskNumber = json.taskNumber;
    model.siteId = json.siteId;
    model.title = json.title;
    model.startDatetime = json.startDatetime;
    model.endDatetime = json.endDatetime;
    model.createdOn = json.createdOn;
    Author.toModel(json.createdBy, model.createdBy);
    model.users = TaskUser.jsonToTaskUsers(json.users);
    model.assets = TaskAsset.jsonToTaskAssets(json.assets);
    model.tags = TaskTag.jsonToTaskTags(json.tags);
    model.contractors = TaskContractor.jsonToTaskContractors(json.contractors);
    model.description = json.description;
    model.locationObject = json.locationObject;
    model.plannedQuantity = json.plannedQuantity;
    model.quantityUnit = json.quantityUnit;
    model.isApproved = json.approved;
    model.isReadyToStart = json.readyToStart;
    model.isCancelled = json.cancelled;
    model.relatedEvents = TaskRelatedEvent.jsonToTaskRelatedEvents(json.relatedEvents);
    model.approvedOn = json.approvedOn;
    Author.toModel(json.approvedBy, model.approvedBy);
    model.isProgressFromSubtasks = json.isProgressFromSubtasks;
    model.subtaskIds = json.minimalSubtasks.map(task => task.id);
    model.linkedPredecessors = LinkedTask.jsonTolinkedTasks(json.detailedPredecessors);
    model.linkedSuccessors = LinkedTask.jsonTolinkedTasks(json.detailedSuccessors);
    this.convertDTOToLinkedTasksModel(json, model);
    model.minimalSubtasks = MinimalSubtask.jsonToFilteredTasks(json);
    model.minimalParentTask = LinkedTask.jsonToLinkedParentTask(json.minimalParentTask);
  }

  static convertDTOToLinkedTasksModel(json: any, model: Task): void {
    let predecessorTasksId = json.detailedPredecessors.map(task => task.id);
    let successorTasksId = json.detailedSuccessors.map(task => task.id);
    model.linkedTasks = {
      Predecessors: predecessorTasksId,
      Successors: successorTasksId
    }
  }
}
