import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe, UnaryFunction } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';

type PostState = 'success' | 'error' | 'in-progress' | 'stopped';

export enum PostStatusStates {
  success = 'success',
  error = 'error',
  inProgress = 'in-progress',
  stopped = 'stopped'
}
export class AssetPostStatus {
  constructor(
    public status: PostState = PostStatusStates.stopped,
    public assetId?: string,
  ) {}
}

export class InformationPostStatus {
  constructor(
    public status: PostState = PostStatusStates.stopped,
    public informationId?: string,
  ) {}
}

export class PostSyncStatus {
  constructor(
    public status: PostState = PostStatusStates.stopped,
    public siteId?: string,
  ) {}
}

const waitForEndOfSync: () => UnaryFunction<Observable<AssetPostStatus>, Observable<void>> = () => pipe(
  first(state => state.status !== PostStatusStates.inProgress),
  mapTo(undefined),
);

@Injectable({
  providedIn: 'root'
})
export class PostStatusService {

  private assetPostStatus$ = new BehaviorSubject<AssetPostStatus>(new AssetPostStatus());
  private informationPostStatus$ = new BehaviorSubject<InformationPostStatus>(new InformationPostStatus());
  private postStatus$ = new BehaviorSubject<PostSyncStatus>(new PostSyncStatus());
  constructor() { }

  public communicateAssetPostStatus(status: PostState, assetId?: string): void {
    this.assetPostStatus$.next(new AssetPostStatus(status, assetId));
  }

  public waitForEndOfAssetPost(): Promise<void> {
    return this.assetPostStatus$.pipe(waitForEndOfSync()).toPromise();
  }

  public communicateInformationPostStatus(status: PostState, informationId?: string): void {
    this.informationPostStatus$.next(new InformationPostStatus(status, informationId));
  }

  public waitForEndOfInformationPost(): Promise<void> {
    return this.informationPostStatus$.pipe(waitForEndOfSync()).toPromise();
  }

  public communicatePostSyncStatus(status: PostState, siteId?:string): void {
    this.postStatus$.next(new PostSyncStatus(status, siteId));
  }

  public waitForEndOfPostSync(): Promise<void> {
    return this.postStatus$.pipe(waitForEndOfSync()).toPromise();
  }

  public getPostStatus(): BehaviorSubject<PostSyncStatus> {
    return this.postStatus$;
  }

  public getAssetPostStatus(): BehaviorSubject<AssetPostStatus> {
    return this.assetPostStatus$;
  }

  public getInformationPostStatus(): BehaviorSubject<InformationPostStatus> {
    return this.informationPostStatus$;
  }
}
