import { UserRightsService } from '../../../services/user-rights/user-rights.service';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TagService } from '../../../services/tag.service';
import { Tag } from '../../../models/tag';
import { ContractorService } from '../../../services/contractor.service';
import { Contractor } from '../../../models/contractor';
import { EventsFilters } from '../../../models/events-filters';
import { EventService } from '../../../services/event.service';
import { SharedService } from '../../../services/shared/shared.service';
import { UserService } from '../../../services/user.service';
import { NetworkStatus } from '../../../models/synchronization/network-status';
import { EventStatus } from '../../../models/event';
import { flatMap, map } from 'rxjs/operators';
import { SiteUser } from '@models/site-user';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import { TaskService } from '@services/task.service';
import { Task } from '@models/task';
import { ModelElement } from '@models/model-element';
import { Labelable } from '@models/labelable';
import { User } from '@models/user';
import { LocationService } from '@services/location.service';
import { Location } from '@models/location';
import { FormatSortService } from '@services/format-sort.service';
import { UtilsService } from '@services/utils.service';


@Component({
  selector: 'app-filters-selector-popup',
  templateUrl: './filters-selector-popup.component.html',
  styleUrls: ['./filters-selector-popup.component.sass']
})
export class FiltersSelectorPopupComponent implements OnInit, OnDestroy {

  private tagSubscription: Subscription;
  private contractorSubscription: Subscription;
  private taskSubscription: Subscription;
  private locationSubscription: Subscription;

  tagsLoaded: boolean = false;
  contractorsLoaded: boolean = false;
  creatorsLoaded: boolean = false;
  tasksLoaded: boolean = false;
  locationsLoaded: boolean = false;

  tags: Tag[];
  contractors: Contractor[];
  tasks: Task[];
  statuses: EventStatus[];
  locations: Location[];
  creators: User[];

  // Filters
  eventsFilters: EventsFilters;

  currentFilter: string;

  resetFiltersFunction: any;
  callMobileFiltersFunction: any;
  filterEvents: any;

  constructor(
    private navParams: NavParams,
    public modalCtrl: ModalController,
    public userRightsService: UserRightsService,
    public tagService: TagService,
    public contractorService: ContractorService,
    public eventService: EventService,
    public sharedService: SharedService,
    private userService: UserService,
    private taskService: TaskService,
    private locationService: LocationService,
  ) { }

  ngOnInit() {
    this.eventsFilters = this.navParams.get('eventsFilters');
    this.resetFiltersFunction = this.navParams.get('resetFiltersFunction');
    this.callMobileFiltersFunction = this.navParams.get('callMobileFiltersFunction');
    this.filterEvents = this.navParams.get('filterEvents') || (() => undefined);
    this.creators = [];
    // tags
    this.tagsLoaded = false;
    this.tagSubscription = this.tagService.items.pipe(
      map(tags => {
        return tags.sort((a, b) => {
          return FormatSortService.compare(a.name, b.name);
        });
      })).subscribe(tags => {
      this.tags = tags;
      this.tagsLoaded = true;
    });
    this.tagService.filterAll();
    // contractors
    this.contractorsLoaded = false;
    this.contractorSubscription = this.contractorService.items.pipe(
      map(contractors => {
        return contractors.sort((a, b) => {
          return FormatSortService.compare(a.name, b.name);
        });
      })).subscribe(contractors => {
      this.contractors = contractors;
      this.contractorsLoaded = true;
    });
    this.contractorService.filterAll();
    // tasks
    this.tasksLoaded = false;
    this.taskSubscription = this.taskService.items.pipe(
      map(tasks => {
        return tasks.sort((a, b) => {
          return FormatSortService.compare(a.title, b.title);
        });
      })).subscribe((tasks) => {
      this.tasks = tasks;
      this.tasksLoaded = true;
    });
    // locations
    this.locationsLoaded = false;
    this.locationSubscription = this.locationService.items.pipe(
      map(locations => {
        return locations.sort((a, b) => {
          return FormatSortService.compare(a.name, b.name);
        });
      })).subscribe(locations => {
      this.locations = locations;
      this.locationsLoaded = true;
    });


    this.sharedService.watchSpaceAndSiteIds.pipe(
      flatMap(([currentSpaceId, currentSiteId]) => {
        if (currentSiteId) {
          return this.userService.getSiteUsers(currentSpaceId, currentSiteId).pipe(
            map(users => {
              // filter the users who doesn't have a valid user id
              users = users.filter(user => user.id !== null);
              return users.sort((a, b) => {
                return FormatSortService.compare(a.lastName, b.lastName);
              });
          }),);
        } else {
          return of(null);
        }
      }),
    ).subscribe((users: SiteUser[]) => {
      if (users) {
        this.creators = users;
        this.creatorsLoaded = true;
      }
    });

    this.statuses = [EventStatus.APPROVED, EventStatus.REJECTED, EventStatus.SUBMITTED, EventStatus.DRAFT];

  }

  ngOnDestroy(): void {
    UtilsService.safeUnsubscribe(this.tagSubscription);
    UtilsService.safeUnsubscribe(this.contractorSubscription);
    UtilsService.safeUnsubscribe(this.taskSubscription);
    UtilsService.safeUnsubscribe(this.locationSubscription);
  }

  resetFilters(): void {
    this.eventsFilters.selectedContractors = [];
    this.eventsFilters.selectedCreators = [];
    this.eventsFilters.selectedLocations = [];
    this.eventsFilters.selectedStatus = [];
    this.eventsFilters.selectedTags = [];
    this.eventsFilters.selectedTasks = [];
    this.resetFiltersFunction();
    this.currentFilter = '';
  }

  updateCurrentTab(tabName: string) {
    if (this.currentFilter === tabName) {
      this.currentFilter = '';
    } else {
      this.currentFilter = tabName;
    }
  }

  toggleStatus(status: EventStatus): void {
    if (this.eventsFilters.selectedStatus.includes(status)) {
      this.eventsFilters.selectedStatus = this.eventsFilters.selectedStatus.filter(selected => selected !== status);
    } else {
      this.eventsFilters.selectedStatus.push(status);
    }
  }
}
