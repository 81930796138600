import { RelatedMinimalEvent } from './event';
import { SiteOwnedModelElement } from './site-owned-model-element';
import { ModelElement } from './model-element';
import { NewTaskIndicator, Task, TaskStatus, TASK_INDICATOR, TASK_STATES, TASK_STATUS } from './task';
import { TaskRelatedEvent } from './tasks-related-events';

export type TasksCount = {
  [key in TASK_STATUS]: number
}

export class SubsetTask implements SiteOwnedModelElement {

  public static STATUS: TaskStatus[] = [
    { name: 'tasks.list.planned', id: 0, active: false, value: TASK_STATUS.PLANNED },
    { name: 'tasks.list.inprogress', id: 1, active: false, value: TASK_STATUS.INPROGRESS },
    { name: 'tasks.list.completed', id: 2, active: false, value: TASK_STATUS.COMPLETED },
    { name: 'tasks.list.cancelled', id: 3, active: false, value: TASK_STATUS.CANCELLED },
  ]
  public static INDICATOR: NewTaskIndicator[] = [
    { name: 'tasks.list.readyToStart', id: 0, active: false, value: TASK_INDICATOR.READY_TO_START },
    { name: 'tasks.list.delayed', id: 1, active: false, value: TASK_INDICATOR.DELAYED },
    { name: 'tasks.list.overdue', id: 2, active: false, value: TASK_INDICATOR.OVERDUE },
    { name: 'tasks.list.pendingApproval', id: 3, active: false, value: TASK_INDICATOR.PENDING_APPROVAL },
  ]

  id: string;
  parentTaskId: string;
  taskNumber: number;
  revision: string;
  title: string;
  startDatetime: Date;
  endDatetime: Date;
  lastKnownProgress: number;
  suspended: boolean;
  states: TASK_STATES[];
  mostRecentEventId: string;
  locationId: string;
  locationName: string;
  plannedQuantity: number;
  taskStatus: TASK_STATUS;
  taskIndicator: TASK_INDICATOR;
  totalQuantityDone: number;
  isApproved: boolean;
  isReadyToStart: boolean;
  isCancelled: boolean;
  relatedMinimalEvents: RelatedMinimalEvent[];
  siteId: string;
  subtasks?: SubsetTask[];
  isProgressFromSubtasks: boolean;

  constructor() {
    this.id = ModelElement.generateId();
    this.parentTaskId = null;
    this.taskNumber = null;
    this.title = '';
    this.startDatetime = new Date();
    this.endDatetime = new Date();
    this.lastKnownProgress = 0;
    this.suspended = false;
    this.states = [];
    this.mostRecentEventId = undefined;
    this.locationId = null;
    this.locationName = null;
    this.plannedQuantity = null;
    this.taskStatus = TASK_STATUS.PLANNED;
    this.taskIndicator = null;
    this.totalQuantityDone = null;
    this.isApproved = false;
    this.isReadyToStart = false;
    this.isCancelled = false;
    this.relatedMinimalEvents = [];
    this.subtasks = [];
    this.isProgressFromSubtasks = false;
  }

  public static toModel(json: any, model: SubsetTask) {
    model.id = json.payload.id;
    model.parentTaskId = json.payload.parentTaskId;
    model.taskNumber = json.payload.taskNumber;
    model.revision = json.payload.revision;
    model.title = json.payload.title;
    model.startDatetime = json.payload.startDatetime;
    model.endDatetime = json.payload.endDatetime;
    model.locationId = json.payload.locationId;
    model.plannedQuantity = json.payload.plannedQuantity;
    model.isApproved = json.payload.isApproved;
    model.isReadyToStart = json.payload.isReadyToStart;
    model.isCancelled = json.payload.isCancelled;
    model.relatedMinimalEvents = json.payload.relatedMinimalEvents;
    model.isProgressFromSubtasks = json.payload.isProgressFromSubtasks;
  }

  updateTaskProgressAndStateFromSubtasks(progress: number): void {
    this.states = [];
    this.lastKnownProgress = progress;
    this.updateTaskStatusAndIndicator();
  }

  updateTaskProgressAndState(mostRecentEvent: RelatedMinimalEvent, totalQuantityDone?: number): void {
    this.states = [];
    if (mostRecentEvent) {
      this.lastKnownProgress = mostRecentEvent.progress;
      this.suspended = mostRecentEvent.isSuspended;
      this.mostRecentEventId = mostRecentEvent.id;
    }
    if (mostRecentEvent === null) {
      this.lastKnownProgress = 0;
      this.mostRecentEventId = null;
    }
    if (this.plannedQuantity > 0 && totalQuantityDone !== null) {
      this.lastKnownProgress = Math.floor((totalQuantityDone/this.plannedQuantity) * 100);
    }
    this.updateTaskStatusAndIndicator();
  }

  updateTaskStatusAndIndicator(): void {
    const today = new Date();

    // New task status and indicator section

    this.taskStatus = TASK_STATUS.PLANNED;
    this.taskIndicator = null;

    // READY TO START: Supervisor indicates when a task is ready to start.
    if(this.isReadyToStart) {
      this.taskIndicator = TASK_INDICATOR.READY_TO_START;
    }

    // DELAYED: If the start date has passed and the work hasn’t begun.
    if((!this.lastKnownProgress || this.lastKnownProgress === 0 ) && today > this.startDatetime) {
      this.taskIndicator = TASK_INDICATOR.DELAYED;
    }

    // IN PROGRESS: Task is between 0% and 100% progress and is on-going.
    if((this.lastKnownProgress > 0 && this.lastKnownProgress <= 100) && !this.isApproved && !(this.suspended || this.isCancelled)) {
      this.taskStatus = TASK_STATUS.INPROGRESS;
    }

    // OVERDUE: Tasks that have passed their end date and progress is still not 100%.
    if((this.lastKnownProgress > 0 && this.lastKnownProgress < 100 && today > this.endDatetime)) {
      this.taskIndicator = TASK_INDICATOR.OVERDUE;
    }

    // PENDING APPROVAL: Tasks that have 100% progress.
    if((this.lastKnownProgress === 100) && !this.isApproved && !(this.suspended || this.isCancelled)) {
      this.taskIndicator = TASK_INDICATOR.PENDING_APPROVAL;
    }

    // COMPLETED: Tasks that are approved are made completed. Tasks need not have 100% progress to be approved.
    if(this.isApproved) {
      this.taskStatus = TASK_STATUS.COMPLETED;
      this.taskIndicator = null;
    }

    // CANCELLED: Tasks that are cancelled/suspended.
    if(this.suspended || this.isCancelled) {
      this.taskStatus = TASK_STATUS.CANCELLED;
      this.taskIndicator = null;
    }
  }

  getCurrentTaskStateText(): string {
    return SubsetTask.STATUS[this.taskStatus].name;
  }

  getCurrentTaskStatusOrIndicatorText(isIndicator: boolean): string {
    if (this.taskStatus && !isIndicator) {
      const statusId = this.taskStatus;
      if (statusId >= 0 && statusId < SubsetTask.STATUS.length) {
        return SubsetTask.STATUS[statusId].name;
      }
      return SubsetTask.STATUS[0].name;
    }
    if (this.taskIndicator && isIndicator) {
      const indicatorId = this.taskIndicator;
      if (indicatorId >= 0 && indicatorId < SubsetTask.INDICATOR.length) {
        return SubsetTask.INDICATOR[indicatorId].name;
      }
      return '';
    } else if (!this.taskIndicator && isIndicator) {
      return '';
    }
    return SubsetTask.STATUS[0].name;
  }

  getCurrentTaskProgress(): number {
    if(this.plannedQuantity > 0) {
      return (this.lastKnownProgress) ? Math.floor(this.lastKnownProgress) : 0;
    }
    return (this.lastKnownProgress) ? Math.floor(this.lastKnownProgress) : 0;
  }

  getCurrentTaskStatusOrIndicator(task: SubsetTask): TaskStatus[] | NewTaskIndicator[] {

    // If task status is 'planned' task indicator can be either 'ready to start' or 'delayed' or 'overdue'
    // depending on the hierarchy return either the status or the indicator
    if (task.taskStatus === TASK_STATUS.PLANNED) {
      if (task.taskIndicator === null) {
        return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
      } else if (task.taskIndicator === TASK_INDICATOR.READY_TO_START) {
        return SubsetTask.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.READY_TO_START);
      } else if (task.taskIndicator === TASK_INDICATOR.DELAYED) {
        return SubsetTask.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.DELAYED);
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return SubsetTask.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.OVERDUE);
      } else {
        return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
      }
    }

    // If task status is 'in progress' task indicator can be either 'overdue' or 'pending for approval'
    // depending on the hierarchy return either the status or the indicator
    if (task.taskStatus === TASK_STATUS.INPROGRESS) {
      if (task.taskIndicator !== TASK_INDICATOR.OVERDUE && task.taskIndicator !== TASK_INDICATOR.PENDING_APPROVAL) {
        return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.INPROGRESS);
      } else if (task.taskIndicator === TASK_INDICATOR.OVERDUE) {
        return SubsetTask.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.OVERDUE);
      } else if (task.taskIndicator === TASK_INDICATOR.PENDING_APPROVAL) {
        return SubsetTask.INDICATOR.filter(indicator => indicator.value === TASK_INDICATOR.PENDING_APPROVAL);
      } else {
        return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.INPROGRESS);
      }
    }

    // If task status is 'completed' return completed status
    if (task.taskStatus === TASK_STATUS.COMPLETED) {
      return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.COMPLETED);
    }

    // If task status is 'cancelled' return cancelled status
    if (task.taskStatus === TASK_STATUS.CANCELLED) {
      return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.CANCELLED);
    }
    return SubsetTask.STATUS.filter(status => status.value === TASK_STATUS.PLANNED);
  }

  public get label(): string {
    return this.title;
  }

  public static taskToSubsetTaskModel(task: Task, model: SubsetTask): void {
    model.id = task.id;
    model.parentTaskId = task.parentTaskId;
    model.taskNumber = task.taskNumber;
    model.revision = task.revision;
    model.title = task.title;
    model.startDatetime = task.startDatetime;
    model.endDatetime = task.endDatetime;
    if (task.locationObject && task.locationObject !== null) {
      model.locationId = task.locationObject.id;
    }
    model.plannedQuantity = task.plannedQuantity;
    model.isApproved = task.isApproved;
    model.isReadyToStart = task.isReadyToStart;
    model.isCancelled = task.isCancelled;
    if (task.relatedEvents && task.relatedEvents.length > 0) {
      model.relatedMinimalEvents = TaskRelatedEvent.taskRelatedEventsToRelatedMinimalEvents(task.relatedEvents, task.id);
    }
    model.isProgressFromSubtasks = task.isProgressFromSubtasks;
  }

  public static mapSubtasksToParentTask(subsetTasks: SubsetTask[]): SubsetTask[] {
    const taskMap: { [key: string]: SubsetTask } = {};

    for (const task of subsetTasks) {
      let subsetTask = this.mapSubsetTaskToNewSubsetTaskObject(task);
      taskMap[task.id] = subsetTask;
    }

    for (const subsetTaskCopy of subsetTasks) {
      let subsetTask = this.mapSubsetTaskToNewSubsetTaskObject(subsetTaskCopy);
      const parentId = subsetTask.parentTaskId;
      if (parentId && taskMap[parentId]) {
        if(!subsetTask.isCancelled) {
          if(taskMap[parentId].subtasks.find((subtask) => subtask.id === subsetTask.id)) {
            taskMap[parentId].subtasks = taskMap[parentId].subtasks.map((subtask) => {
              return subtask.id === subsetTask.id ? subsetTask : subtask;
            });
          } else {
            taskMap[parentId].subtasks.push(subsetTask);
          }
        }
      }
    }
    return subsetTasks.map((subsetTask: SubsetTask) => taskMap[subsetTask.id]);
  }

  // convert subset task json object to new subset task
  // since we converted the task model to json string for avoiding shallow copy issues.
  // need to convert the json string value to the object model.
  public static mapSubsetTaskToNewSubsetTaskObject(subsetTaskCopy: SubsetTask): SubsetTask {
    let subsetTask = new SubsetTask();
    subsetTask.id = subsetTaskCopy.id;
    subsetTask.parentTaskId = subsetTaskCopy.parentTaskId;
    subsetTask.taskNumber = subsetTaskCopy.taskNumber;
    subsetTask.revision = subsetTaskCopy.revision;
    subsetTask.title = subsetTaskCopy.title;
    subsetTask.startDatetime = subsetTaskCopy.startDatetime;
    subsetTask.endDatetime = subsetTaskCopy.endDatetime;
    subsetTask.locationId = subsetTaskCopy.locationId;
    subsetTask.plannedQuantity = subsetTaskCopy.plannedQuantity;
    subsetTask.isApproved = subsetTaskCopy.isApproved;
    subsetTask.isReadyToStart = subsetTaskCopy.isReadyToStart;
    subsetTask.isCancelled = subsetTaskCopy.isCancelled;
    subsetTask.relatedMinimalEvents = SubsetTask.mapRelatedMinimalEventsJsonObjectToRelatedMinimalEvents(subsetTaskCopy.relatedMinimalEvents);
    subsetTask.isProgressFromSubtasks = subsetTaskCopy.isProgressFromSubtasks;
    return subsetTask;
  }

  // convert related events json object to subset task related minimal events
  // since we converted the task model to json string for avoiding shallow copy issues.
  // need to convert the json string value to the object model.
  public static mapRelatedMinimalEventsJsonObjectToRelatedMinimalEvents(RelatedMinimalEvents: RelatedMinimalEvent[]): RelatedMinimalEvent[] {
    return RelatedMinimalEvents.map(relatedMinimalEventCopy => {
      let relatedMinimalEvent: RelatedMinimalEvent  = {}  as RelatedMinimalEvent;
      relatedMinimalEvent.id = relatedMinimalEventCopy.id;
      relatedMinimalEvent.taskId = relatedMinimalEventCopy.taskId;
      relatedMinimalEvent.startDatetime = relatedMinimalEventCopy.startDatetime;
      relatedMinimalEvent.endDatetime = relatedMinimalEventCopy.endDatetime;
      relatedMinimalEvent.progress = relatedMinimalEventCopy.progress;
      relatedMinimalEvent.quantityDone = relatedMinimalEventCopy.quantityDone;
      relatedMinimalEvent.isSuspended = relatedMinimalEventCopy.isSuspended;
      return relatedMinimalEvent;
    });
  }

  toDTO() {}

}
