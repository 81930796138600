import { Injectable } from '@angular/core';
import { SpaceDatabase } from '../../models/db/space-database';
import { UserDatabase } from '../../models/db/user-database';
import { QueueService } from '../synchronization/queue.service';
import { BehaviorSubject, Observable, ReplaySubject, throwError, combineLatest } from 'rxjs';
import { SessionService } from '../session.service';
import { first, map, filter, flatMap } from 'rxjs/operators';
import { AlertBox, AlertLevel, ToasterService } from '../toaster.service';
import { SyncSequence } from '../../models/synchronization/sync-sequence';
import { Site } from '@models/site';
import { Tag } from '@models/tag';
import { Contractor } from '@models/contractor';
import { Currency } from '@models/utils/currency';
import { Task } from '@models/task';
import { Event } from '@models/event';
import { Asset, AssetCategory } from '@models/asset';
import { Location } from '@models/location';
import Dexie from 'dexie';
import { SyncStatusService } from '@services/synchronization/sync-status.service';
import { SpinnerService } from '@services/spinner.service';
import { ActionType } from '@models/synchronization/sync-dto';
import { Attachment } from '@models/attachment';
import { QueueAction } from '@models/synchronization/queue-action';
import { DeviceService } from '@services/device.service';
import { CustomEventForm } from '@models/custom-event-form';
import { ErrorQueueAction } from '@models/synchronization/error-queue-action';
import { captureMessage } from '@sentry/angular';

const MAX_EVENTS_LIMIT_IN_MOBILE = 50;

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  public itemsType: 'tag' | 'asset' | 'contractor' | 'location' | 'site' | 'event' | 'currency' | 'task' | 'TaskNewApi' | 'SiteForm' | 'eventV4';
  public needFirstSync: BehaviorSubject <boolean> = new BehaviorSubject<boolean>(false);
  private spaceDatabase$ = new BehaviorSubject<SpaceDatabase>(null);
  private nonNullSpaceDatabase = this.spaceDatabase$.pipe(
    filter(db => db != null),
    first(),
  );
  private userDatabase$ = new ReplaySubject<UserDatabase>(1);

  constructor(
    private sessionService: SessionService,
    private toasterService: ToasterService,
    private syncStatusService: SyncStatusService,
    private spinnerService: SpinnerService,
    private deviceService: DeviceService,
  ) {
    this.sessionService.watchSession().subscribe(user => {
      if (user && user.userId) {
        this.userDatabase$.next(new UserDatabase(user.userId, deviceService.isMobile));
        this.evaluateUserLogRecordsCount();
      } else {
        this.userDatabase$.next(null);
      }
    });
  }

  /**
   * /!\ Should only be called by the SharedService
   * @protected
   */
  public async initDB(spaceId: string, queueService: QueueService): Promise<void> {
    if (spaceId) {
      const userDB = await this.getUserDB();
      if (!await Dexie.exists('SPACE' + '/' + this.sessionService.getCurrentUser().id + '/' + spaceId)) {
        this.spinnerService.activate('rotating', 'sync.loading-data');
      }
      const db = new SpaceDatabase(userDB.userId, spaceId, queueService, this.deviceService.isMobile);
      this.spaceDatabase$.next(db);
    } else {
      this.logItemToLogsDB('Resetting space database.', 'INFO');
      this.spaceDatabase$.next(null);
    }
  }

  getUserDB(): Promise<UserDatabase> {
    return this.userDatabase$.asObservable().pipe(
      first(),
      map(db => {
        if (db) {
          return db;
        }
        throwError('The current user database is null.');
        return null;
      })
    ).toPromise();
  }

  /**
   * @deprecated
   */
  public getDB(): SpaceDatabase | null {
    return this.spaceDatabase$.getValue();
  }

  /**
   * @returns The database if there is one currently active and an error if there is none
   */
  public getDBInstant(): Promise<SpaceDatabase> {
    return this.nonNullSpaceDatabase.pipe(first()).toPromise();
  }

  /**
   * @returns An observable with the current database, when a user has no space selected, the observable emits null
   */
  public watchDB(): Observable<SpaceDatabase | null> {
    return this.spaceDatabase$.asObservable();
  }

  /**
   * Method to manually set the database flag with value 'corrupted'
   * @param spaceId
   * @param forceReload used to display error alertbox if false (i.e launch automatically)
   * @param siteId
   */
  public flagDatabaseAsCorrupted(spaceId: string, forceReload: boolean, siteId?: string) {
    this.logItemToLogsDB(`The database '${spaceId}/${siteId}' is being flagged as corrupted...`, 'ERROR');
    this.getUserDB().then(userDB => {
      userDB.transaction('rw', userDB.sequence, () => {
        userDB.sequence.where({ spaceId: spaceId }).toArray().then(syncSequences => {
          for (const syncSequence of syncSequences) {
            syncSequence.sequence = SyncSequence.DATABASE_FLAG_CORRUPTED;
            userDB.sequence.put(syncSequence);
          }
        });
      }).then(() => {
        if (!forceReload) {
          this.logItemToLogsDB(`The database '${spaceId}/${siteId}' has been flagged as corrupted.`, 'ERROR');
          this.toasterService.showAlertBox(new AlertBox(
            'database-corrupted',
            AlertLevel.error,
            'alertbox.sync-error',
            false,
            true
          ));
          this.toasterService.showToaster(AlertLevel.error, 'error.corrupted_data');
        }
      });
    });
  }

  async clearDatabase(spaceId: string, siteId?: string): Promise<void> {
    this.logItemToLogsDB(`The database '${spaceId}/${siteId}' is being cleared...`, 'INFO');
    return Promise.all([
      this.getDBInstant()
        .catch(() => null)
        .then(db => {
          if (db && db.spaceId === spaceId) {
            return db.clear();
          } else {
            return this.getUserDB()
              .then(userDB => new SpaceDatabase(userDB.userId, spaceId, null, this.deviceService.isMobile))
              .then(dbToClear => dbToClear.clear());
          }
        }),
      this.getUserDB().then(userDB =>
        // Delete all sequences for this space
        userDB.transaction('rw', userDB.sequence, () =>
          userDB.sequence.where({ spaceId: spaceId }).toArray().then(syncSequences =>
            Promise.all(syncSequences.map(syncSequence =>
              userDB.sequence.delete([syncSequence.spaceId, syncSequence.siteId ? syncSequence.siteId : '']))
            )
          )
        )
      )
    ]).then(() => {
      this.logItemToLogsDB(`The database '${spaceId}/${siteId}' sequence number have been reset.`, 'INFO');
    }).catch(error => {
      this.logItemToLogsDB(`An error has occurred while resetting the database '${spaceId}/${siteId}' sequence numbers.`, 'ERROR', error);
      throw error;
    });
  }

  getSpaceItems(item: 'tag'): Promise<Tag[]>;
  getSpaceItems(item: 'contractor'): Promise<Contractor[]>;
  getSpaceItems(item: 'location'): Promise<Location[]>;
  getSpaceItems(item: 'site'): Promise<Site[]>;
  getSpaceItems(item: 'event'): Promise<Event[]>;
  getSpaceItems(item: 'eventV4'): Promise<Event[]>;
  getSpaceItems(item: 'currency'): Promise<Currency[]>;
  getSpaceItems(item: 'task'): Promise<Task[]>;
  getSpaceItems(item: 'TaskNewApi'): Promise<Task[]>;
  getSpaceItems(item: 'SiteForm'): Promise<CustomEventForm[]>;
  getSpaceItems(item: 'asset'): Promise<Asset[]>;
  getSpaceItems(item: string): Promise<any[]> {
    return this.nonNullSpaceDatabase.pipe(map(db => db[item].where('siteId').equals("null").toArray())).toPromise();
  }

  getSpaceAssets(category: AssetCategory): Promise<Asset[]> {
    return this.nonNullSpaceDatabase.pipe(flatMap(db => {
          return db['asset'].where({ category: category, siteId: 'null' }).toArray();
      })).toPromise();
  }

  getSiteItems(item: 'tag', siteId: string): Promise<Tag[]>;
  getSiteItems(item: 'contractor', siteId: string): Promise<Contractor[]>;
  getSiteItems(item: 'location', siteId: string): Promise<Location[]>;
  getSiteItems(item: 'site', siteId: string): Promise<Site[]>;
  getSiteItems(item: 'event', siteId: string, filterStartDate?: Date, filterEndDate?: Date): Promise<Event[]>;
  getSiteItems(item: 'eventV4', siteId: string, filterStartDate?: Date, filterEndDate?: Date): Promise<Event[]>;
  getSiteItems(item: 'currency', siteId: string): Promise<Currency[]>;
  getSiteItems(item: 'task', siteId: string, filterStartDate?: Date, filterEndDate?: Date): Promise<Task[]>;
  getSiteItems(item: 'TaskNewApi', siteId: string, filterStartDate?: Date, filterEndDate?: Date): Promise<Task[]>;
  getSiteItems(item: 'asset', siteId: string): Promise<Asset[]>;
  getSiteItems(item: 'SiteForm', siteId: string): Promise<CustomEventForm[]>;
  getSiteItems(item: string, siteId: string, filterStartDate?: Date, filterEndDate?: Date): Promise<any[]> {
     const itemsPromise = this.nonNullSpaceDatabase.pipe(map(db => {
      if (filterStartDate && filterEndDate) {
        const filterStart = filterStartDate.getTime();
        const filterEnd = filterEndDate.getTime();
        return db[item].where('siteId').equals(siteId).and(item => item.startDatetime >= filterStart && item.startDatetime <= filterEnd).toArray();
      } else if (filterStartDate) {
        const filterStart = filterStartDate.getTime();
        return db[item].where('siteId').equals(siteId).and(item => item.startDatetime >= filterStart).toArray();
      } else if (filterEndDate) {
        const filterEnd = filterEndDate.getTime();
        return db[item].where('siteId').equals(siteId).and(item => item.endDatetime <= filterEnd).toArray();
      } else {
        return db[item].where('siteId').equals(siteId).toArray();
      }
      })).toPromise();
      if (!this.checkIfEvent(item)) {
        return itemsPromise;
      } else {
        return this.mergeUnsyncedPhotosWithEventsPromise(itemsPromise);
      }
  }

  getSiteEventsCount(siteId: string): Promise<any> {
    const itemsPromise = this.nonNullSpaceDatabase.pipe(map(db => {
      return db['event'].where('siteId').equals(siteId).count();
    }
  )).toPromise();
  return itemsPromise;
  }

  private mergeUnsyncedPhotosWithEventsPromise(eventsPromise: Promise<Event[]>): Promise<Event[]> {
    const pendingSyncItemsPromise = this.getUserDB().then(userDB => userDB.queue.toArray())
      return Promise.all([eventsPromise, pendingSyncItemsPromise]).then(promiseValues => {
        promiseValues[0] = this.updateEventSyncStatus(promiseValues[0], promiseValues[1]);
        return this.mergeUnsyncedPhotos(promiseValues[0] as Event[], promiseValues[1])
      })
  }

  mergeUnsyncedPhotosWithEvents(events: Event[]): Promise<Event[]> {
    return this.getUserDB().then(userDB => userDB.queue.toArray())
      .then(pendingSyncItems => {
        events = this.updateEventSyncStatus(events, pendingSyncItems);
        return this.mergeUnsyncedPhotos(events, pendingSyncItems)
      } )
  }

  // This method checks for events in queue and updates the event sync status for the
  // events found in queue. The check and updation is only done for mobile.
  updateEventSyncStatus(events: Event[], queueAction: QueueAction[]): Event[] {
    if(queueAction.length > 0 && this.deviceService.isMobile) {
      for(let queueItem of queueAction) {
        if(queueItem.action.type === "Event" && queueItem.action.action !== "DELETE") {
          events.forEach((event, index) => {
            if(event.id === queueItem.action.payload.id) {
              events[index].isEventSynced = false;
            }
          })
        }
      }
    }
    return events;
  }

  // This method merges unsynced photos with events locally. This is a quickfix introduced for
  // fixing the bug of images disappearing and re-appearing as a sync action happens.
  private mergeUnsyncedPhotos(events: Event[], unsyncedItems: QueueAction[]): Event[] {
    // Get only unsynced photos:
    const photoSyncItems = unsyncedItems
      .filter(item => item.action.action === ActionType.CREATE && item.action.type === 'Attachment')
      .map(photoItem => {
        return {
          eventId: photoItem.action.payload['eventId'],
          photoId: photoItem.action.payload['id']
        }
      });
    // If there are no unsynced photos, return events as-is.
    if (photoSyncItems.length == 0 ) {
      this.logItemToLogsDB('There are no unsynced photos. Returning events as-is.', 'INFO')
      return events;
    }

    // Prepare a list of photos for each event:
    const photosByEvent: {[eventId: string] : string[]} = {};
    for (const photoSyncItem of photoSyncItems) {
      if (photosByEvent[photoSyncItem.eventId] != null) {
        photosByEvent[photoSyncItem.eventId].push(photoSyncItem.photoId);
      } else {
        photosByEvent[photoSyncItem.eventId] = [photoSyncItem.photoId];
      }
    }

    // Merge photos:
    for (const eventIdOfPhoto of Object.keys(photosByEvent)) {
      for (var event of events) {
        if (event && event.id === eventIdOfPhoto) {
          // Ensure no duplicates
          const attachmentsForEvent = photosByEvent[eventIdOfPhoto]
            .map(photoId => new Attachment(photoId));
          for (const attachment of attachmentsForEvent) {
            if(!this.isAttachmentContainedInEvent(attachment, event)) {
              event.attachments.push(attachment);
            }
          }
        }
      }
    }
    return events;
  }

  getEventsFilteredByTaskId(siteId: string, taskId: string): Promise<Event[]> {
    return this.nonNullSpaceDatabase.pipe(flatMap(db => {
          return db['event'].where({siteId: siteId, 'task.taskId': taskId}).sortBy('startDatetime');
      })).toPromise();
  }

  getSiteAssets(category: AssetCategory, siteId: string): Promise<Asset[]> {
    return this.nonNullSpaceDatabase.pipe(flatMap(db => {
          return db['asset'].where({ category: category, siteId: siteId }).toArray();
      })).toPromise();
  }

  getItemById(item: string, id: string): Promise<any> {
    const itemPromise = this.nonNullSpaceDatabase.pipe(map(db => {
          return db[item].where('id').equals(id).first();
      })).toPromise();

    if (!this.checkIfEvent(item)) {
      return itemPromise;
    } else {
      const eventsPromise = itemPromise.then((event) => [event]);
      return this.mergeUnsyncedPhotosWithEventsPromise(eventsPromise)
        .then((photoMergedEvents) => {
          if (photoMergedEvents.length > 1) {
            this.logItemToLogsDB(`A single event was supplied but multiple events were received.`, 'ERROR');
          }
          return photoMergedEvents[0];
        })
    }
  }

  // get event by space id and event id
  async getEventBySpaceAndEventId(spaceId: string, eventId: string, queueService: QueueService): Promise<Event> {
    const db = await this.getSpaceDBById(spaceId, queueService);
    const itemPromise = db['event'].get(eventId);
    return itemPromise;
  }

  // get space database by space id
  async getSpaceDBById(spaceId: string, queueService: QueueService): Promise<SpaceDatabase> {
    const userDB = await this.getUserDB();
    const db = new SpaceDatabase(userDB.userId, spaceId, queueService, this.deviceService.isMobile);
    return db;
  }

  async getAssetsForCurrentSiteAndSpace(siteId: string, category?: AssetCategory): Promise<Asset[]> {
    const spaceAssetsPromise = this.getSpaceAssets(category);
    const siteAssetsPromise = this.getSiteAssets(category, siteId);
    return (await spaceAssetsPromise).concat(await siteAssetsPromise);
  }

  async getItemsForCurrentSiteAndSpace(item: any, siteId: string): Promise<any[]> {
    const spaceItemsPromise = this.getSpaceItems(item);
    const siteItemsPromise = this.getSiteItems(item, siteId);
    return (await spaceItemsPromise).concat(await siteItemsPromise);
  }

  getSites(): Promise<Site[]> {
    return combineLatest(
      this.nonNullSpaceDatabase,
      this.syncStatusService.waitForEndOfSpaceSync(),
    ).pipe(
      flatMap(([db]) => db['site'].toArray()),
      first(),
    ).toPromise();
  }

  getLastEventForTask(taskId: string): Promise<Event> {
    return this.nonNullSpaceDatabase.pipe(
      flatMap(db => db.event
        // Filter according to task ID and sort according to start date
        .where('[task.taskId+startDatetime]').between([taskId, Dexie.minKey], [taskId, Dexie.maxKey])
        // Get the last item of the list
        .last()
        ),
      first(),
    ).toPromise();
  }

  private checkIfEvent(item: String): Boolean {
    return item == 'event';
  }

  private isAttachmentContainedInEvent(attachment: Attachment, event: Event): Boolean {
    for (const eventAttachment of event.attachments) {
      if (eventAttachment.id == attachment.id) {
        return true;
      }
    }
    return false;
  }

  // Return the total count of the log records in the UserDB
  public async getUserLogDataCount(): Promise<number> {
    const userDB = await this.getUserDB();
    return userDB.logData.count();
  }

  // Delete the excess number of records greater than 3000 in the logData table of UserDB
  public async cleanUserLogData(logDataCount: number): Promise<void> {
    const userDB = await this.getUserDB();
    await userDB.logData.limit(logDataCount - 3000).delete();
  }

  private async evaluateUserLogRecordsCount(): Promise<void> {
    const logDataCount = await this.getUserLogDataCount();
    if(logDataCount > 3000) {
      this.cleanUserLogData(logDataCount);
    }
  }

  public async clearDeletedUserData(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const userDB = await this.getUserDB();
        const deletedUserSpaces = await userDB.spaces.toArray();
        (await this.getDBInstant()).close();
        deletedUserSpaces.forEach(async (space) => {
          await Dexie.delete(SpaceDatabase.getDatabaseName(userDB.userId, space.id));
        });
        await userDB.close();
        await userDB.delete();
        resolve();
      } catch(error) {
        reject(error);
      }
    });
  }

  async fetchFaultyItemsCount(siteId: string): Promise<number> {
    let db = await this.getUserDB();
    let count =  db['errorItemsQueue'].where('siteId').equals(siteId).count();
    return count;
  }

  async getSiteFaultyItems(siteId: string): Promise<ErrorQueueAction[]> {
    let db = await this.getUserDB();
    return db['errorItemsQueue'].where('siteId').equals(siteId).toArray();
  }

  async getDeletedSiteItemFromQueueTable(): Promise<QueueAction[]> {
    let db = await this.getUserDB();
    return (await db['queue'].toArray()).filter(queueAction => {
      return queueAction.action.action === ActionType.DELETE && queueAction.action.type === 'Site';
    });
  }

  public async checkAndReturnAnyExcessEvents(siteId: string): Promise<null | Event[]> {
    if(!this.deviceService.isMobile || !siteId) {
      return null;
    }
    let db = await this.getDBInstant();
    const eventsCount = await db.event.where('siteId').equals(siteId).count();
    let events: Event[] = null;
    if(eventsCount > MAX_EVENTS_LIMIT_IN_MOBILE) {
      events = await db.event.orderBy('startDatetime')
      .filter((event) => event.siteId === siteId)
      .limit(eventsCount - MAX_EVENTS_LIMIT_IN_MOBILE).toArray();
    }
    return events;
  }

  async logItemToLogsDB(item: string, type: string, additionalInfo?: any) {
    this.getUserDB().then((userDB) => {
      if (userDB && userDB.logData) {
        const currentDate = new Date().toISOString();
        let logData = '\n' + type + (' : [' + currentDate + '] ') + item + ' - ' + additionalInfo  + ' \n';
        userDB.logData.put(logData, currentDate);
      }
    }).catch((error) => {
      // Throw error to Sentry directly if User DB is not present.
      captureMessage(error, scope => {
        return scope.setExtra('error', null);
      });
    });
  }
}
