import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PageTitleService {

  private titleSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private translateService: TranslateService) {}

  /** Get page title subscription */
  watchPageTitleChanges(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  /**
   * Emit to all watchers a new title
   * @param title Given title key to translate
   * @param options Tranlating options
   */
  emitNewPageTitle(title: string, options?: Object): void {
    this.translateService.get(title, options)
      .subscribe(translation =>  this.titleSubject.next(translation));
  }

  /** Emit an empty string to all watchers */
  resetPageTitle(): void {
    this.titleSubject.next('');
  }
}
