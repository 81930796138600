<ng-template [ngTemplateOutlet]="deviceService.isMobile ? mobileTemplate : webappTemplate"></ng-template>

<ng-template #mobileTemplate>
  <span *ngIf="value">{{ value | date : localizedFormat }}</span>
  <span *ngIf="!value" class="placeholder">{{ placeholder }}</span>
</ng-template>

<ng-template #webappTemplate>
  <input [class]="customCssClass" type="text" [disabled]="readonly" [value]="value | date : localizedFormat" name="startDate" id="startDate">
</ng-template>
