import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { UrlGiverService } from '../url-giver.service';
import { AuthorizationHandler } from './authorization-handler';
import { SessionService } from '@services/session.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AuthorizationHeadersInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private urlGiverService: UrlGiverService,
    private sessionservice: SessionService,
    private logger: NGXLogger
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const handler = new AuthorizationHandler(request, next, this.authService, this.urlGiverService, this.sessionservice, this.logger);
    return handler.handle();
  }
}
