export const STRIPE_PRICING_PLAN_INTERVALS = ['day', 'week', 'month', 'year'];
export interface StripePricingPlan {
  planGroupID: string;
  siteTaskPrice: number;
  siteDiaryPrice: number;
  currency: string;
  siteTaskPlanId: string;
  siteDiaryPlanId: string;
  nickname: string;
  interval: 'day' | 'week' | 'month' | 'year';
  intervalCount: number;
}
