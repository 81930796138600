export class SyncSequence {

  static readonly DATABASE_FLAG_CORRUPTED = 'DATABASE_FLAG_CORRUPTED';

  constructor(
    public spaceId: string,
    public siteId: string,
    public sequence: string,
  ) {
  }
}
