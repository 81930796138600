import * as uuidv4 from 'uuid/v4';
import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export abstract class ModelElement implements Labelable, Identifiable {

  abstract id: string;
  abstract siteId?: string;
  abstract readonly label: string;

  public static generateId(): string {
    return uuidv4();
  }

  abstract toDTO();
}
