<div class="outer-container">
  <div class="skip-button">
    <button class="inner-button" [ngClass]="(appType === 'tasks' ? 'site-task' : 'site-diary')" (click)="close()">{{(screenNumber === onboardingDataObject.length - 1? 'label.finish': 'tutorial.buttons.skip') | translate}}</button>
  </div>
  <div class="inner-container">
    <div>
      <div class="welcome-text" [ngClass]="(appType === 'tasks' ? 'site-task' : 'site-diary')">
        {{slideWelcomeMessage | translate}}
      </div>
      <div class="title">{{ onboardingDataObject[screenNumber].title | translate}}</div>
      <div class="sub-title" [innerHTML]="onboardingDataObject[screenNumber].subTitle | translate:{administrationLink: administrationLink, administrationEventFormLink: administrationEventFormLink, administrationDashboardLink: administrationDashboardLink, administrationResources: administrationResources, administrationInformation: administrationInformation, administrationUsersSubscriptions: administrationUsersSubscriptions }"></div>
      <div class="buttons">
        <button class="button" *ngIf="screenNumber > 0" (click)="openUserGuide()" [ngClass]="(appType === 'tasks' ? 'site-task site-task-button' : 'site-diary site-diary-button')">
          <div class="button-content">
            <div class="button-text">{{ 'label.view.user.guide' | translate }}</div><img [src]="appType === 'tasks' ? '/assets/img/on-boarding/article-site-task.svg' : '/assets/img/on-boarding/article-site-diary.svg'" alt="site diary app logo" class="watch-overview-icon" >
          </div>
        </button>
        <button *ngIf="(screenNumber === 0) && (appType !== 'tasks')" (click)="openUserGuide()" class="button" [ngClass]="(appType === 'tasks' ? 'site-task site-task-button' : 'site-diary site-diary-button')">
          <div class="button-content">
            <div class="button-text">{{ 'label.watch.overview' | translate  }}</div><img [src]="appType === 'tasks' ? '/assets/img/on-boarding/watch-overview-site-task.svg' : '/assets/img/on-boarding/watch-overview-site-diary.svg'"  alt="site diary app logo" class="watch-overview-icon" >
          </div>
        </button>
      </div>
    </div>
    <div class="icon-container">
      <img src="{{ '/assets/img/on-boarding/' + onboardingDataObject[screenNumber].icon }}" alt="site diary app logo" class="icon" >
    </div>
  </div>
  <div class="paginate">
    <button [ngClass]="screenNumber !== 0 ? 'previous-screen' : 'hide'" (click)="previousScreen()">
      <img [src]="appType === 'tasks' ? '/assets/img/on-boarding/next-screen-site-task.svg' : '/assets/img/on-boarding/next-screen-site-diary.svg'" alt="Next Screen" />
    </button>
    <div class="dots">
      <div *ngFor="let dot of onboardingDataObject" class="dot" [ngClass]="onboardingDataObject.indexOf(dot) === screenNumber ? 'dot-active ' + (appType === 'tasks' ? 'site-task-active' : 'site-diary-active') : 'dot ' + (appType === 'tasks' ? 'site-task' : 'site-diary')"> {{index}}
      </div>
    </div>
    <button (click)="nextScreen()" [ngClass]="screenNumber < onboardingDataObject.length - 1? 'next-screen' : 'hide'"><img [src]="appType === 'tasks' ? '/assets/img/on-boarding/next-screen-site-task.svg' : '/assets/img/on-boarding/next-screen-site-diary.svg'" alt="Next Screen"></button>
  </div>
</div>
