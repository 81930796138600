import { AbstractControl, ValidationErrors } from "@angular/forms";

export function materialAssetNotPositiveNumberValidator(
  assets: AbstractControl
): ValidationErrors {
  if (assets && assets.value) {
    for (let asset of assets.value) {
      if (asset.amount < 0 || isNaN(asset.amount)) {
        return { quantityIsNotValid: true };
      }
    }
  }
  return {};
}
