<div class="top-display">
    <h1>
      {{'events.export.pdf.summary.popup.header' | translate}}
      <span class="popup-close-button" (click)="dismiss()">
        <ion-icon name="custom-close"></ion-icon>
      </span>
    </h1>
  </div>
<div>

  <aside class="webapp-wrapper">
    <form [formGroup]="formGroup" (submit)="download($event)">
      <label>
        {{'events.export.pdf.summary.popup.type-label' | translate}}
      </label>
      <div class="single-line-flex-wrapper">
        <label for="type-daily" class="radio-container">
          <input
            type="radio"
            id="type-daily"
            name="type"
            formControlName="type"
            value="daily" />
          <span class="radio-button"></span>
          {{'events.export.pdf.summary.popup.type-daily-label' | translate}}
        </label>
        <label for="type-weekly" class="radio-container">
          <input
            type="radio"
            id="type-weekly"
            name="type"
            formControlName="type"
            value="weekly" />
          <span class="radio-button"></span>
          {{'events.export.pdf.summary.popup.type-weekly-label' | translate}}
        </label>
        <label for="type-custom" class="radio-container">
          <input
            type="radio"
            id="type-custom"
            name="type"
            formControlName="type"
            value="custom" />
          <span class="radio-button"></span>
          {{'events.export.pdf.summary.popup.type-custom-label' | translate}}
        </label>
      </div>
      <label for="startdate" class="titles">
        {{'events.export.pdf.summary.popup.startdate-label' | translate}}
      </label>
      <div class="flex-row">
        <app-datetime-input #startDate (click)="startDate.openDatetimePicker()" [type]="'startDatetime'" [hideTime]="true"
              placeholder="{{'mobile-placeholder-startdate' | translate}}" formControlName="startdate">
        </app-datetime-input>
        <app-datetime-input #endDate (click)="endDate.openDatetimePicker()" [type]="'endDatetime'" [hideTime]="true" [hidden]="formGroup.controls['type'].value === 'daily'"
              placeholder=" " formControlName="enddate" [readonly]="(formGroup.controls['type'].value !== 'custom'? true: false)">
        </app-datetime-input>
      </div>
      <div *ngIf="isDateDurationInvalid" class="flex-row justify-content-end">
        <span class="validation-error text-danger">
          {{'form.error.event-and-task.negativeDuration' | translate}}
        </span>
      </div>
      <div *ngIf="isDateRangeInvalid && !isDateDurationInvalid">
        <span class="validation-error text-danger">
          {{'events.report.error.one_month_duration' | translate}}
        </span>
       </div>
      <label for="title" class="titles">
        {{'events.export.pdf.summary.popup.title-label' | translate}}
      </label>
      <input
        type="text"
        name="title"
        formControlName="title" />
      <div class="validation-buttons">
        <button
          type="button"
          (click)="dismiss()"
          class="btn btn-link btn-sm">
          {{'btn.cancel' | translate}}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="isExportButtonDisabled()"
          (click)="download($event)"
        >
          <div
          [matTooltip]="getTooltipText() | translate"
          matTooltipPosition="above"
          matTooltipClass="my-tooltip">
          {{ 'btn.export' | translate }}
          </div>
        </button>
      </div>
    </form>
  </aside>
</div>
