import { BehaviorSubject, Observable } from 'rxjs';
import {filter, first, mapTo} from "rxjs/operators";

export class NetworkStatus {
  private static _isOnline = new BehaviorSubject<boolean>(false);
  private static _isPinging = new BehaviorSubject<boolean>(false);

  public static get isPinging(): boolean {
    return NetworkStatus._isPinging.getValue();
  }

  public static set isPinging(value: boolean) {
    if (value !== this._isPinging.getValue()) {
      NetworkStatus._isPinging.next(value);
    }
  }
  
  public static get isOnline(): boolean {
    return NetworkStatus._isOnline.getValue();
  }

  public static set isOnline(value: boolean) {
    if (value !== this._isOnline.getValue()) {
      NetworkStatus._isOnline.next(value);
    }
  }

  public static watchIsOnline(): Observable<boolean> {
    return NetworkStatus._isOnline.asObservable();
  }

  public static waitForOnlineStatus(): Observable<void> {
    return NetworkStatus.watchIsOnline()
    .pipe(
      filter(isOnline => isOnline),
      first(),
      mapTo(undefined),
    );
  }
}


