import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyPipe'
})
export class CurrencyPipe implements PipeTransform {
    constructor() { }

    async transform(currency: string): Promise<string> {
        return getCurrencySymbol(currency, 'narrow');
    }
}
