import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventLegacyFieldComponent } from '../event-legacy-field.component';
import { Event } from '@models/event';

@Component({
  selector: 'app-event-notes',
  templateUrl: './event-notes.component.html',
  styleUrls: ['./event-notes.component.scss', '../event-legacy-field.component.sass']
})
export class EventNotesComponent extends EventLegacyFieldComponent implements OnInit {

  @Input() event: Event = null;

  constructor(
    protected modalController: ModalController,
  ) { 
    super(
      modalController,
    )
  }

  ngOnInit() {
  }

}
