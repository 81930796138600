import { User } from './user';

export class Session {
  user: User;
  userId: string;
  accessToken: string;
  refreshToken: string;
  is2FAAuthenticated: boolean;

  constructor(
    userId: string = '',
    access_token: string = '',
    refresh_token: string = '',
    user: User = new User(),
    is2FAAuthenticated: boolean = false
  ) {
    this.userId = userId;
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.user = user;
    this.is2FAAuthenticated = is2FAAuthenticated;
  }

  static copy(toCopy: Session, target: Session): void {
    target.userId = toCopy.userId;
    target.accessToken = toCopy.accessToken;
    target.refreshToken = toCopy.refreshToken;
    target.user = toCopy.user;
  }

  static toModel(json: any, session: Session) {
    session.userId = json.userId;
    session.accessToken = json.accessToken;
    session.refreshToken = json.refreshToken;
    session.user = json.user;
  }
}
