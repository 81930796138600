import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver/FileSaver';
import { map, first, flatMap, tap} from 'rxjs/operators';
import { Observable, from, combineLatest, of, BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

import { Logger } from './logger';
import { AndroidPermissionService } from '@services/android-permission.service';
import { AuthService } from '@services/auth/auth.service';
import { DeviceService } from '@services/device.service';
import { SharedService } from '@services/shared/shared.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { IntercomService } from './intercom.service';
import { StripeService } from './stripe.service';
import { UpgradeService } from '../subscription/upgrade.service';
import { AttachmentService } from './attachment.service';
import { NGXLogger } from 'ngx-logger';
import { DatabaseService } from './shared/database.service';
import { IntercomUserActions } from '@models/enums/intercom-user-actions';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SpinnerService } from './spinner.service';
import { Session } from '@models/session';
import { SharedDataService } from './shared-data.service';
import { FRONTEND_IMPORT_TYPE, FRONTEND_TEMPLATE_FILENAME, ImportService } from './import.service';
import { Filesystem, WriteFileOptions, WriteFileResult, Directory, Encoding } from '@capacitor/filesystem';
import { FileConversionService } from './file-conversion.service';
import { Share } from '@capacitor/share';
import { ExportReportType } from '@models/custom-event-field';

interface FileTransferEntry {
  toURL(): string;
}

interface ExportParams {
  fromDate?: Date;
  toDate?: Date;
  title?: string;
  includedEvents?: string[];
}

export type urlExportType = 'tasks' | 'events';

export type SummaryType = 'daily' | 'weekly' | 'custom';

const LOCAL_STORAGE_SESSION = 'session';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private readonly PDF_REPORT_MAX_DURATION_IN_MONTHS = 1;
  private readonly EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS = 6;
  private readonly PDF_MIMETYPE = 'application/pdf';
  private readonly CSV_MIMETYPE = 'text/csv';
  private readonly PLAINTEXT_MIMETYPE = 'text/plain';

  public isReportDownloading: boolean;
  public exportingReport: any[] = [];
  private exportQueueSubject = new BehaviorSubject<any[]>(this.exportingReport)


  constructor(
    private sharedService: SharedService,
    private deviceService: DeviceService,
    private toasterService: ToasterService,
    private urlGiver: UrlGiverService,
    private https: HttpClient,
    private translate: TranslateService,
    private transfer: FileTransfer,
    private androidPermissionService: AndroidPermissionService,
    private auth: AuthService,
    private intercomService: IntercomService,
    private stripeService: StripeService,
    private upgradeService: UpgradeService,
    private attachmentService: AttachmentService,
    private logger: NGXLogger,
    private databaseService: DatabaseService,
    private socialSharing: SocialSharing,
    private spinnerService: SpinnerService,
    private sharedDataService: SharedDataService,
    private fileConversionService: FileConversionService,
  ) { }

  /**
   * Helper method to get the first part of the URL in API calls
   */
  private prepareUrl(urlType: urlExportType): string {
    let spaceId: string;
    let siteId: string;
    if (this.deviceService.isMobile) {
      spaceId = this.sharedService.currentSpaceId;
      siteId = this.sharedService.currentSiteId;
    }
    else {
      spaceId = this.sharedDataService.currentSpaceId;
      siteId = this.sharedDataService.currentSiteId;
    }
    return `${this.urlGiver.giveAPIUrl()}/tenant/${spaceId}/sites/${siteId}/${urlType}`;
  }

  /**
   * Helper method to get report title with today's date
   * @param fileExtension File extension to be appended to file name
   */
  private getFilename(
    fileExtension: string,
    documentType: 'summary-daily' | 'summary-weekly' | 'summary-custom' | 'report'| 'task-report' | 'task-summary-pdf' | 'task-weekly-pdf',
    date: string = this.getFormattedToday(),
    documentTitle?:string,
  ): Observable<string> {
    if(documentTitle && documentTitle.length > 0) {
      return of(documentTitle);
    }
    return this.translate.get(
      documentType === 'summary-daily' ? 'events.report.summary.daily.title' :
      documentType === 'summary-weekly' ? 'events.report.summary.weekly.title' :
      documentType === 'task-report' ? 'tasks.report.title' :
      documentType === 'task-summary-pdf' ? 'tasks.report.summary.daily.title' :
      documentType === 'task-weekly-pdf' ? 'tasks.report.weekly.title' :
      'events.report.title')
    .pipe(
      map(translatedTitle => `${translatedTitle}${date}.${fileExtension}`),
    );
  }

  /**
   * Save blob to file
   * @param blob Data to be saved
   * @param filename Default/suggested name of the saved file
   */
  private saveBlob(blob: Blob, filename: string): void {
    const fileURL = window.URL.createObjectURL(blob);
    saveAs(blob, filename);
    // window.open(fileURL);    // hotfix: might later want to open the pdf reports
  }

  /**
   * Get today's date in a nice format
   */
  private getFormattedToday(): string {
    return this.getFormattedDate(new Date());
  }

  /**
   * Helper method to get filename-friendly date format
   */
  private getFormattedDate(date: Date): string {
    return moment(date).format('YYYY_MM_DD');
  }

  /**
   * Helper method to get mobile root directory where reports and the log files are stored, depending on platform and permissions
   */
  private async getRootDirectory(): Promise<string> {
    if (this.deviceService.isAndroid) {
      const hasPermission = await this.androidPermissionService.checkForFileSystemPermission('mobile-write-external-storage-permission-missing');
      if (hasPermission) {
        return cordova.file.externalRootDirectory + 'Download/';
      } else {
        return cordova.file.externalDataDirectory + 'Download/';
      }
    } else {
      return cordova.file.dataDirectory;
    }
  }

  private getDirectoryMobile(directory: string): Promise<DirectoryEntry> {
    return new Promise<DirectoryEntry>((resolve, reject) =>
      window.resolveLocalFileSystemURL(directory, directoryEntry => resolve(directoryEntry as DirectoryEntry), error => reject(error))
    );
  }
  private getFileMobile(directoryEntry: DirectoryEntry, filename: string): Promise<FileEntry> {
    return new Promise<FileEntry>((resolve, reject) =>
      directoryEntry.getFile(filename, { create: true }, fileEntry => resolve(fileEntry), error => reject(error))
    );
  }

  //TODO: Clean up unused functions after cordova is removed
  private async writeLogContents(
    fileEntry: FileEntry,
  ): Promise<void> {
    const userDB = await this.databaseService.getUserDB();
    const logDataCount = await userDB.logData.count();
    let logsCount = 0;
    let logRecords = '';
    let freshSiteSyncValues = '';
    let freshSpaceSyncValues = '';
    return new Promise<void>((resolve, reject) => {
      fileEntry.createWriter(async fileWriter => {
        fileWriter.onwriteend = () => {
            if(logsCount >= logDataCount) {
              logRecords = null;
              logsCount = 0;
              resolve();
            } else {
              logRecords = null;
              logRecords = '';
              userDB.logData.offset(logsCount).limit(150).each((logRecord) => {
                if(logRecord.includes('Fresh syncing of Site items::-')) {
                  freshSiteSyncValues = logRecord;
                  logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
                }
                if(logRecord.includes('Fresh syncing of Space items::-')) {
                  freshSpaceSyncValues = logRecord;
                  logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
                }
                logsCount += 1;
                logRecords += logRecord;
                // Only write one instance of fresh space and site sync data on log file
                if(logsCount >= logDataCount) {
                  logRecords += (freshSpaceSyncValues + '\n' + freshSiteSyncValues);
                }
              }).then(() => {
                let newBlob = new Blob([logRecords], {type: this.PLAINTEXT_MIMETYPE});
                fileWriter.write(newBlob);
                newBlob = null;
              });
            }
        }
        fileWriter.onerror = (error) => {
          this.logger.error("Error encountered while trying to write log contents", error);
          reject()
        }
        userDB.logData.limit(150).each((logRecord) => {
          if(logRecord.includes('Fresh syncing of Site items::-')) {
            freshSiteSyncValues = logRecord;
            logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
          }
          if(logRecord.includes('Fresh syncing of Space items::-')) {
            freshSpaceSyncValues = logRecord;
            logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
          }
          logsCount += 1;
          logRecords += logRecord;
          // Only write one instance of the fresh space and site sync data on log file
          if(logsCount >= logDataCount) {
            logRecords += (freshSpaceSyncValues + '\n' + freshSiteSyncValues);
          }
        }).then(() => {
          let newBlob = new Blob([logRecords], {type: this.PLAINTEXT_MIMETYPE});
          fileWriter.write(newBlob);
          newBlob = null;
        })
      });
    })
  }

private async writeLogContentsToDevice(
  filename: string
  ): Promise<WriteFileResult> {
    const userDB = await this.databaseService.getUserDB();
    const logDataCount = await userDB.logData.count();
    let logsCount = 0;
    let logRecords = '';
    let freshSiteSyncValues = '';
    let freshSpaceSyncValues = '';
    await userDB.logData.each((logRecord) => {
      if(logRecord.includes('Fresh syncing of Site items::-')) {
        freshSiteSyncValues = logRecord;
        logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
      }
      if(logRecord.includes('Fresh syncing of Space items::-')) {
        freshSpaceSyncValues = logRecord;
        logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
      }
      logsCount += 1;
      logRecords += logRecord;
      // Only write one instance of fresh space and site sync data on log file
      if(logsCount >= logDataCount) {
        logRecords += (freshSpaceSyncValues + '\n' + freshSiteSyncValues);
      }
    });

    const writeOptions: WriteFileOptions = {
      path: filename,
      data: logRecords,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    }
    let writeFile = await Filesystem.writeFile(writeOptions);
    return writeFile;
  }

  private writeFileMobile(
    blob: Blob,
    fileEntry: FileEntry,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>
      fileEntry.createWriter(fileWriter => {
        fileWriter.onwriteend = () => resolve();
        fileWriter.onerror = reject;
        fileWriter.write(blob);
      })
    );
  }
  private openFileMobile(
    path: string,
    mimetype: string,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>
      (<any>cordova.plugins).fileOpener2.open(path, mimetype, { error: reject, success: resolve })
    );
  }
  private async saveBlobMobile(
    blob: Blob,
    filename: string,
    mimetype: string,
    isReport: boolean = false
  ): Promise<void> {
    console.log('Writing blob as ', filename);
    try {
      const base64Data = (await this.convertBlobToBase64(blob) as string);
      const writeOptions: WriteFileOptions = {
        path: filename,
        data: base64Data,
        directory: Directory.Documents,
      }
      let writeFile: WriteFileResult = await Filesystem.writeFile(writeOptions);
      if (isReport) {
        await this.openFileMobile(writeFile.uri, mimetype);
      }
      if (this.deviceService.isAndroid) {
        this.toasterService.showSuccessToaster(isReport ?  'events.report.success.download.android' : 'events.image.success.save.message.android' );
      } else {
        this.toasterService.showSuccessToaster(isReport ? 'events.report.success.download.ios' : 'events.image.success.save.message.ios');
      }
    } catch (error) {
      this.logger.error('Error during saving a file in mobile', error);
      this.toasterService.showErrorToaster('events.image.error.failed_to_save.message');
    }
  }

  convertBlobToBase64(blob: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = this.fileConversionService.getFileReader();
        fileReader.onloadend = () => resolve(fileReader.result);
        fileReader.readAsDataURL(blob);
      } catch(error) {
        this.logger.error('Error during converting blob into base64 string', error);
        this.toasterService.showErrorToaster('events.report.error.failed_to_save.message');
        reject('');
      }
    });
  }

  /**
   * Transfer remote file using cordova
   * @param url Backend URL used to generate report
   * @param filename Name of the generated file
   * @param mimetype Mime type of generated file
   */
  private transferFileMobile(
    url: string,
    filename: string,
    mimetype: string,
  ): Observable<void> {
    const fileTransfer = this.transfer.create();
    return from(this.getRootDirectory())
    .pipe(
      flatMap(rootDirectory => fileTransfer.download(
        url,
        rootDirectory + filename,
        true,
        { headers: { 'Authorization': `Bearer ${this.auth.getAccessToken()}`, responseType: 'arraybuffer' }},
      )),
      map((entry: FileTransferEntry) => this.openFileOpener(entry, mimetype)),
      tap({
        next: () => this.toasterService.showSuccessToaster('events.report.success.download'),
        error: (error) => {
          this.logger.error('Failed to download file in mobile', error);
          this.toasterService.showErrorToaster('events.report.error.download');
        }
      }),
    );
  }

  /**
   * Helper method to display file opener with cordova
   * @param entry File entry returned by fileTransfer.download
   * @param mimetype Mime type of generated file
   */
  private openFileOpener(
    entry: FileTransferEntry,
    mimetype: string,
  ) {
    const callbacks = {
      error: function (error) {
        this.logger.error(`Error opening file: ${error.status} - Error message: ${error.message}`);
      },
      success: function () {
        Logger.debug('file opening success');
      },
    };
    if (this.deviceService.isAndroid) {
      (<any>cordova.plugins).fileOpener2.showOpenWithDialog(
        entry.toURL(),
        mimetype,
        callbacks,
      );
    } else {
      // We don't call the same method between Android and iOS because showOpenWithDialog is not handled well on iOS
      (<any>cordova.plugins).fileOpener2.open(
        entry.toURL(),
        mimetype,
        callbacks,
      );
    }
  }

  /**
   * This method takes any Date object, and returns only the date part, smartly discarding the time part.
   *
   * JavaScript is incredibly stupid. Really incredibly stupid. It randomly ignores time zones.
   * Even though ISO-8601 format allows for timezone, some idiot developing JS
   * thought it would be a good idea to just discard for no reason.
   */
  private dateToRealIsoDateString(date: Date): string {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
  }

  /**
   * Helper method to fetch documents (reports or summaries) from remote API
   * @param urlSuffix Path of the API endpoint that needs to be called
   * @param params Request parameters sent to server
   * @return Blob representing fetched data
   */
  private fetchReport(
    urlSuffix: string,
    params: ExportParams,
    reportType:  urlExportType,
  ): Observable<Blob> {
    const url = this.prepareUrl(reportType);
    return this.https.post(`${url}${urlSuffix}`, params, {
      responseType: 'blob',
    });
  }

  /**
   * Helper method to call relevant methods depending on platform. These
   * methods will download a report from the backend then allow the user to
   * save it.
   * @param filename Name of the generated file
   * @param urlSuffix End part of backend URL used to generate report
   * @param params Request parameters sent to server
   * @param mimetype Mime type of generated file
   */
  private fetchAndSaveReport(
    filename: string,
    urlSuffix: string,
    params: ExportParams,
    mimetype: string,
    reportType: urlExportType,
  ): Observable<void> {
    if (this.deviceService.hasCordova) {
      return this.fetchReport(
        urlSuffix,
        params,
        reportType,
      ).pipe(
        flatMap(blob => this.saveBlobMobile(blob, filename, mimetype, true)),
        );
    } else {
      return this.fetchReport(
        urlSuffix,
        params,
        reportType,
      ).pipe(
          map(blob => this.saveBlob(blob, filename)),
        );
    }
  }

  /**
   * Generate pdf summary for today's events
   */
  public fetchAndSaveEventSummaryPDF(
    type: SummaryType,
    fromDate: Date,
    title: string,
    toDate: Date = null
  ): Observable<void> {
    this.intercomService.trackUserAction(type === 'daily' ? IntercomUserActions.ExportPdfDailySummary : (type === 'custom' ? IntercomUserActions.ExportPdfCustomSummary : IntercomUserActions.ExportPdfWeeklySummary));
    const params = type === 'custom'? { fromDate, title, toDate }: { fromDate, title }
    return this.getFilename('pdf', type === 'daily' ? 'summary-daily' : type === 'custom'? 'summary-custom' : 'summary-weekly', this.getFormattedDate(fromDate), title)
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        `/summary/${type}/pdf`,
        params,
        this.PDF_MIMETYPE,
        'events'
      )),
    );
  }

  public fetchAndSaveImage(filename: string, blob: Blob): void {
    const fileType = blob.type.split('/')[1];
    this.saveBlobMobile(blob, filename + "." + fileType, blob.type);
  }

  public async shareImage(url: string, filename: string) {
    try {
      fetch(url)
        .then(response => response.blob())
        .then(async blob => {
          const fileType = blob.type.split('/')[1];
          const base64Data = (await this.convertBlobToBase64(blob) as string);
          const writeOptions: WriteFileOptions = {
            path: filename + "." + fileType,
            data: base64Data,
            directory: Directory.Documents,
          }
          let writeFile: WriteFileResult = await Filesystem.writeFile(writeOptions);
          await Share.share({
            url: writeFile.uri,
          })
          this.toasterService.showSuccessToaster('events.image.success.share.message');
        })
    }catch(error) {
      this.logger.error('Error during saving a file in mobile', error);
      this.toasterService.showErrorToaster('events.image.error.failed_to_share.message');
    }
  }

  /**
   * Generate tasks pdf summary of a period
   */
  public fetchAndSaveTaskSummaryPDF(
    fromDate: Date,
    toDate: Date,
    title: string,
  ): Observable<void> {
    this.intercomService.trackUserAction(IntercomUserActions.ExportPdfTaskSummary);
    return this.getFilename('pdf', 'report')
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        '/summary/pdf',
        { fromDate, toDate, title },
        this.PDF_MIMETYPE,
        'tasks'
      )),
    );
  }

   /**
   * Generate tasks new pdf summary of a period
   */
    public fetchAndSaveTaskNewSummaryPDF(
      fromDate: Date,
      toDate: Date,
    ): Observable<void> {
      this.intercomService.trackUserAction(IntercomUserActions.ExportPdfTaskSummary);
      return this.getFilename('pdf', 'task-summary-pdf')
      .pipe(
        flatMap(filename => this.fetchAndSaveReport(
          filename,
          '/summary/newPdf',
          { fromDate, toDate },
          this.PDF_MIMETYPE,
          'tasks'
        )),
      );
    }

   /**
   * Generate tasks weekly pdf
   */
    public fetchAndSaveTaskWeeklyPDF(
      fromDate: Date,
      toDate: Date,
    ): Observable<void> {
      this.intercomService.trackUserAction(IntercomUserActions.ExportTaskPdf);
      return this.getFilename('pdf', 'task-weekly-pdf')
      .pipe(
        flatMap(filename => this.fetchAndSaveReport(
          filename,
          '/weeklyReportPDF',
          { fromDate, toDate },
          this.PDF_MIMETYPE,
          'tasks'
        )),
      );
    }

  /**
   * Download generated XLS file
   * @param fromDate Filter date begin search
   * @param toDate Filter date end search
   */
   public fetchAndSaveTaskExcelReport(
    fromDate: Date,
    toDate: Date,
    includedEvents: string[],
  ): Observable<void> {
    this.intercomService.trackUserAction(IntercomUserActions.ExportTaskExcelReport);
    return this.getFilename('xls', 'task-report')
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        '/xls',
        { fromDate, toDate, includedEvents },
        this.CSV_MIMETYPE,
        'tasks'
      )),
    );
  }

  /**
   * Check if we allow to export a PDF report for given from/to dates.
   * This limitation is artificial and is only used to make sure backend is not stressed too much
   * (notably RAM) due to too many photos at once.
   * @param fromDate Report start date
   * @param toDate Report end date
   */
  public checkPdfReportDuration(fromDate: Date, toDate: Date): boolean {
    const fromMoment = moment(fromDate).endOf('day');
    const toMoment = moment(toDate).startOf('day');
    const differenceInMonths = moment.duration(toMoment.diff(fromMoment)).asMonths();
    if (differenceInMonths > this.PDF_REPORT_MAX_DURATION_IN_MONTHS) {
      this.toasterService.showWarningToaster('events.report.error.duration', { months: this.PDF_REPORT_MAX_DURATION_IN_MONTHS });
      return false;
    }
    return true;
  }

  public checkExcelOrCsvReportDuration(fromDate: Date, toDate: Date): boolean {
    const fromMoment = moment(fromDate).endOf('day');
    const toMoment = moment(toDate).startOf('day');
    const differenceInMonths = moment.duration(toMoment.diff(fromMoment)).asMonths();
    if (differenceInMonths > this.EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS) {
      this.toasterService.showWarningToaster('events.report.error.duration', { months: this.EXCEL_AND_CSV_REPORT_MAX_DURATION_IN_MONTHS });
      return false;
    }
    return true;
  }

  /**
   * Download generated PDF file
   * @param fromDate Filter date begin search
   * @param toDate Filter date end search
   * @param title Optional title displayed in generated PDF file. A default one will be generated if not given.
   */
  public fetchAndSavePdfReport(
    fromDate: Date,
    toDate: Date,
    includedEvents: string[],
    title?: string,
  ): Observable<void> {
    this.intercomService.trackUserAction(IntercomUserActions.ExportPdfReport);
    return this.getFilename('pdf', 'report', this.getFormattedToday(), title)
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        '/pdf2',
        { fromDate, toDate, title, includedEvents },
        this.PDF_MIMETYPE,
        'events'
      )),
    );
  }

  /**
   * Download generated XLS file
   * @param fromDate Filter date begin search
   * @param toDate Filter date end search
   */
  public fetchAndSaveXlsReport(
    fromDate: Date,
    toDate: Date,
    includedEvents: string[],
  ): Observable<void> {
    this.intercomService.trackUserAction(IntercomUserActions.ExportExcelReport);
    return this.getFilename('xls', 'report')
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        '/xls',
        { fromDate, toDate, includedEvents },
        this.CSV_MIMETYPE,
        'events'
      )),
    );
  }

  /**
   * Download generated PDF file
   * @param includedEvents Events that should be included in the report
   * (they can be filtered out later, e.g. draft events will not be included anyway)
   */
  public fetchAndSaveCsvReport(
    includedEvents: string[],
  ): Observable<void> {
    this.intercomService.trackUserAction(IntercomUserActions.ExportCsvReport);
    return this.getFilename('csv', 'report')
    .pipe(
      flatMap(filename => this.fetchAndSaveReport(
        filename,
        '/csv',
        { includedEvents },
        this.CSV_MIMETYPE,
        'events'
      )),
    );
  }

  public checkSubscription(feature: 'REPORT_CSV' | 'REPORT_PDF' | 'REPORT_XLS' | 'REPORT_SUMMARY'): Observable<void> {
    return this.stripeService.currentSpaceHasFeature(feature)
    .pipe(
      first(),
      map(hasFeature => {
        if (!hasFeature) {
          this.upgradeService.annoyUser('upgrade.feature.'+feature);
          this.logger.error('Tried to get report without subscription');
          throw new Error('Tried to get report without subscription');
        }
      }),
    );
  }

  /**
   * Get current session stored in localstorage
   */
   getSession(): Session {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_SESSION)) as Session;
  }

  /**
   * log user session data and device information
   */
  logIdentificationData(): Promise<void> {
    let identificationData: any = {};
    identificationData.platformType = this.deviceService.getPlatformType();
    identificationData.platforms = this.deviceService.getPlatforms();
    identificationData.version = this.deviceService.getVersion();
    identificationData.deviceData = this.deviceService.getDeviceData();
    identificationData.userSession = this.getSession();
    // The logging of data is an async operation but the library used performs it as an 'execute and forget' kind of way.
    // For this reason a set timeout is used to provide sometime so that this log entry is created and then export starts.
    this.logger.log('Identification Data - [platformType, platforms, version, deviceData, userSession]', identificationData);
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 200);
    });
  }

  /**
   * Fetch all the log records from the userDB and create the log file with those records.
   */
  public async exportLogs(): Promise<void> {
    await this.logIdentificationData();
    this.spinnerService.activate('rotating');
    let currentDate = new Date().toISOString();
    currentDate = currentDate.replace(/[-:.]/g, "_");
    const fileName = currentDate +'_log.txt';
    // If device is not a mobile device directly download the log file
    if(!this.deviceService.isMobile) {
      let logContent = await (await this.databaseService.getUserDB()).logData.toArray();
      let fileContent = '';
      let freshSiteSyncValues = '';
      let freshSpaceSyncValues = '';
      for(let logRecord of logContent) {
        if(logRecord.includes('Fresh syncing of Site items::-')) {
          freshSiteSyncValues = logRecord;
          logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
        }
        if(logRecord.includes('Fresh syncing of Space items::-')) {
          freshSpaceSyncValues = logRecord;
          logRecord = '\n' + logRecord.substring(0, logRecord.indexOf('::-')) + ' - Omitted \n';
        }
        fileContent += logRecord;
      }
      fileContent += (freshSpaceSyncValues + '\n' + freshSiteSyncValues);
      var blob = new Blob([ fileContent ], { type : this.PLAINTEXT_MIMETYPE });
      const fileURL = window.URL.createObjectURL(blob);
      saveAs(blob, fileName);
      blob = null;
      fileContent = null;
      logContent = null;
      this.spinnerService.deactivate();
    } else {
      // If device is a mobile device, check for storage permissions and then download the log file to
      // the same directory as where the export reports are downloaded.
      try {
        let writeFileResult = await this.writeLogContentsToDevice(fileName);
        try {
          this.spinnerService.deactivate();
          this.socialSharing.share(null, fileName , writeFileResult.uri);
        } catch(error) {
          this.logger.error('Failed to open share sheet', error);
          this.spinnerService.deactivate();
          await this.openFileMobile(writeFileResult.uri, this.PLAINTEXT_MIMETYPE);
        }
        this.spinnerService.deactivate();
      } catch(error) {
        this.spinnerService.deactivate();
        this.toasterService.showWarningToaster('export.logs.error.message')
      }
    }
  }

  private fetchAndSaveTemplateFile(
    filename: string,
    url: string,
    params: HttpParams,
  ): Observable<void> {
    return this.https.get(url, {
      responseType: 'blob',
      params,
      }).pipe(
          map(blob => this.saveBlob(blob, filename)),
        );
  }

  getTemplateFileName(string: FRONTEND_TEMPLATE_FILENAME): Promise<string> {
    return this.translate.get(string).toPromise();
  }

  async downloadTemplateFile(categoryType: string, dateFormat?: string): Promise<void> {
    let importType = ImportService.importTypes.find((type) => type.frontendImportType === categoryType);
    let url = this.urlGiver.giveDownloadTemplateFileUrl();
    let params = new HttpParams().set('type', importType.backendImportType);
    if (importType.frontendImportType === FRONTEND_IMPORT_TYPE.TASK) {
      params = params.set('dateFormat', dateFormat);
    }
    let fileName = await this.getTemplateFileName(importType.fileName);
    return this.fetchAndSaveTemplateFile(fileName, url, params).toPromise();
  }

  isReportGenerating(document: any): Observable<boolean> {
    return this.exportQueueSubject.asObservable().pipe(
      map((queue) => queue.indexOf(document) === 0)
    );
  }

  // Update the queue and notify all observers
  updateQueue() {
    this.exportQueueSubject.next([...this.exportingReport]);
  }
}
