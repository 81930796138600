import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SelectMultipleComponent } from '../select-multiple.component';
import { TaskAsset } from '@models/task-asset';
import { AssetCategory } from '@models/asset';
import { QuantityUnitsService } from '@services/quantity-units.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-select-multiple-assets',
  templateUrl: './select-multiple-assets.component.html',
  styleUrls: ['./select-multiple-assets.component.scss']
})
export class SelectMultipleAssetsComponent extends SelectMultipleComponent implements OnInit, OnDestroy {
  @Input() hasDuration: boolean;

   /** Override items in order to properly type it. */
  @Input() items: TaskAsset[];

  @Input() assetCategory: AssetCategory = null;
  isAssetLabour: boolean;

  quantityUnits = {};
  private quantityUnitsSubscription: Subscription;

  constructor(
    private quantityUnitsService: QuantityUnitsService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.isAssetLabour = this.assetCategory === AssetCategory.LABOURS;
    this.quantityUnitsSubscription = this.quantityUnitsService.watchQuantityUnits.subscribe((quantityUnits) => {
      this.quantityUnits = quantityUnits;
    })
  }

  ngOnDestroy(): void {
    UtilsService.safeUnsubscribe(this.quantityUnitsSubscription);
  }

  /** Properly populate selected items from source items when initializing. */
  public compareByIdAndPopulate(selectedItem: TaskAsset, sourceItem: TaskAsset): boolean {
    const isSameAsset = selectedItem.assetId === sourceItem.assetId;
    // Populate amount/duration if asset is selected
    if (isSameAsset) {
      TaskAsset.copyState(sourceItem, selectedItem);
    }
    return isSameAsset;
  }

  private resetAmountAndDuration(item: TaskAsset) {
    item.duration = 0;
    item.amount = 1;
  }

  /**
   * KLUDGE: Manually update source items.
   * This is necessary because ng-select makes an internal copy of selected items
   * and does not properly re-impact its source formControlName for some reason.
   * It works fine for adding/removing elements but not for changing inner values.
   * They only update ngModel at specific times (select/unselect) which are not triggered by inner value modification.
   * A quick inspection of the code at https://github.com/ng-select/ng-select did not yield any obvious solution
   * to trigger an ngModel manually. So here we are ¯\_(ツ)_/¯
   */
  private manuallyPopulateSourceItems() {
    const sourceItems: TaskAsset[] = this.parentFormGroup.controls[this.controlName].value;
    sourceItems.forEach(sourceItem => {
      const selectedItem = this.items.find(item => item.assetId === sourceItem.assetId);
      TaskAsset.copyState(selectedItem, sourceItem);
    });
  }

  manuallyUpdateTaskItem() {
    this.manuallyPopulateSourceItems();
    this.parentFormGroup.controls[this.controlName].updateValueAndValidity();
  }

  /**
   * On quantity and duration change manually mark the formControl as dirty
   */

  markFormControlAsDirty(): void {
    this.parentFormGroup.controls[this.controlName].markAsDirty();
  }

  /**
   * On quantity and duration change manually mark the formControl as touched
   */
  markFormControlAsTouched(): void {
    this.parentFormGroup.controls[this.controlName].markAsTouched({onlySelf: true});
  }

  /* When the time picker is opened the ng-select closes and so the asset will not have the latest value
     with regards to the duration of the assets.
     With this method, on every change of the duration value the assets will update to have the latest value of the duration. */
  updateDuration(){
    this.manuallyPopulateSourceItems();
    this.parentFormGroup.controls[this.controlName].updateValueAndValidity();
  }

  /** This is necessary in case someone removes and then re-adds an asset, which might be storing previous amount/duration */
  public onAdd(item: TaskAsset): void {
    this.resetAmountAndDuration(item);
  }

  public onClose(): void {
    this.manuallyPopulateSourceItems();
  }

  limitDecimal(event, item): void {
    if(this.assetCategory === AssetCategory.MATERIALS) {
      const input = event.target as HTMLInputElement;
      const value = input.value;
      const parts = value.split('.');
      if (parts.length > 2 || (parts.length === 2 && parts[1].length > 1)) {
        // More than one decimal point or more than one digit after the decimal point
        input.value = parts[0] + '.' + parts[1].charAt(0);
        item.amount = parseFloat(input.value);
      }
    }
  }
}
