<div class="row" *ngIf="!deviceService.isMobile">
  <div id="gps-options" class="col col-12">
    <div class="row">
      <!-- LOCATION BUTTON -->
      <div class="col col-12" [ngClass]="{'col-lg-6': withAutoComplete}">
        <button class="btn btn-outline-primary btn-block" type="button" (click)="locateMe()">
          {{
          'sites.weather.coordinates.search.locate_me' | translate
          }}&nbsp;&nbsp; <i class="fas fa-lg fa-map-marked-alt"></i>
        </button>
      </div>
      <!-- SEARCH LOCATION BAR -->
      <div class="col col-12 col-lg-6" *ngIf="withAutoComplete">
        <div class="input-group">
          <input maxlength="250" #searchbox (keyup.enter)="geocodeCity(searchbox.value)" id="map-search" type="text"
                 class="form-control" autocorrect="off" autocapitalize="off" spellcheck="off"
                 placeholder="{{ 'sites.weather.coordinates.search.placeholder' | translate }}"/>
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="geocodeCity(searchbox.value)">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- END LOCATION FORM -->
    </div>
  </div>
<!-- Angular Google Map -->
  <div class="col col-12" *ngIf="apiLoaded">
    <google-map
      height="300px"
      width="400px"
      [center]="center"
      [zoom]="zoom"
      (mapInitialized)="mapIsReady($event)"
      (mapClick)="onMapClick($event)"
      [options]="options"
      >
      <span id="map-location">
        <span class="coordinates-labels">
          {{ 'sites.weather.coordinates.lat' | translate }}
        </span>{{ latitude | number: '1.2-4' }}
        &nbsp;&nbsp;&nbsp;
        <span class="coordinates-labels">
          {{ 'sites.weather.coordinates.lng' | translate }}
        </span>{{ longitude | number: '1.2-4' }}
      </span>
      <map-marker *ngFor="let markerPosition of markerPositions"
        [position]="markerPosition"
        [options]="markerOptions">
      </map-marker>
    </google-map>
  </div>
<!-- End Angular Google Map -->

</div>

<div id="gps-mobile" *ngIf="deviceService.isMobile">
  <ion-button class="option-button" (click)="locateMe()">
    {{
    'sites.weather.coordinates.search.locate_me' | translate
    }}&nbsp;&nbsp; <i class="fas fa-lg fa-map-marked-alt"></i>
  </ion-button>
  <div class="input-group">
    <input maxlength="250" #searchbox (input)="showAutocompletePlaces($event)" (keyup.enter)="geocodeCity(searchbox.value); clearAutocompleteSearch()" id="map-search" type="text"
           class="form-control" autocorrect="off" autocapitalize="off" spellcheck="off"
           placeholder="{{ 'sites.weather.coordinates.search.placeholder' | translate }}"/>
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" (click)="geocodeCity(searchbox.value); clearAutocompleteSearch()">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>

  <!-- Angular Google Map -->
  <div class="col col-12" *ngIf="apiLoaded">
    <google-map
      id="google-map-mobile"
      height="350px"
      width="350px"
      [center]="center"
      [zoom]="zoom"
      (mapInitialized)="mapIsReady($event)"
      (mapClick)="onMapClick($event)"
      [options]="options"
      >
      <span id="map-location">
        <span class="coordinates-labels">
          {{ 'sites.weather.coordinates.lat' | translate }}
        </span>{{ latitude | number: '1.2-4' }}
        &nbsp;&nbsp;&nbsp;
        <span class="coordinates-labels">
          {{ 'sites.weather.coordinates.lng' | translate }}
        </span>{{ longitude | number: '1.2-4' }}
      </span>
      <map-marker *ngFor="let markerPosition of markerPositions"
        [position]="markerPosition"
        [options]="markerOptions">
      </map-marker>
    </google-map>
    <ion-list class="autocomplete-list" [hidden]="!autocomplete || autocompletePlaces.length == 0">
      <ion-item lines="none" button class="autocomplete-item cursor-on" *ngFor="let place of autocompletePlaces"
        (click)="geocodeCity(place.description); clearAutocompleteSearch()">
        {{ place.description }}
      </ion-item>
    </ion-list>
  </div>
  <!-- End Angular Google Map -->
</div>
