import {Site} from '@models/site';
import {Space} from '@models/space';
import { Event } from '@models/event';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, first, flatMap, tap} from 'rxjs/operators';

import {SpaceService} from '@services/space.service';
import {SiteService} from '@services/site.service';
import {DatabaseService} from './database.service';
import {Logger} from '@services/logger';
import {QueueService} from '@services/synchronization/queue.service';
import {IntercomService} from '@services/intercom.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  private collapseSidebar$ = new Subject<void>();
  private canSyncSpinnerActivate$ = new BehaviorSubject<boolean>(null);

  private spaceCache: Space;
  private spaceIdCache: string;

  private currentSiteId$ = new BehaviorSubject<string | null>(null);
  private currentSpaceId$ = new BehaviorSubject<string | null>(null);

  public readonly watchSpaceId: Observable<string>;
  public readonly watchSpace: Observable<Space | null>;
  public readonly watchSiteId: Observable<string>;
  public readonly watchSite: Observable<Site | null>;
  public readonly watchSpaceAndSiteIds: Observable<[string | null, string | null]>;

  public readonly watchCanSyncSpinnerActivate: Observable<boolean>;

  public readonly watchCollapseSidebar: Observable<void>;

  private constructor(
    private databaseService: DatabaseService,
    private queueService: QueueService,
    private spaceService: SpaceService,
    private siteService: SiteService,
    private intercomService: IntercomService,
    private logger: NGXLogger
  ) {
    this.watchSpace = this.currentSpaceId$.pipe(
      flatMap(spaceId => {
        if (spaceId) {
          if (spaceId === this.spaceIdCache) {
            return of(this.spaceCache);
          } else {
            return this.spaceService.getSpace(spaceId);
          }
        } else {
          return of(null);
        }
      }),
    );
    this.watchSpaceId = this.currentSpaceId$.asObservable();
    this.watchSiteId = this.currentSiteId$.asObservable();
    this.watchSite = this.currentSiteId$.pipe(
      flatMap(siteId => siteId ? this.siteService.getOnceById(siteId) : of(null))
    );
    this.watchSpaceAndSiteIds = combineLatest([this.watchSpaceId, this.watchSiteId]).pipe(
      distinctUntilChanged(([prevSpaceId, prevSiteId], [currentSpaceId, currentSiteId]) => {
        return prevSiteId === currentSiteId;
      })
    );
    this.watchCollapseSidebar = this.collapseSidebar$.asObservable();
    this.watchCanSyncSpinnerActivate = this.canSyncSpinnerActivate$.asObservable();
  }

  public get currentSpaceId(): string {
    if (this.hasSpaceSelected) {
      return this.currentSpaceId$.value;
    } else {
      this.logger.error("No space found");
      throw new Error('No space');
    }
  }

  public get currentSpace(): Space {
    if (this.currentSpaceId === this.spaceIdCache) {
      return this.spaceCache;
    } else {
      this.logger.error("No space found");
      throw new Error('No space');
    }
  }

  public getNextNonNullSpace(): Promise<Space> {
    return this.watchSpace.pipe(filter(space => space != null), first()).toPromise();
  }

  public get hasSpaceSelected(): boolean {
    return this.currentSpaceId$.getValue() !== null;
  }

  public resetCurrentSpace(): void {
    this.databaseService.initDB(null, this.queueService);
    this.currentSpaceId$.next(null);
    this.currentSiteId$.next(null);
  }

  public async setCurrentSpaceById(spaceId: string): Promise<void> {
    try {
      Logger.info('Selected space id: ', spaceId);
      this.currentSpaceId$.next(spaceId);
      await this.databaseService.initDB(spaceId, this.queueService);
      const space = await this.spaceService.getSpace(spaceId);
      if (space) {
        this.spaceIdCache = spaceId;
        this.updateSpaceCache(space);
        this.intercomService.updateCompanyFromSpace(space);
        return;
      }
    } catch (ex) {
    }
    this.resetCurrentSpace();
    this.logger.error("Invalid space id:", spaceId);
    throw new Error('Invalid space id: ' + spaceId);
  }

  public updateSpaceCache(space: Space): void {
    this.spaceCache = space;
  }

  public get currentSiteId(): string {
    if (this.hasSiteSelected) {
      return this.currentSiteId$.value;
    } else {
      this.logger.error("No site found");
      throw new Error('No site');
    }
  }

  public getNextNonNullSite(): Promise<Site> {
    return this.watchSite.pipe(filter(site => site != null), first()).toPromise();
  }

  public get hasSiteSelected(): boolean {
    return this.currentSiteId$.getValue() !== null;
  }

  public resetCurrentSite(): void {
    this.currentSiteId$.next(null);
  }

  public async setCurrentSiteById(siteId: string): Promise<void> {
    try {
      this.logger.debug('Selected site id:', siteId);
      this.currentSiteId$.next(siteId);
    } catch (ex) {
      this.resetCurrentSite();
      this.logger.error("Invalid site id:", siteId);
      throw new Error('Invalid site id: ' + siteId);
    }
  }

  public communicateCollapseSidebar() {
    this.collapseSidebar$.next();
  }

  public setCanSyncSpinnerActivate(state: boolean): void {
    this.canSyncSpinnerActivate$.next(state);
  }

  public get canSyncSpinnerActivate(): boolean {
    return this.canSyncSpinnerActivate$.value;
  }

  public async clearSpaceSequenceToken(): Promise<number> {
    try {
      const userDB = await this.databaseService.getUserDB();
      const spaceId = this.currentSpaceId;
      return userDB.sequence.where('spaceId').equals(spaceId).and(item => item.siteId === '').delete()
    } catch (error) {
      this.logger.error('Error while clear User Space sequence token', error);
      return null;
    }
  }

  public async checkAndReturnAnyExcessEvents(): Promise<null | Event[]> {
    const events = await this.databaseService.checkAndReturnAnyExcessEvents(this.currentSiteId);
    return events;
  }
}
