<div>
  <div class="modal-header">
    <h1 class="modal-title">{{featureLabelCode | translate}}</h1>
    <button type="button" aria-label="Close" (click)="dismiss()">
      <ion-icon mode="md" class="small" slot="icon-only" name="cross"></ion-icon>
    </button>
  </div>
  <div class="modal-body">
    <p>{{subtitle}}</p>
    <p *ngIf="!spaceAdmin">{{'upgrade.ask-admin' | translate}}</p>
    <p *ngIf="spaceAdmin && deviceService.isMobile">{{'upgrade.desktop-only' | translate}}</p>
  </div>
  <div class="modal-footer" *ngIf="spaceAdmin && !deviceService.isMobile">
    <ion-button class="secondary-action-button" (click)="dismiss()">
      {{'upgrade.btn.later' | translate}}
    </ion-button>
    <ion-button class="ml-2 primary-action-button" [ngClass]="appTypeService.getCurrentAppType()" (click)="goToSpaceSubscription()">
       {{'upgrade.btn.now' | translate}}
    </ion-button>
  </div>
</div>


