import { Author } from './author';
import { Attachment } from './attachment';
import { EventAsset } from './event-asset';
import { Event, RelatedMinimalEvent } from './event';

export class TaskRelatedEvent {

  eventId: string;
  title: string;
  startDatetime: Date;
  endDatetime: Date;
  description: string;
  createdOn: Date;
  createdBy: Author;
  attachments: string[];
  assets: EventAsset[];
  progress: number;
  isSuspended: boolean;
  quantityDone: number;

  constructor() {
    this.title = '';
    this.eventId = '';
    this.description = '';
    this.startDatetime = new Date();
    this.endDatetime = null;
    this.createdOn = null;
    this.createdBy = new Author();
    this.attachments = [];
    this.assets = [];
    this.progress = 0;
    this.isSuspended = false
    this.quantityDone = 0;
  }

  /**
   * Convert json sent from backend to a task related event model for frontend
   * @param json Json sent from backend
   */
  public static jsonToTaskRelatedEvents(json: any): Array<TaskRelatedEvent> {
    return json.map(relatedEventsObject => {
      const relatedEvent: TaskRelatedEvent = new TaskRelatedEvent();
      relatedEvent.title = relatedEventsObject.title;
      relatedEvent.eventId = relatedEventsObject.eventId;
      relatedEvent.description = relatedEventsObject.description;
      relatedEvent.startDatetime = relatedEventsObject.startDatetime;
      relatedEvent.endDatetime = relatedEventsObject.endDatetime;
      relatedEvent.createdOn = relatedEventsObject.createdOn;
      Author.toModel(relatedEventsObject.createdBy, relatedEvent.createdBy);
      relatedEvent.attachments = relatedEventsObject.pictures;
      relatedEvent.assets = EventAsset.jsonToEventAssets(relatedEventsObject.labourAssets);
      relatedEvent.progress = relatedEventsObject.progress;
      relatedEvent.isSuspended = relatedEventsObject.isSuspended;
      relatedEvent.quantityDone = relatedEventsObject.quantityDone;
      return relatedEvent;
    });
  }

  // convert event to task related event
  public static toModel(event: Event): TaskRelatedEvent {
    const relatedEvent: TaskRelatedEvent = new TaskRelatedEvent();
    relatedEvent.title = event.title;
    relatedEvent.eventId = event.id;
    relatedEvent.description = event.description;
    relatedEvent.startDatetime = event.startDatetime;
    relatedEvent.endDatetime = event.endDatetime;
    relatedEvent.createdOn = event.createdOn;
    Author.toModel(event.createdBy, relatedEvent.createdBy);
    relatedEvent.attachments = event.attachments.map(attachment => attachment.id);
    relatedEvent.assets = EventAsset.jsonToEventAssets(event.assets);
    relatedEvent.progress = event.task.progress;
    relatedEvent.isSuspended = event.task.suspended;
    relatedEvent.quantityDone = event.task.quantityDone;
    return relatedEvent;
  }

  // convert task related events to subset tasks related minimal events
  public static taskRelatedEventsToRelatedMinimalEvents(TaskRelatedEvents: TaskRelatedEvent[], taskId: string): RelatedMinimalEvent[] {
    return TaskRelatedEvents.map(TaskRelatedEvent => {
      let relatedMinimalEvent: RelatedMinimalEvent  = {}  as RelatedMinimalEvent;
      relatedMinimalEvent.id = TaskRelatedEvent.eventId;
      relatedMinimalEvent.taskId = taskId;
      relatedMinimalEvent.startDatetime = TaskRelatedEvent.startDatetime;
      relatedMinimalEvent.endDatetime = TaskRelatedEvent.endDatetime;
      relatedMinimalEvent.progress = TaskRelatedEvent.progress;
      relatedMinimalEvent.quantityDone = TaskRelatedEvent.quantityDone;
      relatedMinimalEvent.isSuspended = TaskRelatedEvent.isSuspended;
      return relatedMinimalEvent;
    });
  }

  // convert task progress event to subset task related minimal event
  public static eventToRelatedMinimalEvent(event: Event) {
    let relatedMinimalEvent: RelatedMinimalEvent  = {}  as RelatedMinimalEvent;
    relatedMinimalEvent.id = event.id;
    relatedMinimalEvent.taskId = event.task.taskId;
    relatedMinimalEvent.startDatetime = event.startDatetime;
    relatedMinimalEvent.endDatetime = event.endDatetime;
    relatedMinimalEvent.progress = event.task.progress;
    relatedMinimalEvent.quantityDone = event.task.quantityDone;
    relatedMinimalEvent.isSuspended = event.task.suspended;
    return relatedMinimalEvent;
  }
 }
