import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AppAccess } from '@models/space-user';
import { TranslateService } from '@ngx-translate/core';
import { AppTypeService } from '@services/app-type.service';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';
import { SharedService } from '@services/shared/shared.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserAppAccessDAO } from './user-app-access-dao.service';

@Injectable({
  providedIn: 'root'
})
export class UserAppAccessService {


  private currentAppAccess$ = new BehaviorSubject<AppAccess>(null);
  private currentAppAccess: AppAccess = null;

  constructor(
    private sharedService: SharedService,
    private userAppAccessDAO: UserAppAccessDAO,
    private logger: NGXLogger,
    private translateService: TranslateService,
    private alertController: AlertController,
    private appTypeService: AppTypeService,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService
  ) {
    if (this.deviceService.isMobile) {
      combineLatest(
        [this.sharedService.watchSpaceId,
         this.userAppAccessDAO.watchAppAccessChanges()
        ]
      ).subscribe(([spaceId, appAccess]) => {
        if (this.sharedService.hasSpaceSelected) {
          this.userAppAccessDAO.getAppAccessBySpaceId(spaceId).then(newAppAccess => {
            if (JSON.stringify(newAppAccess) !== JSON.stringify(this.currentAppAccess)) {
              this.currentAppAccess = newAppAccess;
              this.refreshCurrentAppAccess();
            }
          }).catch(error => {
            this.logger.error('Error while fetching the app access of the current user: ', error);
          });
        } else {
          this.currentAppAccess = null;
          this.refreshCurrentAppAccess();
        }
      });
    }
    else {
      combineLatest(
        [this.sharedDataService.watchSpaceId,
         this.userAppAccessDAO.watchAppAccessChanges()
        ]
      ).subscribe(([spaceId, appAccess]) => {
        if (this.sharedDataService.hasSpaceSelected) {
          this.userAppAccessDAO.getAppAccessBySpaceId(spaceId).then(newAppAccess => {
            if (JSON.stringify(newAppAccess) !== JSON.stringify(this.currentAppAccess)) {
              this.currentAppAccess = newAppAccess;
              this.refreshCurrentAppAccess();
            }
          }).catch(error => {
            this.logger.error('Error while fetching the app access of the current user: ', error);
          });
        } else {
          this.currentAppAccess = null;
          this.refreshCurrentAppAccess();
        }
      });
    }
  }

  userHasSiteDiaryAccess(): boolean {
    const currentAppAccess = this.getCurrentAppAccess();
    if(currentAppAccess) {
      return currentAppAccess.siteDiaryAccess;
    }
    return false;
  }

  userHasSiteTaskAccess(): boolean {
    const currentAppAccess = this.getCurrentAppAccess();
    if(currentAppAccess) {
      return currentAppAccess.siteTaskAccess;
    }
    return false;
  }

  userHasCorrectAppAccess(): boolean {
    if(this.appTypeService.isInAdministrationPage()) {
      return true;
    } 
    else {
      const selectedApp = this.appTypeService.getCurrentAppType();
      if (selectedApp && selectedApp === 'diary') {
        return this.currentAppAccess ? this.currentAppAccess.siteDiaryAccess : false;
      } 
      else if (selectedApp && selectedApp === 'tasks') {
        return this.currentAppAccess ? this.currentAppAccess.siteTaskAccess : false;
      }
      else {
        return false
      }
    }
  }

  /**
   * @returns The stored app access for the current space
   */
  getCurrentAppAccess(): AppAccess | null {
    return this.currentAppAccess;
  }

  private refreshCurrentAppAccess() {
    if(this.currentAppAccess) {
      this.currentAppAccess$.next({siteDiaryAccess: this.currentAppAccess.siteDiaryAccess, siteTaskAccess: this.currentAppAccess.siteTaskAccess});
    } else {
      this.currentAppAccess$.next(null);
    }
  }

  watchCurrentAppAccess(): Observable<AppAccess> {
    return this.currentAppAccess$.asObservable();
  }

  showUpdateAppAccessModal(): void {
    this.translateService.get([
      'app-access.no-access.header',
      'app-access.no-access.message',
      'Ok'
    ]).pipe(
      mergeMap(async translations => {
        const alert = await this.alertController.create({
          header: translations['app-access.no-access.header'],
          message: translations['app-access.no-access.message'],
          cssClass: "app-access-upgrade-alert-box",
          buttons: [
            {
              text: translations['Ok'],
              role: 'cancel',
              cssClass: 'ok-btn',
              handler: () => {
              },
            }
          ],
        });
        await alert.present();
      })
    ).toPromise();
  }

}
