import { TaskTag } from './task-tag';

export class EventTag extends TaskTag {

  constructor() {
    super();
  }

  public static jsonToEventTags(jsonTags): Array<EventTag> {
    return super.jsonToTaskTags(jsonTags);
  }

  public static eventTagsToDto(eventTags: EventTag[]): Array<string> {
    return super.taskTagsToDto(eventTags);
  }
}
