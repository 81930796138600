import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { GlobalConstants } from '@constants/global-constants';
import { CustomEventField } from '@models/custom-event-field';

@Component({
	selector: 'app-custom-form-long-text',
	templateUrl: './custom-form-long-text.component.html',
	styleUrls: ['./custom-form-long-text.component.scss']
})
export class CustomFormLongTextComponent implements OnInit {
	@Input() customField: CustomEventField;
	@Input() deviceIsMobile: boolean;
	@Input() placeholderText: string;
	@Input() inputType: string;
	@Input() isEdited: boolean;
	@Input() parentFormGroup: FormGroup;

	maxLengthAllowed: number = GlobalConstants.maxLengthAllowedInLongTextField;
	lengthOfString: number = 0;

	constructor() {}

	ngOnInit() {
		if (this.parentFormGroup.controls.value.value) {
			this.lengthOfString = this.parentFormGroup.controls.value.value.length;
			}
	}

	countCharacters(event): void {
		this.lengthOfString = event.value.length;
	}
}
