import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
		private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Log requests and responses only for the application APIs
    if (req.url.includes('sitediary.com/api')) {
      this.logger.info('[REQUEST]: ', req);
      return next.handle(req).pipe(
        tap({
          // Intercept response
          next: (response) => {
            // The observable returned is a stream, so we also get notified when the
            // request was sent. This should not be logged.
            if (response.type !== HttpEventType.Sent) {
              this.logger.info('[RESPONSE RECEIVED]: ', response);
            }
          },
          // Intercept error
          error: (error: HttpErrorResponse) => {
            this.logger.info('[ERROR RESPONSE]: ', error);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
