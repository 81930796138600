import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';

import { DeviceService } from '../services/device.service';
import { Logger } from '../services/logger';
import { ToasterService } from '../services/toaster.service';
import {NetworkStatus} from "@models/synchronization/network-status";
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.sass'],
})
export class AccountValidationComponent implements OnInit {

  constructor(
    public deviceService: DeviceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToasterService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    const key = this.route.snapshot.queryParams['key'];
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.authService.confirmUserEmail(key)
        .subscribe((data: { email: string }) => {
            this.logger.info(`[CONFIRM_EMAIL] success form email ${data.email}`);
            this.toastr.showSuccessToaster('account.notify.new_account.confirm');
            this.router.navigate(['/login'], {queryParams: {email: data.email}});
          },
          (error: Error) => {
            this.logger.error('[CONFIRM_EMAIL]', error);
            this.router.navigate(['/login']);
          });
    });
  }
}
