import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class InformationSearchService {

    private informationSearchInput$ = new BehaviorSubject<string>('');
    public readonly watchInformationSerachInput: Observable<string>;

    constructor(
        
    ) {
        this.watchInformationSerachInput = this.informationSearchInput$.asObservable();
    }

    updateInformationSearchInput(searchInput: string): void {
        this.informationSearchInput$.next(searchInput);
    }
}
