<ng-container *ngIf="deviceService.isMobile; else desktop">
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar
      doneLabel="btn.export"
      title="events.export.pdf.events.popup.header"
      [disableDoneButton]="formGroup.invalid"
      (back)="dismiss()"
      (done)="download()">
    </app-mobile-toolbar>
  </ion-header>

  <ion-content class="main-form-wrapper mobile-wrapper whitesmoke-background">
    <form [formGroup]="formGroup">
      <ion-item-divider *ngIf="isDateRequired">
        <ion-label
          (click)="isCollapsedDate = !isCollapsedDate"
          [attr.aria-expanded]="!isCollapsedDate"
          aria-controls="collapseDate">
          <h1 class="pull-left">
            {{'events.export.pdf.summary.popup.dates-label' | translate}}
          </h1>
          <ion-icon
            class="pull-right"
            [attr.name]="isCollapsedDate ? 'chevron-down' : 'chevron-up'">
          </ion-icon>
        </ion-label>
      </ion-item-divider>
      <div id="collapseDate" [ngbCollapse]="isCollapsedDate" *ngIf="isDateRequired">
        <ion-item class="form-input" lines="none" (click)="startDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #startDate [readonly]="false" [type]="'startDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.startdate-label' | translate}}" formControlName="startDatetime">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <ion-item class="form-input" lines="none" (click)="endDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #endDate  [readonly]="false" [type]="'endDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.enddate-label' | translate}}" formControlName="endDatetime">
            </app-datetime-input>
          </ion-col>
        </ion-item>
      </div>

      <ion-item-divider>
        <ion-label
          (click)="isCollapsedTitle = !isCollapsedTitle"
          [attr.aria-expanded]="!isCollapsedTitle"
          aria-controls="collapseTitle">
          <h1 class="pull-left">
            {{'events.export.pdf.events.popup.title-label.short' | translate}}
          </h1>
          <ion-icon
            class="pull-right"
            [attr.name]="isCollapsedTitle ? 'chevron-down' : 'chevron-up'">
          </ion-icon>
        </ion-label>
      </ion-item-divider>
      <div id="collapseTitle" [ngbCollapse]="isCollapsedTitle">
        <ion-input
          formControlName="title"
          placeholder="{{'events.export.pdf.events.popup.title-label' | translate}}"
          type="text">
        </ion-input>
      </div>
    </form>
  </ion-content>
</ng-container>

<ng-template #desktop>
  <aside class="webapp-wrapper">
    <div class="top-display">
      <h1>
        {{'events.export.pdf.events.popup.header' | translate}}
        <span class="popup-close-button" (click)="dismiss()">
          <ion-icon name="custom-close"></ion-icon>
        </span>
      </h1>
    </div>
    <form [formGroup]="formGroup" (submit)="download($event)">
      <label for="title">
        {{'events.export.pdf.events.popup.title-label' | translate}}
      </label>
      <input
        type="text"
        name="title"
        formControlName="title"
        placeholder="{{ 'events.export.pdf.events.popup.text-input.placeholder' | translate : { date: getDateForPlaceholder() } }}"
        />
      <div class="validation-buttons">
        <button
          type="button"
          (click)="dismiss()"
          class="btn btn-link btn-sm">
          {{'btn.cancel' | translate}}
        </button>
        <button
          type="submit"
          [disabled]="this.formGroup.invalid"
          class="btn btn-primary">
          {{'btn.export' | translate}}
        </button>
      </div>
    </form>
  </aside>
</ng-template>
