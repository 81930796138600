import { Injectable } from '@angular/core';
import { SyncSequence } from '@models/synchronization/sync-sequence';
import { Logger } from '@services/logger';
import { DatabaseService } from '@services/shared/database.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SyncSequenceDAO {
  constructor(
    private databaseService: DatabaseService,
    private logger: NGXLogger,
  ) { }

  getSequenceNumber(spaceId: string, siteId?: string): Promise<string> {
    if (siteId) {
      return this.getSiteSequenceNumber(spaceId, siteId);
    } else {
      return this.getSpaceSequenceNumber(spaceId);
    }
  }

  getSpaceSequenceNumber(spaceId: string): Promise<string | null> {
    return this.databaseService.getUserDB().then(db => {
      return db.sequence.get([spaceId, '']).then(syncSequence => syncSequence ? syncSequence.sequence : null);
    })
    .catch(error => {
      this.logger.error(`Error fetching sequence number from local database (${spaceId}): `, error);
      return SyncSequence.DATABASE_FLAG_CORRUPTED;
    });
  }

  getSiteSequenceNumber(spaceId: string, siteId: string): Promise<string | null> {
    return this.databaseService.getUserDB().then(db => {
      return db.sequence.get([spaceId, siteId]).then(syncSequence => syncSequence ? syncSequence.sequence : null);
    })
    .catch(error => {
      this.logger.error(`Error fetching sequence number from local database (${spaceId}/${siteId}): `, error);
      return SyncSequence.DATABASE_FLAG_CORRUPTED;
    });
  }

  setSequenceNumber(spaceId: string, siteId: string, sequenceId: string) {
    if (siteId) {
      return this.setSiteSequenceNumber(spaceId, siteId, sequenceId);
    } else {
      return this.setSpaceSequenceNumber(spaceId, sequenceId);
    }
  }

  setSpaceSequenceNumber(spaceId: string, sequenceId: string): Promise<string> {
    return this.databaseService.getUserDB().then(
      db => db.sequence.put(new SyncSequence(spaceId, '', sequenceId)).then(() => sequenceId))
    .catch(error => {
      this.logger.error(`Error while setting the sequence number ${sequenceId} for space ${spaceId}.` + error);
      throw new Error(Logger.synchronization.error(`Error while setting the sequence number ${sequenceId} for space ${spaceId}.` + error));
    });
  }

  setSiteSequenceNumber(spaceId: string, siteId: string, sequenceId: string): Promise<string> {
    return this.databaseService.getUserDB().then(
      db => db.sequence.put(new SyncSequence(spaceId, siteId, sequenceId)).then(() => sequenceId))
    .catch(error => {
      this.logger.error(`Error while setting the sequence number ${sequenceId} for site ${spaceId + '/' + siteId}.` + error);
      throw new Error(`Error while setting the sequence number ${sequenceId} for site ${spaceId + '/' + siteId}.` + error);
    });
  }
}
