import { AbstractControl, ValidationErrors } from '@angular/forms';

export function eventNotInTheFutureValidator(startDatetimeControl: AbstractControl): ValidationErrors {
  if (startDatetimeControl && startDatetimeControl.value) {
    const startDate = new Date(startDatetimeControl.value);
    const today = new Date();
    // get rid of hours, minutes, seconds and milliseconds
    startDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (startDate.getTime() > today.getTime()) {
      return { 'eventInTheFuture': true };
    }
  }
  return {};
}

