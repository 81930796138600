import { SiteService } from './../site.service';
import { SiteUser } from './../../models/site-user';
import { Injectable } from '@angular/core';
import { DatabaseService } from '../shared/database.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from '@services/device.service';
import { SharedDataService } from '@services/shared-data.service';


export enum UserRole {
  spaceUser = 1,
  spaceManager = 2,
  spaceAdmin = 3,
  siteTeamMember = 11,
  siteSupervisor = 12,
  siteViewer = 13,
}

@Injectable({
  providedIn: 'root'
})
export class UserRightsDAO {
  /**
   * @constant
   * @private
   */
  public static readonly BACKEND_SPACE_ROLES = {
    'User': UserRole.spaceUser,
    'Manager': UserRole.spaceManager,
    'Admin': UserRole.spaceAdmin,
  };

  /**
   * @constant
   * @private
   */
  public static readonly BACKEND_SITE_ROLES = {
    'TeamMember': UserRole.siteTeamMember,
    'Supervisor': UserRole.siteSupervisor,
    'Viewer': UserRole.siteViewer,
  };

  private roleChanges$ = new BehaviorSubject<{ spaceId: string, siteId: string | null, role: string } | null>(null);

  constructor(
    private databaseService: DatabaseService,
    private siteService: SiteService,
    private logger: NGXLogger,
    private deviceService: DeviceService,
    private sharedDataService: SharedDataService
  ) { }

  static getBackendSpaceRole(spaceRole: UserRole): string {
    if (spaceRole) {
      const sessionRole = Object.entries(UserRightsDAO.BACKEND_SPACE_ROLES).filter(([k, v]) => v === spaceRole);
      if (sessionRole.length > 0) {
        return sessionRole[0][0];
      }
    }
    return null;
  }

  static getBackendSiteRole(siteRole: UserRole): string {
    if (siteRole) {
      const sessionRole = Object.entries(UserRightsDAO.BACKEND_SITE_ROLES).filter(([k, v]) => v === siteRole);
      if (sessionRole.length > 0) {
        return sessionRole[0][0];
      }
    }
    return null;
  }

  async getSpaceRole(spaceId: string): Promise<UserRole> {
    if (spaceId) {
      if(this.deviceService.isMobile) {
        return this.databaseService.getUserDB().then(
          userDb => userDb.roles.get(spaceId).then(role => {
            if (role) {
              if (role in UserRightsDAO.BACKEND_SPACE_ROLES) {
                return UserRightsDAO.BACKEND_SPACE_ROLES[role];
              } else {
                this.logger.error(`The role '${role}' is invalid.`);
                throw new Error(`The role '${role}' is invalid.`);
              }
            } else {
              this.logger.error(`Unable to find the role of the current user in space ${spaceId}`);
              throw new Error(`Unable to find the role of the current user in space ${spaceId}`);
            }
          }));
      }
      else {
        return this.sharedDataService.getSpaceUserRoleBySpaceId(spaceId).then(role => {
          if (role) {
            if (role in UserRightsDAO.BACKEND_SPACE_ROLES) {
              return UserRightsDAO.BACKEND_SPACE_ROLES[role];
            } else {
              this.logger.error(`The role '${role}' is invalid.`);
              throw new Error(`The role '${role}' is invalid.`);
            }
          } else {
            this.logger.error(`The role '${role}' is invalid.`);
            throw new Error(`The role '${role}' is invalid.`);
          }
        });
      }
    }
    return Promise.reject('Trying to find the role of the current user in an undefined space.');
  }


  getSiteRole(spaceId: string, siteId: string): Promise<UserRole> {
    if (spaceId && siteId) {
      if (this.deviceService.isMobile) {
        return this.databaseService.getUserDB().then(
          userDb => userDb.roles.get(spaceId + '/' + siteId).then(role => {
            if (role) {
              if (role in UserRightsDAO.BACKEND_SITE_ROLES) {
                return UserRightsDAO.BACKEND_SITE_ROLES[role];
              } else {
                this.logger.error(`The role '${role}' is invalid.`);
                throw new Error(`The role '${role}' is invalid.`);
              }
            } else {
              this.logger.error(`Unable to find the role of the current user in site ${spaceId}/${siteId}`);
              throw new Error(`Unable to find the role of the current user in site ${spaceId}/${siteId}`);
            }
          })
        );
      }
      else {
        return this.sharedDataService.getSiteUserRoleBySiteId(spaceId, siteId).then(role => {
          if (role) {
            if (role in UserRightsDAO.BACKEND_SITE_ROLES) {
              return UserRightsDAO.BACKEND_SITE_ROLES[role];
            } else {
              this.logger.error(`The role '${role}' is invalid.`);
              throw new Error(`The role '${role}' is invalid.`);
            }
          } else {
            this.logger.error(`Unable to find the role of the current user in site ${spaceId}/${siteId}`);
            throw new Error(`Unable to find the role of the current user in site ${spaceId}/${siteId}`);
          }
        });
      }
    }
    return Promise.reject('Trying to find the role of the current user in an undefined space or site.');
  }


  addSpaceRole(spaceId: string, role: string) {
    if (this.deviceService.isMobile) {
      return this.databaseService.getUserDB().then(userDB => userDB.roles.put(role, spaceId)).then(() => {
        this.roleChanges$.next({ spaceId: spaceId, siteId: null, role: role });
        return role;
      });
    }
    else {
      this.sharedDataService.addUserSpaceRole(spaceId, role);
      this.roleChanges$.next({spaceId: spaceId, siteId: null, role: role});
      return role;
    }
  }

  addSiteRole(spaceId: string, siteId: string, role: string) {
    if (this.deviceService.isMobile) {
      return this.databaseService.getUserDB().then(userDB => userDB.roles.put(role, spaceId + '/' + siteId)).then(() => {
        this.roleChanges$.next({ spaceId: spaceId, siteId: siteId, role: role });
        return role;
      });
    }
    else {
      this.sharedDataService.addUserSiteRole(siteId, role);
      this.roleChanges$.next({ spaceId: spaceId, siteId: siteId, role: role });
      return role;
    }
  }

  removeSiteRole(spaceId: string, siteId: string) {
    return this.databaseService.getUserDB().then(userDB => userDB.roles.delete(spaceId + '/' + siteId));
  }

  watchRoleChanges(): Observable<{ spaceId: string, siteId: string | null, role: string } | null> {
    return this.roleChanges$.asObservable();
  }
}
