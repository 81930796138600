import { Pipe, PipeTransform } from '@angular/core';
import { AppTypeService } from '@services/app-type.service';
import { DeviceService } from '@services/device.service';

@Pipe({
  name: 'cssClassToAppSpecificColorCssClass'
})

export class CssClassToAppSpecificColorCssClass implements PipeTransform {
  constructor(
    private appTypeService: AppTypeService,
    public deviceService: DeviceService
  ) { }
    
  transform(css: string): string {
   switch (css) {
    case 'color':
      if(this.appTypeService.isInAdministrationPage() && !this.deviceService.isMobile){
        return 'administartion-color'
      }else if(this.appTypeService.getCurrentAppType() === 'diary') {
          return 'diary-color';
      } else if(this.appTypeService.getCurrentAppType() === 'tasks') {
          return 'tasks-color'
      }
    case 'bg-color':
      if(this.appTypeService.isInAdministrationPage() && !this.deviceService.isMobile){
        return 'administartion-bg-color'
      } else if(this.appTypeService.getCurrentAppType() === 'diary') {
          return 'diary-bg-color';
      } else if(this.appTypeService.getCurrentAppType() === 'tasks') {
          return 'tasks-bg-color'
      }
    case 'border-color':
      if(this.appTypeService.isInAdministrationPage() && !this.deviceService.isMobile){
        return 'administartion-border-color'
      } else if(this.appTypeService.getCurrentAppType() === 'diary') {
          return 'diary-border-color';
      } else if(this.appTypeService.getCurrentAppType() === 'tasks') {
          return 'tasks-border-color'
      }
    default:
      return '';
   }
  }
}
