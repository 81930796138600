/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as Package from '../../package.json';
import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { AndroidPermissionService } from '@services/android-permission.service';
import { AssetService } from '@services/asset.service';
import { AuthService } from '@services/auth/auth.service';
import { ContractorService } from '@services/contractor.service';
import { CurrenciesService } from '@services/currencies.service';
import { DialogService } from '@services/dialog.service';
import { EventService } from '@services/event.service';
import { FileTransfertService } from '@services/file-transfert.service';
import { AuthGuardService } from '@services/guards/auth-guard.service';
import { NotAuthGuardService } from '@services/guards/not-auth-guard.service';
import { PendingChangesGuard } from '@services/guards/pending-changes.guard';
import { InternationalizationService } from '@services/i18n/internationalization.service';
import { AuthorizationHeadersInterceptor } from '@services/interceptors/authorization-headers.interceptor';
import { FeatureNotAvailableInterceptor } from '@services/interceptors/feature-not-available-interceptor';
import { OfflineResponseInterceptor } from '@services/interceptors/offline-response.interceptor';
import { OfflineRequestInterceptor } from '@services/interceptors/offline-request.interceptor';
import { PermissionNotAllowedInterceptor } from '@services/interceptors/permission-not-allowed-interceptor';
import { OfflineCheckerService } from '@services/offline-checker.service';
import { PageTitleService } from '@services/page-title.service';
import { SiteService } from '@services/site.service';
import { TagService } from '@services/tag.service';
import { TaskService } from '@services/task.service';
import { ToasterService } from '@services/toaster.service';
import { UrlGiverService } from '@services/url-giver.service';
import { UserService } from '@services/user.service';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { LoggerModule, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { SupportModule } from './support/support.module';
import { AccountValidationComponent } from './account-validation/account-validation.component';
import { SubscriptionModule} from './subscription/subscription.module';
import { LocationService } from '@services/location.service';
import { environment } from '../environments/environment';
import { Device } from '@ionic-native/device/ngx';
import { NGXLoggerCustomisedWriterService } from '@services/ngx-logger-customised-writer.service';
import { InformationSearchService } from '@services/information-search.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { AppUpdateHandlerInterceptor } from '@services/interceptors/app-update-handler.interceptor';
import { RxStompService } from '@services/websockets/rx-stomp.service';
import { LoggingInterceptor } from '@services/interceptors/logging-interceptor';

const packageInfo = Package;

// sentry configuration
Sentry.init({
  dsn: environment.sentryKey,
  environment: environment.name,
  release: `sitediary@${packageInfo.version}`,
  integrations: [new OfflineIntegration(
    {
      maxStoredEvents: 100 // limit how many sentry events will be localled saved.
    }
  )],
  // ignore all NG0100 errors
  ignoreErrors: [
    'Error: NG0100',
    'Cannot clone array containing an object!', // TODO: Debug and fix this error
    'TypeError: undefined is not an object (evaluating \'this.helpGuideContainer.nativeElement\')',  // TODO: Debug and fix this error
    'TypeError: Cannot read properties of undefined (reading \'nativeElement\')',  // TODO: Debug and fix this error
    'Error during locking the orientation of the screen in PORTRAIT'  // TODO: Debug and fix this error
  ],
});

const ngxLoggerLevel = environment.ngxLoggerLevel;

// AoT requires an exported function for factories
export function HttpLoaderFactory(https: HttpClient) {
  return new TranslateHttpLoader(https, `${UrlGiverService.ASSETS_URL}i18n/`, '.json?v=' + packageInfo.version);
}

/**
 * Some of the third party plugins are making use of FileReader API.
 * There's an issue firing FileReader events in a Capacitor project.
 * Hence we have to use the workaround mentioned here: https://github.com/ionic-team/capacitor/issues/1564#issuecomment-671854779
 * @returns FileReader
 */
export class CapacitorFileReader extends window.FileReader {
  constructor() {
    super();
    const zoneOriginalInstance = (this as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || this;
  }
}

window.FileReader = CapacitorFileReader;

registerLocaleData(localeFr, 'fr');

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  bootstrapPlugin,
  resourceTimelinePlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    AccountValidationComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot(
      { level: ngxLoggerLevel },
      {
        writerProvider: {
          provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: NGXLoggerCustomisedWriterService
        }
      }),
    BrowserModule,
    SharedModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    FlexLayoutModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DataTablesModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    SupportModule,
    SubscriptionModule,
    HammerModule,
    FullCalendarModule,
  ],
  providers: [
    StatusBar,
    Device,
    Geolocation,
    File,
    FileTransfer,
    FileOpener,
    AndroidPermissions,
    RxStompService,
    ImagePicker,
    DatePipe,
    Subject,
    UrlGiverService,
    UserService,
    PageTitleService,
    ContractorService,
    SiteService,
    EventService,
    TaskService,
    TagService,
    LocationService,
    InformationSearchService,
    AssetService,
    PendingChangesGuard,
    DialogService,
    ToasterService,
    AuthService,
    CurrenciesService,
    AndroidPermissionService,
    FileTransfertService,
    AuthGuardService,
    NotAuthGuardService,
    OfflineCheckerService,
    ScreenOrientation,
    SocialSharing,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FeatureNotAvailableInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionNotAllowedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppUpdateHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [InternationalizationService],
      useFactory: (i18n) => i18n.currentLocale
    },
    TranslateService,
    EmailComposer,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],

})

export class AppModule { }
