export class Weather {

  constructor(
    public latitude: number = null,
    public longitude: number = null,
    public ignored: boolean = false,
    public lastUpdatedTimestamp: Date = new Date(),
    public weatherType: number = null,
    public temperature: number = null,
    public windSpeed: number = null,
    public humidity: number = null,
    public isDay: boolean = true,
  ) { }

  /**
   * Convert backend model to front
   * @param json backend model
   * @return front model
   */
  public static jsonToWeather(json: any): Weather {
    if (json) {
      const weather = new Weather();
      weather.latitude = json.coordinates.latitude;
      weather.longitude = json.coordinates.longitude;
      weather.ignored = json.ignored;
      weather.lastUpdatedTimestamp = json.lastUpdatedTimestamp;
      weather.weatherType = json.weatherType;
      weather.temperature = json.temperature;
      weather.windSpeed = json.windSpeed;
      weather.humidity = json.humidity;
      weather.isDay = json.isDay;
      return weather;
    } else {
      return new Weather();
    }
  }

  /**
   * Convert frontend to backend model
   */
  public static toDTO(weather: Weather) {
    if (!weather || !weather.latitude || !weather.longitude || weather.ignored) {
      return {
        coordinates: {
          'latitude': 0,
          'longitude': 0,
        },
        ignored: true
      };
    }
    return {
      coordinates: {
        'latitude': weather.latitude,
        'longitude': weather.longitude,
      }
    };
  }
}
