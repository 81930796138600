<ng-container [formGroup]="parentFormGroup">
  <ng-select [labelForId]="controlName" [formControlName]="controlName"
             [items]="items" [bindLabel]="type === 'user' ? 'fullName': 'name'" [isOpen]="isReadOnly ? false : null"
             [multiple]="multiple" [searchable]="!isReadOnly" [closeOnSelect]="false" [clearOnBackspace]="false" [openOnEnter]="false"
             [loading]="isLoading" [placeholder]="isReadOnly ? null : placeholder">
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div class="selected-element-background">
        <span *ngIf="type !== 'user'">{{ item.name }}</span>
        <span *ngIf="type === 'user'">{{ item.firstName }} {{ item.lastName }}</span>
        <button *ngIf="!itemInParentTaskAssignees(item) && !isReadOnly" class="close-button" ion-button icon-only (click)="clear(item)">
          <ion-icon name="custom-close"></ion-icon>
        </button>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$">
      <div>
        <ion-grid *ngIf="type !== 'user'">
          <ion-row>
            <ion-col size="auto">
              <input type="checkbox" [checked]="item$.selected" />
            </ion-col>
            <ion-col>
              <p>{{ item.name }}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid  *ngIf="type === 'user'">
          <ion-row>
            <ion-col size="auto">
              <input type="checkbox" [checked]="item$.selected" [disabled]="itemInParentTaskAssignees(item)" />
            </ion-col>
            <ion-col>
              <p>{{ item.firstName }} {{ item.lastName }}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ng-template>
  </ng-select>
</ng-container>
