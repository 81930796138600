import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export abstract class AutoUnsubscribeComponent implements OnDestroy {
  protected destroy = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
