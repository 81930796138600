import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { Session } from '@models/session';
import { User } from '@models/user';

const LOCAL_STORAGE_SESSION = 'session';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private currentSession$: BehaviorSubject<Session> = new BehaviorSubject<Session>(this.getSession())

  /**
   * Save a session object in localstorage
   * @param session given session
   */
  setSession(session: Session): Session {
    // save the new session
    localStorage.setItem(LOCAL_STORAGE_SESSION, JSON.stringify(session));
    this.currentSession$.next(session);
    // return as result the current session
    return this.getSession();
  }

  /**
   * Get current session stored in localstorage
   */
  getSession(): Session {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_SESSION)) as Session;
  }

  watchSession(): Observable<Session> {
    return this.currentSession$.asObservable();
  }

  /**
   * Get user stored in current session
   */
  getCurrentUser(): User {
    return this.getSession() ? this.getSession().user : null;
  }


  /**
   * Get the 2FAAuthenticated value of the user for the current session
   */
  isCurrentUser2FAAuthenticatedForCurrentSession(): boolean {
    return this.getSession() ? this.getSession().is2FAAuthenticated : null;
  }

  isUser2FAAuthenticated(): boolean {
    return this.getCurrentUser().isUser2FAEnabled && this.isCurrentUser2FAAuthenticatedForCurrentSession();
  }

  /**
   * Compare current user with given user
   * @param userId given user id
   */
  isCurrentUser(userId: string): boolean {
    const currentUser: User = this.getCurrentUser();
    return currentUser.id === userId;
  }

  /**
   * Clear current stored session from localstorage
   */
  removeSession(): void {
    localStorage.removeItem(LOCAL_STORAGE_SESSION);
    this.currentSession$.next(null);
  }
}
