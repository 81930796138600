import { SpaceUser } from './space-user';

export enum UserRole {
  ADMIN = 'Admin',
  USER = 'User',
  MANAGER = 'Manager'
}

export enum AppAccess {
  SITE_DIARY = 'Site Diary',
  SITE_TASK = 'Site Task'
}

export class UserModel {
  firstName?: string;
  lastName?: string;
  email: string;
  userRole: UserRole;
  appAccess: AppAccess[];
  active: boolean;

  public static fromSpaceUser(user: SpaceUser, userModel: UserModel) {
    userModel.firstName = user.firstName;
    userModel.lastName = user.lastName;
    userModel.email = user.mail;
    userModel.active = user.active;
    if (user.role === UserRole.ADMIN) {
    userModel.userRole = UserRole.ADMIN;
    } else if (user.role === UserRole.MANAGER) {
    userModel.userRole = UserRole.MANAGER;
    } else if (user.role === UserRole.USER) {
    userModel.userRole = UserRole.USER;
    }
    userModel.appAccess = [];
    if (user.siteDiaryAccess) {
    userModel.appAccess.push(AppAccess.SITE_DIARY);
    }
    if (user.siteTaskAccess) {
    userModel.appAccess.push(AppAccess.SITE_TASK);
    }
  }
}

export interface MultipleInviteUserRecord {
  emailAddress: string;
  role: string;
  siteDiaryAccess: boolean;
  siteTaskAccess: boolean;
}

export interface MultipleInviteUserResponse {
  successfullyAddedUsers: SpaceUser[];
  failedToAddUsers: SpaceUser[];
}

export enum MultipleInviteUserAPIError {
  TOO_MANY_INVITES = 'TOO_MANY_INVITES'
}
