import { Labelable } from './labelable';
import { Identifiable } from './identifiable';

export class TaskContractor implements Labelable, Identifiable {
  contractorId: string;
  name?: string;

  constructor() {
    this.contractorId = '';
  }

  public static jsonToTaskContractors(jsonContractors): Array<TaskContractor> {
    return jsonContractors.map(jsonContractor => {
      const contractor: TaskContractor = new TaskContractor();
      contractor.contractorId = jsonContractor;
      return contractor;
    });
  }

  public static taskContractorsToDto(taskContractors: TaskContractor[]): Array<string> {
    return taskContractors.map(taskContractor => taskContractor.contractorId);
  }

  public get label(): string {
    return this.name;
  }

  /**
   * /!\ Warning /!\ This is a helper ID and is not the same as the ID used in the backend
   */
  public get id(): string {
    return this.contractorId;
  }
}
